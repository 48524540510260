import { get } from "lodash";
import { batch } from "react-redux";

import { bookmarkedProjectsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { optimisticUpdateItem } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { clearBookmarkedProjectStatuses } from "store/resources/actions/project/bookmarkedProjectStatusesActions";
import { clearProjectList, getProjectList, optimisticUpdateProjectBookmark } from "store/resources/actions/project/projectActions";

import {
    BookmarkedProject,
    BookmarkedProjectResponseModel,
    CreateBookmarkParams,
    DeleteBookmark,
    UseBookmarksParams,
    UpdateBookmarkForProjectParams,
    OptimisticDeleteBookmarkParams,
    GetBookmarkListParams,
    GetResourceParams,
} from "./types";

const getBookmarkList = ({ userNumber, getState }: GetBookmarkListParams): BookmarkedProject[] => {
    return (
        get(
            getState(),
            `resources.${bookmarkedProjectsResourceName}.itemsById[${bookmarkedProjectsResourceName}-${userNumber}].data.model`
        ) || []
    );
};

const getResourceParams = ({ idClient, idProject, userNumber, updateItems, onComplete, dispatch }: GetResourceParams) => ({
    resourceName: bookmarkedProjectsResourceName,
    key: `${bookmarkedProjectsResourceName}-${userNumber}`,
    path: idProject ? { idProject } : undefined,
    showSuccessNotification: false,
    updateFromResponse: false,
    optimisticUpdate: {
        value: {
            model: updateItems,
        },
    },
    onComplete: () => {
        batch(() => {
            dispatch(clearBookmarks({ userNumber }));
            dispatch(clearBookmarkedProjectStatuses());
            dispatch(clearProjectList({ idClient }));
        });

        onComplete?.();
    },
});

const createBookmark =
    ({ idClient, idProject, userNumber, clientName, onComplete }: CreateBookmarkParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const project = getProjectList({ idClient, getState }).find((p) => p.idProject === idProject);

        const updatedBookmarkList = [
            ...(getBookmarkList({ userNumber, getState }) || []),
            {
                clientName,
                project: {
                    idClient,
                    idProject,
                    projectName: project?.projectName,
                    projectDescription: project?.projectDescription,
                    started: project?.started,
                    projectState: project?.projectState,
                    bookmarked: true,
                },
            },
        ];

        batch(() => {
            dispatch(
                createResource(
                    getResourceParams({ idClient, idProject, userNumber, updateItems: updatedBookmarkList, onComplete, dispatch })
                )
            );

            dispatch(optimisticUpdateProjectBookmark({ idClient, idProject, bookmarked: true }));
        });
    };

export const clearBookmarks =
    ({ userNumber }: UseBookmarksParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: bookmarkedProjectsResourceName,
                key: `${bookmarkedProjectsResourceName}-${userNumber}`,
                broadcast: true,
            })
        );
    };

const deleteBookmark =
    ({ idClient, idProject, userNumber, onComplete }: DeleteBookmark) =>
    // @ts-ignore
    (dispatch, getState) => {
        const updatedBookmarkList = getBookmarkList({ userNumber, getState }).filter(
            (bookmark) => bookmark.project.idProject !== idProject
        );

        batch(() => {
            dispatch(
                deleteResource(
                    getResourceParams({
                        idClient,
                        idProject,
                        userNumber,
                        updateItems: updatedBookmarkList,
                        onComplete,
                        dispatch,
                    })
                )
            );

            dispatch(optimisticUpdateProjectBookmark({ idClient, idProject, bookmarked: false }));
        });
    };

export const useBookmarkedProjects = ({ userNumber }: UseBookmarksParams) => {
    return useResource({
        resourceName: bookmarkedProjectsResourceName,
        key: userNumber ? `${bookmarkedProjectsResourceName}-${userNumber}` : undefined,
        transform: (data: BookmarkedProjectResponseModel) => data?.model,
    }) as [BookmarkedProject[], boolean | undefined, any | undefined];
};

export const updateBookmarkForProject =
    ({ idClient, idProject, userNumber, bookmarked, clientName, onCreationComplete, onDeletionComplete }: UpdateBookmarkForProjectParams) =>
    // @ts-ignore
    (dispatch) => {
        if (bookmarked) {
            dispatch(deleteBookmark({ idClient, idProject, userNumber, onComplete: onDeletionComplete }));
        } else if (clientName) {
            dispatch(createBookmark({ idClient, idProject, userNumber, clientName, onComplete: onCreationComplete }));
        }
    };

export const optimisticDeleteBookmark =
    ({ idProject, userNumber }: OptimisticDeleteBookmarkParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const updatedBookmarkList = getBookmarkList({ userNumber, getState }).filter(
            (bookmark) => bookmark.project.idProject !== idProject
        );

        dispatch(
            optimisticUpdateItem({
                resourceName: bookmarkedProjectsResourceName,
                key: `${bookmarkedProjectsResourceName}-${userNumber}`,
                value: {
                    model: updatedBookmarkList,
                },
            })
        );
    };
