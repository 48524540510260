import { allProjectStates, allModelStates, allComponentModelStates } from "utils/constants";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ROLES } from "utils/user/defines";

export const projectReadOnlyEnabled = (state: string) => state === allProjectStates.FINAL_REPORT.toUpperCase();

export const modelReadOnlyEnabled = (state: string, required: boolean | null) =>
    (state === allModelStates.CLIENT_APPROVED.toUpperCase() || state === allComponentModelStates.FINAL.toUpperCase()) &&
    Boolean(required) === true;

/**
 * Checks if action is allowed for specific user role.
 *
 * BASIC_CLIENT, BASIC_PRIMARY_CONTACT - Read-only access
 *
 * ADVANCED_CLIENT - Same as BASIC_CLIENT, but has editing access to non-required Models
 *
 * Internal users - Access to everything
 *
 * @param role - user's role
 * @param action - action to check
 * @param required - Model required for Project
 *
 * @returns true - action is allowed; false - action is not allowed
 */
export const actionAllowed = (role: string, action: string, required: boolean | null) => {
    if (role === USER_ROLES.ADVANCED_CLIENT) {
        return hasAnyOfPermissions([action]) && Boolean(required) === false;
    } else {
        return hasAnyOfPermissions([action]);
    }
};
