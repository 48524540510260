import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IdsRow, IdsCol } from "@emergn-infinity/ids-react";

import {
    // RUN_ALL_MODULES_START,
    RUN_PENDING_MODULES_V2_START,
} from "store/actionTypes";

import { selectModule } from "store/modules/actions";

import Button from "components/ui/ButtonNew";
import CardsContainer from "components/ui/Cards";
import Drawer from "components/ui/Drawer";
import Icon from "components/ui/IconNew";
import LabelWithIcon from "components/ui/LabelWithIcon";

import CalculationWorkflowSidebar from "layouts/Sidebar/CalculationWorkflowSidebar";
import { openModalDialogAddModule, openModalDialogAddPotentialModule } from "layouts/Modal/CalculationWorkflowModal/utils";

import { actionAllowed } from "pages/utils";

import ModuleCard from "./ModuleCard";
import AddModuleCard from "./AddModuleCard";

import { useModuleList, useRunList } from "utils/useModuleList";
import { useUserRights } from "utils/useUserRights";
import { moduleTypes } from "utils/constants";
import { USER_ACTIONS } from "utils/user/defines";
import { closeWindowCalculationWorkflow } from "utils/window";

import { ModulesContainerProps } from "./types";

import "./style.scss";

const ModulesContainer = memo(
    ({
        viewIndex,
        idClient,
        idProject,
        idAnalyst,
        idReviewer,
        idModel,
        idInputLog,
        modules,
        modelName,
        modelState,
        modelRequired,
    }: ModulesContainerProps) => {
        const dispatch = useDispatch();

        // @ts-ignore
        const selectedModule = useSelector((state) => state.modules.selection?.[idModel]?.[viewIndex])?.moduleId;

        const moduleList = useModuleList({ modules });
        const runList = useRunList({ moduleList });

        const userRights = useUserRights();

        // State that tracks whether "Run Pending Modules" button
        // was clicked or not
        const [runPending, setRunPending] = useState(false);

        useEffect(() => {
            if (!isEmpty(modules) && selectedModule === undefined) {
                const { moduleId } = modules.find((module) => module.type === moduleTypes.BASELINE) || modules[0];

                // Do not select optimistic update item
                // Note: Optimistic update item has
                // a string ID number
                if (typeof moduleId === "number") {
                    dispatch(selectModule({ viewIndex, idModel, moduleId }));
                }
            }
        }, [viewIndex, idModel, modules, selectedModule, dispatch]);

        const onRunPendingModulesClick = useCallback(() => {
            //     dispatch({
            //         type: RUN_ALL_MODULES_START,
            //         payload: {
            //             idProject,
            //             idModel,
            //             runList,
            //         },
            //     });

            setRunPending(true);

            dispatch({
                type: RUN_PENDING_MODULES_V2_START,
                payload: {
                    idProject,
                    idModel,
                    runList,
                },
            });
        }, [idProject, idModel, runList, dispatch]);

        const onAddPotentialModuleClick = useCallback(
            (item) => {
                if (item.length > 1) {
                    dispatch(
                        openModalDialogAddPotentialModule({
                            idProject,
                            idModel,
                            idInputLog,
                            modules: item,
                        })
                    );
                } else {
                    dispatch(
                        openModalDialogAddModule({
                            idProject,
                            idModel,
                            idInputLog,
                            title: "Add EE Potential Module",
                            type: moduleTypes.EE_POTENTIAL,
                            modules: item,
                        })
                    );
                }
            },
            [idProject, idModel, idInputLog, dispatch]
        );

        const onAddBaselineModuleClick = useCallback(() => {
            dispatch(
                openModalDialogAddModule({
                    idProject,
                    idModel,
                    idInputLog,
                    title: "Add Baseline Module",
                    type: moduleTypes.BASELINE,
                    modules,
                })
            );
        }, [idProject, idModel, idInputLog, modules, dispatch]);

        const onModuleClick = useCallback(
            (moduleId) => {
                dispatch(selectModule({ viewIndex, idModel, moduleId }));
            },
            [viewIndex, idModel, dispatch]
        );

        const onBreadcrumbClick = useCallback(() => {
            dispatch(
                closeWindowCalculationWorkflow({
                    idProject,
                    idModel,
                })
            );
        }, [idProject, idModel, dispatch]);

        return (
            <div className="flex-column fill-height modules-container">
                <IdsRow customClasses="with-scroll" horizontalAlign="left" noGutters>
                    <IdsCol customClasses="with-scroll" xs="8">
                        <div className="modules-container__cards-container">
                            <div className="flex-row align-center justify-space-between modules-container__breadcrumbs-container">
                                <div className="flex-row" onClick={onBreadcrumbClick}>
                                    <Icon icon="arrows-arrow_left_b_a" clickable />
                                    <LabelWithIcon
                                        className="modules-container__breadcrumbs-model-name"
                                        icon="files-document_new__empty_b_s"
                                        clickable
                                    >
                                        {modelName}
                                    </LabelWithIcon>
                                </div>
                                <div>
                                    <span>ID: </span>
                                    <span>{idModel}</span>
                                </div>
                            </div>
                            {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                                <div className="modules-container__actions-container">
                                    <Button
                                        dataTestId="run-pending-modules"
                                        variant="primary"
                                        padding="md"
                                        iconLeft="multimedia-play__start_b_s"
                                        iconSize="sm"
                                        isDisabled={runList.length === 0}
                                        onClick={onRunPendingModulesClick}
                                    >
                                        Run Pending Modules
                                    </Button>
                                </div>
                            )}
                            {moduleList.map((item, index) => (
                                <CardsContainer key={`module-group-${index}`}>
                                    {item.map((module) => (
                                        <ModuleCard
                                            dataTestId={`${module.moduleOrder}-${module.type}-${module.studyCase}`}
                                            key={`${module.moduleOrder}-${module.type}-${module.studyCase}`}
                                            idClient={idClient}
                                            idProject={idProject}
                                            idAnalyst={idAnalyst}
                                            idReviewer={idReviewer}
                                            idModel={idModel}
                                            idInputLog={idInputLog}
                                            moduleId={module.moduleId}
                                            moduleLevel={module.moduleLevel}
                                            moduleOrder={module.moduleOrder}
                                            moduleState={module.moduleState}
                                            runState={module.runState}
                                            studyCase={module.studyCase}
                                            type={module.type}
                                            modelName={modelName}
                                            modelState={modelState}
                                            modelRequired={modelRequired}
                                            selected={selectedModule === module.moduleId}
                                            runPending={runPending}
                                            setRunPending={setRunPending}
                                            onClick={() => onModuleClick(module.moduleId)}
                                        />
                                    ))}
                                    {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                                        <AddModuleCard
                                            disabled={item.some((module) => module.disableAddPotentialModule === true)}
                                            onClick={() => onAddPotentialModuleClick(item)}
                                        >
                                            Add Associated Potential Module
                                        </AddModuleCard>
                                    )}
                                </CardsContainer>
                            ))}
                            {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                                <CardsContainer addCard>
                                    <AddModuleCard onClick={onAddBaselineModuleClick}>Add New Baseline Module</AddModuleCard>
                                </CardsContainer>
                            )}
                        </div>
                    </IdsCol>
                    <IdsCol customClasses="with-scroll" xs="4">
                        <Drawer className="flex-column">
                            <CalculationWorkflowSidebar
                                viewIndex={viewIndex}
                                idClient={idClient}
                                idProject={idProject}
                                idModel={idModel}
                                idInputLog={idInputLog}
                                moduleId={selectedModule}
                                modelName={modelName}
                                modelRequired={modelRequired}
                            />
                        </Drawer>
                    </IdsCol>
                </IdsRow>
            </div>
        );
    }
);

export default ModulesContainer;
