import { batch } from "react-redux";

import { RUN_MODULE_V2_START, RUN_PENDING_MODULES_V2_START, PAUSE_MODULE_V2_START, PAUSE_MODULE_V2_COMPLETE } from "store/actionTypes";

import { clearModules } from "store/resources/actions/calculationWorkflow/modulesActions";
import { clearAllModuleRuns, clearLastModuleRun } from "store/resources/actions/calculationWorkflow/moduleRunsActions";
import { clearModuleInputs } from "store/resources/actions/calculationWorkflow/moduleInputsActions";
import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import { runModule, runPendingModules, pauseModule } from "./actions";

import { RunModuleV2Action } from "./types";

export const runModuleV2Middleware = ({ dispatch }) => {
    return (next: any) => (action: RunModuleV2Action) => {
        switch (action.type) {
            case RUN_MODULE_V2_START:
                {
                    const { idProject, idModel, moduleId, onStart, onError } = action.payload;

                    dispatch(
                        runModule({
                            idProject,
                            idModel,
                            moduleId,
                            onStart,
                            onError,
                        })
                    );
                }

                break;

            case RUN_PENDING_MODULES_V2_START:
                {
                    const { idProject, idModel, runList, onStart, onError } = action.payload;

                    dispatch(
                        runPendingModules({
                            idProject,
                            idModel,
                            runList,
                            onStart,
                            onError,
                        })
                    );
                }

                break;

            case PAUSE_MODULE_V2_START:
                {
                    const { idProject, idModel, moduleId, resultsId } = action.payload;

                    dispatch(
                        pauseModule({
                            idProject,
                            idModel,
                            moduleId,
                            resultsId,
                        })
                    );
                }

                break;

            case PAUSE_MODULE_V2_COMPLETE:
                {
                    const { idProject, idModel, moduleId } = action.payload;

                    batch(() => {
                        dispatch(clearProjectLogItems({ idProject }));
                        dispatch(clearModelLogItems({ idProject, idModel }));
                        dispatch(clearModules({ idModel }));
                        dispatch(clearModuleInputs({ moduleId }));
                        dispatch(clearLastModuleRun({ moduleId }));
                        dispatch(clearAllModuleRuns({ moduleId }));
                    });
                }

                break;

            default:
        }

        return next(action);
    };
};
