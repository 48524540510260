import { isEmpty } from "lodash";
import { memo, useEffect, useMemo } from "react";

import DashboardHeader from "./DashboardHeader";
import TrashBinLogPanel from "./TrashBinLogPanel";

import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";
import { useStandaloneReports } from "store/resources/actions/standaloneReport/standaloneReportActions";

import hj from "utils/hotJar";

import { TrashBinDashboardProps } from "./types";
import { ProjectInput } from "store/resources/actions/projectInput/types";
import { StandaloneReport } from "store/resources/actions/standaloneReport/types";

import "./style.scss";

const TrashBinDashboard = memo(({ viewIndex, project }: TrashBinDashboardProps) => {
    const { idProject } = project;

    const [allModels, isLoadingModels] = useProjectInputs({ idProject });
    const [allComponentModels, isLoadingComponentModels] = useComponentModels({ idProject });
    const [allStandaloneReports, isLoadingStandaloneReports] = useStandaloneReports({ idProject });

    const modelsInTrash = useMemo(() => {
        let modelsInTrash: any[] = [];

        let legacyModelsInTrash: any[] = [];
        let componentModelsInTrash: any[] = [];

        if (!isEmpty(allModels) && !isLoadingModels) {
            legacyModelsInTrash = allModels.filter((model: ProjectInput) => model.active === false);
        }

        if (!isEmpty(allComponentModels) && !isLoadingComponentModels) {
            componentModelsInTrash = allComponentModels.filter((model) => model.active === false);
        }

        modelsInTrash = legacyModelsInTrash.concat(componentModelsInTrash);

        return modelsInTrash;
    }, [allModels, isLoadingModels, allComponentModels, isLoadingComponentModels]);

    const standaloneReportsInTrash = useMemo(() => {
        let standaloneReportsInTrash: any[] = [];

        if (!isEmpty(allStandaloneReports) && !isLoadingStandaloneReports) {
            standaloneReportsInTrash = allStandaloneReports.filter(
                (standaloneReport: StandaloneReport) => standaloneReport.active === false
            );
        }

        return standaloneReportsInTrash;
    }, [allStandaloneReports, isLoadingStandaloneReports]);

    const trashCount = useMemo(() => {
        let trashCount = 0;

        if (!isEmpty(modelsInTrash)) {
            trashCount += modelsInTrash.length;
        }

        if (!isEmpty(standaloneReportsInTrash)) {
            trashCount += standaloneReportsInTrash.length;
        }

        return trashCount;
    }, [modelsInTrash, standaloneReportsInTrash]);

    useEffect(() => {
        hj("stateChange", "TrashBinDashboard");
    }, []);

    return (
        <div className="flex-column fill-height trash-bin-dashboard">
            <div className="trash-bin-dashboard__header-container">
                <DashboardHeader idProject={idProject} trashCount={trashCount} />
            </div>
            <div className="trash-bin-dashboard__body-container">
                <div>
                    <TrashBinLogPanel
                        idProject={idProject}
                        viewIndex={viewIndex}
                        modelsInTrash={modelsInTrash}
                        standaloneReportsInTrash={standaloneReportsInTrash}
                    />
                </div>
            </div>
        </div>
    );
});

export default TrashBinDashboard;
