import { POWERBI_DATASET_REFRESH_ERROR, POWERBI_DATASET_REFRESH_RECEIVED, POWERBI_DATASET_REFRESH_REQUESTED } from "store/actionTypes";

import { Action, State } from "store/powerBI/types";

const initialState: State = {};

export const reducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case POWERBI_DATASET_REFRESH_REQUESTED:
            state = {
                ...state,
                [action.id]: {
                    status: "Unknown",
                    error: undefined,
                    isReading: true,
                },
            };

            break;

        case POWERBI_DATASET_REFRESH_RECEIVED:
            state = {
                ...state,
                [action.id]: {
                    ...action.payload,
                    error: undefined,
                    isReading: false,
                },
            };

            break;

        case POWERBI_DATASET_REFRESH_ERROR:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    ...action.payload,
                    isReading: false,
                },
            };

            break;

        default:
            break;
    }

    return state;
};
