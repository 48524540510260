///
/// Territory actions
/// resourceName: territoriesResourceName
///
import {
    territoriesResourceName,
    territoriesClientResourceKey,
    territoriesProjectResourceKey,
    territoriesAllResourceKey,
} from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

export const useClientTerritories = ({ idClient }) =>
    useResource({
        resourceName: territoriesResourceName,
        key: `${territoriesClientResourceKey}-${idClient}`,
        query: {
            idClient: idClient,
        },
        transform: (data) => {
            // Sort territories alphabetically by their names
            return data?.model.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
            );
        },
    });

export const useProjectTerritories = ({ idProject }) =>
    useResource({
        resourceName: territoriesResourceName,
        key: `${territoriesProjectResourceKey}-${idProject}`,
        query: {
            idProject: idProject,
        },
        transform: (data) => data?.model || [],
    });

export const useAllTerritories = () =>
    useResource({
        resourceName: territoriesResourceName,
        key: territoriesAllResourceKey,
        transform: (data) => data?.model || [],
    });

export const clearAllTerritoryList = () => (dispatch) =>
    dispatch(
        clearResource({
            resourceName: territoriesResourceName,
            key: `${territoriesAllResourceKey}`,
            broadcast: true,
        })
    );

export const clearClientTerritories =
    ({ idClient }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: territoriesResourceName,
                key: `${territoriesClientResourceKey}-${idClient}`,
                broadcast: true,
            })
        );

export const clearProjectTerritories =
    ({ idProject }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: territoriesResourceName,
                key: `${territoriesProjectResourceKey}-${idProject}`,
                broadcast: true,
            })
        );
