import { isEqual } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { updateProject } from "store/resources/actions/project/projectActions";
import { useClientFuels, clearProjectFuels } from "store/resources/actions/fuel/fuelActions";
import { useClientTerritories, clearProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useVariableList } from "store/resources/actions/variables/variablesActions";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import DatePicker from "components/ui/Input/DatePicker";
import DatePickerContainer from "components/ui/Input/DatePicker/DatePickerContainer";

import { useUserRights } from "utils/useUserRights";
import { useVariablesTransform } from "utils/useVariablesTransform";
import { USER_ROLES } from "utils/user/defines";

import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";
import { VariablesResponseModel } from "store/resources/actions/variables/types";
import { Territory, Fuel } from "types/types";

import "./style.scss";

const useTransform = (data: VariablesResponseModel) => useVariablesTransform(data?.model, 3);

const ProjectDetails = memo(({ project, editing, onEditCancel }: ProjectDashboardPanelProps) => {
    const { idClient, idProject } = project;

    const dispatch = useDispatch();

    // Resources

    const [allTerritories, isLoadingTerritory] = useClientTerritories({ idClient });
    const [allFuels, isLoadingFuel] = useClientFuels({ idClient });

    const [projectVariables, isLoadingProjectVariables] = useVariableList({ idProject, transform: useTransform });

    const userRights = useUserRights();

    // States

    // @todo: refactor territories and fuels from strings to numbers
    const [territories, setTerritories] = useState<string[]>([]);
    const [fuels, setFuels] = useState<string[]>([]);

    // @todo: refactor targetDate from undefined to null
    const [targetDate, setTargetDate] = useState<any>(undefined);

    const [showSave, setShowSave] = useState(false);

    // Memos

    const savedTargetDate = Date.parse(project.targetDate || "");

    const territoryItems = useMemo(() => {
        return (
            allTerritories?.map((territory: Territory) => ({
                label: `${territory.name} ${territory.alias}`,
                value: territory.name,
            })) || []
        );
    }, [allTerritories]);

    const fuelItems = useMemo(() => {
        return (
            allFuels?.map((fuel: Fuel) => ({
                label: fuel.name,
                value: fuel.idFuel.toString(),
            })) || []
        );
    }, [allFuels]);

    let isLoading = true;

    if (userRights === USER_ROLES.MANAGER || userRights === USER_ROLES.ANALYST || userRights === USER_ROLES.ADVANCED_CLIENT) {
        isLoading = isLoadingTerritory || isLoadingFuel;
    } else if (userRights === USER_ROLES.BASIC_CLIENT || userRights === USER_ROLES.BASIC_PRIMARY_CONTACT) {
        isLoading = isLoadingTerritory || isLoadingFuel || isLoadingProjectVariables;
    }

    // Effects

    useEffect(() => {
        setTerritories(project.territories?.map((territory: Territory) => territory?.name) || []);
        setFuels(project.fuels?.map((fuel: Fuel) => fuel?.idFuel.toString()) || []);
    }, [project]);

    useEffect(() => {
        if (editing) {
            const savedTerritories = project.territories?.map((territory: Territory) => territory?.name) || [];
            const savedFuels = project.fuels?.map((fuel: Fuel) => fuel?.idFuel.toString()) || [];

            // If territories or fuels values have been changed,
            // then enable Save button
            if (
                // On initial render targetDate is undefined
                // if targetDate is not undefined and has been changed
                (targetDate !== undefined && Date.parse(targetDate) !== savedTargetDate) ||
                !isEqual(territories.sort(), savedTerritories.sort()) ||
                !isEqual(fuels.sort(), savedFuels.sort())
            ) {
                setShowSave(true);
            } else {
                setShowSave(false);
            }
        }
    }, [project, editing, territories, fuels, targetDate, savedTargetDate]);

    // Event handlers

    const onChange = useCallback((value, handler) => {
        handler(value);
    }, []);

    const onSaveComplete = useCallback(() => {
        dispatch(clearProjectTerritories({ idProject }));
        dispatch(clearProjectFuels({ idProject }));

        setTargetDate(undefined);
    }, [idProject, dispatch]);

    const onSaveClick = useCallback(() => {
        const projectTerritories = territories
            ?.map((territoryName: string) => allTerritories?.find((territory: Territory) => territory.name === territoryName))
            .filter((territory) => territory !== undefined);

        const projectFuels = fuels
            ?.map((idFuel) => allFuels?.find((fuel: Fuel) => fuel.idFuel.toString() === idFuel))
            .filter((fuel) => fuel !== undefined);

        setShowSave(false);
        onEditCancel?.();

        dispatch(
            updateProject({
                idClient,
                idProject,
                targetDate,
                territories: projectTerritories,
                fuels: projectFuels,
                onComplete: onSaveComplete,
            })
        );
    }, [idClient, idProject, allTerritories, territories, fuels, allFuels, targetDate, onEditCancel, onSaveComplete, dispatch]);

    const onCancelClick = useCallback(() => {
        setTerritories(project.territories?.map((territory: Territory) => territory?.name) || []);
        setFuels(project.fuels?.map((fuel: Fuel) => fuel?.idFuel.toString()) || []);
        setTargetDate(undefined);

        setShowSave(false);
        onEditCancel && onEditCancel();
    }, [project, onEditCancel]);

    const renderProjectVariable = (item: any) => {
        return item.map((it: any) => (
            <div key={`variable-${it.name}`} className="project-details__field">
                {it.isText ? (
                    <Input label={it.uiLabel} ghost percent={it.inputType === "%"} value={it.value} />
                ) : (
                    <Dropdown label={it.uiLabel} ghost value={it.value} items={it.values} />
                )}
            </div>
        ));
    };

    // Main render

    return (
        <>
            {isLoading ? (
                <IconLoading />
            ) : (
                <div className="project-details">
                    <FieldGroup>
                        <div className="flex-row">
                            <div className="project-details__field">
                                <Dropdown
                                    label="Territory"
                                    placeholder={editing ? "Select from the list" : "Not set"}
                                    ghost={!editing}
                                    value={territories}
                                    items={territoryItems}
                                    withFilter
                                    multiple
                                    singleLine
                                    onChange={(value) => onChange(value, setTerritories)}
                                />
                            </div>
                            <div className="project-details__field">
                                <Dropdown
                                    label="Fuel"
                                    placeholder={editing ? "Select from the list" : "Not set"}
                                    ghost={!editing}
                                    value={fuels}
                                    items={fuelItems}
                                    withFilter
                                    multiple
                                    singleLine
                                    onChange={(value) => onChange(value, setFuels)}
                                />
                            </div>
                            <div className="project-details__field">
                                <DatePicker
                                    // @ts-ignore - remove when DatePicker is refactored to TypeScript
                                    label="Target Date"
                                    placeholder={editing ? "Select date" : "Not set"}
                                    ghost={!editing}
                                    value={targetDate || savedTargetDate}
                                    popperContainer={DatePickerContainer}
                                    onChange={(data: any) => onChange(data?.value || undefined, setTargetDate)}
                                />
                            </div>
                        </div>
                        {(userRights === USER_ROLES.BASIC_CLIENT || userRights === USER_ROLES.BASIC_PRIMARY_CONTACT) &&
                            projectVariables?.map((pv: any, index: number) => (
                                <div key={`project-variable-group-${index}`} className="flex-row">
                                    {renderProjectVariable(pv)}
                                </div>
                            ))}
                    </FieldGroup>
                    {editing && (
                        <IdsButtonGroup customClasses="project-details__actions" position="right" spaceBetween="lg">
                            <Button variant="primary" padding="lg" isDisabled={!showSave} onClick={onSaveClick}>
                                Save For Project
                            </Button>
                            <Button variant="secondary" padding="lg" onClick={onCancelClick}>
                                Cancel
                            </Button>
                        </IdsButtonGroup>
                    )}
                </div>
            )}
        </>
    );
});

export default ProjectDetails;
