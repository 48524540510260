import { memo, useCallback, useState } from "react";

import ProjectStudyCase from "./ProjectStudyCase";

import DashboardPanel from "components/ui/Dashboard";

import StudyCasePanelHeader from "pages/ManageProject/ProjectionsContent/common/StudyCasePanelHeader";

import { projectReadOnlyEnabled } from "pages/utils";

import { PROJECT_STUDY_CASE } from "utils/constants";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

const ProjectStudyCasePanel = memo(({ project }: ProjectDashboardPanelProps) => {
    const [isEditing, setIsEditing] = useState(false);

    const onChangeClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const onCancelClick = useCallback(() => {
        setIsEditing(false);
    }, []);

    const headerAction = (
        <StudyCasePanelHeader
            editing={isEditing}
            readOnly={projectReadOnlyEnabled(project.projectState) || !hasAnyOfPermissions([USER_ACTIONS.PROJECT_STUDY_CASE_GOALS_EDIT])}
            onChangeClick={onChangeClick}
        />
    );

    return (
        <DashboardPanel
            className="project-study-case-panel"
            title="Project Study Case Goals"
            headerAction={headerAction}
            pageId={PROJECT_STUDY_CASE}
        >
            <ProjectStudyCase project={project} editing={isEditing} onEditCancel={onCancelClick} />
        </DashboardPanel>
    );
});

export default ProjectStudyCasePanel;
