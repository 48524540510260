import { get } from "lodash";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "react-customizable-progressbar";

import { downloadModelResults } from "store/downloadFile/actions";

import Icon from "components/ui/IconNew";
import IconWrap from "components/ui/Icons";
import IconPreparing from "components/ui/Icons/IconPreparing";

import "./style.scss";

const DownloadResults = memo((props: DownloadResultsProps) => {
    const { idInputLog, idResultsLog, detailed } = props;

    const dispatch = useDispatch();

    const downloadResults: any = useSelector((state) => get(state, `downloadFile.${idResultsLog}`)) ?? {};

    const { receivedLength, contentLength, error, completed = true } = downloadResults;

    const onDownloadResultsClick = useCallback(() => {
        dispatch(
            downloadModelResults({
                idResultsLog,
                idInputLog,
            })
        );
    }, [idInputLog, idResultsLog, dispatch]);

    return (
        <div className="download-results">
            {error && (
                <div className="flex-row align-center">
                    <IconWrap icon="warning-report-problem-empty" title={!detailed ? "Download failed, please try again" : ""} error />
                    {detailed && <span className="margin-left-small details">Download failed, please try again</span>}
                </div>
            )}
            {completed ? (
                <Icon className="download-icon" icon="arrows-align_bottom_b_a" iconTitle="Export" onClick={onDownloadResultsClick} />
            ) : (
                <div className="flex-row align-center">
                    {receivedLength ? (
                        <>
                            <ProgressBar
                                className="indicator"
                                radius={8}
                                progress={((receivedLength || 0) * 100) / contentLength}
                                fillColor="transparent"
                                strokeWidth={2}
                                strokeColor="#5d9cec"
                                strokeLinecap="square"
                                trackStrokeWidth={2}
                                pointerStrokeWidth={0}
                            />
                            {detailed && <span className="margin-left-small details">Downloading...</span>}
                        </>
                    ) : (
                        <>
                            <IconPreparing />
                            {detailed && <span className="margin-left-small details">Preparing data...</span>}
                        </>
                    )}
                </div>
            )}
        </div>
    );
});

interface DownloadResultsProps {
    /**
     * ID of Model.
     */
    idInputLog: number;

    /**
     * ID of Results.
     */
    idResultsLog: number;

    /**
     * Detailed version.
     */
    detailed?: boolean;
}

export default DownloadResults;
