import * as React from "react";
import cn from "classnames";

import Icon from "components/ui/IconNew";

import { IconSizes } from "components/ui/IconNew/types";

import "./style.scss";

const LabelWithIcon = (props: LabelWithIconProps) => {
    const { className, icon, iconSize = "sm", iconClassName, dataTestId, disabled = false, clickable = false, children, onClick } = props;

    const isClickable = (clickable || Boolean(onClick)) && !disabled;

    const classNames = cn("flex-row label-with-icon", className, {
        clickable: isClickable,
        disabled: disabled,
        "label-sm": iconSize === "sm",
        "label-md": iconSize === "md",
        "label-lg": iconSize === "lg",
    });

    return (
        <div data-testid={dataTestId} className={classNames} onClick={onClick}>
            <Icon className={cn(iconClassName)} icon={icon} size={iconSize} />
            <div className="label-with-icon__label">{children}</div>
        </div>
    );
};

interface LabelWithIconProps {
    dataTestId?: string;
    className?: string;
    clickable?: boolean;
    disabled?: boolean;
    icon: string;
    iconSize?: IconSizes;
    iconClassName?: string;
    children: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default LabelWithIcon;
