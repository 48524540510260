import { transform, chunk, filter } from "lodash";
import { useMemo } from "react";

import { isSwitcher } from "utils/studyCase";

import { StudyCase, StudyCaseTransformed } from "store/resources/actions/studyCase/types";

export const useStudyCasesTransform = (data: StudyCase[]): StudyCaseTransformed[][] | undefined => {
    return useMemo(() => {
        if (!data) {
            return;
        }

        const model: StudyCaseTransformed[] = transform(data, (accumulator, value, key) => {
            let studyCase = { ...value, isSwitcher: false, isCheckbox: false };

            if (studyCase.value && isSwitcher(studyCase.value)) {
                if (studyCase.parameterName === "INCLUDE_ACHIEVABLE") {
                    studyCase.isSwitcher = false;
                    studyCase.isCheckbox = true;
                } else {
                    studyCase.isSwitcher = true;
                }
            } else {
                studyCase.isSwitcher = false;
            }

            if (studyCase.parameterName === "ECONOMIC_TEST") {
                try {
                    studyCase.value = studyCase.value ? JSON.parse(studyCase.value) : null;
                } catch (e) {
                    studyCase.value = null;
                }
            }

            accumulator[key] = studyCase;
        });

        return chunk(
            filter(model, (sc) => sc.parameterName !== "ECONOMIC_TEST"),
            3
        ).concat(
            chunk(
                filter(model, (sc) => sc.parameterName === "ECONOMIC_TEST"),
                1
            )
        );
    }, [data]);
};
