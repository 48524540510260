import { API_HUB_BROADCAST_CLEAR_RESOURCE, API_CRUD_NEED_UPDATE } from "store/actionTypes";

/**
 * Mark resource as outdated.
 */
export const clearResource =
    ({ resourceName, key, userNumber, broadcast = false }: ClearResourceParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: API_CRUD_NEED_UPDATE,
            key,
            passThroughData: {
                resourceName,
                userNumber,
            },
        });

        if (broadcast) {
            dispatch({
                type: API_HUB_BROADCAST_CLEAR_RESOURCE,
                resourceName,
                key,
            });
        }
    };

interface ClearResourceParams {
    /**
     * Name of the resource property.
     */
    key: string | RegExp;

    /**
     * Name of the resource.
     */
    resourceName: string;

    /**
     * Number of the user.
     */
    userNumber?: string;

    /**
     * Clear resource for other users.
     */
    broadcast?: boolean;
}
