import { isEmpty } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { windowUpdate } from "store/window/actions";

import { useProjectNames } from "store/resources/actions/project/projectNamesActions";
import { useTeamMembers } from "store/resources/actions/user/userActions";
import { updateProject } from "store/resources/actions/project/projectActions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import Input from "components/ui/Input";

import { windowContainerTypes } from "utils/window";

import { EditProjectProps } from "layouts/Modal/ProjectDashboardModal/types";

const EditProject = memo((props: EditProjectProps) => {
    const { idClient, idProject, onCancel } = props;

    const dispatch = useDispatch();

    // @ts-ignore
    const userNumber = useSelector((state) => state.vdsmUser.userNumber);

    const initialProjectTeamMembers = props.projectTeamMembers ? props.projectTeamMembers.map((member) => member.idUser.toString()) : [];

    // Resources

    const [projectNames = []] = useProjectNames({ idClient });
    const [internalUsers, isLoadingInternalUsers] = useTeamMembers();

    // States

    const [projectName, setProjectName] = useState(props.projectName);
    const [projectDescription, setProjectDescription] = useState(props.projectDescription);
    const [projectManager, setProjectManager] = useState<string>(props.projectManager?.toString() || "");
    const [projectTeamMembers, setProjectTeamMembers] = useState<string[]>(initialProjectTeamMembers);

    // Memos

    const projectNameExists = useMemo(() => {
        return (
            projectNames.some((name: string) => name.toLowerCase() === projectName.toLowerCase().trim()) &&
            projectName !== props.projectName
        );
    }, [props.projectName, projectName, projectNames]);

    const userItems = useMemo(() => {
        let userItems: any[] = [];

        if (!isEmpty(internalUsers) && !isLoadingInternalUsers) {
            userItems = internalUsers.map((member) => ({
                label: member.fullName || "",
                value: member.idUser.toString(),
            }));
        }

        return userItems;
    }, [internalUsers, isLoadingInternalUsers]);

    const onChange = useCallback((value, handler) => {
        handler(value);
    }, []);

    const onProjectNameChangeComplete = useCallback(
        (projectName) => {
            dispatch(
                windowUpdate({
                    name: `client-${idClient}-project-${idProject}`,
                    containerName: windowContainerTypes.Root,
                    headerSubTitle: projectName,
                    tabSubTitle: projectName,
                })
            );
        },
        [idClient, idProject, dispatch]
    );

    const onSaveClick = useCallback(() => {
        dispatch(
            updateProject({
                idClient,
                idProject,
                idManager: Number(projectManager),
                projectName,
                projectDescription,
                managerFullName: internalUsers.find((user) => user.idUser.toString() === projectManager)?.fullName,
                members: isEmpty(projectTeamMembers)
                    ? undefined
                    : internalUsers.filter((member) => projectTeamMembers.includes(member.idUser.toString())),
                userNumber,
                onComplete: projectName !== props.projectName ? () => onProjectNameChangeComplete(projectName) : undefined,
            })
        );

        onCancel?.();
    }, [
        idClient,
        idProject,
        props.projectName,
        internalUsers,
        projectName,
        projectDescription,
        projectManager,
        projectTeamMembers,
        userNumber,
        onCancel,
        onProjectNameChangeComplete,
        dispatch,
    ]);

    return (
        <>
            <div className="flex-column modal-padding">
                <FieldGroup>
                    <Input
                        dataTestId="project-name-input"
                        label="Project name"
                        placeholder="Study Year Project Type"
                        required
                        value={projectName}
                        error={projectNameExists}
                        msgText={projectNameExists ? "This name is already in use." : ""}
                        onChange={(event) => onChange(event.target.value, setProjectName)}
                    />
                    <Input
                        dataTestId="project-description-input"
                        label="Project description"
                        placeholder="Type a project description"
                        required
                        value={projectDescription}
                        onChange={(event) => onChange(event.target.value, setProjectDescription)}
                    />
                    <Dropdown
                        label="Manager responsible"
                        placeholder="Select from the list"
                        value={projectManager}
                        items={userItems}
                        withFilter
                        onChange={(value) => onChange(value, setProjectManager)}
                    />
                    <Dropdown
                        label="Project team members"
                        placeholder="Select from the list"
                        value={projectTeamMembers}
                        items={userItems}
                        multiple
                        singleLine
                        withFilter
                        onChange={(value) => onChange(value, setProjectTeamMembers)}
                    />
                </FieldGroup>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button dataTestId="save-project" variant="primary" padding="lg" onClick={onSaveClick}>
                    Save
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default EditProject;
