import { memo } from "react";

import DashboardPanel from "components/ui/Dashboard";

import StandaloneReports from "./StandaloneReports";

import { StandaloneReportProps } from "./types";

const StandaloneReportsPanel = memo(({ idProject, projectName }: StandaloneReportProps) => {
    return (
        <DashboardPanel className="project-stakeholder-dashboard-panel" title="Standalone Reports">
            <StandaloneReports idProject={idProject} projectName={projectName} />
        </DashboardPanel>
    );
});

export default StandaloneReportsPanel;
