import { get } from "lodash";

import { SELECT_PROJECTION, SET_PROJECTIONS_VIEW_ID, HUB_EVENT_RECEIVE, MODEL_ACK_CHANGE } from "store/actionTypes";
import { modelActions } from "store/resources/actions/projectInput/projectInputActions";

import { ProjectionsState } from "./types";

const initialState: ProjectionsState = {
    selection: {},
    status: {},
};

export const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SELECT_PROJECTION:
            {
                const { viewIndex, idProject, ...rest } = action.selection;

                const selectedProjections = state.selection[idProject] || [];

                selectedProjections[viewIndex] = { ...rest };

                state = {
                    ...state,
                    selection: {
                        ...state.selection,
                        [idProject]: selectedProjections,
                    },
                };
            }

            break;

        case SET_PROJECTIONS_VIEW_ID:
            {
                const { viewIndex, idProject, viewId } = action.selection;

                const selectedProjections = state.selection[idProject] || [];

                selectedProjections[viewIndex] = {
                    ...selectedProjections[viewIndex],
                    viewId,
                };

                state = {
                    ...state,
                    selection: {
                        ...state.selection,
                        [idProject]: selectedProjections,
                    },
                };
            }

            break;

        case HUB_EVENT_RECEIVE:
            // If event is modelAction
            if (Object.values(modelActions).includes(action.key)) {
                const { idProject, idInputLog, lastModified } = action.data;

                // Set projection status
                if (state.selection.hasOwnProperty(idProject)) {
                    if (idInputLog !== undefined) {
                        // @ts-ignore
                        if (state.selection[idProject].idInputLog === idInputLog) {
                            state = {
                                ...state,
                                status: {
                                    ...state.status,
                                    [idProject]: {
                                        // @ts-ignore
                                        ...(state.status[idProject] || {}),
                                        [idInputLog]: {
                                            status: action.key,
                                            userNumber: action.userNumber,
                                            lastModified: lastModified,
                                        },
                                    },
                                },
                            };
                        }
                    } else {
                        // TODO: Add status change for Standalone Reports here...
                    }
                }
            }

            break;

        case MODEL_ACK_CHANGE:
            const { idProject, idInputLog } = action.data;
            const inputLogStatus = get(state.status, `${idProject}.${idInputLog}`);

            if (inputLogStatus !== undefined) {
                // @ts-ignore
                const { [idInputLog]: _remove, ...status } = state.status[idProject];

                state = {
                    ...state,
                    status: {
                        ...state.status,
                        [idProject]: status,
                    },
                };
            }

            break;

        // TODO: Add code here...
        //
        // case STANDALONE_REPORT_ACK_CHANGE:

        //     break;

        default:
            break;
    }

    return state;
};
