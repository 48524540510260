import { batch } from "react-redux";

import * as actionTypes from "store/actionTypes";

import { heartbeat } from "store/system/actions";
import { windowActivate } from "store/window/actions";

import { deleteWindowState, getLoginWindowParams } from "utils/window";
import { getVdsmUser, saveVdsmUser } from "utils/user";

export const vdsmLogin =
    ({ userName, password, authCode }) =>
    async (dispatch, getState) => {
        if (getState().login.isLoading) {
            return;
        }

        dispatch({
            type: actionTypes.API_VDSM_LOGIN,
            userName,
            password,
            authCode,
            actionTypes: {
                pending: actionTypes.VDSM_LOGIN_REQUEST,
                success: actionTypes.VDSM_LOGIN_RESPONSE,
                error: actionTypes.VDSM_LOGIN_ERROR,
                store: actionTypes.VDSM_USER_SAVE,
            },
        });
    };

export const login =
    ({ userName, password, authCode }) =>
    async (dispatch, getState) => {
        if (getState().login.isLoading) {
            return;
        }

        dispatch({
            type: actionTypes.API_LOGIN,
            userName,
            password,
            authCode,
            actionTypes: {
                pending: actionTypes.LOGIN_REQUEST,
                success: actionTypes.LOGIN_RESPONSE,
                error: actionTypes.LOGIN_ERROR,
                store: actionTypes.USER_SAVE,
            },
        });
    };

export const loginClearQrCode = () => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const user = {
        ...getState().vdsmUser,
        qrCode: undefined,
    };

    saveVdsmUser(user);

    dispatch({
        type: actionTypes.VDSM_USER_SAVE,
        vdsmUser: user,
    });

    dispatch({
        type: actionTypes.API_HUB_CONNECT,
    });

    dispatch({
        type: actionTypes.API_RIGHTS_REQUEST,
    });
};

export const refresh =
    ({ action } = {}) =>
    async (dispatch, getState) => {
        if (action) {
            dispatch({
                type: actionTypes.LOGIN_ADD_REPEAT_ACTION,
                repeatAction: action,
            });
        }

        if (getState().login.isLoading) {
            return;
        }

        let accessToken = null;
        let refreshToken = null;
        const user = getVdsmUser();

        if (user) {
            accessToken = user.accessToken;
            refreshToken = user.refreshToken;
        }

        dispatch({
            type: actionTypes.API_LOGIN_REFRESH,
            accessToken,
            refreshToken,
            actionTypes: {
                pending: actionTypes.LOGIN_REFRESH_REQUEST,
                success: actionTypes.LOGIN_REFRESH_RESPONSE,
                error: actionTypes.API_LOGIN_REFRESH_ERROR_RESPONSE,
                store: actionTypes.VDSM_USER_SAVE,
            },
        });
    };

export const logout = () => async (dispatch, getState) => {
    if (getState().login.logOutPending) {
        return;
    }

    const { accessToken, refreshToken } = getState().vdsmUser;

    batch(() => {
        dispatch({
            type: actionTypes.API_LOGOUT,
            accessToken,
            refreshToken,
            actionTypes: {
                pending: actionTypes.LOGOUT_REQUEST,
                success: actionTypes.LOGOUT_RESPONSE,
                error: actionTypes.LOGOUT_ERROR,
                store: actionTypes.USER_DELETE,
            },
        });

        dispatch(resetApp());
    });
};

export const updatePassword = (params) => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const { oldPassword, newPassword, newPasswordRepeated, authCode } = params;

    if (newPassword !== newPasswordRepeated) {
        dispatch({
            type: actionTypes.UPDATE_PASSWORD_ERROR,
            message: "New password does not match the confirm password.",
        });
    } else {
        const { name, accessToken } = getState().vdsmUser;

        dispatch({
            type: actionTypes.API_UPDATE_PASSWORD,
            userName: name,
            oldPassword,
            newPassword,
            authCode,
            accessToken,
            actionTypes: {
                pending: actionTypes.UPDATE_PASSWORD_REQUEST,
                success: actionTypes.UPDATE_PASSWORD_RESPONSE,
                error: actionTypes.UPDATE_PASSWORD_ERROR,
                store: actionTypes.VDSM_USER_SAVE,
            },
        });
    }
};

export const forgotPassword = (params) => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const { userName, recaptcha } = params;

    dispatch({
        type: actionTypes.API_FORGOT_PASSWORD,
        userName,
        recaptcha,
        actionTypes: {
            pending: actionTypes.FORGOT_PASSWORD_REQUEST,
            success: actionTypes.FORGOT_PASSWORD_RESPONSE,
            error: actionTypes.FORGOT_PASSWORD_ERROR,
        },
    });
};

export const resetPassword =
    ({ userNumber, temporaryPassword, newPassword, authCode, recaptcha }) =>
    async (dispatch, getState) => {
        if (getState().resetPassword.isLoading) {
            return;
        }

        dispatch({
            type: actionTypes.API_RESET_PASSWORD,
            userNumber,
            temporaryPassword,
            newPassword,
            authCode,
            recaptcha,
            actionTypes: {
                pending: actionTypes.RESET_PASSWORD_REQUEST,
                success: actionTypes.RESET_PASSWORD_RECEIVE,
                error: actionTypes.RESET_PASSWORD_ERROR,
                store: actionTypes.VDSM_USER_SAVE,
            },
        });
    };

export const resetLogin = () => (dispatch) => {
    batch(() => {
        dispatch({
            type: actionTypes.LOGIN_RESET,
        });

        const { name, containerName } = getLoginWindowParams();

        dispatch(
            windowActivate({
                name,
                containerName,
            })
        );
    });
};

export const resetApp = () => async (dispatch) => {
    deleteWindowState();

    batch(() => {
        dispatch({
            type: actionTypes.USER_DELETE,
        });

        dispatch({
            type: actionTypes.MODAL_CLOSE,
        });

        const { name, containerName } = getLoginWindowParams();

        dispatch(
            windowActivate({
                name,
                containerName,
            })
        );

        dispatch(heartbeat());
    });
};
