import { isNil } from "lodash";

import { RUN_CALCULATION_START, RUN_CALCULATION_TERMINATE, RUN_CALCULATIONS_START } from "store/actionTypes";

import { runCalculation, runMultipleCalculations } from "store/processes/actions/runCalculation/runCalculationActions";

import { stopCalculation } from "store/resources/actions/calculations/stopCalculationActions";

export function runCalculationMiddleware({ dispatch }) {
    return (next) => (action) => {
        switch (action.type) {
            case RUN_CALCULATION_START:
                {
                    const { idProject, idInputLog, idModel = undefined, idResults = 0, includePotential, onStart, onError } = action;

                    dispatch(
                        runCalculation({
                            idProject,
                            idInputLog,
                            idModel,
                            idResults,
                            includePotential,
                            onStart,
                            onError,
                        })
                    );
                }

                break;

            case RUN_CALCULATION_TERMINATE:
                {
                    const { idCalculation } = action;

                    if (!isNil(idCalculation)) {
                        dispatch(stopCalculation({ idCalculation }));
                    }
                }

                break;

            case RUN_CALCULATIONS_START:
                {
                    const { idProject, idResults = 0, models, onStart, onError } = action;

                    dispatch(
                        runMultipleCalculations({
                            idProject,
                            idResults,
                            models,
                            onStart,
                            onError,
                        })
                    );
                }

                break;

            default:
                break;
        }

        return next(action);
    };
}
