import { isEmpty, uniqBy, sortBy } from "lodash";
import { useMemo } from "react";

import { useAllEquipmentInputs, useMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";

import { UseModelInputTablesParams } from "./types";

/**
 * Hook that returns all Model Input tables.
 *
 * That includes:
 *  - Equipment Tables
 *  - Non Equipment Tables
 *  - Additional Tables
 *  - Demand Forecast Tables
 *  - Result Tables
 */
export const useAllModelInputTables = () => {
    const [allModelInputs, isLoadingAllModelInputs] = useAllEquipmentInputs();

    const equipmentInputsTables = useMemo(() => uniqBy(allModelInputs?.equipmentinputstables, "sheetName") || [], [allModelInputs]);

    const nonEquipmentInputsTables = useMemo(() => uniqBy(allModelInputs?.nonequipmentinputstables, "sheetName") || [], [allModelInputs]);

    const extraInputsTables = useMemo(() => uniqBy(allModelInputs?.extrainputstables, "sheetName") || [], [allModelInputs]);

    const resultTables = useMemo(() => uniqBy(allModelInputs?.resulttables, "sheetName") || [], [allModelInputs]);

    return {
        equipmentInputsTables,
        nonEquipmentInputsTables,
        extraInputsTables,
        resultTables,
        isLoadingAllModelInputs,
    };
};

export const useSortedModelInputTables = () => {
    const { equipmentInputsTables, nonEquipmentInputsTables, extraInputsTables, resultTables } = useAllModelInputTables();

    const allInputsTables = useMemo(
        () =>
            sortBy(
                equipmentInputsTables.concat(nonEquipmentInputsTables).concat(extraInputsTables).concat(resultTables),
                "sheetName"
            ) as any,
        [equipmentInputsTables, nonEquipmentInputsTables, extraInputsTables, resultTables]
    );

    return {
        allInputsTables,
    };
};

export const useModelInputTables = ({ idInputLog }: UseModelInputTablesParams) => {
    const [modelInputsRes, isLoadingModelInputsRes] = useMeasureInputs({ idInputLog });

    const modelInputs = useMemo(() => {
        let modelInputs: any = {};

        if (!isEmpty(modelInputsRes) && !isLoadingModelInputsRes) {
            modelInputs = (modelInputsRes.equipmentinputstables || [])
                .concat(modelInputsRes.nonequipmentinputstables || [])
                .concat(modelInputsRes.extrainputstables || [])
                .concat(modelInputsRes.resulttables || [])
                .reduce((acc: any, cv: any) => {
                    if (cv !== undefined) {
                        (acc[cv.sheetName] || (acc[cv.sheetName] = [])).push(cv);
                    }

                    return acc;
                }, {});
        }

        return modelInputs;
    }, [modelInputsRes, isLoadingModelInputsRes]);

    return {
        modelInputs,
        isLoadingModelInputs: isLoadingModelInputsRes,
    };
};
