import { memo } from "react";

import DashboardPanel from "components/ui/Dashboard";

import BookmarkedProjectStatuses from "./BookmarkedProjectStatuses";

const BookmarkedProjectStatusesPanel = memo(() => {
    return (
        <DashboardPanel className="bookmarked-project-statuses-panel" title="Bookmarked Project Statuses" lazyLoad={false}>
            <BookmarkedProjectStatuses />
        </DashboardPanel>
    );
});

export default BookmarkedProjectStatusesPanel;
