const noteTabs = [
    {
        id: "client",
        label1: "Client notes",
        label2: "client notes",
    },
    {
        id: "internal",
        label1: "Internal notes",
        label2: "internal notes",
    },
];

export const getNoteTabs = (isExternalUser: boolean) => {
    let tabs = [...noteTabs];

    if (isExternalUser) {
        tabs = [tabs[0]].map((tab) => ({ ...tab, label1: "Notes", label2: "notes" }));
    }

    return tabs;
};
