import { v1 as uuidv1 } from "uuid";
import { isEmpty } from "lodash";
import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { uploadMarketProfileData } from "store/processes/actions/dataImport/projectDataImportActions";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import UploadFile from "components/ui/Input/UploadFile";

import { isNullOrWhitespace } from "utils/string";

import { UploadMarketProfileDataModalProps } from "layouts/Modal/ProjectDashboardModal/types";

const UploadMarketProfileData = memo(
    ({ idProject, idProjectUpload, onUploadStart, onUploadComplete, onCancel }: UploadMarketProfileDataModalProps) => {
        const dispatch = useDispatch();

        const [files, setFiles] = useState<File[]>([]);

        const onDropFile = useCallback((event) => {
            setFiles(event);
        }, []);

        const onRemoveFile = useCallback((files) => {
            setFiles(files);
        }, []);

        const onImportClick = useCallback(() => {
            files.forEach((file) => {
                if (isNullOrWhitespace(file.name)) {
                    return;
                }

                const id = uuidv1();

                dispatch(
                    uploadMarketProfileData({
                        id,
                        idProject,
                        idProjectUpload,
                        file,
                        name: file.name,
                        onStart: () => {
                            onUploadStart(id, files.length);
                        },
                        onComplete: onUploadComplete,
                    })
                );
            });
        }, [idProject, idProjectUpload, files, onUploadStart, onUploadComplete, dispatch]);

        return (
            <>
                <div className="flex-column modal-padding with-scroll">
                    <UploadFile
                        label="Data file"
                        accept={[".xls", ".xlsm", ".xlsx"]}
                        multiple
                        files={files}
                        onDrop={onDropFile}
                        onRemove={(file) => onRemoveFile(files)}
                    />
                </div>
                <ButtonGroup className="justify-end modal-actions">
                    <Button primary disabled={isEmpty(files)} onClick={onImportClick}>
                        Import
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </ButtonGroup>
            </>
        );
    }
);

export default UploadMarketProfileData;
