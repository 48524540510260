import { memo, useEffect } from "react";

import ImportData from "layouts/common/ImportData";

import Separator from "components/ui/Separator";

import HowToImport from "pages/ManageProject/ProjectionsContent/common/HowToImport";

import hj from "utils/hotJar";

import { ModelSetupProps } from "./types";

import "./style.scss";

const ModelSetup = memo(({ viewIndex, idClient, idProject }: ModelSetupProps) => {
    useEffect(() => {
        hj("stateChange", "ModelSetup");
    }, []);

    return (
        <div className="model-setup">
            <h3>Import data for New Model</h3>
            <HowToImport />
            <Separator />
            <ImportData viewIndex={viewIndex} idClient={idClient} idProject={idProject} />
        </div>
    );
});

export default ModelSetup;
