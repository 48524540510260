import { memo } from "react";

import EmbeddedReport from "pages/common/EmbeddedReport";

import { EmbeddedReportProps } from "pages/common/EmbeddedReport/types";

const StandaloneReport = memo(({ idProject, idReport, idPbi, reportType, fileName }: EmbeddedReportProps) => {
    return (
        <div className="flex-column fill-height">
            <EmbeddedReport
                idProject={idProject}
                idReport={idReport}
                idPbi={idPbi}
                reportType={reportType}
                fileName={fileName}
                showPresentationMode={false}
            />
        </div>
    );
});

export default StandaloneReport;
