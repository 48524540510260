import { batch } from "react-redux";

import * as _actionTypes from "store/actionTypes";

import { windowRemove } from "store/window/actions";
import { logout as logoutAction, resetApp } from "store/login/actions";
import { setServerTimezoneOffset } from "store/system/actions";

import {
    createUser,
    refreshUser,
    saveUser,
    saveVdsmUser,
    getUserNameFromToken,
    hasInternalUserRole,
    hasExternalUserRole,
} from "utils/user";
import { windowContainerTypes } from "utils/window";
import { VLM_URL, isVLMLoginPage } from "utils/constants";
import { isNullOrWhitespace } from "utils/string";

export const BASE_URL = process.env.REACT_APP_VDSM_AUTHENTICATION_API_BASE_URL;
export const BASE_USER_URL = process.env.REACT_APP_VDSM_USERS_API_BASE_URL;

export function authenticationApiMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        let actionFunc = next;

        switch (action.type) {
            case _actionTypes.API_VDSM_LOGIN:
                actionFunc = vdsmLogin;
                break;

            case _actionTypes.API_LOGIN:
                actionFunc = login;
                break;

            case _actionTypes.API_LOGIN_RESPONSE:
                actionFunc = updateUser;
                break;

            case _actionTypes.API_RIGHTS_REQUEST:
                actionFunc = getRights;
                break;

            case _actionTypes.API_RIGHTS_RESPONSE:
                actionFunc = setRights;
                break;

            case _actionTypes.API_LOGIN_REFRESH:
                actionFunc = refresh;
                break;

            case _actionTypes.API_LOGIN_REFRESH_RESPONSE:
                actionFunc = refreshResponse;
                break;

            case _actionTypes.API_LOGIN_REFRESH_ERROR_RESPONSE:
                actionFunc = refreshErrorResponse;
                break;

            case _actionTypes.API_UPDATE_PASSWORD:
                actionFunc = updatePassword;
                break;

            case _actionTypes.API_UPDATE_PASSWORD_RESPONSE:
                actionFunc = updatePasswordResponse;
                break;

            case _actionTypes.API_FORGOT_PASSWORD:
                actionFunc = forgotPassword;
                break;

            case _actionTypes.API_FORGOT_PASSWORD_RESPONSE:
                actionFunc = forgotPasswordResponse;
                break;

            case _actionTypes.API_RESET_PASSWORD:
                actionFunc = resetPassword;
                break;

            case _actionTypes.API_RESET_PASSWORD_RESPONSE:
                actionFunc = resetPasswordResponse;
                break;

            case _actionTypes.API_LOGOUT:
                actionFunc = logout;
                break;

            case _actionTypes.API_LOGOUT_RESPONSE:
                actionFunc = logoutResponse;
                break;

            default:
                actionFunc = next;
                break;
        }

        return actionFunc(action);
    };

    async function login(action) {
        const { userName, password, actionTypes } = action;

        const accessToken = btoa(userName + ":" + password);

        const user = { userName, accessToken, status: "active" };

        saveUser(user);

        batch(() => {
            dispatch({
                type: actionTypes.store,
                user,
            });

            dispatch({
                type: actionTypes.success,
                user,
            });
        });
    }

    async function vdsmLogin(action) {
        const { userName, password, actionTypes, authCode } = action;

        dispatch({
            type: _actionTypes.API_POST,
            url: BASE_URL + "insightstokens",
            body: JSON.stringify({ userName, password, authCode }),
            actionTypes,
            passThroughData: { userName },
            responseActionType: _actionTypes.API_LOGIN_RESPONSE,
        });
    }

    async function getRights() {
        dispatch({
            type: _actionTypes.API_VDSM_GET_AUTHORIZED,
            url: `${process.env.REACT_APP_API_BASE_URL}permissions`,
            actionTypes: {
                store: _actionTypes.VDSM_USER_SAVE,
            },
            responseActionType: _actionTypes.API_RIGHTS_RESPONSE,
        });
    }

    async function setRights(action) {
        const { data, actionTypes } = action;
        const { vdsmUser } = getState();

        const user = {
            ...vdsmUser,
            rights: data,
        };

        // If internal user logs into VisionInsight or
        // if external user logs into VisionLoadMAP,
        // save the user
        if (hasInternalUserRole(user) || (hasExternalUserRole(user) && isVLMLoginPage) || Boolean(process.env.REACT_APP_NO_REDIRECT)) {
            saveVdsmUser(user);

            dispatch({
                type: actionTypes.store,
                vdsmUser: user,
            });
        }
        // If external user logs into VisionInsight,
        // redirect user to VisionLoadMAP
        //
        // Note: this is skipped if "npm run start-vlm" command is used
        else {
            dispatch(logoutAction());

            window.location.replace("https://" + VLM_URL);
        }
    }

    async function refresh(action) {
        const { accessToken, refreshToken, actionTypes } = action;

        dispatch({
            type: _actionTypes.API_PUT,
            url: BASE_URL + "insightstokens",
            body: JSON.stringify({ accessToken, refreshToken }),
            actionTypes,
            skipErrorReport: true,
            responseActionType: _actionTypes.API_LOGIN_REFRESH_RESPONSE,
        });
    }

    async function refreshResponse(action) {
        const { data, actionTypes } = action;
        const { repeatActions } = getState().login;
        const user = refreshUser(data);

        batch(() => {
            dispatch({
                type: actionTypes.store,
                vdsmUser: user,
            });

            dispatch({
                type: actionTypes.success,
                vdsmUser: user,
            });

            dispatch({
                type: _actionTypes.API_RIGHTS_REQUEST,
            });

            repeatActions.forEach(dispatch);

            dispatch({
                type: _actionTypes.LOGIN_CLEAR_REPEAT_ACTIONS,
            });
        });
    }

    async function refreshErrorResponse(action) {
        batch(() => {
            dispatch({
                type: _actionTypes.LOGIN_REFRESH_ERROR,
                message: action.message,
            });

            dispatch(resetApp());
        });
    }

    async function updatePassword(action) {
        const { userName, oldPassword, newPassword, accessToken, actionTypes, authCode } = action;

        dispatch({
            type: _actionTypes.API_PUT,
            url: BASE_USER_URL + "password",
            body: JSON.stringify({ userName, oldPassword, newPassword, accessToken, authCode }),
            actionTypes,
            passThroughData: { userName },
            responseActionType: _actionTypes.API_UPDATE_PASSWORD_RESPONSE,
        });
    }

    async function updatePasswordResponse(action) {
        const { data, actionTypes } = action;
        const { responseStatus } = data;

        if (responseStatus === "loginfailed") {
            dispatch({
                type: actionTypes.error,
                message: data.responseMessage,
            });
        } else {
            updateUser(action);
        }
    }

    async function logout(action) {
        const { accessToken, refreshToken, actionTypes } = action;

        batch(() => {
            dispatch({
                type: _actionTypes.API_DELETE,
                url: BASE_URL + "insightstokens",
                body: JSON.stringify({ accessToken, refreshToken }),
                actionTypes,
                responseActionType: _actionTypes.API_LOGOUT_RESPONSE,
            });

            dispatch({
                type: _actionTypes.API_HUB_DISCONNECT,
            });
        });
    }

    async function logoutResponse(action) {
        const { actionTypes } = action;

        dispatch({
            type: actionTypes.success,
        });
    }

    async function updateUser(action) {
        const { data, passThroughData, actionTypes } = action;
        const { userName } = passThroughData;
        const { qrCode, serverTimezoneOffset, responseMessage } = data;

        const user = createUser({
            ...data,
            userName,
            rights: undefined,
        });

        // TODO: Uncomment when themes are implemented into Vision Insight
        //
        // if (user.settings) {
        //     setSettings(user.settings);
        // }

        if (serverTimezoneOffset) {
            dispatch(setServerTimezoneOffset({ serverTimezoneOffset }));
        }

        switch (user.status) {
            case "active":
                saveVdsmUser(user);

                batch(() => {
                    dispatch({
                        type: actionTypes.store,
                        vdsmUser: user,
                    });

                    dispatch({
                        type: actionTypes.success,
                        vdsmUser: user,
                    });

                    if (isNullOrWhitespace(qrCode)) {
                        dispatch({
                            type: _actionTypes.API_HUB_CONNECT,
                        });

                        dispatch({
                            type: _actionTypes.API_RIGHTS_REQUEST,
                        });
                    }
                });

                break;

            case "expired":
                batch(() => {
                    dispatch({
                        type: actionTypes.store,
                        vdsmUser: user,
                    });

                    dispatch({
                        type: actionTypes.success,
                        vdsmUser: user,
                    });
                });

                break;

            case "disabled":
            case "timelock":
            case "notfound":
            default:
                dispatch({
                    type: actionTypes.error,
                    message: responseMessage,
                });

                break;
        }
    }

    async function forgotPassword(action) {
        const { userName, recaptcha, actionTypes } = action;

        dispatch({
            type: _actionTypes.API_POST,
            url: BASE_URL + "insightspassword",
            body: JSON.stringify({
                userName: userName,
                recaptcha: recaptcha,
            }),
            actionTypes,
            responseActionType: _actionTypes.API_FORGOT_PASSWORD_RESPONSE,
        });
    }

    async function forgotPasswordResponse(action) {
        const { data, actionTypes } = action;

        dispatch({
            type: actionTypes.success,
            message: data.responseMessage,
        });
    }

    async function resetPassword(action) {
        const { userNumber, temporaryPassword, newPassword, authCode, recaptcha, actionTypes } = action;

        dispatch({
            type: _actionTypes.API_POST,
            url: `${BASE_USER_URL}${userNumber}/password`,
            body: JSON.stringify({ temporaryPassword, newPassword, authCode, recaptcha }),
            actionTypes,
            responseActionType: _actionTypes.API_RESET_PASSWORD_RESPONSE,
        });
    }

    async function resetPasswordResponse(action) {
        const { data, actionTypes } = action;
        const { responseStatus } = data;

        if (responseStatus === "loginfailed") {
            dispatch({
                type: actionTypes.error,
                message: data.responseMessage,
            });
        } else {
            updateUser({
                ...action,
                passThroughData: getUserNameFromToken(data.accessToken),
            });

            window.history.pushState("", "", "/");

            dispatch(
                windowRemove({
                    name: "Reset Password",
                    containerName: windowContainerTypes.Login,
                })
            );
        }
    }
}
