import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";

import { usePotentialFlag } from "utils/usePotentialFlag";

import {
    ProjectCalculationPreconditions,
    ModelCalculationPreconditions,
    UseProjectCalculationPreconditionsParams,
    UseModelCalculationPreconditionsParams,
} from "./types";
import { CalculationPreconditionsTransformed } from "store/resources/actions/calculations/types";

export const useProjectCalculationPreconditions = ({
    idProject,
}: UseProjectCalculationPreconditionsParams): ProjectCalculationPreconditions => {
    const [calculationPreconditionsResponse] = useCalculationPreconditions({ idProject });

    const projectCalculationPreconditions = useMemo(() => {
        let projectCalculationPreconditions: any = {};

        if (
            !isEmpty(calculationPreconditionsResponse.BASELINE_PRECONDITIONS) &&
            !isEmpty(calculationPreconditionsResponse.POTENTIAL_PRECONDITIONS)
        ) {
            const baselineCalculationArr = [];
            const potentialCalculationArr = [];

            // List of idInputLogs
            const baselineKeys = Object.keys(calculationPreconditionsResponse.BASELINE_PRECONDITIONS);
            const potentialKeys = Object.keys(calculationPreconditionsResponse.POTENTIAL_PRECONDITIONS);

            // Push those idInputLogs that have invalid table/-s
            // for baseline calculation
            for (const idInputLog of baselineKeys) {
                const _idInputLog = parseInt(idInputLog);

                if (calculationPreconditionsResponse.BASELINE_PRECONDITIONS[_idInputLog].some((cp) => cp.tableIsValid === 0)) {
                    baselineCalculationArr.push(_idInputLog);
                }
            }

            // Push those idInputLogs that have invalid table/-s
            // for potential calculation
            for (const idInputLog of potentialKeys) {
                const _idInputLog = parseInt(idInputLog);

                if (calculationPreconditionsResponse.POTENTIAL_PRECONDITIONS[_idInputLog].some((cp) => cp.tableIsValid === 0)) {
                    potentialCalculationArr.push(_idInputLog);
                }
            }

            projectCalculationPreconditions = {
                BASELINE_PRECONDITIONS: baselineCalculationArr,
                POTENTIAL_PRECONDITIONS: potentialCalculationArr,
            };
        }

        return projectCalculationPreconditions;
    }, [calculationPreconditionsResponse]);

    return { projectCalculationPreconditions };
};

export const useModelCalculationPreconditions = ({
    idProject,
    idInputLog,
}: UseModelCalculationPreconditionsParams): ModelCalculationPreconditions => {
    const { modelPotentialFlag } = usePotentialFlag({ idProject, idInputLog });

    const [calculationPreconditionsResponse, isLoading] = useCalculationPreconditions({ idProject });

    const calculationPreconditions = useMemo(() => {
        let calculationPreconditions: CalculationPreconditionsTransformed[] = [];

        if (idInputLog !== undefined) {
            calculationPreconditions = modelPotentialFlag
                ? calculationPreconditionsResponse.POTENTIAL_PRECONDITIONS[idInputLog]
                : calculationPreconditionsResponse.BASELINE_PRECONDITIONS[idInputLog];
        }

        return calculationPreconditions;
    }, [idInputLog, modelPotentialFlag, calculationPreconditionsResponse]);

    // Returns data about each table
    const modelCalculationPreconditions = useMemo(() => {
        let modelCalculationPreconditions: any = {};

        if (!isEmpty(calculationPreconditions)) {
            modelCalculationPreconditions = calculationPreconditions.reduce((acc: any, cv) => {
                if (cv.tableName !== null) {
                    if (!acc[cv.tableName]) {
                        acc[cv.tableName] = [];
                    }

                    acc[cv.tableName].push(cv);
                }

                return acc;
            }, {});
        }

        return modelCalculationPreconditions;
    }, [calculationPreconditions]);

    // Returns error if any of the tables are not valid
    const modelCalculationPreconditionsErrors = useMemo(() => {
        return calculationPreconditions?.some((cp) => cp.tableIsValid === 0);
    }, [calculationPreconditions]);

    // Returns required tables for Model
    const requiredTables = useMemo(() => {
        let requiredTables: string[] = [];

        if (!isEmpty(calculationPreconditions)) {
            requiredTables = calculationPreconditions.reduce((acc: string[], cv) => {
                if (cv.tableName !== null) {
                    if (cv.required && !acc.includes(cv.tableName)) {
                        acc.push(cv.tableName);
                    }
                }

                return acc;
            }, []);
        }

        return requiredTables;
    }, [calculationPreconditions]);

    // Returns required sheets for Model
    const requiredSheets = useMemo(() => {
        let requiredSheets: string[] = [];

        if (!isEmpty(calculationPreconditions)) {
            requiredSheets = calculationPreconditions.reduce((acc: string[], cv) => {
                if (cv.sheetName !== null) {
                    if (cv.required && !acc.includes(cv.sheetName)) {
                        acc.push(cv.sheetName);
                    }
                }

                return acc;
            }, []);
        }

        return requiredSheets;
    }, [calculationPreconditions]);

    return { modelCalculationPreconditions, modelCalculationPreconditionsErrors, requiredTables, requiredSheets, isLoading };
};
