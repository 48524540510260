import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { windowAdd } from "store/window/actions";

import { windowContainerTypes } from "utils/window";

import { TileProps } from "./types";

import "./style.scss";

const Tile = ({ header, children, component, windowProps }: TileProps) => {
    const dispatch = useDispatch();

    const onPageOpen = useCallback(() => {
        dispatch(
            windowAdd({
                containerName: windowContainerTypes.Root,
                name: component,
                component,
                headerTitle: header,
                tabSubTitle: header,
                close: true,
                activate: true,
                props: windowProps,
            })
        );
    }, [header, component, windowProps, dispatch]);

    return (
        <div className="flex-row fill-width no-shrink flex-padding tile" onClick={onPageOpen}>
            <div className="flex-column">
                <div className="tile__header">{header}</div>
                <small>{children}</small>
            </div>
            <div className="flex-one" />
        </div>
    );
};

export default Tile;
