/**
 * Removes Object properties with empty values.
 *
 * @param tmp - object from which to remove empty object properties
 * @returns object only with valid properties
 */
export const removeObjEmptyProps = (tmp: any) =>
    Object.keys(tmp).reduce((obj: any, key) => {
        if (tmp[key] !== "") {
            obj[key] = tmp[key];
        }

        return obj;
    }, {});
