import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";

import { VerticalFadeProps } from "./types";

import "./style.scss";

const VerticalFade = memo((props: VerticalFadeProps) => {
    // States

    // States for fade effect

    const [isTopVisible, setIsTopVisible] = useState(true);
    const [isBottomVisible, setIsBottomVisible] = useState(false);

    // State and refs for detecting scrollbar visibility

    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

    // Refs

    const wrapperElementRef = useRef<HTMLDivElement>(null);
    const innerElementRef = useRef<HTMLDivElement>(null);

    // Helper function

    /**
     * Checks if scrollbar is present on the container
     * by subtracting parent container width from
     * child's container width.
     * If there's a difference, then that means
     * that scrollbar is present (scrollbar takes
     * 10 px of width).
     */
    const checkScrollVisibility = useCallback(() => {
        if (wrapperElementRef !== null && wrapperElementRef.current && innerElementRef !== null && innerElementRef.current) {
            if (wrapperElementRef.current.clientWidth - innerElementRef.current.clientWidth > 0) {
                setIsScrollbarVisible(true);
            } else {
                setIsScrollbarVisible(false);
            }
        }
    }, []);

    // useEffect

    useEffect(() => {
        checkScrollVisibility(); // run this function on initial render

        window.addEventListener("resize", checkScrollVisibility); // run this function on resize event as well

        return () => {
            window.removeEventListener("resize", checkScrollVisibility);
        };
    }, [checkScrollVisibility]);

    useEffect(() => {
        if (!isEmpty(props.filters)) {
            checkScrollVisibility(); // run this function on filter change
        }
    }, [props.filters, checkScrollVisibility]);

    // Event handler

    const onContainerScroll = useCallback((event) => {
        const { clientHeight, scrollHeight, scrollTop } = event.target;
        const scrollPosition = scrollHeight - scrollTop - clientHeight;

        // If scrollTop is equal to 0,
        // scroll is located at the top
        if (scrollTop === 0) {
            setIsTopVisible(true);
            setIsBottomVisible(false);
        }
        // If scrollPosition is below 1,
        // scroll is located at the bottom
        else if (scrollPosition < 1) {
            setIsTopVisible(false);
            setIsBottomVisible(true);
        }
        // If scroll is not located at the top
        // or the bottom, then it is somewhere
        // in the middle
        else {
            setIsTopVisible(false);
            setIsBottomVisible(false);
        }
    }, []);

    // Main render

    return (
        <div ref={wrapperElementRef} className={cn("fill-height", props.wrapperClassName, { "no-scroll": props.withFade })}>
            <div
                data-testid={props.dataTestId}
                ref={innerElementRef}
                className={cn("fill-height", props.innerClassName)}
                onScroll={props.withFade ? onContainerScroll : undefined}
            >
                {isScrollbarVisible && props.withFade && !props.skipTopFade && (
                    <div
                        className={cn("vertical-fade top-side-fade", {
                            "show-fade": !isTopVisible,
                            "hide-fade": isTopVisible,
                        })}
                    />
                )}
                {props.children}
                {isScrollbarVisible && props.withFade && !props.skipBottomFade && (
                    <div
                        className={cn("vertical-fade bottom-side-fade", {
                            "show-fade": !isBottomVisible,
                            "hide-fade": isBottomVisible,
                        })}
                    />
                )}
            </div>
        </div>
    );
});

export default VerticalFade;
