import * as React from "react";
import ClassNames from "classnames";

import "../style.scss";

const SideNavFooter = (props: SideNavFooterProps) => {
    const { className, children } = props;

    const classNames = ClassNames("flex-row sidenav-footer", className);

    return <div className={classNames}>{children}</div>;
};

interface SideNavFooterProps {
    className?: string;
    children?: React.ReactNode;
}

export default SideNavFooter;
