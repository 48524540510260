import { toast } from "react-toastify";

import { createProjectFile } from "store/resources/actions/projectFiles/projectFilesActions";

import { isDevEnvironment } from "utils/constants";

import { UploadProjectFileParams } from "./types";

export const uploadProjectFile =
    ({
        idProject,
        fileAccess,
        shareWithStakeholder,
        fileType,
        fileName,
        clientName,
        file,
        rights,
        onStart,
        onComplete,
    }: UploadProjectFileParams) =>
    // @ts-ignore
    (dispatch) => {
        let formData = new FormData();

        formData.append("idProject", idProject.toString());
        formData.append("fileAccess", fileAccess);
        formData.append("shareWithStakeholder", shareWithStakeholder.toString());
        formData.append("fileType", fileType);
        formData.append("fileName", fileName);
        formData.append("file", file);
        formData.append("userRights", rights);
        clientName && formData.append("clientName", clientName);

        const onUploadSuccess = (action: any) => {
            const responseStatus = action?.data?.responseStatus;
            const responseMessage = action?.data?.responseMessage;

            if (isDevEnvironment && responseStatus === "success") {
                console.log(action);
            }

            if (responseMessage === "failure") {
                toast.error(responseMessage.split("\n", 2)[0]);

                return;
            }

            toast.success(responseMessage);

            onComplete?.();
        };

        const onUploadError = () => {
            toast.error("Project file upload error");

            onComplete?.();
        };

        dispatch(createProjectFile({ idProject, formData, onStart, onSuccess: onUploadSuccess, onError: onUploadError }));
    };
