import { memo } from "react";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import FieldGroup from "components/ui/FieldGroup";

const Buttons = memo(() => {
    return (
        <div className="flex-column">
            <div className="ui-library__element-group__sub-title">Primary, Primary + Icon</div>
            <FieldGroup>
                <ButtonGroup>
                    <Button primary>Button</Button>
                    <Button primary icon="delete-trash-empty">
                        Button
                    </Button>
                </ButtonGroup>
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Secondary, Secondary + Icon, Danger
            </div>
            <FieldGroup>
                <ButtonGroup>
                    <Button>Button</Button>
                    <Button icon="delete-trash-empty">Button</Button>
                    <Button danger icon="delete-trash-filled">
                        Button
                    </Button>
                </ButtonGroup>
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Disabled, Disabled + Icon
            </div>
            <FieldGroup>
                <ButtonGroup>
                    <Button disabled>Button</Button>
                    <Button disabled icon="delete-trash-empty">
                        Button
                    </Button>
                </ButtonGroup>
            </FieldGroup>
        </div>
    );
});

export default Buttons;
