import { camelCase } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { projectInputValidationsDetailsResourceName, projectInputValidationsColumnsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { getResource } from "store/resources/actions/getResource";

import IconLoading from "components/ui/Icons/IconLoading";
import CustomList from "components/ui/List/CustomList";
import Pagination from "components/ui/Pagination";

import { ValidationErrorProps } from "pages/ManageProject/ProjectionsContent/common/ImportErrorPanel/ValidationError/types";

import "./style.scss";

export const ValidationErrorsTable = memo(({ idInputLog, idInputVersion, validations }: ValidationErrorProps) => {
    const dispatch = useDispatch();

    const [detailsPage, setDetailsPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    // TODO: Move as a separate resource
    const [headers, isVCLoading] = useResource({
        resourceName: projectInputValidationsColumnsResourceName,
        key: projectInputValidationsColumnsResourceName,
        transform: (data) => {
            if (!data?.model) {
                return;
            }

            const result = data.model[validations.tableName]
                .sort((a: any, b: any) => a.uiOrder - b.uiOrder)
                .map((c: any) => ({
                    [camelCase(c.columnName.substr(3))]: c.columnDisplayName,
                }));

            return Object.assign({}, ...result, { rowCount: "row count" });
        },
    });

    const inputValidationsDetailsParams = useCallback(
        (startIndex?: number, endIndex?: number) => {
            return {
                resourceName: projectInputValidationsDetailsResourceName,
                key: `${projectInputValidationsDetailsResourceName}-${idInputLog}-${idInputVersion}-${validations.idValidation}`,
                query: {
                    idInputLog,
                    idInputVersion,
                    idValidation: validations.idValidation,
                    startIndex,
                    endIndex,
                },
                onComplete: () => setIsLoading(false),
            };
        },
        [idInputLog, idInputVersion, validations.idValidation]
    );

    const [inputValidationDetails, isLoadingInputValidationDetails] = useResource({
        ...inputValidationsDetailsParams(),
        takeFromStore: true,
    });

    const detailsPagesCount = Math.ceil(validations.count / 10);

    useEffect(() => {
        dispatch(getResource(inputValidationsDetailsParams(0, 9)));
    }, [dispatch, inputValidationsDetailsParams]);

    const handleClickChangePage = useCallback(
        (page) => {
            if (!isLoadingInputValidationDetails) {
                setIsLoading(true);
                setDetailsPage(page);

                const startIndex = page * 10;
                const endIndex = Math.min(startIndex + 9, validations.count);

                dispatch(getResource(inputValidationsDetailsParams(startIndex, endIndex)));
            }
        },
        [dispatch, inputValidationsDetailsParams, isLoadingInputValidationDetails, validations.count]
    );

    return (
        <>
            <div className="flex-column no-scroll validation-errors-table">
                {isLoading || isVCLoading || !inputValidationDetails?.model ? (
                    <div className="flex-row validation-errors-table__overlay">
                        <div className="flex-row loading-input-validations">
                            <IconLoading />
                        </div>
                    </div>
                ) : (
                    // @ts-ignore - remove when CustomList is refactored to TypeScript
                    <CustomList headers={headers} items={inputValidationDetails?.model} withFade scrollBody />
                )}
                {inputValidationDetails?.model && inputValidationDetails.model.length < validations.count && (
                    <div className="flex-row validation-errors-table__action-row">
                        <Pagination
                            // @ts-ignore - remove when Pagination is refactored to TypeScript
                            selectedPage={detailsPage}
                            pageCount={detailsPagesCount}
                            onSelectPage={handleClickChangePage}
                            pageDisplayCount={5}
                        />
                    </div>
                )}
            </div>
        </>
    );
});

export default ValidationErrorsTable;
