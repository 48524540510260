import ClassNames from "classnames";

import { LabelProps } from "./types";

import "./style.scss";

const Label = (props: LabelProps) => {
    const classNames = ClassNames("label", props.className, {
        required: props.required,
        optional: props.optional,
        disabled: props.disabled,
        "read-only": props.readOnly,
        error: props.error,
    });

    return (
        <label className={classNames} htmlFor={props.htmlFor} onClick={props.disabled || props.readOnly ? undefined : props.onClick}>
            {props.children}
        </label>
    );
};

export default Label;
