import { memo } from "react";

import ProjectLog from "./ProjectLog";

import DashboardPanel from "components/ui/Dashboard";

import { PROJECT_LOG } from "utils/constants";

import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

const ProjectLogPanel = memo(({ project }: ProjectDashboardPanelProps) => {
    return (
        <DashboardPanel className="project-log-panel" title="Project Log" pageId={PROJECT_LOG}>
            <ProjectLog project={project} />
        </DashboardPanel>
    );
});

export default ProjectLogPanel;
