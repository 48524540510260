import { isEmpty } from "lodash";

import {
    APP_VERSION_CHANGED,
    API_GET,
    SYSTEM_STATUS_REQUEST,
    SYSTEM_STATUS_RECEIVE,
    SYSTEM_STATUS_ERROR,
    HEARTBEAT_STOP,
    SET_SERVER_TIMEZONE_OFFSET,
} from "store/actionTypes";
import { store } from "store/configureStore";

import { logError } from "utils/logger";
import { saveServerTimezone } from "utils/date";

let timer = null;
let appVersion = null;

const checkAppVersion = async () => {
    try {
        const response = await fetch("/version.json");
        const newVersion = await response.text();

        if (!isEmpty(newVersion)) {
            if (appVersion === null) {
                appVersion = newVersion;
            } else {
                if (newVersion !== appVersion) {
                    store.dispatch({ type: APP_VERSION_CHANGED });
                }
            }
        }
    } catch (error) {
        logError(error);
    }
};

export const heartbeat = () => (dispatch, getState) => {
    dispatch({
        type: API_GET,
        url: "/heartbeat.json",
        actionTypes: {
            pending: SYSTEM_STATUS_REQUEST,
            response: SYSTEM_STATUS_RECEIVE,
            error: SYSTEM_STATUS_ERROR,
        },
    });

    checkAppVersion();

    const intervalMs = getState().system.heartbeatInterval * 1000;

    if (intervalMs > 0) {
        clearInterval(timer);

        timer = setInterval(() => {
            // Skip this beat if request is in progress
            if (!getState().system.pending) {
                dispatch(heartbeat());
            }
        }, intervalMs);
    }
};

export const stopHeartbeat = () => {
    clearInterval(timer);

    return { type: HEARTBEAT_STOP };
};

export const setServerTimezoneOffset =
    ({ serverTimezoneOffset }) =>
    (dispatch, getState) => {
        saveServerTimezone(serverTimezoneOffset);

        dispatch({
            type: SET_SERVER_TIMEZONE_OFFSET,
            serverTimezoneOffset,
        });
    };
