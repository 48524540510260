import { memo } from "react";

import DashboardHeader from "./DashboardHeader";

import { ComponentModelDashboardViewsProps } from "pages/ManageProject/ProjectionsContent/ModelDashboardNew/types";

const ComponentModelDashboardEmpty = memo(({ idClient, viewIndex, model, projectName }: ComponentModelDashboardViewsProps) => {
    return (
        <div className="component-model-dashboard-empty">
            <div className="component-model-dashboard-empty__header-container">
                <DashboardHeader idClient={idClient} viewIndex={viewIndex} model={model} projectName={projectName} />
            </div>
        </div>
    );
});

export default ComponentModelDashboardEmpty;
