import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import Input from "components/ui/Input";
import ActionLabel from "components/ui/Label/ActionLabel";

import { openWindowCombinedModelReport, openWindowMeasureLevelReport } from "utils/window";

import { CombinationProps } from "./types";

export const renderFilters = (filters: any, updateFilter: any) => {
    return (
        <div className="list-search-headers-row">
            <div className="column-filter column-combination-name filter left right">
                <Input
                    placeholder="Search"
                    icon="search"
                    inputTableFilter
                    value={filters.projectName}
                    onChange={(event) => updateFilter("combinationName")(event)}
                />
            </div>
        </div>
    );
};

const Combination = memo(({ idClient, idProject, idCombination, combinationName, projectName, reportName }: CombinationProps) => {
    const dispatch = useDispatch();

    const onCombinationNameClick = useCallback(() => {
        switch (reportName) {
            case "project-level":
                dispatch(
                    openWindowCombinedModelReport({
                        idClient,
                        idProject,
                        filterEntityId: idCombination,
                        projectName,
                        combinationName,
                    })
                );

                break;

            case "measure-level":
                dispatch(
                    openWindowMeasureLevelReport({
                        idClient,
                        idProject,
                        filterEntityId: idCombination,
                        projectName,
                        combinationName,
                    })
                );

                break;

            default:
        }
    }, [idClient, idProject, idCombination, projectName, combinationName, reportName, dispatch]);

    return (
        <div key={`combination-${idCombination}`} className="list-item-row">
            <div className="item-value column-combination-name">
                <ActionLabel
                    title={`ID: ${idCombination} | Open ${reportName === "project-level" ? "Project-level" : "Measure Summary"} Report`}
                    onClick={onCombinationNameClick}
                >
                    {combinationName}
                </ActionLabel>
            </div>
        </div>
    );
});

export default Combination;
