import { openModalDialog } from "layouts/Modal/utils";

import ResetQrCode from "./ResetQrCode";

import { ResetQrCodeModalProps } from "./types";

export const openModalDialogResetQrCode =
    ({ userNumber, username }: ResetQrCodeModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Reset QR Code",
                component: <ResetQrCode userNumber={userNumber} username={username} />,
            })
        );
    };
