import { isNil, forEach, set } from "lodash";
import { memo, useState, useCallback, useMemo } from "react";

import { modalOpen, modalClose } from "store/modal/actions";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import Checkbox from "components/ui/Input/Checkbox";
import Input from "components/ui/Input";
import NumericInput from "components/ui/Input/NumericInput";
import Radio from "components/ui/Input/Radio";
import StatusMsg from "components/ui/StatusMsg";
import Separator from "components/ui/Separator";

import { isNullOrWhitespace } from "utils/string";

import "./style.scss";

const seasons = ["Summer", "Winter"];

export const openModalDialogAvoidedCostsBulkEdit =
    ({ yearStart, yearEnd, avoidedCostSectorItems, activeItem, avoidedCostTypeItems, costType, onSave, onCancel }) =>
    (dispatch) => {
        const title = "Bulk edit";

        dispatch(
            modalOpen({
                modalType: "WAITING_MODAL",
                modalProps: {
                    title,
                },
            })
        );

        const selectedItems = Object.values(avoidedCostTypeItems.current[costType]).filter((p) => p.selected);
        const selectedTypes = { COST: selectedItems[0].COST, UOM: selectedItems[0].UOM }; // selected items will always have the same cost and uom type

        const renderModal = (data) => {
            const ModalContent = memo((data) => {
                const [yearType, setYearType] = useState("yearSingle");
                const [cost, setCost] = useState("");
                const [costCheck, setCostCheck] = useState();
                const [lineLoss, setLineLoss] = useState("");
                const [lineLossCheck, setLineLossCheck] = useState();
                const [peakSeason, setPeakSeason] = useState("Summer");
                const [seasonCheck, setSeasonCheck] = useState();
                const [fromYear, setFromYear] = useState(yearStart);
                const [toYear, setToYear] = useState(yearEnd);
                const [singleYear, setSingleYear] = useState(yearStart);

                const [dataIsValid, setDataIsValid] = useState(true);

                const seasonItems = useMemo(() => {
                    return seasons.map((season) => ({ label: season, value: season }));
                }, []);

                const outsideRange = useCallback((year) => {
                    return (
                        isNil(year) ||
                        isNil(yearEnd) ||
                        isNil(yearStart) ||
                        parseInt(year) > parseInt(yearEnd) ||
                        parseInt(year) < parseInt(yearStart)
                    );
                }, []);

                const yearOutsideRange = useCallback(
                    (years = []) => {
                        return years.some(outsideRange);
                    },
                    [outsideRange]
                );

                const onRadioChange = useCallback(
                    (event, handler) => {
                        if (yearType === "yearRange") {
                            yearOutsideRange([toYear]) && setToYear(yearEnd);
                            yearOutsideRange([fromYear]) && setFromYear(yearStart);
                        } else if (yearType === "yearSingle") {
                            yearOutsideRange([singleYear]) && setSingleYear(yearStart);
                        }

                        handler(event.target.value);
                    },
                    [yearOutsideRange, yearType, toYear, fromYear, singleYear]
                );

                const onValueChange = useCallback((value, handler) => {
                    handler(value);
                }, []);

                const onBlurChangeCostValue = (cost) => {
                    setCost(parseFloat(cost).toFixed(2));
                };

                const onCheckboxChange = useCallback((event, handler) => {
                    handler(event.target.checked);
                }, []);

                const onClickCancel = useCallback(() => {
                    dispatch(modalClose());
                    onCancel && onCancel();
                }, []);

                const onClickSave = useCallback(() => {
                    if (
                        (yearType === "yearSingle" && yearOutsideRange([parseInt(singleYear)])) ||
                        (yearType === "yearRange" && yearOutsideRange([parseInt(toYear), parseInt(fromYear)])) ||
                        (costCheck && isNullOrWhitespace(cost)) ||
                        (lineLossCheck && isNullOrWhitespace(lineLoss))
                    ) {
                        setDataIsValid(false);
                        return;
                    }
                    if (seasonCheck && peakSeason) {
                        avoidedCostSectorItems.current[activeItem].PEAK_SEASON = peakSeason;
                    }
                    if (lineLossCheck && lineLoss) {
                        avoidedCostSectorItems.current[activeItem].LINE_LOSS = lineLoss;
                    }

                    if (yearType === "yearSingle") {
                        forEach(avoidedCostTypeItems.current[costType], function (obj) {
                            if (costCheck && obj.selected && cost) {
                                set(obj, singleYear, cost);
                            }
                        });
                    } else if (yearType === "yearRange") {
                        for (let i = fromYear; i <= toYear; i++) {
                            forEach(avoidedCostTypeItems.current[costType], function (obj) {
                                if (costCheck && obj.selected && cost) {
                                    set(obj, i, cost);
                                }
                            });
                        }
                    }

                    dispatch(modalClose());
                    (seasonCheck || lineLossCheck || costCheck) && onSave && onSave();
                }, [
                    seasonCheck,
                    peakSeason,
                    cost,
                    costCheck,
                    lineLoss,
                    lineLossCheck,
                    singleYear,
                    yearType,
                    fromYear,
                    toYear,
                    yearOutsideRange,
                ]);

                return (
                    <>
                        <div className="flex-column modal-padding with-scroll">
                            <div className="flex-column">
                                <StatusMsg
                                    icon="info-empty"
                                    message={`${selectedItems.length} ${
                                        selectedItems.length === 1 ? "item" : "items"
                                    } will be changed by this bulk edit`}
                                />
                                <FieldGroup>
                                    <FieldGroup label="Values">
                                        <div className="flex-row align-center">
                                            <Checkbox
                                                id="costCheck"
                                                label={selectedTypes.COST}
                                                checked={costCheck || false}
                                                onChange={(event, handler = setCostCheck) => onCheckboxChange(event, handler)}
                                            />
                                            <Input
                                                className="flex-margin"
                                                type="number"
                                                value={cost}
                                                error={costCheck && isNullOrWhitespace(cost)}
                                                onBlur={() => onBlurChangeCostValue(cost)}
                                                onChange={(event, handler = setCost) => onValueChange(event.target.value, handler)}
                                            />
                                            <span className="ac-measure-label">{selectedTypes.UOM}</span>
                                        </div>
                                        {dataIsValid === false && isNullOrWhitespace(cost) && (
                                            <div className="ac-status-box">
                                                <div className="status-msg error-msg with-icon warning-report-problem-empty msg-field-status msg-visible">
                                                    You should enter a value in the field
                                                </div>
                                            </div>
                                        )}
                                    </FieldGroup>
                                    <FieldGroup label="Timeframe">
                                        <div className="flex-row flex-one-in-row align-center">
                                            <Radio
                                                id="yearRange"
                                                name="year"
                                                label="Year"
                                                value="yearRange"
                                                checked={yearType === "yearRange"}
                                                onChange={(e, handler = setYearType) => onRadioChange(e, handler)}
                                            />
                                            <NumericInput
                                                className="flex-margin"
                                                value={fromYear}
                                                min={yearStart}
                                                max={yearEnd}
                                                autoDisableArrows
                                                error={yearOutsideRange([fromYear])}
                                                onChange={(event, handler = setFromYear) => onValueChange(event.target.value, handler)}
                                            />
                                            <span className="ac-measure-label">to</span>
                                            <NumericInput
                                                className="flex-margin"
                                                value={toYear}
                                                min={yearStart}
                                                max={yearEnd}
                                                autoDisableArrows
                                                error={yearOutsideRange([toYear])}
                                                onChange={(event, handler = setToYear) => onValueChange(event.target.value, handler)}
                                            />
                                        </div>
                                        <div className="flex-row flex-one-in-row align-center">
                                            <Radio
                                                id="yearSingle"
                                                name="year"
                                                label="Year"
                                                value="yearSingle"
                                                checked={yearType === "yearSingle"}
                                                onChange={(e, handler = setYearType) => onRadioChange(e, handler)}
                                            />
                                            <NumericInput
                                                className="flex-margin"
                                                value={singleYear}
                                                min={yearStart}
                                                max={yearEnd}
                                                autoDisableArrows
                                                error={yearOutsideRange([singleYear])}
                                                onChange={(event, handler = setSingleYear) => onValueChange(event.target.value, handler)}
                                            />
                                        </div>
                                        {dataIsValid === false &&
                                            ((yearType === "yearSingle" && yearOutsideRange([parseInt(singleYear)])) ||
                                                (yearType === "yearRange" && yearOutsideRange([parseInt(toYear), parseInt(fromYear)]))) && (
                                                <div className="ac-status-box">
                                                    <div className="status-msg error-msg with-icon warning-report-problem-empty msg-field-status msg-visible">
                                                        Year should be within the forecast period
                                                    </div>
                                                </div>
                                            )}
                                    </FieldGroup>
                                </FieldGroup>
                                <Separator line />
                                <FieldGroup>
                                    <div>
                                        <div className="flex-row flex-one-in-row align-center">
                                            <Checkbox
                                                id="lineLoss"
                                                label="Line Loss Percentage"
                                                checked={lineLossCheck || false}
                                                onChange={(event, handler = setLineLossCheck) => onCheckboxChange(event, handler)}
                                            />
                                            <Input
                                                className="flex-margin"
                                                type="number"
                                                value={lineLoss}
                                                error={lineLossCheck && isNullOrWhitespace(lineLoss)}
                                                onChange={(event, handler = setLineLoss) => onValueChange(event.target.value, handler)}
                                            />
                                            <span className="ac-measure-label">%</span>
                                        </div>
                                        {dataIsValid === false && lineLossCheck && isNullOrWhitespace(lineLoss) && (
                                            <div className="ac-status-box">
                                                <div className="status-msg error-msg with-icon warning-report-problem-empty msg-field-status msg-visible">
                                                    You should enter a value in the field
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className="flex-row flex-one-in-row align-center">
                                            <Checkbox
                                                id="peakSeason"
                                                label="Peak Season"
                                                checked={seasonCheck || false}
                                                onChange={(event, handler = setSeasonCheck) => onCheckboxChange(event, handler)}
                                            />
                                            <Dropdown
                                                className="flex-margin ac-peak-season"
                                                value={peakSeason}
                                                items={seasonItems}
                                                onChange={(value, handler = setPeakSeason) => onValueChange(value, handler)}
                                            />
                                        </div>
                                    </div>
                                </FieldGroup>
                            </div>
                        </div>
                        <ButtonGroup className="justify-end modal-actions">
                            <Button primary onClick={onClickSave}>
                                Save Values
                            </Button>
                            <Button onClick={onClickCancel}>Cancel</Button>
                        </ButtonGroup>
                    </>
                );
            });

            dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title,
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        children: <ModalContent data={data} />,
                        withScroll: true,
                        noFooter: true,
                    },
                })
            );
        };

        renderModal();
    };
