import ClassNames from "classnames";

import { IconSvgProps } from "./types";

import "./style.scss";

const IconSvg = (props: IconSvgProps) => {
    const classNames = ClassNames("flex-row align-center justify-center icon-custom", props.icon, {
        disabled: props.disabled,
        "icon-small": props.iconSmall,
        "icon-medium": props.iconMedium,
        "icon-big": props.iconBig,
        clickable: props.clickable,
    });

    return (
        <div data-testid={props.dataTestId} className={classNames} title={props.title} onClick={props.disabled ? undefined : props.onClick}>
            <svg style={{ fill: props.color }} viewBox={props.viewBox} xmlns="http://www.w3.org/2000/svg">
                <path d={props.path} />
            </svg>
        </div>
    );
};

export default IconSvg;
