import { find } from "lodash";
import { memo, useMemo } from "react";

import { useMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";

import IconLoading from "components/ui/Icons/IconLoading";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";

import PowerBI from "pages/PowerBI";

import { useCalculationResultModelLog } from "utils/useCalculationResult";

import { UtilityForecastProps } from "pages/ManageProject/ProjectionsContent/ModelDashboard/DashboardDefault/UtilityForecastPanel/types";

import "./style.scss";

const UtilityForecast = memo(({ idClient, idProject, idInputLog }: UtilityForecastProps) => {
    const reportId = process.env.REACT_APP_POWERBI_VLM_MODEL_FORECASTS_COMPARISON_SNAPSHOT_REPORT_ID;

    const [modelInputs, isLoadingModelInputs] = useMeasureInputs({ idInputLog });

    const [logItem, isLoadingLogItem] = useCalculationResultModelLog({ idProject, idInputLog });

    const forecastInput = useMemo(() => {
        return find(modelInputs?.extrainputstables, (input) => input?.tableName === "F_UTILITY_FORECAST");
    }, [modelInputs]);

    let errorMessage = "";

    if ((forecastInput?.rowCount === 0 || forecastInput?.rowCount === undefined) && logItem?.results === undefined) {
        errorMessage = "It was not possible to calculate the Baseline Forecast and Utility Forecast because data is missing.";
    } else if (logItem?.results === undefined) {
        errorMessage = "It was not possible to calculate Baseline Forecast because data is missing.";
    } else if (forecastInput?.rowCount === 0 || forecastInput?.rowCount === undefined) {
        errorMessage = "It was not possible to calculate the Utility Forecast because data is missing.";
    }

    return (
        <>
            {isLoadingModelInputs || isLoadingLogItem ? (
                <IconLoading />
            ) : (
                <div className="flex-column flex-one-in-column utility-forecast">
                    {errorMessage && <ErrorMsg icon="alert-error-empty" message={errorMessage} />}
                    {(forecastInput?.rowCount > 0 || logItem?.results !== undefined) && reportId && (
                        <PowerBI
                            idClient={idClient}
                            idProject={idProject}
                            idInputLog={idInputLog}
                            reportId={reportId}
                            filterEntityId={idInputLog} // filter by Model ID
                            pageName="ReportSection"
                            hideActions
                        />
                    )}
                </div>
            )}
        </>
    );
});

export default UtilityForecast;
