import { isEmpty } from "lodash";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { windowActivate } from "store/window/actions";

import WindowTab from "./WindowTab";

import { useViews } from "utils/useViews";
import { isDevEnvironment, isVLMLoginPage } from "utils/constants";
import { windowContainerTypes, openWindowDeveloperPage } from "utils/window";

import { View } from "store/window/types";

import "./style.scss";

const WindowTabs = memo((props: WindowTabsProps) => {
    const { containerName, onClose, onActivate, onSync } = props;

    const dispatch = useDispatch();

    const views = useViews({ containerName });

    const tabs = views.filter((view: View) => view.showTabs !== false);

    const onVisionClick = useCallback(() => {
        dispatch(
            windowActivate({
                containerName: windowContainerTypes.Root,
                name: "Home",
            })
        );
    }, [dispatch]);

    const onVisionDoubleClick = useCallback(() => {
        dispatch(openWindowDeveloperPage());
    }, [dispatch]);

    return !isEmpty(tabs) ? (
        <div className="window-tabs">
            <div className="flex-row align-end fill-height window-tabs__container">
                <div
                    className="window-tabs__vision-logo"
                    onClick={onVisionClick}
                    onDoubleClick={isDevEnvironment && !isVLMLoginPage ? onVisionDoubleClick : undefined}
                />
                <div className="flex-row align-end fill-height with-scroll window-tabs__tabs">
                    {tabs.map((view: View) => (
                        <WindowTab
                            key={view.name}
                            containerName={containerName}
                            name={view.name}
                            active={view.active}
                            close={view.close}
                            splitView={view.splitView}
                            onActivate={onActivate}
                            onClose={onClose}
                            onSync={onSync}
                        />
                    ))}
                </div>
            </div>
        </div>
    ) : null;
});

interface WindowTabsProps {
    /**
     * Type of View's container.
     */
    containerName: string;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that activates a View.
     */
    onActivate: (name: string) => void;

    /**
     * Function that enables/disables
     * sync scrolling for Split View.
     */
    onSync: (name: string) => void;
}

export default WindowTabs;
