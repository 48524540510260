import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { modelActions, ackChangeModel } from "store/resources/actions/projectInput/projectInputActions";
import { selectProjection } from "store/projections/actions";

import UserName from "components/ui/UserName";
import Button from "components/ui/Button";

import { projections } from "utils/constants";
import { toLocaleDateTime } from "utils/dateTime";

const ModelNotification = memo(
    ({ viewIndex, idProject, idInputLog, datasetName, status, userNumber, lastModified }: ModelNotificationProps) => {
        const dispatch = useDispatch();

        const isDeleted = status === modelActions.DELETED;

        const action = {
            [modelActions.DELETED]: "deleted permanently",
            [modelActions.WASTED]: "moved to Trash Bin",
            [modelActions.RESTORED]: "restored from Trash Bin",
        }[status];

        const onConfirmClick = useCallback(() => {
            dispatch(ackChangeModel({ idProject, idInputLog }));

            if (isDeleted) {
                dispatch(selectProjection({ viewIndex, idProjectionView: projections.PROJECT_DASHBOARD, idProject }));
            }
        }, [viewIndex, idProject, idInputLog, isDeleted, dispatch]);

        return (
            <>
                <div className="flex-column modal-padding">
                    <strong>{datasetName}</strong>
                    {` has been ${action} by `}
                    {/* @ts-ignore - remove when UserName is refactored to TypeScript */}
                    <UserName userNumber={userNumber} />
                    {` at ${toLocaleDateTime(lastModified)}`}
                </div>
                <div className="flex-row justify-end modal-actions">
                    <Button primary onClick={onConfirmClick}>
                        OK
                    </Button>
                </div>
            </>
        );
    }
);

interface ModelNotificationProps {
    /**
     * View index:
     *  - 0: main view (or left view)
     *  - 1: right view
     */
    viewIndex: number;

    /**
     * ID of Project.
     */
    idProject: number;

    /**
     * ID of Model.
     */
    idInputLog: number;

    /**
     * Name of the Model.
     */
    datasetName: string;

    /**
     * Status of the Model.
     */
    status: any;

    /**
     * Number of the user.
     */
    userNumber: string;

    /**
     * Model last modification date.
     */
    lastModified: string;
}

export default ModelNotification;
