import ClassNames from "classnames";

import { DropdownListProps } from "components/ui/Dropdown/types";

import "./style.scss";

const DropdownList = (props: DropdownListProps) => {
    const { popperElement, popperStyle, children, ...rest } = props;

    const dropdownListClassNames = ClassNames("fill-width dropdown-list", {
        "top-placement": rest["data-popper-placement"]?.includes("top"),
        "bottom-placement": rest["data-popper-placement"]?.includes("bottom"),
    });

    return (
        <div ref={popperElement} className={dropdownListClassNames} style={popperStyle} {...rest}>
            {children}
        </div>
    );
};

export default DropdownList;
