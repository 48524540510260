import { SIDENAV_OPEN, SIDENAV_CLOSE, SIDENAV_CHANGE } from "store/actionTypes";

const initialState = {};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SIDENAV_OPEN:
            state = {
                ...state,
                [action.id]: {
                    ...action.props,
                },
            };

            break;

        case SIDENAV_CLOSE:
            const { [action.id]: _remove, ...updatedState } = state;

            state = {
                ...updatedState,
            };

            break;

        case SIDENAV_CHANGE:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    size: action.size,
                    className: action.className,
                },
            };

            break;

        default:
            break;
    }

    return state;
};
