import { memo, useCallback, useMemo, useState } from "react";

import { IdsTabs, IdsTabItem, IdsTabPanel } from "@emergn-infinity/ids-react";

import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import IconLoading from "components/ui/Icons/IconLoading";

import NoteView from "./NoteView";

import { getNoteTabs } from "./utils";

import { hasExternalUserRole } from "utils/user";

import { ModelNotesProps } from "./types";

import "./style.scss";

/**
 * Model notes are implemented in the following panels:
 *  - Model Log (can also be accessed from Project Log)
 *  - Model Inputs
 *
 * Model notes have two types:
 *  - "user-note" - adds a note as a separate entry in Model Log
 *  - "system-entry" - adds a note to an existing entry in Model Log/Model Inputs
 *
 * With this component user can do the following:
 *  - add a new note (possible to every user)
 *  - edit note (if user is the author of the note)
 *  - delete note (if user is the author of the note)
 */
const ModelNotes = memo(
    ({
        idProject,
        idInputLog,
        idModel,
        idStudyLog,
        idDataImportLog,
        notes,
        noteType,
        variant,
        isLoadingNotes,

        onClose,
    }: ModelNotesProps) => {
        const noteTabs = getNoteTabs(hasExternalUserRole());

        const [activeTab, setActiveTab] = useState(noteTabs[0].id);

        const isPublic = useMemo(() => activeTab === noteTabs[0].id, [noteTabs, activeTab]); // client notes are public notes (accessible by everyone)

        const onTabClick = useCallback((tabId) => {
            setActiveTab(tabId);
        }, []);

        return (
            <SideNavContent className="model-notes">
                <SideNavHeader title="Notes" onClose={onClose} />
                <SideNavBody className="fill-height">
                    {isLoadingNotes ? (
                        <IconLoading />
                    ) : (
                        // IdsTabs
                        <IdsTabs customClasses="fill-height">
                            {!hasExternalUserRole() &&
                                noteTabs.map((tab) => (
                                    // IdsTabItem
                                    <IdsTabItem
                                        key={`${tab.id}-tab`}
                                        className="model-notes__tab-item"
                                        idValue={tab.id}
                                        slot="header"
                                        label={tab.label1}
                                        isActive={activeTab === tab.id}
                                        onClick={() => onTabClick(tab.id)}
                                    />
                                ))}

                            {noteTabs.map((tab) => (
                                // IdsTabPanel
                                <IdsTabPanel
                                    key={`${tab.id}-panel`}
                                    className="fill-height model-notes__tab-panel"
                                    idValue={tab.id}
                                    slot="panel"
                                    ariaLabelledBy={tab.label2}
                                >
                                    <NoteView
                                        idProject={idProject}
                                        idInputLog={idInputLog}
                                        idModel={idModel}
                                        idStudyLog={idStudyLog}
                                        idDataImportLog={idDataImportLog}
                                        notes={notes}
                                        noteType={noteType}
                                        isPublic={isPublic}
                                        variant={variant}
                                        label={tab.label1}
                                        onClose={onClose}
                                    />
                                </IdsTabPanel>
                            ))}
                        </IdsTabs>
                    )}
                </SideNavBody>
            </SideNavContent>
        );
    }
);

export default ModelNotes;
