import { memo } from "react";
import * as React from "react";
import cn from "classnames";

import "./style.scss";

const IconMore = memo((props: IconMoreProps) => {
    const { clickable, onClick } = props;

    const isClickable = clickable || onClick;

    return (
        <div className={cn("icon-more", { clickable: isClickable })} onClick={onClick}>
            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
        </div>
    );
});

interface IconMoreProps {
    /**
     * Display icon as clickable.
     */
    clickable?: boolean;

    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default IconMore;
