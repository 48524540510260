import { memo, useEffect } from "react";

import ImportData from "layouts/common/ImportData";

import Separator from "components/ui/Separator";

import HowToImport from "pages/ManageProject/ProjectionsContent/common/HowToImport";

import hj from "utils/hotJar";

import { ComponentModelSetupProps } from "./types";

import "./style.scss";

const ComponentModelSetup = memo(({ viewIndex, idClient, idProject }: ComponentModelSetupProps) => {
    useEffect(() => {
        hj("stateChange", "ComponentModelSetup");
    }, []);

    return (
        <div className="component-model-setup">
            <h3>Import data for New Component Model</h3>
            <HowToImport />
            <Separator />
            <ImportData viewIndex={viewIndex} idClient={idClient} idProject={idProject} isComponentModel />
        </div>
    );
});

export default ComponentModelSetup;
