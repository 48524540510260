import { memo } from "react";

import PowerBI from "pages/PowerBI";

import { PBIReportProps } from "./types";

import "./style.scss";

const PBIReport = memo(({ id, hideActions }: PBIReportProps) => {
    return (
        <div className="flex-column fill-height pbi-report">
            <PowerBI reportId={id} filterEntityId={0} reportType={1} hideActions={hideActions} showRefresh={false} showPrint={false} />
        </div>
    );
});

export default PBIReport;
