import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useProjectList } from "store/resources/actions/project/projectActions";
import { useProjectFileSystem } from "store/resources/actions/projectFiles/projectFilesActions";
import { updateHasProjectFinalReport } from "store/resources/actions/projectFiles/projectFinalReportExistsActions";

import IconLoading from "components/ui/Icons/IconLoading";

import ProjectDashboardFiles from "./ProjectDashboardFiles";
import ProjectDashboardDefault from "./ProjectDashboardDefault";
import ProjectDashboardClient from "./ProjectDashboardClient";

import { useUserRights } from "utils/useUserRights";
import hj from "utils/hotJar";
import { USER_ROLES } from "utils/user/defines";

import { ProjectDashboardProps } from "./types";
import { Project } from "store/resources/actions/project/types";

/**
 * PROJECT DASHBOARD VIEW.
 *
 * View where user can configure Project settings.
 */
const ProjectDashboard = memo(({ viewIndex, project, clientName }: ProjectDashboardProps) => {
    const { idClient, idProject } = project;

    const dispatch = useDispatch();

    const userRights = useUserRights();

    const [projects = [], isLoadingProjects] = useProjectList({ idClient });

    const [projectFileSystem, isLoadingProjectFileSystem] = useProjectFileSystem({ idProject });

    const [displayFiles, setDisplayFiles] = useState(false);

    const currentProject: Project = projects.find((p: Project) => p.idProject === idProject) || project;

    useEffect(() => {
        hj("stateChange", "ProjectDashboard");
    }, []);

    useEffect(() => {
        if (!isEmpty(projectFileSystem) && !isLoadingProjectFileSystem) {
            dispatch(updateHasProjectFinalReport({ idProject }));
        }
    }, [idClient, idProject, projectFileSystem, isLoadingProjectFileSystem, dispatch]);

    const onDisplayFiles = useCallback(() => {
        setDisplayFiles(true);
    }, []);

    const onReturn = useCallback(() => {
        setDisplayFiles(false);
    }, []);

    if (isLoadingProjects) {
        return (
            <div className="flex-column flex-one align-center justify-center">
                <IconLoading />
            </div>
        );
    } else if (displayFiles) {
        return (
            <ProjectDashboardFiles
                project={currentProject}
                clientName={clientName}
                displayFiles={displayFiles}
                onDisplayFiles={onDisplayFiles}
                onReturn={onReturn}
            />
        );
    }

    switch (userRights) {
        case USER_ROLES.ANALYST:
        case USER_ROLES.MANAGER:
        case USER_ROLES.ADVANCED_CLIENT:
            return (
                <ProjectDashboardDefault
                    viewIndex={viewIndex}
                    project={currentProject}
                    clientName={clientName}
                    displayFiles={displayFiles}
                    onDisplayFiles={onDisplayFiles}
                    onReturn={onReturn}
                />
            );

        case USER_ROLES.BASIC_CLIENT:
        case USER_ROLES.BASIC_PRIMARY_CONTACT:
            return (
                <ProjectDashboardClient
                    viewIndex={viewIndex}
                    project={currentProject}
                    clientName={clientName}
                    displayFiles={displayFiles}
                    onDisplayFiles={onDisplayFiles}
                    onReturn={onReturn}
                />
            );

        default:
            return null;
    }
});

export default ProjectDashboard;
