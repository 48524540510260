import { memo, useCallback, useRef } from "react";

import { useStandaloneReportFile } from "store/resources/actions/standaloneReport/standaloneReportFilesActions";

import IconLoading from "components/ui/Icons/IconLoading";
import IconWithLabel from "components/ui/Icons/IconWithLabel";

import { HTMLReportProps } from "./types";

import "./style.scss";

const HTMLReport = memo(({ idProject, idReport, fileName, showPresentationMode }: HTMLReportProps) => {
    const reportRef = useRef<HTMLIFrameElement>(null);

    const [url] = useStandaloneReportFile({ idProject, idReport, fileName });

    const onPresentationModeClick = useCallback(() => {
        reportRef.current?.requestFullscreen();
    }, []);

    return !url ? (
        <div className="flex-column flex-one-in-column align-center justify-center">
            <IconLoading />
        </div>
    ) : (
        <div className="flex-column flex-one-in-column html-report">
            {showPresentationMode && (
                <div className="flex-row justify-end">
                    <IconWithLabel icon="fullscreen" onClick={onPresentationModeClick}>
                        Presentation Mode
                    </IconWithLabel>
                </div>
            )}
            <div className="flex-column fill-height report-container">
                <iframe ref={reportRef} src={url} title="HTML Report" />
            </div>
        </div>
    );
});

export default HTMLReport;
