import { get, isNumber } from "lodash";
import { batch } from "react-redux";

import { SELECT_PROJECTION, HUB_EVENT_RECEIVE, USER_DISCONNECTED } from "store/actionTypes";

import {
    updateActiveViewsModel,
    clearActiveViewsModelAll,
    deleteActiveViewsModel,
} from "store/resources/actions/activeViews/activeViewModelActions";
import {
    createActiveViewsCustomReport,
    clearActiveViewsCustomReportAll,
    deleteActiveViewsCustomReport,
} from "store/resources/actions/activeViews/activeViewCustomReportActions";
import { clearActiveViewsAvoidedCostsAll } from "store/resources/actions/activeViews/activeViewAvoidedCostsActions";

import { projections } from "utils/constants";

export const projectionsMiddleware =
    // @ts-ignore
    ({ dispatch, getState }) => {
        return (next: any) => (action: any) => {
            switch (action.type) {
                case SELECT_PROJECTION:
                    {
                        const { viewIndex, idProjectionView, idProject, key } = action.selection;

                        const idProjection = action.selection[key]; // idInputLog, idReport

                        const projectionsPreviousState = get(getState(), `projections.selection.${idProject}`); // previous Active View state

                        const previousIdProjectionView = projectionsPreviousState?.[viewIndex]?.idProjectionView; // previous Active View - either Model view or Standalone Report view
                        const previousViewId = projectionsPreviousState?.[viewIndex]?.viewId;
                        let previousIdProjection;

                        // If previous Active View was Model view, then previousidProjection
                        // is idInputLog
                        if (previousIdProjectionView === projections.MODEL_DASHBOARD) {
                            previousIdProjection = projectionsPreviousState?.idInputLog;
                        }
                        // If previous Active View was Standalone Report, then previousIdProjection
                        // is idReport
                        else if (previousIdProjectionView === projections.STANDALONE_REPORT_DASHBOARD) {
                            previousIdProjection = projectionsPreviousState?.idReport;
                        }

                        if (isNumber(idProjection)) {
                            // If current Active View is Model view, set it as an Active View
                            if (idProjectionView === projections.MODEL_DASHBOARD) {
                                dispatch(
                                    updateActiveViewsModel({
                                        viewIndex,
                                        idProject,
                                        idInputLog: idProjection,
                                        previousIdInputLog: previousIdProjection,
                                        previousViewId,
                                    })
                                );
                            }
                            // If current Active View is Standalone Report view, set it as an Active View
                            else if (idProjectionView === projections.STANDALONE_REPORT_DASHBOARD) {
                                dispatch(
                                    createActiveViewsCustomReport({
                                        viewIndex,
                                        idProject,
                                        idReport: idProjection,
                                    })
                                );
                            }
                        }

                        // TODO: Removing previousViewId results in API error when switching views
                        // too quickly. Need to think of a solution, otherwise Active Views might
                        // not get deleted when switching views too quickly
                        if (isNumber(previousViewId) && isNumber(previousIdProjection)) {
                            // If there is a previous Active View and it was Model view, delete it
                            if (previousIdProjectionView === projections.MODEL_DASHBOARD) {
                                dispatch(deleteActiveViewsModel({ idProject, idInputLog: previousIdProjection, previousViewId }));
                            }
                            // If there is a previous Active VIew and it was Standalone Report view, delete it
                            else if (previousIdProjectionView === projections.STANDALONE_REPORT_DASHBOARD) {
                                dispatch(deleteActiveViewsCustomReport({ idProject, idReport: previousIdProjection, previousViewId }));
                            }
                        }
                    }

                    break;

                case HUB_EVENT_RECEIVE:
                    if (action.key === USER_DISCONNECTED) {
                        batch(() => {
                            dispatch(clearActiveViewsModelAll());
                            dispatch(clearActiveViewsCustomReportAll());
                            dispatch(clearActiveViewsAvoidedCostsAll());
                        });
                    }

                    break;

                default:
                    break;
            }

            return next(action);
        };
    };
