import { memo, useCallback, useState } from "react";

import ModelStudyCase from "./ModelStudyCase";

import DashboardPanel from "components/ui/Dashboard";

import StudyCasePanelHeader from "pages/ManageProject/ProjectionsContent/common/StudyCasePanelHeader";

import { modelReadOnlyEnabled, actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { MODEL_STUDY_CASE } from "utils/constants";
import { USER_ACTIONS } from "utils/user/defines";

import { ModelStudyCasePanelProps } from "./types";

const ModelStudyCasePanel = memo((props: ModelStudyCasePanelProps) => {
    const { idClient, idProject, idInputLog, modelState, required } = props;

    const userRights = useUserRights();

    const [isEditing, setIsEditing] = useState(false);

    const onChangeClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const onCancelClick = useCallback(() => {
        setIsEditing(false);
    }, []);

    const headerAction = (
        <StudyCasePanelHeader
            editing={isEditing}
            readOnly={
                modelReadOnlyEnabled(modelState, required) || !actionAllowed(userRights, USER_ACTIONS.MODEL_STUDY_CASE_GOALS_EDIT, required)
            }
            onChangeClick={onChangeClick}
        />
    );

    return (
        <DashboardPanel
            className="model-study-case-panel"
            title="Model Study Case Goals"
            headerAction={headerAction}
            pageId={MODEL_STUDY_CASE}
        >
            <ModelStudyCase
                idClient={idClient}
                idProject={idProject}
                idInputLog={idInputLog}
                editing={isEditing}
                onEditCancel={onCancelClick}
            />
        </DashboardPanel>
    );
});

export default ModelStudyCasePanel;
