import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";

import ProjectListPanel from "./ProjectListPanel";

import { openModalDialogClientAttributes } from "layouts/Modal/HomeDashboardModal/utils";

import { hasAnyOfPermissions, hasStakeholderUserRole } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";
import { openWindowProjectComparisonReport } from "utils/window";

import { ProjectListContainerProps } from "./types";

import "./style.scss";

const ProjectListContainer = memo(({ selectedClient, user }: ProjectListContainerProps) => {
    const dispatch = useDispatch();

    const onEditClientAttributesClick = useCallback(() => {
        dispatch(openModalDialogClientAttributes({ client: selectedClient }));
    }, [selectedClient, dispatch]);

    const onViewProjectComparisonReportClick = useCallback(() => {
        dispatch(
            openWindowProjectComparisonReport({
                client: selectedClient,
            })
        );
    }, [selectedClient, dispatch]);

    return (
        <div className="flex-column fill-width with-scroll project-list-container">
            <div className="flex-row align-center justify-space-between project-list-container__actions">
                <div className="project-list-container__client-name">
                    Client | <span className="emphasize">{selectedClient.clientName}</span>
                </div>
                <div className="flex-row align-center">
                    <IdsButtonGroup customClasses="client-actions" position="right" spaceBetween="md">
                        {hasAnyOfPermissions([USER_ACTIONS.CLIENT_EDIT]) && (
                            <Button
                                variant="tertiary"
                                padding="md"
                                iconLeft="ui-pencil__edit__create_b_s"
                                iconSize="sm"
                                onClick={onEditClientAttributesClick}
                            >
                                Edit Client Attributes
                            </Button>
                        )}
                        <Button
                            variant={hasStakeholderUserRole(user) ? "primary" : "tertiary"}
                            padding="md"
                            iconLeft="ui-chart_pie__data_usage__circular_diagram_b_s"
                            iconSize="sm"
                            onClick={onViewProjectComparisonReportClick}
                        >
                            View Project Comparison Report
                        </Button>
                    </IdsButtonGroup>
                    <div className="margin-left">
                        <span className="emphasize">Client ID:</span> {selectedClient.id}
                    </div>
                </div>
            </div>
            <ProjectListPanel selectedClient={selectedClient} user={user} />
        </div>
    );
});

export default ProjectListContainer;
