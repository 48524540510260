import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { logout } from "store/login/actions";
import { deleteQrCode } from "store/resources/actions/user/userQrCodeActions";

import Button from "components/ui/Button";

import { ResetQrCodeModalProps } from "layouts/Modal/UserProfileModal/types";

const ResetQrCode = memo(({ userNumber, username, onCancel }: ResetQrCodeModalProps) => {
    const dispatch = useDispatch();

    const onDeleteComplete = useCallback(() => {
        onCancel?.();

        dispatch(logout());
    }, [onCancel, dispatch]);

    const onOkClick = useCallback(() => {
        dispatch(
            deleteQrCode({
                userNumber,
                onComplete: onDeleteComplete,
            })
        );
    }, [userNumber, onDeleteComplete, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding">
                <p>
                    Are you sure you want to reset the QR code for <strong>{username}</strong>?
                </p>
            </div>
            <div className="flex-row justify-end modal-actions">
                <Button primary onClick={onOkClick}>
                    OK
                </Button>
            </div>
        </>
    );
});

export default ResetQrCode;
