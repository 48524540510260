import { useCallback } from "react";
import * as React from "react";
import ClassNames from "classnames";

import IconWrap from "components/ui/Icons";
import Label from "components/ui/Label";
import StatusMsg from "components/ui/StatusMsg";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";

import "./style.scss";

const Input = (props: InputProps) => {
    const { onChange } = props;

    const type = props.type || "text";

    let percentage = "";

    if (props.ghost && props.percent) {
        const percentNumber = ((Number(props.defaultValue) || Number(props.value)) * 100).toFixed(2);
        percentage = `${percentNumber}%`;
    }

    const classNames = ClassNames("input-container", props.className, {
        "fill-width": type === "text",
    });

    const inputClassNames = ClassNames("input-field with-icon", {
        focus: props.focus,
        "read-only": props.readOnly,
        ghost: props.ghost,
        "input-table-filter": props.inputTableFilter,
        error: props.error,
        warning: props.warning,
    });

    const onTelehponeNumberChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const eventValue = event.target.value.replace(/[^\d\-+]/g, ""); // allow only digits, hyphen and plus

            event.target.value = eventValue;

            onChange?.(event);
        },
        [onChange]
    );

    return (
        <div className={classNames}>
            {props.label && <Label required={props.required}>{props.label}</Label>}
            <div className="input-container__wrapper">
                <input
                    ref={props.inputRef}
                    data-testid={props.dataTestId}
                    className={inputClassNames}
                    type={type}
                    id={props.id}
                    name={props.name}
                    autoFocus={props.autoFocus}
                    autoComplete={props.autoComplete || "on"}
                    required={props.required}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    placeholder={props.placeholder}
                    defaultValue={props.defaultValue !== undefined ? percentage || props.defaultValue : undefined}
                    value={props.value !== undefined ? percentage || props.value : undefined}
                    defaultChecked={props.defaultChecked}
                    checked={props.checked}
                    maxLength={props.maxLength}
                    onBlur={props.onBlur}
                    onChange={type === "tel" ? onTelehponeNumberChange : onChange}
                    onKeyDown={props.onKeyDown}
                />
                {props.icon && <IconWrap icon={props.icon} onClick={props.onIconClick} />}
            </div>
            {props.msgText && (props.error ? <ErrorMsg message={props.msgText} /> : <StatusMsg message={props.msgText} />)}
        </div>
    );
};

export interface InputInterface {
    inputRef?: React.RefObject<HTMLInputElement>;

    /**
     * Playwright attribute.
     */
    dataTestId?: string;

    className?: string;

    autoFocus?: boolean;

    required?: boolean;

    disabled?: boolean;

    readOnly?: boolean;

    /**
     * Ghost style for input field.
     */
    ghost?: boolean;

    /**
     * Focus style for input field.
     */
    focus?: boolean;

    /**
     * Error style for input field.
     */
    error?: boolean;

    /**
     * Warning style for input field.
     */
    warning?: boolean;

    /**
     * Label for input field.
     */
    label?: string;

    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

interface InputProps extends InputInterface {
    type?: React.HTMLInputTypeAttribute;

    id?: string;

    name?: string;

    autoComplete?: string;

    /**
     * Search field style for table.
     */
    inputTableFilter?: boolean;

    placeholder?: string;

    defaultValue?: string | number | readonly string[];

    value?: string | number | readonly string[];

    defaultChecked?: boolean;

    checked?: boolean;

    maxLength?: number;

    /**
     * Format value in percentage.
     */
    percent?: boolean;

    /**
     * Name of the icon.
     */
    icon?: string;

    /**
     * Additional information for input field
     * that will be put below the field.
     */
    msgText?: string;

    onBlur?: React.FocusEventHandler<HTMLInputElement>;

    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;

    /**
     * TODO: Only used in Dropdowns.
     *
     * Look into if this prop is needed.
     */
    onIconClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default Input;
