import Separator from "components/ui/Separator";

import { openModalDialog, openModalDialogCustomActions } from "layouts/Modal/utils";

import EditProject from "./EditProject";
import UploadProjectFile from "./UploadProjectFile";
import UploadMarketProfileData from "./UploadMarketProfileData";

import {
    EditProjectProps,
    SetFinalReportErrorParams,
    SetFinalReportWarningParams,
    UploadProjectFileModalProps,
    UploadMarketProfileDataModalProps,
    PublishCombinationWarningParams,
} from "./types";

import "./style.scss";

export const openModalDialogEditProject =
    ({ idClient, idProject, projectName, projectDescription, projectManager, projectTeamMembers }: EditProjectProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Edit Project Information",
                component: (
                    <EditProject
                        idClient={idClient}
                        idProject={idProject}
                        projectName={projectName}
                        projectDescription={projectDescription}
                        projectManager={projectManager}
                        projectTeamMembers={projectTeamMembers}
                    />
                ),
            })
        );
    };

export const openModalDialogSetFinalReportRequiredError =
    ({ projectName }: SetFinalReportErrorParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "No models set as required for project",
                modalIcon: "circle-exclamation-filled",
                error: true,
                text: (
                    <div>
                        To be able to set the project <strong>'{projectName}'</strong> to 'Final Report', you need to define at least one
                        model as required for project and make sure all required models has the status 'Final'.
                    </div>
                ),
                actions: [
                    {
                        children: "Close",
                    },
                ],
            })
        );
    };

export const openModalDialogSetFinalReportModelFinalStatusError =
    ({ projectName }: SetFinalReportErrorParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Project models needs finalization",
                modalIcon: "circle-exclamation-filled",
                error: true,
                text: (
                    <div>
                        To move project <strong>'{projectName}'</strong> to 'Final Report' status, please ensure all the required project
                        models are marked with 'Approved' status for legacy models or 'Final' status for component models.
                    </div>
                ),
                actions: [
                    {
                        children: "Close",
                    },
                ],
            })
        );
    };

export const openModalDialogSetFinalReportWarning =
    ({ projectName, onChange }: SetFinalReportWarningParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Set project to Final Report",
                text: (
                    <>
                        <div>
                            By setting the project <strong>'{projectName}'</strong> to 'Final Report', you will lock the project and all
                            required models to be read only. Please ensure all the deliverables are aligned before you proceed.
                        </div>
                        <Separator />
                        <div>Are you sure you want to set the project to 'Final Report'?</div>
                    </>
                ),
                actions: [
                    {
                        primary: true,
                        children: "Yes, Change Status",
                        onClick: onChange,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };

export const openModalDialogRollbackFinalReportWarning =
    ({ projectName, onChange }: SetFinalReportWarningParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Revert project to In Progress",
                text: (
                    <div className="rollback-final-report-modal">
                        <div>
                            The project <strong>'{projectName}'</strong> is labeled as 'Final Report' now. Changing it to 'In Progress'
                            will:
                        </div>
                        <ul className="rollback-final-report-modal__list-container">
                            <li>
                                Set the project status back to 'In Progress' for clients in <strong>VisionLoadMap</strong>.
                            </li>
                            <li>
                                Allow the project to be edited, but you must ensure all deliverables are aligned if the results change in
                                any way.
                            </li>
                        </ul>
                        <Separator />
                        <div>Proceed with reverting the project to 'In Progress'?</div>
                    </div>
                ),
                actions: [
                    {
                        primary: true,
                        children: "Yes, Change Status",
                        onClick: onChange,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };

export const openModalDialogUploadProjectFile =
    ({ idProject, clientName }: UploadProjectFileModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Upload Supporting Project Files",
                component: <UploadProjectFile idProject={idProject} clientName={clientName} />,
            })
        );
    };

export const openModalDialogUploadMarketProfileData =
    ({ idProject, idProjectUpload, onUploadStart, onUploadComplete }: UploadMarketProfileDataModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Upload Market Profile Data",
                component: (
                    <UploadMarketProfileData
                        idProject={idProject}
                        idProjectUpload={idProjectUpload}
                        onUploadStart={onUploadStart}
                        onUploadComplete={onUploadComplete}
                    />
                ),
            })
        );
    };

export const openModalDialogPublishCombinationWarning =
    ({ onAccept }: PublishCombinationWarningParams) =>
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Publish Report for Client Access",
                text: (
                    <>
                        <div>When the Combined Model report is bookmarked it will be shared with all external client users.</div>
                        <Separator />
                        <div>Are you sure you want to share the report?</div>
                    </>
                ),
                actions: [
                    {
                        primary: true,
                        children: "Yes, Publish Report",
                        onClick: onAccept,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };
