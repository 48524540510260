import ClassNames from "classnames";

import { ButtonGroupProps } from "./types";

import "./style.scss";

const ButtonGroup = (props: ButtonGroupProps) => {
    const classNames = ClassNames("flex-row button-group", props.className);

    return <div className={classNames}>{props.children}</div>;
};

export default ButtonGroup;
