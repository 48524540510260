import { useCallback, useMemo, useState } from "react";
import cn from "classnames";

import Input, { InputInterface } from "components/ui/Input";
import IconWrap from "components/ui/Icons";

import "./style.scss";

const NumericInput = (props: NumericInputProps) => {
    const { min, max, onChange } = props;

    const [focus, setFocus] = useState(props.autoFocus || props.focus || false);

    const parsedValue = parseInt(props.defaultValue || props.value || "");

    const increaseDisabled = useMemo(() => {
        if (props.autoDisableArrows && min && max) {
            if (parsedValue >= parseInt(max)) {
                return true;
            }
        }

        return props.disabled;
    }, [props.disabled, min, max, props.autoDisableArrows, parsedValue]);

    const decreaseDisabled = useMemo(() => {
        if (props.autoDisableArrows && min && max) {
            if (parsedValue <= parseInt(min)) {
                return true;
            }
        }

        return props.disabled;
    }, [props.disabled, min, max, props.autoDisableArrows, parsedValue]);

    const onUp = useCallback(() => {
        setFocus(true);

        let value = parsedValue;

        if (max === undefined || (max !== undefined && value < parseInt(max))) {
            value++;
        }

        onChange?.({
            // @ts-ignore - any ideas how to type this?
            target: {
                value: value.toString(),
            },
        });
    }, [parsedValue, max, onChange]);

    const onDown = useCallback(() => {
        setFocus(true);

        let value = parsedValue;

        if (min === undefined || (min !== undefined && value > parseInt(min))) {
            value--;
        }

        onChange?.({
            // @ts-ignore
            target: {
                value: value.toString(),
            },
        });
    }, [parsedValue, min, onChange]);

    const onBlur = useCallback((event) => {
        // Do not lose focus, if clicked on shevrons
        if (
            !event.relatedTarget?.className.includes("shevron-small-up-expand-less") &&
            !event.relatedTarget?.className.includes("shevron-small-down-expand-more")
        ) {
            setFocus(false);
        }
    }, []);

    return (
        <div className={cn("numeric-input-container", props.className)} tabIndex={0} onBlur={onBlur}>
            <Input
                inputRef={props.inputRef}
                data-testid={props.dataTestId}
                type="number"
                autoFocus={props.autoFocus}
                required={props.required}
                disabled={props.disabled}
                readOnly={props.readOnly}
                ghost={props.ghost}
                focus={focus}
                error={props.error}
                warning={props.warning}
                label={props.label}
                defaultValue={props.defaultValue}
                value={props.value}
                onChange={onChange}
            />
            {!props.ghost && (
                <div
                    className={cn("numeric-input-container__arrows", {
                        disabled: props.disabled,
                        "read-only": props.readOnly,
                        error: props.error || props.warning,
                    })}
                >
                    <div>
                        <IconWrap icon="shevron-small-up-expand-less" disabled={increaseDisabled} noBackground onClick={onUp} />
                    </div>
                    <div className="numeric-input-container__arrows__separator" />
                    <div>
                        <IconWrap icon="shevron-small-down-expand-more" disabled={decreaseDisabled} noBackground onClick={onDown} />
                    </div>
                </div>
            )}
        </div>
    );
};

interface NumericInputProps extends InputInterface {
    /**
     * Minimum allowed value.
     */
    min?: string;

    /**
     * Maximum allowed value.
     */
    max?: string;

    /**
     * Automatically disable arrows when limit is reached.
     */
    autoDisableArrows?: boolean;

    defaultValue?: string;

    value?: string;
}

export default NumericInput;
