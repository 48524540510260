///
/// Project Variables and Model Variables actions
/// resourceName: variablesResourceName
///
import { get } from "lodash";
import { batch } from "react-redux";

import { variablesResourceName } from "store/configureResources";
import { getKey } from "store/utils";

import { useResource } from "store/resources/actions/useResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";

import { clearProjectLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import { removeObjEmptyProps } from "utils/object";

import { Variables } from "./types";
import { VariableParams, UseVariableListParams, UpdateVariablesParams, GetCurrentVariableListParams, GetResourceParams } from "./types";
import { IndexSignature } from "types/types";

export const getCurrentVariableList = ({ idProject, idInputLog, getState }: GetCurrentVariableListParams): Variables[] =>
    get(getState(), `resources.${variablesResourceName}.itemsById[${variablesResourceName}-${idProject}${getKey(idInputLog)}].data.model`);

const getResourceParams = ({ idProject, idInputLog, formItems, updateItems, action, onSuccess, dispatch }: GetResourceParams) => ({
    resourceName: variablesResourceName,
    key: `${variablesResourceName}-${idProject}${getKey(idInputLog)}`,
    query: {
        idProject,
        idInputLog,
        action,
    },
    body: {
        variableInputs: formItems,
    },
    optimisticUpdate: updateItems?.length
        ? {
              value: { model: updateItems },
          }
        : undefined,
    showSuccessNotification: false,
    onSuccess: () => {
        batch(() => {
            dispatch(clearVariableList({ idProject, idInputLog }));
            dispatch(clearProjectLogItems({ idProject }));

            onSuccess?.();
        });
    },
});

export const useVariableList = ({ idProject, idInputLog, transform }: UseVariableListParams) =>
    useResource({
        resourceName: variablesResourceName,
        key: `${variablesResourceName}-${idProject}${getKey(idInputLog)}`,
        query: {
            idProject,
            idInputLog,
        },
        transform,
    });

export const clearVariableList =
    ({ idProject, idInputLog }: VariableParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: variablesResourceName,
                key: `${variablesResourceName}-${idProject}${getKey(idInputLog)}`,
                broadcast: true,
            })
        );

export const updateVariables =
    ({ idProject, idInputLog, values, action, onSuccess }: UpdateVariablesParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const oldItems = getCurrentVariableList({ idProject, idInputLog, getState });
        const modifiedValues: IndexSignature<string> = removeObjEmptyProps(values);
        const formItems: IndexSignature<any> = {};

        for (const key of Object.keys(modifiedValues)) {
            if (Array.isArray(modifiedValues[key])) {
                formItems[key] = JSON.stringify(modifiedValues[key]);
            } else {
                formItems[key] = modifiedValues[key];
            }
        }

        const updateItems = oldItems?.map((u) => {
            return {
                ...u,
                value: modifiedValues[u.name] ?? u.value,
            };
        });

        dispatch(
            updateResource(
                getResourceParams({
                    idProject,
                    idInputLog,
                    formItems,
                    updateItems,
                    action,
                    onSuccess,
                    dispatch,
                })
            )
        );
    };
