import { get } from "lodash";
import cn from "classnames";
import { memo } from "react";
import { useSelector } from "react-redux";

import { useProcessesInProgress } from "store/processes/useProcessStatus";

import FloatModalRoot from "components/ui/Modal/FloatModalRoot";

import DashboardHeader from "./DashboardHeader";
import CalculatedResultsPanel from "./CalculatedResultsPanel";
import ModelLogPanel from "./ModelLogPanel";
import ModelStudyCasePanel from "./ModelStudyCasePanel";
import ModelVariablesPanel from "./ModelVariablesPanel";
import ModelInputsPanel from "./ModelInputsPanel";
import UtilityForecastPanel from "./UtilityForecastPanel";
import ModelNotification from "./ModelNotification";

import { ModelDashboardDefaultProps } from "./types";

import "./style.scss";

const ModelDashboardDefault = memo(({ viewIndex, idClient, model, projectName, models }: ModelDashboardDefaultProps) => {
    const { idProject, idInputLog, name, modelState, required } = model;

    // @ts-ignore
    const modelStatus = useSelector((state) => get(state.projections.status, `${idProject}.${idInputLog}`));

    const processesInProgress = useProcessesInProgress({ idInputLog });

    const modalProps = {
        title: "Model has been changed",
        overlayClassName: "modal-styled",
        className: "modal-sm",
        children: (
            <ModelNotification
                viewIndex={viewIndex}
                idProject={idProject}
                idInputLog={idInputLog}
                datasetName={name}
                status={modelStatus?.status}
                userNumber={modelStatus?.userNumber}
                lastModified={modelStatus?.lastModified}
            />
        ),
        noFooter: true,
        noCrossButton: true,
        withScroll: false,
    };

    return (
        <FloatModalRoot modalId={`modal-model-dashboard-${idProject}-${idInputLog}`} modalType={modelStatus} modalProps={modalProps}>
            <div className="model-dashboard">
                <div className="model-dashboard__header-container">
                    <DashboardHeader idClient={idClient} viewIndex={viewIndex} model={model} />
                </div>
                <div
                    className={cn("model-dashboard__body-container", {
                        "in-progress": processesInProgress.runCalculations,
                    })}
                >
                    <div>
                        <CalculatedResultsPanel idClient={idClient} idProject={idProject} idInputLog={idInputLog} projectInputs={models} />
                    </div>
                    <div>
                        <ModelLogPanel idClient={idClient} idProject={idProject} idInputLog={idInputLog} />
                    </div>
                    <div>
                        <ModelStudyCasePanel
                            idClient={idClient}
                            idProject={idProject}
                            idInputLog={idInputLog}
                            modelState={modelState}
                            required={required}
                        />
                    </div>
                    <div>
                        <ModelVariablesPanel
                            idClient={idClient}
                            idProject={idProject}
                            idInputLog={idInputLog}
                            modelState={modelState}
                            required={required}
                        />
                    </div>
                    <div>
                        <ModelInputsPanel
                            idClient={idClient}
                            idProject={idProject}
                            idInputLog={idInputLog}
                            model={model}
                            projectName={projectName}
                        />
                    </div>
                    <div>
                        <UtilityForecastPanel idClient={idClient} idProject={idProject} idInputLog={idInputLog} />
                    </div>
                </div>
            </div>
        </FloatModalRoot>
    );
});

export default ModelDashboardDefault;
