import { OPEN_AVOIDED_COSTS, CLOSE_AVOIDED_COSTS, SET_AVOIDED_COSTS_VIEW_ID } from "store/actionTypes";

const initialState = {
    selection: {},
};

export const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case OPEN_AVOIDED_COSTS:
            state = {
                ...state,
                selection: {
                    ...state.selection,
                    [action.selection.idInputLog]: action.selection,
                },
            };

            break;

        case CLOSE_AVOIDED_COSTS:
            // @ts-ignore
            const { [action.selection.idInputLog]: _remove, ...selection } = state.selection;

            state = {
                ...state,
                selection,
            };

            break;

        case SET_AVOIDED_COSTS_VIEW_ID:
            state = {
                ...state,
                selection: {
                    ...state.selection,
                    [action.selection.idInputLog]: {
                        // @ts-ignore
                        ...state.selection[action.selection.idInputLog],
                        viewId: action.selection.viewId,
                    },
                },
            };

            break;

        default:
            break;
    }

    return state;
};
