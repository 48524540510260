///
/// Label Variable actions (Project-level)
/// resourceName: labelVariablesResourceName
///
import { get } from "lodash";

import { labelVariablesResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";

import { removeObjEmptyProps } from "utils/object";

import { Variables, VariablesResponseModel } from "./types";
import { IndexSignature } from "types/types";

export const getCurrentLabelVariableList = ({ idProject, getState }: GetCurrentLabelVariableListParams): Variables[] =>
    get(getState(), `resources.${labelVariablesResourceName}.itemsById[${labelVariablesResourceName}-${idProject}].data.model`);

const getResourceParams = ({ idProject, formItems, updateItems, onComplete }: GetResourceParams) => ({
    resourceName: labelVariablesResourceName,
    key: `${labelVariablesResourceName}-${idProject}`,
    query: { idProject },
    body: {
        variableInputs: formItems,
    },
    optimisticUpdate: updateItems?.length
        ? {
              value: { model: updateItems },
          }
        : undefined,
    updateFromResponse: true,
    showSuccessNotification: false,
    // No need to dispatch clear resurce because updateFromResponse is true
    onComplete,
});

export const useLabelVariableList = ({ idProject, transform }: UseLabelVariableListParams) =>
    useResource({
        resourceName: labelVariablesResourceName,
        key: `${labelVariablesResourceName}-${idProject}`,
        query: {
            idProject,
        },
        transform,
    });

export const clearLabelVariableList =
    ({ idProject }: LabelVariableParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: labelVariablesResourceName,
                key: `${labelVariablesResourceName}-${idProject}`,
                broadcast: true,
            })
        );

export const updateLabelVariables =
    ({ idProject, values, onComplete }: UpdateLabelVariablesParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const oldItems = getCurrentLabelVariableList({ idProject, getState });
        const modifiedValues: IndexSignature<string> = removeObjEmptyProps(values);
        const formItems: IndexSignature<any> = {};

        for (const key of Object.keys(modifiedValues)) {
            if (Array.isArray(modifiedValues[key])) {
                formItems[key] = JSON.stringify(modifiedValues[key]);
            } else {
                formItems[key] = modifiedValues[key];
            }
        }

        const updateItems = oldItems?.map((u) => {
            return {
                ...u,
                value: modifiedValues[u.name] ?? u.value,
            };
        });

        dispatch(
            updateResource(
                getResourceParams({
                    idProject,
                    updateItems,
                    formItems,
                    onComplete,
                })
            )
        );
    };

interface LabelVariableParams {
    /**
     * ID of Project.
     */
    idProject: number;
}

interface UseLabelVariableListParams extends LabelVariableParams {
    /**
     * Function to transform state as needed.
     */
    transform?: (data: VariablesResponseModel) => any;
}

interface UpdateLabelVariablesParams extends LabelVariableParams {
    /**
     * Variable values.
     */
    values: IndexSignature<string>;

    /**
     * Function to run on completion.
     */
    onComplete?: () => void;
}

interface GetCurrentLabelVariableListParams extends LabelVariableParams {
    getState: any; // not ideal
}

interface GetResourceParams extends LabelVariableParams {
    formItems: IndexSignature<string>;

    /**
     * Label Variables optimistic update object.
     */
    updateItems: Variables[];

    /**
     * Function to run on completion.
     */
    onComplete?: () => void;
}
