import { memo } from "react";

import { useStandaloneReportLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { useStandaloneReports } from "store/resources/actions/standaloneReport/standaloneReportActions";

import ScenarioLog from "pages/ManageProject/ProjectionsContent/common/ScenarioLog";

import IconLoading from "components/ui/Icons/IconLoading";

import { ReportLogProps } from "pages/ManageProject/ProjectionsContent/ReportDashboard/ReportLogPanel/types";

const headers = {
    actionName: "action",
    started: "started",
    user: "user",
    executionTime: "execution time",
    note: {
        label: "",
        sortable: false,
    },
};

const ReportLog = memo(({ idClient, idProject, idReport }: ReportLogProps) => {
    const [standaloneReportLogItems, isLoadingStandaloneReportLogItems] = useStandaloneReportLogItems({
        idProject,
        idReport,
    });

    const [standaloneReports = [], isLoadingStandaloneReports] = useStandaloneReports({ idProject });

    return isLoadingStandaloneReportLogItems || isLoadingStandaloneReports ? (
        <IconLoading />
    ) : (
        <ScenarioLog
            idClient={idClient}
            idProject={idProject}
            projections={standaloneReports}
            headers={headers}
            logItems={standaloneReportLogItems}
        />
    );
});

export default ReportLog;
