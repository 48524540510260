import { memo, useCallback } from "react";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Separator from "components/ui/Separator";

import { ClientReviewModalProps } from "layouts/Modal/CalculationWorkflowModal/types";

const ClientReviewModule = memo(({ moduleName, onChange, onCancel }: ClientReviewModalProps) => {
    const onSetClick = useCallback(() => {
        onChange();

        onCancel?.();
    }, [onChange, onCancel]);

    return (
        <>
            <div className="flex-column modal-padding">
                <div>
                    Setting the status of <strong>{moduleName}</strong> to 'Client Review' will publish it and make it available for client
                    in <strong>VisionLoadMap</strong>.
                </div>
                <Separator />
                <div>Are you sure you want to set the module to Client Review?</div>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button variant="primary" padding="md" onClick={onSetClick}>
                    Yes, Publish Module
                </Button>
                <Button variant="secondary" padding="md" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default ClientReviewModule;
