import { v4 as uuidv4 } from "uuid";
import { camelCase, isEqual } from "lodash";
import { createRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { useAllTerritories } from "store/resources/actions/territory/territoryActions";
import { useAllFuels } from "store/resources/actions/fuel/fuelActions";
import { updateClient } from "store/resources/actions/client/clientActions";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import IconWrap from "components/ui/Icons";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import Checkbox from "components/ui/Input/Checkbox";
import CustomList from "components/ui/List/CustomList";
import TextArea from "components/ui/TextArea";

import { hasStakeholderUserRole } from "utils/user";

import { Territory, Fuel } from "types/types";
import { ClientAttributesProps } from "./types";

import "./style.scss";

const HEADERS = {
    alias: "Name",
    code: "Shortcode",
    remove: "",
};

const ClientAttributes = memo(({ client, displayInModal, onCancel }: ClientAttributesProps) => {
    const dispatch = useDispatch();

    const [allTerritories, isLoadingTerritory] = useAllTerritories();
    const [allFuels, isLoadingFuel] = useAllFuels();

    const isStakeholder = hasStakeholderUserRole();

    const initialClientTerritories = useMemo(() => {
        let territories: string[] = [];

        if (client.territories !== null) {
            territories = client.territories.map((territory: Territory) => territory.name);
        }

        return territories;
    }, [client.territories]);

    // States

    const [clientName, setClientName] = useState(client.clientName);
    const [clientDescription, setClientDescription] = useState(client.clientDescription || "");
    const [clientTerritories, setClientTerritories] = useState(initialClientTerritories);
    const [newTerritories, setNewTerritories] = useState([]);
    const [fuels, setFuels] = useState(client.fuels || []);
    const [contactName, setContactName] = useState(client.contactName || "");
    const [contactEmail, setContactEmail] = useState(client.contactEmail || "");
    const [contactPhone, setContactPhone] = useState(client.contactPhone || "");

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    useEffect(() => {
        // Change field values on client change.
        // Only possible in Stakeholder user view
        if (isStakeholder) {
            const clientTerr = client.territories !== null ? client.territories.map((territory: any) => territory.name) : [];

            setClientName(client.clientName);
            setClientDescription(client.clientDescription || "");
            setClientTerritories(clientTerr);
            setFuels(client.fuels || []);
            setContactName(client.contactName || "");
            setContactEmail(client.contactEmail || "");
            setContactPhone(client.contactPhone || "");
        }
    }, [client, isStakeholder]);

    useEffect(() => {
        if (!isStakeholder) {
            const currentTerritories = client.territories !== null ? client.territories.map((territory: any) => territory.name) : []; // saved client territories
            const selectedTerritories = [...clientTerritories]; // currently selected client territories

            const currentFuels = client.fuels !== null ? client.fuels.map((fuel: any) => fuel.name) : []; // saved client fuels
            const selectedFuels = [...fuels]; // currently selected client fuels

            // Sort both arrays, in order
            // to check if both arrays
            // are equal
            currentTerritories.sort();
            selectedTerritories.sort();

            currentFuels.sort();
            selectedFuels.sort();

            // TODO: Need to add check for new territories
            const isChanged =
                client.clientName !== clientName ||
                client.clientDescription !== clientDescription ||
                !isEqual(currentTerritories, selectedTerritories) ||
                !isEqual(currentFuels, selectedFuels) ||
                client.contactName !== contactName ||
                client.contactEmail !== contactEmail ||
                client.contactPhone !== contactPhone;

            // Do not enable save button
            // if client name is empty
            if (clientName !== "") {
                if (isChanged) {
                    setIsSaveEnabled(true);
                } else {
                    setIsSaveEnabled(false);
                }
            } else {
                setIsSaveEnabled(false);
            }
        }
    }, [client, isStakeholder, clientName, clientDescription, clientTerritories, fuels, contactName, contactEmail, contactPhone]);

    const territoryItems = useMemo(() => {
        return (
            allTerritories?.map((territory: Territory) => ({ label: `${territory.name} ${territory.alias}`, value: territory.name })) || []
        );
    }, [allTerritories]);

    const onChange = useCallback((value, handler) => {
        handler(value);
    }, []);

    const onFuelChange = useCallback(
        (fuel) => {
            const newFuels = [...fuels];

            const newFuelIndex = newFuels.findIndex((f) => f.name === fuel.name);

            // Either selects fuel
            // or deselects fuel
            if (newFuelIndex === -1) {
                newFuels.push(fuel);
            } else {
                newFuels.splice(newFuelIndex, 1);
            }

            setFuels(newFuels);
        },
        [fuels]
    );

    const onAddTerritoryClick = useCallback(() => {
        // @ts-ignore - remove when Client is typed
        setNewTerritories([...newTerritories, { id: uuidv4(), alias: createRef(), code: createRef() }]);
    }, [newTerritories]);

    const onRemoveTerritoryClick = useCallback(
        (id) => {
            setNewTerritories(newTerritories.filter((territory: any) => territory.id !== id));
        },
        [newTerritories]
    );

    const onSaveAttributesClick = useCallback(() => {
        if (clientName) {
            const territoriesToAdd = newTerritories
                ?.map((t: any) => ({
                    idTerritory: null,
                    name: t.code?.current?.value,
                    alias: t.alias?.current?.value,
                }))
                .filter((p) => p.name !== undefined && p.name !== "");

            const existingTerritories = clientTerritories?.map((t: any) => allTerritories?.find((p: any) => p.name === t));
            const territories = [...(existingTerritories || []), ...(territoriesToAdd || [])];

            dispatch(
                // @ts-ignore
                updateClient({
                    id: client.id,
                    clientName,
                    clientDescription,
                    territories,
                    fuels,
                    contactName,
                    contactEmail,
                    contactPhone,
                    onComplete: onCancel,
                })
            );
        }
    }, [
        client,
        allTerritories,
        clientName,
        clientDescription,
        clientTerritories,
        newTerritories,
        fuels,
        contactName,
        contactEmail,
        contactPhone,
        onCancel,
        dispatch,
    ]);

    const renderNewTerritories = useCallback(
        (item, index) => {
            return (
                <div key={item.id} className="list-item-row">
                    <div className="item-value column-alias">
                        <Input inputRef={item.alias} defaultValue={item.alias?.current?.value} />
                    </div>
                    <div className="item-value column-code">
                        <Input inputRef={item.code} defaultValue={item.code?.current?.value} />
                    </div>
                    <div className="item-value column-remove">
                        <IconWrap icon="clear-close" title="Remove" onClick={() => onRemoveTerritoryClick(item.id)} />
                    </div>
                </div>
            );
        },
        [onRemoveTerritoryClick]
    );

    const renderFuel = (item: Fuel, index: number) => {
        const fuel = camelCase(item.name);
        const fuelLabel = item.name;

        const isChecked = fuels.find((f: any) => f.name === fuelLabel) !== undefined;

        return (
            <div key={`fuel-${fuel}-${index}`}>
                <Checkbox
                    id={`client-${fuel}-${client?.id}`}
                    name={fuel}
                    label={fuelLabel}
                    readOnly={isStakeholder}
                    checked={isChecked}
                    onChange={() => onFuelChange(item)}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <>
                {isLoadingTerritory || isLoadingFuel ? (
                    <IconLoading />
                ) : (
                    <div className="flex-column">
                        <div className="flex-column client-attributes__client-section">
                            <div className="flex-row">
                                <div className="flex-column client-attributes__client-left-container">
                                    <FieldGroup>
                                        <Input
                                            label="Client name"
                                            readOnly={isStakeholder}
                                            value={clientName}
                                            onChange={(event) => onChange(event.target.value, setClientName)}
                                        />
                                        <TextArea
                                            label="Description"
                                            readOnly={isStakeholder}
                                            value={clientDescription}
                                            onChange={(event) => onChange(event.target.value, setClientDescription)}
                                        />
                                    </FieldGroup>
                                </div>
                                <div className="flex-column client-attributes__client-right-container">
                                    <FieldGroup>
                                        <div>
                                            <FieldGroup direction="row" label="Fuel">
                                                {allFuels?.map((fuel: Fuel, index: number) => renderFuel(fuel, index))}
                                            </FieldGroup>
                                        </div>
                                        <div>
                                            <Dropdown
                                                label="Territory"
                                                placeholder="Select from the list"
                                                readOnly={isStakeholder}
                                                value={clientTerritories}
                                                items={territoryItems}
                                                multiple
                                                singleLine
                                                onChange={(value) => onChange(value, setClientTerritories)}
                                            />
                                        </div>
                                        {newTerritories.length > 0 && (
                                            <div>
                                                <CustomList
                                                    // @ts-ignore
                                                    id={`list-${client?.id}`}
                                                    headers={HEADERS}
                                                    items={newTerritories}
                                                    scrollBody
                                                    renderItem={renderNewTerritories}
                                                />
                                            </div>
                                        )}
                                        {!isStakeholder && (
                                            <div>
                                                <Button
                                                    variant="tertiary"
                                                    iconLeft="ui-plus_in_circle__add__create_b_s"
                                                    iconSize="sm"
                                                    padding="md"
                                                    onClick={onAddTerritoryClick}
                                                >
                                                    Add territory
                                                </Button>
                                            </div>
                                        )}
                                    </FieldGroup>
                                </div>
                            </div>
                        </div>
                        <div className="flex-column client-attributes__contact-section">
                            <FieldGroup>
                                <div className="client-attributes__contact-name-wrapper">
                                    <Input
                                        label="Contact name"
                                        readOnly={isStakeholder}
                                        value={contactName}
                                        onChange={(event) => onChange(event.target.value, setContactName)}
                                    />
                                </div>
                                <div className="flex-row">
                                    <div className="client-attributes__contact-email-wrapper">
                                        <Input
                                            label="Contact e-mail"
                                            readOnly={isStakeholder}
                                            value={contactEmail}
                                            onChange={(event) => onChange(event.target.value, setContactEmail)}
                                        />
                                    </div>
                                    <div className="client-attributes__contact-phone-wrapper">
                                        <Input
                                            label="Contact phone"
                                            type="tel"
                                            readOnly={isStakeholder}
                                            placeholder="+1 767687687687687"
                                            value={contactPhone}
                                            maxLength={15}
                                            onChange={(event) => onChange(event.target.value, setContactPhone)}
                                        />
                                    </div>
                                </div>
                            </FieldGroup>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return displayInModal ? (
        <>
            <div className="flex-column modal-padding client-attributes client-attributes-modal">{renderContent()}</div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button variant="primary" padding="lg" isDisabled={isSaveEnabled === false} onClick={onSaveAttributesClick}>
                    Save Attributes
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    ) : (
        <div className="flex-column fill-height with-scroll client-attributes client-attributes-tab">{renderContent()}</div>
    );
});

export default ClientAttributes;
