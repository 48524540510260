///
/// Stop Calculation actions
/// resourceName: stopCalculationResourceName
///

import { get } from "lodash";

import { stopCalculationResourceName } from "store/configureResources";

import { updateResource } from "store/resources/actions/updateResource";
import { deleteItem } from "store/resources/actions/deleteResource";

export const getStoppedCalculationList = ({ getState }: GetStoppedCalculationListParams) =>
    get(getState(), `resources.${stopCalculationResourceName}.itemsById[${stopCalculationResourceName}].data.model`) || [];

export const stopCalculation =
    ({ idCalculation }: StopCalculationParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const updateItems = getStoppedCalculationList({ getState });

        updateItems.push(idCalculation);

        dispatch(
            updateResource({
                resourceName: stopCalculationResourceName,
                key: stopCalculationResourceName,
                path: {
                    idCalculation,
                },
                showSuccessNotification: false,
                optimisticUpdate: {
                    value: {
                        model: updateItems,
                    },
                },
            })
        );
    };

export const clearStoppedCalculationList =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            deleteItem({
                resourceName: stopCalculationResourceName,
                key: stopCalculationResourceName,
            })
        );
    };

interface StopCalculationParams {
    /**
     * ID of Calculation.
     */
    idCalculation: string;
}

interface GetStoppedCalculationListParams {
    getState: any; // not ideal
}
