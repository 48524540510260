import { batch } from "react-redux";
import { toast } from "react-toastify";

import { projectDataImportResourceName } from "store/configureResources";

import { checkProcessStatus } from "store/processes/useProcessStatus";
import { marketProfileDataImportStatusProps } from "store/processes/useProjectDataImportStatus";

import { modalClose } from "store/modal/actions";

import { createResource } from "store/resources/actions/createResource";
import { clearResource } from "store/resources/actions/clearResource";

import { isDevEnvironment } from "utils/constants";

const startMarketProfileDataImportStatusCheck =
    () =>
    // @ts-ignore
    (dispatch) => {
        // If status check is already scheduled - do nothing
        if (marketProfileDataImportStatusProps.timeoutHandlers) {
            return;
        }

        dispatch(checkProcessStatus(marketProfileDataImportStatusProps));
    };

export const uploadMarketProfileData =
    ({ id, idProject, idProjectUpload, file, name, onStart, onComplete }: UploadMarketProfileDataParams) =>
    // @ts-ignore
    (dispatch) => {
        let formData = new FormData();

        formData.append("id", id);
        formData.append("file", file);
        formData.append("name", name);

        const onUploadSuccess = (action: any) => {
            const { responseStatus, responseMessage } = action?.data;

            if (isDevEnvironment && responseStatus === "success\nsuccess") {
                console.log(action);
            }

            if (responseStatus === "failure\nfailure") {
                toast.error(responseMessage.split("\n", 2)[0]);

                dispatch(modalClose());

                return;
            }

            batch(() => {
                dispatch(modalClose());

                dispatch(startMarketProfileDataImportStatusCheck());

                // TODO: Check if different key is not needed
                dispatch(
                    clearResource({
                        resourceName: projectDataImportResourceName,
                        key: id,
                        broadcast: true,
                    })
                );
            });

            onComplete?.();
        };

        const onUploadError = () => {
            onComplete?.();
        };

        dispatch(
            createResource({
                resourceName: projectDataImportResourceName,
                key: id,
                path: {
                    projectId: idProject,
                },
                query: {
                    projectUploadId: idProjectUpload,
                },
                body: formData,
                onStart,
                onSuccess: onUploadSuccess,
                onError: onUploadError,
            })
        );
    };

interface UploadMarketProfileDataParams {
    /**
     * Upload ID.
     */
    id: string;

    /**
     * ID of Project.
     */
    idProject: number;

    /**
     * ID of Market Profile data.
     */
    idProjectUpload?: number;

    /**
     * Market Profile data.
     */
    file: File;

    /**
     * Name of Market Profile data.
     */
    name: string;

    /**
     * Function to run when upload has started.
     */
    onStart?: (id: string, numberOfFiles: number) => void;

    /**
     * Function to run when upload is complete.
     */
    onComplete?: () => void;
}
