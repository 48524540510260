import { useRef } from "react";
import cn from "classnames";
import { useAccordionItem } from "@react-aria/accordion";
import { useHover } from "@react-aria/interactions";
import { FocusRing } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";

import Link from "components/ui/Link";
import Icon from "components/ui/IconNew";
import ExpandableList from "components/ui/ExpandableComponent/ExpandableList";
import ExpandableItem from "components/ui/ExpandableComponent/ExpandableItem";

import { AccordionItemProps, AccordionItemType } from "../types";

import "../style.scss";

export const AccordionItem = <T extends object>(props: AccordionItemProps<T>) => {
    const ref = useRef<HTMLButtonElement>(null);
    const { state, item } = props;
    const { buttonProps, regionProps } = useAccordionItem<T>(props, state, ref);
    const { children } = item.value as AccordionItemType;
    const isOpen = state.expandedKeys.has(item.key);
    const isDisabled = state.disabledKeys.has(item.key);
    const { isHovered, hoverProps } = useHover({ isDisabled });

    return (
        <div
            className={cn("accordion-item", {
                "is-open": isOpen,
                "is-disabled": isDisabled,
            })}
        >
            <FocusRing within focusRingClass="focus-ring">
                <div
                    className={cn("flex-row align-center accordion-header", {
                        "is-hovered": isHovered,
                    })}
                >
                    <div
                        className={cn("flex-column flex-one", {
                            "accordion-header__no_shevron": !children?.items?.length,
                        })}
                    >
                        <button className={"accordion-header__button"} ref={ref} {...mergeProps(buttonProps, hoverProps)}>
                            {!!children?.items?.length && (
                                <span
                                    className={cn("accordion-header__shevron", {
                                        expanded: isOpen,
                                    })}
                                >
                                    <Icon className="shevron-keyboard-arrow-right" icon="arrows-chevron_right__keyboard_arrow_right_b_a" />
                                </span>
                            )}
                            {children?.header || item.props.children}
                        </button>
                    </div>
                    <div className="flex-column align-center justify-center">{children?.action}</div>
                </div>
            </FocusRing>
            <ExpandableList regionProps={regionProps} isOpen={isOpen}>
                {children?.items.map((child, index) =>
                    children?.handleClick !== undefined ? (
                        <Link key={`accordion-item-${index}`} className="accordion-item__link" onClick={children?.handleClick?.(child)}>
                            {children?.renderItem(child, index)}
                        </Link>
                    ) : (
                        <ExpandableItem key={`expandable-item-${index}`}>{children?.renderItem(child, index)}</ExpandableItem>
                    )
                )}
            </ExpandableList>
        </div>
    );
};
