import { v1 as uuidv1 } from "uuid";
import { some, pickBy, isEmpty, isNil } from "lodash";
import { memo, useCallback, useState } from "react";
import { batch, useDispatch } from "react-redux";

import { componentModelDataImportV3ResourceName, componentModelListResourceName } from "store/configureResources";

import { getResourceState } from "store/utils";

import { selectProjection } from "store/projections/actions";

import { optimisticUpdateItem } from "store/resources/actions/updateResource";

import { uploadDataset } from "store/processes/actions/dataImport/modelDataImportActions";
import { createComponentModel, updateComponentModel } from "store/resources/actions/componentModel/componentModelActions";
import { getProjectFile } from "store/resources/actions/projectFiles/projectFilesActions";
import { clearAllModuleInputs } from "store/resources/actions/calculationWorkflow/moduleInputsActions";
import { clearModules } from "store/resources/actions/calculationWorkflow/modulesActions";

import { FORM_VALIDATION_KEYS } from "layouts/common/ImportData";
import ImportDataForm from "layouts/common/ImportData/ImportDataForm";

import { projections, allComponentModelStates } from "utils/constants";
import { isNullOrWhitespace } from "utils/string";

import { ComponentModelResponseModel } from "store/resources/actions/componentModel/types";
import { ImportDataCommonProps } from "layouts/common/ImportData/types";

const ComponentModel = memo(
    ({
        viewIndex,

        idProject,
        idManager,
        idModel,
        idInputLog,

        managerFullName,
        members,

        modelName,
        modelTemplate,
        modelRequired,
        modelDescription,
        modelAnalyst,
        modelReviewer,
        modelTerritory,
        modelFuel,
        files,
        uploadedFile,
        selectedSheets,
        uploadedProjectFiles,
        projectTerritories,
        projectFuels,

        importMode,

        formValidationWarnings,
        modelNameExists,

        displayInModal,

        setFiles,
        setSelectedSheets,

        onChange,
        onCancel,
    }: ImportDataCommonProps) => {
        const dispatch = useDispatch();

        const initialFormValidationErrors = {
            [FORM_VALIDATION_KEYS.NO_NAME]: false,
            [FORM_VALIDATION_KEYS.NAME_EXISTS]: false,
            [FORM_VALIDATION_KEYS.NO_TEMPLATE]: false,
            [FORM_VALIDATION_KEYS.NO_TERRITORY]: false,
            [FORM_VALIDATION_KEYS.NO_FUEL]: false,
            [FORM_VALIDATION_KEYS.NO_FILE]: false,
            [FORM_VALIDATION_KEYS.NO_SELECTED_SHEETS]: false,
        };

        const [formValidationErrors, setFormValidationErrors] = useState(initialFormValidationErrors);

        // Helper functions

        /**
         * Once data import starts, optimistically update
         * "New Model" to represent newly created Component Model
         * and navigate to that Model.
         */
        const onUploadStart = useCallback(
            (id: string, fileName: string, idModel?: number) => (dispatch, getState) => {
                const state = getState();

                const allComponentModels = getResourceState<ComponentModelResponseModel>(state, componentModelListResourceName, {
                    idProject,
                });

                const modelId = idModel ? idModel : uuidv1();

                let updateItems = [];

                if (idModel) {
                    updateItems = allComponentModels.map((model) =>
                        model.modelId === idModel
                            ? {
                                  ...model,
                                  uploadProps: {
                                      uploadId: id,
                                      fileName,
                                      isUploading: true,
                                  },
                              }
                            : {
                                  ...model,
                              }
                    );
                } else {
                    updateItems = allComponentModels.map((model) =>
                        model.modelId.toString().includes("new-model")
                            ? {
                                  ...model,
                                  modelId,
                                  territoryId: modelTerritory,
                                  fuelId: modelFuel,
                                  analystId: modelAnalyst ?? undefined,
                                  reviewerId: modelReviewer ?? undefined,
                                  name: modelName,
                                  description: modelDescription,
                                  required: modelRequired,
                                  modelState: allComponentModelStates.PROPOSED.toUpperCase(),
                                  uploadProps: {
                                      uploadId: id,
                                      fileName,
                                      isUploading: true,
                                  },
                              }
                            : {
                                  ...model,
                              }
                    );
                }

                batch(() => {
                    dispatch(
                        optimisticUpdateItem({
                            resourceName: componentModelListResourceName,
                            key: `${componentModelListResourceName}-${idProject}`,
                            value: updateItems,
                        })
                    );

                    if (displayInModal) {
                        onCancel?.();
                    } else {
                        dispatch(
                            selectProjection({
                                viewIndex,
                                idProjectionView: projections.COMPONENT_MODEL_DASHBOARD,
                                idProject,
                                idProjection: modelId,
                                key: "idModel",
                            })
                        );
                    }
                });
            },
            [
                viewIndex,
                idProject,
                modelTerritory,
                modelFuel,
                modelAnalyst,
                modelReviewer,
                modelName,
                modelDescription,
                modelRequired,
                displayInModal,
                onCancel,
            ]
        );

        const onUploadSuccess = useCallback(
            (idModel: number, firstImport?: boolean) => (dispatch, getState) => {
                const state = getState();

                const allComponentModels = getResourceState<ComponentModelResponseModel>(state, componentModelListResourceName, {
                    idProject,
                });

                let updateItems = [];

                if (firstImport) {
                    // Remove "persist" mark from optimistically added Model
                    // because we now have real Model
                    updateItems = allComponentModels.map((model) =>
                        !model.modelId.toString().includes("new-model") && typeof model.modelId === "string"
                            ? {
                                  ...model,
                                  persist: false,
                              }
                            : {
                                  ...model,
                              }
                    );
                } else {
                    // On successful upload set "isUploading" to false
                    updateItems = allComponentModels.map((model) =>
                        model.modelId === idModel
                            ? {
                                  ...model,
                                  uploadProps: {
                                      ...model.uploadProps,
                                      isUploading: false,
                                  },
                              }
                            : {
                                  ...model,
                              }
                    );
                }

                dispatch(
                    optimisticUpdateItem({
                        resourceName: componentModelListResourceName,
                        key: `${componentModelListResourceName}-${idProject}`,
                        value: updateItems,
                    })
                );
            },
            [idProject]
        );

        /**
         * Once Component Model has been successfully created,
         * select that Model, only if user is on Component Model Dashboard
         * for newly created Model.
         *
         * There are multiple phases for Model creation:
         *
         *  1. When Model creation request has been made,
         *     while UI waits for response (upload dataset to DB),
         *     optimistic item is created with the name of the Model.
         *  2. When optimistic item is created, app navigates user
         *     to Component Model Dashboard for that item and
         *     displays upload progress.
         *  3. When response is received (upload is finished):
         *      a. If user is still on Component Model Dashboard for that item,
         *         app navigates user to Component Model Dashboard, but this
         *         time for the newly created, real Model.
         *      b. If user is not on Component Model Dashboard for that item anymore,
         *         app does not navigate user anywhere.
         */
        const onCreateModelSuccess = useCallback(
            (idModel: number, firstImport?: boolean) => (dispatch, getState) => {
                const state = getState();

                if (firstImport) {
                    dispatch(onUploadSuccess(idModel, firstImport));

                    // @ts-ignore
                    const selectedProjection = state.projections?.selection[idProject]?.[viewIndex];

                    if (
                        selectedProjection.idProjectionView === projections.COMPONENT_MODEL_DASHBOARD &&
                        selectedProjection.idModel &&
                        typeof selectedProjection.idModel === "string"
                    ) {
                        dispatch(
                            selectProjection({
                                viewIndex,
                                idProjectionView: projections.COMPONENT_MODEL_DASHBOARD,
                                idProject,
                                idProjection: idModel,
                                key: "idModel",
                            })
                        );
                    }
                } else {
                    const allComponentModels = getResourceState<ComponentModelResponseModel>(state, componentModelListResourceName, {
                        idProject,
                    });

                    const updateItems = allComponentModels.filter((model) => !model.modelId.toString().includes("new-model"));

                    dispatch(
                        optimisticUpdateItem({
                            resourceName: componentModelListResourceName,
                            key: `${componentModelListResourceName}-${idProject}`,
                            value: updateItems,
                        })
                    );

                    dispatch(
                        selectProjection({
                            viewIndex,
                            idProjectionView: projections.COMPONENT_MODEL_DASHBOARD,
                            idProject,
                            idProjection: idModel,
                            key: "idModel",
                        })
                    );
                }
            },
            [viewIndex, idProject, onUploadSuccess]
        );

        /**
         * Maps input data to Component Model.
         *
         * @param idInputLog - ID of input data
         * @param firstImport - Flag for first time import
         */
        const onImportDataComplete = useCallback(
            (idInputLog: number, firstImport: boolean) => (dispatch) => {
                // If first time import
                if (firstImport) {
                    // If Component Model exists,
                    // then map input data with Component Model
                    if (idModel) {
                        dispatch(
                            updateComponentModel({
                                idProject,
                                idModel,
                                idInputLog,
                            })
                        );
                    }
                    // If Component Model does not exist,
                    // create a new Component Model
                    // with input data.
                    //
                    // With input file provided, Component Models should always
                    // also have territory and fuel provided
                    else if (modelName && !isNil(modelTemplate) && !isNil(modelTerritory) && !isNil(modelFuel)) {
                        dispatch(
                            createComponentModel({
                                idProject,
                                idTemplate: modelTemplate,
                                idInputLog,
                                name: modelName,
                                required: modelRequired,
                                description: modelDescription,
                                idTerritory: modelTerritory,
                                idFuel: modelFuel,
                                idAnalyst: modelAnalyst ?? undefined,
                                idReviewer: modelReviewer ?? undefined,
                                onSuccess: (idModel) => {
                                    dispatch(onCreateModelSuccess(idModel, firstImport));
                                },
                            })
                        );
                    }
                }
                // If recurrent import,
                // clear all Module resources
                // to get most up-to-date data
                else if (idModel) {
                    batch(() => {
                        dispatch(onUploadSuccess(idModel));

                        dispatch(clearAllModuleInputs());
                        dispatch(clearModules({ idModel }));
                    });
                }
            },
            [
                idProject,
                idModel,
                modelName,
                modelTemplate,
                modelRequired,
                modelDescription,
                modelTerritory,
                modelFuel,
                modelAnalyst,
                modelReviewer,
                onCreateModelSuccess,
                onUploadSuccess,
            ]
        );

        // Event handlers

        /**
         * Creates a new Component Model without
         * an input file.
         */
        const onCreateModel = useCallback(() => {
            const newFormValidationErrors = {
                [FORM_VALIDATION_KEYS.NO_NAME]: isNullOrWhitespace(modelName || ""),
                [FORM_VALIDATION_KEYS.NAME_EXISTS]: modelNameExists,
                [FORM_VALIDATION_KEYS.NO_TEMPLATE]: modelTemplate === null,
                [FORM_VALIDATION_KEYS.NO_TERRITORY]: modelTerritory === null,
                [FORM_VALIDATION_KEYS.NO_FUEL]: modelFuel === null,
            };

            setFormValidationErrors(newFormValidationErrors);

            if (some(newFormValidationErrors)) {
                return;
            }

            if (modelName && !isNil(modelTemplate) && !isNil(modelTerritory) && !isNil(modelFuel)) {
                dispatch(
                    createComponentModel({
                        idProject,
                        idTemplate: modelTemplate,
                        name: modelName,
                        required: modelRequired,
                        description: modelDescription,
                        idAnalyst: modelAnalyst ?? undefined,
                        idReviewer: modelReviewer ?? undefined,
                        idTerritory: modelTerritory,
                        idFuel: modelFuel,
                        onSuccess: (idModel) => {
                            dispatch(onCreateModelSuccess(idModel));
                        },
                    })
                );
            }
        }, [
            idProject,
            modelName,
            modelTemplate,
            modelRequired,
            modelDescription,
            modelAnalyst,
            modelReviewer,
            modelTerritory,
            modelFuel,
            modelNameExists,
            onCreateModelSuccess,
            dispatch,
        ]);

        /**
         * Imports data from the selected input file.
         *
         * If Component Model is already created, but
         * it does not have any import data, map
         * import data with the existing Model.
         *
         * If it's a new import, create a new
         * Component Model.
         */
        const onImportData = useCallback(async () => {
            const newFormValidationErrors = {
                [FORM_VALIDATION_KEYS.NO_NAME]: isNullOrWhitespace(modelName || ""),
                [FORM_VALIDATION_KEYS.NAME_EXISTS]: !displayInModal && modelNameExists,
                [FORM_VALIDATION_KEYS.NO_TEMPLATE]: modelTemplate === null,
                [FORM_VALIDATION_KEYS.NO_FILE]: isEmpty(files) && uploadedFile === "",
                [FORM_VALIDATION_KEYS.NO_TERRITORY]: modelTerritory === null,
                [FORM_VALIDATION_KEYS.NO_FUEL]: modelFuel === null,
                [FORM_VALIDATION_KEYS.NO_SELECTED_SHEETS]: !some(selectedSheets),
            };

            setFormValidationErrors(newFormValidationErrors);

            if (some(newFormValidationErrors)) {
                return;
            }

            let file: File | undefined;

            // With import mode we take user provided input file
            // With reference mode we take input file from Data Lake
            if (importMode === "import" && files !== undefined) {
                file = files[0];
            } else if (importMode === "reference" && uploadedFile !== "") {
                const fullPath = uploadedProjectFiles.find((file: any) => file.name === uploadedFile)?.path;

                if (fullPath) {
                    const filePath = fullPath.replace(`${idProject}/`, "");

                    const response = await getProjectFile({ idProject, filePath });

                    file = new File([response.blob], response.fileName, { type: response.blob.type });
                }
            }

            // Input file should always be provided with territory and fuel
            if (modelName && !isNil(modelTerritory) && !isNil(modelFuel) && file !== undefined) {
                const firstImport = isNil(idInputLog);

                const uploadId = uuidv1();

                const sheets = Object.keys(pickBy(selectedSheets || {}));

                dispatch(
                    uploadDataset({
                        resourceName: componentModelDataImportV3ResourceName,
                        id: uploadId,
                        idProject,
                        idInputLog,
                        idTerritory: modelTerritory,
                        idFuel: modelFuel,
                        idAnalyst: modelAnalyst ?? undefined,
                        idReviewer: modelReviewer ?? undefined,
                        name: modelName,
                        description: modelDescription,
                        required: modelRequired,
                        file,
                        selectedSheets: sheets,
                        onStart: () => {
                            dispatch(onUploadStart(uploadId, file?.name, idModel));
                        },
                        onComplete: (newIdInputLog) => {
                            dispatch(onImportDataComplete(newIdInputLog, firstImport));
                        },
                    })
                );
            }
        }, [
            idProject,
            idInputLog,
            idModel,
            modelName,
            modelTemplate,
            modelRequired,
            modelDescription,
            modelAnalyst,
            modelReviewer,
            modelTerritory,
            modelFuel,
            files,
            uploadedFile,
            selectedSheets,
            uploadedProjectFiles,
            importMode,
            modelNameExists,
            displayInModal,
            onUploadStart,
            onImportDataComplete,
            dispatch,
        ]);

        return (
            <ImportDataForm
                idProject={idProject}
                idManager={idManager}
                idInputLog={idInputLog}
                managerFullName={managerFullName}
                members={members}
                modelName={modelName}
                modelTemplate={modelTemplate}
                modelRequired={modelRequired}
                modelDescription={modelDescription}
                modelAnalyst={modelAnalyst}
                modelReviewer={modelReviewer}
                modelTerritory={modelTerritory}
                modelFuel={modelFuel}
                files={files}
                uploadedFile={uploadedFile}
                selectedSheets={selectedSheets}
                uploadedProjectFiles={uploadedProjectFiles}
                projectTerritories={projectTerritories}
                projectFuels={projectFuels}
                importMode={importMode}
                formValidationErrors={formValidationErrors}
                formValidationWarnings={formValidationWarnings}
                displayInModal={displayInModal}
                isComponentModel
                setFiles={setFiles}
                setSelectedSheets={setSelectedSheets}
                onChange={onChange}
                onCreate={onCreateModel}
                onImport={onImportData}
                onCancel={onCancel}
            />
        );
    }
);

export default ComponentModel;
