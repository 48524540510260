import { get } from "lodash";

import {
    API_VDSM_GET_AUTHORIZED,
    API_CRUD_READ_REQUEST,
    API_CRUD_READ_SUCCESS,
    API_CRUD_READ_ERROR,
    API_CRUD_WAIT_READ,
} from "store/actionTypes";
import { getResourceKey, getResourceUrl } from "store/utils";
import { store } from "store/configureStore";

import { IndexSignature } from "types/types";

/**
 * Get resource from database.
 */
export const getResource =
    ({
        resourceName,
        resourceId,
        key,
        path = {},
        query = {},
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        transform,
        onSuccess,
        onError,
        onComplete,
    }: GetResourceParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const resourceKey = getResourceKey({ resourceId, key });
        const isReading = get(getState(), `resources.${resourceName}.itemsById[${resourceKey}].isReading`);

        if (isReading) {
            return;
        }

        dispatch({
            type: API_VDSM_GET_AUTHORIZED,
            url: getResourceUrl(resourceName, path) + (resourceId ? "/" + resourceId : ""),
            query: {
                // Remove query params with empty value
                ...Object.keys(query)
                    // @ts-ignore
                    .filter((key) => query[key] !== null && query[key] !== undefined)
                    // @ts-ignore
                    .reduce((obj, key) => (obj = { ...obj, [key]: query[key] }), {}),
            },
            actionTypes: {
                pending: API_CRUD_READ_REQUEST,
                response: API_CRUD_READ_SUCCESS,
                error: API_CRUD_READ_ERROR,
            },
            passThroughData: {
                resourceName,
                resourceId,
                key,
                path,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onSuccess,
                onError,
                onComplete,
                transform,
            },
        });
    };

/**
 * Get resource from database as a Promise.
 */
export const getResourcePromise = ({
    resourceName,
    resourceId,
    key,
    path = {},
    query = {},
    showSuccessNotification,
    showErrorNotification,
}: GetResourcePromiseParams) =>
    new Promise((resolve, reject) => {
        store.dispatch(
            getResource({
                resourceName,
                resourceId,
                key,
                path,
                query,
                showSuccessNotification,
                showErrorNotification,
                onSuccess: (action) => resolve(action.data),
                onError: (action) => reject(action),
            })
        );
    });

/**
 * Get resource from store.
 */
export const getResourceFromStore = ({ resourceName, resourceId, key }: GetResourceFromStoreParams) => {
    const resourceKey = getResourceKey({ resourceId, key });

    return get(store.getState(), `resources[${resourceName}].itemsById[${resourceKey}].data`);
};

export const waitReadResource =
    ({ resourceName, key, actionName }: WaitReadResourceParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: API_CRUD_WAIT_READ,
            key,
            actionName,
            passThroughData: {
                resourceName,
            },
        });
    };

interface GetResourceFromStoreParams {
    /**
     * Name of the resource property.
     */
    key?: string | RegExp;

    /**
     * Name of the resource.
     */
    resourceName?: string;

    /**
     * ID of resource.
     */
    resourceId?: string;
}

interface GetResourcePromiseParams extends GetResourceFromStoreParams {
    path?: IndexSignature<any>;

    query?: object;

    /**
     * Display notification on success.
     */
    showSuccessNotification?: boolean;

    /**
     * Display notification on error.
     */
    showErrorNotification?: boolean;
}

interface GetResourceParams extends GetResourcePromiseParams {
    forced?: boolean;

    takeFromStore?: boolean;

    successMessage?: string;

    errorMessage?: string;

    /**
     * Function to transform received data as needed.
     */
    transform?: (data: any) => any;

    /**
     * Function to run on completion.
     */
    onComplete?: () => void;

    /**
     * Function to run on success.
     */
    onSuccess?: (action?: any) => void;

    /**
     * Function to run on error.
     */
    onError?: (action?: any) => void;
}

interface WaitReadResourceParams {
    /**
     * Name of the resource.
     */
    resourceName: string;

    /**
     * Name of the resource property.
     */
    key: string | RegExp;

    /**
     * Name of the action.
     *
     * To add descriptive information next to the loader element.
     */
    actionName?: string;
}
