import { isEmpty } from "lodash";
import { memo } from "react";

import { useMarketProfileData } from "store/resources/actions/marketProfile/marketProfileActions";

import DashboardHeader from "pages/ManageProject/ProjectionsContent/ProjectDashboard/DashboardHeader";
import ProjectLogPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ProjectLogPanel";
import ProjectDetailsPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ProjectDetailsPanel";
import ProjectStudyCasePanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ProjectStudyCasePanel";
import ModelsPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ModelsPanel";
import MarketProfileSnapshotPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/MarketProfileSnapshotPanel";
import ProjectLabelsPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ProjectLabelsPanel";

import { ProjectDashboardViewsProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

import "./style.scss";

/**
 * Project Dashboard view for external clients.
 */
const ProjectDashboardClient = memo(
    ({ viewIndex, project, clientName, displayFiles, onDisplayFiles, onReturn }: ProjectDashboardViewsProps) => {
        const [marketProfileData, isLoadingMarketProfileData] = useMarketProfileData({ idProject: project.idProject });

        return (
            <div className="project-dashboard-client">
                <div className="project-dashboard-client__header-container">
                    <div>
                        <DashboardHeader
                            project={project}
                            clientName={clientName}
                            displayFiles={displayFiles}
                            onDisplayFiles={onDisplayFiles}
                            onReturn={onReturn}
                        />
                    </div>
                </div>
                <div className="project-dashboard-client__body-container">
                    <div>
                        <ProjectLogPanel project={project} />
                    </div>
                    <div>
                        <ProjectDetailsPanel project={project} />
                    </div>
                    <div>
                        <ProjectStudyCasePanel project={project} />
                    </div>
                    {!isEmpty(marketProfileData) && (
                        <div>
                            <MarketProfileSnapshotPanel project={project} data={marketProfileData} isLoading={isLoadingMarketProfileData} />
                        </div>
                    )}
                    <div>
                        <ProjectLabelsPanel project={project} />
                    </div>
                    <div>
                        <ModelsPanel viewIndex={viewIndex} project={project} />
                    </div>
                </div>
            </div>
        );
    }
);

export default ProjectDashboardClient;
