import { SELECT_PROJECTION } from "store/actionTypes";

import { SelectProjectionParams } from "./types";

export const selectProjection =
    ({ viewIndex, idProjectionView, idProject, idProjection, key }: SelectProjectionParams) =>
    // @ts-ignore
    (dispatch) => {
        if (idProjection && key) {
            dispatch({
                type: SELECT_PROJECTION,
                selection: { viewIndex, idProjectionView, idProject, [key]: idProjection, key },
            });
        } else {
            dispatch({
                type: SELECT_PROJECTION,
                selection: { viewIndex, idProjectionView, idProject },
            });
        }
    };
