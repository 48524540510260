import { isEmpty } from "lodash";
import { memo } from "react";

import { useStandaloneReports } from "store/resources/actions/standaloneReport/standaloneReportActions";

import IconLoading from "components/ui/Icons/IconLoading";
import CustomList from "components/ui/List/CustomList";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import StandaloneReport, { renderFilters } from "./StandaloneReport";

import { StandaloneReportProps } from "pages/ProjectStakeholderDashboard/StandaloneReportsPanel/types";

import "./style.scss";

const HEADERS = {
    name: "Standalone report",
    reportType: {
        label: "Type",
        sortable: false,
    },
    lastUpdate: {
        label: "Last update",
        sortable: false,
    },
};

const StandaloneReports = memo(({ idProject, projectName }: StandaloneReportProps) => {
    const [standaloneReports, isLoadingStandaloneReports] = useStandaloneReports({ idProject });

    const standaloneReportProps = {
        projectName,
    };

    return isLoadingStandaloneReports ? (
        <IconLoading />
    ) : (
        <div className="standalone-report-list">
            {isEmpty(standaloneReports) ? (
                <NothingFoundBlock icon="ui-chart_multiple_b_s" title="There are no published reports" />
            ) : (
                <CustomList
                    // @ts-ignore
                    headers={HEADERS}
                    items={standaloneReports}
                    component={StandaloneReport}
                    componentProps={standaloneReportProps}
                    limit={4}
                    pageDisplayCount={3}
                    sortable
                    paginationAlignToRight
                    renderSearch={renderFilters}
                />
            )}
        </div>
    );
});

export default StandaloneReports;
