import { useEffect } from "react";
import ReactGA from "react-ga4";

import { clientRoute, isDevEnvironment } from "utils/constants";
import { getCookiesPolicyCookie } from "utils/cookies";

import { UseGoogleAnalyticsProps, UseGoogleAnalyticsInPageProps } from "./types";

enum GoogleAnalyticsTrackers {
    Main = "MainTracker",
    Configured = "t0",
}

/**
 * Hook that initializes GA4.
 */
export const useGoogleAnalytics = ({ trackingId }: UseGoogleAnalyticsProps) => {
    const mainTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "";

    useEffect(() => {
        if (isDevEnvironment) {
            let trackers = [];

            if (mainTrackingId) {
                // @ts-ignore
                window[`ga-disable-${mainTrackingId}`] = true;

                trackers.push({
                    trackingId: mainTrackingId,
                    gaOptions: { name: GoogleAnalyticsTrackers.Main },
                });
            }

            if (trackingId) {
                // @ts-ignore
                window[`ga-disable-${trackingId}`] = true;

                trackers = trackers.concat({
                    trackingId,
                    gaOptions: { name: GoogleAnalyticsTrackers.Configured },
                });
            }

            if (trackers.length > 0) {
                if (ReactGA.isInitialized) {
                    trackers = trackers.filter((t) => t.gaOptions.name === GoogleAnalyticsTrackers.Configured);

                    ReactGA.reset();
                }

                ReactGA.initialize(trackers, { legacyDimensionMetric: false });
            }
        }
    }, [trackingId, mainTrackingId]);
};

/**
 * Hook that checks if it is needed to disable the analytics or
 * send the "pageview" event.
 */
export const useGoogleAnalyticsInPage = ({ isAnonymousPage, trackingId }: UseGoogleAnalyticsInPageProps) => {
    const mainTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "";

    useEffect(() => {
        if (isDevEnvironment) {
            const pathParts = window.location.pathname.split("/")[1];

            const isOptIn = getCookiesPolicyCookie();
            const isTrackingDisabled = pathParts !== clientRoute.reports || !isOptIn || !isAnonymousPage;
            let activeTrackers = [];

            if (mainTrackingId) {
                // @ts-ignore
                window[`ga-disable-${mainTrackingId}`] = isTrackingDisabled;

                if (!isTrackingDisabled) {
                    activeTrackers.push(GoogleAnalyticsTrackers.Main);
                }
            }

            if (trackingId) {
                // @ts-ignore
                window[`ga-disable-${trackingId}`] = isTrackingDisabled;

                if (!isTrackingDisabled) {
                    activeTrackers.push(GoogleAnalyticsTrackers.Configured);
                }
            }

            if (activeTrackers.length > 0) {
                setTimeout(() => {
                    // @ts-ignore
                    if (window.ga) {
                        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
                    }
                });
            }
        }
    }, [isAnonymousPage, trackingId, mainTrackingId]);
};
