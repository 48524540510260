import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { passFailModules } from "store/resources/actions/calculationWorkflow/modulesActions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Separator from "components/ui/Separator";
import TextArea from "components/ui/TextArea";

import { ClientApprovementModalProps } from "layouts/Modal/CalculationWorkflowModal/types";

const ClientApprovement = memo(({ idProject, idInputLog, idModel, onCancel }: ClientApprovementModalProps) => {
    const dispatch = useDispatch();

    const [note, setNote] = useState("");

    const onNoteChange = useCallback((event) => {
        setNote(event.target.value);
    }, []);

    const onApprovementClick = useCallback(
        (action: "PASS" | "FAIL") => {
            dispatch(
                passFailModules({
                    idProject,
                    idInputLog,
                    idModel,
                    action,
                    userNote: note,
                })
            );

            onCancel?.();
        },
        [idProject, idInputLog, idModel, note, onCancel, dispatch]
    );

    return (
        <>
            <div className="flex-column modal-padding">
                <div>
                    <div>By approving these modules, you will lock them to be read only.</div>
                    <div>By returning these modules back to AEG, they will be sent back to the manager.</div>
                </div>
                <Separator />
                <div>
                    <TextArea label="Note" placeholder="Write a note" value={note} onChange={onNoteChange} />
                </div>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button variant="primary" padding="lg" onClick={() => onApprovementClick("PASS")}>
                    Approve Final
                </Button>
                <Button variant="primary" color="system-critical" padding="lg" onClick={() => onApprovementClick("FAIL")}>
                    Return with Note
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default ClientApprovement;
