import { USER_SAVE } from "store/actionTypes";

import { getUser } from "utils/user";

import { UserState, UserAction } from "./types";

const initialState = null;

export const reducer = (state: UserState | null = initialState, action: UserAction) => {
    state = getUser();

    switch (action.type) {
        case USER_SAVE:
            state = action.user;

            break;

        default:
            break;
    }

    return state;
};
