import cn from "classnames";
import { MODAL_COMPONENTS } from "../ModalRoot";

import "./style.scss";

/*
Example:

<FloatModalRoot
            modalId={`modal-setup-model`}
            modalType={showMo[showMoKey] && "MODAL"}
            modalProps={moProps}
        >
...area to modalize
</FloatModalRoot>
*/

const FloatModalRoot = ({ modalId, modalType, modalProps, children }) => {
    const overlayClassName = cn("float-modal-overlay", modalProps.overlayClassName);
    const portalClassName = cn("float-modal-portal", modalProps.portalClassName);
    const className = cn("float-modal-modal", modalProps.className);
    const modalRootClassName = cn("float-modal-root", modalProps.modalRootClassName);

    const SpecificModal = modalType && MODAL_COMPONENTS[modalType];

    return (
        <div id={modalId} className={modalRootClassName}>
            {modalType && (
                <SpecificModal
                    modalType={modalType}
                    {...modalProps}
                    overlayClassName={overlayClassName}
                    portalClassName={portalClassName}
                    className={className}
                    parentSelector={`#${modalId}`}
                />
            )}
            {children}
        </div>
    );
};

export default FloatModalRoot;
