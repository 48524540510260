import { memo, useCallback, useState } from "react";

import Button from "components/ui/ButtonNew";
import DashboardPanel from "components/ui/Dashboard";
import ProjectDetails from "./ProjectDetails";

import { projectReadOnlyEnabled } from "pages/utils";

import { PROJECT_DETAILS } from "utils/constants";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

const ProjectDetailsPanel = memo(({ project }: ProjectDashboardPanelProps) => {
    const [isEditing, setIsEditing] = useState(false);

    const title = "Project Details";

    const onChangeClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const onCancelClick = useCallback(() => {
        setIsEditing(false);
    }, []);

    const headerAction =
        !isEditing && !projectReadOnlyEnabled(project.projectState) && hasAnyOfPermissions([USER_ACTIONS.PROJECT_EDIT]) ? (
            <Button variant="tertiary" padding="md" iconLeft="ui-pencil__edit__create_b_s" iconSize="sm" onClick={onChangeClick}>
                {`Change ${title}`}
            </Button>
        ) : undefined;

    return (
        <DashboardPanel className="project-details-panel" title={title} headerAction={headerAction} pageId={PROJECT_DETAILS}>
            <ProjectDetails project={project} editing={isEditing} onEditCancel={onCancelClick} />
        </DashboardPanel>
    );
});

export default ProjectDetailsPanel;
