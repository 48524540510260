import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import cn from "classnames";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import IconWrap from "components/ui/Icons";
import Input from "components/ui/Input";

import "./style.scss";

const TextEdit = (props: TextEditProps) => {
    const { onApply } = props;

    const [currentValue, setCurrentValue] = useState(props.value);
    const [isEditing, setIsEditing] = useState(props.edit || false);

    const onChange = useCallback((event) => {
        setCurrentValue(event.target.value);
    }, []);

    const onEditClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const onApplyClick = useCallback(() => {
        onApply?.(currentValue);

        setIsEditing(false);
    }, [onApply, currentValue]);

    const onCancelClick = useCallback(() => {
        setCurrentValue(props.value);
        setIsEditing(false);
    }, [props.value]);

    const renderDefaultState = () => {
        return (
            <>
                {props.value}
                <IconWrap
                    className={cn({ "margin-left-small": !isEmpty(props.value) })}
                    icon="edit-empty"
                    title={props.title}
                    disabled={props.disabled}
                    onClick={onEditClick}
                />
            </>
        );
    };

    const renderEditState = () => {
        return (
            <>
                <Input value={currentValue} autoFocus onChange={onChange} />
                <ButtonGroup className="margin-left-small">
                    <Button primary square onClick={onApplyClick}>
                        <IconWrap icon="check-done" />
                    </Button>
                    <Button square onClick={onCancelClick}>
                        <IconWrap icon="clear-close" />
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    return (
        <div className={cn("flex-row align-center text-edit-input-field", props.className)}>
            {isEditing ? renderEditState() : renderDefaultState()}
        </div>
    );
};

interface TextEditProps {
    className?: string;

    title?: string;

    disabled?: boolean;

    /**
     * Renders edit state on initial render.
     */
    edit?: boolean;

    value: string;

    onApply?: (value: string) => void;
}

export default TextEdit;
