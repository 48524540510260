import { connect } from "react-redux";
import ReactModal from "react-modal";

import { modalClose } from "store/modal/actions";

import Button from "components/ui/Button";

import "../style.scss";

export const SimpleDialog = ({ title, text, dispatch, onClose = () => {} }) => (
    <ReactModal isOpen shouldCloseOnOverlayClick={false} onRequestClose={() => dispatch(modalClose())} className="modal simple-dialog">
        <div className="modal-content">
            {title && <div className="modal-header">{title}</div>}
            <div className="modal-body">
                <div className="flex-column">{text}</div>
            </div>
            <div className="modal-footer">
                <Button
                    className="modal-close"
                    onClick={() => {
                        dispatch(modalClose());
                        onClose();
                    }}
                >
                    Close
                </Button>
            </div>
        </div>
    </ReactModal>
);

export default connect()(SimpleDialog);
