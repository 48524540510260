import { isEmpty } from "lodash";
import { memo, useCallback, useMemo } from "react";
import cn from "classnames";

import Icon from "components/ui/IconNew";
import IconWrap from "components/ui/Icons";

import { useViews } from "utils/useViews";
import { EMPTY_SPLIT_VIEW_TITLE } from "utils/window";

import { View } from "store/window/types";

import "./style.scss";

const TabDetails = memo((props: TabDetailsProps) => {
    const { containerName, name, side, onActivate, onClose } = props;

    const views = useViews({ containerName });

    const view = useMemo(() => {
        let view = views.find((view: View) => view.name === name);

        if (view === undefined) {
            view = {};
        }

        switch (side) {
            case "left":
                view = {
                    ...view,
                    name: view.leftViewName,
                    component: view.leftViewComponent,
                    tabTitle: view.leftViewTabTitle,
                    tabSubTitle: view.leftViewTabSubTitle,
                    tabIcon: view.leftViewTabIcon,
                    props: view.leftViewProps,
                };

                break;

            case "right":
                view = {
                    ...view,
                    name: view.rightViewName,
                    component: view.rightViewComponent,
                    tabTitle: view.rightViewTabTitle,
                    tabSubTitle: view.rightViewTabSubTitle,
                    tabIcon: view.rightViewTabIcon,
                    props: view.rightViewProps,
                };

                break;

            default:
        }

        return view;
    }, [name, side, views]);

    const { tabTitle, tabSubTitle, tabIcon, active } = view;

    const classNames = cn("flex-row align-center fill-height tab-details", {
        active: active,
        "split-view": side !== undefined,
        "tab-left": side === "left",
        "tab-right": side === "right",
    });

    const onTabClose = useCallback(
        (event, name) => {
            event.stopPropagation();

            onClose?.(name);
        },
        [onClose]
    );

    return (
        <div className={classNames} onClick={() => onActivate(name)}>
            <div className="flex-row align-center fill-height tab-details__content">
                {tabIcon && tabSubTitle !== EMPTY_SPLIT_VIEW_TITLE && (
                    <Icon className="tab-details__content-icon" icon={tabIcon} size="sm" />
                )}
                <div className="tab-details__content-title">
                    {tabTitle && <div className="tab-details__content-main-title">{tabTitle}</div>}
                    <div
                        className={cn("tab-details__content-sub-title", {
                            "with-title": !isEmpty(tabTitle),
                        })}
                    >
                        {tabSubTitle}
                    </div>
                </div>
            </div>
            {onClose && (
                <div className="fill-height tab-details__close-box">
                    <IconWrap
                        dataTestId="close-tab"
                        icon="clear-close"
                        title="Close"
                        noBackground
                        onClick={(event) => onTabClose(event, name)}
                    />
                </div>
            )}
        </div>
    );
});

interface TabDetailsProps {
    /**
     * Type of View's container.
     */
    containerName: string;

    /**
     * Name of the View.
     */
    name: string;

    /**
     * Side of the Split View.
     */
    side?: "right" | "left";

    /**
     * Function that closes a View.
     */
    onClose?: (name: string) => void;

    /**
     * Function that activates a View.
     */
    onActivate: (name: string) => void;
}

export default TabDetails;
