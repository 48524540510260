import { memo, useCallback, useState } from "react";

import ModelVariables from "./ModelVariables";

import DashboardPanel from "components/ui/Dashboard";
import Button from "components/ui/ButtonNew";

import { modelReadOnlyEnabled, actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { MODEL_VARIABLES } from "utils/constants";
import { USER_ACTIONS } from "utils/user/defines";

import { ModelVariablesProps } from "./types";

const ModelVariablesPanel = memo(({ idClient, idProject, idInputLog, modelState, required }: ModelVariablesProps) => {
    const userRights = useUserRights();

    const [isEditing, setIsEditing] = useState(false);

    const onChangeClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const onCancelClick = useCallback(() => {
        setIsEditing(false);
    }, []);

    const headerAction =
        !isEditing &&
        !modelReadOnlyEnabled(modelState, required) &&
        actionAllowed(userRights, USER_ACTIONS.MODEL_VARIABLES_EDIT, required) ? (
            <Button variant="tertiary" padding="md" iconLeft="ui-pencil__edit__create_b_s" iconSize="sm" onClick={onChangeClick}>
                Change Model Variables
            </Button>
        ) : undefined;

    return (
        <DashboardPanel className="model-variables-panel" title="Variables" headerAction={headerAction} pageId={MODEL_VARIABLES}>
            <ModelVariables
                idClient={idClient}
                idProject={idProject}
                idInputLog={idInputLog}
                editing={isEditing}
                modelState={modelState}
                required={required}
                onEditCancel={onCancelClick}
            />
        </DashboardPanel>
    );
});

export default ModelVariablesPanel;
