///
/// Calculations actions
/// resourceName: calculationsResourceName
///
import { calculationsResourceName } from "store/configureResources";

import { getResource } from "store/resources/actions/getResource";
import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { clearResource } from "store/resources/actions/clearResource";

import { Calculation, CalculationResponseModel } from "./types";

export const getCalculations =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            getResource({
                resourceName: calculationsResourceName,
                key: calculationsResourceName,
            })
        );
    };

export const useCalculationList = () =>
    useResource({
        resourceName: calculationsResourceName,
        key: calculationsResourceName,
        transform: (data: CalculationResponseModel) => data?.model,
    }) as [Calculation[], boolean | undefined, any | undefined];

export const createCalculation =
    ({ idProject, idInputLog, resultsId, includePotential, onSuccess, onError }: CreateCalculationParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            createResource({
                resourceName: calculationsResourceName,
                resourceId: calculationsResourceName,
                query: {
                    idProject,
                    idInputLog,
                    resultsId,
                    includePotential,
                },
                onSuccess,
                onError,
            })
        );
    };

export const clearCalculations =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: calculationsResourceName,
                key: calculationsResourceName,
                broadcast: true,
            })
        );
    };

interface CreateCalculationParams {
    /**
     * ID of Project.
     */
    idProject: number;

    /**
     * ID of Model.
     */
    idInputLog: number;

    resultsId: number;

    includePotential: boolean;

    /**
     * Function to run on success action.
     */
    onSuccess?: (action?: any) => void;

    /**
     * Function to run on error action.
     */
    onError?: (action?: any) => void;
}
