///
/// Avoided Costs Setup actions
/// resourceName: avoidedCostsSetupResourceName
///
import { avoidedCostsSetupResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

export const useAvoidedCostsSetup = ({ idInputLog }) =>
    useResource({
        resourceName: avoidedCostsSetupResourceName,
        key: `${avoidedCostsSetupResourceName}-${idInputLog}`,
        path: { idInputLog: idInputLog },
        transform: (data) => data?.model,
    });

export const clearAvoidedCostsSetup =
    ({ idInputLog }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: avoidedCostsSetupResourceName,
                key: `${avoidedCostsSetupResourceName}-${idInputLog}`,
                broadcast: true,
            })
        );
