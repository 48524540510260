import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { createModelLogNote, updateModelLogNote } from "store/resources/actions/scenarioLog/modelLogNotesActions";
import { createModelInputNote, updateModelInputNote } from "store/resources/actions/modelInputs/modelInputNotesActions";

import Button from "components/ui/ButtonNew";
import TextArea from "components/ui/TextArea";

import { NoteFormProps } from "./types";

import "./style.scss";

const NoteForm = memo(
    ({
        idProject,
        idInputLog,
        idModel,
        idStudyLog,
        idDataImportLog,
        idNote,
        noteType,
        isPublic,
        variant,
        formType,
        noteText,
        inputLabel,

        onSave,
        onCancel,
    }: NoteFormProps) => {
        const dispatch = useDispatch();

        const [note, setNote] = useState(noteText || "");

        const [saveEnabled, setSaveEnabled] = useState(false);

        useEffect(() => {
            let enabled = note.length > 0;

            if (formType === "edit") {
                enabled = note !== noteText;
            }

            setSaveEnabled(enabled);
        }, [formType, noteText, note]);

        const onNoteChange = useCallback((event) => {
            setNote(event.target.value);
        }, []);

        /**
         * Creates/updates a note.
         */
        const onSaveClick = useCallback(
            (event) => {
                // Model Log note
                if (variant === "model-log") {
                    switch (formType) {
                        // Creates a new Model Log note
                        case "add":
                            // If adding user note, idStudyLog is 0
                            // which also equals to false
                            if (idProject && idStudyLog !== undefined && note) {
                                setNote("");
                                onSave?.(event);

                                dispatch(
                                    createModelLogNote({
                                        idProject,
                                        idInputLog,
                                        idStudyLog,
                                        idModel,
                                        description: note,
                                        noteType,
                                        isPublic,
                                    })
                                );
                            }

                            break;
                        // Updates an existing Model Log note
                        case "edit":
                            if (idProject && idStudyLog && idNote && note) {
                                dispatch(
                                    updateModelLogNote({
                                        idProject,
                                        idInputLog,
                                        idModel,
                                        idStudyLog,
                                        idModelNote: idNote,
                                        description: note,
                                        noteType,
                                    })
                                );

                                onCancel?.();
                            }

                            break;
                        default:
                    }
                }
                // Model Inputs note
                else {
                    switch (formType) {
                        // Creates a new Model Inputs note
                        case "add":
                            if (idDataImportLog && note) {
                                setNote("");

                                dispatch(
                                    createModelInputNote({
                                        idInputLog,
                                        idDataImportLog,
                                        description: note,
                                        isPublic,
                                    })
                                );
                            }

                            break;
                        // Updates an existing Model Inputs note
                        case "edit":
                            if (idDataImportLog && idNote && note) {
                                dispatch(
                                    updateModelInputNote({
                                        idInputLog,
                                        idDataImportLog,
                                        idInputNote: idNote,
                                        description: note,
                                    })
                                );

                                onCancel?.();
                            }

                            break;
                        default:
                    }
                }
            },
            [
                idProject,
                idInputLog,
                idModel,
                idStudyLog,
                idDataImportLog,
                note,
                noteType,
                formType,
                variant,
                idNote,
                isPublic,
                onSave,
                onCancel,
                dispatch,
            ]
        );

        const onCancelClick = useCallback(() => {
            setNote("");

            onCancel?.();
        }, [onCancel]);

        return (
            <div className="note-form">
                <TextArea label={inputLabel} placeholder="Write a note" value={note} onChange={(event) => onNoteChange(event)} />
                <IdsButtonGroup
                    customClasses="note-form__actions-container"
                    position={formType === "add" ? "left" : "right"}
                    spaceBetween="lg"
                >
                    <Button
                        variant="primary"
                        padding="md"
                        iconLeft={formType === "add" ? "communication-send_b_s" : undefined}
                        isDisabled={!saveEnabled}
                        onClick={onSaveClick}
                    >
                        {formType === "add" ? "Send note" : "Save changes"}
                    </Button>
                    <Button variant="secondary" padding="md" onClick={onCancelClick}>
                        Cancel
                    </Button>
                </IdsButtonGroup>
            </div>
        );
    }
);

export default NoteForm;
