import { isEmpty } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";
import { useProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useProjectFuels } from "store/resources/actions/fuel/fuelActions";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import Radio from "components/ui/Input/Radio";
import TextArea from "components/ui/TextArea";

import { useProjectFromStore } from "utils/useProjectFromStore";
import { hasInternalUserRole } from "utils/user";
import { isNullOrWhitespace } from "utils/string";

import { Territory, Fuel } from "types/types";
import { ProjectInput } from "store/resources/actions/projectInput/types";
import { EditModelModalProps } from "layouts/Modal/ModelDashboardModal/types";

const EditModel = memo((props: EditModelModalProps) => {
    const { idClient, idProject, onEdit, onCancel } = props;

    // Resources

    const [allModels = []] = useProjectInputs({ idProject });
    const [allComponentModels] = useComponentModels({ idProject });

    const [projectTerritories, isLoadingProjectTerritories] = useProjectTerritories({ idProject });
    const [projectFuels, isLoadingProjectFuels] = useProjectFuels({ idProject });

    // Selectors

    const project = useProjectFromStore({ idClient, idProject });

    // States

    const [modelName, setModelName] = useState(props.modelName || "");
    const [modelDescription, setModelDescription] = useState(props.modelDescription || "");
    const [modelAnalyst, setModelAnalyst] = useState(props.modelAnalyst);
    const [modelReviewer, setModelReviewer] = useState(props.modelReviewer);
    const [modelTerritory, setModelTerritory] = useState(props.modelTerritory || undefined);
    const [modelFuel, setModelFuel] = useState(props.modelFuel || undefined);

    // Memos

    const userItems = useMemo(() => {
        const manager =
            project?.idManager && project?.managerFullName
                ? [
                      {
                          label: project.managerFullName,
                          value: project.idManager,
                      },
                  ]
                : [];

        const members = project?.members
            ? project.members.map((member) => ({
                  label: member.fullName || "",
                  value: member.idUser,
              }))
            : [];

        return [...manager, ...members];
    }, [project]);

    const modelNameExists = useMemo(() => {
        return (
            (allModels.some((model: ProjectInput) => model.name.toLowerCase() === modelName.toLowerCase().trim()) ||
                allComponentModels.some((model) => model.name.toLowerCase() === modelName.toLowerCase().trim())) &&
            modelName !== props.modelName
        );
    }, [props.modelName, allModels, allComponentModels, modelName]);

    const territoryItems = useMemo(() => {
        let territoryItems = [];

        if (!isEmpty(projectTerritories)) {
            territoryItems = projectTerritories.map((territory: Territory) => ({
                label: territory.name + " " + territory.alias,
                value: territory.idTerritory,
            }));
        }

        return territoryItems;
    }, [projectTerritories]);

    const isSaveDisabled = modelNameExists || isNullOrWhitespace(modelName);

    const onChange = useCallback((value, name) => {
        switch (name) {
            case "modelName":
                setModelName(value);

                break;

            case "modelDescription":
                setModelDescription(value);

                break;

            case "modelAnalyst":
                setModelAnalyst(value);

                break;

            case "modelReviewer":
                setModelReviewer(value);

                break;

            case "modelTerritory":
                setModelTerritory(value);

                break;

            case "modelFuel":
                setModelFuel(parseInt(value));

                break;

            default:
        }
    }, []);

    const onSaveClick = useCallback(() => {
        onEdit({
            modelName,
            modelDescription,
            modelAnalyst,
            modelReviewer,
            modelTerritory,
            modelFuel,
            onSuccess: onCancel,
        });
    }, [modelName, modelDescription, modelAnalyst, modelReviewer, modelTerritory, modelFuel, onEdit, onCancel]);

    return (
        <>
            <div className="flex-column modal-padding">
                {isLoadingProjectTerritories || isLoadingProjectFuels ? (
                    <div className="flex-column align-center">
                        <IconLoading />
                    </div>
                ) : (
                    <FieldGroup>
                        <Input
                            label="Name"
                            placeholder="Territory Sector Fuel"
                            required
                            value={modelName}
                            error={modelNameExists}
                            msgText={modelNameExists ? "This name is already in use" : ""}
                            onChange={(event) => onChange(event.target.value, "modelName")}
                        />
                        <TextArea
                            label="Description"
                            placeholder="Type a model description"
                            value={modelDescription}
                            onChange={(event) => onChange(event.target.value, "modelDescription")}
                        />
                        {hasInternalUserRole() && (
                            <>
                                <Dropdown
                                    label="Analyst responsible"
                                    placeholder="Select from the list"
                                    value={modelAnalyst ?? undefined}
                                    items={userItems}
                                    withFilter
                                    onChange={(value) => onChange(value, "modelAnalyst")}
                                />
                                <Dropdown
                                    label="Reviewer responsible"
                                    placeholder="Select from the list"
                                    value={modelReviewer ?? undefined}
                                    items={userItems}
                                    withFilter
                                    onChange={(value) => onChange(value, "modelReviewer")}
                                />
                            </>
                        )}
                        <Dropdown
                            label="Territory"
                            placeholder="Select from the list"
                            value={modelTerritory}
                            items={territoryItems}
                            withFilter
                            onChange={(value) => onChange(value, "modelTerritory")}
                        />
                        <FieldGroup direction="row" label="Fuel">
                            {!isEmpty(projectFuels) &&
                                projectFuels.map((fuel: Fuel) => (
                                    <Radio
                                        key={`fuel-${fuel.name}`}
                                        id={`fuel-${fuel.idFuel}`}
                                        name="Fuel"
                                        label={fuel.name}
                                        value={fuel.idFuel}
                                        checked={fuel.idFuel === modelFuel}
                                        onChange={(event) => onChange(event.target.value, "modelFuel")}
                                    />
                                ))}
                        </FieldGroup>
                    </FieldGroup>
                )}
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button variant="primary" padding="lg" isDisabled={isSaveDisabled} onClick={onSaveClick}>
                    Save
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default EditModel;
