import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { clearProjectLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { useLabelVariableList, updateLabelVariables } from "store/resources/actions/variables/labelVariablesActions";

import Variables from "layouts/common/Variables";

import IconLoading from "components/ui/Icons/IconLoading";

import { useVariablesTransformByGroupName } from "utils/useVariablesTransform";

import { IndexSignature } from "types/types";
import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";
import { VariablesTransformed, VariablesResponseModel } from "store/resources/actions/variables/types";

const useTransform = (data: VariablesResponseModel) => useVariablesTransformByGroupName(data?.model);

const ProjectLabels = memo(({ project, editing, onEditCancel }: ProjectDashboardPanelProps) => {
    const { idProject } = project;

    const dispatch = useDispatch();

    const [projectLabelVariables, isLoading] = useLabelVariableList({ idProject, transform: useTransform });

    const [labelValues, setLabelValues] = useState<IndexSignature<string>>({});

    const variablesSavedOnProject = useMemo(() => {
        return projectLabelVariables?.some((v1: VariablesTransformed[]) =>
            v1.some((v2: VariablesTransformed) => v2.lastUpdatedBy !== null)
        );
    }, [projectLabelVariables]);

    const onUpdateVariableClick = useCallback(() => {
        dispatch(
            updateLabelVariables({
                idProject,
                values: labelValues,
                onComplete: () => {
                    dispatch(clearProjectLogItems({ idProject }));

                    setLabelValues({});
                },
            })
        );
    }, [idProject, labelValues, dispatch]);

    return (
        <>
            {isLoading ? (
                <IconLoading />
            ) : (
                <Variables
                    variablesLevel="project"
                    values={labelValues}
                    projectVariables={projectLabelVariables}
                    variablesSavedOnProject={variablesSavedOnProject}
                    editing={editing}
                    infoMessage="All models use local values until you save them on the Project level"
                    setValues={setLabelValues}
                    onSave={onUpdateVariableClick}
                    onEditCancel={onEditCancel}
                />
            )}
        </>
    );
});

export default ProjectLabels;
