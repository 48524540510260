import { twoFactorResourceName } from "store/configureVDSMResources";

import { useResource } from "store/resources/actions/useResource";

export const useTwoFactor = ({
    userName,
    userNumber,
    skip,
    onError,
}: {
    userName?: string;
    userNumber?: string;
    skip?: boolean;
    onError?: (message: string) => void;
}) =>
    useResource({
        resourceName: twoFactorResourceName,
        key: skip ? undefined : userName ?? userNumber,
        path: {
            userName: userName ?? userNumber,
        },
        onError: () => {
            onError("Two factor authentication check failed. Please try again later!");
        },
    }) as [{ twoFactorRequired: boolean; qrCodeExists: boolean }, boolean | undefined, any];
