// RESOURCE NAMES

// Authentication resource names

export const twoFactorResourceName = "2fa";
export const usersAuthenticationCodeResourceName = "usersAuthCode";

// Users resource names

export const userPasswordResourceName = "userPassword";
export const usersQuickResponseCodeResourceName = "usersQrCode";
export const userThemesResourceName = "userThemes";

export const availableVDSMResources = [
    {
        resourceName: usersAuthenticationCodeResourceName,
        resourceUrl: `${process.env.REACT_APP_VDSM_USERS_API_BASE_URL}authcode`,
    },
    {
        resourceName: userPasswordResourceName,
        resourceUrl: `${process.env.REACT_APP_VDSM_USERS_API_BASE_URL}password`,
    },
    {
        resourceName: usersQuickResponseCodeResourceName,
        resourceUrl: `${process.env.REACT_APP_VDSM_USERS_API_BASE_URL}{userNumber}/qrcode`,
    },
    {
        resourceName: userThemesResourceName,
        resourceUrl: `${process.env.REACT_APP_VDSM_USERS_API_BASE_URL}themes`,
    },
    {
        resourceName: twoFactorResourceName,
        resourceUrl: `${process.env.REACT_APP_VDSM_AUTHENTICATION_API_BASE_URL}users/{userName}/twofactor`,
    },
];
