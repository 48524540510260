import { memo } from "react";

import FieldGroup from "components/ui/FieldGroup";
import IconWrap from "components/ui/Icons";
import IconWithLabel from "components/ui/Icons/IconWithLabel";

const Icons = memo(() => {
    return (
        <div className="flex-column">
            <div className="ui-library__element-group__sub-title">Icons from Vision DSM</div>
            <FieldGroup className="flex-wrap" direction="row">
                <IconWrap icon="add-create-plus-circle-filled" />
                <IconWrap icon="arrow-in-circle-left-empty" />
                <IconWrap icon="arrow-in-circle-right-empty" />
                <IconWrap icon="arrow-thin-right" />
                <IconWrap icon="assignment" />
                <IconWrap icon="assignment-self" />
                <IconWrap icon="attachment" />
                <IconWrap icon="backup-restore-empty" />
                <IconWrap icon="bookmark-empty" />
                <IconWrap icon="bookmark-filled" />
                <IconWrap icon="checkbox-checked-filled" />
                <IconWrap icon="checkbox-unchecked-empty" />
                <IconWrap icon="check-circle-empty" />
                <IconWrap icon="check-circle-filled" />
                <IconWrap icon="check-done" />
                <IconWrap icon="circle-exclamation-filled" />
                <IconWrap icon="circle-radio-button-checked-filled" />
                <IconWrap icon="circle-radio-button-unchecked-empty" />
                <IconWrap icon="clear-circle" />
                <IconWrap icon="clear-close" />
                <IconWrap icon="control-point-duplicate-empty" />
                <IconWrap icon="copy-link" />
                <IconWrap icon="edit-empty" />
                <IconWrap icon="cut-scissors-filled" />
                <IconWrap icon="delete-trash-empty" />
                <IconWrap icon="delete-trash-filled" />
                <IconWrap icon="document-text" />
                <IconWrap icon="do-not-disturb-on-remove-circle-empty" />
                <IconWrap icon="download" />
                <IconWrap icon="exit-door-empty" />
                <IconWrap icon="eye-visibility-empty" />
                <IconWrap icon="hub-empty" />
                <IconWrap icon="help-empty" />
                <IconWrap icon="help-filled" />
                <IconWrap icon="info-empty" />
                <IconWrap icon="open-new-window" />
                <IconWrap icon="play-in-circle-filled" />
                <IconWrap icon="plus" />
                <IconWrap icon="print" />
                <IconWrap icon="search" />
                <IconWrap icon="settings-applications-empty" />
                <IconWrap icon="shevron-in-circle-up-filled" />
                <IconWrap icon="shevron-keyboard-arrow-left" />
                <IconWrap icon="shevron-keyboard-arrow-right" />
                <IconWrap icon="shevron-small-down-expand-more" />
                <IconWrap icon="shevron-small-up-expand-less" />
                <IconWrap icon="swap-vertical" />
                <IconWrap icon="update-refresh" />
                <IconWrap icon="upload" />
                <IconWrap icon="view-module-empty" />
                <IconWrap icon="view-stream-view-agenda-empty" />
                <IconWrap icon="view-stream-view-agenda-filled" />
                <IconWrap icon="warning-report-problem-empty" />
                <IconWrap icon="warning-report-problem-filled" />
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Vision Insight icons
            </div>
            <FieldGroup className="flex-wrap" direction="row">
                <IconWrap icon="alert-error-empty" />
                <IconWrap icon="arrow-thin-left" />
                <IconWrap icon="assessment-empty" />
                <IconWrap icon="chart-pie-circular-empty" />
                <IconWrap icon="circle-small-empty" />
                <IconWrap icon="folder-closed-empty" />
                <IconWrap icon="folder-open-empty" />
                <IconWrap icon="fullscreen" />
                <IconWrap icon="lightbulb-empty" />
                <IconWrap icon="note-empty" />
                <IconWrap icon="octagon-exclamation-empty" />
                <IconWrap icon="octagon-exclamation-filled" />
                <IconWrap icon="pause" />
                <IconWrap icon="powerbi-reports" />
                <IconWrap icon="repeat-empty" />
                <IconWrap icon="reply" />
                <IconWrap icon="step-back" />
                <IconWrap icon="swap-horizontal" />
                <IconWrap icon="run-filled" />
                <IconWrap icon="vi-upload" />
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                SVG icons
            </div>
            <FieldGroup className="flex-wrap" direction="row">
                <IconWrap icon="moon-filled" />
                <IconWrap icon="brightness-filled" />
                <IconWrap icon="document-table-empty" />
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Icon sizes
            </div>
            <FieldGroup className="align-center flex-wrap" direction="row">
                <IconWrap icon="octagon-exclamation-empty" title="Big icon" iconBig />
                <IconWrap icon="octagon-exclamation-empty" title="Medium icon" iconMedium />
                <IconWrap icon="octagon-exclamation-empty" title="Default icon" />
                <IconWrap icon="octagon-exclamation-empty" title="Small icon" iconSmall />
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Disabled and clickable icons
            </div>
            <FieldGroup className="align-center flex-wrap" direction="row">
                <IconWrap icon="octagon-exclamation-empty" title="Disabled icon" disabled />
                <IconWrap icon="octagon-exclamation-empty" title="Clickable icon" clickable />
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Active icons
            </div>
            <FieldGroup className="align-center flex-wrap" direction="row">
                <IconWrap icon="octagon-exclamation-empty" title="Primary, active, big icon" iconBig active />
                <IconWrap icon="octagon-exclamation-empty" title="Secondary, active, big icon" iconBig active secondary />
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Icon with text sizes
            </div>
            <FieldGroup>
                <IconWithLabel icon="octagon-exclamation-empty" iconWithLabelMedium>
                    Medium size icon and text
                </IconWithLabel>
                <IconWithLabel icon="octagon-exclamation-empty">Default size icon and text</IconWithLabel>
                <IconWithLabel icon="octagon-exclamation-empty" iconWithLabelSmall>
                    Small size icon and text
                </IconWithLabel>
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Disabled and clickable icons with text
            </div>
            <FieldGroup>
                <IconWithLabel icon="octagon-exclamation-empty" disabled>
                    Disabled icon with text
                </IconWithLabel>
                <IconWithLabel icon="octagon-exclamation-empty" clickable>
                    Clickable icon with text
                </IconWithLabel>
            </FieldGroup>
            <div className="ui-library__element-group__sub-title" style={{ marginTop: "20px" }}>
                Icon on the right
            </div>
            <div>
                <IconWithLabel icon="open-new-window" iconRight>
                    Icon and text
                </IconWithLabel>
            </div>
        </div>
    );
});

export default Icons;
