import cn from "classnames";

import Icon from "components/ui/IconNew";
import Checkbox from "components/ui/Input/Checkbox";
import Label from "components/ui/Label";
import { Card } from "components/ui/Cards";

import { CheckboxProps } from "components/ui/Input/Checkbox/types";

import "./style.scss";

const CheckboxCard = (props: CheckboxProps) => {
    return (
        <Label className={cn("checkbox-card", props.className, { "checkbox-card__checked": props.checked })} htmlFor={props.id}>
            <Card
                contentClassName="flex-row align-center justify-space-between"
                selected={props.checked}
                readOnly={props.readOnly}
                cardWithInput
            >
                {props.label && <div>{props.label}</div>}
                {!props.readOnly ? (
                    <Checkbox id={props.id} name={props.name} checked={props.checked} onChange={props.onChange} />
                ) : (
                    <div className="flex-row align-center">
                        <span>{props.checked ? "On" : "Off"}</span>
                        <Icon
                            className={cn("checkbox-card__read-only-icon", { checked: props.checked })}
                            icon={props.checked ? "ui-checked__check_circle_b_f" : "ui-block__not_interested__prohibited_b_s"}
                            size="md"
                        />
                    </div>
                )}
            </Card>
        </Label>
    );
};

export default CheckboxCard;
