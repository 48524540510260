import { useState, useEffect, useCallback, useRef } from "react";
import ReactResizeDetector from "react-resize-detector";

import IconWrap from "components/ui/Icons";

import { useUnmounted } from "utils/useUnmounted";
import { scrollEnd } from "utils/dom";

import "./style.scss";

// Show controls only if scroll longer than content + treshold;
const defaultTreshold = 3;
const CHILD_VERTICAL_SCROLL_TRESHOLD = 10;

// Note: This component is only used in one place and is quite out-dated,
// might need to think about remove it (if it's not working properly)
const ScrollControls = ({ activeItemIndex, targetRef, noWrap, iconSmall, updateInterval, horizontalScroll = true, onChange }) => {
    const unmounted = useUnmounted();

    const [scrollArrows, setScrollArrows] = useState({ left: false, right: false });
    const updateIntervalTimer = useRef();
    const childrenCount = targetRef?.current?.children?.length ? targetRef.current.children.length : 0;
    const lastScrollLeft = useRef();

    const updateScrollable = useCallback(() => {
        const node = targetRef && targetRef.current;

        if (!unmounted.current && node) {
            setScrollArrows((prevValue) => {
                const left = node.scrollLeft > 0;
                const treshold = left ? 0 : defaultTreshold;
                const right = Math.ceil(node.scrollLeft + node.offsetWidth + treshold) < node.scrollWidth;

                if (prevValue.left !== left || prevValue.right !== right) {
                    const newValue = { left, right };

                    if (lastScrollLeft.current !== node.scrollLeft) {
                        lastScrollLeft.current = node.scrollLeft;
                        onChange && onChange(newValue);
                    }

                    return newValue;
                }

                return prevValue;
            });
        }
    }, [targetRef, onChange, unmounted]);

    useEffect(() => {
        if (!unmounted.current) {
            updateScrollable();
        }
    }, [childrenCount, updateScrollable, unmounted]);

    useEffect(() => {
        if (!unmounted.current) {
            let timer = updateIntervalTimer.current;

            if (!timer && updateInterval > 0) {
                timer = setInterval(() => {
                    updateScrollable();
                }, updateInterval);
            } else {
                clearInterval(timer);
            }

            return () => {
                clearInterval(timer);
            };
        }
    }, [updateInterval, updateScrollable, unmounted]);

    useEffect(() => {
        if (!unmounted.current) {
            const node = targetRef && targetRef.current;
            let isScrolling;

            const onScroll = () => {
                clearTimeout(isScrolling);
                isScrolling = setTimeout(() => {
                    updateScrollable();
                }, 50);
            };

            const onWeel = (event) => {
                if (!event.shiftKey && node.scrollWidth > node.clientWidth && horizontalScroll) {
                    // check if we are scrolling child element
                    if (event.path?.length > 0) {
                        for (let i = 0; i < event.path.length; i++) {
                            const child = event.path[i];

                            // End search if there are no other children with scroll
                            if (child === node) {
                                break;
                            }

                            // Use default behavior if child has vertical scrollbar
                            if (child.scrollHeight > child.clientHeight + CHILD_VERTICAL_SCROLL_TRESHOLD) {
                                return;
                            }
                        }
                    }

                    event.preventDefault();
                    node.scrollBy({
                        top: 0,
                        left: event.deltaY * 0.5,
                    });
                }
            };

            if (node) {
                node.addEventListener("scroll", onScroll);
                node.addEventListener("wheel", onWeel);
            }

            return () => {
                if (node) {
                    node.removeEventListener("scroll", onScroll);
                    node.removeEventListener("wheel", onWeel);
                }
            };
        }
    }, [targetRef, horizontalScroll, updateScrollable, unmounted]);

    useEffect(() => {
        if (!unmounted.current) {
            const node = targetRef && targetRef.current;

            if (activeItemIndex && node && node.children[activeItemIndex]) {
                node.children[activeItemIndex].scrollIntoView({
                    behavior: "smooth",
                });

                scrollEnd(node, () => {
                    updateScrollable();
                });
            } else {
                updateScrollable();
            }
        }
    }, [activeItemIndex, targetRef, updateScrollable, updateInterval, unmounted]);

    const scroll = useCallback(
        (direction) => {
            if (!unmounted.current) {
                const node = targetRef && targetRef.current;

                if (node) {
                    if (direction === "left") {
                        node.scrollLeft = node.scrollLeft - node.clientWidth;
                    }

                    if (direction === "right") {
                        node.scrollLeft = node.scrollLeft + node.clientWidth;
                    }

                    scrollEnd(node, () => {
                        updateScrollable();
                    });
                }
            }
        },
        [targetRef, updateScrollable, unmounted]
    );

    const onResize = useCallback(() => {
        if (!unmounted.current) {
            updateScrollable();
        }
    }, [updateScrollable, unmounted]);

    const controls = (
        <>
            {scrollArrows.left && (
                <div className="scroll-control left">
                    <IconWrap
                        icon="shevron-keyboard-arrow-left"
                        title="Scroll Left"
                        iconMedium={!iconSmall}
                        noBackground
                        onClick={() => scroll("left")}
                    />
                </div>
            )}
            {scrollArrows.right && (
                <div className="scroll-control right">
                    <IconWrap
                        icon="shevron-keyboard-arrow-right"
                        title="Scroll Right"
                        iconMedium={!iconSmall}
                        noBackground
                        onClick={() => scroll("right")}
                    />
                </div>
            )}
            <ReactResizeDetector handleWidth onResize={onResize} />
        </>
    );

    return <div className="scroll-controls">{noWrap ? controls : <div className="main-grid-wrap">{controls}</div>}</div>;
};

export default ScrollControls;
