import * as React from "react";
import cn from "classnames";

import { ActionLabelProps } from "./types";

import "./style.scss";

const ActionLabel = (props: ActionLabelProps) => {
    const isTabable = Boolean(props.onClick);

    return (
        <span className={cn("action-label", props.className)} title={props.title} tabIndex={isTabable ? 0 : -1} onClick={props.onClick}>
            {props.children}
        </span>
    );
};

export default ActionLabel;
