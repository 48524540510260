import { isEmpty } from "lodash";
import { useCallback } from "react";
import ClassNames from "classnames";

import IconWrap from "components/ui/Icons";
import Label from "components/ui/Label";

import { UploadFileProps } from "components/ui/Input/types";

import { useDropzone } from "utils/useDropzone";

import "./style.scss";

// If "preview" property is needed in future:
//  - https://stackoverflow.com/questions/60525442/react-dropzone-preview-property-missing
const UploadFile = (props: UploadFileProps) => {
    const { onRemove } = props;

    const { getRootProps, getInputProps } = useDropzone({
        options: {
            disabled: props.disabled,
            multiple: props.multiple,
            onDrop: props.onDrop,
        },
        accept: props.accept,
    });

    const classNames = ClassNames("flex-column justify-center upload-file", props.className, {
        disabled: props.disabled,
        error: props.error,
    });

    const _onRemove = useCallback(
        (event, file) => {
            event.stopPropagation();

            let newFiles: File[] = [];

            if (props.multiple) {
                newFiles = props.files.filter((f) => f.name !== file.name);
            }

            onRemove(newFiles);
        },
        [props.multiple, props.files, onRemove]
    );

    return (
        <div className="upload-file-container">
            {props.label && <Label required={props.required}>{props.label}</Label>}
            <div className={classNames} {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="flex-row align-center">
                    <IconWrap icon="upload" clickable />
                    <div className="upload-file-container__text-container">
                        <span>{`Drop file${props.multiple ? "s" : ""} to upload, or `}</span>
                        <span className="upload-file-container__highlight">browse</span>
                    </div>
                </div>
            </div>
            {props.accept && isEmpty(props.files) && (
                <div className="upload-file-container__accept">{`Allowed types: ${props.accept.join(", ")}`}</div>
            )}
            {!isEmpty(props.files) && (
                <ul className="upload-file-container__file-list-container">
                    {props.files.map((file, index) => (
                        <li
                            key={`file-${index}`}
                            className="flex-row align-center justify-space-between upload-file-container__file-list-item"
                        >
                            <div className="flex-row align-center">
                                <IconWrap icon="document-text" clickable />
                                <div className="upload-file-container__file-name">{`${file.name} (${file.size} bytes)`}</div>
                            </div>
                            <IconWrap icon="clear-close" title="Remove" onClick={(event) => _onRemove(event, file)} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default UploadFile;
