import ClassNames from "classnames";

import IconLoading from "components/ui/Icons/IconLoading";

import { useFocusClasses } from "utils/useFocusClasses";

import { ButtonProps } from "./types";

import "./style.scss";

const Button = (props: ButtonProps) => {
    const [_onFocus, _onBlur] = useFocusClasses({ disabled: props.disabled });

    const classNames = ClassNames("button", props.className, props.icon, {
        button__primary: props.primary,
        button__danger: props.danger,
        button__square: props.square,
        "with-icon": props.icon && !props.loading,
    });

    const type = props.type || "button";

    return (
        <button
            className={classNames}
            type={type}
            title={props.title}
            disabled={props.disabled || props.loading}
            onFocus={_onFocus}
            onBlur={_onBlur}
            onClick={props.onClick}
        >
            {props.loading ? (
                <div className="flex-row justify-center">
                    <IconLoading />
                </div>
            ) : (
                props.children
            )}
        </button>
    );
};

export default Button;
