import { memo } from "react";

import DashboardHeader from "pages/ManageProject/ProjectionsContent/ProjectDashboard/DashboardHeader";

import FileNavigatorPanel from "pages/common/FileNavigatorPanel";

import { ProjectDashboardViewsProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

import "./style.scss";

/**
 * Project File navigator view.
 */
const ProjectDashboardFiles = memo(({ project, clientName, displayFiles, onDisplayFiles, onReturn }: ProjectDashboardViewsProps) => {
    return (
        <div className="project-dashboard-files">
            <div className="project-dashboard-files__header-container">
                <div>
                    <DashboardHeader
                        project={project}
                        clientName={clientName}
                        displayFiles={displayFiles}
                        onDisplayFiles={onDisplayFiles}
                        onReturn={onReturn}
                    />
                </div>
            </div>
            <div className="project-dashboard-files__body-container">
                <div>
                    <FileNavigatorPanel project={project} clientName={clientName} />
                </div>
            </div>
        </div>
    );
});

export default ProjectDashboardFiles;
