///
/// Active Views let's the user know if other users
/// also have this view opened on their end.
///
/// Avoided Costs Active Views actions
/// resourceName: activeViewsAvoidedCostsResourceName
///
import { batch } from "react-redux";

import { SET_AVOIDED_COSTS_VIEW_ID } from "store/actionTypes";
import { activeViewsAvoidedCostsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

export const useActiveViewsAvoidedCostsList = ({ idProject, idInputLog }) => {
    return useResource({
        resourceName: activeViewsAvoidedCostsResourceName,
        key: `${activeViewsAvoidedCostsResourceName}-${idProject}-${idInputLog}`,
        path: { idInputLog },
        transform: (data) => data?.model,
    });
};

export const createActiveViewsAvoidedCosts =
    ({ idProject, idInputLog }) =>
    (dispatch) =>
        dispatch(
            createResource({
                resourceName: activeViewsAvoidedCostsResourceName,
                path: { idInputLog },
                onSuccess: (response) => {
                    batch(() => {
                        dispatch({
                            type: SET_AVOIDED_COSTS_VIEW_ID,
                            selection: {
                                idProject,
                                idInputLog,
                                viewId: response.data,
                            },
                        });

                        dispatch(clearActiveViewsAvoidedCosts({ idProject, idInputLog }));
                    });
                },
            })
        );

export const deleteActiveViewsAvoidedCosts =
    ({ idProject, idInputLog, previousViewId }) =>
    (dispatch) =>
        dispatch(
            deleteResource({
                resourceName: activeViewsAvoidedCostsResourceName,
                path: { idInputLog },
                body: {
                    previousViewId,
                },
                onSuccess: () => {
                    dispatch(clearActiveViewsAvoidedCosts({ idProject, idInputLog }));
                },
            })
        );

export const clearActiveViewsAvoidedCosts =
    ({ idProject, idInputLog }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: activeViewsAvoidedCostsResourceName,
                key: `${activeViewsAvoidedCostsResourceName}-${idProject}-${idInputLog}`,
                broadcast: true,
            })
        );

export const clearActiveViewsAvoidedCostsAll = () => (dispatch) =>
    dispatch(
        clearResource({
            resourceName: activeViewsAvoidedCostsResourceName,
            key: new RegExp(`^${activeViewsAvoidedCostsResourceName}.*$`),
        })
    );
