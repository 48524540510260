import cn from "classnames";
import { createRef } from "react";

import TabListItem from "components/ui/List/TabList/TabListItem";
import IconLoading from "components/ui/Icons/IconLoading";
import ScrollControls from "components/ui/ScrollControls";

import "./style.scss";

const TabList = ({
    items = [],
    activeItem = {},
    tabListGhost,
    tabListGhostNoBorder,
    loading,
    onClick,
    equalItemsWidth,
    scrollRef,
    className,
}) => {
    scrollRef = createRef();

    const noBorder = tabListGhost && tabListGhostNoBorder;

    return (
        <div className={cn("tab-list", className, { "tab-list-ghost": tabListGhost, "tab-list-ghost-without-border": noBorder })}>
            <div ref={scrollRef} className="tabs flex">
                {items.map((item) => (
                    <TabListItem
                        key={item.id}
                        item={item}
                        active={item.id === activeItem.id}
                        onClick={onClick}
                        equalItemsWidth={equalItemsWidth}
                    />
                ))}
                {loading && <IconLoading />}
            </div>
            <ScrollControls targetRef={scrollRef} />
        </div>
    );
};

export default TabList;
