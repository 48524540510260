import cn from "classnames";

import { IdsTag } from "@emergn-infinity/ids-react";

import { TagProps } from "./types";

import "./style.scss";

const Tag = (props: TagProps) => {
    const classNames = cn("aeg-tag", props.className);

    return (
        <IdsTag
            customClasses={classNames}
            variant={props.variant}
            size={props.size}
            iconLeft={props.iconLeft}
            iconRight={props.iconRight}
            iconSize={props.iconSize}
            isDisabled={props.isDisabled}
            onClick={props.onClick}
            rest={{
                "data-testid": props.dataTestId,
                title: props.title || "",
            }}
        >
            {props.children}
        </IdsTag>
    );
};

export default Tag;
