import cn from "classnames";

import { CardsContainerProps } from "./types";

import "./style.scss";

/**
 * NOTE: IDS Card component is currently in Beta state.
 * Once it becomes live, can try to use it instead.
 */
const CardsContainer = (props: CardsContainerProps) => {
    const classNames = cn("cards-container", {
        "cards-container-add-card": props.addCard,
    });

    return <div className={classNames}>{props.children}</div>;
};

export default CardsContainer;

export { Card } from "./Card";
