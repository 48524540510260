import cn from "classnames";

import { ExpandableListProps } from "components/ui/ExpandableComponent/types";

import "./style.scss";

const ExpandableList = (props: ExpandableListProps) => {
    const classNames = cn("fill-width expandable-list", {
        expanded: props.isOpen,
    });

    return (
        <div {...props.regionProps} className={classNames}>
            {props.subTitle && <div className="expandable-list__header">{props.subTitle}</div>}
            <div className="expandable-list__items">{props.children}</div>
        </div>
    );
};

export default ExpandableList;
