import { get, isEmpty } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IdsTabs, IdsTabItem } from "@emergn-infinity/ids-react";

import {
    // RUN_MODULE_START
    RUN_MODULE_V2_START,
    RUN_MODULE_V2_STARTED,
    PAUSE_MODULE_V2_START,
} from "store/actionTypes";

import { selectModule } from "store/modules/actions";

import { useModules, deleteModule } from "store/resources/actions/calculationWorkflow/modulesActions";

import Button from "components/ui/ButtonNew";
import ComponentWithDropdown from "components/ui/Dropdown/ComponentWithDropdown";
import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import StepBox from "components/ui/StepBox";

import { moduleTabs } from "./utils";

import { actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { moduleTypeLabels } from "utils/constants";
import { USER_ACTIONS } from "utils/user/defines";

import { CalculationWorkflowSidebarProps } from "./types";

import "./style.scss";

const CalculationWorkflowSidebar = memo(
    ({ viewIndex, idClient, idProject, idModel, idInputLog, moduleId, modelName, modelRequired }: CalculationWorkflowSidebarProps) => {
        const dispatch = useDispatch();

        const runStatus = useSelector((state) => get(state, `runModule.${idModel}-${moduleId}`));

        const [modules, isLoadingModules] = useModules({ idModel });

        const userRights = useUserRights();

        const [activeTab, setActiveTab] = useState(moduleTabs[0].id);

        const selectedModule = useMemo(() => {
            let selectedModule;

            if (!isEmpty(modules) && !isLoadingModules) {
                selectedModule = modules.find((module) => module.moduleId === moduleId);
            }

            return selectedModule;
        }, [moduleId, modules, isLoadingModules]);

        const moduleActionItems = useMemo(() => {
            const moduleActionItems = [];

            const onDeleteSelect = () => {
                dispatch(
                    deleteModule({
                        idProject,
                        idModel,
                        idInputLog,
                        moduleId,
                        onSuccess: () => {
                            dispatch(selectModule({ viewIndex, idModel, moduleId: undefined }));
                        },
                    })
                );
            };

            if (actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired)) {
                moduleActionItems.push({
                    label: "Delete",
                    icon: "delete-trash-empty",
                    disabled: selectedModule?.hasChildren,
                    onSelect: onDeleteSelect,
                });
            }

            return moduleActionItems;
        }, [viewIndex, idProject, idModel, idInputLog, moduleId, modelRequired, userRights, selectedModule, dispatch]);

        const onCalculateClick = useCallback(
            (event: React.MouseEvent<HTMLIdsButtonElement, MouseEvent>) => {
                event.stopPropagation();

                // dispatch({
                //     type: RUN_MODULE_START,
                //     payload: {
                //         idProject,
                //         idModel,
                //         moduleId,
                //     },
                // });

                dispatch({
                    type: RUN_MODULE_V2_START,
                    payload: {
                        idProject,
                        idModel,
                        moduleId,
                    },
                });
            },
            [idProject, idModel, moduleId, dispatch]
        );

        const onTabClick = useCallback((tabId) => {
            setActiveTab(tabId);
        }, []);

        return (
            <SideNavContent className="calculation-workflow-sidebar">
                {selectedModule === undefined ? (
                    <IconLoading />
                ) : (
                    <>
                        <SideNavHeader>
                            <div className="flex-row align-center justify-space-between">
                                <div className="flex-row align-center">
                                    <StepBox className="calculation-workflow-sidebar__step-number" number={selectedModule.moduleOrder} />
                                    <span className="calculation-workflow-sidebar__module-name">{`${
                                        moduleTypeLabels[selectedModule.type]
                                    }`}</span>
                                </div>
                                <div>
                                    <ComponentWithDropdown
                                        className="calculation-workflow-sidebar__more"
                                        component={<Icon icon="ui-menu-dots_horizontal" clickable />}
                                        withoutShevron
                                        items={moduleActionItems}
                                    />
                                </div>
                            </div>
                        </SideNavHeader>
                        <SideNavBody>
                            {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                                <div>
                                    <Button
                                        variant="secondary"
                                        padding="md"
                                        iconLeft="multimedia-play__start_b_s"
                                        iconSize="sm"
                                        isDisabled={
                                            ["RUNNING", "DATA_MISSING"].includes(selectedModule.runState) ||
                                            [
                                                // "RUN_MODULE_START",
                                                RUN_MODULE_V2_START,
                                                // "RUN_MODULE_STARTED",
                                                RUN_MODULE_V2_STARTED,
                                                // "PAUSE_MODULE_START",
                                                PAUSE_MODULE_V2_START,
                                                // "PAUSE_MODULE_STARTED",
                                            ].includes(String(runStatus))
                                        }
                                        onClick={onCalculateClick}
                                    >
                                        Run Calculation
                                    </Button>
                                </div>
                            )}
                            <div className="flex-column flex-one-in-column calculation-workflow-sidebar__tabs-container">
                                {/* IdsTabs */}
                                <IdsTabs key={`${moduleId}-tabs`} customClasses="fill-height">
                                    {/* IdsTabItem */}
                                    {moduleTabs.map((tab) => (
                                        <IdsTabItem
                                            key={`${moduleId}-${tab.id}-header`}
                                            idValue={`${moduleId}-${tab.id}`}
                                            slot="header"
                                            label={tab.label1}
                                            isActive={activeTab === tab.id}
                                            onClick={() => onTabClick(tab.id)}
                                        />
                                    ))}

                                    {/* IdsTabPanel */}
                                    {moduleTabs.map((tab) => {
                                        const Component = tab.component;

                                        let componentProps: any = {
                                            moduleId,
                                            id: `${moduleId}-${tab.id}`,
                                            ariaLabelledBy: tab.label2,
                                            isActive: activeTab === tab.id,
                                        };

                                        if (tab.id === "configurations") {
                                            componentProps = {
                                                ...componentProps,
                                                idProject,
                                                idModel,
                                                idInputLog,
                                                studyCaseId: selectedModule.studyCaseId,
                                                type: selectedModule.type,
                                                modelRequired,
                                            };
                                        } else if (tab.id === "calculations") {
                                            componentProps = {
                                                ...componentProps,
                                                idModel,
                                            };
                                        } else if (tab.id === "inputs") {
                                            componentProps = {
                                                ...componentProps,
                                                type: selectedModule.type,
                                            };
                                        } else if (tab.id === "runs") {
                                            componentProps = {
                                                ...componentProps,
                                                idClient,
                                                idProject,
                                                idModel,
                                                idInputLog,
                                                studyCase: selectedModule.studyCase,
                                                modelName,
                                                modelRequired,
                                            };
                                        }

                                        return <Component key={`${moduleId}-${tab.id}-panel`} {...componentProps} />;
                                    })}
                                </IdsTabs>
                            </div>
                        </SideNavBody>
                    </>
                )}
            </SideNavContent>
        );
    }
);

export default CalculationWorkflowSidebar;
