import { isBoolean, some } from "lodash";

import { StudyCase } from "store/resources/actions/studyCase/types";

export const getStudyCaseNumberValue = (value: number | boolean) => {
    return isBoolean(value) ? (value ? "1" : "0") : value;
};

export const getStudyCaseBooleanValue = (value: string) => {
    return value === "1" ? true : false;
};

export const getResultIsPotential = (studyCasesRes: StudyCase[]) => {
    return some(studyCasesRes, (studyCase) => studyCase?.displayName?.includes("Potential") && studyCase.value === "1");
};

export const isSwitcher = (value: string) => {
    return value === "0" || value === "1";
};
