///
/// Uploaded Project Files actions
/// resourceName: projectFilesUploadedResourceName
///
import { projectFilesUploadedResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

import { UploadedProjectFile, ProjectFileParams } from "./types";

export const useUploadedProjectFiles = ({ idProject }: ProjectFileParams) =>
    useResource({
        resourceName: projectFilesUploadedResourceName,
        key: `${projectFilesUploadedResourceName}-${idProject}`,
        path: {
            idProject,
        },
    }) as [UploadedProjectFile[], boolean | undefined, any];

export const clearUploadedProjectFiles =
    ({ idProject }: ProjectFileParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: projectFilesUploadedResourceName,
                key: `${projectFilesUploadedResourceName}-${idProject}`,
                broadcast: true,
            })
        );
