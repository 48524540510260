import { memo, useMemo, useState } from "react";

import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";

import Models from "./Models";

import ComponentWithDropdown from "components/ui/Dropdown/ComponentWithDropdown";
import LabelWithIcon from "components/ui/LabelWithIcon";
import DashboardPanel from "components/ui/Dashboard";

import { modelReadOnlyEnabled } from "pages/utils";

import { MODELS } from "utils/constants";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { ProjectInput } from "store/resources/actions/projectInput/types";
import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";
import { isEmpty } from "lodash";

const ModelsPanel = memo(({ viewIndex, project }: ProjectDashboardPanelProps) => {
    const { idProject } = project;

    const [models, isLoadingModels] = useProjectInputs({
        idProject,
        transform: (data) => data?.filter((model) => model?.active === true),
    });

    const [componentModels, isLoadingComponentModels] = useComponentModels({ idProject, onlyActives: true });

    // States

    const [selectToCombine, setSelectToCombine] = useState(false);
    const [selectToRun, setSelectToRun] = useState(false);
    const [selectToCompare, setSelectToCompare] = useState(false);

    // Memos

    const allModels = useMemo(() => [...(models || []), ...(componentModels || [])], [models, componentModels]);

    const allModelsFinalized = useMemo(() => {
        let allModelsFinalized = false;

        if (
            !isEmpty(models) &&
            !isLoadingModels &&
            models.every((model: ProjectInput) => modelReadOnlyEnabled(model.modelState, model.required))
        ) {
            allModelsFinalized = true;
        }

        if (
            !isEmpty(componentModels) &&
            !isLoadingComponentModels &&
            componentModels.every((model) => modelReadOnlyEnabled(model.modelState, model.required))
        ) {
            allModelsFinalized = true;
        } else {
            allModelsFinalized = false;
        }

        return allModelsFinalized;
    }, [models, isLoadingModels, componentModels, isLoadingComponentModels]);

    const actionItems = useMemo(() => {
        const actionItems = [];

        const onCombineSelect = () => {
            setSelectToCombine(true);
            setSelectToRun(false);
            setSelectToCompare(false);
        };

        const onRunSelect = () => {
            setSelectToRun(true);
            setSelectToCombine(false);
            setSelectToCompare(false);
        };

        const onCompareSelect = () => {
            setSelectToCompare(true);
            setSelectToCombine(false);
            setSelectToRun(false);
        };

        if (hasAnyOfPermissions([USER_ACTIONS.MODELS_COMBINE])) {
            actionItems.push({
                label: "Combine Models",
                active: selectToCombine,
                icon: "edit-empty",
                onSelect: onCombineSelect,
            });
        }

        // Do not show this option
        // if all Models are finalized
        if (!allModelsFinalized && hasAnyOfPermissions([USER_ACTIONS.CALCULATIONS_RUN])) {
            actionItems.push({
                label: "Run Models",
                active: selectToRun,
                icon: "play-in-circle-filled",
                onSelect: onRunSelect,
            });
        }

        if (hasAnyOfPermissions([USER_ACTIONS.MODELS_COMPARE])) {
            actionItems.push({
                label: "Compare Models",
                active: selectToCompare,
                icon: "view-stream-view-agenda-empty",
                onSelect: onCompareSelect,
            });
        }

        return actionItems;
    }, [selectToCombine, selectToRun, selectToCompare, allModelsFinalized]);

    // JSX Element

    const headerAction = <ComponentWithDropdown component={<ModelActionsDropdownComponent />} items={actionItems} withoutShevron />;

    // Main render

    return (
        <DashboardPanel className="models-panel" title="Models" headerAction={headerAction} pageId={MODELS}>
            <Models
                viewIndex={viewIndex}
                project={project}
                isLoadingModels={isLoadingModels || isLoadingComponentModels}
                allModels={allModels}
                selectToCombine={selectToCombine}
                setSelectToCombine={setSelectToCombine}
                selectToRun={selectToRun}
                setSelectToRun={setSelectToRun}
                selectToCompare={selectToCompare}
                setSelectToCompare={setSelectToCompare}
            />
        </DashboardPanel>
    );
});

const ModelActionsDropdownComponent = memo(() => {
    return (
        <div className="flex-row align-center clickable model-actions-dropdown-component">
            <LabelWithIcon className="more-icon" icon="ui-more_vertical_b_a" clickable>
                Model Actions
            </LabelWithIcon>
        </div>
    );
});

export default ModelsPanel;
