import { memo, useCallback } from "react";
import { batch, useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { deleteProject } from "store/resources/actions/project/projectActions";

import Button from "components/ui/ButtonNew";

import { DeleteProjectModalProps } from "layouts/Modal/HomeDashboardModal/types";

const DeleteProject = memo(({ idClient, idProject, projectName, userNumber, onLoading, onCancel }: DeleteProjectModalProps) => {
    const dispatch = useDispatch();

    const onDeleteClick = useCallback(() => {
        batch(() => {
            onLoading?.();

            dispatch(
                deleteProject({
                    idClient,
                    idProject,
                    userNumber,
                    onComplete: onCancel,
                })
            );
        });
    }, [idClient, idProject, userNumber, onLoading, onCancel, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding">
                <div>
                    <strong>{projectName}</strong> project and all associated data (imported models and calculation results) will be deleted
                    permanently.
                </div>
                <div>Are you sure you want to delete?</div>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button
                    variant="primary"
                    color="system-critical"
                    iconLeft="ui-delete_forever_b_s"
                    iconSize="sm"
                    padding="lg"
                    onClick={onDeleteClick}
                >
                    Delete Permanently
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default DeleteProject;
