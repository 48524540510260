import { isEmpty, uniqBy } from "lodash";
import { memo, useMemo } from "react";
import cn from "classnames";

import { useModuleInputs } from "store/resources/actions/calculationWorkflow/moduleInputsActions";

import { IdsTabPanel } from "@emergn-infinity/ids-react";

import IconLoading from "components/ui/Icons/IconLoading";
import StatusMsg from "components/ui/StatusMsg";
import CustomList from "components/ui/List/CustomList";

import { drPotentialTables } from "layouts/Sidebar/CalculationWorkflowSidebar/utils";

import { moduleTypes } from "utils/constants";

import { InputsProps } from "./types";
import { ModuleInput } from "store/resources/actions/calculationWorkflow/types";

import "./style.scss";

const Inputs = memo(({ moduleId, type, id, ariaLabelledBy, isActive }: InputsProps) => {
    const [moduleInputs, isLoadingModuleInputs] = useModuleInputs({ moduleId });

    const [baselineInputs, eePotentialInputs, drPotentialInputs] = useMemo(() => {
        let baselineInputs: ModuleInput[] = [];
        let eePotentialInputs: ModuleInput[] = [];
        let drPotentialInputs: ModuleInput[] = [];

        if (!isEmpty(moduleInputs) && !isLoadingModuleInputs) {
            const modifiedModuleInputs = uniqBy(moduleInputs, "name");

            if (type === moduleTypes.BASELINE) {
                baselineInputs = modifiedModuleInputs.filter((input) => input.category === "MANDATORY") || [];
            } else if (type === moduleTypes.EE_POTENTIAL) {
                eePotentialInputs =
                    modifiedModuleInputs.filter((input) => input.category === "POTENTIAL" && !drPotentialTables.includes(input.name)) || [];
            } else if (type === moduleTypes.DR_POTENTIAL) {
                drPotentialInputs =
                    modifiedModuleInputs.filter((input) => input.category === "POTENTIAL" && drPotentialTables.includes(input.name)) || [];
            }
        }

        return [baselineInputs, eePotentialInputs, drPotentialInputs];
    }, [type, moduleInputs, isLoadingModuleInputs]);

    const classNames = cn("flex-column fill-height remove-background inputs-tab", { "is-hidden": !isActive });

    const renderInput = (moduleInput: ModuleInput) => {
        return (
            <div key={`module-input-${moduleInput.name}`} className="list-item-row module-input-row">
                <div className="item-value column-input">
                    <div className="module-input-row__sheet-name">{moduleInput.name}</div>
                    <StatusMsg
                        success={moduleInput.status === "DONE"}
                        error={moduleInput.status === "ERROR"}
                        // TODO: Replace with SVG icons
                        icon={moduleInput.status === "ERROR" ? "circle-exclamation-filled" : "check-circle-empty"}
                        message={`${moduleInput.status === "ERROR" ? "Import" : "Imported"} from ${moduleInput.fileName}`}
                    />
                </div>
            </div>
        );
    };

    return (
        <IdsTabPanel customClasses={classNames} idValue={id} slot="panel" ariaLabelledBy={ariaLabelledBy}>
            {isLoadingModuleInputs ? (
                <IconLoading />
            ) : (
                <>
                    {type === moduleTypes.BASELINE && !isEmpty(baselineInputs) && (
                        <>
                            <div className="flex-column justify-center inputs-tab__header">Baseline Tables</div>
                            <CustomList
                                // @ts-ignore
                                items={baselineInputs}
                                scrollBody
                                withFade
                                renderItem={renderInput}
                            />
                        </>
                    )}
                    {type === moduleTypes.EE_POTENTIAL && !isEmpty(eePotentialInputs) && (
                        <>
                            <div className="flex-column justify-center inputs-tab__header">EE Potential Tables</div>
                            <CustomList
                                // @ts-ignore
                                items={eePotentialInputs}
                                scrollBody
                                withFade
                                renderItem={renderInput}
                            />
                        </>
                    )}
                    {type === moduleTypes.DR_POTENTIAL && !isEmpty(drPotentialInputs) && (
                        <>
                            <div className="flex-column justify-center inputs-tab__header">DR Potential Tables</div>
                            <CustomList
                                // @ts-ignore
                                items={drPotentialInputs}
                                scrollBody
                                withFade
                                renderItem={renderInput}
                            />
                        </>
                    )}
                </>
            )}
        </IdsTabPanel>
    );
});

export default Inputs;
