import { memo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_ERROR_CLEAR } from "store/actionTypes";
import { userPasswordResourceName } from "store/configureVDSMResources";
import { useResource } from "store/resources/actions/useResource";

import { changePassword } from "store/resources/actions/user/userActions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import FieldGroup from "components/ui/FieldGroup";
import Input from "components/ui/Input";

const ERROR_NEW_PASSWORD_SAME_AS_OLD = "New password can't be the same as old password";
const ERROR_PASSWORDS_DO_NOT_MATCH = "Passwords don't match.";
const ERROR_LOGIN_FAILED = "The username/password combination you entered cannot be found";

const ChangePasswordForm = memo(() => {
    const dispatch = useDispatch();

    // @ts-ignore
    const errorMessage = useSelector((state) => state.changePassword.message);

    // Resources

    const [passwordInfo] = useResource({
        resourceName: userPasswordResourceName,
        key: userPasswordResourceName,
    });

    // States

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const [changeDetected, setChangeDetected] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Variables

    const isError = errorMessage?.length > 0;

    const isOldPasswordError = errorMessage === ERROR_LOGIN_FAILED;
    const isNewPasswordError = errorMessage !== ERROR_LOGIN_FAILED && errorMessage !== ERROR_PASSWORDS_DO_NOT_MATCH && isError;
    const isRepeatPasswordError = errorMessage === ERROR_PASSWORDS_DO_NOT_MATCH;

    const isSaveEnabled = oldPassword.length > 0 && newPassword.length > 0 && repeatPassword.length > 0;

    const newPasswordHelpText = isNewPasswordError ? errorMessage : passwordInfo?.description;

    if ((oldPassword.length > 0 || newPassword.length > 0 || repeatPassword.length > 0) && !changeDetected) {
        setChangeDetected(true);
    }

    const clearError = useCallback(() => {
        dispatch({
            type: CHANGE_PASSWORD_ERROR_CLEAR,
        });
    }, [dispatch]);

    const onChange = useCallback(
        (event, name) => {
            clearError();

            switch (name) {
                case "oldPassword":
                    setOldPassword(event.target.value);

                    break;
                case "newPassword":
                    setNewPassword(event.target.value);

                    break;
                case "repeatPassword":
                    setRepeatPassword(event.target.value);

                    break;
                default:
            }
        },
        [clearError]
    );

    const revertChanges = useCallback(() => {
        setOldPassword("");
        setNewPassword("");
        setRepeatPassword("");

        setChangeDetected(false);
    }, []);

    const onPasswordChangeError = useCallback(
        (data) => {
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                message: data.message,
            });

            setIsSubmitting(false);
        },
        [dispatch]
    );

    const onPasswordChangeSuccess = useCallback(() => {
        revertChanges();

        setIsSubmitting(false);

        toast.success("Password changed successfully");
    }, [revertChanges]);

    const onSaveClick = useCallback(() => {
        // Old and new passwords are the same
        if (oldPassword.length > 0 && newPassword.length > 0 && oldPassword === newPassword) {
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                message: ERROR_NEW_PASSWORD_SAME_AS_OLD,
            });
        }
        // Passwords do not match
        else if (newPassword.length > 0 && repeatPassword.length > 0 && newPassword !== repeatPassword) {
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                message: ERROR_PASSWORDS_DO_NOT_MATCH,
            });
        }
        // No errors - change password
        else if (!isError) {
            setIsSubmitting(true);

            dispatch(
                changePassword({
                    oldPassword,
                    newPassword,
                    onError: onPasswordChangeError,
                    onSuccess: onPasswordChangeSuccess,
                })
            );
        }
    }, [oldPassword, newPassword, repeatPassword, isError, onPasswordChangeError, onPasswordChangeSuccess, dispatch]);

    const onClearClick = useCallback(() => {
        revertChanges();
    }, [revertChanges]);

    return (
        <div className="flex-column align-center gap-4">
            <div className="fill-width pt-4">
                <FieldGroup>
                    <Input
                        type="password"
                        label="Old password"
                        required
                        placeholder="Enter your old password"
                        value={oldPassword}
                        error={isOldPasswordError}
                        msgText={isOldPasswordError && errorMessage}
                        onChange={(event) => onChange(event, "oldPassword")}
                    />
                    <Input
                        type="password"
                        label="New password"
                        required
                        placeholder="Enter your new password"
                        value={newPassword}
                        error={isNewPasswordError}
                        msgText={newPasswordHelpText}
                        onChange={(event) => onChange(event, "newPassword")}
                    />
                    <Input
                        type="password"
                        label="Repeat password"
                        required
                        value={repeatPassword}
                        error={isRepeatPasswordError}
                        msgText={isRepeatPasswordError && errorMessage}
                        onChange={(event) => onChange(event, "repeatPassword")}
                    />
                </FieldGroup>
            </div>
            <IdsButtonGroup spaceBetween="lg">
                <div style={{ width: "165px" }}>
                    <Button variant="primary" isDisabled={!isSaveEnabled} padding="lg" fullWidth onClick={onSaveClick}>
                        {isSubmitting ? "Saving..." : "Save"}
                    </Button>
                </div>
                <div style={{ width: "165px" }}>
                    <Button variant="secondary" isDisabled={!changeDetected} padding="lg" fullWidth onClick={onClearClick}>
                        Clear
                    </Button>
                </div>
            </IdsButtonGroup>
        </div>
    );
});

export default ChangePasswordForm;
