import { VDSM_USER_SAVE, USER_DELETE } from "store/actionTypes";

import { getVdsmUser, deleteVdsmUser } from "utils/user";

import { UserState, UserAction } from "./types";

const initialState = null;

export const reducer = (state: UserState | null = initialState, action: UserAction) => {
    state = getVdsmUser();

    switch (action.type) {
        case VDSM_USER_SAVE:
            state = action.vdsmUser;

            break;

        case USER_DELETE:
            deleteVdsmUser();

            state = null;

            break;

        default:
            break;
    }

    return state;
};
