import { memo } from "react";

import DashboardPanel from "components/ui/Dashboard";

import EmbeddedReport from "pages/common/EmbeddedReport";

import { STANDALONE_REPORT_EMBEDDED_PANEL } from "utils/constants";

import { EmbeddedReportProps } from "pages/common/EmbeddedReport/types";

const EmbeddedPanel = memo(({ idProject, idReport, idPbi, reportType, fileName }: EmbeddedReportProps) => {
    return (
        <DashboardPanel className="embedded-panel" pageId={STANDALONE_REPORT_EMBEDDED_PANEL}>
            <EmbeddedReport idProject={idProject} idReport={idReport} idPbi={idPbi} reportType={reportType} fileName={fileName} />
        </DashboardPanel>
    );
});

export default EmbeddedPanel;
