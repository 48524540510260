import { get, isEmpty } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { projectFileResourceName } from "store/configureResources";

import { useComponentModelTemplates } from "store/resources/actions/componentModel/componentModelTemplateActions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import Checkbox from "components/ui/Input/Checkbox";
import Radio from "components/ui/Input/Radio";
import UploadFile from "components/ui/Input/UploadFile";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import WarningMsg from "components/ui/StatusMsg/WarningMsg";
import Separator from "components/ui/Separator";
import TextArea from "components/ui/TextArea";

import { FORM_VALIDATION_KEYS, FORM_VALIDATION_ERROR_MESSAGES } from "layouts/common/ImportData";
import ModelInputsTableSelection, { modelInputsToSelections } from "layouts/common/ModelInputsTableSelection";

import { useAllModelInputTables } from "utils/useModelInputTables";

import { ImportDataFormProps } from "./types";
import { Territory, Fuel } from "types/types";

import "./style.scss";

const ImportDataForm = memo(
    ({
        idProject,
        idManager,
        idInputLog,

        managerFullName,
        members,

        modelName,
        modelTemplate,
        modelRequired,
        modelDescription,
        modelAnalyst,
        modelReviewer,
        modelTerritory,
        modelFuel,
        files,
        uploadedFile,
        selectedSheets,
        uploadedProjectFiles,
        projectTerritories,
        projectFuels,

        importMode,

        formValidationErrors,
        formValidationWarnings,

        displayInModal,
        isComponentModel,

        setFiles,
        setSelectedSheets,

        onChange,
        onCreate,
        onImport,
        onCancel,
    }: ImportDataFormProps) => {
        // @ts-ignore
        const projectFileStatus: any = useSelector((state) => get(state, `resources.${projectFileResourceName}`));

        const [isLoadMAPTemplate, setIsLoadMAPTemplate] = useState(false);
        const [renderDatasetProperties, setRenderDatasetProperties] = useState(isComponentModel ? displayInModal : true);

        const [templates, isLoadingTemplates] = useComponentModelTemplates({ loadResource: isComponentModel || false });

        const { equipmentInputsTables, nonEquipmentInputsTables, extraInputsTables, resultTables, isLoadingAllModelInputs } =
            useAllModelInputTables();

        // Variables and useMemos

        const loadMAPTemplate = templates?.find((t) => t.name === "Excel LoadMAP Results");

        let buttonText = "Import Data";

        if (isComponentModel && isEmpty(files) && uploadedFile === "") {
            buttonText = "Create Model";
        }

        const templateItems = useMemo(() => {
            let templateItems: any[] = [];

            if (!isEmpty(templates) && !isLoadingTemplates) {
                templateItems = templates.map((template) => ({
                    label: template.name,
                    value: template.idTemplate,
                }));
            }

            return templateItems;
        }, [templates, isLoadingTemplates]);

        const userItems = useMemo(() => {
            let manager = [];
            let membersList: any[] = [];

            if (idManager && managerFullName) {
                manager.push({
                    label: managerFullName,
                    value: idManager,
                });
            }

            if (members && !isEmpty(members)) {
                membersList = members.map((member: any) => ({
                    label: member.fullName || "",
                    value: member.idUser,
                }));
            }

            return [...manager, ...membersList];
        }, [idManager, managerFullName, members]);

        const uploadedFileItems = useMemo(() => {
            let uploadedFileItems: any[] = [];

            if (!isEmpty(uploadedProjectFiles)) {
                // @ts-ignore
                uploadedFileItems = uploadedProjectFiles.map((file) => ({
                    label: file.name,
                    value: file.name,
                }));
            }

            return uploadedFileItems;
        }, [uploadedProjectFiles]);

        const territoryItems = useMemo(() => {
            let territoryItems: any[] = [];

            if (!isEmpty(projectTerritories)) {
                territoryItems = projectTerritories.map((territory: Territory) => ({
                    label: `${territory.name} ${territory.alias}`,
                    value: territory.idTerritory,
                }));
            }

            return territoryItems;
        }, [projectTerritories]);

        // useEffects

        useEffect(() => {
            if (!displayInModal && !isLoadingAllModelInputs) {
                setSelectedSheets(modelInputsToSelections(equipmentInputsTables, true));
            }
        }, [displayInModal, equipmentInputsTables, isLoadingAllModelInputs, setSelectedSheets]);

        useEffect(() => {
            if (modelTemplate && loadMAPTemplate && loadMAPTemplate.idTemplate === modelTemplate) {
                setIsLoadMAPTemplate(true);
            }
        }, [modelTemplate, loadMAPTemplate]);

        useEffect(() => {
            if (!displayInModal && isComponentModel) {
                if (importMode === "import" && !isEmpty(files)) {
                    setRenderDatasetProperties(true);
                } else if (importMode === "reference" && uploadedFile !== "") {
                    setRenderDatasetProperties(true);
                } else {
                    setRenderDatasetProperties(false);
                }
            }
        }, [files, uploadedFile, importMode, displayInModal, isComponentModel]);

        useEffect(() => {
            if (modelTemplate === null && !isEmpty(templateItems)) {
                // By default select the first item
                // which should be "LoadMAP [version]" template
                onChange(templateItems[0].value, "modelTemplate");
            }
        }, [modelTemplate, templateItems, onChange]);

        useEffect(() => {
            if (projectTerritories.length === 1) {
                // Automatically select territory
                // if there's only one territory available
                onChange(projectTerritories[0].idTerritory, "modelTerritory");
            }
        }, [projectTerritories, onChange]);

        useEffect(() => {
            if (projectFuels.length === 1) {
                // Automatically select fuel
                // if there's only one fuel available
                onChange(projectFuels[0].idFuel, "modelFuel");
            }
        }, [projectFuels, onChange]);

        // Helper functions

        const templateHelper = useCallback(
            (value) => {
                if (value === loadMAPTemplate?.idTemplate) {
                    setIsLoadMAPTemplate(true);

                    setSelectedSheets(modelInputsToSelections(resultTables, true));
                } else {
                    setIsLoadMAPTemplate(false);

                    setSelectedSheets(modelInputsToSelections(equipmentInputsTables, true));
                }
            },
            [equipmentInputsTables, resultTables, loadMAPTemplate, setSelectedSheets]
        );

        // Event handlers

        const onDrop = useCallback(
            (event) => {
                setFiles?.(event);
            },
            [setFiles]
        );

        const onRemoveFile = useCallback(
            (files) => {
                setFiles?.(files);
            },
            [setFiles]
        );

        return (
            <>
                <div
                    className={cn("flex-column import-data-form", {
                        "modal-padding with-scroll import-data-form-modal": displayInModal,
                    })}
                >
                    {displayInModal && (isLoadingTemplates || isLoadingAllModelInputs) ? (
                        <div className="flex-column align-center">
                            <IconLoading />
                        </div>
                    ) : (
                        <div className="flex-column">
                            {!displayInModal && (
                                <>
                                    <div className="import-data-form__title">
                                        {isComponentModel ? "Create Component Model" : "Import Data"}
                                    </div>
                                    <FieldGroup>
                                        <Checkbox
                                            id="required-for-project"
                                            label="Required for project"
                                            checked={modelRequired}
                                            onChange={(event) => onChange(event.target.checked, "modelRequired")}
                                        />
                                        <div>
                                            <Input
                                                label="Model name"
                                                required
                                                placeholder="Territory Sector Fuel"
                                                value={modelName}
                                                error={formValidationErrors[FORM_VALIDATION_KEYS.NAME_EXISTS]}
                                                onChange={(event) => onChange(event.target.value, "modelName")}
                                            />
                                            {formValidationErrors[FORM_VALIDATION_KEYS.NO_NAME] && (
                                                <ErrorMsg message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_NAME]} />
                                            )}
                                            {formValidationErrors[FORM_VALIDATION_KEYS.NAME_EXISTS] && (
                                                <ErrorMsg message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NAME_EXISTS]} />
                                            )}
                                        </div>
                                        {isComponentModel && (
                                            <div>
                                                <Dropdown
                                                    label="Model template"
                                                    placeholder="Not selected"
                                                    required
                                                    value={modelTemplate === null ? undefined : modelTemplate}
                                                    items={templateItems}
                                                    withFilter
                                                    onChange={(value) => onChange(value, "modelTemplate", templateHelper)}
                                                />
                                                {formValidationErrors[FORM_VALIDATION_KEYS.NO_TEMPLATE] && (
                                                    <ErrorMsg message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_TEMPLATE]} />
                                                )}
                                            </div>
                                        )}
                                        <TextArea
                                            label="Model description"
                                            value={modelDescription}
                                            placeholder="Type a model description"
                                            onChange={(event) => onChange(event.target.value, "modelDescription")}
                                        />
                                        <Dropdown
                                            label="Analyst responsible"
                                            placeholder="Select from the list"
                                            value={modelAnalyst ?? undefined}
                                            items={userItems}
                                            withFilter
                                            onChange={(value) => onChange(value, "modelAnalyst")}
                                        />
                                        <Dropdown
                                            label="Reviewer responsible"
                                            placeholder="Select from the list"
                                            value={modelReviewer ?? undefined}
                                            items={userItems}
                                            withFilter
                                            onChange={(value) => onChange(value, "modelReviewer")}
                                        />
                                    </FieldGroup>
                                    <Separator line />
                                    <div className="import-data-form__section-separator">From Project data structure</div>
                                </>
                            )}
                            {!displayInModal && (
                                <>
                                    <Dropdown
                                        label="Territory"
                                        placeholder="Not selected"
                                        required
                                        value={modelTerritory === null ? undefined : modelTerritory}
                                        items={territoryItems}
                                        withFilter
                                        onChange={(value) => onChange(value, "modelTerritory")}
                                    />
                                    {!formValidationErrors[FORM_VALIDATION_KEYS.NO_TERRITORY] &&
                                        formValidationWarnings[FORM_VALIDATION_KEYS.NO_PROJECT_TERRITORIES] && (
                                            <WarningMsg
                                                message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_PROJECT_TERRITORIES]}
                                            />
                                        )}
                                    {formValidationErrors[FORM_VALIDATION_KEYS.NO_TERRITORY] && (
                                        <ErrorMsg message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_TERRITORY]} />
                                    )}
                                    <Separator line />
                                    <FieldGroup direction="row" label="Fuel" required>
                                        {!isEmpty(projectFuels) &&
                                            projectFuels.map((fuel: Fuel) => (
                                                <Radio
                                                    key={`fuel-${fuel.name}`}
                                                    id={`fuel-${fuel.idFuel}`}
                                                    name="Fuel"
                                                    label={fuel.name}
                                                    value={fuel.idFuel}
                                                    checked={fuel.idFuel === modelFuel}
                                                    onChange={(event) => onChange(event.target.value, "modelFuel")}
                                                />
                                            ))}
                                    </FieldGroup>
                                    {!formValidationErrors[FORM_VALIDATION_KEYS.NO_FUEL] &&
                                        formValidationWarnings[FORM_VALIDATION_KEYS.NO_PROJECT_FUELS] && (
                                            <WarningMsg message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_PROJECT_FUELS]} />
                                        )}
                                    {formValidationErrors[FORM_VALIDATION_KEYS.NO_FUEL] && (
                                        <ErrorMsg message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_FUEL]} />
                                    )}
                                    <Separator line />
                                </>
                            )}
                            <FieldGroup className="import-data-form__section-separator" direction="row" label="Import mode">
                                <Radio
                                    id="import"
                                    name="importMode"
                                    label="Import New File"
                                    value="import"
                                    checked={importMode === "import"}
                                    onChange={(event) => onChange(event.target.value, "importMode")}
                                />
                                <Radio
                                    id="reference"
                                    name="importMode"
                                    label="Reference Uploaded File"
                                    value="reference"
                                    checked={importMode === "reference"}
                                    onChange={(event) => onChange(event.target.value, "importMode")}
                                />
                            </FieldGroup>
                            {importMode === "import" ? (
                                <UploadFile
                                    required={isComponentModel ? displayInModal : true}
                                    label="Dataset file"
                                    accept={[".csv", ".xls", ".xlsb", ".xlsm", ".xlsx"]}
                                    error={formValidationErrors[FORM_VALIDATION_KEYS.NO_FILE]}
                                    files={files === undefined ? [] : files}
                                    onDrop={onDrop}
                                    onRemove={(files) => onRemoveFile(files)}
                                />
                            ) : (
                                <>
                                    <Dropdown
                                        label="Dataset file"
                                        placeholder="Not selected"
                                        required
                                        value={uploadedFile === "" ? undefined : uploadedFile}
                                        items={uploadedFileItems}
                                        onChange={(value) => onChange(value, "uploadedFile")}
                                    />
                                    {!formValidationErrors[FORM_VALIDATION_KEYS.NO_FILE] &&
                                        formValidationWarnings[FORM_VALIDATION_KEYS.NO_UPLOADED_FILES] && (
                                            <WarningMsg message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_UPLOADED_FILES]} />
                                        )}
                                </>
                            )}
                            {formValidationErrors[FORM_VALIDATION_KEYS.NO_FILE] && (
                                <ErrorMsg message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_FILE]} />
                            )}
                            <Separator line />
                            {renderDatasetProperties && (
                                <>
                                    {isLoadingTemplates || isLoadingAllModelInputs ? (
                                        <IconLoading />
                                    ) : (
                                        <>
                                            {isLoadMAPTemplate ? (
                                                <ModelInputsTableSelection
                                                    idProject={idProject}
                                                    idInputLog={idInputLog}
                                                    title="Import LoadMAP Result tables"
                                                    tables={resultTables}
                                                    displayInModal={displayInModal}
                                                    obligatoryCheck
                                                    sheetSelections={selectedSheets}
                                                    setSheetSelections={setSelectedSheets}
                                                />
                                            ) : (
                                                <>
                                                    <ModelInputsTableSelection
                                                        idProject={idProject}
                                                        idInputLog={idInputLog}
                                                        title="Import Baseline tables"
                                                        tables={equipmentInputsTables}
                                                        displayInModal={displayInModal}
                                                        showSelectAll
                                                        obligatoryCheck
                                                        sheetSelections={selectedSheets}
                                                        setSheetSelections={setSelectedSheets}
                                                    />
                                                    <Separator line />
                                                    <ModelInputsTableSelection
                                                        idProject={idProject}
                                                        idInputLog={idInputLog}
                                                        title="Import Potential tables"
                                                        tables={nonEquipmentInputsTables}
                                                        displayInModal={displayInModal}
                                                        showSelectAll
                                                        obligatoryCheck
                                                        sheetSelections={selectedSheets}
                                                        setSheetSelections={setSelectedSheets}
                                                    />
                                                    <Separator line />
                                                    <ModelInputsTableSelection
                                                        idProject={idProject}
                                                        idInputLog={idInputLog}
                                                        title="Import Additional tables"
                                                        tables={extraInputsTables}
                                                        displayInModal={displayInModal}
                                                        obligatoryCheck
                                                        sheetSelections={selectedSheets}
                                                        setSheetSelections={setSelectedSheets}
                                                    />
                                                </>
                                            )}
                                            {formValidationErrors[FORM_VALIDATION_KEYS.NO_SELECTED_SHEETS] && (
                                                <ErrorMsg
                                                    message={FORM_VALIDATION_ERROR_MESSAGES[FORM_VALIDATION_KEYS.NO_SELECTED_SHEETS]}
                                                />
                                            )}
                                            <Separator line />
                                            <div className="import-data-form__important-note">
                                                *To import Demand Forecast data, upload the required files at the "Project Files"
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {!displayInModal && (
                                <div className="flex-row justify-end">
                                    <Button
                                        variant="primary"
                                        iconLeft={buttonText === "Import Data" ? "arrows-upload_b_a" : undefined}
                                        isLoading={projectFileStatus.isReading}
                                        padding="lg"
                                        onClick={buttonText === "Import Data" ? onImport : onCreate}
                                    >
                                        <>{buttonText}</>
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {displayInModal && (
                    <>
                        {!isLoadingTemplates && !isLoadingAllModelInputs && (
                            <Separator className="import-data-form__actions-separator" line />
                        )}
                        <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                            <Button
                                variant="primary"
                                iconLeft="arrows-upload_b_a"
                                isLoading={projectFileStatus.isReading}
                                padding="md"
                                onClick={onImport}
                            >
                                Import Data
                            </Button>
                            <Button variant="secondary" padding="md" onClick={onCancel}>
                                Cancel
                            </Button>
                        </IdsButtonGroup>
                    </>
                )}
            </>
        );
    }
);

export default ImportDataForm;
