import { isEmpty } from "lodash";
import { memo, useMemo } from "react";

import { useCombinationList } from "store/resources/actions/projectInput/projectInputCombinationActions";

import IconLoading from "components/ui/Icons/IconLoading";
import CustomList from "components/ui/List/CustomList";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import Combination, { renderFilters } from "./Combination";

import { ProjectLevelReportsProps } from "pages/ProjectStakeholderDashboard/ProjectLevelReportsPanel/types";

const HEADERS = {
    combinationName: "Combination name",
};

const ProjectLevelReports = memo(({ idClient, idProject, projectName, reportName }: ProjectLevelReportsProps) => {
    const [allModelCombinations = [], isLoadingModelCombinations] = useCombinationList({ idProject });

    const publishedCombinations = useMemo(() => {
        let publishedCombinations = [];

        if (!isEmpty(allModelCombinations) && !isLoadingModelCombinations) {
            publishedCombinations = allModelCombinations.filter((combination) => combination.bookmarked);
        }

        return publishedCombinations;
    }, [allModelCombinations, isLoadingModelCombinations]);

    const combinationProps = {
        idClient,
        projectName,
        reportName,
    };

    return isLoadingModelCombinations ? (
        <IconLoading />
    ) : isEmpty(publishedCombinations) ? (
        <NothingFoundBlock icon="ui-chart_multiple_b_s" title="There are no published reports" />
    ) : (
        <CustomList
            // @ts-ignore
            headers={HEADERS}
            items={publishedCombinations}
            component={Combination}
            componentProps={combinationProps}
            limit={4}
            pageDisplayCount={3}
            sortable
            paginationAlignToRight
            renderSearch={renderFilters}
        />
    );
});

export default ProjectLevelReports;
