import { isEmpty } from "lodash";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { selectClient } from "store/clients/actions";

import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import CustomList from "components/ui/List/CustomList";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import { Client } from "types/api/ClientResponseModel";
import { ClientListProps } from "pages/HomeDashboard/common/ClientListPanel/types";

import "./style.scss";

const ClientList = memo(({ idClient, clients, loading }: ClientListProps) => {
    const dispatch = useDispatch();

    const onClientClick = useCallback(
        (id) => {
            dispatch(selectClient(id));
        },
        [dispatch]
    );

    const renderItem = (item: Client) => {
        return (
            <div
                key={item.id}
                className={cn("list-item-row", { "selected-row": item.id === idClient })}
                onClick={() => onClientClick(item.id)}
            >
                <div className="flex-row align-center justify-space-between item-value">
                    <div className="client-name">{item.clientName}</div>
                    <Icon className="arrow-in-circle-right-empty" icon="arrows-arrow_in_circle_right_b_s" size="sm" clickable />
                </div>
            </div>
        );
    };

    return (
        <>
            {loading ? (
                <IconLoading />
            ) : (
                <div className="flex-column fill-height client-list">
                    {isEmpty(clients) ? (
                        <div className="flex-column flex-one-in-column">
                            <NothingFoundBlock icon="people-user__identity__person__personal_b_s" title="There are no clients" />
                        </div>
                    ) : (
                        <CustomList
                            // @ts-ignore - remove when CustomList is refactored to TypeScript
                            dataTestId="client-list"
                            searchPlaceholder={clients.length > 5 ? "Type to Search" : undefined}
                            searchFilters={clients.length > 5 ? { clientName: "" } : undefined}
                            items={clients}
                            scrollBody
                            withFade
                            renderItem={renderItem}
                        />
                    )}
                </div>
            )}
        </>
    );
});

export default ClientList;
