import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { modelActions, ackChangeModel } from "store/resources/actions/projectInput/projectInputActions";
import { selectProjection } from "store/projections/actions";

import UserName from "components/ui/UserName";
import Button from "components/ui/Button";

import { projections } from "utils/constants";
import { toLocaleDateTime } from "utils/dateTime";

import { ModelNotificationProps } from "./types";

const ModelNotification = memo((props: ModelNotificationProps) => {
    const { idProject, idInputLog } = props;

    const dispatch = useDispatch();

    const isDeleted = props.status === modelActions.DELETED;

    const action = {
        [modelActions.DELETED]: "deleted permanently",
        [modelActions.WASTED]: "moved to Trash Bin",
        [modelActions.RESTORED]: "restored from Trash Bin",
    }[props.status];

    const onConfirmClick = useCallback(() => {
        dispatch(ackChangeModel({ idProject, idInputLog }));

        if (isDeleted) {
            dispatch(selectProjection({ viewIndex: props.viewIndex, idProjectionView: projections.PROJECT_DASHBOARD, idProject }));
        }
    }, [props.viewIndex, idProject, idInputLog, isDeleted, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding">
                <strong>{props.modelName}</strong>
                {` has been ${action} by `}
                {/* @ts-ignore - remove when UserName is refactored to TypeScript */}
                <UserName userNumber={userNumber} />
                {` at ${toLocaleDateTime(props.lastModified)}`}
            </div>
            <div className="flex-row justify-end modal-actions">
                <Button primary onClick={onConfirmClick}>
                    OK
                </Button>
            </div>
        </>
    );
});

export default ModelNotification;
