import { isEmpty, kebabCase } from "lodash";
import { memo, useMemo } from "react";

import { modelDataImportV3ResourceName } from "store/configureResources";

import { useProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useProjectFuels } from "store/resources/actions/fuel/fuelActions";

import Label from "components/ui/Label";
import LabelWithIcon from "components/ui/LabelWithIcon";
import { DeterminateProgressBar } from "components/ui/ProgressBar";
import Separator from "components/ui/Separator";
import Switcher from "components/ui/Switcher";
import Tag from "components/ui/Tag";

import { hasInternalUserRole } from "utils/user";

import { Territory, Fuel } from "types/types";
import { ModelDashboardEmptyProps } from "pages/ManageProject/ProjectionsContent/ModelDashboard/DashboardEmpty/types";

import "./style.scss";

const DashboardHeader = memo(({ model }: ModelDashboardEmptyProps) => {
    const { idProject, idTerritory, idFuel, analyst, reviewer, name, description, required, modelState } = model;

    // Resources

    const [projectTerritories, isLoadingProjectTerritories] = useProjectTerritories({ idProject });
    const [projectFuels, isLoadingProjectFuels] = useProjectFuels({ idProject });

    const isUploading = Boolean(model.uploadProps?.isUploading);

    // Memos

    const territory = useMemo(() => {
        let territory;

        if (!isEmpty(projectTerritories) && !isLoadingProjectTerritories) {
            territory = projectTerritories.find((territory: Territory) => territory.idTerritory === idTerritory);
        }

        return territory;
    }, [idTerritory, projectTerritories, isLoadingProjectTerritories]);

    const fuel = useMemo(() => {
        let fuel;

        if (!isEmpty(projectFuels) && !isLoadingProjectFuels) {
            fuel = projectFuels.find((fuel: Fuel) => fuel.idFuel === idFuel);
        }

        return fuel;
    }, [idFuel, projectFuels, isLoadingProjectFuels]);

    return (
        <div className="flex-column model-dashboard-header">
            <div className="model-dashboard-header__first-level-container">
                <div className="flex-row align-center model-dashboard-header__top-container">
                    <div>
                        <div className="model-dashboard-header__model">Model</div>
                        <div className="flex-row align-center">
                            <div className="model-dashboard-header__title">{name}</div>
                        </div>
                    </div>
                </div>
                <div className="model-dashboard-header__middle-container">
                    <span>{description}</span>
                </div>
                <div className="flex-row align-center justify-space-between model-dashboard-header__bottom-container">
                    <div className="flex-column flex-one model-dashboard-header__model-details-container">
                        <div className="flex-row model-dashboard-header__model-details">
                            <div>
                                <span className="model-dashboard-header__label">ID: </span>
                                <span>0</span>
                            </div>
                            <div className="margin-left">
                                <span className="model-dashboard-header__label">Territory: </span>
                                <span>{territory?.alias || territory?.name || "None"}</span>
                            </div>
                            <div className="margin-left">
                                <span className="model-dashboard-header__label">Fuel: </span>
                                <span>{fuel?.name || "None"}</span>
                            </div>
                        </div>
                        <div className="flex-row">
                            <div>
                                <span className="model-dashboard-header__label">Analyst responsible: </span>
                                <span>{analyst || "None"}</span>
                            </div>
                            <div className="margin-left">
                                <span className="model-dashboard-header__label">Reviewer responsible: </span>
                                <span>{reviewer || "None"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="model-dashboard-header__model-actions-container">
                        {(required || hasInternalUserRole) && (
                            <div className="model-dashboard-header__status-container">
                                <div className="flex-row align-center">
                                    <Label>Status</Label>
                                    <div className="aeg-tag-wrapper">
                                        <Tag className={kebabCase(modelState)} size="sm">
                                            <>{modelState.toLowerCase()}</>
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="model-dashboard-header__second-level-container">
                <Separator line />
                <div className="flex-row flex-one justify-end">
                    <Switcher active={required === null ? false : required} label="Required for project" disabled />
                </div>
            </div>
            <div className="model-dashboard-header__third-level-container">
                {/* Uploading dataset progress bar */}
                {isUploading && (
                    <div className="model-dashboard-header__progress-bar">
                        <Separator />
                        <DeterminateProgressBar
                            resourceId={model.uploadProps.uploadId}
                            resourceName={modelDataImportV3ResourceName}
                            topElement={
                                <LabelWithIcon
                                    className="model-dashboard-header__progress-bar-top-element"
                                    icon="arrows-sync__autorenew_b_a"
                                >{`Uploading dataset | ${model.uploadProps.fileName}`}</LabelWithIcon>
                            }
                            showPercentage
                        />
                    </div>
                )}
            </div>
        </div>
    );
});

export default DashboardHeader;
