import { memo } from "react";

import { useModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import IconLoading from "components/ui/Icons/IconLoading";

import ScenarioLog from "pages/ManageProject/ProjectionsContent/common/ScenarioLog";

import { ModelLogProps } from "pages/ManageProject/ProjectionsContent/ModelDashboardNew/DashboardDefault/ModelLogPanel/types";
import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";

const headers = {
    actionName: "action",
    started: "started",
    user: "user",
    executionTime: "execution time",
    result: {
        label: "result",
        sortable: false,
    },
    note: {
        label: "",
        sortable: false,
    },
};

const ModelLog = memo(({ dataTestId, idClient, idProject, idModel }: ModelLogProps) => {
    const [modelLogItems, isLoadingModelLogItems] = useModelLogItems({ idProject, idModel });

    const [componentModels = [], isLoadingComponentModels] = useComponentModels({ idProject });

    return (
        <>
            {isLoadingModelLogItems || isLoadingComponentModels ? (
                <IconLoading />
            ) : (
                <ScenarioLog
                    dataTestId={dataTestId}
                    idClient={idClient}
                    idProject={idProject}
                    projections={componentModels}
                    headers={headers}
                    logItems={modelLogItems}
                />
            )}
        </>
    );
});

export default ModelLog;
