import { memo } from "react";

import { useModelInputNotes } from "store/resources/actions/modelInputs/modelInputNotesActions";

import ModelNotes from "layouts/Sidebar/common/ModelNotes";

import { ModelNoteInterface } from "layouts/Sidebar/common/ModelNotes/types";
import { SideNavHeaderProps } from "components/ui/SideNav/SideNavHeader";

const ModelInputNoteSidebar = memo((props: ModelNoteInterface & SideNavHeaderProps) => {
    const { idInputLog, idModel, idDataImportLog, noteType, onClose } = props;

    const [modelInputNotes, isLoading] = useModelInputNotes({ idDataImportLog: idDataImportLog! });

    return (
        <ModelNotes
            idInputLog={idInputLog}
            idModel={idModel}
            idDataImportLog={idDataImportLog}
            notes={modelInputNotes}
            noteType={noteType}
            variant="model-inputs"
            isLoadingNotes={isLoading}
            onClose={onClose}
        />
    );
});

export default ModelInputNoteSidebar;
