import cn from "classnames";

import Input from "components/ui/Input";
import Label from "components/ui/Label";

import { RadioProps } from "./types";

import "./style.scss";

const Radio = (props: RadioProps) => {
    const classNames = cn("flex-row align-center radio-input-container", props.className, {
        disabled: props.disabled,
    });

    const inputClassNames = cn({ checked: props.checked });

    return (
        <div className={classNames}>
            <Input
                inputRef={props.inputRef}
                data-testid={props.dataTestId}
                className={inputClassNames}
                type="radio"
                id={props.id}
                name={props.name}
                disabled={props.disabled}
                readOnly={props.readOnly}
                value={props.value}
                onChange={props.onChange}
            />
            {props.label && (
                <Label htmlFor={props.id} disabled={props.disabled} readOnly={props.readOnly}>
                    {props.label}
                </Label>
            )}
        </div>
    );
};

export default Radio;
