import ClassNames from "classnames";

import IconTriangle from "components/ui/Icons/IconTriangle";
import Tag from "components/ui/Tag";

import { TagWithCustomArrowProps } from "./types";

import "./style.scss";

const TagWithCustomArrow = ({ tagClassName, label }: TagWithCustomArrowProps) => {
    const classNames = ClassNames("tag-with-custom-arrow", tagClassName);

    return (
        <Tag className={classNames} size="sm">
            <div className="flex-row align-center">
                <span>{label}</span>
                <IconTriangle className="margin-left-small" />
            </div>
        </Tag>
    );
};

export default TagWithCustomArrow;
