import { get } from "lodash";
import { batch } from "react-redux";

import { POWERBI_DATASET_REFRESH_REQUESTED, POWERBI_DATASET_REFRESH_STATUS_REQUESTED, HUB_DATASET_REFRESH } from "store/actionTypes";

import { workspaceId } from "utils/constants";

import { RefreshPowerBIDatasetParams } from "store/powerBI/types";

export const refreshPowerBIDataset =
    ({ datasetId }: RefreshPowerBIDatasetParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        // Check if the request is already in progress
        const refreshState = get(getState(), `powerBiRefresh[${workspaceId}-${datasetId}]`);

        if (refreshState?.isReading) {
            return;
        }

        batch(() => {
            dispatch({
                type: POWERBI_DATASET_REFRESH_STATUS_REQUESTED,
                id: `${workspaceId}-${datasetId}`,
                payload: {
                    metadata: "user",
                },
            });

            dispatch({
                type: POWERBI_DATASET_REFRESH_REQUESTED,
                id: `${workspaceId}-${datasetId}`,
            });

            dispatch({
                type: HUB_DATASET_REFRESH,
                datasetId,
                workspaceId,
            });
        });
    };
