import { get } from "lodash";
import { memo } from "react";
import { useSelector } from "react-redux";

import { variablesResourceName } from "store/configureResources";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import ActionLabel from "components/ui/Label/ActionLabel";
import StatusMsg from "components/ui/StatusMsg";

import { getVariableValue } from "layouts/common/Variables/utils";

import { modelReadOnlyEnabled, actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { USER_ACTIONS } from "utils/user/defines";

import { VariablesTransformed } from "store/resources/actions/variables/types";
import { ModelState } from "store/resources/actions/projectInput/types";
import { ComponentModelState } from "store/resources/actions/componentModel/types";
import { CommonVariablesProps } from "layouts/common/Variables";

const ModelVariables = memo(
    ({
        values,
        modelVariables,
        differsFromProject,
        editing,
        showSave,
        warningMessage,
        infoMessage,
        modelState,
        modelRequired,
        fieldDiffersFromProject,
        onChange,
        onSave,
        onCancel,
    }: ModelVariablesProps) => {
        const resource: any = useSelector((state) => get(state, `resources.${variablesResourceName}`));

        const userRights = useUserRights();

        return (
            <>
                {!editing &&
                    !modelReadOnlyEnabled(modelState, modelRequired) &&
                    actionAllowed(userRights, USER_ACTIONS.MODEL_VARIABLES_EDIT, modelRequired) &&
                    differsFromProject && (
                        <div className="px-2 py-1">
                            {resource.isReading || resource.isUpdating ? (
                                <IconLoading />
                            ) : (
                                <ActionLabel onClick={() => onSave("Revert")}>Use global project settings</ActionLabel>
                            )}
                        </div>
                    )}
                {warningMessage && <StatusMsg className="px-2 py-1 variables__warning" icon="info-empty" message={warningMessage} />}
                {infoMessage && <StatusMsg className="px-2 py-1 variables__info" icon="info-empty" message={infoMessage} />}
                <div className="flex-column">
                    {modelVariables?.map((item, i) => (
                        <div key={`model-variables-${i}`} className="flex-row align-end flex-wrap gap-2 py-2">
                            {item.map((it, j) => (
                                <div key={`model-variable-${it.name}-${i}-${j}`} className="variable-field">
                                    {it.isText ? (
                                        <Input
                                            label={it.uiLabel}
                                            ghost={!editing}
                                            percent={!editing && it.inputType === "%"}
                                            readOnly={it.updateLevel === "PROJECT"}
                                            value={getVariableValue(values, it)}
                                            warning={fieldDiffersFromProject ? fieldDiffersFromProject(it.name) : undefined}
                                            onChange={(event) => onChange(event.target.value, it.name)}
                                        />
                                    ) : (
                                        <Dropdown
                                            label={it.uiLabel}
                                            readOnly={it.updateLevel === "PROJECT"}
                                            warning={fieldDiffersFromProject ? fieldDiffersFromProject(it.name) : undefined}
                                            ghost={!editing}
                                            value={getVariableValue(values, it)}
                                            items={it.values}
                                            multiple={it.inputType === "LIST"}
                                            singleLine={it.inputType === "LIST"}
                                            onChange={(value) => onChange(value, it.name)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                {editing && (
                    <IdsButtonGroup customClasses="variables__actions" position="right" spaceBetween="lg">
                        <Button variant="primary" padding="lg" isDisabled={!showSave} onClick={() => onSave("Save")}>
                            Apply Changes
                        </Button>
                        <Button variant="secondary" padding="lg" onClick={onCancel}>
                            Cancel
                        </Button>
                    </IdsButtonGroup>
                )}
            </>
        );
    }
);

export interface ModelVariablesProps extends CommonVariablesProps {
    /**
     * An array of Model Variables.
     */
    modelVariables: VariablesTransformed[][];

    /**
     * Model Variables differ from Project Variables.
     */
    differsFromProject?: boolean;

    /**
     * Display "Save" button.
     */
    showSave: boolean;

    /**
     * Status of the Model.
     */
    modelState: ModelState | ComponentModelState;

    /**
     * Model required for Project.
     */
    modelRequired: boolean | null;

    onChange: (value: string | number | readonly string[] | undefined, name: string) => void;

    onCancel: () => void;
}

export default ModelVariables;
