import * as React from "react";
import ClassNames from "classnames";

import IconWrap from "components/ui/Icons";
import Label from "components/ui/Label";

import { useFocusClasses } from "utils/useFocusClasses";
import { useEnterKey } from "utils/useEnterKey";

import "./style.scss";

const Switcher = (props: SwitcherProps) => {
    const isTabable = !props.disabled && !props.readOnly;

    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({ disabled: !isTabable });
    const [onEnter] = useEnterKey({ disabled: !isTabable, onClick: props.onClick });

    const classNames = ClassNames("flex-row align-center justify-space-between switcher-container", props.className, {
        disabled: props.disabled,
        "read-only": props.readOnly,
        active: props.active,
    });

    return (
        <div className="flex-row align-center switcher">
            {props.label && (
                <Label className="switcher-label" disabled={props.disabled} readOnly={props.readOnly} onClick={props.onClick}>
                    {props.label}
                </Label>
            )}
            <div
                className={classNames}
                tabIndex={isTabable ? 0 : -1}
                title={props.label ? "" : props.title}
                onFocus={onFocusClassesFocus}
                onBlur={onFocusClassesBlur}
                onKeyDown={onEnter}
                onClick={props.disabled || props.readOnly ? undefined : props.onClick}
            >
                {props.onIcon && <IconWrap icon={props.onIcon} iconSmall color="#ffffff" />}
                <div className="switcher-thumb" />
                {props.offIcon && <IconWrap icon={props.offIcon} iconSmall color="#ffffff" />}
            </div>
        </div>
    );
};

interface SwitcherProps {
    className?: string;

    /**
     * Label for Switcher.
     */
    label?: string;

    title?: string;

    disabled?: boolean;

    readOnly?: boolean;

    /**
     * On/off state for switcher.
     */
    active?: boolean;

    /**
     * Icon to display next to thumb when switcher is on.
     */
    onIcon?: any;

    /**
     * Icon to display next to thumb when switcher is off.
     */
    offIcon?: any;

    onClick?: React.MouseEventHandler<HTMLElement>;
}

export default Switcher;
