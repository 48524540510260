import { memo } from "react";

import ImportData from "layouts/common/ImportData";

import { ImportDataModalProps } from "layouts/Modal/ModelDashboardModal/types";

const ImportModelData = memo(
    ({
        viewIndex,
        idClient,
        idProject,
        idModel,
        idInputLog,
        idTemplate,
        idTerritory,
        idFuel,
        modelName,
        onCancel,
    }: ImportDataModalProps) => {
        return (
            <ImportData
                viewIndex={viewIndex}
                idClient={idClient}
                idProject={idProject}
                idModel={idModel}
                idInputLog={idInputLog}
                idTemplate={idTemplate}
                idTerritory={idTerritory}
                idFuel={idFuel}
                modelName={modelName}
                displayInModal
                isComponentModel={idModel !== undefined && idTemplate !== undefined}
                onCancel={onCancel}
            />
        );
    }
);

export default ImportModelData;
