import { get } from "lodash";

import * as actionTypes from "store/actionTypes";

import { availableResources } from "store/configureResources";

export function resourcesMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        switch (action.type) {
            case actionTypes.API_CRUD_READ_LIST_SUCCESS:
                onReadListSuccess(action);
                break;
            case actionTypes.API_CRUD_READ_SUCCESS:
                onReadSuccess(action);
                onReadComplete(action);
                break;
            case actionTypes.API_CRUD_READ_ERROR:
                onReadComplete(action);
                break;
            default:
                break;
        }

        next(action);

        onResourceChanged(action);
    };

    // Merge path params in list objects for later filtering and get rid of listName in returned data.
    async function onReadListSuccess(action) {
        const { resourceName, path, appendData } = action.passThroughData;
        const pathKeys = Object.keys(path || {});

        const listName = availableResources.filter((item) => item.resourceName === resourceName)[0].listName;
        action.data = listName ? action.data[listName] : action.data;

        if (pathKeys.length) {
            action.data = action.data.map((item) => {
                return {
                    ...item,
                    ...path,
                };
            });
        }

        if (appendData) {
            action.data = action.data.map((item) => {
                return {
                    ...item,
                    ...appendData,
                };
            });
        }
    }

    async function onReadSuccess(action) {
        const { transform } = action.passThroughData;

        if (transform) {
            action.data = transform(action.data);
        }
    }

    async function onReadComplete(action) {}

    async function onResourceChanged(action) {
        if (action.passThroughData) {
            const { onStart, onSuccess, onError, onComplete, onProgress } = action.passThroughData;

            if (
                [
                    actionTypes.API_CRUD_READ_LIST_SUCCESS,
                    actionTypes.API_CRUD_READ_SUCCESS,
                    actionTypes.API_CRUD_CREATE_SUCCESS,
                    actionTypes.API_CRUD_UPDATE_SUCCESS,
                    actionTypes.API_CRUD_DELETE_SUCCESS,
                ].includes(action.type)
            ) {
                if (get(action, "data.responseStatus") === "failure") {
                    onError && onError(action);
                } else {
                    onSuccess && onSuccess(action);
                }

                onComplete && onComplete(action);
            }

            if (actionTypes.API_CRUD_CREATE_PROGRESS === action.type) {
                onProgress && onProgress(action);
            }

            if (actionTypes.API_CRUD_CREATE_START === action.type) {
                onStart && onStart(action);
            }

            if (
                [
                    actionTypes.API_CRUD_READ_LIST_ERROR,
                    actionTypes.API_CRUD_READ_ERROR,
                    actionTypes.API_CRUD_CREATE_ERROR,
                    actionTypes.API_CRUD_UPDATE_ERROR,
                    actionTypes.API_CRUD_DELETE_ERROR,
                ].includes(action.type)
            ) {
                onError && onError(action);
                onComplete && onComplete(action);
            }
        }
    }
}
