import { isEmpty } from "lodash";
import { memo, useCallback, useState } from "react";
import { batch, useDispatch } from "react-redux";

import { updateModule } from "store/resources/actions/calculationWorkflow/modulesActions";
import { useModuleStudyCases } from "store/resources/actions/calculationWorkflow/moduleStudyCasesActions";
import { updateModuleVariables, useModuleVariables } from "store/resources/actions/calculationWorkflow/moduleVariablesActions";
import { useHelpTip } from "store/resources/actions/helpTips/helpTipsActions";

import Markup from "components/ui/CKEditor/Markup";

import Button from "components/ui/ButtonNew";
import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import Separator from "components/ui/Separator";
import Tooltip from "components/ui/Tooltip";

import ModuleStudyCases from "layouts/common/ModuleStudyCases";
import ModuleStudyCaseVariables from "layouts/common/ModuleStudyCaseVariables";

import { studyCaseVariables } from "layouts/Sidebar/CalculationWorkflowSidebar/utils";

import { actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { useVariablesTransform } from "utils/useVariablesTransform";
import { MODEL_STUDY_CASE } from "utils/constants";
import { USER_ACTIONS } from "utils/user/defines";
import { variableValuesChanged } from "utils/variables";

import { StudyCaseProps } from "./types";
import { IndexSignature } from "types/types";
import { ModuleVariable, ModuleVariableTransformed } from "store/resources/actions/calculationWorkflow/types";

import "./style.scss";

const useVariablesFilter = (data: ModuleVariable[]) => data?.filter((item) => studyCaseVariables.includes(item.name));

const useTransform = (data: ModuleVariable[]) =>
    useVariablesTransform<ModuleVariable, ModuleVariableTransformed>(useVariablesFilter(data), 1);

const StudyCase = memo(({ idProject, idModel, idInputLog, moduleId, studyCaseId, type, modelRequired }: StudyCaseProps) => {
    const dispatch = useDispatch();

    const [moduleStudyCases, isLoadingModuleStudyCases] = useModuleStudyCases({ type });
    const [moduleVariables, isLoadingModuleVariables] = useModuleVariables({ moduleId, transform: useTransform });
    const [helpTip, isLoadingHelpTip] = useHelpTip({ pageId: MODEL_STUDY_CASE });

    const userRights = useUserRights();

    const [selectedCase, setSelectedCase] = useState(studyCaseId);
    const [variableValues, setVariableValues] = useState<IndexSignature<string>>({});

    const [isEditing, setIsEditing] = useState(false);

    const showSave = studyCaseId !== selectedCase || variableValuesChanged(variableValues, moduleVariables);

    const onCaseChange = useCallback((event) => {
        setSelectedCase(parseInt(event.target.value));
    }, []);

    const onChangeVariable = useCallback(
        (value, name) =>
            setVariableValues({
                ...variableValues,
                [name]: value,
            }),
        [variableValues]
    );

    const onEditClick = useCallback(() => {
        if (isEditing) {
            setIsEditing(false);

            setSelectedCase(studyCaseId);
            setVariableValues({});
        } else {
            setIsEditing(true);
        }
    }, [studyCaseId, isEditing]);

    const onSaveClick = useCallback(() => {
        batch(() => {
            dispatch(
                updateModule({
                    idProject,
                    idModel,
                    idInputLog,
                    moduleId,
                    studyCaseId: selectedCase,
                })
            );

            dispatch(updateModuleVariables({ idProject, idModel, idInputLog, moduleId, values: variableValues, action: "Save" }));
        });
        setIsEditing(false);
    }, [idProject, idModel, idInputLog, moduleId, selectedCase, variableValues, dispatch]);

    return (
        <div className="study-case">
            <div className="flex-row align-center justify-space-between study-case__header-container">
                <div className="flex-row align-center">
                    <span className="study-case__title">Study Case Goals</span>
                    {helpTip !== undefined && !isLoadingHelpTip && (
                        <Tooltip
                            containerClassName="study-case__help-tip"
                            placement="left"
                            customIcon={<Icon icon="text-info_italic__more__details__information_about_b_f" clickable />}
                        >
                            <div className="study-case__tooltip-content-wrapper">
                                <Markup content={helpTip.content} />
                            </div>
                        </Tooltip>
                    )}
                </div>
                {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                    <>
                        {!isLoadingModuleStudyCases && !isLoadingModuleVariables && (
                            <Button
                                variant="tertiary"
                                iconLeft={!isEditing ? "ui-pencil__edit__create_b_s" : undefined}
                                padding="md"
                                onClick={onEditClick}
                            >
                                Edit
                            </Button>
                        )}
                    </>
                )}
            </div>
            <div>
                {isLoadingModuleStudyCases ? (
                    <IconLoading />
                ) : (
                    <ModuleStudyCases
                        selectedCase={selectedCase}
                        studyCases={moduleStudyCases}
                        type={type}
                        readOnly={!isEditing}
                        onCaseChange={onCaseChange}
                    />
                )}
            </div>
            {!isEmpty(moduleVariables) && (
                <div>
                    <Separator line />
                    <ModuleStudyCaseVariables
                        values={variableValues}
                        moduleVariables={moduleVariables}
                        editing={isEditing}
                        onChange={onChangeVariable}
                    />
                </div>
            )}
            {isEditing && showSave && (
                <div className="study-case__actions-container">
                    <Button variant="secondary" padding="md" onClick={onSaveClick}>
                        Save changes
                    </Button>
                </div>
            )}
        </div>
    );
});

export default StudyCase;
