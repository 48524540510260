import { memo, useCallback, useState } from "react";

import FieldGroup from "components/ui/FieldGroup";
import Dropdown from "components/ui/Dropdown";
import ComponentWithDropdown from "components/ui/Dropdown/ComponentWithDropdown";
import TagWithDropdown from "components/ui/Dropdown/TagWithDropdown";
import IconWithLabel from "components/ui/Icons/IconWithLabel";

const ITEMS = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
];

const COMPONENT_ITEMS = [
    {
        label: "Upload info",
        disabled: true,
        icon: "upload",
    },
    {
        label: "Download info",
        active: true,
        icon: "download",
    },
];

const TAG_ITEMS = [
    {
        label: "Proposed",
        value: "proposed",
    },
    {
        label: "In Progress",
        value: "in-progress",
    },
    {
        label: "Draft",
        value: "draft",
    },
    {
        label: "Final Report",
        value: "final-report",
        disabled: true,
    },
];

const generateArray = (length: number) => {
    const arr = [];

    for (let index = 0; index < length; index++) {
        arr.push(ITEMS[index].value);
    }

    return arr;
};

const DropdownsNew = memo(() => {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState(ITEMS[0].value);
    const [value3, setValue3] = useState("");
    const [value4, setValue4] = useState("");
    const [value5, setValue5] = useState("");
    const [value6, setValue6] = useState("");

    const [value7, setValue7] = useState([]);
    const [value8, setValue8] = useState(generateArray(4));
    const [value9, setValue9] = useState(generateArray(8));
    const [value10, setValue10] = useState(generateArray(8));
    const [value11, setValue11] = useState(generateArray(2));

    const value12 = generateArray(4);

    const [value13, setValue13] = useState("proposed");

    const onChange = useCallback((value, handler) => {
        handler(value);
    }, []);

    return (
        <div className="flex-row">
            <div className="flex-column ui-library__element-group__dropdown">
                <div className="ui-library__element-group__sub-title">Standard</div>
                <FieldGroup>
                    <Dropdown
                        label="Dropdown"
                        placeholder="Select"
                        value={value1}
                        items={ITEMS}
                        onChange={(value, handler = setValue1) => onChange(value, handler)}
                    />
                    <Dropdown
                        label="Dropdown"
                        placeholder="Select"
                        value={value2}
                        items={ITEMS}
                        onChange={(value, handler = setValue2) => onChange(value, handler)}
                    />
                    <Dropdown
                        label="Dropdown"
                        required
                        placeholder="Required"
                        value={value3}
                        items={ITEMS}
                        onChange={(value, handler = setValue3) => onChange(value, handler)}
                    />
                    <Dropdown
                        label="Dropdown"
                        placeholder="With Filter"
                        withFilter
                        value={value4}
                        items={ITEMS}
                        onChange={(value, handler = setValue4) => onChange(value, handler)}
                    />
                    <Dropdown label="Dropdown" disabled value={ITEMS[0].value} items={ITEMS} />
                    <Dropdown label="Dropdown" readOnly value={ITEMS[0].value} items={ITEMS} />
                    <Dropdown label="Dropdown" ghost value={ITEMS[0].value} items={ITEMS} />
                    <Dropdown
                        label="Dropdown"
                        warning
                        placeholder="Warning"
                        value={value5}
                        items={ITEMS}
                        onChange={(value, handler = setValue5) => onChange(value, handler)}
                    />
                    <Dropdown
                        label="Dropdown"
                        error
                        placeholder="Error"
                        value={value6}
                        items={ITEMS}
                        msgText="Please select a value"
                        onChange={(value, handler = setValue6) => onChange(value, handler)}
                    />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__dropdown">
                <div className="ui-library__element-group__sub-title">Multiple choice</div>
                <FieldGroup>
                    <Dropdown
                        label="Dropdown"
                        placeholder="Select one or more"
                        multiple
                        value={value7}
                        items={ITEMS}
                        onChange={(value, handler = setValue7) => onChange(value, handler)}
                    />
                    <Dropdown
                        label="Dropdown - Two Rows"
                        placeholder="Select one or more"
                        multiple
                        value={value8}
                        items={ITEMS}
                        onChange={(value, handler = setValue8) => onChange(value, handler)}
                    />
                    <Dropdown
                        label="Dropdown - Four Rows"
                        placeholder="Select one or more"
                        multiple
                        value={value9}
                        items={ITEMS}
                        onChange={(value, handler = setValue9) => onChange(value, handler)}
                    />
                    <Dropdown
                        label="Dropdown - Single-line"
                        placeholder="Select one or more"
                        multiple
                        singleLine
                        value={value10}
                        items={ITEMS}
                        onChange={(value, handler = setValue10) => onChange(value, handler)}
                    />
                    <Dropdown
                        label="Dropdown - Single-line - display count"
                        placeholder="Select one or more"
                        multiple
                        displayCount
                        value={value11}
                        items={ITEMS}
                        onChange={(value, handler = setValue11) => onChange(value, handler)}
                    />
                    <Dropdown label="Dropdown - Two Rows - Disabled" disabled multiple value={value12} items={ITEMS} />
                    <Dropdown label="Dropdown - Single-line - Disabled" disabled multiple singleLine value={value12} items={ITEMS} />
                    <Dropdown label="Dropdown - Two Rows - Read-only" readOnly multiple value={value12} items={ITEMS} />
                    <Dropdown label="Dropdown - Single-line - Read-only" readOnly multiple singleLine value={value12} items={ITEMS} />
                    <Dropdown label="Dropdown" ghost multiple value={value12} items={ITEMS} />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__dropdown">
                <div className="ui-library__element-group__sub-title">Component</div>
                <FieldGroup>
                    <ComponentWithDropdown
                        component={
                            <IconWithLabel icon="settings-applications-empty" clickable>
                                Project Files
                            </IconWithLabel>
                        }
                        items={COMPONENT_ITEMS}
                    />
                    <TagWithDropdown
                        className="tag-dropdown"
                        tagClassName={value13}
                        label="Status"
                        value={value13}
                        items={TAG_ITEMS}
                        onChange={(value) => onChange(value, setValue13)}
                    />
                </FieldGroup>
            </div>
        </div>
    );
});

export default DropdownsNew;
