import { isNil } from "lodash";
import { memo, useEffect, useState } from "react";
import cn from "classnames";

import { useResourceProgress } from "store/resources/actions/useResource";

import IconWithLabel from "components/ui/Icons/IconWithLabel";

import { toTimerString } from "utils/dateTime";

import "./style.scss";

export const DeterminateProgressBar = memo(({ resourceName, resourceId, showPercentage, topElement }: DeterminateProgressBarProps) => {
    const percentage = useResourceProgress({ resourceName, resourceId });

    return (
        <div className="determinate-progress-bar">
            {topElement && (
                <div className="flex-row top-container">
                    <div className="top-element">{topElement}</div>
                    <div>{showPercentage && `- ${percentage}%`}</div>
                </div>
            )}
            <div className="progress-bar">
                <div className="filler" style={{ width: `${percentage}%` }} />
            </div>
        </div>
    );
});

export const IndeterminateProgressBar = memo(
    ({ animationClassName, topElement, startTime, bottomElement, steps }: IndeterminateProgressBarProps) => {
        const [now, setNow] = useState<any>(Date.now());

        useEffect(() => {
            if (startTime) {
                setNow(new Date());

                const interval = setInterval(() => {
                    setNow(Date.now());
                }, 1000);

                return () => {
                    clearInterval(interval);
                };
            }
        }, [startTime]);

        const renderProgressBar = () => {
            return steps === undefined ? (
                <div className="progress-bar">
                    <div className={cn("filler", animationClassName)} />
                </div>
            ) : (
                <div className="flex-row">
                    {steps.map((step: any) => {
                        let icon = "circle-radio-button-unchecked-empty";

                        if (step.status === "IN PROGRESS") {
                            icon = "repeat-empty";
                        } else if (step.status === "DONE") {
                            icon = "check-done";
                        }

                        return (
                            <div className="flex-column flex-one progress-bar-with-steps">
                                <div className="progress-bar">
                                    <div
                                        className={cn("filler", {
                                            todo: isNil(step.status),
                                            done: step.status === "DONE",
                                        })}
                                    />
                                </div>
                                <IconWithLabel icon={icon}>{step.name}</IconWithLabel>
                            </div>
                        );
                    })}
                </div>
            );
        };

        return (
            <div className="indeterminate-progress-bar">
                <div className="flex-row align-center top-container">
                    <div className="top-element">{topElement}</div>
                    {startTime && <div>{`- ${toTimerString(now - startTime)}`}</div>}
                </div>
                {renderProgressBar()}
                {bottomElement !== undefined && <div className="bottom-element">{bottomElement}</div>}
            </div>
        );
    }
);

export const StaticProgressBar = memo((props: StaticProgressBarProps) => {
    const { className, percentage, topDescription, bottomDescription } = props;

    return (
        <div className={cn("static-progress-bar", className)}>
            {topDescription && <div className="margin-bottom-small top-element">{topDescription}</div>}
            <div className={cn("progress-bar without-shadow", { "margin-bottom-small": bottomDescription })}>
                <div className="filler" style={{ width: `${percentage}%` }} />
            </div>
            {bottomDescription && <div className="bottom-element">{bottomDescription}</div>}
        </div>
    );
});

interface DeterminateProgressBarProps {
    resourceName: string;

    resourceId: string;

    /**
     * Display percentage next to the top custom JSX element.
     */
    showPercentage?: boolean;

    /**
     * Custom JSX element above progress bar.
     */
    topElement?: JSX.Element;
}

interface IndeterminateProgressBarProps {
    animationClassName?: string;

    /**
     * Custom JSX element above progress bar.
     */
    topElement?: JSX.Element;

    /**
     * Start time of action.
     */
    startTime?: number;

    /**
     * Custom JSX element below progress bar.
     */
    bottomElement?: JSX.Element;

    /**
     * Render progress bar with steps.
     */
    steps?: {
        name: string;
        status?: string | null;
    }[];
}

interface StaticProgressBarProps {
    className?: string;

    /**
     * Percentage done.
     */
    percentage: number;

    /**
     * Description above progress bar.
     */
    topDescription?: string;

    /**
     * Description below progress bar.
     */
    bottomDescription?: string;
}
