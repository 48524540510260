import { isEmpty } from "lodash";
import { memo, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { QRCodeSVG } from "qrcode.react";

import { loginClearQrCode, logout } from "store/login/actions";
import { createUserAuthenticationCode } from "store/resources/actions/user/userAuthActions";
import { deleteQrCode } from "store/resources/actions/user/userQrCodeActions";

import Button from "components/ui/Button";
import IconWrap from "components/ui/Icons";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";

import FormWrap from "pages/common/FormWrap";

import { isVLMLoginPage } from "utils/constants";

import "./style.scss";

const QrCode = memo(() => {
    const dispatch = useDispatch();

    // @ts-ignore
    const user = useSelector((state) => state.vdsmUser);

    const [authCode, setAuthCode] = useState("");
    const [isError, setIsError] = useState(false);

    const isAuthCodeValid = authCode.trim().length > 0;

    const qrCode = useMemo(() => {
        let qrCode = "";

        if (user) {
            qrCode = `otpauth://totp/VisionDSM:${user.name}?secret=${user.qrCode}&issuer=AEG`;
        }

        return qrCode;
    }, [user]);

    const onAuthCodeChange = (event) => {
        setAuthCode(event.target.value);
    };

    const onSubmit = (event) => {
        event.preventDefault();

        dispatch(
            createUserAuthenticationCode({
                authCode,
                onError: () => setIsError(true),
                onSuccess: () => dispatch(loginClearQrCode()),
            })
        );
    };

    const onRedirect = () => {
        dispatch(deleteQrCode({ userNumber: user?.userNumber, onComplete: () => dispatch(logout()) }));
    };

    return (
        <FormWrap>
            <form className="flex-column qr-code-form" onSubmit={onSubmit}>
                {isEmpty(user) ? (
                    <div className="flex-column flex-one align-center justify-center">
                        <IconLoading />
                    </div>
                ) : (
                    <>
                        {isError && (
                            <div className="qr-code-form__error-message">
                                <ErrorMsg largeMsg message="Invalid Authentication Code" />
                            </div>
                        )}
                        <div className="text-center qr-code-form__header">Set up Authenticator</div>
                        <div className="flex-row align-center qr-code-form__two-fa-setup-steps">
                            <ul>
                                <li>
                                    Get the <b>Google Authenticator</b> or <b>Microsoft Authenticator</b> app from your application store
                                </li>
                                <li>
                                    Once the app is installed, select <b>Set Up Account</b> in the app
                                </li>
                                <li>
                                    Choose <b>Scan barcode</b>
                                </li>
                                <li>
                                    <div className="flex-row align-center">
                                        <span>Scan the barcode</span>
                                        <IconWrap icon="arrow-thin-right-empty" />
                                    </div>
                                    {`to configure the authentication code you will use to log into the ${
                                        isVLMLoginPage ? "VisionLoadMAP" : "Vision Insight"
                                    } system`}
                                </li>
                            </ul>
                            <div className="qr-code-form__qr-code-wrap">
                                <QRCodeSVG className="qr-code" value={qrCode} size={248} />
                            </div>
                        </div>
                        <div className="qr-code-form__inputs">
                            <Input
                                label="Authentication Code"
                                placeholder="Enter authentication code"
                                value={authCode}
                                onChange={onAuthCodeChange}
                            />
                        </div>
                        <div className="flex-row align-center justify-space-between">
                            <span className="qr-code-form__redirect" onClick={onRedirect}>
                                Return to login page
                            </span>
                            <Button primary type="submit" disabled={!isAuthCodeValid}>
                                Continue
                            </Button>
                        </div>
                    </>
                )}
            </form>
        </FormWrap>
    );
});

export default QrCode;
