///
/// Fuel actions
/// resourceName: fuelResourceName
///
import { fuelsClientResourceKey, fuelsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

export const useAllFuels = () =>
    useResource({
        resourceName: fuelsResourceName,
        key: fuelsResourceName,
        transform: (data) => data?.model || [],
    });

export const useClientFuels = ({ idClient }) =>
    useResource({
        resourceName: fuelsResourceName,
        key: `${fuelsClientResourceKey}-${idClient}`,
        query: {
            idClient: idClient,
        },
        transform: (data) => data?.model || [],
    });

export const clearClientFuels =
    ({ idClient }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: fuelsResourceName,
                key: `${fuelsClientResourceKey}-${idClient}`,
                broadcast: true,
            })
        );

export const useProjectFuels = ({ idProject }) =>
    useResource({
        resourceName: fuelsResourceName,
        key: `${fuelsResourceName}-${idProject}`,
        query: {
            idProject: idProject,
        },
        transform: (data) => data?.model || [],
    });

export const clearProjectFuels =
    ({ idProject }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: fuelsResourceName,
                key: `${fuelsResourceName}-${idProject}`,
                broadcast: true,
            })
        );
