import { memo, useMemo } from "react";

import { useProjectLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useStandaloneReports } from "store/resources/actions/standaloneReport/standaloneReportActions";

import IconLoading from "components/ui/Icons/IconLoading";

import ScenarioLog from "pages/ManageProject/ProjectionsContent/common/ScenarioLog";

import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";
import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";

const headers = {
    projectionName: "model/report",
    actionName: "action",
    started: "started",
    user: "user",
    executionTime: "execution time",
    result: {
        label: "result",
        sortable: false,
    },
    note: {
        label: "",
        sortable: false,
    },
};

const ProjectLog = memo(({ project }: ProjectDashboardPanelProps) => {
    const { idClient, idProject } = project;

    const [projectLogItems, isLoadingProjectLogItems] = useProjectLogItems({ idProject });

    const [models = []] = useProjectInputs({ idProject });
    const [componentModels = []] = useComponentModels({ idProject });
    const [standaloneReports = []] = useStandaloneReports({ idProject });

    const projections = useMemo(() => {
        return [...models, ...componentModels, ...standaloneReports];
    }, [models, componentModels, standaloneReports]);

    return (
        <>
            {isLoadingProjectLogItems ? (
                <IconLoading />
            ) : (
                <ScenarioLog
                    idClient={idClient}
                    idProject={idProject}
                    projections={projections}
                    headers={headers}
                    logItems={projectLogItems}
                />
            )}
        </>
    );
});

export default ProjectLog;
