///
/// Avoided Cost Template actions
/// resourceName: avoidedCostsTemplateResourceName
///
import { get } from "lodash";

import {
    avoidedCostsTemplateResourceName,
    avoidedCostsTemplateSectorsKey,
    avoidedCostsTemplateValidationKey,
} from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";

import { clearCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";
import { clearInputValidations } from "store/resources/actions/projectInput/projectInputValidationsActions";
import { clearMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";
import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

export const getFromStoreAvoidedCostsList =
    ({ idInputLog }) =>
    (dispatch, getState) =>
        get(
            getState(),
            `resources.${avoidedCostsTemplateResourceName}.itemsById[${avoidedCostsTemplateResourceName}-${idInputLog}].data.model`
        );

const getResourceParams = ({ idProject, idInputLog, updateItems, onComplete, dispatch, body }) => ({
    resourceName: avoidedCostsTemplateResourceName,
    key: `${avoidedCostsTemplateResourceName}-${idInputLog}`,
    path: { idInputLog },
    body,
    optimisticUpdate: updateItems?.length
        ? {
              value: {
                  model: updateItems,
              },
          }
        : undefined,
    updateFromResponse: true,
    showSuccessNotification: false,
    showErrorNotification: false,
    onComplete: () => {
        onComplete && onComplete();
        dispatch(clearAvoidedCostsTemplate({ idInputLog }));
        dispatch(clearCalculationPreconditions({ idProject }));
        dispatch(clearInputValidations({ idInputLog }));
        dispatch(clearMeasureInputs({ idInputLog }));
        dispatch(clearModelLogItems({ idProject, idInputLog }));
    },
});

export const useAvoidedCostsTemplate = ({ idInputLog }) =>
    useResource({
        resourceName: avoidedCostsTemplateResourceName,
        key: `${avoidedCostsTemplateResourceName}-${idInputLog}`,
        path: { idInputLog },
        transform: (data) => data?.model,
    });

// Validate should be called after an EDIT table is created
// EDIT table is created in API in GET_AVOIDED_COSTS_TEMPLATE
// 'deferUpdate' is the value of the second item of an array returned from useAvoidedCostsTemplate (isLoading)
export const useAvoidedCostsTemplateValidation = ({ idInputLog, deferUpdate }) =>
    useResource({
        resourceName: avoidedCostsTemplateResourceName,
        key: `${avoidedCostsTemplateValidationKey}-${idInputLog}`,
        resourceId: "validation",
        path: { idInputLog },
        takeFromStore: deferUpdate,
    });

export const useAvoidedCostsTemplateSectors = ({ idInputLog }) =>
    useResource({
        resourceName: avoidedCostsTemplateResourceName,
        key: `${avoidedCostsTemplateSectorsKey}-${idInputLog}`,
        resourceId: "sectors",
        path: { idInputLog },
        transform: (data) => data?.model,
    });

export const createAvoidedCostsTemplate =
    ({ idProject, idInputLog, columnList, onComplete }) =>
    (dispatch) => {
        dispatch(
            createResource(
                getResourceParams({
                    idProject,
                    idInputLog,
                    onComplete,
                    dispatch,
                    body: {
                        idInputLog,
                        columnList,
                    },
                })
            )
        );
    };

export const updateAvoidedCostsTemplate =
    ({ idProject, idInputLog, body, onComplete }) =>
    (dispatch) => {
        dispatch(
            updateResource({
                ...getResourceParams({
                    idProject,
                    idInputLog,
                    dispatch,
                    body,
                    onComplete: () => {
                        dispatch(clearModelLogItems({ idProject, idInputLog }));
                        dispatch(clearProjectLogItems({ idProject }));
                    },
                }),
                key: `${avoidedCostsTemplateValidationKey}-${idInputLog}`,
            })
        );
    };

export const clearAvoidedCostsTemplate =
    ({ idInputLog }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: avoidedCostsTemplateResourceName,
                key: `${avoidedCostsTemplateResourceName}-${idInputLog}`,
                broadcast: true,
            })
        );
