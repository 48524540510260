import { batch } from "react-redux";

import { useProcessStatus, ProcessStatusProps, UseProcessParams } from "./useProcessStatus";

import { RUN_CALCULATION_FAILED, RUN_CALCULATION_COMPLETE, RUN_CALCULATION_TERMINATE } from "store/actionTypes";
import { calculationsResourceName } from "store/configureResources";

import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { clearInputValidations } from "store/resources/actions/projectInput/projectInputValidationsActions";
import { clearProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { clearModelStudyCase } from "store/resources/actions/studyCase/studyCaseModelActions";
import { clearLastCalculationResult } from "store/resources/actions/calculations/calculationsLogActions";

export const runCalculationStatusProps: ProcessStatusProps = {
    resourceName: calculationsResourceName,
    idSelector: ({ idInputLog }) => idInputLog,
    process: "run-calculation",
    timeoutHandlers: null,
    doReportRefresh: false,
    onProjectStatusChange:
        (idProject) =>
        // @ts-ignore
        (dispatch) => {
            dispatch(clearProjectLogItems({ idProject }));
        },
    onItemStatusChange:
        ({ idProject, idInputLog }) =>
        // @ts-ignore
        (dispatch) => {
            batch(() => {
                dispatch(clearModelLogItems({ idProject, idInputLog }));
                dispatch(clearInputValidations({ idInputLog }));
            });
        },
    onItemFinishBefore:
        ({ idProject, idInputLog }) =>
        // @ts-ignore
        (dispatch) => {
            batch(() => {
                dispatch(clearProjectInputs({ idProject }));
                dispatch(clearModelStudyCase({ idProject, idInputLog }));
                dispatch(clearLastCalculationResult({ idProject, idInputLog }));
            });
        },
    onItemFailed:
        ({ idProject, idInputLog }) =>
        // @ts-ignore
        (dispatch) => {
            dispatch({
                type: RUN_CALCULATION_FAILED,
                idProject,
                idInputLog,
            });
        },
    onItemTerminated:
        ({ idProject, idInputLog }) =>
        // @ts-ignore
        (dispatch) => {
            dispatch({
                type: RUN_CALCULATION_TERMINATE,
                idProject,
                idInputLog,
            });
        },
    onItemFinishAfter:
        (status) =>
        // @ts-ignore
        (dispatch) => {
            dispatch({
                type: RUN_CALCULATION_COMPLETE,
                status,
            });
        },
};

export const useRunCalculationStatus = ({ idProject, idInputLog }: UseProcessParams) =>
    useProcessStatus({ statusProps: runCalculationStatusProps, idProject, idInputLog });
