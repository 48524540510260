import { isEmpty } from "lodash";
import { memo } from "react";

import { useMarketProfileData } from "store/resources/actions/marketProfile/marketProfileActions";

import DashboardHeader from "pages/ManageProject/ProjectionsContent/ProjectDashboard/DashboardHeader";
import ProjectLogPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ProjectLogPanel";
import ProjectDetailsPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ProjectDetailsPanel";
import ProjectStudyCasePanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ProjectStudyCasePanel";
import ModelsPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ModelsPanel";
import MarketProfileSnapshotPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/MarketProfileSnapshotPanel";
import ProjectLabelsPanel from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ProjectLabelsPanel";

import ProjectVariablesPanel from "./ProjectVariablesPanel";

import { useUserRights } from "utils/useUserRights";
import { USER_ROLES } from "utils/user/defines";

import { ProjectDashboardViewsProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

import "./style.scss";

/**
 * Default Project Dashboard view for internal users.
 */
const ProjectDashboardDefault = memo(
    ({ viewIndex, project, clientName, displayFiles, onDisplayFiles, onReturn }: ProjectDashboardViewsProps) => {
        const [marketProfileData, isLoadingMarketProfileData] = useMarketProfileData({ idProject: project.idProject });

        const userRights = useUserRights();

        return (
            <div className="project-dashboard-default">
                <div className="project-dashboard-default__header-container">
                    <DashboardHeader
                        project={project}
                        clientName={clientName}
                        displayFiles={displayFiles}
                        onDisplayFiles={onDisplayFiles}
                        onReturn={onReturn}
                    />
                </div>
                <div className="project-dashboard-default__body-container">
                    <div>
                        <ProjectLogPanel project={project} />
                    </div>
                    <div>
                        <ProjectDetailsPanel project={project} />
                    </div>
                    <div>
                        <ProjectStudyCasePanel project={project} />
                    </div>
                    <div>
                        <ModelsPanel viewIndex={viewIndex} project={project} />
                    </div>
                    {userRights === USER_ROLES.ADVANCED_CLIENT ? (
                        <>
                            {!isEmpty(marketProfileData) && (
                                <div>
                                    <MarketProfileSnapshotPanel
                                        project={project}
                                        data={marketProfileData}
                                        isLoading={isLoadingMarketProfileData}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <div>
                            <MarketProfileSnapshotPanel project={project} data={marketProfileData} isLoading={isLoadingMarketProfileData} />
                        </div>
                    )}
                    <div className="panels-row">
                        <ProjectVariablesPanel project={project} />
                        <ProjectLabelsPanel project={project} />
                    </div>
                </div>
            </div>
        );
    }
);

export default ProjectDashboardDefault;
