import { memo, useCallback, useMemo } from "react";
// @ts-ignore
import { ScrollSyncPane } from "react-scroll-sync";
import ClassNames from "classnames";

import ErrorBoundary from "components/ui/ErrorBoundary";

import SideNavContainer from "components/ui/SideNav/SideNavContainer";
import SideNavRoot from "components/ui/SideNav/SideNavRoot";

import { availableViews } from "pages/configureViews";
import { WindowContext } from "pages/WindowManager/Windows/WindowsContainer/Window";
import WindowHeader from "pages/WindowManager/Windows/WindowsContainer/Window/WindowHeader";
import WindowContent from "pages/WindowManager/Windows/WindowsContainer/Window/WindowContent";

import { useViews } from "utils/useViews";
import { sideNavigationPosition, getViewSideNavigationKey } from "utils/sideNav";
import { EMPTY_SPLIT_VIEW_NAME } from "utils/window";

import { View } from "store/window/types";

const SplitWindow = memo((props: SplitWindowProps) => {
    const { className, name, containerName, onOpen, onClose, onRemoveSplit, onClearSplit } = props;

    const views = useViews({ containerName });

    const view = useMemo(() => {
        let view = views.find((view: View) => view.name === name);

        if (view === undefined) {
            view = {};
        }

        return view;
    }, [name, views]);

    const {
        showHeader,
        active,
        close,
        clear,
        isSplitViewSync,
        splitView,
        leftViewClassName,
        leftViewName,
        leftViewComponent,
        leftViewHeaderTitle,
        leftViewHeaderSubTitle,
        leftViewProps,
        rightViewClassName,
        rightViewName,
        rightViewComponent,
        rightViewHeaderTitle,
        rightViewHeaderSubTitle,
        rightViewProps,
    } = view;

    const leftViewClassNames = ClassNames("flex-column flex-one-in-row with-scroll no-shrink split-window-left", leftViewClassName);
    const rightViewClassNames = ClassNames("flex-column flex-one-in-row with-scroll no-shrink split-window-right", rightViewClassName);

    const leftView = {
        className: leftViewClassName,
        name: leftViewName,
        containerName,
        component: leftViewComponent,
        headerTitle: leftViewHeaderTitle,
        headerSubTitle: leftViewHeaderSubTitle,
        props: leftViewProps,
    };

    const rightView = {
        className: rightViewClassName,
        name: rightViewName,
        containerName,
        component: rightViewComponent,
        headerTitle: rightViewHeaderTitle,
        headerSubTitle: rightViewHeaderSubTitle,
        splitView,
        props: rightViewProps,
    };

    const LeftViewComponent = availableViews[leftViewComponent];
    const RightViewComponent = availableViews[rightViewComponent];

    const leftViewSideNavLeftId = getViewSideNavigationKey({ name: leftViewName, position: sideNavigationPosition.left });
    const leftViewSideNavRightId = getViewSideNavigationKey({ name: leftViewName, position: sideNavigationPosition.right });
    const rightViewSideNavLeftId = getViewSideNavigationKey({ name: rightViewName, position: sideNavigationPosition.left });
    const rightViewSideNavRightId = getViewSideNavigationKey({ name: rightViewName, position: sideNavigationPosition.right });

    const onSplitViewClose = useCallback(
        (side) => {
            if (clear) {
                // Clear only one side
                onClearSplit(name, side);
            } else if (close) {
                // Close view
                onClose(name);
            }
        },
        [name, close, clear, onClose, onClearSplit]
    );

    return (
        <div id={active ? "presentation-mode-window" : ""} className={className} hidden={!active}>
            <WindowContent active={active}>
                <>
                    <WindowContext.Provider value={{ name: leftViewName, position: "left", active, splitView }}>
                        <div className={leftViewClassNames}>
                            <SideNavContainer
                                className="flex-column fill-height"
                                sideNavIds={[leftViewSideNavLeftId, leftViewSideNavRightId]}
                            >
                                <SideNavRoot id={leftViewSideNavLeftId} />
                                <WindowHeader
                                    view={leftView}
                                    completeName={name}
                                    isSplitViewEnabled={view.isSplitViewEnabled}
                                    splitView={splitView}
                                />
                                <div className="flex-one-in-column with-scroll">
                                    <div className="flex-column flex-one fill-height">
                                        <ScrollSyncPane>
                                            <ErrorBoundary>
                                                {LeftViewComponent && (
                                                    <div className="flex-column flex-one-in-column with-scroll">
                                                        <div className="flex-column flex-one">
                                                            <LeftViewComponent
                                                                {...leftViewProps}
                                                                viewIndex={0}
                                                                isSplitViewSync={isSplitViewSync}
                                                                onOpen={onOpen}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </ErrorBoundary>
                                        </ScrollSyncPane>
                                    </div>
                                </div>
                                <SideNavRoot id={leftViewSideNavRightId} />
                            </SideNavContainer>
                        </div>
                    </WindowContext.Provider>
                    <WindowContext.Provider value={{ name: rightViewName, position: "right", active, splitView }}>
                        <div className={rightViewClassNames}>
                            <SideNavContainer
                                className="flex-column fill-height"
                                sideNavIds={[rightViewSideNavLeftId, rightViewSideNavRightId]}
                            >
                                <SideNavRoot id={rightViewSideNavLeftId} />
                                {showHeader && (
                                    <WindowHeader
                                        view={rightView}
                                        completeName={name}
                                        close={view.close}
                                        isSplitViewEnabled={view.isSplitViewEnabled}
                                        splitView={splitView}
                                        onClose={rightViewComponent !== EMPTY_SPLIT_VIEW_NAME ? () => onSplitViewClose("right") : undefined}
                                        onRemoveSplit={() => onRemoveSplit(name)}
                                    />
                                )}
                                <div className="flex-one-in-column with-scroll">
                                    <div className="flex-column flex-one fill-height">
                                        <ScrollSyncPane>
                                            <ErrorBoundary>
                                                {RightViewComponent && (
                                                    <div className="flex-column flex-one-in-column with-scroll">
                                                        <div className="flex-column flex-one">
                                                            <RightViewComponent
                                                                {...rightViewProps}
                                                                viewIndex={1}
                                                                isSplitViewSync={isSplitViewSync}
                                                                onOpen={onOpen}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </ErrorBoundary>
                                        </ScrollSyncPane>
                                    </div>
                                </div>
                                <SideNavRoot id={rightViewSideNavRightId} />
                            </SideNavContainer>
                        </div>
                    </WindowContext.Provider>
                </>
            </WindowContent>
        </div>
    );
});

interface SplitWindowProps {
    className?: string;

    /**
     * Name of the View.
     */
    name: string;

    /**
     * Type of View's container.
     */
    containerName: string;

    /**
     * Function that opens a new View.
     */
    onOpen: (name: string) => void;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit: (name: string) => void;

    /**
     * Function that clears the chosen side
     * of the Split View to blank View.
     */
    onClearSplit: (name: string, side: "left" | "right") => void;
}

export default SplitWindow;
