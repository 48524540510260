import { memo, useCallback } from "react";
import { useSelector } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import { UserAvatar } from "components/ui/Avatar";

import { getUserInitials } from "utils/user";

import "./style.scss";

const WindowHeaderIconGroup = memo((props: WindowHeaderIconGroupProps) => {
    const { name, onClose, onSplit, onRemoveSplit, onPresentationModeClick, onAvatarClick, onLogout } = props;

    // @ts-ignore
    const user = useSelector((state) => state.vdsmUser);

    const { initials } = getUserInitials(user.firstName, user.lastName);

    const onSplitClick = useCallback(() => {
        onSplit?.(name);
    }, [name, onSplit]);

    const onCloseClick = useCallback(() => {
        onClose?.(name);
    }, [name, onClose]);

    const onRemoveSplitClick = useCallback(() => {
        onRemoveSplit?.(name);
    }, [name, onRemoveSplit]);

    return (
        <div className="flex-row align-center window-header-icon-group">
            <IdsButtonGroup spaceBetween="md">
                {onSplit && (
                    <>
                        <Button
                            className="split-button"
                            variant="tertiary"
                            title="Split Screen - switch ON"
                            icon="ui-view_stream__view_agenda_b_s"
                            iconSize="sm"
                            padding="sm"
                            onClick={onSplitClick}
                        />
                        <Button
                            className="split-button"
                            variant="tertiary"
                            title="Screen is too small for Split Screen View"
                            icon="ui-view_stream__view_agenda_b_s"
                            iconSize="sm"
                            padding="sm"
                            isDisabled
                        />
                    </>
                )}
                {onRemoveSplit && (
                    <>
                        <Button
                            className="split-button"
                            variant="tertiary"
                            title="Split Screen - switch OFF"
                            icon="ui-view_stream__view_agenda_b_f"
                            iconSize="sm"
                            padding="sm"
                            onClick={onRemoveSplitClick}
                        />
                        <Button
                            className="split-button"
                            variant="tertiary"
                            title="Screen is too small for Split Screen View"
                            icon="ui-view_stream__view_agenda_b_f"
                            iconSize="sm"
                            padding="sm"
                            isDisabled
                        />
                    </>
                )}
                {onPresentationModeClick && (
                    <Button
                        className="fullscreen-button"
                        variant="tertiary"
                        title="Enable Full Screen"
                        icon="arrows-fullscreen_b_a"
                        iconSize="sm"
                        padding="sm"
                        onClick={onPresentationModeClick}
                    />
                )}
                {onClose && (
                    <Button
                        variant="tertiary"
                        title="Close"
                        icon="ui-close-delete-cross-clear-b-a"
                        iconSize="sm"
                        padding="sm"
                        onClick={onCloseClick}
                    />
                )}
                {initials && onAvatarClick && (
                    <div className="window-header-icon-group__user-avatar-clickable" onClick={onAvatarClick}>
                        <UserAvatar name={initials} size={32} />
                    </div>
                )}
                {onLogout && (
                    <Button
                        dataTestId="logout-btn"
                        variant="tertiary"
                        title="Logout"
                        icon="arrows-exit_b_s"
                        iconSize="sm"
                        padding="sm"
                        onClick={onLogout}
                    />
                )}
            </IdsButtonGroup>
        </div>
    );
});

interface WindowHeaderIconGroupProps {
    /**
     * Name of the View.
     */
    name: string;

    /**
     * Function that closes a View.
     */
    onClose?: (name: string) => void;

    /**
     * Function that splits the View.
     */
    onSplit?: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit?: (name: string) => void;

    /**
     * Function that triggers fullscreen.
     */
    onPresentationModeClick?: () => void;

    /**
     * Function to run when user has clicked
     * on own avatar.
     */
    onAvatarClick?: () => void;

    /**
     * Function to run when user logs out.
     */
    onLogout?: () => void;
}

export default WindowHeaderIconGroup;
