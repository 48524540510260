import { memo, useRef } from "react";
import * as React from "react";

const WindowContent = memo(
    ({ active, children }: WindowContentProps) => {
        const viewActivatedRef = useRef(false);

        if (!viewActivatedRef.current) {
            viewActivatedRef.current = active;

            if (!active) {
                return null;
            }
        }

        return children;
    },
    (prevProps, nextProps) => {
        return !nextProps.active;
    }
);

interface WindowContentProps {
    active: boolean;

    children: React.ReactElement;
}

export default WindowContent;
