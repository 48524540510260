import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import cn from "classnames";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import LabelWithIcon from "components/ui/LabelWithIcon";
import WarningMsg from "components/ui/StatusMsg/WarningMsg";
import Separator from "components/ui/Separator";
import Switcher from "components/ui/Switcher";
import Tooltip from "components/ui/Tooltip";

import { useStudyCasesTransform } from "utils/useStudyCasesTransform";
import { getStudyCaseNumberValue, getStudyCaseBooleanValue } from "utils/studyCase";

import { StudyCaseProps } from "./types";
import { IndexSignature } from "types/types";
import { StudyCaseTransformed } from "store/resources/actions/studyCase/types";

import "./style.scss";

const StudyCase = memo((props: StudyCaseProps) => {
    const { studyCasesRaw, editing, casesToHighlight, warningMessage, saveBtnText, onSave, onEditCancel } = props;

    // Resource

    const studyCases = useStudyCasesTransform(studyCasesRaw);

    // States

    const [values, setValues] = useState<IndexSignature<string>>({});

    const [showSave, setShowSave] = useState(false);
    const [showWarningMessage, setShowWarningMessage] = useState(false);

    // useEffects

    useEffect(() => {
        if (!isEmpty(values)) {
            let valuesChanged = false;

            for (let key in values) {
                const savedStudyCaseValue = studyCasesRaw.find((sc) => sc.parameterName === key)?.value;

                if (values[key] !== savedStudyCaseValue) {
                    valuesChanged = true;

                    break;
                }
            }

            setShowSave(valuesChanged);
        }
    }, [studyCasesRaw, values]);

    useEffect(() => {
        setShowWarningMessage(!isEmpty(warningMessage) && !isEmpty(casesToHighlight));
    }, [warningMessage, casesToHighlight]);

    // Event handlers

    const onChange = useCallback(
        (value, name) => {
            const newValues = {
                ...values,
                [name]: value,
            };

            setValues(newValues);
        },
        [values]
    );

    const onSaveClick = useCallback(() => {
        setShowSave(false);
        onEditCancel && onEditCancel();

        onSave(values);
    }, [values, onSave, onEditCancel]);

    const onCancelClick = useCallback(() => {
        setValues({});

        setShowSave(false);
        onEditCancel && onEditCancel();
    }, [onEditCancel]);

    // Render functions

    const renderItem = (item: StudyCaseTransformed, index: number) => {
        const markCase = casesToHighlight?.some((studyCase) => studyCase.parameterName === item.parameterName);
        let formattedValue: string | boolean | undefined;

        if (item.isSwitcher || item.isCheckbox) {
            formattedValue = getStudyCaseBooleanValue(values?.[item.parameterName] || item.value);
        } else if (item.parameterName === "ECONOMIC_TEST") {
            formattedValue = values?.[item.parameterName] || item.value?.[0];
        } else {
            formattedValue = values?.[item.parameterName] || item.value;
        }

        return (
            <div
                key={`study-case-${index}`}
                className={cn("column-content", {
                    "flex-row align center": item.isSwitcher,
                    "flex-column flex-column-reverse": !item.isSwitcher,
                })}
            >
                <div className={cn("flex-row align-center fill-width row-value", { "row-value-changed": markCase && !editing })}>
                    {item.isSwitcher || item.isCheckbox ? (
                        <>
                            {editing ? (
                                <>
                                    <div className="flex-row align-center flex-one row-title">
                                        {item.displayName}
                                        {item.isCheckbox && (
                                            <Tooltip icon="info-empty">
                                                Calculates Achievable Potentials of selected Study Cases. Model Input "Achievability" is
                                                mandatory.
                                            </Tooltip>
                                        )}
                                    </div>
                                    <div>
                                        <Switcher
                                            active={formattedValue as boolean}
                                            disabled={item.possibleValues?.length === 1}
                                            onClick={() => onChange(getStudyCaseNumberValue(!formattedValue), item.parameterName)}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="flex-row align-center">
                                    <LabelWithIcon
                                        className="regular"
                                        icon={
                                            formattedValue
                                                ? "ui-checked__check_circle_b_f"
                                                : "ui-minus_in_circle__do_not_disturb_on__remove_b_s"
                                        }
                                    >
                                        {item.displayName}
                                    </LabelWithIcon>
                                    {item.isCheckbox && (
                                        <Tooltip icon="info-empty">
                                            Calculates Achievable Potentials of selected Study Cases. Model Input "Achievability" is
                                            mandatory.
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <Dropdown
                            className="fill-width"
                            label={item.displayName}
                            disabled={editing && item.possibleValues?.length === 1}
                            ghost={!editing}
                            value={formattedValue as string}
                            items={item.possibleValues?.map((p: any) => ({ label: p, value: p })) || []}
                            onChange={(value) => onChange(value, item.parameterName)}
                        />
                    )}
                </div>
            </div>
        );
    };

    const renderStudyCases = () => {
        return studyCases?.map((part, index) => (
            <div key={index}>
                {index < 2 && (
                    <div
                        className={cn("section-title", {
                            "section-title__margin": index > 0,
                        })}
                    >
                        {index === 0 ? "Baseline" : "Potential"}
                    </div>
                )}
                <Separator className={cn({ "section-title-separator": index < 2 })} line={index < 2} />
                <div className="flex-row align-center">{part.map((studyCase, j) => renderItem(studyCase, j))}</div>
            </div>
        ));
    };

    // Main render

    return (
        <div className="study-case">
            <div className="study-case__tables">{studyCases && renderStudyCases()}</div>
            {!editing && warningMessage && showWarningMessage && (
                <div className="flex-row">
                    <div className="study-case__warning">
                        <WarningMsg icon="warning-report-problem-empty" message={warningMessage} />
                    </div>
                </div>
            )}
            {editing && (
                <IdsButtonGroup customClasses="study-case__actions" position="right" spaceBetween="lg">
                    <Button variant="primary" padding="lg" isDisabled={!showSave} onClick={onSaveClick}>
                        {saveBtnText}
                    </Button>
                    <Button variant="secondary" padding="lg" onClick={onCancelClick}>
                        Cancel
                    </Button>
                </IdsButtonGroup>
            )}
        </div>
    );
});

export default StudyCase;
