import { memo } from "react";

import FieldGroup from "components/ui/FieldGroup";
import IconWrap from "components/ui/Icons";
import StatusMsg from "components/ui/StatusMsg";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import SuccessMsg from "components/ui/StatusMsg/SuccessMsg";
import WarningMsg from "components/ui/StatusMsg/WarningMsg";
import Tag from "components/ui/Tag";

import { allProjectStates, allModelStates, allComponentModelStates, allModelInputStates } from "utils/constants";

const StatusesAndMarks = memo(() => {
    return (
        <div className="flex-row">
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Large status message (used in Login page)</div>
                <FieldGroup>
                    <ErrorMsg largeMsg message="The username/password combination you entered cannot be found" />
                    <SuccessMsg largeMsg message="Login successful" />
                    <WarningMsg largeMsg message="Your e-mail address has been changed" />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Status message</div>
                <FieldGroup>
                    <StatusMsg message="Password must include Capital, Lowercase and Numbers and be at least 8 characters in length" />
                    <ErrorMsg message="The username/password combination you entered cannot be found" />
                    <ErrorMsg message="The username/password combination you entered cannot be found (no fill)" icon="alert-error-empty" />
                    <SuccessMsg message="Action was done successfully" />
                    <SuccessMsg message="Action was done successfully (no fill)" icon="check-circle-empty" />
                    <WarningMsg message="Values of some variables differ from the Project settings" />
                    <WarningMsg
                        message="Values of some variables differ from the Project settings (no fill)"
                        icon="warning-report-problem-empty"
                    />
                    <StatusMsg
                        message="Password must include Capital, Lowercase and Numbers and be at least 8 characters in length"
                        error
                        iconWithLabelMedium
                        icon="alert-error-empty"
                    />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Icon status</div>
                <FieldGroup>
                    <IconWrap icon="octagon-exclamation-empty" title="Error" error />
                    <IconWrap icon="check-circle-empty" title="Success" success />
                    <IconWrap icon="warning-report-problem-empty" title="Warning" warning />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Status tags</div>
                <FieldGroup>
                    <div className="flex-row">
                        <div>
                            <Tag className="proposed">{allProjectStates.PROPOSED}</Tag>
                        </div>
                        <div className="margin-left-small">
                            <Tag className="planned">{allModelStates.PLANNED}</Tag>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div>
                            <Tag className="in-progress">{allProjectStates.IN_PROGRESS}</Tag>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div>
                            <Tag className="in-manager-review">{allModelStates.IN_MANAGER_REVIEW}</Tag>
                        </div>
                        <div className="margin-left-small">
                            <Tag className="pending-review">{allModelInputStates.PENDING_REVIEW}</Tag>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div>
                            <Tag className="in-client-review">{allModelStates.IN_CLIENT_REVIEW}</Tag>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div>
                            <Tag className="client-unapproved">{allModelStates.CLIENT_UNAPPROVED}</Tag>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div>
                            <Tag className="final-report">{allProjectStates.FINAL_REPORT}</Tag>
                        </div>
                        <div className="margin-left-small">
                            <Tag className="final">{allComponentModelStates.FINAL}</Tag>
                        </div>
                        <div className="margin-left-small">
                            <Tag className="approved">{allModelStates.CLIENT_APPROVED}</Tag>
                        </div>
                        <div className="margin-left-small">
                            <Tag className="manager-signed-off">{allModelInputStates.MANAGER_SIGNED_OFF}</Tag>
                        </div>
                    </div>
                </FieldGroup>
            </div>
        </div>
    );
});

export default StatusesAndMarks;
