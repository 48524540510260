import { isEmpty, isNil } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { deleteModelLogNote } from "store/resources/actions/scenarioLog/modelLogNotesActions";
import { deleteModelInputNote } from "store/resources/actions/modelInputs/modelInputNotesActions";

import { IdsButtonGroup, IdsLabel } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Icon from "components/ui/IconNew";
import Separator from "components/ui/Separator";
import Tooltip from "components/ui/Tooltip";

import NoteForm from "./NoteForm";

import { openModalDialogDeleteNote } from "layouts/Modal/ModelDashboardModal/utils";

import { toLocaleDateTime } from "utils/dateTime";
import { formatFullName } from "utils/string";

import { NoteViewProps } from "./types";
import { ModelLogNote } from "store/resources/actions/scenarioLog/types";
import { ModelInputNote } from "store/resources/actions/modelInputs/types";

import "./style.scss";

const initialEditingIndex = -1;

const NoteView = memo(
    ({
        idProject,
        idInputLog,
        idModel,
        idStudyLog,
        idDataImportLog,
        notes,
        noteType,
        isPublic,
        variant,
        label,
        onClose,
    }: NoteViewProps) => {
        const dispatch = useDispatch();

        // @ts-ignore
        const userNumber = useSelector((state) => state.vdsmUser.userNumber);

        const [editingIndex, setEditingIndex] = useState(initialEditingIndex);

        const filteredNotes = useMemo(() => notes.filter((note) => (isPublic ? note.isPublic : !note.isPublic)), [notes, isPublic]);

        /**
         * Edit an exisiting note.
         */
        const onEditClick = useCallback(
            (note: ModelLogNote | ModelInputNote) => {
                switch (variant) {
                    case "model-log":
                        setEditingIndex((note as ModelLogNote).idModelNote!);

                        break;

                    case "model-inputs":
                        setEditingIndex((note as ModelInputNote).idInputNote!);

                        break;

                    default:
                }
            },
            [variant]
        );

        /**
         * Delete note.
         */
        const onConfirmDeleteNote = useCallback(
            (event, note: ModelLogNote | ModelInputNote) => {
                switch (variant) {
                    case "model-log":
                        if (idProject && idStudyLog) {
                            dispatch(
                                deleteModelLogNote({
                                    idProject,
                                    idInputLog,
                                    idStudyLog,
                                    idModelNote: (note as ModelLogNote).idModelNote!,
                                    idModel,
                                })
                            );
                        }

                        break;

                    case "model-inputs":
                        if (idDataImportLog) {
                            dispatch(
                                deleteModelInputNote({
                                    idInputLog,
                                    idDataImportLog,
                                    idInputNote: (note as ModelInputNote).idInputNote!,
                                })
                            );
                        }

                        break;

                    default:
                }

                if (noteType === "user-note" && filteredNotes.length === 1) {
                    onClose?.(event);
                }
            },
            [idProject, idInputLog, idModel, idStudyLog, idDataImportLog, noteType, variant, filteredNotes, onClose, dispatch]
        );

        /**
         * Open modal window to confirm deletion of note.
         */
        const onDeleteClick = useCallback(
            (event, note: ModelLogNote | ModelInputNote) => {
                dispatch(
                    openModalDialogDeleteNote({
                        onDelete: () => onConfirmDeleteNote(event, note),
                    })
                );
            },
            [onConfirmDeleteNote, dispatch]
        );

        /**
         * Close sidebar on save if it's the first note.
         *
         * Note: Only used for "user-note" type notes.
         */
        const onSaveClick = useCallback(
            (event) => {
                if (isEmpty(filteredNotes)) {
                    onClose?.(event);
                }
            },
            [filteredNotes, onClose]
        );

        /**
         * Cancel edit.
         */
        const onCancelEditClick = useCallback(() => {
            setEditingIndex(initialEditingIndex);
        }, []);

        return (
            <div className="note-view">
                <NoteForm
                    idProject={idProject}
                    idInputLog={idInputLog}
                    idModel={idModel}
                    idStudyLog={idStudyLog}
                    idDataImportLog={idDataImportLog}
                    noteType={noteType}
                    isPublic={isPublic}
                    variant={variant}
                    formType="add"
                    inputLabel={isEmpty(filteredNotes) ? "New note" : "Reply"}
                    onSave={noteType === "user-note" ? onSaveClick : undefined}
                />

                <Separator line />

                <div className="flex-row align-center note-view__tab-panel-header-container">
                    <span className="note-view__panel-header">{label}</span>
                    <Tooltip customIcon={<Icon icon="text-info_italic__more__details__information_about_b_f" clickable />}>
                        {isPublic
                            ? "Create messages to share with the client"
                            : "Create messages that will only be viewable with the internal team"}
                    </Tooltip>
                </div>

                {filteredNotes.map((note, index) => (
                    <div key={`note_${(note as ModelLogNote).idModelNote || (note as ModelInputNote).idInputNote}`}>
                        <div className="flex-row align-center justify-space-between note-view__top-container">
                            <div className="flex-row">
                                {!isNil(note.firstName) && !isNil(note.lastName) && (
                                    <>
                                        <IdsLabel className="note-view__author" size="md">
                                            {formatFullName(note.firstName, note.lastName)}
                                        </IdsLabel>
                                    </>
                                )}
                                {!isNil(note.lastUpdateDate) && (
                                    <IdsLabel className="note-view__date" size="md">
                                        {`• ${toLocaleDateTime(note.lastUpdateDate + "Z")}`}
                                    </IdsLabel>
                                )}
                            </div>
                            {note.createdBy === userNumber &&
                                ![(note as ModelLogNote).idModelNote, (note as ModelInputNote).idInputNote].includes(editingIndex) && (
                                    <IdsButtonGroup spaceBetween="sm">
                                        <Button
                                            variant="tertiary"
                                            padding="sm"
                                            icon="ui-pencil__edit__create_b_s"
                                            iconSize="xs"
                                            title="Edit note"
                                            onClick={() => onEditClick(note)}
                                        />
                                        <Button
                                            variant="tertiary"
                                            padding="sm"
                                            icon="ui-trash__garbage__delete__remove__bin_b_s"
                                            iconSize="xs"
                                            title="Delete note"
                                            onClick={(event) => onDeleteClick(event, note)}
                                        />
                                    </IdsButtonGroup>
                                )}
                        </div>
                        {(note as ModelLogNote).idModelNote === editingIndex && (
                            <NoteForm
                                idProject={idProject}
                                idInputLog={idInputLog}
                                idModel={idModel}
                                idStudyLog={idStudyLog}
                                idDataImportLog={idDataImportLog}
                                idNote={(note as ModelLogNote).idModelNote!}
                                noteType={noteType}
                                isPublic={isPublic}
                                variant={variant}
                                formType="edit"
                                noteText={note.description || ""}
                                onCancel={onCancelEditClick}
                            />
                        )}

                        {(note as ModelInputNote).idInputNote === editingIndex && (
                            <NoteForm
                                idProject={idProject}
                                idInputLog={idInputLog}
                                idModel={idModel}
                                idStudyLog={idStudyLog}
                                idDataImportLog={idDataImportLog}
                                idNote={(note as ModelInputNote).idInputNote!}
                                noteType={noteType}
                                isPublic={isPublic}
                                variant={variant}
                                formType="edit"
                                noteText={note.description || ""}
                                onCancel={onCancelEditClick}
                            />
                        )}

                        {![(note as ModelLogNote).idModelNote, (note as ModelInputNote).idInputNote].includes(editingIndex) && (
                            <div className="note-view__note">{note.description}</div>
                        )}

                        {index < filteredNotes.length - 1 && <Separator line />}
                    </div>
                ))}
            </div>
        );
    }
);

export default NoteView;
