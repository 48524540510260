import { get } from "lodash";
import { memo } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import CalculatedResultsPanel from "./CalculatedResultsPanel";
import DashboardHeader from "./DashboardHeader";
import ModelLogPanel from "./ModelLogPanel";
import ModelInputsPanel from "./ModelInputsPanel";
import UtilityForecastPanel from "./UtilityForecastPanel";
import ModelNotification from "./ModelNotification";

import { useProcessesInProgress } from "store/processes/useProcessStatus";

import FloatModalRoot from "components/ui/Modal/FloatModalRoot";

import { ComponentModelDashboardViewsProps } from "pages/ManageProject/ProjectionsContent/ModelDashboardNew/types";

import "./style.scss";

const ComponentModelDashboardDefault = memo(
    ({ idClient, viewIndex, model, clientName, projectName, componentModels }: ComponentModelDashboardViewsProps) => {
        const { idProject, idModel, idTemplate, name } = model;

        const idInputLog = model.idInputLog as number;

        // @ts-ignore
        const modelStatus = useSelector((state) => get(state.projects, `projections.status.${idProject}.${idInputLog}`));

        // Resources

        const processesInProgress = useProcessesInProgress({ idInputLog });

        const modalProps = {
            title: "Model has been changed",
            overlayClassName: "modal-styled",
            className: "modal-sm",
            children: (
                <ModelNotification
                    viewIndex={viewIndex}
                    idProject={idProject}
                    idInputLog={idInputLog}
                    modelName={name}
                    status={modelStatus?.status}
                    userNumber={modelStatus?.userNumber}
                    lastModified={modelStatus?.lastModified}
                />
            ),
            noFooter: true,
            noCrossButton: true,
            withScroll: false,
        };

        return (
            <FloatModalRoot
                modalId={`modal-component-model-dashboard-${idProject}-${idInputLog}`}
                modalType={modelStatus}
                modalProps={modalProps}
            >
                <div className="component-model-dashboard-default">
                    <div className="component-model-dashboard-default__header-container">
                        <DashboardHeader
                            idClient={idClient}
                            viewIndex={viewIndex}
                            model={model}
                            clientName={clientName}
                            projectName={projectName}
                        />
                    </div>
                    <div
                        className={cn("component-model-dashboard-default__body-container", {
                            "in-progress": processesInProgress.runCalculations,
                        })}
                    >
                        <div>
                            <CalculatedResultsPanel
                                idClient={idClient}
                                idProject={idProject}
                                idModel={idModel}
                                idInputLog={idInputLog}
                                componentModels={componentModels}
                            />
                        </div>
                        <div>
                            <ModelLogPanel idClient={idClient} idProject={idProject} idModel={idModel} idInputLog={idInputLog} />
                        </div>
                        <div>
                            <ModelInputsPanel
                                idClient={idClient}
                                idProject={idProject}
                                idInputLog={idInputLog}
                                idTemplate={idTemplate}
                                model={model}
                                projectName={projectName}
                            />
                        </div>
                        <div>
                            <UtilityForecastPanel idClient={idClient} idProject={idProject} idModel={idModel} idInputLog={idInputLog} />
                        </div>
                    </div>
                </div>
            </FloatModalRoot>
        );
    }
);

export default ComponentModelDashboardDefault;
