import { v1 as uuidv1 } from "uuid";
import { isEmpty, isNil, kebabCase } from "lodash";
import { memo, useCallback, useMemo } from "react";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import { componentModelListResourceName } from "store/configureResources";

import { getResourceState } from "store/utils";

import { selectProjection } from "store/projections/actions";

import { useProcessesInProgress } from "store/processes/useProcessStatus";

import { optimisticUpdateItem } from "store/resources/actions/updateResource";

import { useCombinationList, updateCombination } from "store/resources/actions/projectInput/projectInputCombinationActions";
import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";
import { useStandaloneReports } from "store/resources/actions/standaloneReport/standaloneReportActions";

import { openModalDialogClearTrashBin } from "layouts/Modal/TrashBinDashboardModal/utils";
import { openModalDialogEditHtmlReport, openModalDialogEditPbiReport } from "layouts/Modal/StandaloneReportModal/utils";

import Button from "components/ui/ButtonNew";
import ComponentWithDropdown from "components/ui/Dropdown/ComponentWithDropdown";
import Link from "components/ui/Link";
import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import { IconCombinedModelReport, IconSingleModelReport } from "components/ui/Icons/IconReport";
import LabelWithIcon from "components/ui/LabelWithIcon";
import { Accordion, Item } from "components/ui/Accordion";
import { AccordionItemType } from "components/ui/Accordion/types";

import { useProjectCalculationPreconditions } from "utils/useCalculationPreconditions";
import { useCalculationResultProjectLog } from "utils/useCalculationResult";
import { usePotentialFlag } from "utils/usePotentialFlag";
import { usePowerBIReportsRefreshStatus } from "utils/usePowerBIReportsRefreshStatus";
import { sortArray } from "utils/array";
import { projections, singleModelDatasetId, combinedModelDatasetId, measureLevelDatasetId } from "utils/constants";
import { hasAnyOfPermissions, hasInternalUserRole } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";
import { openWindowMeasureLevelReport } from "utils/window";

import { ProjectionsSelectionSidebarProps } from "./types";
import { ProjectInput } from "store/resources/actions/projectInput/types";
import { ComponentModel, ComponentModelResponseModel } from "store/resources/actions/componentModel/types";
import { ModelCombination } from "types/api/ModelCombinationResponseModel";
import { StandaloneReport } from "store/resources/actions/standaloneReport/types";

import "./style.scss";

const ProjectionsSelectionSidebar = memo(({ viewIndex, project, selectedProjection }: ProjectionsSelectionSidebarProps) => {
    const { idClient, idProject, projectName } = project;

    const dispatch = useDispatch();

    const state = useSelector((state) => state);

    const { isRefreshInProgress: isSingleModelReportRefreshInProgress } = usePowerBIReportsRefreshStatus({
        datasetId: singleModelDatasetId,
    });

    const { isRefreshInProgress: isCombinedModelReportRefreshInProgress } = usePowerBIReportsRefreshStatus({
        datasetId: combinedModelDatasetId,
    });

    const { isRefreshInProgress: isMeasureLevelReportRefreshInProgress } = usePowerBIReportsRefreshStatus({
        datasetId: measureLevelDatasetId,
    });

    // Resources

    const [allModelCombinations = [], isLoadingModelCombinations, waitReadModelCombinations] = useCombinationList({ idProject });
    const [allModels = [], isLoadingModels, waitReadModels] = useProjectInputs({ idProject });
    const [allComponentModels = [], isLoadingComponentModels, waitReadComponentModels] = useComponentModels({ idProject });
    const [allStandaloneReports = [], isLoadingStandaloneReports, waitReadStandaloneReports] = useStandaloneReports({ idProject });

    const processesInProgress = useProcessesInProgress({ idProject });

    const { projectCalculationPreconditions } = useProjectCalculationPreconditions({ idProject });
    const [calculationResults] = useCalculationResultProjectLog({ idProject });
    const { projectPotentialFlag } = usePotentialFlag({ idProject });

    // Variables and useMemos

    const isLoading = isLoadingModelCombinations || isLoadingModels || isLoadingComponentModels || isLoadingStandaloneReports;

    const isReading =
        waitReadModelCombinations?.isReading ||
        waitReadModels?.isReading ||
        waitReadComponentModels?.isReading ||
        waitReadStandaloneReports?.isReading;

    const bookmarkedModelCombinations = useMemo(() => {
        let bookmarkedModelCombinations: any[] = [];

        if (!isEmpty(allModelCombinations) && !isLoadingModelCombinations) {
            bookmarkedModelCombinations = allModelCombinations.filter((combination: ModelCombination) => combination.bookmarked === true);
        }

        return bookmarkedModelCombinations;
    }, [allModelCombinations, isLoadingModelCombinations]);

    const [models, modelsInTrash] = useMemo(() => {
        let models: any[] = [];
        let modelsInTrash: any[] = [];

        let legacyModels: ProjectInput[] = [];
        let legacyModelsInTrash: ProjectInput[] = [];
        let componentModels: ComponentModel[] = [];
        let componentModelsInTrash: ComponentModel[] = [];

        if (!isEmpty(allComponentModels)) {
            componentModels = allComponentModels.filter((model) => model.active === true);
            componentModelsInTrash = allComponentModels.filter((model) => model.active === false);
        }

        if (!isEmpty(allModels)) {
            // Extra precaution step not to render duplicate model
            // There is an issue in Production where duplicate
            // legacy model would render
            legacyModels = allModels.filter(
                (model: ProjectInput) => model.active === true && !componentModels.find((cModel) => cModel.idInputLog === model.idInputLog)
            );
            legacyModelsInTrash = allModels.filter(
                (model: ProjectInput) => model.active === false && !componentModels.find((cModel) => cModel.idInputLog === model.idInputLog)
            );
        }

        const tempModels = sortArray([...componentModels, ...legacyModels], "name");

        // Sort "New Model" to be first in the list
        // Note: This will not be needed if Legacy Models
        // are removed
        models = tempModels.sort((a, b) =>
            a.idInputLog?.toString().includes("new-model") || a.idModel?.toString().includes("new-model")
                ? -1
                : b.idInputLog?.toString().includes("new-model") || b.idModel?.toString().includes("new-model")
                ? 1
                : 0
        );

        modelsInTrash = sortArray([...componentModelsInTrash, ...legacyModelsInTrash]);

        return [models, modelsInTrash];
    }, [allModels, allComponentModels]);

    const [standaloneReports, standaloneReportsInTrash] = useMemo(() => {
        let standaloneReports: any[] = [];
        let standaloneReportsInTrash: any[] = [];

        if (!isEmpty(allStandaloneReports) && !isLoadingStandaloneReports) {
            standaloneReports = allStandaloneReports.filter((standaloneReport: StandaloneReport) => standaloneReport.active === true);
            standaloneReportsInTrash = allStandaloneReports.filter(
                (standaloneReport: StandaloneReport) => standaloneReport.active === false
            );
        }

        return [standaloneReports, standaloneReportsInTrash];
    }, [allStandaloneReports, isLoadingStandaloneReports]);

    const onTitleClick = useCallback(
        (idProjectionView: string, event?: React.MouseEvent<HTMLElement>) => {
            event?.stopPropagation?.();

            dispatch(selectProjection({ viewIndex, idProjectionView, idProject }));
        },
        [viewIndex, idProject, dispatch]
    );

    const onProjectionClick = useCallback(
        ({ idInputLog, idModel, idReport, uploadProps }: any) => {
            const onClick = (idProjectionView: string, idProjection?: number, key?: string) => () => {
                dispatch(
                    selectProjection({
                        viewIndex,
                        idProjectionView,
                        idProject,
                        idProjection,
                        key,
                    })
                );
            };

            // Clicking on "New Model",
            // if projection has idModel property,
            // opens Component Model creation form
            if (typeof idModel === "string" && !uploadProps?.isUploading) {
                return onClick(projections.COMPONENT_MODEL_SETUP);
            }
            // Clicking on "New Model",
            // if projection has idInputLog property,
            // opens Legacy Model creation form
            else if (typeof idInputLog === "string" && !uploadProps?.isUploading) {
                return onClick(projections.MODEL_SETUP);
            }

            // Projection has idModel property - COMPONENT MODEL DASHBOARD
            if (idModel) {
                return onClick(projections.COMPONENT_MODEL_DASHBOARD, idModel, "idModel");
            }
            // Projection has idInputLog property - MODEL DASHBOARD
            else if (idInputLog) {
                return onClick(projections.MODEL_DASHBOARD, idInputLog, "idInputLog");
            }
            // Projection has idReport property - STANDALONE REPORT DASHBOARD
            else if (idReport) {
                return onClick(projections.STANDALONE_REPORT_DASHBOARD, idReport, "idReport");
            }
        },
        [viewIndex, idProject, dispatch]
    );

    const onAddHTMLReportClick = useCallback(() => {
        dispatch(openModalDialogEditHtmlReport({ viewIndex, idProject }));
    }, [viewIndex, idProject, dispatch]);

    const onAddPBIReportClick = useCallback(() => {
        dispatch(openModalDialogEditPbiReport({ idProject }));
    }, [idProject, dispatch]);

    const onBookmarkIconClick = useCallback(
        (idCombination, combinationName) => {
            dispatch(
                // @ts-ignore
                updateCombination({
                    idProject,
                    idCombination: idCombination,
                    combinationName: combinationName,
                    bookmarked: false,
                    action: "Removing combination from bookmarks",
                })
            );
        },
        [idProject, dispatch]
    );

    const onMeasureLevelReportIconClick = useCallback(
        (idCombination, combinationName) => {
            dispatch(
                openWindowMeasureLevelReport({
                    idClient,
                    idProject,
                    filterEntityId: idCombination,
                    projectName,
                    combinationName,
                })
            );
        },
        [idClient, idProject, projectName, dispatch]
    );

    const onTrashBinIconClick = useCallback(() => {
        dispatch(
            openModalDialogClearTrashBin({
                idProject,
                trashCount: modelsInTrash.length + standaloneReportsInTrash.length,
            })
        );
    }, [idProject, modelsInTrash.length, standaloneReportsInTrash.length, dispatch]);

    const addNewReportItems = useMemo(() => {
        return [
            {
                label: "Add HTML Report",
                onSelect: onAddHTMLReportClick,
            },
            {
                label: "Add PBI Report",
                onSelect: onAddPBIReportClick,
            },
        ];
    }, [onAddHTMLReportClick, onAddPBIReportClick]);

    const onAddNewModelClick = useCallback(() => {
        let newModel = {};

        const allComponentModelsFromStore = getResourceState<ComponentModelResponseModel>(state, componentModelListResourceName, {
            idProject,
        });

        // If Component Models list does not have "New Model" placeholder,
        // create one
        if (!allComponentModelsFromStore.find((model) => model.modelId.toString().includes("new-model"))) {
            newModel = {
                projectId: idProject,
                inputLogId: null,
                modelId: `new-model-${uuidv1()}`,
                name: "New Model",
                active: true,
                persist: true, // this property makes this optimistic item persist on state update
            };
        }

        // If Component Models list have "New Model" placeholder,
        // do not do optimstic update
        if (!isEmpty(newModel)) {
            const newModelItems = [newModel, ...allComponentModelsFromStore];

            dispatch(
                optimisticUpdateItem({
                    resourceName: componentModelListResourceName,
                    key: `${componentModelListResourceName}-${idProject}`,
                    value: newModelItems,
                })
            );
        }

        onTitleClick(projections.COMPONENT_MODEL_SETUP);
    }, [idProject, state, onTitleClick, dispatch]);

    const getActionName = () => {
        if (waitReadModels?.isReading && waitReadStandaloneReports?.isReading) {
            return `${waitReadModels.actionName} & ${waitReadStandaloneReports.actionName}`;
        } else if (waitReadModelCombinations?.isReading) {
            return waitReadModelCombinations.actionName;
        } else if (waitReadModels?.isReading) {
            return waitReadModels.actionName;
        } else if (waitReadComponentModels?.isReading) {
            return waitReadComponentModels.actionName;
        } else if (waitReadStandaloneReports?.isReading) {
            return waitReadStandaloneReports.actionName;
        }
    };

    const renderCombination = (combination: any) => {
        const { idCombination, combinationName } = combination;

        return (
            <div className="flex-row align-center projections-selection-sidebar__projection">
                <div className="projections-selection-sidebar__status-container" />
                <span className="flex-column flex-one justify-center projections-selection-sidebar__projection-name">
                    {combinationName}
                </span>
                <span className="flex-column">
                    <span className="flex-row align-center justify-center">
                        {(isCombinedModelReportRefreshInProgress || isMeasureLevelReportRefreshInProgress) && (
                            <Icon
                                className="rotate projections-selection-sidebar__refresh-icon"
                                icon="arrows-sync__autorenew_b_a"
                                iconTitle="Refreshing reports"
                                disabled
                                size="xs"
                            />
                        )}
                        {hasAnyOfPermissions([USER_ACTIONS.MODELS_COMBINE]) && (
                            <Icon
                                icon="files-bookmark_a_f"
                                size="sm"
                                iconTitle="Unpublish reports"
                                onClick={() => onBookmarkIconClick(idCombination, combinationName)}
                            />
                        )}
                        <IconCombinedModelReport
                            idClient={idClient}
                            idProject={idProject}
                            idCombination={idCombination}
                            projectName={projectName}
                            combinationName={combinationName}
                        />
                        <Icon
                            icon="files-document_table_b_s"
                            size="sm"
                            iconTitle="Measure-level report"
                            onClick={() => onMeasureLevelReportIconClick(idCombination, combinationName)}
                        />
                    </span>
                </span>
            </div>
        );
    };

    const renderProjection = (projection: any) => {
        const idModel = projection.idModel; // Component Model
        const idInputLog = projection.idInputLog; // Legacy Model
        const idReport = projection.idReport; // Standalone Report

        // Only for Models
        let cannotRunBaseline = false;
        let cannotRunPotential = false;
        let cannotRunLabel = "";

        const cannotRunArray = [];

        if (idInputLog !== undefined) {
            cannotRunBaseline = projectCalculationPreconditions.BASELINE_PRECONDITIONS?.includes(idInputLog);
            cannotRunPotential = projectCalculationPreconditions.POTENTIAL_PRECONDITIONS?.includes(idInputLog) && projectPotentialFlag;

            cannotRunBaseline && cannotRunArray.push("Baseline");
            cannotRunPotential && cannotRunArray.push("Potential");

            if (!isEmpty(cannotRunArray)) {
                cannotRunLabel = `Cannot run ${cannotRunArray.join(" and ")} calculation, check Model Inputs`;
            }
        }

        return (
            <div
                className={cn("flex-row flex-one align-center projections-selection-sidebar__projection", {
                    selected:
                        (idModel !== undefined && idModel === selectedProjection.idModel) ||
                        (idInputLog !== undefined && idInputLog === selectedProjection.idInputLog) ||
                        (idReport !== undefined && idReport === selectedProjection.idReport) ||
                        ((selectedProjection.idProjectionView === projections.COMPONENT_MODEL_SETUP ||
                            selectedProjection.idProjectionView === projections.MODEL_SETUP) &&
                            (idModel?.toString().includes("new-model") || idInputLog?.toString().includes("new-model"))),
                })}
            >
                <div className="projections-selection-sidebar__status-container">
                    {!isNil(projection.modelState) && (hasInternalUserRole() ? true : projection.required) && (
                        <div
                            className={cn("projections-selection-sidebar__status-circle", kebabCase(projection.modelState))}
                            title={`Input Log ID: ${idInputLog}\n${idModel !== undefined ? `Model ID: ${idModel}\n` : ""}Model status: ${
                                projection.modelState
                            }`}
                        />
                    )}
                </div>

                <div
                    data-testid="component-model-name"
                    className="flex-column flex-one justify-center projections-selection-sidebar__projection-name"
                >
                    {projection.name}
                </div>

                {/* Only for "New Model" Models */}
                {((typeof idModel === "string" && !idModel.toString().includes("new-model")) ||
                    (typeof idInputLog === "string" && !idInputLog.toString().includes("new-model"))) && (
                    // Dataset upload
                    <Icon className="rotate" icon="arrows-sync__autorenew_b_a" iconTitle="Uploading dataset" size="sm" />
                )}

                {/* Only for Models */}
                {idInputLog !== undefined && processesInProgress && (
                    <div>
                        {/* Transforming dataset or calculation in progress */}
                        {Object.keys(processesInProgress).map(
                            (key) =>
                                processesInProgress[key]?.[idInputLog] && (
                                    <Icon
                                        key={key}
                                        className="rotate"
                                        icon="arrows-sync__autorenew_b_a"
                                        iconTitle={
                                            key === "modelDataImport"
                                                ? "Transforming dataset"
                                                : idModel
                                                ? "Running modules"
                                                : "Running calculations"
                                        }
                                        size="sm"
                                    />
                                )
                        )}

                        {!Object.keys(processesInProgress).some((key) => processesInProgress[key]?.[idInputLog]) && (
                            <div className="flex-row align-center">
                                {/* Calculation has result */}
                                {calculationResults?.[idInputLog] && (
                                    <>
                                        {isSingleModelReportRefreshInProgress && (
                                            <Icon
                                                className="rotate projections-selection-sidebar__refresh-icon"
                                                icon="arrows-sync__autorenew_b_a"
                                                iconTitle="Refreshing report"
                                                disabled
                                                size="xs"
                                            />
                                        )}
                                        {isNil(idModel) ? (
                                            // Legacy Model
                                            <IconSingleModelReport
                                                idClient={idClient}
                                                idProject={idProject}
                                                idInputLog={idInputLog}
                                                title={projection.name}
                                                subTitle={`${calculationResults[idInputLog].isPotential ? "Potential" : "Baseline"} result`}
                                                calculationResult={calculationResults[idInputLog]}
                                            />
                                        ) : (
                                            // Component Model
                                            <IconSingleModelReport
                                                idClient={idClient}
                                                idProject={idProject}
                                                idModel={idModel}
                                                idInputLog={idInputLog}
                                                title={projection.name}
                                                subTitle={calculationResults[idInputLog].parameters?.CASE_STUDIES?.[0].NAME}
                                                calculationResult={calculationResults[idInputLog]}
                                                withApprovement={
                                                    calculationResults[idInputLog].isInClientReview &&
                                                    hasAnyOfPermissions([USER_ACTIONS.MODULES_APPROVE])
                                                }
                                            />
                                        )}
                                    </>
                                )}

                                {/* Calculation cannot run */}
                                {(cannotRunBaseline || cannotRunPotential) && hasInternalUserRole() && (
                                    <>
                                        {calculationResults?.[idInputLog] && <div className="margin-left-small" />}
                                        <Icon variant="error" icon="text-alert__error_b_f" iconTitle={cannotRunLabel} size="sm" />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="flex-column fill-height projections-selection-sidebar">
            {isLoading ? (
                <div className="flex-row align-center justify-center fill-height">
                    <IconLoading />
                </div>
            ) : (
                <div className="flex-column flex-one-in-column">
                    {isReading && (
                        <div className="flex-row justify-center projections-selection-sidebar__action-running">
                            <IconLoading label={getActionName()} />
                        </div>
                    )}
                    <div className="with-scroll">
                        <div className="projections-selection-sidebar__main-header-overview">Overview</div>
                        <Link
                            className={cn("flex-row align-center projections-selection-sidebar__main-header-container", {
                                selected: selectedProjection.idProjectionView === projections.PROJECT_DASHBOARD,
                            })}
                            onClick={(e: any) => onTitleClick(projections.PROJECT_DASHBOARD, e)}
                        >
                            <LabelWithIcon className="with-aura" icon="files-assignment_b_s" iconSize="lg" clickable>
                                Project Dashboard
                            </LabelWithIcon>
                        </Link>
                        <div className="projections-selection-sidebar__main-header-overview">Project Work</div>
                        <Accordion
                            defaultExpandedKeys={["models", "reports"]}
                            items={[
                                {
                                    key: "models",
                                    title: "Models",
                                    children: {
                                        header: (
                                            <LabelWithIcon
                                                className="with-aura"
                                                icon="files-document_new__empty_b_s"
                                                iconSize="lg"
                                                clickable
                                            >
                                                Models
                                            </LabelWithIcon>
                                        ),
                                        action: (
                                            <div>
                                                {hasAnyOfPermissions([USER_ACTIONS.MODEL_ADD]) && (
                                                    <Button
                                                        variant="tertiary"
                                                        padding="md"
                                                        iconLeft="ui-plus_in_circle__add__create_b_s"
                                                        iconSize="sm"
                                                        onClick={onAddNewModelClick}
                                                    >
                                                        Add new
                                                    </Button>
                                                )}
                                            </div>
                                        ),
                                        items: models,
                                        renderItem: renderProjection,
                                        handleClick: onProjectionClick,
                                    },
                                },
                                {
                                    key: "reports",
                                    title: "Reports",
                                    children: {
                                        header: (
                                            <LabelWithIcon
                                                className="with-aura"
                                                icon="files-document_diagram__poll_b_s"
                                                iconSize="lg"
                                                clickable
                                            >
                                                Reports
                                            </LabelWithIcon>
                                        ),
                                        action: (
                                            <div>
                                                {hasAnyOfPermissions([USER_ACTIONS.SA_REPORT_ADD]) && (
                                                    <ComponentWithDropdown
                                                        component={
                                                            <Button
                                                                className="add-new"
                                                                variant="tertiary"
                                                                padding="md"
                                                                iconLeft="ui-plus_in_circle__add__create_b_s"
                                                                iconSize="sm"
                                                            >
                                                                Add new
                                                            </Button>
                                                        }
                                                        withoutShevron
                                                        items={addNewReportItems}
                                                    />
                                                )}
                                            </div>
                                        ),
                                        items: [...standaloneReports, ...bookmarkedModelCombinations],
                                        renderItem: (item) => (!!item?.bookmarked ? renderCombination(item) : renderProjection(item)),
                                        handleClick: onProjectionClick,
                                    },
                                },
                                {
                                    key: "trashbin",
                                    title: "Trash Bin",
                                    children: {
                                        header: (
                                            <LabelWithIcon
                                                className="with-aura"
                                                icon="ui-trash__garbage__delete__remove__bin_b_s"
                                                iconSize="lg"
                                                onClick={(e: any) => onTitleClick(projections.TRASH_BIN_DASHBOARD, e)}
                                            >
                                                Trash Bin
                                            </LabelWithIcon>
                                        ),
                                        action: (
                                            <div>
                                                {hasAnyOfPermissions([USER_ACTIONS.PROJECTION_DELETE_ALL]) && (
                                                    <Button
                                                        variant="tertiary"
                                                        isDisabled={isEmpty(modelsInTrash) && isEmpty(standaloneReportsInTrash)}
                                                        padding="md"
                                                        onClick={onTrashBinIconClick}
                                                    >
                                                        Clear All
                                                    </Button>
                                                )}
                                            </div>
                                        ),
                                        items: modelsInTrash.concat(standaloneReportsInTrash),
                                        renderItem: renderProjection,
                                        handleClick: onProjectionClick,
                                    },
                                },
                            ]}
                        >
                            {(item: AccordionItemType) => (
                                <Item key={item.key} title={item.title}>
                                    {item.title}
                                </Item>
                            )}
                        </Accordion>
                    </div>
                </div>
            )}
        </div>
    );
});

export default ProjectionsSelectionSidebar;
