import "./style.scss";

const Copyright = () => {
    return (
        <div className="fill-width text-center copyright">
            &copy; {new Date().getFullYear()} Applied Energy Group, Inc. All Rights Reserved
        </div>
    );
};

export default Copyright;
