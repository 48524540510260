import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { logout } from "store/login/actions";
import { windowChangeSplit } from "store/window/actions";

import Dropdown from "components/ui/Dropdown";

import WindowHeaderIconGroup from "./WindowHeaderIconGroup";

import { isVLMLoginPage } from "utils/constants";
import { openWindowUserProfile } from "utils/window";

import { View } from "store/window/types";

import "./style.scss";

const WindowHeader = memo((props: WindowHeaderProps) => {
    const { view, completeName, close, isSplitViewEnabled, splitView, onClose, onSplit, onRemoveSplit } = props;

    const dispatch = useDispatch();

    const { containerName, name, headerTitle, headerSubTitle } = view;

    const onPresentationModeClick = useCallback(() => {
        const element = document.getElementById("presentation-mode-window");

        if (element !== null) {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                element.requestFullscreen();
            }
        }
    }, []);

    const onAvatarClick = useCallback(() => {
        dispatch(openWindowUserProfile());
    }, [dispatch]);

    const onLogout = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const onDropdownChange = useCallback(
        (value, { filterEntityId, name, tabSubTitle }) => {
            const newView = {
                ...view,
                name,
                tabSubTitle,
                props: { ...view.props, [view.props.idSelector]: value, filterEntityId },
            };

            if (splitView && completeName) {
                dispatch(
                    windowChangeSplit({
                        name: completeName,
                        containerName,
                        side: view.props.side,
                        view: newView,
                    })
                );
            }
        },
        [view, completeName, splitView, containerName, dispatch]
    );

    const renderHeader = () => {
        // Header as a Dropdown component
        if (view.props?.renderDropdownHeader) {
            return (
                <div className="flex-one window-header__dropdown-container">
                    <Dropdown
                        value={view.props[view.props.idSelector]}
                        items={view.props.dropdownItems}
                        onChange={(value, args) => onDropdownChange(value, args)}
                    />
                </div>
            );
        }
        // Add else ifs here if more new elements are needed...
        // Regular header
        else {
            return (
                <>
                    {name === "Home" && (
                        <div
                            className={cn("window-header__logo", {
                                "window-header__vlm-logo": isVLMLoginPage,
                            })}
                        />
                    )}
                    <div className="flex-row align-center flex-one">
                        <div className="window-header__title">{headerTitle}</div>
                        {headerSubTitle && <div className="window-header__subtitle">{headerSubTitle}</div>}
                    </div>
                </>
            );
        }
    };

    return (
        <div className="window-header">
            <div className="flex-row align-center window-header__body">
                {renderHeader()}
                <WindowHeaderIconGroup
                    name={name}
                    onClose={close ? onClose : undefined}
                    onSplit={isSplitViewEnabled ? onSplit : undefined}
                    onRemoveSplit={splitView && isSplitViewEnabled ? onRemoveSplit : undefined}
                    onPresentationModeClick={view.props?.renderPresentationMode ? onPresentationModeClick : undefined}
                    onAvatarClick={name === "Home" ? onAvatarClick : undefined}
                    onLogout={name === "Home" ? onLogout : undefined}
                />
            </div>
        </div>
    );
});

interface WindowHeaderProps {
    /**
     * Current View.
     */
    view: View;

    /**
     * Split view's main name.
     *
     * NOTE: Used for split view.
     */
    completeName?: string;

    /**
     * View can be closed.
     */
    close?: boolean;

    /**
     * Split view functionality enabled for the View.
     */
    isSplitViewEnabled?: boolean;

    /**
     * Split view active for the View.
     */
    splitView?: boolean;

    /**
     * Function that closes a View.
     */
    onClose?: (name: string) => void;

    /**
     * Function that splits the View.
     */
    onSplit?: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit?: (name: string) => void;
}

export default WindowHeader;
