import { logWarning } from "utils/logger";

const formatError = (error) => {
    const maxLength = {
        errorName: 150,
        errorMessage: 1000,
        errorStack: 1000,
        errorComponentStack: 1000,
        errorUserAgent: 500,
        errorURL: 500,
    };

    Object.keys(error).forEach((key) => {
        error[key] = error[key] || "";

        if (typeof error[key] === "object") {
            error[key] = JSON.stringify(error[key]);
        }

        if (maxLength[key] && error[key]) {
            if (error[key].length > maxLength[key]) {
                logWarning(`${key} too long: ${error[key].length}|${maxLength[key]}`);
                error[key] = error[key].substring(0, maxLength[key]);
            }
        }
    });

    return error;
};

export const createHttpError = ({ name, message, body, url, state }) => {
    return formatError({
        shortMessage: name,
        errorMessage: message,
        errorStack: body,
        errorComponentStack: "",
        errorUserAgent: navigator.userAgent,
        errorURL: url,
        host: document.location.host,
        errorState: "",
        // TODO: 1. pass state only in verbose debug more (user settings) 2. store VARCHAR(MAX) in database (atm limited to 1000)
        // errorState: state
    });
};

export const createJavascriptError = ({ error, info, state }) => {
    return formatError({
        shortMessage: error.name,
        errorMessage: error.message,
        errorStack: error.stack,
        errorComponentStack: info && info.componentStack,
        errorUserAgent: navigator.userAgent,
        errorURL: document.location.href,
        host: document.location.host,
        errorState: "",
        // TODO: 1. pass state only in verbose debug more (user settings) 2. store VARCHAR(MAX) in database (atm limited to 1000)
        // errorState: state
    });
};
