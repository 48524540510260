import { memo, useCallback, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import { windowRemove } from "store/window/actions";
import { useModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import Button from "components/ui/ButtonNew";
import FloatModalRoot from "components/ui/Modal/FloatModalRoot";

import PowerBI from "pages/PowerBI";

import { windowContainerTypes } from "utils/window";

import { FloatModalPowerBIProps } from "pages/PowerBI/types";

const FloatModalPowerBI = memo((props: FloatModalPowerBIProps) => {
    const { idProject, idInputLog, filterEntityId } = props;

    const dispatch = useDispatch();

    const [modelLogItems, isLoading] = useModelLogItems({ idProject: idProject!, idInputLog });

    const openedAt = useRef(Date.now());

    const resultsAreActual = useMemo(() => {
        return modelLogItems?.findIndex((it: any) => it.idInputLog === idInputLog && it.results === filterEntityId.toString()) >= 0;
    }, [idInputLog, filterEntityId, modelLogItems]);

    const onCloseReportClick = useCallback(() => {
        dispatch(
            windowRemove({
                name: `SingleModelReport-${idInputLog}-${filterEntityId}`,
                containerName: windowContainerTypes.Root,
            })
        );
    }, [idInputLog, filterEntityId, dispatch]);

    const modalProps = {
        overlayClassName: "modal-styled",
        modalRootClassName: "fill-height modal-padding",
        className: "modal-sm",
        title: "The results are out of date",
        noFooter: true,
        noCrossButton: true,
        withScroll: false,
        children: (
            <div className="flex-row align-center justify-center modal-actions">
                <Button variant="secondary" padding="md" onClick={onCloseReportClick}>
                    Close Report
                </Button>
            </div>
        ),
    };

    return (
        <div className="flex-column flex-one-in-column with-scroll">
            <FloatModalRoot
                modalId={`modal-powerbi-${openedAt.current}`}
                modalType={!resultsAreActual && !isLoading && "MODAL"}
                modalProps={modalProps}
            >
                <PowerBI idProject={idProject} {...props} />
            </FloatModalRoot>
        </div>
    );
});

export default FloatModalPowerBI;
