import { SELECT_MODULE } from "store/actionTypes";

import { SelectModulePayload } from "./types";

export const selectModule =
    ({ viewIndex, idModel, moduleId }: SelectModulePayload) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: SELECT_MODULE,
            payload: { viewIndex, idModel, moduleId },
        });
    };
