import { memo, useCallback, useState } from "react";

import FieldGroup from "components/ui/FieldGroup";
import Checkbox from "components/ui/Input/Checkbox";
import Radio from "components/ui/Input/Radio";
import Switcher from "components/ui/Switcher";

const Controls = memo(() => {
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(true);
    const [checkbox3, setCheckbox3] = useState(false);

    const [value1, setValue1] = useState("");

    const [switcher1, setSwitcher1] = useState(false);
    const [switcher2, setSwitcher2] = useState(true);

    const onCheckboxChange = useCallback((event, handler) => {
        handler(event.target.checked);
    }, []);

    const onRadioChange = useCallback((event, handler) => {
        handler(event.target.value);
    }, []);

    const onSwitcherClick = useCallback((name) => {
        switch (name) {
            case "switcher1":
                setSwitcher1((prevState) => !prevState);

                break;

            case "switcher2":
                setSwitcher2((prevState) => !prevState);

                break;
            default:
        }
    }, []);

    return (
        <div className="flex-row">
            <div className="flex-column ui-library__element-group__column" style={{ minWidth: "auto", width: "160px" }}>
                <div className="ui-library__element-group__sub-title">Checkbox</div>
                <FieldGroup>
                    <Checkbox
                        label={checkbox1 ? "Checked Checkbox" : "Unchecked Checkbox"}
                        id="checkbox1"
                        checked={checkbox1}
                        onChange={(event, handler = setCheckbox1) => onCheckboxChange(event, handler)}
                    />
                    <Checkbox
                        label={checkbox2 ? "Checked Checkbox" : "Unchecked Checkbox"}
                        id="checkbox2"
                        checked={checkbox2}
                        onChange={(event, handler = setCheckbox2) => onCheckboxChange(event, handler)}
                    />
                    <Checkbox
                        label="Checkbox with long text that drops to the next line"
                        id="checkbox3"
                        checked={checkbox3}
                        onChange={(event, handler = setCheckbox3) => onCheckboxChange(event, handler)}
                    />
                    <Checkbox label="Disabled Checkbox" disabled />
                    <Checkbox label="Read-only Checkbox" readOnly defaultChecked={true} />
                    <Checkbox label="Error Checkbox" error defaultChecked={false} />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Radio</div>
                <FieldGroup>
                    <FieldGroup direction="row" label="Radio Buttons">
                        <Radio
                            label="Value A"
                            id="a"
                            name="radio-active"
                            value="a"
                            checked={value1 === "a"}
                            onChange={(event, handler = setValue1) => onRadioChange(event, handler)}
                        />
                        <Radio
                            label="Value B"
                            id="b"
                            name="radio-active"
                            value="b"
                            checked={value1 === "b"}
                            onChange={(event, handler = setValue1) => onRadioChange(event, handler)}
                        />
                    </FieldGroup>
                    <FieldGroup direction="row" label="Disabled Radio Buttons">
                        <Radio label="Value A" name="radio-disabled" disabled value="a" />
                        <Radio label="Value B" name="radio-disabled" disabled value="b" />
                    </FieldGroup>
                    <FieldGroup direction="row" label="Read-only Radio Buttons">
                        <Radio label="Value A" name="radio-read-only" readOnly value="a" />
                        <Radio label="Value B" name="radio-read-only" readOnly value="b" />
                    </FieldGroup>
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Switcher</div>
                <FieldGroup>
                    <Switcher active={switcher1} label={switcher1 ? "ON" : "OFF"} onClick={() => onSwitcherClick("switcher1")} />
                    <Switcher active={switcher2} label={switcher2 ? "ON" : "OFF"} onClick={() => onSwitcherClick("switcher2")} />
                    <Switcher disabled label="Disabled" />
                    <Switcher readOnly label="Read-only" />
                </FieldGroup>
            </div>
        </div>
    );
});

export default Controls;
