import Calculations from "./Calculations";
import Configurations from "./Configurations";
import Inputs from "./Inputs";
import Runs from "./Runs";

export const moduleTabs = [
    {
        id: "configurations",
        label1: "Configurations",
        label2: "configurations",
        component: Configurations,
    },
    {
        id: "inputs",
        label1: "Inputs",
        label2: "inputs",
        component: Inputs,
    },
    {
        id: "calculations",
        label1: "Calculations",
        label2: "calculations",
        component: Calculations,
    },
    {
        id: "runs",
        label1: "Runs",
        label2: "runs",
        component: Runs,
    },
];

export const drPotentialTables = ["DR Forecast", "DR Program Inputs"];

export const storageStatuses = ["Keep Permanently", "Keep Last Runs", "Delete"];

export const studyCaseVariables = ["Economic Test", "Include Achievable"];
