import { v4 as uuidv4 } from "uuid";
import { createRef, memo, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { useClientList } from "store/resources/actions/client/clientActions";
import { useAllTerritories } from "store/resources/actions/territory/territoryActions";
import { useAllFuels } from "store/resources/actions/fuel/fuelActions";
import { createClient } from "store/resources/actions/client/clientActions";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import IconWrap from "components/ui/Icons";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import Checkbox from "components/ui/Input/Checkbox";
import CustomList from "components/ui/List/CustomList";
import TextArea from "components/ui/TextArea";
import Separator from "components/ui/Separator";

import { isNullOrWhitespace } from "utils/string";

import { ModalOpenChildrenProps } from "layouts/Modal/types";
import { Territory, Fuel } from "types/types";

import "./style.scss";

const HEADERS = {
    alias: "NAME",
    code: "SHORTCODE",
    remove: "",
};

const CreateClient = memo(({ onCancel }: ModalOpenChildrenProps) => {
    const dispatch = useDispatch();

    const [clients = []] = useClientList();

    const [clientName, setClientName] = useState("");
    const [clientDescription, setClientDescription] = useState("");
    const [clientTerritories, setClientTerritories] = useState([]);
    const [newTerritories, setNewTerritories] = useState<any[]>([]);
    const [clientFuels, setClientFuels] = useState<any>({});

    const [allTerritories, isLoadingTerritory] = useAllTerritories();
    const [allFuels, isLoadingFuel] = useAllFuels();

    const clientNameExists = useMemo(() => {
        return clients.some((client: any) => client.clientName.toLowerCase() === clientName.toLowerCase().trim());
    }, [clients, clientName]);

    const territoryItems = useMemo(() => {
        let territoryItems = [];

        if (allTerritories && !isLoadingTerritory) {
            territoryItems = allTerritories.map((territory: Territory) => ({
                label: `${territory.name} ${territory.alias}`,
                value: territory.name,
            }));
        }

        return territoryItems;
    }, [allTerritories, isLoadingTerritory]);

    const onAddTerritoryClick = useCallback(() => {
        setNewTerritories([...newTerritories, { id: uuidv4(), alias: createRef(), code: createRef() }]);
    }, [newTerritories]);

    const onRemoveLookupClick = useCallback(
        (id) => {
            setNewTerritories(newTerritories.filter((p) => p.id !== id));
        },
        [newTerritories]
    );

    const onChange = useCallback((value, handler) => {
        handler(value);
    }, []);

    const onFuelChange = useCallback(
        (event) => {
            setClientFuels({ ...clientFuels, [event.target.name]: event.target.checked });
        },
        [clientFuels]
    );

    const onAddClientClick = useCallback(() => {
        if (clientName) {
            const territoriesToAdd = newTerritories
                ?.map((t) => ({
                    idTerritory: null,
                    name: t.code?.current?.value,
                    alias: t.alias?.current?.value,
                }))
                .filter((p) => p.name !== undefined && p.name !== "");

            const existingTerritories = clientTerritories?.map((p) => allTerritories?.find((x: Territory) => x.name === p));
            const territories = [...(existingTerritories || []), ...(territoriesToAdd || [])];

            const fuelSelected = Object.keys(clientFuels)
                .map((key) => (clientFuels[key] === true ? key : null))
                ?.filter((p) => p !== null);

            const fuels = fuelSelected?.map((p) => allFuels?.find((x: Fuel) => x.name === p));

            dispatch(
                createClient({
                    clientName,
                    clientDescription,
                    territories,
                    fuels,
                    onComplete: onCancel,
                })
            );
        }
    }, [clientName, clientDescription, clientTerritories, clientFuels, newTerritories, allTerritories, allFuels, onCancel, dispatch]);

    const renderNewTerritories = useCallback(
        (item) => {
            return (
                <div key={item.id} className="list-item-row">
                    <div className="item-value column-alias">
                        <Input inputRef={item.alias} defaultValue={item.alias?.current?.value} />
                    </div>
                    <div className="item-value column-code">
                        <Input inputRef={item.code} defaultValue={item.code?.current?.value} />
                    </div>
                    <div className="item-value column-remove">
                        <IconWrap icon="clear-close" title="Remove" onClick={() => onRemoveLookupClick(item.id)} />
                    </div>
                </div>
            );
        },
        [onRemoveLookupClick]
    );

    const renderFuels = (fuel: string, index: number) => {
        return (
            <Checkbox
                key={`fuel-${fuel}-${index}`}
                id={`client-${fuel}`}
                name={fuel}
                label={fuel}
                checked={clientFuels[fuel] || false}
                onChange={onFuelChange}
            />
        );
    };

    return (
        <>
            <div className="flex-column modal-padding with-scroll client-create">
                {isLoadingTerritory || isLoadingFuel ? (
                    <IconLoading />
                ) : (
                    <div className="flex-column">
                        <FieldGroup>
                            <Input
                                label="Client name"
                                required
                                value={clientName}
                                error={clientNameExists}
                                msgText={clientNameExists ? "This name is already in use." : ""}
                                onChange={(event, handler = setClientName) => onChange(event.target.value, handler)}
                            />
                            <TextArea
                                label="Description"
                                placeholder="Type a client description"
                                value={clientDescription}
                                onChange={(event, handler = setClientDescription) => onChange(event.target.value, handler)}
                            />
                        </FieldGroup>
                        <Separator line />
                        <FieldGroup>
                            <Dropdown
                                label="Territory"
                                placeholder="Select from the list"
                                withFilter
                                multiple
                                singleLine
                                value={clientTerritories}
                                items={territoryItems}
                                onChange={(value, handler = setClientTerritories) => onChange(value, handler)}
                            />
                            {newTerritories.length > 0 && (
                                <div>
                                    <CustomList
                                        // @ts-ignore
                                        headers={HEADERS}
                                        items={newTerritories}
                                        scrollBody
                                        renderItem={renderNewTerritories}
                                    />
                                </div>
                            )}
                            <Button
                                variant="tertiary"
                                iconLeft="ui-plus_in_circle__add__create_b_s"
                                iconSize="sm"
                                padding="md"
                                onClick={onAddTerritoryClick}
                            >
                                Add territory
                            </Button>
                        </FieldGroup>
                        <Separator line />
                        <FieldGroup direction="row" label="Fuel">
                            {allFuels?.map((fuel: Fuel, index: number) => renderFuels(fuel.name, index))}
                        </FieldGroup>
                    </div>
                )}
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button
                    variant="primary"
                    padding="lg"
                    isDisabled={isNullOrWhitespace(clientName) || clientNameExists}
                    onClick={onAddClientClick}
                >
                    Create
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default CreateClient;
