import { useSelector } from "react-redux";

import SideNav from ".";

export const getViewSideNavKey = (name: string, position: string) => {
    return `${name}-${position}`;
};

const SideNavRoot = ({ id }: SideNavRootProps) => {
    // @ts-ignore - remove when state typed
    const sideNavigationProps = useSelector((state) => state.sideNav[id]);

    if (!id || !sideNavigationProps) {
        return null;
    }

    const position = sideNavigationProps.position || (id.indexOf("left") > -1 ? "left" : "right");

    return <SideNav id={id} position={position} {...sideNavigationProps} />;
};

interface SideNavRootProps {
    id?: string;
}

export default SideNavRoot;
