import { isEmpty, kebabCase } from "lodash";
import { memo, useMemo } from "react";
import cn from "classnames";

import { useBookmarkedProjectStatuses } from "store/resources/actions/project/bookmarkedProjectStatusesActions";

import IconLoading from "components/ui/Icons/IconLoading";

import "./style.scss";

const BookmarkedProjectStatuses = memo(() => {
    const [bookmarkedProjectStatuses, isLoadingBookmarkedProjectStatuses] = useBookmarkedProjectStatuses();

    const totalCount = useMemo(() => {
        let totalCount = 0;

        if (!isEmpty(bookmarkedProjectStatuses) && !isLoadingBookmarkedProjectStatuses) {
            bookmarkedProjectStatuses.forEach((status) => {
                totalCount += status.projectCount;
            });
        }

        return totalCount;
    }, [bookmarkedProjectStatuses, isLoadingBookmarkedProjectStatuses]);

    const renderChart = (className: string, count: number) => {
        const width = ((count / totalCount) * 100).toFixed(2);

        return <div key={`${className}-chart`} className={cn("chart-line", className)} style={{ width: `${width}%` }} />;
    };

    return isLoadingBookmarkedProjectStatuses ? (
        <IconLoading />
    ) : (
        <div className="flex-row align-center no-scroll bookmarked-project-statuses">
            <div className="bookmarked-project-statuses__total-projects">{totalCount}</div>
            <div className="flex-column flex-one bookmarked-project-statuses__chart-container">
                {bookmarkedProjectStatuses?.map(
                    (status) => status.projectCount > 0 && renderChart(kebabCase(status.projectState), status.projectCount)
                )}
            </div>
            <div className="flex-column bookmarked-project-statuses__legends-container">
                {bookmarkedProjectStatuses?.map(
                    (status) =>
                        status.projectCount > 0 && (
                            <div key={`${status.projectState}-legend`} className="flex-row align-center justify-end legend-label-container">
                                <span className="legend-label">{`${status.projectCount} ${status.projectState.toLowerCase()}`}</span>
                                <span className={cn("ellipsis", kebabCase(status.projectState))} />
                            </div>
                        )
                )}
            </div>
        </div>
    );
});

export default BookmarkedProjectStatuses;
