import { singleResultResourceName, multipleResultsResourceName, projectFileResourceName } from "store/configureResources";
import {
    DOWNLOAD_FILE_START,
    DOWNLOAD_FILE_STARTED,
    DOWNLOAD_FILE_PROGRESS,
    DOWNLOAD_FILE_COMPLETE,
    DOWNLOAD_FILE_ERROR,
} from "store/actionTypes";
import { getResourceUrl } from "store/utils";

import { isResponseJson } from "store/api/functions";

import { refresh } from "store/login/actions";

import { openModalDialogCustomActions } from "layouts/Modal/utils";

import { tokenExpired } from "utils/user";

import { DownloadFileAction } from "./types";

export const downloadFileMiddleware =
    // @ts-ignore
    ({ dispatch, getState }) => {
        return (next: any) => (action: DownloadFileAction) => {
            switch (action.type) {
                case DOWNLOAD_FILE_START:
                    {
                        const user = getState().vdsmUser;
                        let accessToken = null;

                        if (user) {
                            if (!tokenExpired(user)) {
                                accessToken = user.accessToken;
                            }
                        }

                        if (!accessToken) {
                            refresh({ action })(dispatch, getState);

                            break;
                        }

                        const requestParams = {
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        };

                        const { id, fileType } = action;

                        const query = new URLSearchParams();
                        let url = "";

                        if (fileType === "model-results") {
                            query.append("idResultsLog", id.toString());
                            action.idInputLog && query.append("idInputLog", action.idInputLog.toString());

                            url = `${getResourceUrl(singleResultResourceName)}?${query.toString()}`;
                        } else if (fileType === "multiple-model-results") {
                            let idResultsLogsQueryParams = "";
                            let includedTablesQueryParams = "";

                            action.idResultsLogs.forEach((idResultsLog, index) => {
                                idResultsLogsQueryParams += `idResultsLogs=${idResultsLog}`;

                                if (index < action.idResultsLogs.length - 1) {
                                    idResultsLogsQueryParams += "&";
                                }
                            });

                            action.includedTables.forEach((table, index) => {
                                includedTablesQueryParams += `includedTables=${table}`;

                                if (index < action.includedTables.length - 1) {
                                    includedTablesQueryParams += "&";
                                }
                            });

                            url = `${getResourceUrl(multipleResultsResourceName)}?${idResultsLogsQueryParams}&${includedTablesQueryParams}`;
                        } else if (fileType === "project-file") {
                            action.path && query.append("path", action.path);
                            action.fileName && query.append("fileName", action.fileName);
                            action.projectFileType && query.append("fileType", action.projectFileType);

                            url = `${getResourceUrl(projectFileResourceName, { idProject: action.idProject })}?${query.toString()}`;
                        }

                        fetch(url, requestParams)
                            .then((response) => {
                                if (response.ok) {
                                    const contentLength = Number(response.headers.get("Content-Length"));

                                    dispatch({
                                        type: DOWNLOAD_FILE_STARTED,
                                        id,
                                        contentLength,
                                    });

                                    return response;
                                } else {
                                    throw response;
                                }
                            })
                            .then((response) => {
                                if (isResponseJson(response)) {
                                    return [null, response];
                                }

                                const reader = response.body!.getReader();

                                let r = 0;

                                return [
                                    new ReadableStream({
                                        start(controller) {
                                            return pump();

                                            // @ts-ignore
                                            function pump() {
                                                return reader.read().then(({ done, value }) => {
                                                    // When no more data needs to be consumed, close the stream
                                                    if (done) {
                                                        controller.close();

                                                        return;
                                                    }

                                                    r += value.length;

                                                    dispatch({
                                                        type: DOWNLOAD_FILE_PROGRESS,
                                                        id,
                                                        receivedLength: r,
                                                    });

                                                    // Enqueue the next data chunk into our target stream
                                                    controller.enqueue(value);

                                                    return pump();
                                                });
                                            }
                                        },
                                    }),
                                    null,
                                ];
                            })
                            .then(([stream, response]) => [stream ? new Response(stream as ReadableStream<any>) : null, response])
                            .then(async ([streamResponse, jsonResponse]) => [
                                streamResponse ? await (streamResponse as Response).blob() : null,
                                jsonResponse ? await (jsonResponse as Response).json() : null,
                            ])
                            .then(([blob, json]) => {
                                const url = blob ? URL.createObjectURL(new Blob([blob])) : json.downloadLink;

                                if (url) {
                                    const link = document.createElement("a");

                                    link.href = url;
                                    link.setAttribute("download", action.fileName ?? json.fileName);
                                    document.body.appendChild(link);
                                    link.click();
                                    link.parentNode?.removeChild(link);

                                    action.onComplete?.();
                                } else {
                                    dispatch(
                                        openModalDialogCustomActions({
                                            title: "Export",
                                            text: json.responseMessage,
                                            actions: [
                                                {
                                                    children: "Ok",
                                                },
                                            ],
                                        })
                                    );
                                }

                                dispatch({
                                    type: DOWNLOAD_FILE_COMPLETE,
                                    id,
                                });
                            })
                            .catch(async (err) => {
                                let message = "";

                                if (isResponseJson(err)) {
                                    try {
                                        const json = await err.json();

                                        message = json.responseMessage || json.title;
                                    } catch (error: any) {
                                        message = error.message;
                                    }
                                } else {
                                    const text = await err.text();

                                    message = text ? text : `${err.status} (${err.statusText})`;
                                }

                                dispatch({
                                    type: DOWNLOAD_FILE_ERROR,
                                    id,
                                    error: message,
                                });
                            });
                    }

                    break;

                default:
                    break;
            }

            return next(action);
        };
    };
