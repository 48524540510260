import { useCallback } from "react";
import { useDispatch } from "react-redux";
import ClassNames from "classnames";

import { sideNavClose } from "store/sideNav/actions";

import { useOnKey } from "utils/useOnKey";

import "../style.scss";

const SideNavBackdrop = (props: SideNavBackdropProps) => {
    const { id, show, disableClose } = props;

    const dispatch = useDispatch();

    const onBackDropClick = useCallback(
        (event) => {
            event.preventDefault();

            if (!disableClose) {
                dispatch(sideNavClose({ id }));
            }
        },
        [id, disableClose, dispatch]
    );

    useOnKey("Escape", onBackDropClick);

    const classNames = ClassNames("sidenav-backdrop", {
        "sidenav-backdrop--no-pointer-events": !show,
    });

    return <div className={classNames} onClick={onBackDropClick} />;
};

interface SideNavBackdropProps {
    id?: string;
    show?: boolean;
    disableClose?: boolean;
}

export default SideNavBackdrop;
