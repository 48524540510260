import { batch } from "react-redux";

import { RUN_CALCULATION_STARTED } from "store/actionTypes";

import { checkProcessStatus } from "store/processes/useProcessStatus";
import { runCalculationStatusProps } from "store/processes/useRunCalculationStatus";

import { createCalculation, clearCalculations } from "store/resources/actions/calculations/calculationsActions";

export const startRunCalculationStatusCheck =
    () =>
    // @ts-ignore
    (dispatch) => {
        // If status check is already scheduled - do nothing
        if (runCalculationStatusProps.timeoutHandlers) {
            return;
        }

        dispatch(checkProcessStatus(runCalculationStatusProps));
    };

export const runCalculation =
    ({ idProject, idInputLog, idResults, includePotential, onStart, onError }: RunCalculationParams) =>
    // @ts-ignore
    (dispatch) => {
        const onRunCalculationSuccess = (action: any) => {
            const { id } = action?.data;

            batch(() => {
                dispatch({
                    type: RUN_CALCULATION_STARTED,
                    idProject,
                    idInputLog,
                    id,
                });

                dispatch(startRunCalculationStatusCheck());

                dispatch(clearCalculations());
            });

            onStart?.();
        };

        const onRunCalculationError = () => {
            onError?.();
        };

        dispatch(
            createCalculation({
                idProject,
                idInputLog,
                resultsId: idResults,
                includePotential,
                onSuccess: onRunCalculationSuccess,
                onError: onRunCalculationError,
            })
        );
    };

export const runMultipleCalculations =
    ({ idProject, idResults, models, onStart, onError }: RunMultipleCalculationsParams) =>
    // @ts-ignore
    (dispatch) => {
        const onRunCalculationSuccess = (action: any, idInputLog: number) => {
            const { id } = action?.data;

            batch(() => {
                dispatch({
                    type: RUN_CALCULATION_STARTED,
                    idProject,
                    idInputLog,
                    id,
                });

                dispatch(startRunCalculationStatusCheck());

                dispatch(clearCalculations());
            });

            onStart?.();
        };

        const onRunCalculationError = () => {
            onError?.();
        };

        models.forEach((model) => {
            dispatch(
                createCalculation({
                    idProject,
                    idInputLog: model.idInputLog,
                    resultsId: idResults,
                    includePotential: model.includePotential,
                    onSuccess: (action) => onRunCalculationSuccess(action, model.idInputLog),
                    onError: onRunCalculationError,
                })
            );
        });
    };

interface CalculationParams {
    /**
     * ID of Project.
     */
    idProject: number;

    /**
     * ID of Calculation results.
     */
    idResults: number;

    /**
     * Function to run when calculation has started.
     */
    onStart?: () => void;

    /**
     * Function to run when calculation cannot start.
     */
    onError?: () => void;
}

interface RunCalculationParams extends CalculationParams {
    /**
     * ID of Model.
     */
    idInputLog: number;

    includePotential: boolean;
}

interface RunMultipleCalculationsParams extends CalculationParams {
    /**
     * Array of Model IDs and results type.
     */
    models: {
        idInputLog: number;
        includePotential: boolean;
    }[];
}
