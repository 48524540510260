import ClassNames from "classnames";

import ComponentWithDropdown from "components/ui/Dropdown/ComponentWithDropdown";

import TagWithCustomArrow from "./TagWithCustomArrow";

import Label from "components/ui/Label";

import { TagWithDropdownProps } from "./types";

import "./style.scss";

const TagWithDropdown = (props: TagWithDropdownProps) => {
    const selectedItem = props.items.find((item) => item.value === props.value)?.label;

    const classNames = ClassNames(props.className, {
        "fill-width": !props.className,
    });

    return (
        <div className="flex-row align-center tag-with-dropdown">
            {props.label && <Label>{props.label}</Label>}
            <ComponentWithDropdown
                className={classNames}
                component={<TagWithCustomArrow tagClassName={props.tagClassName} label={selectedItem} />}
                value={props.value}
                items={props.items}
                withoutShevron
                onChange={props.onChange}
            />
        </div>
    );
};

export default TagWithDropdown;
