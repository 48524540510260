import { memo, useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import ClassNames from "classnames";

import SideNavBackdrop from "./SideNavBackdrop";
import { SideNavContext } from "./SideNavContext";

import { sideNavigationSize } from "utils/sideNav";

import "./style.scss";

// TODO: Rework this whole concept
//
// Add onClose prop
const SideNav = memo((props: SideNavProps) => {
    const { className, id, position, mode, size = sideNavigationSize.small, backdrop, disableClose, children } = props;

    const onCloseCallback = useRef<() => void | null>(null);

    const classNames = ClassNames("sidenav", `sidenav--${position}`, `sidenav--${mode}`, `sidenav--${size}`, className);

    const registerOnClose = useCallback((onCloseCb) => {
        (onCloseCallback as React.MutableRefObject<() => void | null>).current = onCloseCb;
    }, []);

    const [contextState] = useState({
        registerOnClose,
    });

    useEffect(() => {
        const callback = onCloseCallback.current;

        return () => {
            if (callback) {
                callback();
            }
        };
    }, []);

    return (
        <SideNavContext.Provider value={contextState}>
            <SideNavBackdrop id={id} show={backdrop} disableClose={disableClose} />
            <div className={classNames} id={`${id}-sidenav`}>
                {children}
            </div>
        </SideNavContext.Provider>
    );
});

interface SideNavProps {
    className?: string;
    id?: string;
    position: string;
    mode?: string;
    size?: string;
    backdrop?: boolean;
    disableClose?: boolean;
    children?: React.ReactNode;
}

export default SideNav;
