import { get, isEmpty } from "lodash";
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { avoidedCostsTemplateValidationKey, avoidedCostsTemplateResourceName } from "store/configureResources";

import {
    useAvoidedCostsTemplate,
    useAvoidedCostsTemplateValidation,
} from "store/resources/actions/avoidedCosts/avoidedCostsTemplateActions";
import { useActiveViewsAvoidedCostsList } from "store/resources/actions/activeViews/activeViewAvoidedCostsActions";
import { useMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";

import IconLoading from "components/ui/Icons/IconLoading";
import TabList from "components/ui/List/TabList";
import Avatar from "components/ui/Avatar";
import InfoPanel from "components/ui/InfoPanel";
import StatusMsg from "components/ui/StatusMsg";
import UserName from "components/ui/UserName";

import AvoidedCosts from "./AvoidedCosts";

import { transformAvoidedCostTemplate, getTabs } from "./utils";

import { toLocaleDateTime } from "utils/dateTime";
import hj from "utils/hotJar";

import { ModifyAvoidedCostsProps } from "./types";

import "./style.scss";

const ModifyAvoidedCosts = memo(({ idProject, idInputLog }: ModifyAvoidedCostsProps) => {
    // Selectors

    // @ts-ignore
    const userNumber = useSelector((state) => state.vdsmUser.userNumber);

    const isUpdating = useSelector((state) =>
        get(state, `resources.${avoidedCostsTemplateResourceName}.itemsById[${avoidedCostsTemplateValidationKey}-${idInputLog}].isUpdating`)
    );

    const isCreating = useSelector((state) =>
        get(state, `resources.${avoidedCostsTemplateResourceName}.itemsById[${avoidedCostsTemplateResourceName}-${idInputLog}].isCreating`)
    );

    const isReading = useSelector((state) =>
        get(state, `resources.${avoidedCostsTemplateResourceName}.itemsById[${avoidedCostsTemplateResourceName}-${idInputLog}].isReading`)
    );

    // Resources

    const [modelInputs, isLoadingModelInputs] = useMeasureInputs({ idInputLog });

    const [avoidedCostsTemplateItems, isLoadingAvoidedCosts] = useAvoidedCostsTemplate({ idInputLog });

    const [avoidedCostsValidation, isLoadingAvoidedCostsValidation] = useAvoidedCostsTemplateValidation({
        idInputLog,
        deferUpdate: isLoadingAvoidedCosts,
    });

    const [activeViews, isLoadingActiveViews] = useActiveViewsAvoidedCostsList({ idProject, idInputLog });

    // States

    const [tabs, setTabs] = useState([]);

    const [activeTab, setActiveTab] = useState(tabs?.[0]);

    const [showValidationPanel, setShowValidationPanel] = useState(true);

    const [save, setSave] = useState(false);

    // useMemos

    const avoidedCostsTemplateItemsGroupedBySector = useMemo(() => {
        if (isLoadingAvoidedCosts || isLoadingAvoidedCostsValidation || isReading) {
            return;
        }

        const years = avoidedCostsValidation?.missingYears
            ? Object.fromEntries(avoidedCostsValidation.missingYears.map((i) => [i, ""]))
            : undefined;

        return transformAvoidedCostTemplate(avoidedCostsTemplateItems, years);
    }, [avoidedCostsTemplateItems, avoidedCostsValidation, isLoadingAvoidedCosts, isLoadingAvoidedCostsValidation, isReading]);

    const [lastUpdateUserNumber, lastUpdateDate] = useMemo(() => {
        let userNumber;
        let importStarted;

        if (!isEmpty(modelInputs) && !isLoadingModelInputs) {
            const avoidedCostInput = modelInputs.equipmentinputstables.find((input) => input.tableName === "AVOIDED_COSTS");

            userNumber = avoidedCostInput?.userNumber;
            importStarted = avoidedCostInput?.importStarted;
        }

        return [userNumber, importStarted];
    }, [modelInputs, isLoadingModelInputs]);

    // Refs

    // Constant object in order to avoid performance issues when table is large
    const avoidedCostsTemplateItemsGroupedBySectorRef = useRef(avoidedCostsTemplateItemsGroupedBySector);

    useEffect(() => {
        hj("stateChange", "AvoidedCosts");
    }, []);

    useEffect(() => {
        //set init value if it ref is empty or Save is not pressed
        if (isEmpty(avoidedCostsTemplateItemsGroupedBySectorRef?.current) || save !== true) {
            avoidedCostsTemplateItemsGroupedBySectorRef.current = avoidedCostsTemplateItemsGroupedBySector;
        }

        const tabs = getTabs(avoidedCostsTemplateItemsGroupedBySector);

        setTabs(tabs);
        setActiveTab(tabs?.[0]);
    }, [avoidedCostsTemplateItemsGroupedBySector, save]);

    const onUpdate = useCallback(() => {
        setTabs(getTabs(avoidedCostsTemplateItemsGroupedBySectorRef.current));
    }, []);

    const onShowValidationPanel = useCallback(() => {
        setShowValidationPanel(true);
    }, []);

    const onSaveAvoidedCosts = useCallback(() => {
        setSave(true);
    }, []);

    const onClickHideValidationPanel = useCallback(() => {
        setShowValidationPanel(false);
    }, []);

    return (
        <div className="flex-column flex-one-in-column no-scroll modify-avoided-costs">
            {/*Error Message */}
            {!isLoadingAvoidedCostsValidation &&
                !isUpdating &&
                (avoidedCostsValidation?.dataIsValid === false || (avoidedCostsValidation?.dataIsSaved === false && save)) &&
                showValidationPanel && (
                    <div className="avoided-costs-message-box">
                        <InfoPanel error onClose={onClickHideValidationPanel}>
                            <div className="flex-column flex-one-in-row avoided-costs-message">
                                <div className="flew-row flex-one-in-row">
                                    <StatusMsg
                                        error
                                        icon="octagon-exclamation-empty"
                                        iconWithLabelMedium
                                        message={
                                            avoidedCostsValidation?.dataIsValid === false
                                                ? "There are some errors in the “Avoided Costs” inputs:"
                                                : avoidedCostsValidation?.responseStatus !== "success"
                                                ? "There are some errors in the  saving “Avoided Costs” :"
                                                : ""
                                        }
                                    />
                                </div>
                                <div className="flex-row flex-one-in-row message error">
                                    {avoidedCostsValidation?.dataIsValid === false
                                        ? avoidedCostsValidation?.validationMessage
                                        : avoidedCostsValidation?.responseStatus !== "success"
                                        ? avoidedCostsValidation?.responseMessage
                                        : "Technical error"}
                                </div>
                            </div>
                        </InfoPanel>
                    </div>
                )}
            {/*Success Message */}
            {!isLoadingAvoidedCostsValidation &&
                !isUpdating &&
                avoidedCostsValidation?.dataIsSaved === true &&
                save &&
                showValidationPanel && (
                    <div className="avoided-costs-message-box">
                        <InfoPanel success onClose={onClickHideValidationPanel}>
                            <StatusMsg
                                success
                                icon="check-circle-empty"
                                iconWithLabelMedium
                                message="The data was successfully validated and saved."
                            />
                        </InfoPanel>
                    </div>
                )}
            {/* Body */}
            {isEmpty(avoidedCostsTemplateItemsGroupedBySectorRef?.current) &&
            isEmpty(avoidedCostsTemplateItemsGroupedBySector) &&
            (isLoadingAvoidedCosts === false || isLoadingAvoidedCosts === undefined) &&
            (isCreating === undefined || isCreating === false) &&
            (isReading === undefined || isReading === false) ? (
                <div className="avoided-costs-message-box">
                    <InfoPanel error>
                        <StatusMsg iconWithLabelMedium error icon="octagon-exclamation-empty" message="Data not found" />
                    </InfoPanel>
                </div>
            ) : isLoadingAvoidedCosts ||
              isLoadingAvoidedCostsValidation ||
              !activeTab?.id ||
              isUpdating ||
              isEmpty(avoidedCostsTemplateItemsGroupedBySectorRef?.current) ? (
                <IconLoading />
            ) : (
                <>
                    {/* Header */}
                    <div className="flex align-end avoided-costs-header">
                        <div className="flex-one-in-row avoided-costs-sector-tabs">
                            {/* @ts-ignore */}
                            <TabList
                                items={tabs}
                                activeItem={activeTab}
                                onClick={(tab) => setActiveTab(tab)}
                                tabListGhost
                                tabListGhostNoBorder
                            />
                        </div>
                        <div className="flex align-center avoided-costs-details">
                            {!isLoadingActiveViews &&
                                activeViews &&
                                activeViews.length > 0 &&
                                activeViews.map((av, i) => (
                                    <Fragment key={JSON.stringify(av)}>
                                        {userNumber !== av.userNumber && (
                                            <div className="margin-left-small">
                                                <Avatar userNumber={av.userNumber} />
                                            </div>
                                        )}
                                    </Fragment>
                                ))}
                            {!isLoadingModelInputs && lastUpdateUserNumber && (
                                <div className="flex-row align-center latest-update">
                                    {`Last updated by `}
                                    <UserName
                                        // @ts-ignore
                                        userNumber={lastUpdateUserNumber}
                                    />
                                    {` on ${toLocaleDateTime(lastUpdateDate)}`}
                                </div>
                            )}
                        </div>
                    </div>
                    <AvoidedCosts
                        idProject={idProject}
                        idInputLog={idInputLog}
                        activeTab={activeTab.id}
                        avoidedCostsTemplateItemsGroupedBySectorRef={avoidedCostsTemplateItemsGroupedBySectorRef}
                        onUpdate={onUpdate}
                        onShowValidationPanel={onShowValidationPanel}
                        onSaveAvoidedCosts={onSaveAvoidedCosts}
                    />
                </>
            )}
        </div>
    );
});

export default ModifyAvoidedCosts;
