///
/// Model (Project Input) Validations actions
/// resourceName: projectInputValidationsResourceName
///
import { projectInputValidationsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

export const useInputValidations = ({ idInputLog }) =>
    useResource({
        resourceName: projectInputValidationsResourceName,
        key: `${projectInputValidationsResourceName}-${idInputLog}`,
        query: {
            idInputLog,
        },
        transform: (data) => data?.model || [],
    });

export const clearInputValidations =
    ({ idInputLog }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: projectInputValidationsResourceName,
                key: `${projectInputValidationsResourceName}-${idInputLog}`,
                broadcast: true,
            })
        );
