import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import RadioCard from "components/ui/Input/RadioCard";
import FieldGroup from "components/ui/FieldGroup";

import { openModalDialogAddModule } from "layouts/Modal/CalculationWorkflowModal/utils";

import { moduleTypes } from "utils/constants";

import { AddPotentialModuleModalProps } from "layouts/Modal/CalculationWorkflowModal/types";
import { ModuleType } from "store/resources/actions/calculationWorkflow/types";

const POTENTIAL_MODULES = [
    {
        name: "potentialModule",
        label: "EE Potential",
        value: moduleTypes.EE_POTENTIAL,
    },
    {
        name: "potentialModule",
        label: "DR Potential",
        value: moduleTypes.DR_POTENTIAL,
    },
];

const AddPotentialModule = memo(({ idProject, idModel, idInputLog, modules, onCancel }: AddPotentialModuleModalProps) => {
    const dispatch = useDispatch();

    const [selectedModule, setSelectedModule] = useState<ModuleType>(moduleTypes.EE_POTENTIAL);

    const onChange = useCallback((event) => {
        setSelectedModule(event.target.value);
    }, []);

    const onNextClick = useCallback(() => {
        dispatch(
            openModalDialogAddModule({
                idProject,
                idModel,
                idInputLog,
                title: selectedModule === moduleTypes.EE_POTENTIAL ? "Add EE Potential Module" : "Add DR Potential Module",
                type: selectedModule,
                modules,
                goBack: true,
            })
        );
    }, [idProject, idModel, idInputLog, modules, selectedModule, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding">
                <FieldGroup>
                    {POTENTIAL_MODULES.map((module) => (
                        <RadioCard
                            id={module.value}
                            name={module.name}
                            label={module.label}
                            value={module.value}
                            checked={selectedModule === module.value}
                            onChange={onChange}
                        />
                    ))}
                </FieldGroup>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button ariaLabel="Next button" variant="primary" padding="md" onClick={onNextClick}>
                    Next
                </Button>
                <Button ariaLabel="Cancel button" variant="secondary" padding="md" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default AddPotentialModule;
