import { usersResourceName, teamMembersResourceName, userRoleResourceName } from "store/configureResources";
import { userPasswordResourceName } from "store/configureVDSMResources";

import { useResource } from "store/resources/actions/useResource";
import { updateResource } from "store/resources/actions/updateResource";

import { UseUserProfileParams, ChangePasswordParams, TeamMember, UpdateUserRoleParams } from "./types";

export const useUserProfile = ({ userNumber }: UseUserProfileParams) => {
    const userN = userNumber || 1;

    const [user, isLoading] = useResource({
        resourceName: usersResourceName,
        key: `${usersResourceName}-${userNumber}`,
        path: {
            userNumber: userN,
        },
        showSuccessNotification: false,
        showErrorNotification: false,
        transform: (data) => data?.model[0], // array will always consist of one item
    });

    return [user, isLoading];
};

export const changePassword =
    ({ oldPassword, newPassword, onError, onSuccess }: ChangePasswordParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            updateResource({
                resourceName: userPasswordResourceName,
                body: {
                    oldPassword,
                    newPassword,
                },
                showErrorNotification: false,
                onError,
                onSuccess,
            })
        );

export const useTeamMembers = () =>
    useResource({
        resourceName: teamMembersResourceName,
        key: `${teamMembersResourceName}`,
        transform: (data) => data?.model,
    }) as [TeamMember[], boolean | undefined, any];

export const updateUserRole =
    ({ userName, userRole, onComplete }: UpdateUserRoleParams) =>
    (dispatch) => {
        dispatch(
            updateResource({
                resourceName: userRoleResourceName,
                key: `${userRoleResourceName}`,
                path: {
                    userName,
                },
                body: {
                    actionPermission: userRole,
                },
                onComplete,
            })
        );
    };
