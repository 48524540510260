import { IndexSignature } from "types/types";
import { VariablesTransformed } from "store/resources/actions/variables/types";
import { ModuleVariableTransformed } from "store/resources/actions/calculationWorkflow/types";

export const variableValuesChanged = <T extends VariablesTransformed | ModuleVariableTransformed>(
    values: IndexSignature<string>,
    variables: T[][]
) => {
    // Value keys are variable names
    for (const key in values) {
        for (const variablesRow of variables) {
            const savedValue = variablesRow.find((_variable) => _variable.name === key)?.value; // Variables without values are null

            if (savedValue !== undefined && savedValue !== values[key]) {
                return true;
            }
        }
    }

    return false;
};
