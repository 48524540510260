///
/// Model Inputs actions
/// resourceName: measureInputsResourceName, modelInputsV2ResourceName
///
/// Formerly known as Measure Inputs
///
import { get, forOwn, isArray } from "lodash";

import { allMeasureInputsResourceKey, modelInputsResourceName, modelInputsV2ResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";

import { ModelInputsParams, UseModelInputsParams, UpdateModelInputsParams, GetModelInputsParams } from "./types";

export const MANDATORY_INPUT = "MANDATORY";
export const POTENTIAL_INPUT = "POTENTIAL";
export const EXTRA_INPUT = "EXTRA";
export const PAST_RESULTS_INPUT = "PAST_RESULTS";
export const MARKET_PROFILE_INPUT = "MARKET_PROFILE";

const getMeasureInputList = ({ idInputLog, getState }: GetModelInputsParams) =>
    get(getState(), `resources.${modelInputsV2ResourceName}.itemsById[${modelInputsV2ResourceName}-${idInputLog}].data`);

export const useAllEquipmentInputs = () =>
    useResource({
        resourceName: modelInputsV2ResourceName,
        key: allMeasureInputsResourceKey,
    });

export const useMeasureInputs = ({ idInputLog, transform }: UseModelInputsParams) =>
    useResource({
        resourceName: modelInputsV2ResourceName,
        key: `${modelInputsV2ResourceName}-${idInputLog}`,
        query: {
            idInputLog,
        },
        transform,
    });

export const clearMeasureInputs =
    ({ idInputLog }: ModelInputsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: modelInputsV2ResourceName,
                key: `${modelInputsV2ResourceName}-${idInputLog}`,
                broadcast: true,
            })
        );

export const updateMeasureInputs =
    ({ idInputLog, idDataImportLog, datasetStatus, onComplete }: UpdateModelInputsParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const inputList = getMeasureInputList({ idInputLog, getState });

        const updateItems = forOwn(inputList, (value, key) => {
            if (isArray(value)) {
                inputList[key] = value?.map((u) =>
                    u.idDataImportLog === idDataImportLog
                        ? {
                              ...u,
                              idDataImportLog: idDataImportLog ?? u.idDataImportLog,
                              datasetStatus: datasetStatus ?? u.datasetStatus,
                          }
                        : u
                );
            } else {
                inputList[key] = value;
            }
        });

        dispatch(
            updateResource({
                key: `${modelInputsResourceName}-${idInputLog}`,
                resourceName: modelInputsResourceName,
                path: idDataImportLog ? { idDataImportLog } : undefined,
                body: {
                    datasetStatus,
                    userNumber: getState().vdsmUser?.userNumber,
                },
                optimisticUpdate:
                    updateItems !== undefined
                        ? {
                              value: updateItems,
                          }
                        : undefined,
                onComplete: () => {
                    onComplete?.();

                    dispatch(clearMeasureInputs({ idInputLog }));
                },
            })
        );
    };
