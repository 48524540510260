///
/// Standalone Report actions
/// resourceName: standaloneReportsResourceName
///
import { get } from "lodash";
import { batch } from "react-redux";

import { standaloneReportsResourceName } from "store/configureResources";
import { getKey } from "store/utils";

import { useResource } from "store/resources/actions/useResource";
import { getResource, waitReadResource } from "store/resources/actions/getResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { clearProjectLogItems, clearStandaloneReportLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import {
    StandaloneReport,
    UseStandaloneReportsParams,
    GetStandaloneReportsParams,
    WaitReadStandaloneReportsParams,
    UpdateStandaloneReportParams,
    DeleteStandaloneReportParams,
    DeleteAllInactiveStandaloneReportsParams,
    GetStandaloneReportsListParams,
} from "./types";

const getStandaloneReportsList = ({ idProject, getState }: GetStandaloneReportsListParams): StandaloneReport[] =>
    get(getState(), `resources.${standaloneReportsResourceName}.itemsById[${standaloneReportsResourceName}-${idProject}].data`) || [];

export const useStandaloneReports = ({ idProject }: UseStandaloneReportsParams) =>
    useResource({
        resourceName: standaloneReportsResourceName,
        key: `${standaloneReportsResourceName}-${idProject}`,
        query: { idProject },
    });

export const getStandaloneReports = ({ idProject, onComplete }: GetStandaloneReportsParams) =>
    getResource({
        resourceName: standaloneReportsResourceName,
        key: `${standaloneReportsResourceName}-${idProject}`,
        query: { idProject },
        onComplete,
    });

export const clearStandaloneReports = ({ idProject }: UseStandaloneReportsParams) =>
    clearResource({
        resourceName: standaloneReportsResourceName,
        key: `${standaloneReportsResourceName}-${idProject}`,
        broadcast: true,
    });

const waitReadStandaloneReports =
    ({ idProject, action }: WaitReadStandaloneReportsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            waitReadResource({
                resourceName: standaloneReportsResourceName,
                key: `${standaloneReportsResourceName}-${idProject}`,
                actionName: action,
            })
        );

export const updateStandaloneReport =
    ({ idProject, idReport, formData, action, onStart, onSuccess, onError }: UpdateStandaloneReportParams) =>
    // @ts-ignore
    (dispatch) => {
        if (action) {
            dispatch(waitReadStandaloneReports({ idProject, action }));
        }

        dispatch(
            updateResource({
                resourceName: standaloneReportsResourceName,
                key: `${standaloneReportsResourceName}-${idProject}${getKey(idReport)}`,
                path: {
                    idReport,
                },
                body: formData,
                updateFromResponse: false,
                showSuccessNotification: false,
                onStart,
                onSuccess: (action) => {
                    batch(() => {
                        dispatch(clearStandaloneReports({ idProject }));
                        dispatch(clearProjectLogItems({ idProject }));
                        dispatch(clearStandaloneReportLogItems({ idProject, idReport }));
                    });

                    onSuccess?.(action);
                },
                onError,
            })
        );
    };

export const deleteStandaloneReport =
    ({ idProject, idReport, reportType, filename, action, onSuccess }: DeleteStandaloneReportParams) =>
    // @ts-ignore
    (dispatch) => {
        batch(() => {
            dispatch(waitReadStandaloneReports({ idProject, action }));

            dispatch(
                deleteResource({
                    resourceName: standaloneReportsResourceName,
                    key: `${standaloneReportsResourceName}-${idProject}-${idReport}`,
                    path: {
                        idReport,
                    },
                    body: {
                        reportType,
                        filename,
                    },
                    onSuccess: () => {
                        dispatch(clearStandaloneReports({ idProject }));
                        dispatch(clearProjectLogItems({ idProject }));

                        onSuccess?.();
                    },
                })
            );
        });
    };

export const deleteAllInactiveStandaloneReports =
    ({ idProject, action }: DeleteAllInactiveStandaloneReportsParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const inactiveReports = getStandaloneReportsList({ idProject, getState }).filter((report) => report.active === false);

        batch(() => {
            inactiveReports.forEach((report) => {
                dispatch(
                    deleteStandaloneReport({
                        idProject,
                        idReport: report.idReport,
                        reportType: report.reportType,
                        filename: report.filename,
                        action,
                    })
                );
            });
        });
    };
