import { isEmpty, kebabCase } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { componentModelDataImportV3ResourceName } from "store/configureResources";

import { useActiveViewsModelList } from "store/resources/actions/activeViews/activeViewModelActions";
import { updateComponentModel, deleteComponentModel } from "store/resources/actions/componentModel/componentModelActions";
import { useProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useProjectFuels } from "store/resources/actions/fuel/fuelActions";
import { useModules } from "store/resources/actions/calculationWorkflow/modulesActions";

import { useProcessesInProgress, useIsInProgress } from "store/processes/useProcessStatus";

import { selectProjection } from "store/projections/actions";

import Button from "components/ui/ButtonNew";
import TagWithDropdown from "components/ui/Dropdown/TagWithDropdown";
import Label from "components/ui/Label";
import LabelWithIcon from "components/ui/LabelWithIcon";
import Avatar from "components/ui/Avatar";
import { DeterminateProgressBar, IndeterminateProgressBar } from "components/ui/ProgressBar";
import Separator from "components/ui/Separator";
import Switcher from "components/ui/Switcher";
import Tag from "components/ui/Tag";

import {
    openModalDialogDeleteProjection,
    openModalDialogRestoreProjection,
    openModalDialogDeletePermanentlyProjection,
} from "layouts/Modal/utils";
import {
    openModalDialogEditModel,
    // openModalDialogSetFinalSignedOffError,
    openModalDialogSetFinalNoModulesError,
    openModalDialogSetFinalApprovedError,
    openModalDialogSetFinalWarning,
    openModalDialogRollbackFinalWarning,
    openModalDialogDuplicateModel,
    openModalDialogDownloadData,
} from "layouts/Modal/ModelDashboardModal/utils";

import ImportErrorPanel from "pages/ManageProject/ProjectionsContent/common/ImportErrorPanel";
import ImportDataPanel from "pages/ManageProject/ProjectionsContent/common/ImportDataPanel";

import { projectReadOnlyEnabled, modelReadOnlyEnabled, actionAllowed } from "pages/utils";

import { useModelCalculationPreconditions } from "utils/useCalculationPreconditions";
import { useHaveRights } from "utils/useHaveRights";
import { useProjectFromStore } from "utils/useProjectFromStore";
import { useUserRights } from "utils/useUserRights";
import { useViews } from "utils/useViews";
import { projections, allComponentModelStates, componentModelStates, allModuleStates } from "utils/constants";
import { hasAnyOfPermissions, hasInternalUserRole } from "utils/user";
import { USER_ACTIONS, USER_ROLES } from "utils/user/defines";
import { windowContainerTypes, openWindowCalculationWorkflow } from "utils/window";

import { Territory, Fuel } from "types/types";
import { DashboardHeaderProps } from "./types";

import "./style.scss";

const DashboardHeader = memo(({ idClient, viewIndex, model, clientName, projectName }: DashboardHeaderProps) => {
    const {
        idProject,
        idInputLog,
        idModel,
        idTemplate,
        idTerritory,
        idFuel,
        idAnalyst,
        idReviewer,
        analyst,
        reviewer,
        name,
        description,
        required,
        active,
        modelState,
    } = model;
    const dispatch = useDispatch();

    // @ts-ignore
    const userName = useSelector((state) => state.user.userName);

    const views = useViews({ containerName: windowContainerTypes.Root });

    // Resources

    const processesInProgress = useProcessesInProgress({ idInputLog });

    const isInProgress = useIsInProgress({ idInputLog });

    const [activeViews, isLoadingActiveViews] = useActiveViewsModelList({ idProject, idInputLog });

    const [projectTerritories, isLoadingProjectTerritories] = useProjectTerritories({ idProject });
    const [projectFuels, isLoadingProjectFuels] = useProjectFuels({ idProject });

    const { modelCalculationPreconditions, modelCalculationPreconditionsErrors } = useModelCalculationPreconditions({
        idProject,
        idInputLog,
    });

    const [modules, isLoadingModules] = useModules({ idModel });

    // const [inputsProgress] = useModelInputsProgress({ idProject, idInputLog });

    const isUploading = Boolean(model.uploadProps?.isUploading);

    const userRights = useUserRights();

    const haveAnalystRights = useHaveRights({ entityId: idAnalyst, userName });
    const haveReviewerRights = useHaveRights({ entityId: idReviewer, userName });

    const importStartTime =
        processesInProgress?.modelDataImport?.started === undefined ? 0 : Date.parse(processesInProgress.modelDataImport.started);

    // Selectors

    const project = useProjectFromStore({ idClient, idProject });

    // Memos

    const modelStateItems = useMemo(() => {
        let modelStateItems: any[] = [];

        if (haveAnalystRights && componentModelStates[USER_ROLES.ANALYST].hasOwnProperty(modelState.toUpperCase())) {
            modelStateItems = componentModelStates[USER_ROLES.ANALYST][modelState.toUpperCase()].map((status) => ({
                label: status,
                value: status.toUpperCase(),
            }));
        }
        if (haveReviewerRights && componentModelStates[USER_ROLES.MANAGER].hasOwnProperty(modelState.toUpperCase())) {
            modelStateItems = componentModelStates[USER_ROLES.MANAGER][modelState.toUpperCase()].map((status) => ({
                label: status,
                value: status.toUpperCase(),
            }));
        }

        return modelStateItems;
    }, [modelState, haveAnalystRights, haveReviewerRights]);

    const territory = useMemo(() => {
        let territory: Territory;

        if (!isEmpty(projectTerritories) && !isLoadingProjectTerritories) {
            territory = projectTerritories.find((territory: Territory) => territory.idTerritory === idTerritory);
        }

        return territory;
    }, [idTerritory, projectTerritories, isLoadingProjectTerritories]);

    const fuel = useMemo(() => {
        let fuel: Fuel;

        if (!isEmpty(projectFuels) && !isLoadingProjectFuels) {
            fuel = projectFuels.find((fuel: Fuel) => fuel.idFuel === idFuel);
        }

        return fuel;
    }, [idFuel, projectFuels, isLoadingProjectFuels]);

    const [noModules, modulesNotApproved] = useMemo(() => {
        let noModules = false;
        let modulesNotApproved = false;

        if (!isLoadingModules) {
            if (isEmpty(modules)) {
                noModules = true;
            } else {
                modulesNotApproved = !modules.every((m) => m.moduleState === allModuleStates.CLIENT_APPROVED.toUpperCase());
            }
        }

        return [noModules, modulesNotApproved];
    }, [modules, isLoadingModules]);

    // Helper functions

    const changeModelStatus = useCallback(
        (modelStatus: string) => {
            dispatch(
                updateComponentModel({
                    idProject,
                    idModel,
                    modelState: modelStatus,
                    action: "Updating Model",
                })
            );
        },
        [idProject, idModel, dispatch]
    );

    const onEditModel = useCallback(
        ({ modelName, modelDescription, modelAnalyst, modelReviewer, modelTerritory, modelFuel, onSuccess }) => {
            dispatch(
                updateComponentModel({
                    idProject,
                    idModel,
                    idTerritory: modelTerritory,
                    idFuel: modelFuel,
                    idAnalyst: modelAnalyst,
                    idReviewer: modelReviewer,
                    name: modelName,
                    description: modelDescription,
                    onSuccess,
                })
            );
        },
        [idProject, idModel, dispatch]
    );

    const onMoveModel = useCallback(
        (active) => {
            dispatch(
                updateComponentModel({
                    idProject,
                    idModel,
                    active,
                    action: active ? "Restoring Model" : "Moving Model to Trash Bin",
                })
            );
        },
        [idProject, idModel, dispatch]
    );

    const onDeleteSuccess = useCallback(() => {
        dispatch(
            selectProjection({
                viewIndex,
                idProjectionView: projections.PROJECT_DASHBOARD,
                idProject,
            })
        );
    }, [idProject, viewIndex, dispatch]);

    const onDeleteModel = useCallback(() => {
        dispatch(
            deleteComponentModel({
                idProject,
                idModel,
                onSuccess: onDeleteSuccess,
            })
        );
    }, [idProject, idModel, onDeleteSuccess, dispatch]);

    // Event handlers

    const onModelStatusChange = useCallback(
        (modelStatus) => {
            // If Manager wants to set Model status
            // to Final
            if (modelStatus === allComponentModelStates.FINAL.toUpperCase()) {
                // // If all required Model Inputs for Model
                // // are not set to Manager Signed Off,
                // // Modal window with an error pops up
                // if (inputsProgress?.percentage !== 100) {
                //     dispatch(
                //         openModalDialogSetFinalSignedOffError({
                //             modelName: name,
                //         })
                //     );
                // }
                // // If all required Model Inputs are set
                // // to Manager Signed Off,
                // // Modal window with a warning pops up
                // else {
                //     dispatch(
                //         openModalDialogSetFinalWarning({
                //             modelName: name,
                //             onChange: () => changeModelStatus(modelStatus),
                //         })
                //     );
                // }

                // If no Modules were found for the Model,
                // Modal window with an error pops up
                if (noModules) {
                    dispatch(openModalDialogSetFinalNoModulesError({ modelName: name }));
                }
                // If Model does have Modules, but not all
                // Modules are marked with the Final status,
                // Modal window with an error pops up
                else if (modulesNotApproved) {
                    dispatch(openModalDialogSetFinalApprovedError({ modelName: name }));
                } else {
                    dispatch(
                        openModalDialogSetFinalWarning({
                            modelName: name,
                            onChange: () => changeModelStatus(modelStatus),
                        })
                    );
                }
            }
            // If Manager wants to rollback from Final status,
            // Modal window with a warning pops up
            else if (
                modelStatus === allComponentModelStates.IN_PROGRESS.toUpperCase() &&
                modelState === allComponentModelStates.FINAL.toUpperCase()
            ) {
                dispatch(
                    openModalDialogRollbackFinalWarning({
                        modelName: name,
                        onChange: () => changeModelStatus(modelStatus),
                    })
                );
            }
            // If Manager wants to set Model status to In Progress
            // or Proposed, no Modal window pops up
            else {
                changeModelStatus(modelStatus);
            }
        },
        [
            name,
            modelState,
            // inputsProgress,
            noModules,
            modulesNotApproved,
            changeModelStatus,
            dispatch,
        ]
    );

    const onRequiredChange = useCallback(() => {
        dispatch(
            updateComponentModel({
                idProject,
                idModel,
                required: !required,
                action: "Updating Model",
            })
        );
    }, [idProject, idModel, required, dispatch]);

    const onEditClick = useCallback(() => {
        dispatch(
            openModalDialogEditModel({
                idClient,
                idProject,
                modelName: name,
                modelDescription: description,
                modelAnalyst: idAnalyst,
                modelReviewer: idReviewer,
                modelTerritory: idTerritory,
                modelFuel: idFuel,
                // @ts-ignore
                onEdit: onEditModel,
            })
        );
    }, [idClient, idProject, name, description, idAnalyst, idReviewer, idTerritory, idFuel, onEditModel, dispatch]);

    const onDuplicateClick = () => {
        const onDuplicateSuccess = (action?: any, targetProjectId?: number) => {
            const modelId = action?.data?.targetModelId;

            // Only select newly created model if project view where
            // the model is located at is opened
            const isViewOpen = views.find((view) => view.name.includes(`project-${targetProjectId}`)) !== undefined;

            if (isViewOpen) {
                dispatch(
                    selectProjection({
                        viewIndex,
                        idProjectionView: projections.COMPONENT_MODEL_DASHBOARD,
                        idProject,
                        idProjection: modelId,
                        key: "idModel",
                    })
                );
            }
        };

        dispatch(
            openModalDialogDuplicateModel({
                idClient,
                idProject,
                idInputLog,
                idTerritory,
                idFuel,
                idModel,
                onSuccess: onDuplicateSuccess,
            })
        );
    };

    const onDeleteClick = useCallback(() => {
        dispatch(
            openModalDialogDeleteProjection({
                title: "Delete Model",
                projectionName: name,
                onMove: () => onMoveModel(false),
                onDelete: onDeleteModel,
            })
        );
    }, [name, onMoveModel, onDeleteModel, dispatch]);

    const onRestoreClick = useCallback(() => {
        dispatch(
            openModalDialogRestoreProjection({
                title: "Restore Model",
                text: (
                    <>
                        <div>
                            <strong>{name}</strong> will be restored to the Project.
                        </div>
                        <div>You will be able to import data and run calculations.</div>
                    </>
                ),
                onRestore: () => onMoveModel(true),
            })
        );
    }, [name, onMoveModel, dispatch]);

    const onDeletePermanentlyClick = useCallback(() => {
        dispatch(
            openModalDialogDeletePermanentlyProjection({
                title: "Delete Model Permanently",
                projectionName: name,
                onDelete: onDeleteModel,
            })
        );
    }, [name, onDeleteModel, dispatch]);

    const onDownloadClick = useCallback(() => {
        dispatch(
            openModalDialogDownloadData({
                idModel,
            })
        );
    }, [idModel, dispatch]);

    const onViewWorkflowClick = useCallback(() => {
        dispatch(
            openWindowCalculationWorkflow({
                idClient,
                idProject,
                idAnalyst,
                idReviewer,
                idModel,
                idInputLog,
                clientName,
                projectName,
                modelName: name,
                modelState,
                required,
            })
        );
    }, [idClient, idProject, idAnalyst, idReviewer, idModel, idInputLog, name, modelState, required, clientName, projectName, dispatch]);

    return (
        <div className="flex-column component-model-dashboard-header">
            <div className="component-model-dashboard-header__first-level-container">
                <div className="flex-row align-center component-model-dashboard-header__top-container">
                    <div>
                        <div className="component-model-dashboard-header__model">Model</div>
                        <div className="flex-row align-center">
                            <div data-testid="header-model-name" className="component-model-dashboard-header__title">
                                {name}
                            </div>
                            {active &&
                                !modelReadOnlyEnabled(modelState, required) &&
                                actionAllowed(userRights, USER_ACTIONS.MODEL_EDIT, required) && (
                                    <div className="margin-left-small">
                                        <Button
                                            variant="tertiary"
                                            icon="ui-pencil__edit__create_b_s"
                                            iconSize="sm"
                                            title="Edit model information"
                                            padding="sm"
                                            onClick={onEditClick}
                                        />
                                    </div>
                                )}
                            {!isEmpty(activeViews) &&
                                !isLoadingActiveViews &&
                                activeViews.map((userNumber: string) => (
                                    <div key={`avatar-${userNumber}`} className="margin-left-small">
                                        <Avatar userNumber={userNumber} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="component-model-dashboard-header__middle-container">
                    <span>{description}</span>
                </div>
                <div className="flex-row align-center justify-space-between component-model-dashboard-header__bottom-container">
                    <div className="flex-column flex-one component-model-dashboard-header__model-details-container">
                        <div className="flex-row component-model-dashboard-header__model-details">
                            <div title={`Model ID: ${idModel}`}>
                                <span className="component-model-dashboard-header__label">ID: </span>
                                <span>{idInputLog}</span>
                            </div>
                            <div className="margin-left">
                                <span className="component-model-dashboard-header__label">Territory: </span>
                                <span>{territory?.alias || territory?.name || "None"}</span>
                            </div>
                            <div className="margin-left">
                                <span className="component-model-dashboard-header__label">Fuel: </span>
                                <span>{fuel?.name || "None"}</span>
                            </div>
                        </div>
                        {hasInternalUserRole() && (
                            <div className="flex-row">
                                <div>
                                    <span className="component-model-dashboard-header__label">Analyst responsible: </span>
                                    <span>{analyst || "None"}</span>
                                </div>
                                <div className="margin-left">
                                    <span className="component-model-dashboard-header__label">Reviewer responsible: </span>
                                    <span>{reviewer || "None"}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="component-model-dashboard-header__model-actions-container">
                        {(required || hasInternalUserRole()) && (
                            <div className="component-model-dashboard-header__status-container">
                                <div className="flex-row align-center">
                                    <Label>Status</Label>
                                    {/* Final Report required finalized Model statuses cannot be changed */}
                                    {!isEmpty(modelStateItems) &&
                                    !projectReadOnlyEnabled(project?.projectState || "") &&
                                    (haveAnalystRights || haveReviewerRights) ? (
                                        <TagWithDropdown
                                            tagClassName={kebabCase(modelState)}
                                            value={modelState.toUpperCase()}
                                            items={modelStateItems}
                                            onChange={(value) => onModelStatusChange(value)}
                                        />
                                    ) : (
                                        <div className="aeg-tag-wrapper">
                                            <Tag className={kebabCase(modelState)}>
                                                <>{modelState.toLowerCase()}</>
                                            </Tag>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="flex-row">
                            {active && hasAnyOfPermissions([USER_ACTIONS.MODEL_DUPLICATE]) && (
                                <div className="component-model-dashboard-header__duplicate-container">
                                    <Button
                                        variant="tertiary"
                                        padding="sm"
                                        iconLeft="ui-control_point_duplicate_b_s"
                                        iconSize="sm"
                                        onClick={onDuplicateClick}
                                    >
                                        Duplicate
                                    </Button>
                                </div>
                            )}
                            {active &&
                                // Required finalized Models cannot be deleted
                                !modelReadOnlyEnabled(modelState, required) &&
                                actionAllowed(userRights, USER_ACTIONS.MODEL_DELETE, required) && (
                                    <div className="margin-left-small">
                                        <Button
                                            variant="tertiary"
                                            padding="sm"
                                            iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                                            iconSize="sm"
                                            onClick={onDeleteClick}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                )}
                            {!active &&
                                // Required finalized Models cannot be deleted
                                !modelReadOnlyEnabled(modelState, required) &&
                                actionAllowed(userRights, USER_ACTIONS.MODEL_DELETE, required) && (
                                    <>
                                        <div className="margin-left-small">
                                            <Button
                                                variant="tertiary"
                                                padding="sm"
                                                iconLeft="arrows-reply_b_a"
                                                iconSize="sm"
                                                onClick={onRestoreClick}
                                            >
                                                Restore
                                            </Button>
                                        </div>
                                        <div className="margin-left-small">
                                            <Button
                                                variant="tertiary"
                                                padding="sm"
                                                iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                                                iconSize="sm"
                                                onClick={onDeletePermanentlyClick}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="component-model-dashboard-header__third-level-container">
                <Separator line />
                <div className="flex-row align-center">
                    <div className="flex-column flex-one">
                        <ImportErrorPanel
                            idProject={idProject}
                            idInputLog={idInputLog}
                            modelName={name}
                            calculationPreconditions={modelCalculationPreconditions}
                        />

                        {!modelReadOnlyEnabled(modelState, required) && hasAnyOfPermissions([USER_ACTIONS.MODEL_ADD]) && active && (
                            <ImportDataPanel
                                viewIndex={viewIndex}
                                idClient={idClient}
                                idProject={idProject}
                                idModel={idModel}
                                idInputLog={idInputLog}
                                idTemplate={idTemplate}
                                idTerritory={idTerritory}
                                idFuel={idFuel}
                                modelName={name}
                                calculationPreconditionsErrors={modelCalculationPreconditionsErrors}
                                disabledImport={isInProgress}
                            />
                        )}
                    </div>
                    <div className="flex-row">
                        <Switcher
                            active={required ?? false}
                            label="Required for project"
                            disabled={
                                projectReadOnlyEnabled(project?.projectState || "") ||
                                !hasAnyOfPermissions([USER_ACTIONS.MODEL_EDIT_REQUIRED])
                            }
                            onClick={onRequiredChange}
                        />
                    </div>
                </div>
            </div>
            <div className="component-model-dashboard-header__fourth-level-container">
                {/* Uploading dataset progress bar */}
                {isUploading && (
                    <div className="component-model-dashboard-header__progress-bar">
                        <Separator />
                        <DeterminateProgressBar
                            resourceId={model.uploadProps.uploadId}
                            resourceName={componentModelDataImportV3ResourceName}
                            topElement={
                                <LabelWithIcon
                                    className="component-model-dashboard-header__progress-bar-top-element"
                                    icon="arrows-sync__autorenew_b_a"
                                >{`Uploading dataset | ${model.uploadProps.fileName}`}</LabelWithIcon>
                            }
                            showPercentage
                        />
                    </div>
                )}

                {/* Transforming dataset progress bar */}
                {processesInProgress.modelDataImport !== undefined && processesInProgress.modelDataImport !== false && (
                    <div className="component-model-dashboard-header__progress-bar">
                        <Separator />
                        <IndeterminateProgressBar
                            topElement={
                                <LabelWithIcon
                                    className="component-model-dashboard-header__progress-bar-top-element"
                                    icon="arrows-sync__autorenew_b_a"
                                >{`Transforming dataset | ${processesInProgress.modelDataImport.fileName}`}</LabelWithIcon>
                            }
                            startTime={importStartTime}
                            steps={Object.keys(processesInProgress.modelDataImport.steps).map((stepKey) => ({
                                name: stepKey,
                                status: processesInProgress.modelDataImport.steps[stepKey],
                            }))}
                        />
                    </div>
                )}

                <Separator />
                <div className="flex-row justify-space-between">
                    <div style={{ width: "200px" }}>
                        <Button
                            variant="secondary"
                            iconLeft="arrows-align_bottom_b_a"
                            iconSize="sm"
                            padding="lg"
                            fullWidth
                            onClick={onDownloadClick}
                        >
                            Download Data
                        </Button>
                    </div>
                    {!isUploading &&
                        (processesInProgress === undefined || processesInProgress.modelDataImport === false) &&
                        hasAnyOfPermissions([USER_ACTIONS.CALCULATION_WORKFLOW_ACCESS]) && (
                            <div style={{ width: "200px" }}>
                                <Button
                                    dataTestId="open-calculation-workflow"
                                    variant="secondary"
                                    padding="lg"
                                    fullWidth
                                    onClick={onViewWorkflowClick}
                                >
                                    View Calculation Workflow
                                </Button>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
});

export default DashboardHeader;
