import { memo, useCallback, useState } from "react";

import { useHelpTip } from "store/resources/actions/helpTips/helpTipsActions";

import IconLoading from "components/ui/Icons/IconLoading";

import HelpTipsForm from "./HelpTipsForm";
import HelpTipsView from "./HelpTipsView";

import { USER_ACTIONS } from "utils/user/defines";
import { hasAnyOfPermissions } from "utils/user";

import { HelpTipsSidebarProps } from "./types";

const HelpTipsSidebar = memo((props: HelpTipsSidebarProps) => {
    const [helpTip, isLoadingHelpTip] = useHelpTip({ pageId: props.pageId });

    const [isEditingContent, setIsEditingContent] = useState(false);

    const onEditContentClick = useCallback(() => {
        setIsEditingContent(true);
    }, []);

    const onCancelEditContentClick = useCallback(() => {
        setIsEditingContent(false);
    }, []);

    return isLoadingHelpTip ? (
        <IconLoading />
    ) : (
        <>
            {isEditingContent && (
                <HelpTipsForm
                    id={helpTip?.id}
                    pageId={props.pageId}
                    title={helpTip?.name}
                    content={helpTip?.content}
                    onClose={props.onClose}
                    onCancel={onCancelEditContentClick}
                />
            )}
            {!isEditingContent && (
                <HelpTipsView
                    title={helpTip?.name}
                    content={helpTip?.content}
                    onEdit={hasAnyOfPermissions([USER_ACTIONS.HELP_TIPS_EDIT]) ? onEditContentClick : undefined}
                    onClose={props.onClose}
                />
            )}
        </>
    );
});

export default HelpTipsSidebar;
