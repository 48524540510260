///
/// Calculations Preconditions actions
/// resourceName: calculationsPreconditionsResourceName
///
import { calculationsPreconditionsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

import {
    CalculationPreconditions,
    CalculationPrecondtionsResponseModel,
    CalculationPreconditionsModelTransformed,
    CalculationPreconditionsParams,
} from "./types";

const transformData = (responseModelArr: CalculationPreconditions[] = []) => {
    let calculationPreconditions = {};

    for (let index = 0; index < responseModelArr.length; index++) {
        const previousIdInputLog = index > 0 ? responseModelArr[index - 1].id_Input_Log : undefined;
        const idInputLog = responseModelArr[index].id_Input_Log;

        if (idInputLog === previousIdInputLog) {
            continue;
        } else {
            const calculationPreconditionsData = responseModelArr
                .filter((cp1) => cp1.id_Input_Log === idInputLog)
                .map((cp2) => {
                    const { id_Input_Log, ...rest } = cp2;

                    return {
                        ...rest,
                        idInputLog: id_Input_Log,
                    };
                });

            calculationPreconditions = {
                ...calculationPreconditions,
                [idInputLog]: calculationPreconditionsData,
            };
        }
    }

    return calculationPreconditions;
};

export const useCalculationPreconditions = ({ idProject }: CalculationPreconditionsParams) =>
    useResource({
        resourceName: calculationsPreconditionsResourceName,
        key: `${calculationsPreconditionsResourceName}-${idProject}`,
        query: {
            idProject,
        },
        transform: (data: CalculationPrecondtionsResponseModel) => {
            const baselineCalculationPreconditions = transformData(data?.model["0"]);
            const potentialCalculationPreconditions = transformData(data?.model["1"]);

            return {
                BASELINE_PRECONDITIONS: baselineCalculationPreconditions,
                POTENTIAL_PRECONDITIONS: potentialCalculationPreconditions,
            };
        },
    }) as [CalculationPreconditionsModelTransformed, boolean | undefined, any | undefined];

export const clearCalculationPreconditions =
    ({ idProject }: CalculationPreconditionsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: calculationsPreconditionsResourceName,
                key: `${calculationsPreconditionsResourceName}-${idProject}`,
                broadcast: true,
            })
        );
