import {
    RUN_MODULE_V2_START,
    RUN_MODULE_V2_STARTED,
    RUN_PENDING_MODULES_V2_START,
    RUN_MODULE_V2_RETRY,
    SET_PENDING_MODULE_V2_ACTIVE,
    RUN_MODULE_V2_FAILED,
    RUN_MODULE_V2_COMPLETE,
    PAUSE_MODULE_V2_START,
    PAUSE_MODULE_V2_FAILED,
    PAUSE_MODULE_V2_COMPLETE,
} from "store/actionTypes";

import { RunModuleV2State, RunModuleV2Action } from "./types";

const initialState: RunModuleV2State = {};

export const reducer = (state = initialState, action: RunModuleV2Action) => {
    switch (action.type) {
        case RUN_MODULE_V2_START:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        status: RUN_MODULE_V2_START,
                        completedSteps: [],
                    },
                };
            }

            break;

        case RUN_MODULE_V2_STARTED:
            {
                const { idModel, moduleId, status, completedStep } = action.payload;

                const completedSteps = [...(state[`${idModel}-${moduleId}`]?.completedSteps || []), completedStep];

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        status: status.toUpperCase(),
                        completedSteps,
                    },
                };
            }

            break;

        case RUN_PENDING_MODULES_V2_START:
            {
                const { idModel, runList } = action.payload;

                const start: RunModuleV2State = {};

                runList.forEach((moduleRun, index) => {
                    start[`${idModel}-${moduleRun.moduleId}`] = {
                        status: RUN_MODULE_V2_START,
                        completedSteps: [],
                        waiting: index > 0,
                    };
                });

                state = {
                    ...state,
                    ...start,
                };
            }

            break;

        case RUN_MODULE_V2_RETRY:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        ...state[`${idModel}-${moduleId}`],
                        status: RUN_MODULE_V2_RETRY,
                    },
                };
            }

            break;

        case SET_PENDING_MODULE_V2_ACTIVE:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        ...state[`${idModel}-${moduleId}`],
                        waiting: false,
                    },
                };
            }

            break;

        case RUN_MODULE_V2_FAILED:
            {
                const { idModel, moduleId, status } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        ...state[`${idModel}-${moduleId}`],
                        status: status.toUpperCase(),
                    },
                };
            }

            break;

        case RUN_MODULE_V2_COMPLETE:
            {
                const { idModel, moduleId, status, completedStep } = action.payload;

                const completedSteps = [...(state[`${idModel}-${moduleId}`]?.completedSteps || [])];

                if (completedStep) {
                    completedSteps.push(completedStep);
                }

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        ...state[`${idModel}-${moduleId}`],
                        status: status.toUpperCase(),
                        completedSteps,
                    },
                };
            }

            break;

        case PAUSE_MODULE_V2_START:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        ...state[`${idModel}-${moduleId}`],
                        status: PAUSE_MODULE_V2_START,
                    },
                };
            }

            break;

        case PAUSE_MODULE_V2_FAILED:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        ...state[`${idModel}-${moduleId}`],
                        status: PAUSE_MODULE_V2_FAILED,
                    },
                };
            }

            break;

        case PAUSE_MODULE_V2_COMPLETE:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: {
                        ...state[`${idModel}-${moduleId}`],
                        status: "PAUSED",
                    },
                };
            }

            break;

        default:
    }

    return state;
};
