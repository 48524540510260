import { isEmpty } from "lodash";
import { memo, useEffect, useMemo } from "react";

import IconLoading from "components/ui/Icons/IconLoading";
import CustomList from "components/ui/List/CustomList";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import ProjectDefault from "./ProjectDefault";
import ProjectStakeholder from "./ProjectStakeholder";
import ProjectDefaultFilters from "./ProjectDefaultFilters";
import ProjectStakeholderFilters from "./ProjectStakeholderFilters";

import { sortObjectsByDate } from "utils/date";
import hj from "utils/hotJar";
import { hasStakeholderUserRole, hasExternalUserRole } from "utils/user";

import { ProjectListProps } from "./types";

import "./style.scss";

const DEFAULT_HEADERS = {
    bookmarked: "",
    projectName: "Project name",
    projectDescription: "Project description",
    started: "Creation date",
    targetDate: "Target date",
    projectState: "Status",
    actions: "",
};

const STAKEHOLDER_HEADERS = {
    projectName: "Project name",
    projectDescription: "Project description",
    finished: "Set to Final",
};

const ProjectList = memo(({ projects, client, isLoadingProjects, user, withFilters }: ProjectListProps) => {
    useEffect(() => {
        hj("stateChange", "Projects");
    }, []);

    const Filters = hasStakeholderUserRole(user) ? ProjectStakeholderFilters : ProjectDefaultFilters;

    const projectsSorted = useMemo(() => {
        let projectsSorted = [];

        if (!isEmpty(projects) && !isLoadingProjects) {
            projectsSorted = sortObjectsByDate(projects, "started") || []; // Projects are sorted by their creation date
        }

        return projectsSorted;
    }, [projects, isLoadingProjects]);

    const projectProps = {
        clientName: client.clientName,
    };

    return (
        <>
            {isLoadingProjects ? (
                <IconLoading />
            ) : (
                <div className="flex-column fill-height project-list">
                    {isEmpty(projects) ? (
                        <div className="flex-column flex-one-in-column">
                            <NothingFoundBlock
                                className="no-projects"
                                icon="files-assignment_b_s"
                                title="There are no projects for the selected client"
                            >
                                <span>Please select a different </span>
                                {hasExternalUserRole(user) ? (
                                    <span>
                                        utility from the <strong>utilities panel</strong>
                                    </span>
                                ) : (
                                    <span>
                                        client from the <strong>client panel</strong>
                                    </span>
                                )}
                            </NothingFoundBlock>
                        </div>
                    ) : (
                        <CustomList
                            // @ts-ignore
                            dataTestId="project-list"
                            items={projectsSorted}
                            headers={hasStakeholderUserRole(user) ? STAKEHOLDER_HEADERS : DEFAULT_HEADERS}
                            component={hasStakeholderUserRole(user) ? ProjectStakeholder : ProjectDefault}
                            searchComponent={withFilters ? Filters : undefined}
                            componentProps={projectProps}
                            sortable
                            scrollBody
                            withFade
                        />
                    )}
                </div>
            )}
        </>
    );
});

export default ProjectList;
