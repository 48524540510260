import ReCAPTCHA from "react-google-recaptcha";

import { CaptchaProps } from "./types";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY;

const Captcha = ({ captchaRef, onChange }: CaptchaProps) => (
    <div className="captcha">
        <ReCAPTCHA ref={captchaRef} sitekey={RECAPTCHA_SITE_KEY || ""} onChange={onChange} />
    </div>
);

export default Captcha;
