import Login from "./Login";
import VLMLogin from "./VLMLogin";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import QrCode from "./QrCode";

import HomeDashboard from "./HomeDashboard";
import { ProjectSources } from "./ProjectSources";
import UserProfile from "./UserProfile";

import ManageProject from "./ManageProject";
import ProjectStakeholderDashboard from "./ProjectStakeholderDashboard";
import ManageCalculationWorkflow from "./ManageCalculationWorkflow";
import ModifyAvoidedCosts from "./ModifyAvoidedCosts";

import PowerBI from "./PowerBI";
import FloatModalPowerBI from "./PowerBI/FloatModalPowerBI";
import PublicPowerBI from "./PowerBI/PublicPowerBI";
import StandaloneReport from "./StandaloneReport";

import DeveloperPage from "./DeveloperPage";
import UILibrary from "./UILibrary";

import SamlView from "./SamlView";

import SplitView from "./SplitView";

import { IndexSignature } from "types/types";

export const availableViews: IndexSignature<any> = {
    Login,
    VLMLogin,
    ResetPassword,
    ChangePassword,
    ForgotPassword,
    QrCode,

    HomeDashboard,
    ProjectSources,
    UserProfile,

    ManageProject,
    ProjectStakeholderDashboard,
    ManageCalculationWorkflow,
    ModifyAvoidedCosts,

    PowerBI,
    FloatModalPowerBI,
    PublicPowerBI,
    StandaloneReport,

    DeveloperPage,
    UILibrary,

    SamlView,

    SplitView,
};
