const storage = sessionStorage;

export const SERVER_TIMEZONE_OFFSET_STORAGE_KEY = "server-timezone";

export const DEFAULT_SERVER_TIMEZONE_OFFSET = -300;
export const DEFAULT_LOCALE = new Intl.DateTimeFormat().resolvedOptions().locale;

/**
 * Formats the date as following:
 *
 * Nov 6, 2023, 09:46 AM
 */
export const d = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
});

const getDatePattern = (locale = DEFAULT_LOCALE) => {
    // expected style = year: 2010, month: 12, day: 31
    var options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

    // formatToParts() returns array of object breaking down the locales dateformat
    // [
    //  {type: "month", value: "03"},
    //  {type: "literal", value: "/"},
    //  {type: "day", value: "30"},
    //  {type: "literal", value: "/"},
    //  {type: "year", value: "2021"},
    // ]
    var formatter = new Intl.DateTimeFormat(locale, options).formatToParts();

    return formatter
        .map((e) => {
            switch (e.type) {
                case "month":
                    return "MM";
                case "day":
                    return "dd";
                case "year":
                    return "yyyy";
                default:
                    return e.value;
            }
        })
        .join("");
};

/**
 * Returns beginning of day for a given datetime
 * @param date Date
 * @returns Date
 */
const getDateWithoutTime = (date: number | Date) => {
    let datePart = new Date(date);

    datePart.setHours(0);
    datePart.setMinutes(0);
    datePart.setSeconds(0);
    datePart.setMilliseconds(0);

    return datePart;
};

export const DATEPICKER_DATE_FORMAT = getDatePattern();

/**
 * Compares an input date against a reference date
 * Return 1 if input date > reference date
 * Return -1 if input date < reference date
 * Return 0 if input date = reference date
 * @param inputDate Date
 * @param referenceDate Date
 * @returns int
 */
export const compareDateWithoutTime = (inputDate: Date, referenceDate: any) => {
    const inputDatePart = getDateWithoutTime(inputDate);
    const referenceDatePart = getDateWithoutTime(referenceDate);

    if (inputDatePart > referenceDatePart) {
        return 1;
    } else if (inputDatePart < referenceDatePart) {
        return -1;
    } else {
        return 0;
    }
};

export const jsonDateToDate = (dateStr: string) => {
    const fixedDateStr = dateStr.replace(" ", "T");
    const parts = fixedDateStr.split("T");

    if (parts.length > 0) {
        const dateParts = parts[0].split("-");
        const result = new Date();
        result.setMilliseconds(0);

        if (dateParts.length === 3) {
            result.setFullYear(Number(dateParts[0]));
            result.setMonth(Number(dateParts[1]) - 1);
            result.setDate(Number(dateParts[2]));
        } else {
            return NaN;
        }

        if (parts[1]) {
            const timeParts = parts[1].split(".")[0].split(":");

            if (timeParts.length === 3) {
                result.setHours(Number(timeParts[0]));
                result.setMinutes(Number(timeParts[1]));
                result.setSeconds(Number(timeParts[2]));
            }
        } else {
            result.setHours(0);
            result.setMinutes(0);
            result.setSeconds(0);
        }

        return result;
    }

    return NaN;
};

export const sortObjectsByDate = (list: any[], dateKey: string) => {
    if (list) {
        let orderedList = list.slice();

        orderedList.sort((a, b) => {
            return jsonDateToDate(b[dateKey]).valueOf() - jsonDateToDate(a[dateKey]).valueOf();
        });

        return orderedList;
    }
};

export const saveServerTimezone = (value: any) => {
    storage.setItem(SERVER_TIMEZONE_OFFSET_STORAGE_KEY, value);
};

export const getInitialServerTimezoneOffset = () => {
    const value = storage.getItem(SERVER_TIMEZONE_OFFSET_STORAGE_KEY);

    return value ? Number(value) : DEFAULT_SERVER_TIMEZONE_OFFSET;
};

export const getTimeDifferenceToString = (started: string, finished: string) => {
    let timeDiff = "";

    const startDate = new Date(started + "Z");
    const endDate = new Date(finished + "Z");

    const diffMs = endDate.getTime() - startDate.getTime(); // milliseconds
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // days
    const diffHrs = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // hours
    const diffMins = Math.floor(((diffMs % (1000 * 60 * 60 * 24)) % (1000 * 60 * 60)) / (1000 * 60)); // minutes
    const diffSecs = Math.round((((diffMs % (1000 * 60 * 60 * 24)) % (1000 * 60 * 60)) % (1000 * 60)) / 1000); // seconds

    if (diffDays > 0) {
        timeDiff += `${diffDays} days, `;
    }
    if (diffHrs > 0) {
        timeDiff += `${diffHrs} hours, `;
    }
    if (diffMins > 0) {
        timeDiff += `${diffMins} minutes, `;
    }
    if (diffSecs > 0) {
        timeDiff += `${diffSecs} seconds`;
    }

    return timeDiff;
};
