import { batch } from "react-redux";

import { useProcessStatus, ProcessStatusProps, UseProcessParams } from "./useProcessStatus";

import { projectDataImportResourceName } from "store/configureResources";

import { clearMarketProfileData } from "store/resources/actions/marketProfile/marketProfileActions";
import { clearProjectLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

export const marketProfileDataImportStatusProps: ProcessStatusProps = {
    resourceName: projectDataImportResourceName,
    idSelector: ({ idInputLog }) => idInputLog, // TODO: Replace idInputLog with idProjectUpload - VI-1544
    process: "data-import",
    timeoutHandlers: null,
    onItemFinishBefore:
        ({ idProject }) =>
        // @ts-ignore
        (dispatch) => {
            batch(() => {
                dispatch(clearMarketProfileData({ idProject }));
                dispatch(clearProjectLogItems({ idProject }));
            });
        },
};

// TODO: Replace idInputLog with idProjectUpload - VI-1544
export const useProjectDataImportStatus = ({ idProject, idProjectUpload }: UseProcessParams) =>
    useProcessStatus({ statusProps: marketProfileDataImportStatusProps, idProject, idInputLog: idProjectUpload });
