import { memo } from "react";

import DashboardPanel from "components/ui/Dashboard";

import ProjectList from "pages/HomeDashboard/HomeDashboardDefault/common/ProjectList";

import { BookmarkedProjectsPanelProps } from "./types";

const BookmarkedProjectsPanel = memo(({ client, bookmarkedProjects, isLoadingProjects, user }: BookmarkedProjectsPanelProps) => {
    return (
        <DashboardPanel className="bookmarked-projects-panel" title={client.clientName}>
            <ProjectList client={client} projects={bookmarkedProjects} isLoadingProjects={isLoadingProjects} user={user} />
        </DashboardPanel>
    );
});

export default BookmarkedProjectsPanel;
