import ClassNames from "classnames";

import IconWithLabel from "components/ui/Icons/IconWithLabel";

import "./style.scss";

const StatusMsg = (props: StatusMsgProps) => {
    const classNames = ClassNames("status-msg", props.className, {
        error: props.error,
        success: props.success,
        warning: props.warning,
        "text-center large-msg": props.largeMsg,
    });

    return (
        <div className={classNames}>
            {props.icon ? (
                <IconWithLabel iconWithLabelMedium={props.iconWithLabelMedium} icon={props.icon}>
                    {props.message}
                </IconWithLabel>
            ) : (
                props.message
            )}
        </div>
    );
};

export interface StatusMsgInterface {
    /**
     * Display large message with a background.
     */
    largeMsg?: boolean;

    /**
     * Display medium size icon and label.
     */
    iconWithLabelMedium?: boolean;

    /**
     * Display message without an icon.
     */
    withoutIcon?: boolean;

    /**
     * Name of the icon.
     */
    icon?: string;

    /**
     * Message text.
     */
    message: string | JSX.Element;
}

interface StatusMsgProps extends StatusMsgInterface {
    /**
     * Playwright attribute.
     */
    dataTestId?: string;

    className?: string;

    /**
     * Sets error style to message.
     */
    error?: boolean;

    /**
     * Sets success style to message.
     */
    success?: boolean;

    /**
     * Sets warning style to message.
     */
    warning?: boolean;
}

export default StatusMsg;
