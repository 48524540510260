import { isEmpty } from "lodash";
import { memo, useEffect, useMemo } from "react";

import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";

import ModelDashboardEmpty from "./DashboardEmpty";
import ModelDashboardDefault from "./DashboardDefault";

import hj from "utils/hotJar";

import { ModelDashboardProps } from "./types";
import { ProjectInput } from "store/resources/actions/projectInput/types";

const ModelDashboard = memo(({ viewIndex, idClient, idProject, idInputLog, projectName }: ModelDashboardProps) => {
    const [projectInputs, isLoadingProjectInputs] = useProjectInputs({ idProject });

    const currentModel = useMemo(() => {
        let currentModel;

        if (!isEmpty(projectInputs) && !isLoadingProjectInputs) {
            currentModel = projectInputs.find((projectInput: ProjectInput) => projectInput.idInputLog === idInputLog);
        }

        return currentModel;
    }, [idInputLog, projectInputs, isLoadingProjectInputs]);

    useEffect(() => {
        hj("stateChange", "ModelDashboard");
    }, []);

    if (currentModel === undefined) {
        return null;
    } else if (typeof currentModel.idInputLog === "string") {
        return <ModelDashboardEmpty model={currentModel} />;
    } else {
        return (
            <ModelDashboardDefault
                viewIndex={viewIndex}
                idClient={idClient}
                model={currentModel}
                projectName={projectName}
                models={projectInputs}
            />
        );
    }
});

export default ModelDashboard;
