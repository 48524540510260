import { isEmpty } from "lodash";
import { memo, useCallback, useMemo } from "react";
import cn from "classnames";

import { useMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";

import IconWrap from "components/ui/Icons";
import Checkbox from "components/ui/Input/Checkbox";
import Label from "components/ui/Label";
import ActionLabel from "components/ui/Label/ActionLabel";
import Tooltip from "components/ui/Tooltip";

import { useModelCalculationPreconditions } from "utils/useCalculationPreconditions";

import { ModelInputsTableSelectionProps } from "./types";
import { ModelInputs } from "store/resources/actions/modelInputs/types";

import "./style.scss";

export const modelInputsToSelections = (modelInputs: any[], value: boolean) => {
    return Object.assign({}, ...modelInputs.map((mi) => ({ [mi.sheetName]: value })));
};

export const uncheckModelInputsWithErrors = (modelInputs: any[]) => {
    const sheetSelections: any = {};

    for (let index = 0; index < modelInputs.length; index++) {
        const { sheetName, status } = modelInputs[index];

        // Deselect tables with errors
        if (status === "ERROR") {
            sheetSelections[sheetName] = false;
        }
    }

    return sheetSelections;
};

const getTooltipPlacements = (items: any[], itemsInRow: 3 | 5) => {
    // Three items per row
    // 1, 4, 7, 10, 13, ... - bottom-start
    // 2, 5, 8, 11, 14, ... - bottom
    // 3, 6, 9, 12, 15, ... - bottom-end

    // Five items per row
    // 1, 6, 11, 16, 21, ... - bottom-start
    // 2, 7, 12, 17, 22, ... - bottom
    // 3, 8, 13, 18, 23, ... - bottom
    // 4, 9, 14, 19, 24, ... - bottom
    // 5, 10, 15, 20, 25, ... - bottom-end

    let first = 1;
    let second = 2;
    let third = 3;
    let fourth = 4;
    let fifth = 5;

    return items.map((item, index) => {
        const itemNumber = index + 1;

        if (itemsInRow === 3) {
            switch (itemNumber) {
                case first:
                    first += itemsInRow;

                    return "bottom-start";
                case second:
                    second += itemsInRow;

                    return "bottom";
                case third:
                    third += itemsInRow;

                    return "bottom-end";
                default:
                    return undefined;
            }
        } else {
            switch (itemNumber) {
                case first:
                    first += itemsInRow;

                    return "bottom-start";
                case second:
                    second += itemsInRow;

                    return "bottom";
                case third:
                    third += itemsInRow;

                    return "bottom";
                case fourth:
                    fourth += itemsInRow;

                    return "bottom";
                case fifth:
                    fifth += itemsInRow;

                    return "bottom-end";
                default:
                    return undefined;
            }
        }
    });
};

const ModelInputsTableSelection = memo((props: ModelInputsTableSelectionProps) => {
    const { idProject, idInputLog, title, tables, displayInModal, showSelectAll, obligatoryCheck, sheetSelections, setSheetSelections } =
        props;

    const [modelInputsRes, isLoadingModelInputsRes] = useMeasureInputs({ idInputLog });

    const { modelCalculationPreconditions } = useModelCalculationPreconditions({ idProject, idInputLog });

    const allSelected = tables.every((sheet: ModelInputs) => sheetSelections[sheet.sheetName]);

    const tooltipPlacements = getTooltipPlacements(tables, displayInModal ? 3 : 5);

    const modelInputs = useMemo(() => {
        let modelInputs: any = [];

        if (!isEmpty(modelInputsRes) && !isLoadingModelInputsRes) {
            modelInputs = (modelInputsRes.equipmentinputstables || [])
                .concat(modelInputsRes.nonequipmentinputstables || [])
                // TODO: Add type to tables variable
                .reduce((tables: any, mi: ModelInputs) => {
                    tables[mi.sheetName] = mi;

                    return tables;
                }, {});
        }

        return modelInputs;
    }, [modelInputsRes, isLoadingModelInputsRes]);

    const onCheckAll = useCallback(() => {
        setSheetSelections({
            ...sheetSelections,
            ...modelInputsToSelections(tables, !allSelected),
        });
    }, [tables, sheetSelections, setSheetSelections, allSelected]);

    const onSelectedTableChange = useCallback(
        (sheetName, value) => {
            setSheetSelections({ ...sheetSelections, [sheetName]: value });
        },
        [sheetSelections, setSheetSelections]
    );

    const renderModelInput = () => {
        return tables.map((input: ModelInputs, index: number) => {
            const { sheetName, tableName } = input;
            const sheetInfo = modelInputs && modelInputs[sheetName];

            let required = false;
            let sheetInfoError = false;
            let emptySheet = false;

            if (obligatoryCheck) {
                required =
                    modelCalculationPreconditions?.[tableName] &&
                    modelCalculationPreconditions[tableName].some((table) => table.required === 1);

                sheetInfoError =
                    sheetInfo?.rowCount === 0 ||
                    (modelCalculationPreconditions?.[tableName] &&
                        modelCalculationPreconditions[tableName].some((table) => table.tableIsValid === 0));
            } else {
                sheetInfoError = sheetInfo?.status === "ERROR";

                // Disable selection for sheet when duplicating
                // if that specific sheet does not have any data
                emptySheet = !Object.keys(modelInputs).includes(sheetName);
            }

            return (
                <div
                    key={`input-table-${index}`}
                    className={cn("flex-row align-center input-table", {
                        "one-third": displayInModal,
                        "one-fifth": !displayInModal,
                    })}
                >
                    <Checkbox
                        id={`input-table-${sheetName}`}
                        label={sheetName}
                        disabled={(!obligatoryCheck && sheetInfoError) || emptySheet}
                        checked={sheetSelections[sheetName] || false}
                        onChange={(event) => onSelectedTableChange(sheetName, event.target.checked)}
                    />
                    {!sheetSelections[sheetName] && required && sheetInfoError && (
                        <Tooltip className="margin-left-small" icon="octagon-exclamation-empty" placement={tooltipPlacements[index]}>
                            Mising obligatory table or contains import errors.
                        </Tooltip>
                    )}
                    {!obligatoryCheck && sheetInfoError && <IconWrap className="margin-left-small" icon="alert-error-empty" error />}
                </div>
            );
        });
    };

    return (
        <div className="flex-column model-inputs-table-selection">
            <div className="flex-row justify-space-between model-inputs-table-selection__title-row">
                <Label>{title}</Label>
                {showSelectAll && <ActionLabel onClick={onCheckAll}>{allSelected ? "Deselect all" : "Select all"}</ActionLabel>}
            </div>
            <div className="flex-row align-center flex-wrap">{renderModelInput()}</div>
        </div>
    );
});

export default ModelInputsTableSelection;
