import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useVariableList, updateVariables } from "store/resources/actions/variables/variablesActions";

import Variables from "layouts/common/Variables";

import IconLoading from "components/ui/Icons/IconLoading";

import { useVariablesTransform } from "utils/useVariablesTransform";

import { IndexSignature } from "types/types";
import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";
import { VariablesTransformed, VariablesResponseModel } from "store/resources/actions/variables/types";

const useTransform = (data: VariablesResponseModel) => useVariablesTransform(data?.model, 3);

const ProjectVariables = memo(({ project, editing, onEditCancel }: ProjectDashboardPanelProps) => {
    const { idProject } = project;

    const dispatch = useDispatch();

    const [projectVariables, isLoading] = useVariableList({ idProject, transform: useTransform });

    const [variableValues, setVariableValues] = useState<IndexSignature<string>>({});

    const variablesSavedOnProject = useMemo(() => {
        return projectVariables?.some((v1: VariablesTransformed[]) => v1.some((v2: VariablesTransformed) => v2.lastUpdatedBy !== null));
    }, [projectVariables]);

    const onUpdateVariableClick = useCallback(() => {
        dispatch(
            updateVariables({
                idProject,
                values: variableValues,
                onSuccess: () => {
                    setVariableValues({});
                },
            })
        );
    }, [idProject, variableValues, dispatch]);

    return (
        <>
            {isLoading ? (
                <IconLoading />
            ) : (
                <Variables
                    variablesLevel="project"
                    values={variableValues}
                    projectVariables={projectVariables}
                    variablesSavedOnProject={variablesSavedOnProject}
                    editing={editing}
                    warningMessage="By saving, current models and their modules won't be affected"
                    infoMessage="All models use local values until you save them on the Project level"
                    setValues={setVariableValues}
                    onSave={onUpdateVariableClick}
                    onEditCancel={onEditCancel}
                />
            )}
        </>
    );
});

export default ProjectVariables;
