import { useCallback } from "react";
import ClassNames from "classnames";

import Checkbox from "components/ui/Input/Checkbox";

import { DropdownItemProps } from "components/ui/Dropdown/types";

import "./style.scss";

const DropdownItem = (props: DropdownItemProps) => {
    const { onMouseDown } = props;

    const isSelectedInArray = Array.isArray(props.selectedValue) && props.selectedValue.includes(props.value);

    const classNames = ClassNames("flex-row align-center dropdown-item", {
        selected:
            ((typeof props.selectedValue === "string" || typeof props.selectedValue === "number") && props.selectedValue === props.value) ||
            isSelectedInArray ||
            props.active,
        disabled: props.disabled,
        danger: props.danger,
    });

    const _onMouseDown = useCallback(
        (event) => {
            event.stopPropagation();

            onMouseDown?.(props.value, props.itemProps);
        },
        [props.value, props.itemProps, onMouseDown]
    );

    const _onMultipleMouseDown = useCallback(() => {
        if (props.value && Array.isArray(props.selectedValue)) {
            let value = [...(props.selectedValue || [])];

            if (value.includes(props.value)) {
                value = value.filter((v) => v !== props.value);
            } else {
                value.push(props.value);
            }

            onMouseDown?.(value);
        }
    }, [props.value, props.selectedValue, onMouseDown]);

    return (
        <li
            data-testid={props.dataTestId}
            className={classNames}
            hidden={props.hidden}
            onMouseDown={props.multiple ? _onMultipleMouseDown : _onMouseDown}
        >
            {props.multiple && <Checkbox checked={isSelectedInArray} />}
            {props.children}
        </li>
    );
};

export default DropdownItem;
