import { get } from "lodash";

import { availableResources } from "./configureResources";

import { IndexSignature } from "types/types";

export const getKey = (id?: string | number) => `${id ? `-${id}` : ""}`;

export const getResourceUrl = (resourceName?: string, pathParams: IndexSignature<string | number | undefined> = {}) => {
    let url = null;

    const resource = availableResources.filter((r) => r.resourceName === resourceName)[0];

    if (resource) {
        if (Object.keys(pathParams).length) {
            url = Object.keys(pathParams).reduce(
                (path, paramKey) => path.replace(`{${paramKey}}`, `${pathParams[paramKey]}`),
                resource.resourceUrl
            );
        } else {
            url = resource.resourceUrl;
        }
        // Remove path sections
        url = url.replace(/\/\{\w+\}/gm, "");
    }

    return url;
};

export const getResourceKey = ({ resourceId, key }: { resourceId?: string; key?: string | RegExp }) => {
    return key ? key : resourceId;
};

export const getResourceState = <T>(state: any, resourceName: string, params?: IndexSignature<string | number>): T[] => {
    let itemsByIdKey = resourceName;

    if (params) {
        Object.keys(params).forEach((key) => {
            itemsByIdKey += `-${params[key]}`;
        });
    }

    return (
        get(state, `resources.${resourceName}.itemsById[${itemsByIdKey}].data.model`) ||
        get(state, `resources.${resourceName}.itemsById[${itemsByIdKey}].data`) ||
        []
    );
};
