import { isEmpty, isNil, uniqBy } from "lodash";
import { memo, useMemo, useCallback } from "react";

import ModelLogNoteSidebar from "layouts/Sidebar/ModelLogNoteSidebar";

import { IconSingleModelReport } from "components/ui/Icons/IconReport";
import Button from "components/ui/ButtonNew";
import CustomList from "components/ui/List/CustomList";
import NothingFoundBlock from "components/ui/NothingFoundBlock";
import Tooltip from "components/ui/Tooltip";
import Dropdown from "components/ui/Dropdown";
import IconLoading from "components/ui/Icons/IconLoading";

import DownloadResults from "pages/ManageProject/ProjectionsContent/common/DownloadResults";

import { parseCalculationResult } from "utils/useCalculationResult";
import { useSidePanelHandlers } from "utils/useSidePanelHandlers";
import { actionStatus } from "utils/constants";
import { toLocaleDateTime } from "utils/dateTime";
import { logActionKeys, logAction } from "utils/scenarioLog";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";
import { formatFullName } from "utils/string";

import { StandaloneReport } from "store/resources/actions/standaloneReport/types";
import { ProjectInput } from "store/resources/actions/projectInput/types";
import { ComponentModel } from "store/resources/actions/componentModel/types";
import { ScenarioLogProps } from "./types";

import "./style.scss";

const ScenarioLog = memo((props: ScenarioLogProps) => {
    const { dataTestId = "scenario-log", idClient, idProject, projections, headers, logItems } = props;

    // Resources

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ key: "outer-right" });

    const isProjectionView = headers.projectionName === undefined;

    // Memos

    const projectionItems = useMemo(() => {
        let projectionItems: any[] = [];

        if (!isEmpty(projections)) {
            projectionItems = [
                {
                    value: "",
                    label: "All",
                },
                ...projections
                    // Note: Filtering here because there have been cases in DB
                    // where idInputLog is -1 which then causes an error
                    .filter((item) => item.idModel > 0 || item.idInputLog > 0 || item.idReport)
                    .map((item) => ({
                        // Note: Order is important - first idModel, second idInputLog, third idReport
                        value: String(item.idModel || item.idInputLog || item.idReport),
                        label: item.name,
                    })),
            ];
        }

        return projectionItems;
    }, [projections]);

    const logActionItems = useMemo(() => {
        let logActionItems: any[] = [];

        if (!isEmpty(logItems)) {
            logActionItems = [
                {
                    value: "",
                    label: "All",
                },
                ...uniqBy(
                    Object.entries(logAction).map(([action, status]) => ({
                        value: action,
                        label: status.default,
                    })),
                    ({ value }) => value
                ).filter((status) => logItems.find((item) => item.actionName === status.value)),
            ];
        }

        return logActionItems;
    }, [logItems]);

    // Event handlers

    const onNameFilterChange = useCallback(
        (value, updateFilterValue) => {
            const componentModel = projections.find((cm: ComponentModel) => cm.idModel === Number(value));
            const legacyModel = projections.find((lm: ProjectInput) => lm.idInputLog === Number(value));
            const standaloneReport = projections.find((sr: StandaloneReport) => sr.idReport === Number(value));

            if (componentModel !== undefined) {
                updateFilterValue("idModel")(value);
            } else if (legacyModel !== undefined) {
                updateFilterValue("idInputLog")(value);
            } else if (standaloneReport !== undefined) {
                updateFilterValue("idReport")(value);
            }

            componentModel === undefined && updateFilterValue("idModel")("");
            legacyModel === undefined && updateFilterValue("idInputLog")("");
            standaloneReport === undefined && updateFilterValue("idReport")("");
        },
        [projections]
    );

    const onAddNoteClick = useCallback(
        (idInputLog, idModel, idStudyLog) => {
            handleOpenSidePanel(
                <ModelLogNoteSidebar
                    idProject={idProject}
                    idInputLog={idInputLog}
                    idModel={idModel === 0 ? undefined : idModel}
                    idStudyLog={idStudyLog}
                    noteType="system-entry"
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [idProject, handleOpenSidePanel, handleCloseSidePanel]
    );

    const onShowNoteClick = useCallback(
        (idInputLog, idModel, idStudyLog, noteType) => {
            handleOpenSidePanel(
                <ModelLogNoteSidebar
                    idProject={idProject}
                    idInputLog={idInputLog}
                    idModel={idModel === 0 ? undefined : idModel}
                    idStudyLog={idStudyLog}
                    noteType={noteType}
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [idProject, handleOpenSidePanel, handleCloseSidePanel]
    );

    // Render functions

    const renderActionFilter = (filters: any, updateFilterValue: any) => (
        <Dropdown
            placeholder="Select"
            value={filters.actionName || ""}
            items={logActionItems}
            onChange={(value: any) => updateFilterValue("actionName")(value)}
            inputTableFilter
        />
    );

    const renderProjectionFilters = (filters: any, updateFilter: any, updateFilterValue: any) => (
        <div className="list-search-headers-row">
            <div className="column-filter column-action-name filter left right">{renderActionFilter(filters, updateFilterValue)}</div>
        </div>
    );

    const renderProjectFilters = (filters: any, updateFilter: any, updateFilterValue: any) => (
        <div className="list-search-headers-row">
            <div className="column-filter column-projection-name filter left right">
                <Dropdown
                    placeholder="Select"
                    value={filters.idModel || filters.idInputLog || filters.idReport}
                    items={projectionItems}
                    onChange={(value: any) => onNameFilterChange(value, updateFilterValue)}
                    inputTableFilter
                />
            </div>
            <div className="column-filter column-action-name filter left right">{renderActionFilter(filters, updateFilterValue)}</div>
            <div className="column-filter column-started" />
            <div className="column-filter column-user" />
            <div className="column-filter column-execution-time" />
            <div className="column-filter column-result" />
            <div className="column-filter column-note" />
        </div>
    );

    const renderProjectLogItem = (item: any, index: number) => {
        const calculationResult = !isNaN(parseInt(item.results)) ? parseCalculationResult(item) : undefined;

        const noteType = item.actionName === logActionKeys.USER_NOTE ? "user-note" : "system-entry";

        const componentModel = projections.find((cm: ComponentModel) => cm.idModel === item.idModel);
        const legacyModel = projections.find((lm: ProjectInput) => lm.idInputLog === item.idInputLog);
        const standaloneReport = projections.find((sr: StandaloneReport) => sr.idReport === item.idReport);

        let name = "";

        if (componentModel !== undefined) {
            name = componentModel.name;
        } else if (legacyModel !== undefined) {
            name = legacyModel.name;
        } else if (standaloneReport !== undefined) {
            name = standaloneReport.name;
        }

        return (
            <div key={`timestamp-${index}`} className="list-item-row scenario-log-row">
                <div className="item-value column-projection-name">{name || "All models/reports"}</div>
                <div className="item-value column-action-name">
                    {logAction[item.actionName][item.results] || logAction[item.actionName].default}
                </div>
                <div className="item-value column-started">{toLocaleDateTime(`${item.started}Z`) || ""}</div>
                <div className="item-value column-user">{formatFullName(item.creatorFirstName, item.creatorLastName)}</div>
                <div className="item-value column-execution-time">{item.executionTime || ""}</div>
                <div className="flex item-value column-result">
                    {item.actionName === logActionKeys.RUN_CALCULATION && calculationResult !== undefined && (
                        <>
                            <div>
                                <DownloadResults idInputLog={item.idInputLog} idResultsLog={item.results} />
                            </div>
                            <div className="margin-left-small">
                                {componentModel === undefined ? (
                                    // Legacy Model
                                    <IconSingleModelReport
                                        idClient={idClient}
                                        idProject={idProject}
                                        idInputLog={item.idInputLog}
                                        title={name}
                                        subTitle={`${calculationResult.isPotential ? "Potential" : "Baseline"} result`}
                                        calculationResult={calculationResult}
                                    />
                                ) : (
                                    // Component Model
                                    <IconSingleModelReport
                                        idClient={idClient}
                                        idProject={idProject}
                                        idModel={item.idModel}
                                        idInputLog={item.idInputLog}
                                        title={name}
                                        subTitle={calculationResult.parameters?.CASE_STUDIES?.[0].NAME}
                                        calculationResult={calculationResult}
                                        scenarioName={item.scenarioName}
                                        withApprovement={
                                            calculationResult.isInClientReview && hasAnyOfPermissions([USER_ACTIONS.MODULES_APPROVE])
                                        }
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {item.results === actionStatus.OUT_OF_DATE && <span>Results out of date</span>}
                </div>
                <div className="item-value column-note">
                    {item.lastIdModelNote !== null && (
                        <Tooltip
                            placement="bottom-end"
                            className="note-tooltip"
                            customIcon={
                                <Button
                                    ariaLabel="Show notes"
                                    variant="tertiary"
                                    icon="communication-message_list__speaker_notes_b_s"
                                    iconSize="sm"
                                    padding="sm"
                                    onClick={() => onShowNoteClick(item.idInputLog, item.idModel, item.idStudyLog, noteType)}
                                />
                            }
                        >
                            {`Last entry by ${formatFullName(item.noteFirstName, item.noteLastName)} on ${toLocaleDateTime(
                                `${item.notesLastUpdateDate}Z`
                            )}`}
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    };

    const renderProjectionLogItem = (item: any, index: number) => {
        const calculationResult = !isNaN(parseInt(item.results)) ? parseCalculationResult(item) : undefined;

        const noteType = item.actionName === logActionKeys.USER_NOTE ? "user-note" : "system-entry";

        const model = projections.find((p: ProjectInput) => p.idInputLog === item.idInputLog);
        const standaloneReport = projections.find((sr: StandaloneReport) => sr.idReport === item.idReport);

        return (
            <div key={`timestamp-${index}`} className="list-item-row scenario-log-row">
                <div className="item-value column-action-name">
                    {logAction[item.actionName][item.results] || logAction[item.actionName].default}
                </div>
                <div className="item-value column-started">{toLocaleDateTime(`${item.started}Z`) || ""}</div>
                <div className="item-value column-user">{formatFullName(item.creatorFirstName, item.creatorLastName)}</div>
                <div className="item-value column-execution-time">{item.executionTime || ""}</div>
                {model !== undefined && (
                    <div className="flex item-value column-result">
                        {item.actionName === logActionKeys.RUN_CALCULATION && calculationResult !== undefined && (
                            <>
                                <div>
                                    <DownloadResults idInputLog={item.idInputLog} idResultsLog={item.results} />
                                </div>
                                <div className="margin-left-small">
                                    {item.idModel === 0 ? (
                                        // Legacy Model
                                        <IconSingleModelReport
                                            idClient={idClient}
                                            idProject={idProject}
                                            idInputLog={item.idInputLog}
                                            title={model.name}
                                            subTitle={`${calculationResult.isPotential ? "Potential" : "Baseline"} result`}
                                            calculationResult={calculationResult}
                                        />
                                    ) : (
                                        // Component Model
                                        <IconSingleModelReport
                                            idClient={idClient}
                                            idProject={idProject}
                                            idModel={item.idModel}
                                            idInputLog={item.idInputLog}
                                            title={model.name}
                                            subTitle={calculationResult.parameters?.CASE_STUDIES?.[0].NAME}
                                            calculationResult={calculationResult}
                                            scenarioName={item.scenarioName}
                                            withApprovement={
                                                calculationResult.isInClientReview && hasAnyOfPermissions([USER_ACTIONS.MODULES_APPROVE])
                                            }
                                        />
                                    )}
                                </div>
                            </>
                        )}
                        {item.results === actionStatus.OUT_OF_DATE && <span>Results out of date</span>}
                    </div>
                )}
                <div className="item-value column-note">
                    {/* TODO: Temporary check. Remove when Notes have been added for Standalone Reports. */}
                    {standaloneReport === undefined && (
                        <>
                            {/* Add note to action log */}
                            {item.lastIdModelNote === null &&
                                item.loadingNote === undefined &&
                                hasAnyOfPermissions([USER_ACTIONS.NOTE_ADD]) && (
                                    <Button
                                        variant="tertiary"
                                        icon="communication-message_b_s"
                                        iconSize="sm"
                                        padding="sm"
                                        title="Add note to system entry"
                                        onClick={() => onAddNoteClick(item.idInputLog, item.idModel, item.idStudyLog)}
                                    />
                                )}

                            {/* Note being added/deleted */}
                            {item.loadingNote && <IconLoading />}

                            {/* Display note */}
                            {!isNil(item.lastIdModelNote) && item.loadingNote === undefined && (
                                <Tooltip
                                    placement="bottom-end"
                                    className="note-tooltip"
                                    customIcon={
                                        <Button
                                            ariaLabel="Show notes"
                                            variant="tertiary"
                                            icon="communication-message_list__speaker_notes_b_s"
                                            iconSize="sm"
                                            padding="sm"
                                            onClick={() => onShowNoteClick(item.idInputLog, item.idModel, item.idStudyLog, noteType)}
                                        />
                                    }
                                >
                                    {`Last entry by ${formatFullName(item.noteFirstName, item.noteLastName)} on ${toLocaleDateTime(
                                        `${item.notesLastUpdateDate}Z`
                                    )}`}
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    };

    // Main render

    return (
        <div className="flex-column flex-one-in-column justify-space-between scenario-log">
            {isEmpty(logItems) ? (
                <NothingFoundBlock icon="date&time-event_note_b_s" title="There are no actions done" />
            ) : (
                <CustomList
                    // @ts-ignore - remove when CustomList is refactored to TypeScript
                    dataTestId={dataTestId}
                    headers={headers}
                    items={logItems}
                    limit={4}
                    pageDisplayCount={3}
                    renderSearch={isProjectionView ? renderProjectionFilters : renderProjectFilters}
                    renderItem={isProjectionView ? renderProjectionLogItem : renderProjectLogItem}
                    sortable
                    paginationAlignToRight
                />
            )}
        </div>
    );
});

export default ScenarioLog;
