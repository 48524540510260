///
/// Model Study Case actions
/// resourceName: studyCaseModelResourceName
///
import { isNil } from "lodash";
import { batch } from "react-redux";

import { studyCaseModelResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";

import { clearCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";
import { clearAllLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { clearModelInputsProgress } from "store/resources/actions/modelInputs/modelInputsProgressActions";

import {
    StudyCaseResponseModel,
    ModelStudyCaseParams,
    ClearAllModelStudyCasesParams,
    ClearSpecificModelStudyCaseParams,
    UpdateModelStudyCaseParams,
    GetResourceParams,
} from "./types";

const getResourceParams = ({ idProject, idInputLog, updateItems, onComplete, dispatch }: GetResourceParams) => ({
    resourceName: studyCaseModelResourceName,
    key: `${studyCaseModelResourceName}-${idProject}-${idInputLog}`,
    path: idInputLog ? { idInputLog } : undefined,
    body: {
        parameters: updateItems,
    },
    showSuccessNotification: false,
    updateFromResponse: true,
    onComplete: () => {
        batch(() => {
            dispatch(clearModelStudyCase({ idProject, idInputLog }));
            dispatch(clearCalculationPreconditions({ idProject }));
            dispatch(clearModelInputsProgress({ idProject }));

            if (idInputLog) {
                dispatch(clearModelLogItems({ idProject, idInputLog }));
                dispatch(clearModelInputsProgress({ idProject, idInputLog }));
            }
        });

        onComplete?.();
    },
});

export const useModelStudyCase = ({ idProject, idInputLog }: ModelStudyCaseParams) =>
    useResource({
        resourceName: studyCaseModelResourceName,
        key: isNil(idInputLog) ? undefined : `${studyCaseModelResourceName}-${idProject}-${idInputLog}`,
        path: {
            idInputLog,
        },
        transform: (data: StudyCaseResponseModel) => data?.model || [],
    });

const clearAllModelStudyCases =
    ({ idProject }: ClearAllModelStudyCasesParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: studyCaseModelResourceName,
                key: new RegExp(`^${studyCaseModelResourceName}-${idProject}(-[0-9]+){0,1}$`),
                broadcast: true,
            })
        );
    };

const clearSpecificModelStudyCase =
    ({ idProject, idInputLog }: ClearSpecificModelStudyCaseParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: studyCaseModelResourceName,
                key: `${studyCaseModelResourceName}-${idProject}-${idInputLog}`,
                broadcast: true,
            })
        );
    };

export const clearModelStudyCase =
    ({ idProject, idInputLog }: ModelStudyCaseParams) =>
    // @ts-ignore
    (dispatch) => {
        if (idInputLog === undefined) {
            batch(() => {
                dispatch(clearAllModelStudyCases({ idProject }));
                dispatch(clearAllLogItems({ idProject }));
            });
        } else {
            batch(() => {
                dispatch(clearSpecificModelStudyCase({ idProject, idInputLog }));
                dispatch(clearModelLogItems({ idProject, idInputLog }));
            });
        }
    };

export const updateModelStudyCase =
    ({ idProject, idInputLog, values, onComplete }: UpdateModelStudyCaseParams) =>
    // @ts-ignore
    (dispatch) => {
        const parameters: any =
            values.ECONOMIC_TEST === undefined
                ? values
                : {
                      ...values,
                      ECONOMIC_TEST: JSON.stringify([values.ECONOMIC_TEST]),
                  };

        dispatch(
            updateResource(
                getResourceParams({
                    idProject,
                    idInputLog,
                    updateItems: parameters,
                    onComplete,
                    dispatch,
                })
            )
        );
    };
