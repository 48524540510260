///
/// Project Files actions
/// resourceName: projectFilesResourceName, projectFileResourceName
///
import { batch } from "react-redux";
import { toast } from "react-toastify";

import {
    projectFilesResourceName,
    projectFileAccessResourceName,
    projectFileTypesResourceName,
    projectFileResourceName,
} from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { getResourcePromise } from "store/resources/actions/getResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { clearUploadedProjectFiles } from "./uploadedProjectFilesActions";

import {
    ProjectFile,
    ProjectFileAttributesList,
    ProjectFileParams,
    GetProjectFileParams,
    CreateProjectFileParams,
    DeleteProjectFileParams,
} from "./types";

export const useProjectFileSystem = ({ idProject }: ProjectFileParams) =>
    useResource({
        resourceName: projectFilesResourceName,
        key: `${projectFilesResourceName}-${idProject}`,
        path: {
            idProject,
        },
    }) as [ProjectFile[], boolean | undefined, any];

export const useProjectFileAccess = () =>
    useResource({
        resourceName: projectFileAccessResourceName,
        key: projectFileAccessResourceName,
    }) as [ProjectFileAttributesList[], boolean | undefined, any];

export const useProjectFileTypes = () =>
    useResource({
        resourceName: projectFileTypesResourceName,
        key: projectFileTypesResourceName,
    }) as [ProjectFileAttributesList[], boolean | undefined, any];

// TODO: Add type
export const getProjectFile = async ({ idProject, filePath }: GetProjectFileParams) =>
    (await getResourcePromise({
        resourceName: projectFileResourceName,
        key: `${projectFileResourceName}-${idProject}`,
        path: {
            idProject,
        },
        query: {
            path: filePath,
        },
    })) as any;

export const createProjectFile =
    ({ idProject, formData, onStart, onSuccess, onError }: CreateProjectFileParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            createResource({
                resourceName: projectFilesResourceName,
                key: `${projectFilesResourceName}-${idProject}`,
                body: formData,
                updateFromResponse: false,
                showSuccessNotification: false,
                onStart,
                onSuccess: (action) => {
                    onSuccess?.(action);

                    batch(() => {
                        dispatch(clearProjectFileSystem({ idProject }));
                        dispatch(clearUploadedProjectFiles({ idProject }));
                    });
                },
                onError,
            })
        );

export const deleteProjectFile =
    ({ idProject, path, fileName, fileType }: DeleteProjectFileParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            deleteResource({
                resourceName: projectFileResourceName,
                path: { idProject },
                query: {
                    path,
                    fileName,
                    fileType,
                },
                onSuccess: (action) => {
                    batch(() => {
                        dispatch(clearProjectFileSystem({ idProject }));
                        dispatch(clearUploadedProjectFiles({ idProject }));
                    });

                    action?.data.responseMessage && toast.success(action.data.responseMessage);
                },
            })
        );

const clearProjectFileSystem =
    ({ idProject }: ProjectFileParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: projectFilesResourceName,
                key: `${projectFilesResourceName}-${idProject}`,
                broadcast: true,
            })
        );
