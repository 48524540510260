import { RUN_ALL_MODULES_START, RUN_MODULE_START, PAUSE_MODULE_START } from "store/actionTypes";

import { runAllModules, runModule, pauseModule } from "./actions";

import { RunModuleAction } from "./types";

export const runModuleMiddleware =
    // @ts-ignore
    ({ dispatch }) => {
        return (next: any) => (action: RunModuleAction) => {
            switch (action.type) {
                case RUN_ALL_MODULES_START:
                    {
                        const { idProject, idModel, runList, onStart, onError } = action.payload;
                        dispatch(
                            runAllModules({
                                idProject,
                                idModel,
                                runList,
                                onStart,
                                onError,
                            })
                        );
                    }

                    break;

                case RUN_MODULE_START:
                    {
                        const { idProject, idModel, moduleId, onStart, onError } = action.payload;

                        dispatch(
                            runModule({
                                idProject,
                                idModel,
                                moduleId,
                                onStart,
                                onError,
                            })
                        );
                    }

                    break;

                case PAUSE_MODULE_START:
                    {
                        const { idProject, idModel, moduleId, resultsId, onStart, onError } = action.payload;

                        dispatch(
                            pauseModule({
                                idProject,
                                idModel,
                                moduleId,
                                resultsId,
                                onStart,
                                onError,
                            })
                        );
                    }

                    break;
                default:
            }

            return next(action);
        };
    };
