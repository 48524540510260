import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import cn from "classnames";

import ProjectVariables from "./ProjectVariables";
import ModelVariables from "./ModelVariables";
import ModuleVariables from "./ModuleVariables";

import { variableValuesChanged } from "utils/variables";

import { IndexSignature, UpdateVariableAction } from "types/types";
import { VariablesTransformed } from "store/resources/actions/variables/types";
import { ModuleVariableTransformed } from "store/resources/actions/calculationWorkflow/types";
import { ModelState } from "store/resources/actions/projectInput/types";
import { ComponentModelState } from "store/resources/actions/componentModel/types";

import "./style.scss";

const Variables = memo((props: VariablesProps) => {
    const {
        values,
        projectVariables,
        modelVariables,
        moduleVariables,
        variablesSavedOnProject,
        differsFromProject,
        editing,
        warningMessage,
        infoMessage,
        variablesLevel,
        modelState,
        modelRequired,

        setValues,
        fieldDiffersFromProject,
        onSave,
        onEditCancel,
    } = props;

    // State

    const [showSave, setShowSave] = useState(false);

    useEffect(() => {
        let areValuesChanged = false;

        if (!isEmpty(values)) {
            if (projectVariables && variablesLevel === "project") {
                areValuesChanged = variableValuesChanged(values, projectVariables);
            } else if (modelVariables && variablesLevel === "model") {
                areValuesChanged = variableValuesChanged(values, modelVariables);
            } else if (moduleVariables && variablesLevel === "module") {
                areValuesChanged = variableValuesChanged(values, moduleVariables);
            }
        }

        setShowSave(areValuesChanged);
    }, [values, projectVariables, modelVariables, moduleVariables, variablesLevel]);

    // Event handlers

    const onChange = useCallback(
        (value, name) => {
            let newValues = {};

            newValues = {
                ...values,
                [name]: value,
            };

            setValues(newValues);
        },
        [values, setValues]
    );

    const _onSave = useCallback(
        (action?: UpdateVariableAction) => {
            setShowSave(false);

            onSave(action);
            onEditCancel?.();
        },
        [onSave, onEditCancel]
    );

    const onCancel = useCallback(() => {
        setValues({});

        setShowSave(false);

        onEditCancel?.();
    }, [setValues, onEditCancel]);

    // Main render

    return (
        <div
            className={cn("variables", props.className, {
                "project-variables": variablesLevel === "project",
                "model-variables": variablesLevel === "model",
                "module-variables": variablesLevel === "module",
            })}
        >
            {variablesLevel === "project" && projectVariables && (
                <ProjectVariables
                    values={values}
                    projectVariables={projectVariables}
                    variablesSavedOnProject={variablesSavedOnProject || false}
                    editing={editing}
                    showSave={showSave}
                    warningMessage={warningMessage}
                    infoMessage={infoMessage}
                    onChange={onChange}
                    onSave={_onSave}
                    onCancel={onCancel}
                />
            )}

            {variablesLevel === "model" && modelVariables && modelState && modelRequired !== undefined && (
                <ModelVariables
                    values={values}
                    modelVariables={modelVariables}
                    differsFromProject={differsFromProject}
                    editing={editing}
                    showSave={showSave}
                    warningMessage={warningMessage}
                    infoMessage={infoMessage}
                    modelState={modelState}
                    modelRequired={modelRequired}
                    fieldDiffersFromProject={fieldDiffersFromProject}
                    onChange={onChange}
                    onSave={_onSave}
                    onCancel={onCancel}
                />
            )}

            {variablesLevel === "module" && moduleVariables && (
                <ModuleVariables
                    values={values}
                    moduleVariables={moduleVariables}
                    differsFromProject={differsFromProject}
                    editing={editing}
                    showSave={showSave}
                    warningMessage={warningMessage}
                    infoMessage={infoMessage}
                    modelRequired={modelRequired}
                    fieldDiffersFromProject={fieldDiffersFromProject}
                    onChange={onChange}
                    onSave={_onSave}
                />
            )}
        </div>
    );
});

export interface CommonVariablesProps {
    /**
     * Variable values.
     */
    values: IndexSignature<string>;

    /**
     * Edit mode.
     */
    editing?: boolean;

    /**
     * Warning message is being displayed only during editing and
     * is displayed above info message.
     */
    warningMessage?: string;

    /**
     * Informative message.
     */
    infoMessage?: string;

    /**
     * Function to check if Model Variable field
     * differs from Project Variable.
     */
    fieldDiffersFromProject?: (fieldName: string) => boolean;

    /**
     * Function to run on save action.
     */
    onSave: (action?: UpdateVariableAction) => void;
}

export interface VariablesProps extends CommonVariablesProps {
    className?: string;

    /**
     * An array of Project Variables.
     */
    projectVariables?: VariablesTransformed[][];

    /**
     * An array of Model Variables.
     */
    modelVariables?: VariablesTransformed[][];

    /**
     * An array of Module Variables.
     */
    moduleVariables?: ModuleVariableTransformed[][];

    /**
     * Variables saved on Project-level.
     */
    variablesSavedOnProject?: boolean;

    /**
     * Model Variables differ from Project Variables.
     */
    differsFromProject?: boolean;

    /**
     * Variables have few nesting levels:
     *  - Project-level
     *      - Model-level
     *          - Module-level
     */
    variablesLevel: "project" | "model" | "module";

    /**
     * Status of the Model.
     */
    modelState?: ModelState | ComponentModelState;

    /**
     * Model required for Project.
     */
    modelRequired?: boolean | null;

    /**
     * Sets variable values.
     */
    setValues: (values: IndexSignature<string>) => void;

    /**
     * Function to run when canceling edit.
     */
    onEditCancel?: () => void;
}

export default Variables;
