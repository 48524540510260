import { Markup as InterweaveMarkup } from "interweave";
import ClassNames from "classnames";

import "./Markup.scss";

/**
 * Wrapper component for Interweave which includes custom styling.
 *
 * More information: https://interweave.dev/
 */
const Markup = (props: MarkupProps) => {
    const classNames = ClassNames("interweave-markup", props.className);

    return <InterweaveMarkup className={classNames} content={props.content} />;
};

interface MarkupProps {
    className?: string;

    content?: string | null;
}

export default Markup;
