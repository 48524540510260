import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { createModule } from "store/resources/actions/calculationWorkflow/modulesActions";
import { useModuleStudyCases } from "store/resources/actions/calculationWorkflow/moduleStudyCasesActions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import IconLoading from "components/ui/Icons/IconLoading";
import Separator from "components/ui/Separator";

import ModuleStudyCases from "layouts/common/ModuleStudyCases";
import { openModalDialogAddPotentialModule } from "layouts/Modal/CalculationWorkflowModal/utils";

import { moduleTypes } from "utils/constants";

import { AddModuleProps } from "layouts/Modal/CalculationWorkflowModal/types";

import "./style.scss";

const AddModule = memo(({ idProject, idModel, idInputLog, type, modules, goBack, onCancel }: AddModuleProps) => {
    const dispatch = useDispatch();

    let buttonText = "Add Baseline Module";

    if (type === moduleTypes.EE_POTENTIAL) {
        buttonText = "Add EE Potential Module";
    } else if (type === moduleTypes.DR_POTENTIAL) {
        buttonText = "Add DR Potential Module";
    }

    const [moduleStudyCases, isLoadingModuleStudyCases] = useModuleStudyCases({ type });

    const [selectedCase, setSelectedCase] = useState(0);
    const [selectedParent, setSelectedParent] = useState(type === moduleTypes.DR_POTENTIAL ? 0 : undefined);

    const eePotentialItems = useMemo(() => {
        let eePotentialItems: any[] = [];

        if (type === moduleTypes.DR_POTENTIAL) {
            const filterdModules = modules.filter((module) => module.type === moduleTypes.EE_POTENTIAL);

            eePotentialItems = filterdModules.map((module) => ({
                label: `${module.moduleOrder} | ${module.studyCase}`,
                value: module.moduleId,
            }));
        }

        return eePotentialItems;
    }, [type, modules]);

    useEffect(() => {
        if (!isEmpty(moduleStudyCases) && !isLoadingModuleStudyCases && selectedCase === 0) {
            setSelectedCase(moduleStudyCases[0].studyCaseId);
        }
    }, [moduleStudyCases, isLoadingModuleStudyCases, selectedCase]);

    const onCaseChange = useCallback((event) => {
        setSelectedCase(parseInt(event.target.value));
    }, []);

    const onParentChange = useCallback((value) => {
        setSelectedParent(value);
    }, []);

    const onAddModuleClick = useCallback(() => {
        switch (type) {
            case moduleTypes.BASELINE:
                {
                    let uiOrder = 1;

                    const baselineModules = modules.filter((module) => module.type === type);

                    if (!isEmpty(baselineModules)) {
                        const lastModuleOrder = baselineModules.reduce((moduleOrder, currentValue) => {
                            // Find the latest Module order (largest value)
                            if (moduleOrder < Number(currentValue.moduleOrder)) {
                                moduleOrder = Number(currentValue.moduleOrder);
                            }

                            return moduleOrder;
                        }, 0);

                        uiOrder = lastModuleOrder + 1;
                    }

                    dispatch(
                        createModule({
                            idProject,
                            idModel,
                            idInputLog,
                            studyCaseId: selectedCase,
                            uiOrder,
                            type,
                        })
                    );
                }

                break;

            case moduleTypes.EE_POTENTIAL:
                {
                    const baselineModule = modules.find((module) => module.type === moduleTypes.BASELINE);

                    dispatch(
                        createModule({
                            idProject,
                            idModel,
                            idInputLog,
                            studyCaseId: selectedCase,
                            moduleParentId: baselineModule?.moduleId,
                            uiOrder: Number(baselineModule?.moduleOrder),
                            type,
                        })
                    );
                }

                break;
            case moduleTypes.DR_POTENTIAL:
                {
                    const potentialModule = modules.find((module) => module.moduleId === selectedParent);

                    dispatch(
                        createModule({
                            idProject,
                            idModel,
                            idInputLog,
                            studyCaseId: selectedCase,
                            moduleParentId: selectedParent,
                            uiOrder: Math.floor(Number(potentialModule?.moduleOrder)), // temp code
                            type,
                        })
                    );
                }

                break;

            default:
        }

        onCancel?.();
    }, [idProject, idModel, idInputLog, type, modules, selectedCase, selectedParent, onCancel, dispatch]);

    const onBackClick = useCallback(() => {
        dispatch(
            openModalDialogAddPotentialModule({
                idProject,
                idModel,
                idInputLog,
                modules,
            })
        );
    }, [idProject, idModel, idInputLog, modules, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding module-add">
                {isLoadingModuleStudyCases ? (
                    <IconLoading />
                ) : (
                    <ModuleStudyCases selectedCase={selectedCase} studyCases={moduleStudyCases} type={type} onCaseChange={onCaseChange} />
                )}
                {type === moduleTypes.DR_POTENTIAL && (
                    <>
                        <Separator line />
                        <Dropdown
                            label="EE Potential Case"
                            placeholder="Select from the list"
                            value={selectedParent}
                            items={eePotentialItems}
                            onChange={(value) => onParentChange(value)}
                        />
                    </>
                )}
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                {
                    // Render button when Module Study Cases loaded up (BASELINE, EE_POTENTIAL)
                    ((!isLoadingModuleStudyCases && type !== moduleTypes.DR_POTENTIAL) ||
                        // Render button when Module Study Cases loaded up and parent Module is selected (DR_POTENTIAL)
                        (!isLoadingModuleStudyCases && type === moduleTypes.DR_POTENTIAL && selectedParent !== 0)) && (
                        <Button ariaLabel="Add button" variant="primary" padding="md" onClick={onAddModuleClick}>
                            {buttonText}
                        </Button>
                    )
                }
                {goBack ? (
                    <Button ariaLabel="Back button" variant="secondary" padding="md" onClick={onBackClick}>
                        Back
                    </Button>
                ) : (
                    <Button ariaLabel="Cancel button" variant="secondary" padding="md" onClick={onCancel}>
                        Cancel
                    </Button>
                )}
            </IdsButtonGroup>
        </>
    );
});

export default AddModule;
