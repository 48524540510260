import { isEmpty } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { downloadProjectFile } from "store/downloadFile/actions";
import { deleteProjectFile, useProjectFileSystem } from "store/resources/actions/projectFiles/projectFilesActions";

import Button from "components/ui/ButtonNew";
import IconLoading from "components/ui/Icons/IconLoading";
import DashboardPanel from "components/ui/Dashboard";
import FileNavigator from "components/ui/FileNavigator";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import { openModalDialogUploadProjectFile } from "layouts/Modal/ProjectDashboardModal/utils";
import { openModalDialogCustomActions } from "layouts/Modal/utils";

import { projectReadOnlyEnabled } from "pages/utils";

import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { FileType } from "components/ui/FileNavigator/types";
import { FileNavigatorPanelProps } from "./types";

const getFilePath = (folderChain: FileType[] = []) => {
    const folders = [...folderChain];

    folders.shift();

    return folders.map((folder) => folder.name).join("/");
};

const FileNavigatorPanel = memo(({ project, clientName }: FileNavigatorPanelProps) => {
    const { idProject, projectState } = project;

    const dispatch = useDispatch();

    const [projectFileSystem, isLoadingProjectFileSystem] = useProjectFileSystem({ idProject });

    const title = `${clientName} Files`;

    const [rootFolderId, fileMap] = useMemo(() => {
        let rootFolderId;
        let fileMap = {};

        if (!isEmpty(projectFileSystem) && !isLoadingProjectFileSystem) {
            rootFolderId = projectFileSystem.find((data) => data.name === "root")?.id;

            for (const file of projectFileSystem) {
                const { name, isDirectory, ...rest } = file;
                let fileName = name === "root" ? `${clientName} Files` : name;

                fileMap = {
                    ...fileMap,
                    [file.id]: {
                        ...rest,
                        name: fileName,
                        isDir: isDirectory,
                    },
                };
            }
        }

        return [rootFolderId, fileMap];
    }, [clientName, projectFileSystem, isLoadingProjectFileSystem]);

    const onUploadFileClick = useCallback(() => {
        dispatch(
            openModalDialogUploadProjectFile({
                idProject,
                clientName,
            })
        );
    }, [idProject, clientName, dispatch]);

    const onDownloadClick = useCallback(
        async (params: FileType) => {
            const path = getFilePath(params.folderChain);
            const fileName = `${params.name}${params.extension}`;

            dispatch(
                downloadProjectFile({
                    id: params.id,
                    idProject,
                    path,
                    fileName,
                    projectFileType: params.type,
                })
            );
        },
        [idProject, dispatch]
    );

    const onDeleteConfirm = useCallback(
        (params: FileType) => {
            const path = getFilePath(params.folderChain);
            const fileName = `${params.name}${params.extension}`;

            dispatch(
                deleteProjectFile({
                    idProject,
                    path,
                    fileName,
                    fileType: params.type,
                })
            );
        },
        [idProject, dispatch]
    );

    const onDeleteClick = useCallback(
        (params: FileType) => {
            dispatch(
                openModalDialogCustomActions({
                    title: `Delete ${params.name}`,
                    text: <div>Are you sure you want to delete this file?</div>,
                    actions: [
                        {
                            icon: "ui-delete_forever_b_s",
                            primary: true,
                            danger: true,
                            children: "Delete permanently",
                            onClick: () => {
                                onDeleteConfirm(params);
                            },
                        },
                        {
                            children: "Cancel",
                        },
                    ],
                })
            );
        },
        [onDeleteConfirm, dispatch]
    );

    const headerAction =
        !projectReadOnlyEnabled(projectState) && hasAnyOfPermissions([USER_ACTIONS.PROJECT_FILES_ADD]) ? (
            <Button variant="tertiary" padding="md" iconLeft="arrows-upload_b_a" iconSize="sm" onClick={onUploadFileClick}>
                Upload File
            </Button>
        ) : undefined;

    return (
        <DashboardPanel title={title} headerAction={headerAction}>
            {isLoadingProjectFileSystem ? (
                <IconLoading />
            ) : (
                <div>
                    {rootFolderId === undefined || isEmpty(fileMap) ? (
                        <div>
                            <NothingFoundBlock icon="files-folder_opened_b_s" title="There are no saved client files" />
                        </div>
                    ) : (
                        <FileNavigator
                            rootFolderId={rootFolderId}
                            fileMap={fileMap}
                            onDownload={onDownloadClick}
                            onDelete={hasAnyOfPermissions([USER_ACTIONS.PROJECT_FILES_DELETE]) ? onDeleteClick : undefined}
                        />
                    )}
                </div>
            )}
        </DashboardPanel>
    );
});

export default FileNavigatorPanel;
