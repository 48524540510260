import { cloneElement } from "react";

import { modalOpen, modalClose } from "store/modal/actions";

import UploadProgress from "./common/UploadProgress";
import CustomActions from "./common/CustomActions";

import {
    ModalDialogParams,
    ModalDialogUploadProgressParams,
    ModalDialogCustomActionsParams,
    ModalDialogDeleteProjectionParams,
    ModalDialogRestoreProjectionParams,
    ModalDialogDeletePermanentlyProjectionParams,
} from "./types";

export const openModalDialog =
    (params: ModalDialogParams) =>
    // @ts-ignore
    (dispatch) => {
        const loading = () => {
            dispatch(
                modalOpen({
                    modalType: "WAITING_MODAL",
                    modalProps: {
                        title: params.title,
                    },
                })
            );
        };

        const onCancel = () => {
            dispatch(modalClose());
        };

        const Component = cloneElement(params.component, { onLoading: loading, onCancel });

        let modalClassName = "modal-sm";

        if (params.size === "medium") {
            modalClassName = "modal-md";
        } else if (params.size === "large") {
            modalClassName = "modal-lg";
        } else if (params.size === "fill") {
            modalClassName = "modal-fill";
        }

        loading();

        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: params.title,
                    modalIcon: params.modalIcon,
                    errorIcon: params.error,
                    overlayClassName: "modal-styled",
                    className: modalClassName,
                    noFooter: params.noFooter || true,
                    withScroll: params.withScroll || true,
                    highlightCustomList: params.highlightCustomList,
                    children: Component,
                },
            })
        );
    };

export const openModalDialogUploadProgress =
    ({ resourceId, resourceName, message, title }: ModalDialogUploadProgressParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title,
                size: "medium",
                component: <UploadProgress resourceId={resourceId} resourceName={resourceName} message={message} />,
            })
        );
    };

export const openModalDialogCustomActions =
    ({ title, modalIcon, error, size, text, actions }: ModalDialogCustomActionsParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title,
                modalIcon,
                error,
                size,
                component: <CustomActions text={text} actions={actions} />,
            })
        );
    };

export const openModalDialogDeleteProjection =
    ({ title, projectionName, onMove, onDelete }: ModalDialogDeleteProjectionParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title,
                size: "medium",
                text: (
                    <>
                        <div>
                            For data security reasons, we recommend moving <strong>'{projectionName}'</strong> to the Trash Bin.
                        </div>
                        <div>This allows you to find it there and, if necessary, restore it in the future.</div>
                    </>
                ),
                actions: [
                    {
                        primary: true,
                        children: "Move to Trash Bin",
                        onClick: onMove,
                    },
                    {
                        danger: true,
                        icon: "ui-delete_forever_b_s",
                        children: "Delete Permanently",
                        onClick: onDelete,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };

export const openModalDialogRestoreProjection =
    ({ title, text, onRestore }: ModalDialogRestoreProjectionParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title,
                text,
                actions: [
                    {
                        primary: true,
                        children: "Restore",
                        onClick: onRestore,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };

export const openModalDialogDeletePermanentlyProjection =
    ({ title, projectionName, onDelete }: ModalDialogDeletePermanentlyProjectionParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title,
                text: (
                    <div>
                        <strong>{projectionName}</strong> will be deleted permanently.
                    </div>
                ),
                actions: [
                    {
                        primary: true,
                        danger: true,
                        icon: "ui-delete_forever_b_s",
                        children: "Delete Permanently",
                        onClick: onDelete,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };
