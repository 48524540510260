import { memo, useCallback } from "react";

import Dropdown from "components/ui/Dropdown";
import CheckboxCard from "components/ui/Input/CheckboxCard";

import { ModuleStudyCaseVariablesProps } from "./types";

import "./style.scss";

const ModuleStudyCaseVariables = memo(({ values, moduleVariables, editing, onChange }: ModuleStudyCaseVariablesProps) => {
    const onAchievableChange = useCallback(
        (name: string) => (event: any) => {
            const value = event.target.checked ? "Yes" : "No";

            onChange(value, name);
        },
        [onChange]
    );

    return (
        <>
            <div className="flex-column module-study-case-variables">
                {moduleVariables?.map((item, i) => (
                    <div key={`module-variable-${i}`}>
                        {item.map((it, j) => (
                            <div key={`module-variable-${it.name}-${i}-${j}`}>
                                {it.name === "Economic Test" && (
                                    <Dropdown
                                        className="module-study-case-variables__economic-test"
                                        label={it.uiLabel as string}
                                        ghost={!editing}
                                        value={values?.[it.name] || it.value || ""}
                                        items={it.values}
                                        onChange={(value) => onChange(value, it.name)}
                                    />
                                )}
                                {it.name === "Include Achievable" && (
                                    <CheckboxCard
                                        className="module-study-case-variables__include-achievable"
                                        id={it.name}
                                        name={it.name}
                                        label={it.uiLabel}
                                        checked={values?.[it.name] !== undefined ? values?.[it.name] === "Yes" : it.value === "Yes"}
                                        readOnly={!editing}
                                        onChange={onAchievableChange(it.name)}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    );
});

export default ModuleStudyCaseVariables;
