import { isEqual, isEmpty, uniqBy } from "lodash";
import { batch } from "react-redux";

import {
    WINDOW_CONTAINER_ADD,
    WINDOW_CONTAINER_REMOVE,
    WINDOW_ADD,
    WINDOW_UPDATE,
    WINDOW_REMOVE,
    WINDOW_ACTIVATE,
} from "store/actionTypes";

import { createId } from "utils/string";
import { EMPTY_SPLIT_VIEW_NAME, EMPTY_SPLIT_VIEW_TITLE, setWindowState } from "utils/window";

import {
    Window,
    View,
    Container,
    HideSelectedItemsParams,
    SetActiveViewParams,
    WindowContainerParams,
    WindowParams,
    WindowActivateParams,
    WindowAddSplitParams,
    WindowRemoveParams,
    WindowClearSplitParams,
} from "./types";

/**
 * Helper function that sets "hidden" property for
 * a dropdown item to true and returns the list.
 *
 * NOTE: Only used in split view cases where window
 * headers are Dropdown components and cannot allow
 * user to select the same view for both sides.
 */
const hideSelectedItems = ({ itemId, dropdownItems }: HideSelectedItemsParams) => {
    return dropdownItems.map((item) => ({
        ...item,
        hidden: item.value === itemId,
    }));
};

const setActiveView = ({ views, container }: SetActiveViewParams) => {
    const previousActive = container.previousActive;

    if (previousActive && views.filter((view: View) => view.name === previousActive.name).length > 0) {
        views.forEach((view: View) => {
            view.active = view.name === previousActive.name;

            return view;
        });
    } else {
        views.forEach((view: View, index: number) => {
            view.active = index === 0;

            return view;
        });
    }
};

// WINDOW CONTAINER ACTIONS

export const windowContainerAdd =
    ({ containerName }: WindowContainerParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        if (!getState().window[containerName]) {
            dispatch({
                type: WINDOW_CONTAINER_ADD,
                window: containerName,
            });
        }
    };

export const windowContainerReset =
    ({ containerName }: WindowContainerParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        if (getState().window[containerName]) {
            batch(() => {
                dispatch({
                    type: WINDOW_CONTAINER_REMOVE,
                    window: containerName,
                });

                dispatch({
                    type: WINDOW_CONTAINER_ADD,
                    window: containerName,
                });
            });
        }
    };

// WINDOW ACTIONS

export const windowActivate =
    ({ name, containerName, persist = true }: WindowActivateParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        if (container && container.views && !isEmpty(container.views)) {
            const splitView = container.views.find((view) => view.splitView && view.active);
            const selectedView = container.views.find((view) => view.name === name);

            // Open in active split view
            if (
                splitView &&
                selectedView &&
                !selectedView.splitView &&
                selectedView.close &&
                (splitView.leftViewName === EMPTY_SPLIT_VIEW_NAME || splitView.rightViewName === EMPTY_SPLIT_VIEW_NAME)
            ) {
                let updatedView = { ...splitView };

                if (splitView.leftViewName === EMPTY_SPLIT_VIEW_NAME) {
                    updatedView = {
                        ...updatedView,
                        leftViewClassName: selectedView.className,
                        leftViewName: selectedView.name,
                        leftViewComponent: selectedView.component,
                        leftViewHeaderTitle: selectedView.headerTitle,
                        leftViewHeaderSubTitle: selectedView.headerSubTitle,
                        leftViewTabTitle: selectedView.tabTitle,
                        leftViewTabSubTitle: selectedView.tabSubTitle,
                        leftViewProps: selectedView.props,
                    };
                }
                if (splitView.rightViewName === EMPTY_SPLIT_VIEW_NAME) {
                    updatedView = {
                        ...updatedView,
                        rightViewClassName: selectedView.className,
                        rightViewName: selectedView.name,
                        rightViewComponent: selectedView.component,
                        rightViewHeaderTitle: selectedView.headerTitle,
                        rightViewHeaderSubTitle: selectedView.headerSubTitle,
                        rightViewTabTitle: selectedView.tabTitle,
                        rightViewTabSubTitle: selectedView.tabSubTitle,
                        rightViewProps: selectedView.props,
                    };
                }

                const views = container.views.filter((view) => view.name !== splitView.name).concat([updatedView]);

                dispatch({
                    type: WINDOW_ADD,
                    container: containerName,
                    views,
                });
            } else {
                const isActive =
                    container.views.filter(
                        (view) => view.active && (view.name === name || view.leftViewName === name || view.rightViewName === name)
                    ).length > 0;

                if (!isActive) {
                    const views = container.views.map((view) => ({
                        ...view,
                        active: view.name === name,
                    }));

                    const previousActive = container.views.find((view) => view.active && view.name !== name);

                    dispatch({
                        type: WINDOW_ACTIVATE,
                        container: containerName,
                        views,
                        previousActive,
                    });
                }
            }

            if (persist) {
                dispatch(windowPersist());
            }
        }
    };

export const windowPersist =
    () =>
    // @ts-ignore
    (dispatch, getState) => {
        setWindowState(getState().window);
    };

export const windowAdd =
    ({
        name,
        containerName,
        component,
        showHeader = true,
        showTabs = true,
        headerTitle,
        headerSubTitle,
        tabTitle,
        tabSubTitle,
        tabIcon,
        activate,
        close,
        persist = true,
        isSplitViewEnabled = false,
        splitView = false,
        props,
        onClose,
    }: Window) =>
    // @ts-ignore
    (dispatch, getState) => {
        dispatch(windowContainerAdd({ containerName }));

        const container: Container = getState().window[containerName];

        const existingView = container.views?.filter((view) => view.name === name)[0];

        // If View does not exist
        if (!existingView) {
            const view = {
                name,
                containerName,
                component,
                showHeader,
                showTabs,
                headerTitle,
                headerSubTitle,
                tabTitle,
                tabSubTitle,
                tabIcon,
                active: false,
                close,
                isSplitViewEnabled,
                splitView,
                props,

                onClose,
            };

            const views = ([] as View[]).concat(container.views, [view]);

            dispatch({
                type: WINDOW_ADD,
                container: containerName,
                views,
            });
        }
        // If View does already exist
        else {
            // Only dispatch WINDOW_ADD if props differ
            if (!isEqual(existingView.props, props)) {
                const views = container.views.map((view) => {
                    if (view.name === existingView.name) {
                        return {
                            ...view,
                            props,
                        };
                    }
                    if (view.leftViewName === existingView.name) {
                        return {
                            ...view,
                            leftViewProps: props,
                        };
                    }
                    if (view.rightViewName === existingView.name) {
                        return {
                            ...view,
                            rightViewProps: props,
                        };
                    }

                    return view;
                });

                dispatch({
                    type: WINDOW_ADD,
                    container: containerName,
                    views,
                });
            }
        }

        if (activate) {
            dispatch(windowActivate({ name, containerName, persist: false }));
        }

        if (persist) {
            dispatch(windowPersist());
        }
    };

export const windowUpdate =
    ({
        name,
        containerName,
        showHeader,
        showTabs,
        headerTitle,
        headerSubTitle,
        tabTitle,
        tabSubTitle,
        tabIcon,
        close,
        isSplitViewEnabled,
        props,
    }: Window) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        const existingView = container.views?.find((view) => view.name === name);

        if (existingView !== undefined) {
            const views = container.views.map((view) =>
                view.name === existingView.name
                    ? {
                          ...view,
                          showHeader: showHeader || view.showHeader,
                          showTabs: showTabs || view.showTabs,
                          headerTitle: headerTitle || view.headerTitle,
                          headerSubTitle: headerSubTitle || view.headerSubTitle,
                          tabTitle: tabTitle || view.tabTitle,
                          tabSubTitle: tabSubTitle || view.tabSubTitle,
                          tabIcon: tabIcon || view.tabIcon,
                          close: close || view.close,
                          isSplitViewEnabled: isSplitViewEnabled || view.isSplitViewEnabled,
                          props: props || view.props,
                      }
                    : view
            );

            dispatch({
                type: WINDOW_UPDATE,
                container: containerName,
                views,
            });
        }
    };

/**
 * Add a new window to the specified container with split view set to active.
 */
export const windowAddSplit =
    ({
        name,
        containerName,
        showHeader = true,
        showTabs = true,
        activate = true,
        persist = true,
        isSplitViewEnabled,
        leftView,
        rightView,
    }: WindowAddSplitParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        const viewExists = container.views?.find((view) => view.name === name) !== undefined;

        if (!viewExists) {
            const view = {
                name,
                containerName,
                showHeader,
                showTabs,
                close: true,
                splitView: true,
                isSplitViewEnabled,
                isSplitViewSync: false,

                leftViewClassName: leftView.className,
                leftViewName: leftView.name,
                leftViewComponent: leftView.component,
                leftViewHeaderTitle: leftView.headerTitle,
                leftViewHeaderSubTitle: leftView.headerSubTitle,
                leftViewTabTitle: leftView.tabTitle,
                leftViewTabSubTitle: leftView.tabSubTitle,
                leftViewTabIcon: leftView.tabIcon,
                leftViewProps: leftView.props,

                rightViewClassName: rightView.className,
                rightViewName: rightView.name,
                rightViewComponent: rightView.component,
                rightViewHeaderTitle: rightView.headerTitle,
                rightViewHeaderSubTitle: rightView.headerSubTitle,
                rightViewTabTitle: rightView.tabTitle,
                rightViewTabSubTitle: rightView.tabSubTitle,
                rightViewTabIcon: rightView.tabIcon,
                rightViewProps: rightView.props,
            };

            // If headers are Dropdown components do not allow
            // to select the same view
            if (leftView.props?.renderDropdownHeader && rightView.props?.renderDropdownHeader) {
                view.leftViewProps.dropdownItems = hideSelectedItems({
                    itemId: rightView.props[rightView.props.idSelector],
                    dropdownItems: view.leftViewProps.dropdownItems,
                });

                view.rightViewProps.dropdownItems = hideSelectedItems({
                    itemId: leftView.props[leftView.props.idSelector],
                    dropdownItems: view.rightViewProps.dropdownItems,
                });
            }

            const views = ([] as View[]).concat(container.views, [view]);

            dispatch({
                type: WINDOW_ADD,
                container: containerName,
                views,
            });
        }

        if (activate) {
            dispatch(windowActivate({ name, containerName, persist: false }));
        }

        if (persist) {
            dispatch(windowPersist());
        }
    };

export const windowRemove =
    ({ name, containerName, persist = true, shouldWindowRemain, onRemove }: WindowRemoveParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        if (container) {
            const view = container.views.filter((view) => view.name === name)[0];
            const parentSplitView = container.views.filter((view) => view.leftViewName === name || view.rightViewName === name)[0];

            // Remove split view
            if (!view && parentSplitView) {
                const views: View[] = [];

                container.views.forEach((view) => {
                    if (view.name === parentSplitView.name) {
                        view.isSplitViewSync = false;

                        if (parentSplitView.leftViewName === name) {
                            view.leftViewName = EMPTY_SPLIT_VIEW_NAME;
                            view.leftViewComponent = EMPTY_SPLIT_VIEW_NAME;
                            view.leftViewHeaderTitle = EMPTY_SPLIT_VIEW_TITLE;
                            view.leftViewTabSubTitle = EMPTY_SPLIT_VIEW_TITLE;
                            view.leftViewProps = undefined;
                        }
                        if (parentSplitView.rightViewName === name) {
                            view.rightViewName = EMPTY_SPLIT_VIEW_NAME;
                            view.rightViewComponent = EMPTY_SPLIT_VIEW_NAME;
                            view.rightViewHeaderTitle = EMPTY_SPLIT_VIEW_TITLE;
                            view.rightViewTabSubTitle = EMPTY_SPLIT_VIEW_TITLE;
                            view.rightViewProps = undefined;
                        }

                        onRemove?.();

                        if (
                            shouldWindowRemain ||
                            view.leftViewName !== EMPTY_SPLIT_VIEW_NAME ||
                            view.rightViewName !== EMPTY_SPLIT_VIEW_NAME
                        ) {
                            views.push(view);
                        }
                    } else {
                        views.push(view);
                    }
                });

                if (views.length !== container.views.length) {
                    setActiveView({ views, container });
                }

                dispatch({
                    type: WINDOW_REMOVE,
                    container: parentSplitView.containerName,
                    views,
                });
            }
            // Remove standard view
            else {
                view.onClose?.();

                const views = container.views.filter((view) => view.name !== name);

                setActiveView({ views, container });

                dispatch({
                    type: WINDOW_REMOVE,
                    container: containerName,
                    views,
                });
            }

            if (persist) {
                dispatch(windowPersist());
            }
        }
    };

export const windowSplit =
    ({ name, containerName }: WindowParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        if (container) {
            const views = container.views.map((view) => {
                if (view.name === name) {
                    view.clear = true;

                    view.splitView = true;
                    view.isSplitViewSync = false;

                    view.leftViewClassName = view.className;
                    view.leftViewName = view.name;
                    view.leftViewComponent = view.component;
                    view.leftViewHeaderTitle = view.headerTitle;
                    view.leftViewHeaderSubTitle = view.headerSubTitle;
                    view.leftViewTabTitle = view.tabTitle;
                    view.leftViewTabSubTitle = view.tabSubTitle;
                    view.leftViewProps = view.props;

                    view.rightViewClassName = undefined;
                    view.rightViewName = EMPTY_SPLIT_VIEW_NAME;
                    view.rightViewComponent = EMPTY_SPLIT_VIEW_NAME;
                    view.rightViewHeaderTitle = EMPTY_SPLIT_VIEW_TITLE;
                    view.rightViewTabSubTitle = EMPTY_SPLIT_VIEW_TITLE;
                    view.rightViewProps = undefined;

                    view.name = createId();
                }

                return view;
            });

            dispatch({
                type: WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

/**
 * Change view for a specified side of the window.
 */
export const windowChangeSplit =
    ({ name, containerName, side, view }: any) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        if (container) {
            const views = container.views.map((v) => {
                if (v.name === name) {
                    if (side === "left") {
                        v.leftViewClassName = view.className;
                        v.leftViewName = view.name;
                        v.leftViewComponent = view.component;
                        v.leftViewHeaderTitle = view.headerTitle;
                        v.leftViewTabSubTitle = view.tabSubTitle;
                        v.leftViewProps = view.props;

                        // If right side view has a header as Dropdown component,
                        // do not allow to set right side the same view
                        // as left side
                        if (v.rightViewProps?.renderDropdownHeader) {
                            v.rightViewProps.dropdownItems = hideSelectedItems({
                                itemId: view.props[view.props.idSelector],
                                dropdownItems: v.rightViewProps.dropdownItems,
                            });
                        }
                    } else {
                        v.rightViewClassName = view.className;
                        v.rightViewName = view.name;
                        v.rightViewComponent = view.component;
                        v.rightViewHeaderTitle = view.headerTitle;
                        v.rightViewTabSubTitle = view.tabSubTitle;
                        v.rightViewProps = view.props;

                        // If left side view has a header as Dropdown component,
                        // do not allow to set left side the same view
                        // as right side
                        if (v.leftViewProps?.renderDropdownHeader) {
                            v.leftViewProps.dropdownItems = hideSelectedItems({
                                itemId: view.props[view.props.idSelector],
                                dropdownItems: v.leftViewProps.dropdownItems,
                            });
                        }
                    }
                }

                return v;
            });

            dispatch({
                type: WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowRemoveSplit =
    ({ name, containerName }: WindowParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        if (container) {
            let newName = "";

            let views = container.views
                .map((view) => {
                    if (view.name === name) {
                        newName = (view.leftViewName === EMPTY_SPLIT_VIEW_NAME ? view.rightViewName : view.leftViewName) as string;

                        view.active = true;

                        view.splitView = false;
                        view.isSplitViewSync = false;

                        view.leftViewClassName = undefined;
                        view.leftViewName = undefined;
                        view.leftViewComponent = undefined;
                        view.leftViewHeaderTitle = undefined;
                        view.leftViewHeaderSubTitle = undefined;
                        view.leftViewTabTitle = undefined;
                        view.leftViewTabSubTitle = undefined;
                        view.leftViewProps = undefined;

                        view.rightViewClassName = undefined;
                        view.rightViewName = undefined;
                        view.rightViewComponent = undefined;
                        view.rightViewHeaderTitle = undefined;
                        view.rightViewHeaderSubTitle = undefined;
                        view.rightViewTabTitle = undefined;
                        view.rightViewTabSubTitle = undefined;
                        view.rightViewProps = undefined;

                        view.name = newName;
                    }

                    return view;
                })
                .map((view) => ({
                    ...view,
                    active: view.name === newName,
                }))
                .filter((view) => view.name !== EMPTY_SPLIT_VIEW_NAME);

            views = uniqBy(views, "name");

            if (!views.some((view) => view.active)) {
                setActiveView({ views, container });
            }

            dispatch({
                type: WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowClearSplit =
    ({ name, containerName, side }: WindowClearSplitParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        if (container) {
            const views = container.views.map((view) => {
                if (view.name === name) {
                    view.isSplitViewSync = false;

                    if (side === "left") {
                        view.leftViewClassName = undefined;
                        view.leftViewName = EMPTY_SPLIT_VIEW_NAME;
                        view.leftViewComponent = EMPTY_SPLIT_VIEW_NAME;
                        view.leftViewHeaderTitle = EMPTY_SPLIT_VIEW_TITLE;
                        view.leftViewTabSubTitle = EMPTY_SPLIT_VIEW_TITLE;
                        view.leftViewProps = undefined;
                    } else {
                        view.rightViewClassName = undefined;
                        view.rightViewName = EMPTY_SPLIT_VIEW_NAME;
                        view.rightViewComponent = EMPTY_SPLIT_VIEW_NAME;
                        view.rightViewHeaderTitle = EMPTY_SPLIT_VIEW_TITLE;
                        view.rightViewTabSubTitle = EMPTY_SPLIT_VIEW_TITLE;
                        view.rightViewProps = undefined;
                    }
                }

                return view;
            });

            dispatch({
                type: WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowSync =
    ({ name, containerName }: WindowParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const container: Container = getState().window[containerName];

        if (container) {
            const views = container.views.map((view) => {
                if (view.name === name && view.splitView) {
                    view.isSplitViewSync = !view.isSplitViewSync;
                }

                return view;
            });

            dispatch({
                type: WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };
