import { DOWNLOAD_FILE_START } from "store/actionTypes";

import { DownloadModelResultsParams, DownloadMultipleModelResultsParams, DownloadProjectFileParams } from "./types";

export const downloadModelResults =
    ({ idResultsLog, idInputLog }: DownloadModelResultsParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: DOWNLOAD_FILE_START,
            id: idResultsLog,
            fileType: "model-results",
            idInputLog,
        });
    };

export const downloadMultipleModelResults =
    ({ idResultsLogs, includedTables, onComplete }: DownloadMultipleModelResultsParams) =>
    (dispatch) => {
        dispatch({
            type: DOWNLOAD_FILE_START,
            id: idResultsLogs.join(","),
            fileType: "multiple-model-results",
            idResultsLogs,
            includedTables,
            onComplete,
        });
    };

export const downloadProjectFile =
    ({ id, idProject, path, fileName, projectFileType }: DownloadProjectFileParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: DOWNLOAD_FILE_START,
            id,
            fileType: "project-file",
            idProject,
            path,
            fileName,
            projectFileType,
        });
    };
