import { get, isNil, isEmpty } from "lodash";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as pbi from "powerbi-client";

import { projectInputsResourceName, projectsResourceName } from "store/configureResources";
import { refreshPowerBIDataset } from "store/powerBI/refreshAction/actions";

import Button from "components/ui/ButtonNew";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import LabelWithIcon from "components/ui/LabelWithIcon";

import { openModalDialogClientApprovementModule } from "layouts/Modal/CalculationWorkflowModal/utils";

import { usePowerBIReportsRefreshStatus } from "utils/usePowerBIReportsRefreshStatus";

import { PowerBIReportProps } from "./types";
import { Project } from "store/resources/actions/project/types";
import { ProjectInput } from "store/resources/actions/projectInput/types";

import "./style.scss";

const PowerBIReport = memo((props: PowerBIReportProps) => {
    const {
        idClient,
        idProject,
        idModel,
        idInputLog,
        embedId,
        embedUrl,
        accessToken,
        datasetId,
        isPaginated,
        pageName,
        showFilter = false,
        showApprovement = false,
        showPresentationMode = true,
        showRefresh = true,
        showPrint = true,
        hideActions = false,

        onDataSelected,
        onDataHyperlinkClicked,
        onButtonClicked,
    } = props;

    const dispatch = useDispatch();

    const clientProjects: Project[] =
        useSelector((state) => get(state, `resources.${projectsResourceName}.itemsById[${projectsResourceName}-${idClient}].data.model`)) ||
        [];

    const models: ProjectInput[] =
        useSelector((state) =>
            get(state, `resources.${projectInputsResourceName}.itemsById[${projectInputsResourceName}-${idProject}].data`)
        ) || [];

    // Ref

    const reportRef = useRef<HTMLDivElement>(null);

    // Variables

    const project = clientProjects?.find((project) => project.idProject === idProject);

    const model = models?.find((model) => model.idInputLog === idInputLog);

    const { isRefreshInProgress } = usePowerBIReportsRefreshStatus({ datasetId: showRefresh ? datasetId : undefined });

    // Do not show the navigation pane by default
    const navContentPaneEnabled = pageName ? false : props.showNavContentPane || false;

    let report: any = null;

    // useMemo

    const projectFuel = useMemo(() => {
        let projectFuel = "";

        if (project && !isNil(project.fuels) && !isEmpty(project.fuels)) {
            projectFuel = project.fuels[0].name;
        }

        return projectFuel;
    }, [project]);

    const modelFuel = useMemo(() => {
        let modelFuel = "";

        if (model && !isNil(model.fuel)) {
            modelFuel = model.fuel;
        }

        return modelFuel;
    }, [model]);

    // useEffect

    useEffect(() => {
        if (reportRef.current) {
            let defaultUnits = "Thrm";

            if (projectFuel === "Electric" || modelFuel === "Electric") {
                defaultUnits = "kWh";
            }

            let fuelFilter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "FUEL",
                    column: "FUEL",
                },
                operator: "In",
                values: [modelFuel],
                filterType: pbi.models.FilterType.Basic,
                requireSingleSelection: true,
            };

            let projectFuelFilter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "D_FUEL",
                    column: "Fuel",
                },
                operator: "In",
                values: [projectFuel],
                filterType: pbi.models.FilterType.Basic,
                requireSingleSelection: true,
            };

            let uomFilter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "UOM_CONVERSION",
                    column: "UOM_HOURS",
                },
                operator: "In",
                values: [defaultUnits],
                filterType: pbi.models.FilterType.Basic,
                requireSingleSelection: true,
            };

            let slicers = [
                {
                    selector: {
                        $schema: "http://powerbi.com/product/schema#visualSelector",
                        visualName: "e9b9e3b3e7fea1414e36",
                    },
                    state: {
                        filters: [fuelFilter],
                    },
                },
                {
                    selector: {
                        $schema: "http://powerbi.com/product/schema#visualSelector",
                        visualName: "1668243ad2623c15a6cf",
                    },
                    state: {
                        filters: [uomFilter],
                    },
                },
                {
                    selector: {
                        $schema: "http://powerbi.com/product/schema#visualSelector",
                        visualName: "451cc5dd2a89ec34024b",
                    },
                    state: {
                        filters: [projectFuelFilter],
                    },
                },
                {
                    selector: {
                        $schema: "http://powerbi.com/product/schema#visualSelector",
                        visualName: "509f0657ed15967f9a96",
                    },
                    state: {
                        filters: [uomFilter],
                    },
                },
            ];

            let config = {
                id: embedId,
                type: "report",
                embedUrl,
                accessToken,
                tokenType: pbi.models.TokenType.Embed,
                pageName,
                slicers,
                permissions: pbi.models.Permissions.Read,
                settings: {
                    navContentPaneEnabled,
                    filterPaneEnabled: showFilter || false,
                    hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
                },
            };

            // @ts-ignore
            // eslint-disable-next-line
            report = powerbi.embed(reportRef.current, config); // global Power BI object

            report.on("dataSelected", (event: any) => {
                onDataSelected?.(event);
            });

            report.on("dataHyperlinkClicked", (event: any) => {
                // const { url } = event.detail;

                // processHyperlink({ url });

                onDataHyperlinkClicked?.(event);
            });

            report.on("buttonClicked", (event: any) => {
                onButtonClicked?.(event);
            });

            // USE FOR DEBUGGING PURPOSES OR WHEN ADDING NEW SLICERS
            //
            // report.on("loaded", async () => {
            //     let pages = await report.getPages();
            //     let firstPage = pages[1];
            //     let visuals = await firstPage.getVisuals();

            //     console.log("VISUALS:", visuals);
            // })
        }

        return () => {
            if (report && reportRef.current) {
                // @ts-ignore
                // eslint-disable-next-line
                powerbi.reset(reportRef.current);
            }
        };
    }, [embedId, embedUrl, accessToken, pageName, navContentPaneEnabled, showFilter]);

    // Event handlers

    const onRefreshReportsClick = () => {
        if (datasetId) {
            dispatch(refreshPowerBIDataset({ datasetId }));
        }
    };

    const onPrintReportClick = () => {
        if (reportRef.current) {
            // @ts-ignore
            let report = powerbi.get(reportRef.current);

            report.print();
        }
    };

    const onPresentationModeClick = () => {
        if (reportRef.current) {
            // @ts-ignore
            let report = powerbi.get(reportRef.current);

            report.fullscreen();
        }
    };

    const onApproveClick = useCallback(() => {
        if (idProject && idInputLog && idModel) {
            dispatch(openModalDialogClientApprovementModule({ idProject, idInputLog, idModel }));
        }
    }, [idProject, idInputLog, idModel, dispatch]);

    // Main render

    return (
        <div className="fill-height report">
            {!hideActions && (
                <div className="flex-row align-center report-actions">
                    <span className="flex-one" />
                    {showApprovement && (
                        <div className="report-actions__client-approvement">
                            <Button
                                className="report-actions__client-approvement-btn"
                                variant="tertiary"
                                padding="xs"
                                onClick={onApproveClick}
                            >
                                Approve/Return Results
                            </Button>
                        </div>
                    )}

                    {showPresentationMode && (
                        <IconWithLabel icon="fullscreen" onClick={onPresentationModeClick}>
                            Presentation Mode
                        </IconWithLabel>
                    )}

                    {showRefresh && (
                        <div className="margin-left">
                            {isRefreshInProgress ? (
                                <div className="flex-row align-center">
                                    <LabelWithIcon
                                        className="report-actions__refresh"
                                        iconClassName="rotate"
                                        icon="arrows-sync__autorenew_b_a"
                                        iconSize="sm"
                                    >
                                        Refresh in progress, viewing old data
                                    </LabelWithIcon>
                                </div>
                            ) : (
                                <IconWithLabel icon="update-refresh" onClick={onRefreshReportsClick}>
                                    Refresh
                                </IconWithLabel>
                            )}
                        </div>
                    )}

                    {showPrint && !isPaginated && (
                        <IconWithLabel className="margin-left" icon="print" onClick={onPrintReportClick}>
                            Print
                        </IconWithLabel>
                    )}
                </div>
            )}
            <div key={embedId} className="report-body" ref={reportRef} />
        </div>
    );
});

export default PowerBIReport;
