import { openModalDialogCustomActions } from "layouts/Modal/utils";

import { ConfirmBulkEditParams } from "./types";

export const openModalDialogConfirmBulkEdit =
    ({ onConfirm }: ConfirmBulkEditParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Bulk Edit",
                text: (
                    <div>
                        You haven't validated and saved the previous bulk edit changes made to the data. By proceeding with another bulk
                        edit, you'll lose the changes done previously. Are you sure you want to bulk edit?
                    </div>
                ),
                actions: [
                    {
                        primary: true,
                        children: "Bulk Edit",
                        onClick: onConfirm,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };
