import { Component, Fragment } from "react";
import withReactAITracking from "./AppInsights";

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
    componentDidMount() {
        this.props.after && this.props.after();
    }

    render() {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

export default withReactAITracking(TelemetryProvider);
