import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
let initialized = false;
let ai;

try {
    const appInsightsConnectionString = process.env.REACT_APP_AI_CONNECTION_STRING;

    ai = new ApplicationInsights({
        config: {
            connectionString: appInsightsConnectionString,
            disableFetchTracking: false,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
            autoTrackPageVisitTime: true,
            // TODO: turn this off
            // enableAutoRouteTracking: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAjaxErrorStatusText: true,
            enableAjaxPerfTracking: true,
        },
    });
    ai.loadAppInsights();
    initialized = true;
} catch {
    console.log("Cannot initialize ApplicationInsights");
}

const withReactAITracking = (Component) => (initialized ? withAITracking(reactPlugin, Component) : Component);

export const appInsights = ai?.appInsights;
export { reactPlugin };
export default withReactAITracking;
