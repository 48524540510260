import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import ActionLabel from "components/ui/Label/ActionLabel";

import { openWindowProjectStakeholder } from "utils/window";

import { ProjectItemProps } from "pages/HomeDashboard/HomeDashboardDefault/common/ProjectList/types";

const ProjectStakeholder = memo(
    ({
        idClient,
        idProject,
        idManager,

        managerFullName,
        members,
        projectType,
        projectName,
        projectDescription,
        projectState,
        territories,
        fuels,
        targetDate,
        territoryStructureName,
        uploadFolder,
        uploadUrl,
        started,
        finished,
        bookmarked,

        clientName,
    }: ProjectItemProps) => {
        const dispatch = useDispatch();

        const onProjectNameClick = useCallback(() => {
            const project = {
                idProject,
                idClient,
                idManager,
                managerFullName,
                members,
                projectType,
                projectName,
                projectDescription,
                projectState,
                territories,
                fuels,
                targetDate,
                territoryStructureName,
                uploadFolder,
                uploadUrl,
                started,
                finished,
                bookmarked,
            };

            dispatch(
                openWindowProjectStakeholder({
                    project,
                    clientName,
                })
            );
        }, [
            idProject,
            idClient,
            idManager,
            managerFullName,
            members,
            projectType,
            projectName,
            projectDescription,
            projectState,
            territories,
            fuels,
            targetDate,
            territoryStructureName,
            uploadFolder,
            uploadUrl,
            started,
            finished,
            bookmarked,
            clientName,
            dispatch,
        ]);

        return (
            <div key={`project-${idClient}-${idProject}`} className="list-item-row">
                <div data-testid="project-name" className="item-value column-project-name">
                    <ActionLabel onClick={onProjectNameClick}>{projectName}</ActionLabel>
                </div>
                <div className="item-value column-project-description">{projectDescription}</div>
                <div className="item-value column-finished">{finished && new Date(finished).toLocaleDateString()}</div>
            </div>
        );
    }
);

export default ProjectStakeholder;
