import { get } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RUN_CALCULATION_START, RUN_CALCULATION_FAILED } from "store/actionTypes";
import { useIsInProgress } from "store/processes/useProcessStatus";
import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useLastCalculationResult } from "store/resources/actions/calculations/calculationsLogActions";
import { useModelStudyCase, updateModelStudyCase } from "store/resources/actions/studyCase/studyCaseModelActions";

import IconLoading from "components/ui/Icons/IconLoading";
import { IconSingleModelReport } from "components/ui/Icons/IconReport";
import Button from "components/ui/ButtonNew";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";

import StudyCase from "pages/ManageProject/ProjectionsContent/common/StudyCase";
import DownloadResults from "pages/ManageProject/ProjectionsContent/common/DownloadResults";

import { modelReadOnlyEnabled } from "pages/utils";

import { useModelCalculationPreconditions } from "utils/useCalculationPreconditions";
import { useCalculationResultModelLog } from "utils/useCalculationResult";
import { useUserRights } from "utils/useUserRights";
import { actionStatus } from "utils/constants";
import { getResultIsPotential } from "utils/studyCase";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS, USER_ROLES } from "utils/user/defines";

import { IndexSignature } from "types/types";
import { StudyCase as StudyCaseInterface } from "store/resources/actions/studyCase/types";
import { ModelStudyCaseProps } from "pages/ManageProject/ProjectionsContent/ModelDashboard/DashboardDefault/ModelStudyCasePanel/types";

import "./style.scss";

const ModelStudyCase = memo(({ idClient, idProject, idInputLog, editing, onEditCancel }: ModelStudyCaseProps) => {
    const runCalculationStatus = useSelector((state) => get(state, `runCalculation.${idProject}-${idInputLog}`));

    const dispatch = useDispatch();

    // State

    const [casesToHighlight, setCasesToHighlight] = useState([]);

    // Resources

    const [activeModel = []] = useProjectInputs({
        idProject,
        transform: (data) => data?.find((pi) => pi.idInputLog === idInputLog),
    });

    const [modelStudyCases, isLoadingModelStudyCases] = useModelStudyCase({ idProject, idInputLog });

    const [lastCalculationResult, isLoadingLastCalculationResult] = useLastCalculationResult({ idProject, idInputLog });

    const isInProgress = useIsInProgress({ idInputLog });

    const { modelCalculationPreconditionsErrors } = useModelCalculationPreconditions({
        idProject,
        idInputLog,
    });

    const [logItem, isLoadingLogItem] = useCalculationResultModelLog({ idProject, idInputLog });

    const userRights = useUserRights();

    // Variables and useMemos

    const haveBasicClientRights = userRights === USER_ROLES.BASIC_CLIENT || userRights === USER_ROLES.BASIC_PRIMARY_CONTACT;

    const activeInput = activeModel?.active || false;

    const isCalculationFailed = lastCalculationResult?.taskState === actionStatus.FAILED;

    const resultIsPotential = useMemo(() => {
        return getResultIsPotential(modelStudyCases);
    }, [modelStudyCases]);

    const checkForStudyCaseChangesSinceLastCalculation = useCallback(() => {
        // Do not highlight any Study Cases if Model
        // has never ran calculations
        if (lastCalculationResult !== undefined && logItem !== undefined) {
            // 1. Take Study Cases that are turned on, modify their parameter
            // names to match the naming in CASE_STUDIES array and put the
            // modified parameter names in an array

            const activeStudyCases = modelStudyCases.filter((studyCase: StudyCaseInterface) => studyCase.value === "1"); // currently highlighting only works for Switchers

            const activeStudyCasesModifiedParameterNames = activeStudyCases.map((studyCase: StudyCaseInterface) =>
                studyCase.parameterName.replace(/_+/g, " ")
            ); // replace underscore with a whitespace

            const calculatedStudyCasesParameterNames = logItem.parameters.CASE_STUDIES.map((cs: any) => cs.NAME);

            // 2. Put modified parameter names that differ
            // between active Study Cases array and
            // calculated Study Cases array in a separate
            // array, revert their parameter names back
            // to the original ones and put those
            // Study Cases in an array. In the result,
            // we get an array with Study Cases that
            // needs to be highlighted

            const changedStudyCasesModifiedParameterNames = activeStudyCasesModifiedParameterNames
                .filter((parameterName: string) => !calculatedStudyCasesParameterNames.includes(parameterName))
                .concat(
                    calculatedStudyCasesParameterNames.filter(
                        (parameterName: string) => !activeStudyCasesModifiedParameterNames.includes(parameterName)
                    )
                );

            const changedStudyCasesParameterNames = changedStudyCasesModifiedParameterNames.map((parameterName: string) =>
                parameterName.replace(/ /g, "_")
            ); // replace whitespace with an underscore

            const newCasesToHighlight = modelStudyCases.filter((studyCase: StudyCaseInterface) =>
                changedStudyCasesParameterNames.includes(studyCase.parameterName)
            );

            setCasesToHighlight(newCasesToHighlight);
        }
    }, [modelStudyCases, lastCalculationResult, logItem]);

    useEffect(() => {
        if (modelStudyCases) {
            checkForStudyCaseChangesSinceLastCalculation();
        }
    }, [modelStudyCases, checkForStudyCaseChangesSinceLastCalculation]);

    // Event handlers

    const onRunCalculationClick = useCallback(() => {
        dispatch({
            type: RUN_CALCULATION_START,
            idProject,
            idInputLog,
            includePotential: resultIsPotential,
        });
    }, [idInputLog, idProject, resultIsPotential, dispatch]);

    const onSaveStudyCaseClick = useCallback(
        (values: IndexSignature<string>) => {
            dispatch(
                updateModelStudyCase({
                    idProject,
                    idInputLog,
                    values,
                })
            );
        },
        [idProject, idInputLog, dispatch]
    );

    return (
        <>
            {isLoadingModelStudyCases || isLoadingLogItem || isLoadingLastCalculationResult ? (
                <IconLoading />
            ) : (
                <div className="model-study-case">
                    {activeInput && (
                        <>
                            <div className="flex-row align-center justify-space-between model-study-case__calculation-section">
                                {!isCalculationFailed && logItem && (
                                    <div className="flex-row align-center">
                                        <div>
                                            <IconSingleModelReport
                                                idClient={idClient}
                                                idProject={idProject}
                                                idInputLog={idInputLog}
                                                title={activeModel.name}
                                                subTitle={`${logItem.isPotential ? "Potential" : "Baseline"} result`}
                                                calculationResult={logItem}
                                                withLabel
                                                withDate
                                            />
                                        </div>
                                        <div className="margin-left">
                                            <DownloadResults idInputLog={logItem.idInputLog} idResultsLog={logItem.results} detailed />
                                        </div>
                                    </div>
                                )}
                                {isCalculationFailed && !isInProgress && !haveBasicClientRights && (
                                    <div className="model-study-case__case-error">
                                        <ErrorMsg
                                            icon="alert-error-empty"
                                            message="Calculation failed. Please contact support to know more."
                                        />
                                    </div>
                                )}
                                {modelCalculationPreconditionsErrors && !haveBasicClientRights && (
                                    <div className="model-study-case__case-error">
                                        <ErrorMsg
                                            icon="alert-error-empty"
                                            message={`Cannot run ${resultIsPotential ? "Potential" : "Baseline"}, check Model Inputs.`}
                                        />
                                    </div>
                                )}
                                {activeModel &&
                                    !modelReadOnlyEnabled(activeModel.modelState, activeModel.required) &&
                                    hasAnyOfPermissions([USER_ACTIONS.CALCULATIONS_RUN]) && (
                                        <div className="flex-row">
                                            <Button
                                                variant="primary"
                                                iconLeft="multimedia-play_circle_b_s"
                                                iconSize="sm"
                                                padding="lg"
                                                isDisabled={
                                                    isInProgress ||
                                                    modelCalculationPreconditionsErrors ||
                                                    runCalculationStatus === RUN_CALCULATION_START ||
                                                    runCalculationStatus === RUN_CALCULATION_FAILED
                                                }
                                                onClick={onRunCalculationClick}
                                            >
                                                <>{`Run ${resultIsPotential ? "Potential" : "Baseline"}`}</>
                                            </Button>
                                        </div>
                                    )}
                            </div>
                            <StudyCase
                                studyCasesRaw={modelStudyCases}
                                editing={editing}
                                casesToHighlight={casesToHighlight}
                                warningMessage="Model Case Studies has been changed. You have to re-calculate the model."
                                saveBtnText="Update Model"
                                onSave={onSaveStudyCaseClick}
                                onEditCancel={onEditCancel}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
});

export default ModelStudyCase;
