import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { selectClient } from "store/clients/actions";

import { useBookmarkedProjects } from "store/resources/actions/bookmarks/bookmarkedProjectsActions";

import WelcomeBoard from "pages/HomeDashboard/common/WelcomeBoard";

import ClientListContainer from "./ClientListContainer";
import BookmarkedProjectsContainer from "./BookmarkedProjectsContainer";
import ProjectListContainer from "./ProjectListContainer";

import { hasExternalUserRole, hasStakeholderUserRole } from "utils/user";

import { HomeDashboardProps } from "pages/HomeDashboard/types";

import "./style.scss";

/**
 * Default Home Dashboard view.
 */
const HomeDashboardDefault = memo(({ selectedClient, clients, isLoadingClients, user }: HomeDashboardProps) => {
    const dispatch = useDispatch();

    // Stakeholders cannot bookmark Projects
    const [bookmarkedProjects, isLoadingBookmarkedProjects] = useBookmarkedProjects({
        userNumber: hasStakeholderUserRole(user) ? undefined : user.userNumber,
    });

    const [isBookmarkView, setIsBookmarkView] = useState(false);

    useEffect(() => {
        if (!hasStakeholderUserRole(user)) {
            // If Client is selected,
            // switch FROM "Bookmarked Projects" view
            if (selectedClient !== undefined) {
                setIsBookmarkView(false);
            }
            // If user has bookmarked Projects,
            // switch TO "Bookmarked Projects"
            // view
            else if (!isEmpty(bookmarkedProjects) && !isLoadingBookmarkedProjects) {
                setIsBookmarkView(true);
            }
        }
    }, [selectedClient, user, bookmarkedProjects, isLoadingBookmarkedProjects]);

    const onBookmarkedProjectsClick = useCallback(() => {
        dispatch(selectClient(-1));

        setIsBookmarkView(true);
    }, [dispatch]);

    return (
        <div
            className={cn("flex-row fill-height with-scroll home-dashboard-default", {
                "home-dashboard-stakeholder": hasStakeholderUserRole(user),
            })}
        >
            <div className="flex-column left-container">
                <ClientListContainer
                    idClient={selectedClient?.id}
                    clients={clients}
                    isLoadingClients={isLoadingClients}
                    isBookmarkView={isBookmarkView}
                    isBookmarkViewEnabled={!hasStakeholderUserRole(user)}
                    onBookmarkedProjectsClick={onBookmarkedProjectsClick}
                />
            </div>
            <div className="flex-column fill-width with-scroll right-container">
                {(isBookmarkView || selectedClient === undefined) && !isLoadingClients && (
                    <WelcomeBoard
                        name={user.firstName || user.name}
                        message={`Select a ${hasExternalUserRole(user) ? "utility" : "client"} to view its projects`}
                    />
                )}

                {isBookmarkView ? (
                    <BookmarkedProjectsContainer
                        clients={clients}
                        isLoadingClients={isLoadingClients}
                        bookmarkedProjects={bookmarkedProjects}
                        isLoadingBookmarkedProjects={isLoadingBookmarkedProjects}
                        user={user}
                    />
                ) : (
                    selectedClient && <ProjectListContainer selectedClient={selectedClient} user={user} />
                )}
            </div>
        </div>
    );
});

export default HomeDashboardDefault;
