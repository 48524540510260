import { get, isEmpty } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { useLastModuleRun } from "store/resources/actions/calculationWorkflow/moduleRunsActions";

import { IdsTabPanel } from "@emergn-infinity/ids-react";

import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";

import { CalculationProps } from "./types";
import { IndexSignature } from "types/types";
import { RunV2Module } from "store/runModuleV2/types";
import { ComponentStatus, Step } from "store/resources/actions/calculationWorkflow/types";

import "./style.scss";

const Calculations = memo(({ idModel, moduleId, id, ariaLabelledBy, isActive }: CalculationProps) => {
    const runModule: RunV2Module = useSelector((state) => get(state, `runModuleV2.${idModel}-${moduleId}`));

    const [lastModuleRun, isLoadingModuleLastRun] = useLastModuleRun({ moduleId });

    const classNames = cn("flex-column fill-height with-scroll remove-background calculations-tab", { "is-hidden": !isActive });

    const components = useMemo(() => {
        let components: IndexSignature<{
            state: string;
            status: ComponentStatus;
            steps: Step[];
        }> = {};

        if (!isEmpty(lastModuleRun) && !isLoadingModuleLastRun) {
            components = lastModuleRun.reduce((comp, currentValue) => {
                if (comp[currentValue.state] === undefined) {
                    comp[currentValue.state] = {
                        state: currentValue.state,
                        status: "PENDING",
                        steps: [],
                    };
                }

                comp[currentValue.state].status = currentValue.status;
                comp[currentValue.state].steps.push({
                    step: currentValue.step,
                    status: currentValue.status,
                });

                return comp;
            }, {});
        }

        return components;
    }, [lastModuleRun, isLoadingModuleLastRun]);

    const allSteps = useMemo(() => {
        let allSteps: string[] = [];

        if (!isEmpty(lastModuleRun) && !isLoadingModuleLastRun) {
            allSteps = lastModuleRun.map((moduleRun) => moduleRun.step);
        }

        return allSteps;
    }, [lastModuleRun, isLoadingModuleLastRun]);

    const getComponentBranchClassNames = useCallback(
        (steps: Step[], status: ComponentStatus) => {
            return { "branch-done": steps.every((st) => runModule?.completedSteps?.includes(st.step)) || status === "DONE" };
        },
        [runModule]
    );

    const getStepBranchClassNames = useCallback(
        (step: string, status: ComponentStatus) => {
            return { "branch-done": runModule?.completedSteps?.includes(step) || status === "DONE" };
        },
        [runModule]
    );

    const getComponentIcon = useCallback(
        (key: string, index: number) => {
            let className = "calculations-tab__icon";
            let icon = "figures-circle__full_moon_b_s";

            const steps = components[key].steps;
            const status = components[key].status;

            // Current component is done if:
            //
            // (runModule state) Every step for current component completed
            // OR
            // (lastModuleRun resource) Component status is DONE
            if (steps.every((st) => runModule?.completedSteps?.includes(st.step)) || status === "DONE") {
                className += " icon-done";
                icon = "ui-checked__check_circle_b_f";
            }
            // Current component is in progress if:
            //
            // (runModule state) Some steps for current component completed
            // OR
            // (lastModuleRun resource) Component status is IN_PROGRESS
            else if (steps.some((st) => runModule?.completedSteps?.includes(st.step)) || status === "IN_PROGRESS") {
                className += " icon-in-progress";
            }
            // Current component has error if:
            //
            // (lastModuleRun resource) Component status is ERROR
            else if (status === "ERROR") {
                className += " icon-error";
                icon = "text-alert__error_b_f";
            } else if (index > 0) {
                const previousKey = Object.keys(components)[index - 1];
                const previousSteps = components[previousKey].steps;

                // Current component is in progress if:
                //
                // (runModule state) Every step for previous component completed
                if (previousSteps.every((st) => runModule?.completedSteps?.includes(st.step))) {
                    className += " icon-in-progress";
                }
            }

            return <Icon className={className} icon={icon} size="md" />;
        },
        [runModule, components]
    );

    const getStepIcon = useCallback(
        (step: string, status: ComponentStatus) => {
            let className = "calculations-tab__icon";
            let icon = "figures-circle__full_moon_b_s";

            const lastCompletedStep = runModule?.completedSteps?.[runModule.completedSteps.length - 1];
            const lastCompletedStepIndex = allSteps.findIndex((step) => step === lastCompletedStep);

            // Current step is done if:
            //
            // (runModule state) Step exists in completed steps list
            // OR
            // (lastModuleRun resource) Step status is DONE
            if (runModule?.completedSteps?.includes(step) || status === "DONE") {
                className += " icon-done";
                icon = "ui-checked__check_circle_b_f";
            }
            // Current step is in progress if:
            //
            // (runModule state) Neighboring step on the left side is completed
            // OR
            // (lastModuleRun resource) Step status is IN_PROGRESS
            else if (
                (lastCompletedStepIndex !== -1 &&
                    lastCompletedStepIndex !== allSteps.length - 1 &&
                    allSteps[lastCompletedStepIndex + 1] === step) ||
                status === "IN_PROGRESS"
            ) {
                className += " icon-in-progress";
            }
            // Current step has error if:
            //
            // (lastModuleRun resource) Step status is ERROR
            else if (status === "ERROR") {
                className += " icon-error";
                icon = "text-alert__error_b_f";
            }

            return <Icon className={className} icon={icon} size="md" />;
        },
        [runModule, allSteps]
    );

    return (
        <IdsTabPanel customClasses={classNames} idValue={id} slot="panel" ariaLabelledBy={ariaLabelledBy}>
            {isLoadingModuleLastRun ? (
                <IconLoading />
            ) : (
                !isEmpty(components) && (
                    <div>
                        {Object.keys(components).map((key, outerIndex) => (
                            <div key={`component-${components[key].state}`} className="calculations-tab__components-container">
                                {outerIndex < Object.keys(components).length - 1 && (
                                    <div
                                        className={cn(
                                            "calculations-tab__component-branch",
                                            getComponentBranchClassNames(components[key].steps, components[key].status)
                                        )}
                                    />
                                )}
                                <div className="flex-row align-center">
                                    {getComponentIcon(key, outerIndex)}
                                    <div className="calculations-tab__name">{components[key].state}</div>
                                </div>
                                <div className="calculations-tab__steps-container">
                                    {components[key].steps.map((st, innerIndex) => (
                                        <div key={`step-${st.step}-${innerIndex}`} className="flex-row align-center calculations-tab__step">
                                            {innerIndex < components[key].steps.length - 1 && (
                                                <div
                                                    className={cn(
                                                        "calculations-tab__step-branch",
                                                        getStepBranchClassNames(st.step, st.status)
                                                    )}
                                                />
                                            )}
                                            {getStepIcon(st.step, st.status)}
                                            <div className="calculations-tab__name">{st.step}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            )}
        </IdsTabPanel>
    );
});

export default Calculations;
