import { isEmpty, isNil, kebabCase } from "lodash";
import { memo, useCallback, useMemo } from "react";
import cn from "classnames";
import { batch, useDispatch } from "react-redux";

import { useModelDataImportStatus } from "store/processes/useModelDataImportStatus";
import { MANDATORY_INPUT, POTENTIAL_INPUT, updateMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";
import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useModelInputsProgress, clearModelInputsProgress } from "store/resources/actions/modelInputs/modelInputsProgressActions";

import { openModalDialogAvoidedCostsSetup } from "components/modals/AvoidedCostsModals/openModalDialogAvoidedCostsSetup";

import ModelInputNoteSidebar from "layouts/Sidebar/ModelInputNoteSidebar";

import { openModalDialogModelInputsValidationErrorsPopup } from "layouts/Modal/ModelDashboardModal/utils";

import TagWithDropdown from "components/ui/Dropdown/TagWithDropdown";
import Button from "components/ui/ButtonNew";
import Icon from "components/ui/IconNew";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import IconLoading from "components/ui/Icons/IconLoading";
import CustomList from "components/ui/List/CustomList";
import StatusMsg from "components/ui/StatusMsg";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import SuccessMsg from "components/ui/StatusMsg/SuccessMsg";
import WarningMsg from "components/ui/StatusMsg/WarningMsg";
import Tag from "components/ui/Tag";
import UserName from "components/ui/UserName";
import Tooltip from "components/ui/Tooltip";
import { StaticProgressBar } from "components/ui/ProgressBar";

import { modelReadOnlyEnabled, actionAllowed } from "pages/utils";

import { useModelCalculationPreconditions } from "utils/useCalculationPreconditions";
import { useAllModelInputTables, useModelInputTables } from "utils/useModelInputTables";
import { useSidePanelHandlers } from "utils/useSidePanelHandlers";
import { useUserRights } from "utils/useUserRights";
import { useValidationErrors } from "utils/useValidationErrors";
import { allModelInputStates, modelInputStates, actionStatus } from "utils/constants";
import { toLocaleDateTime } from "utils/dateTime";
import { formatFullName } from "utils/string";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";
import { openWindowModelInputPaginatedReport, openWindowModifyAvoidedCosts } from "utils/window";

import { ModelInputsProps } from "pages/ManageProject//ProjectionsContent/ModelDashboard/DashboardDefault/ModelInputsPanel/types";
import { ModelInputType } from "utils/constants/types";

import "./style.scss";

const headers = {
    required: {
        label: "",
        sortable: false,
    },
    datasetName: {
        label: "Dataset name",
        sortable: true,
    },
    upload: "Uploaded by",
    review: "Review",
    status: "Status",
    note: {
        label: "",
        sortable: false,
    },
};

const ModelInputs = memo(({ idClient, idProject, idInputLog, model, projectName }: ModelInputsProps) => {
    const { name, modelState, required: modelRequired } = model;

    const dispatch = useDispatch();

    const userRights = useUserRights();

    // Resources

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ key: "outer-right" });

    const [activeModel = []] = useProjectInputs({
        idProject,
        transform: (data) => data && data.find((pi) => pi.idInputLog === idInputLog),
    });

    const { equipmentInputsTables, nonEquipmentInputsTables, extraInputsTables, isLoadingAllModelInputs } = useAllModelInputTables();

    const { modelInputs, isLoadingModelInputs } = useModelInputTables({ idInputLog });

    const { modelCalculationPreconditions } = useModelCalculationPreconditions({ idProject, idInputLog });

    const { inputValidations, hasValidationsErrors } = useValidationErrors(idInputLog, false);

    const importStatus = useModelDataImportStatus({ idInputLog });

    const [inputsProgress, isLoadingInputsProgress] = useModelInputsProgress({ idProject, idInputLog });

    // Memos

    const inputInProgress = importStatus && !importStatus.finished;

    const inputsProgressPercentage = inputsProgress?.percentage;
    const inputsProgressCompleted = inputsProgress?.completed;
    const inputsProgressTotal = inputsProgress?.total;

    const mandatoryModelInputs = useMemo(() => {
        let mandatoryModelInputs: any[] = [];

        if (!isEmpty(equipmentInputsTables)) {
            mandatoryModelInputs = equipmentInputsTables.filter((input: any) => input?.showOnUi === true);
        }

        return mandatoryModelInputs;
    }, [equipmentInputsTables]);

    const potentialModelInputs = useMemo(() => {
        let potentialModelInputs: any[] = [];

        if (!isEmpty(nonEquipmentInputsTables)) {
            potentialModelInputs = nonEquipmentInputsTables.filter((input: any) => input?.showOnUi === true);
        }

        return potentialModelInputs;
    }, [nonEquipmentInputsTables]);

    const extraModelInputs = useMemo(() => {
        let extraModelInputs: any[] = [];

        if (!isEmpty(extraInputsTables)) {
            extraModelInputs = extraInputsTables.filter((input: any) => input?.showOnUi === true);
        }

        return extraModelInputs;
    }, [extraInputsTables]);

    // Event handlers

    const onCreateInputsClick = useCallback(() => {
        dispatch(
            // @ts-ignore
            openModalDialogAvoidedCostsSetup({
                idProject,
                projectName,
                model: activeModel,
            })
        );
    }, [idProject, projectName, activeModel, dispatch]);

    const onDatasetStatusChange = useCallback(
        (newDatasetStatus, idDataImportLog, oldDatasetStatus) => {
            if (newDatasetStatus !== oldDatasetStatus && idDataImportLog !== undefined && idDataImportLog !== null) {
                dispatch(
                    updateMeasureInputs({
                        idInputLog,
                        idDataImportLog,
                        datasetStatus: newDatasetStatus,
                        onComplete: () => {
                            if (
                                newDatasetStatus === allModelInputStates.MANAGER_SIGNED_OFF.toUpperCase() ||
                                oldDatasetStatus === allModelInputStates.MANAGER_SIGNED_OFF.toUpperCase()
                            ) {
                                batch(() => {
                                    dispatch(clearModelInputsProgress({ idProject, idInputLog }));
                                    modelRequired && dispatch(clearModelInputsProgress({ idProject }));
                                });
                            }
                        },
                    })
                );
            }
        },
        [idProject, idInputLog, modelRequired, dispatch]
    );

    const onModifyInputsClick = useCallback(() => {
        dispatch(openWindowModifyAvoidedCosts({ idProject, projectName, model: activeModel }));
    }, [idProject, projectName, activeModel, dispatch]);

    const onAddNoteClick = useCallback(
        (idDataImportLog) => {
            handleOpenSidePanel(
                <ModelInputNoteSidebar
                    idInputLog={idInputLog}
                    idDataImportLog={idDataImportLog}
                    noteType="system-entry"
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [idInputLog, handleOpenSidePanel, handleCloseSidePanel]
    );

    const onShowNoteClick = useCallback(
        (idDataImportLog) => {
            handleOpenSidePanel(
                <ModelInputNoteSidebar
                    idInputLog={idInputLog}
                    idDataImportLog={idDataImportLog}
                    noteType="system-entry"
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [idInputLog, handleOpenSidePanel, handleCloseSidePanel]
    );

    const onViewValidationErrorsClick = useCallback(
        (inputValidations, idInputVersion) => {
            dispatch(
                openModalDialogModelInputsValidationErrorsPopup({
                    idInputLog,
                    validations: inputValidations,
                    idInputVersion,
                })
            );
        },
        [idInputLog, dispatch]
    );

    const onModelInputReportIconClick = useCallback(
        (inputName, tableName) => {
            dispatch(
                openWindowModelInputPaginatedReport({
                    idClient,
                    idProject,
                    filterEntityId: idInputLog,
                    projectName,
                    configName: tableName as ModelInputType,
                    title: `${name} - ${inputName}`,
                })
            );
        },
        [idClient, idProject, idInputLog, projectName, name, dispatch]
    );

    // Render function

    const renderModelInput = (modelInput: any) => {
        const sheetProgressStat =
            importStatus?.sheetStatuses?.hasOwnProperty(modelInput.sheetName) && importStatus?.sheetStatuses[modelInput.sheetName];

        const sheetInfoArr = !inputInProgress && modelInputs && modelInputs[modelInput.sheetName];

        // Sheet import attempt
        const sheetInfo = sheetInfoArr?.[0];

        const calcPreconditions = !inputInProgress
            ? modelCalculationPreconditions && modelCalculationPreconditions[modelInput.tableName]
            : [];

        const inputRequired = calcPreconditions && calcPreconditions.some((cp: any) => cp.required === 1);

        const rowCount = sheetInfoArr
            ? sheetInfoArr.reduce((acc: any, v: any) => {
                  if (v.rowCount !== undefined) {
                      return (acc || 0) + v.rowCount;
                  }
                  return acc;
              }, undefined)
            : undefined;

        const sheetInfoWarning = sheetInfo?.status === "WARNING";

        const sheetInfoError =
            (calcPreconditions && calcPreconditions.some((cp: any) => cp.tableIsValid === 0)) || sheetInfo?.status === "ERROR";

        let modelInputStateItems: any = [];
        let canChangeStatus = false;

        if (sheetInfo && modelInputStates.hasOwnProperty(userRights)) {
            canChangeStatus = modelInputStates[userRights].hasOwnProperty(sheetInfo.datasetStatus.toUpperCase());

            if (modelInputStates[userRights].hasOwnProperty(sheetInfo.datasetStatus.toUpperCase())) {
                modelInputStateItems = modelInputStates[userRights][sheetInfo.datasetStatus.toUpperCase()].map((status) => ({
                    label: status,
                    value: status.toUpperCase(),
                }));
            }
        }

        return (
            <div key={`equipment-input-${modelInput.sheetName}`} className="list-item-row equipment-inputs-row">
                {/* Required column */}
                <div className="item-value column-required">
                    {inputRequired && <Icon disabled icon="ui-checked__check_circle_b_f" iconTitle="Required by model" />}
                </div>

                {/* Dataset Name column */}
                <div className="item-value column-dataset-name">
                    <div className="flex-row align-center">
                        <div className="item-value equipment-inputs-row__title">{modelInput.sheetName}</div>
                        {/* Avoided Costs setup */}
                        {modelInput.tableName === "AVOIDED_COSTS" && (
                            <div className="flex-row flex-wrap">
                                {
                                    // Display "Modify inputs" if there is data in edit table or in target table
                                    ((calcPreconditions === undefined ? 0 : calcPreconditions[0]?.avoidedCostEditRowCount) ||
                                        (rowCount !== undefined && rowCount !== 0)) && (
                                        <div key={`equipment-input-${modelInput.sheetName}-edit-empty`} className="margin-left-small">
                                            {!modelReadOnlyEnabled(modelState, modelRequired) &&
                                            actionAllowed(userRights, USER_ACTIONS.AVOIDED_COSTS_EDIT, modelRequired) ? (
                                                <IconWithLabel icon="edit-empty" onClick={onModifyInputsClick}>
                                                    Modify Inputs
                                                </IconWithLabel>
                                            ) : (
                                                <IconWithLabel icon="eye-visibility-empty" onClick={onModifyInputsClick}>
                                                    View Inputs
                                                </IconWithLabel>
                                            )}
                                        </div>
                                    )
                                }
                                {!modelReadOnlyEnabled(modelState, modelRequired) &&
                                    actionAllowed(userRights, USER_ACTIONS.AVOIDED_COSTS_ADD, modelRequired) && (
                                        <div key={`equipment-input-${modelInput.sheetName}-create-inputs`} className="margin-left-small">
                                            <IconWithLabel icon="view-module-empty" onClick={onCreateInputsClick}>
                                                Create Inputs
                                            </IconWithLabel>
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>

                    {/* Import messages */}
                    {((inputInProgress && sheetProgressStat) || (!inputInProgress && sheetInfo)) && (
                        <div className="flex-row equipment-inputs-row__details">
                            {inputInProgress
                                ? sheetProgressStat && (
                                      <div className="item-value">
                                          {sheetProgressStat.status === actionStatus.ERROR && (
                                              <ErrorMsg message={sheetProgressStat.message} />
                                          )}
                                          {sheetProgressStat.status === actionStatus.DONE && (
                                              <SuccessMsg message={sheetProgressStat.message} />
                                          )}
                                      </div>
                                  )
                                : sheetInfo && (
                                      <div className="item-value">
                                          <StatusMsg
                                              success={!sheetInfoError && rowCount !== 0}
                                              error={sheetInfoError || rowCount === 0}
                                              message={`${sheetInfoError || rowCount === 0 ? "Import" : "Imported"} from ${
                                                  sheetInfo.fileName
                                              }`}
                                          />
                                      </div>
                                  )}
                        </div>
                    )}

                    {!inputInProgress && (
                        <>
                            {/* Calculation Precondition messages */}
                            {calcPreconditions !== undefined ? (
                                <>
                                    {calcPreconditions
                                        .filter((cp: any) => cp.msgClassification !== actionStatus.DONE && isNil(cp.idValidation))
                                        .map((cp: any, index: number) => {
                                            const msgError = cp.msgClassification === actionStatus.ERROR && sheetInfo;
                                            const msgWarning = cp.msgClassification === actionStatus.WARNING && sheetInfo;

                                            return (
                                                <div key={index} className="flex-row equipment-inputs-row__details">
                                                    <div className={cn("item-value", { "equipment-inputs-row__errors": sheetInfo })}>
                                                        {msgError && <ErrorMsg withoutIcon message={sheetInfo ? cp.message : ""} />}
                                                        {msgWarning && <WarningMsg withoutIcon message={sheetInfo ? cp.message : ""} />}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </>
                            ) : (
                                <>
                                    {/* Imported sheet was empty error message */}
                                    {!sheetInfoError && rowCount === 0 && (
                                        <div className="flex-row equipment-inputs-row__details">
                                            <div className="item-value equipment-inputs-row__errors">
                                                {inputRequired && <ErrorMsg withoutIcon message="Imported sheet was empty" />}
                                                {!inputRequired && <WarningMsg message="Imported sheet was empty" />}
                                            </div>
                                        </div>
                                    )}

                                    {/* Import warning messages */}
                                    {sheetInfoWarning && (
                                        <div className="flex-row equipment-inputs-row__details">
                                            <div className="item-value equipment-inputs-row__errors">
                                                <WarningMsg withoutIcon message={sheetInfo.message} />
                                            </div>
                                        </div>
                                    )}

                                    {/* Import error messages */}
                                    {sheetInfoError && (
                                        <div className="flex-row equipment-inputs-row__details">
                                            <div className="item-value equipment-inputs-row__errors">
                                                <ErrorMsg withoutIcon message={sheetInfo.message} />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {/* Validation messages */}
                    {inputRequired &&
                        sheetInfo &&
                        hasValidationsErrors &&
                        inputValidations.some((iv: any) => sheetInfoArr.some((sh: any) => iv.tableName === sh.tableName)) &&
                        inputValidations
                            .filter((iv: any) => sheetInfoArr.some((sh: any) => iv.tableName === sh.tableName))
                            .map((iv: any, index: number) => {
                                const msgError = iv.msgClassification === actionStatus.ERROR && inputRequired;
                                const msgWarning = iv.msgClassification === actionStatus.WARNING && inputRequired;

                                return (
                                    <div key={index} className="flex-row">
                                        <div className="flex-row align-center">
                                            <div className="item-value equipment-inputs-row__errors">
                                                {msgError && <ErrorMsg withoutIcon message={iv.message} />}
                                                {msgWarning && <WarningMsg withoutIcon message={iv.message} />}
                                            </div>
                                            <IconWithLabel
                                                className="margin-left-small"
                                                icon="eye-visibility-empty"
                                                onClick={() => onViewValidationErrorsClick(iv, sheetInfo.inputVersion)}
                                            >
                                                View
                                            </IconWithLabel>
                                        </div>
                                    </div>
                                );
                            })}
                </div>

                {/* Uploaded By Column */}
                <div className="item-value column-upload">
                    {sheetInfo?.userNumber && (
                        <div className="update-info">
                            {/* @ts-ignore - remove when UserName is refactored to TypeScript */}
                            <UserName userNumber={sheetInfo?.userNumber} />
                            <div>{`${toLocaleDateTime(sheetInfo?.importStarted)}`}</div>
                        </div>
                    )}
                </div>

                {/* Review column */}
                <div className="item-value column-review">
                    {sheetInfo && !sheetInfoError && [MANDATORY_INPUT, POTENTIAL_INPUT].includes(modelInput.category) && (
                        <Tooltip
                            placement="top"
                            className="review-data"
                            customIcon={
                                <Button
                                    ariaLabel="Review data"
                                    variant="tertiary"
                                    icon="files-document_table_b_s"
                                    iconSize="sm"
                                    padding="sm"
                                    onClick={() => onModelInputReportIconClick(modelInput.sheetName, modelInput.tableName)}
                                />
                            }
                        >
                            Review data
                        </Tooltip>
                    )}
                </div>

                {/* Status column */}
                <div className="item-value column-status">
                    {sheetInfo && (
                        <>
                            {canChangeStatus ? (
                                <TagWithDropdown
                                    tagClassName={kebabCase(sheetInfo.datasetStatus)}
                                    value={sheetInfo.datasetStatus}
                                    items={modelInputStateItems}
                                    onChange={(value) => onDatasetStatusChange(value, sheetInfo?.idDataImportLog, sheetInfo?.datasetStatus)}
                                />
                            ) : (
                                <Tag className={kebabCase(sheetInfo.datasetStatus)} size="sm">
                                    <>{sheetInfo.datasetStatus.toLowerCase()}</>
                                </Tag>
                            )}
                        </>
                    )}
                </div>

                {/* Note column */}
                <div className="item-value column-note">
                    {sheetInfo && (
                        <>
                            {/* Add note to import log */}
                            {sheetInfo.lastIdInputNote === null &&
                                sheetInfo.loadingNote === undefined &&
                                hasAnyOfPermissions([USER_ACTIONS.NOTE_ADD]) && (
                                    <Button
                                        variant="tertiary"
                                        icon="communication-message_b_s"
                                        iconSize="sm"
                                        padding="sm"
                                        title="Add note to system entry"
                                        onClick={() => onAddNoteClick(sheetInfo.idDataImportLog)}
                                    />
                                )}

                            {/* Note being added/deleted */}
                            {sheetInfo.loadingNote && <IconLoading />}

                            {/* Display note */}
                            {!isNil(sheetInfo.lastIdInputNote) && sheetInfo.loadingNote === undefined && (
                                <Tooltip
                                    placement="bottom-end"
                                    className="note-tooltip"
                                    customIcon={
                                        <Button
                                            ariaLabel="Show notes"
                                            variant="tertiary"
                                            icon="communication-message_list__speaker_notes_b_s"
                                            iconSize="sm"
                                            padding="sm"
                                            onClick={() => onShowNoteClick(sheetInfo.idDataImportLog)}
                                        />
                                    }
                                >
                                    {`Last entry by ${formatFullName(
                                        sheetInfo.noteFirstName,
                                        sheetInfo.noteLastName
                                    )} on ${toLocaleDateTime(`${sheetInfo.notesLastUpdateDate}Z`)}`}
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            {isLoadingAllModelInputs || isLoadingModelInputs || isLoadingInputsProgress ? (
                <IconLoading />
            ) : (
                <div className="model-inputs">
                    <div className="model-inputs__sticky-header">
                        <StaticProgressBar
                            className="margin-bottom model-progress-bar"
                            percentage={inputsProgressPercentage}
                            topDescription="Completion of the Model based on required inputs."
                            bottomDescription={`${inputsProgressPercentage}% - ${inputsProgressCompleted} of ${inputsProgressTotal} Completed`}
                        />
                    </div>
                    <div>
                        <div className="model-inputs__fade" />
                        <div className="content-header sticky">Baseline Tables</div>
                        <CustomList
                            // @ts-ignore - remove when CustomList is refactored to TypeScript
                            headers={headers}
                            items={mandatoryModelInputs}
                            renderItem={renderModelInput}
                            noPadding
                        />
                        <div className="content-header sticky">Potential Tables</div>
                        <CustomList
                            // @ts-ignore
                            headers={headers}
                            items={potentialModelInputs}
                            renderItem={renderModelInput}
                            noPadding
                        />
                        <div className="content-header sticky">Additional Tables</div>
                        <CustomList
                            // @ts-ignore
                            headers={headers}
                            items={extraModelInputs}
                            renderItem={renderModelInput}
                            noPadding
                        />
                    </div>
                </div>
            )}
        </>
    );
});

export default ModelInputs;
