import { memo } from "react";

import MarketProfileSnapshot from "./MarketProfileSnapshot";

import DashboardPanel from "components/ui/Dashboard";

import { MARKET_PROFILE_SNAPSHOT } from "utils/constants";

import { MarketProfileSnapshotProps } from "./types";

const MarketProfileSnapshotPanel = memo(({ project, data, isLoading }: MarketProfileSnapshotProps) => {
    return (
        <DashboardPanel className="market-profile-snapshot-panel" title="Market Profile Snapshot" pageId={MARKET_PROFILE_SNAPSHOT}>
            <MarketProfileSnapshot project={project} data={data} isLoading={isLoading} />
        </DashboardPanel>
    );
});

export default MarketProfileSnapshotPanel;
