import { isEmpty, uniqBy } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { useClientList } from "store/resources/actions/client/clientActions";
import { useProjectList } from "store/resources/actions/project/projectActions";
import { useProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useProjectFuels } from "store/resources/actions/fuel/fuelActions";
import { useMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";
import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useComponentModels, cloneComponentModel } from "store/resources/actions/componentModel/componentModelActions";
import { createDuplicateInput } from "store/resources/actions/projectInput/projectInputDuplicationActions";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import Radio from "components/ui/Input/Radio";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import Separator from "components/ui/Separator";
import TextArea from "components/ui/TextArea";

import ModelInputsTableSelection, { modelInputsToSelections, uncheckModelInputsWithErrors } from "layouts/common/ModelInputsTableSelection";

import { isNullOrWhitespace } from "utils/string";

import type { Client } from "types/api/ClientResponseModel";
import type { Project } from "store/resources/actions/project/types";
import type { ProjectInput } from "store/resources/actions/projectInput/types";
import type { Territory, Fuel, IndexSignature } from "types/types";

import type { ModalOpenChildrenProps } from "layouts/Modal/types";

const concatTableNames = (tableNames: string, tablesArr: any, selectedSheets: IndexSignature<boolean>) => {
    const separator = ";";

    tablesArr.forEach((table: any) => {
        const { tableName, sheetName } = table;

        if (selectedSheets[sheetName] === true) {
            if (isEmpty(tableNames)) {
                tableNames += tableName;
            } else {
                tableNames += separator + tableName;
            }
        }
    });

    return tableNames;
};

const DuplicateModel: React.NamedExoticComponent<DuplicateModelProps> = memo(
    ({ idClient, idProject, idInputLog, idTerritory, idFuel, idModel, onSuccess, onCancel }) => {
        const dispatch = useDispatch();

        const [targetModelName, setTargetModelName] = useState("");
        const [targetModelDescription, setTargetModelDescription] = useState("");
        const [targetIdClient, setTargetIdClient] = useState<number | null>(idClient);
        const [targetIdProject, setTargetIdProject] = useState<number | null>(idProject);
        const [targetIdTerritory, setTargetIdTerritory] = useState<number | null>(idTerritory);
        const [targetIdFuel, setTargetIdFuel] = useState<number | null>(idFuel);
        const [targetSheetSelections, setTargetSheetSelections] = useState<IndexSignature<boolean>>({});

        const [clients, isLoadingClients] = useClientList();
        const [projects, isLoadingProjects] = useProjectList({ idClient: targetIdClient });
        const [projectTerritories, isLoadingProjectTerritories] = useProjectTerritories({ idProject: targetIdProject });
        const [projectFuels, isLoadingProjectFuels] = useProjectFuels({ idProject: targetIdProject });
        const [models, isLoadingModels] = useProjectInputs({ idProject: targetIdProject });
        const [componentModels, isLoadingComponentModels] = useComponentModels({ idProject: targetIdProject });

        const [modelInputs, isLoadingModelInputs] = useMeasureInputs({ idInputLog });

        const modelNameExists = useMemo(() => {
            let modelNameExists = false;

            if (!isEmpty(models) && !isLoadingModels) {
                modelNameExists = models.some((model: ProjectInput) => model.name.toLowerCase() === targetModelName.toLowerCase().trim());
            }
            if (!modelNameExists && !isEmpty(componentModels) && !isLoadingComponentModels) {
                modelNameExists = componentModels.some(
                    (componentModel) => componentModel.name.toLowerCase() === targetModelName.toLowerCase().trim()
                );
            }

            return modelNameExists;
        }, [targetModelName, models, isLoadingModels, componentModels, isLoadingComponentModels]);

        const clientItems = useMemo(() => {
            let clientItems = [];

            if (!isEmpty(clients) && !isLoadingClients) {
                clientItems = clients.map((client: Client) => ({ label: client.clientName, value: client.id }));
            }

            return clientItems;
        }, [clients, isLoadingClients]);

        const projectItems = useMemo(() => {
            let projectItems = [];

            if (!isEmpty(projects) && !isLoadingProjects) {
                projectItems = projects.map((project: Project) => ({ label: project.projectName, value: project.idProject }));
            }

            return projectItems;
        }, [projects, isLoadingProjects]);

        const territoryItems = useMemo(() => {
            let territoryItems = [];

            if (!isEmpty(projectTerritories) && !isLoadingProjectTerritories) {
                territoryItems = projectTerritories.map((territory: Territory) => ({
                    label: `${territory.name} ${territory.alias}`,
                    value: territory.idTerritory,
                }));
            }

            return territoryItems;
        }, [projectTerritories, isLoadingProjectTerritories]);

        useEffect(() => {
            setTargetIdTerritory(
                targetIdTerritory || projectTerritories.find((territory: Territory) => territory.idTerritory === idTerritory)?.idTerritory
            );
            setTargetIdFuel(targetIdFuel || projectFuels.find((fuel: Fuel) => fuel.idFuel === idFuel)?.idFuel);
        }, [idTerritory, idFuel, targetIdTerritory, targetIdFuel, projectTerritories, projectFuels]);

        useEffect(() => {
            if (!isLoadingModelInputs) {
                const eqInputsTables = uniqBy(modelInputs.equipmentinputstables, "sheetName");
                const nonEqInputsTables = uniqBy(modelInputs.nonequipmentinputstables, "sheetName");
                const extraInputsTables = uniqBy(modelInputs.extrainputstables, "sheetName");
                const resultTables = uniqBy(modelInputs.resulttables, "sheetName");

                let newTargetSheetSelections = {
                    ...modelInputsToSelections(eqInputsTables, true),
                    ...modelInputsToSelections(nonEqInputsTables, true),
                    ...modelInputsToSelections(extraInputsTables, true),
                    ...modelInputsToSelections(resultTables, true),
                };

                newTargetSheetSelections = {
                    ...newTargetSheetSelections,
                    ...uncheckModelInputsWithErrors(eqInputsTables),
                    ...uncheckModelInputsWithErrors(nonEqInputsTables),
                    ...uncheckModelInputsWithErrors(extraInputsTables),
                    ...uncheckModelInputsWithErrors(resultTables),
                };

                setTargetSheetSelections(newTargetSheetSelections);
            }
        }, [modelInputs, isLoadingModelInputs]);

        const onChange = useCallback((value, name) => {
            switch (name) {
                case "targetModelName":
                    setTargetModelName(value);

                    break;

                case "targetModelDescription":
                    setTargetModelDescription(value);

                    break;

                case "targetIdClient":
                    setTargetIdClient(value);
                    setTargetIdProject(null);
                    setTargetIdTerritory(null);
                    setTargetIdFuel(null);

                    break;

                case "targetIdProject":
                    setTargetIdProject(value);
                    setTargetIdTerritory(null);
                    setTargetIdFuel(null);

                    break;

                case "targetIdTerritory":
                    setTargetIdTerritory(value);

                    break;

                case "targetIdFuel":
                    setTargetIdFuel(parseInt(value));

                    break;

                default:
            }
        }, []);

        const onDuplicateClick = () => {
            // Which Model Inputs tables are duplicated are
            // selected by tableName not sheetName property
            // and separated by ";"
            let tableNames = "";

            const eqInputsTables = modelInputs.equipmentinputstables || [];
            const nonEqInputsTables = modelInputs.nonequipmentinputstables || [];
            const extraInputsTables = modelInputs.extrainputstables || [];
            const resultTables = modelInputs.resulttables || [];

            if (!isEmpty(eqInputsTables)) {
                tableNames = concatTableNames(tableNames, eqInputsTables, targetSheetSelections);
            }
            if (!isEmpty(nonEqInputsTables)) {
                tableNames = concatTableNames(tableNames, nonEqInputsTables, targetSheetSelections);
            }
            if (!isEmpty(extraInputsTables)) {
                tableNames = concatTableNames(tableNames, extraInputsTables, targetSheetSelections);
            }
            if (!isEmpty(resultTables)) {
                tableNames = concatTableNames(tableNames, resultTables, targetSheetSelections);
            }

            // Duplicate Component Model
            if (idModel) {
                dispatch(
                    cloneComponentModel({
                        sourceProjectId: idProject,
                        sourceModelId: idModel,
                        targetProjectId: targetIdProject,
                        targetModelName,
                        targetTerritoryId: targetIdTerritory,
                        targetFuelId: targetIdFuel,
                        targetModelDescription,
                        tableNames,
                        onSuccess: (action) => {
                            onSuccess(action, targetIdProject);
                        },
                    })
                );
            }
            // Duplicate Legacy Model
            else {
                dispatch(
                    createDuplicateInput({
                        sourceIdProject: idProject,
                        sourceIdInputLog: idInputLog,
                        targetIdProject,
                        targetModelName,
                        targetModelDescription,
                        targetIdTerritory,
                        targetIdFuel,
                        tableNames,
                        onSuccess,
                    })
                );
            }

            onCancel?.();
        };

        const renderFuels = useMemo(() => {
            return !isLoadingProjectFuels
                ? projectFuels.map((fuel: Fuel) => (
                      <Radio
                          key={`fuel-${fuel.name}`}
                          id={`fuel-${fuel.idFuel}`}
                          name="Fuel"
                          label={fuel.name}
                          value={fuel.idFuel}
                          checked={fuel.idFuel === targetIdFuel}
                          onChange={(event) => onChange(event.target.value, "targetIdFuel")}
                      />
                  ))
                : null;
        }, [targetIdFuel, projectFuels, isLoadingProjectFuels, onChange]);

        return (
            <>
                <div className="flex-column modal-padding">
                    <FieldGroup>
                        <Input
                            label="Model name"
                            required
                            placeholder="Territory Sector Fuel"
                            value={targetModelName}
                            error={modelNameExists}
                            msgText={modelNameExists ? "This name is already in use." : ""}
                            onChange={(event) => onChange(event.target.value, "targetModelName")}
                        />
                        <TextArea
                            label="Model description"
                            value={targetModelDescription}
                            placeholder="Type a model description"
                            onChange={(event) => onChange(event.target.value, "targetModelDescription")}
                        />
                        <Dropdown
                            label="Assign to client"
                            placeholder="Not selected"
                            required
                            value={targetIdClient ?? undefined}
                            items={clientItems}
                            withFilter
                            onChange={(value) => onChange(value, "targetIdClient")}
                        />
                        <Dropdown
                            label="Assign to project"
                            placeholder="Not selected"
                            required
                            value={targetIdProject ?? undefined}
                            items={projectItems}
                            withFilter
                            warning={targetIdProject !== null && targetIdProject !== idProject}
                            msgText={
                                targetIdProject !== null && targetIdProject !== idProject
                                    ? "The variables will match the project level settings of the selected project."
                                    : ""
                            }
                            onChange={(value) => onChange(value, "targetIdProject")}
                        />
                        {targetIdProject !== idProject && (
                            <FieldGroup>
                                <div>
                                    <Dropdown
                                        label="Territory of the new project"
                                        placeholder="Not selected"
                                        value={targetIdTerritory ?? undefined}
                                        items={territoryItems}
                                        withFilter
                                        warning={targetIdTerritory !== undefined && isEmpty(territoryItems)}
                                        msgText={
                                            targetIdTerritory !== undefined && isEmpty(territoryItems)
                                                ? "Selected project doesn't have any territories set."
                                                : ""
                                        }
                                        onChange={(value) => onChange(value, "targetIdTerritory")}
                                    />
                                </div>
                                <div>
                                    <FieldGroup direction="row" label="Fuel for the new model" required>
                                        {renderFuels}
                                    </FieldGroup>
                                    {projectFuels.length === 0 && targetIdProject !== null && (
                                        <ErrorMsg
                                            message={`This model can't be duplicated because ${
                                                projects?.find((project: Project) => project.idProject === targetIdProject)?.projectName
                                            } project doesn't have fuel.`}
                                        />
                                    )}
                                </div>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <Separator />
                    {isLoadingModelInputs ? (
                        <IconLoading />
                    ) : (
                        <>
                            {/* BASELINE TABLES */}
                            {modelInputs.equipmentinputstables !== undefined && (
                                <ModelInputsTableSelection
                                    idProject={idProject}
                                    idInputLog={idInputLog}
                                    title="Duplicate Baseline tables"
                                    tables={modelInputs.equipmentinputstables}
                                    displayInModal
                                    sheetSelections={targetSheetSelections}
                                    setSheetSelections={setTargetSheetSelections}
                                />
                            )}

                            {/* POTENTIAL TABLES */}
                            {modelInputs.nonequipmentinputstables !== undefined && (
                                <>
                                    {modelInputs.equipmentinputstables !== undefined && <Separator line />}
                                    <ModelInputsTableSelection
                                        idProject={idProject}
                                        idInputLog={idInputLog}
                                        title="Duplicate Potential tables"
                                        tables={modelInputs.nonequipmentinputstables}
                                        displayInModal
                                        sheetSelections={targetSheetSelections}
                                        setSheetSelections={setTargetSheetSelections}
                                    />
                                </>
                            )}

                            {/* ADDITIONAL TABLES */}
                            {modelInputs.extrainputstables !== undefined && (
                                <>
                                    {(modelInputs.equipmentinputstables !== undefined ||
                                        modelInputs.nonequipmentinputstables !== undefined) && <Separator line />}
                                    <ModelInputsTableSelection
                                        idProject={idProject}
                                        idInputLog={idInputLog}
                                        title="Duplicate Additional tables"
                                        tables={modelInputs.extrainputstables}
                                        displayInModal
                                        sheetSelections={targetSheetSelections}
                                        setSheetSelections={setTargetSheetSelections}
                                    />
                                </>
                            )}

                            {/* LOADMAP RESULT TABLES */}
                            {modelInputs.resulttables !== undefined && (
                                <>
                                    {(modelInputs.equipmentinputstables !== undefined ||
                                        modelInputs.nonequipmentinputstables !== undefined ||
                                        modelInputs.extrainputstables !== undefined) && <Separator line />}
                                    <ModelInputsTableSelection
                                        idProject={idProject}
                                        idInputLog={idInputLog}
                                        title="Duplicate LoadMAP Result tables"
                                        tables={modelInputs.resulttables}
                                        displayInModal
                                        sheetSelections={targetSheetSelections}
                                        setSheetSelections={setTargetSheetSelections}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
                <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                    <Button
                        variant="primary"
                        padding="lg"
                        isDisabled={
                            modelNameExists ||
                            isNullOrWhitespace(targetModelName) ||
                            targetIdClient === null ||
                            targetIdProject === null ||
                            targetIdFuel === null ||
                            isLoadingModelInputs
                        }
                        onClick={onDuplicateClick}
                    >
                        Duplicate
                    </Button>
                    <Button variant="secondary" padding="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                </IdsButtonGroup>
            </>
        );
    }
);

export interface DuplicateModelProps extends ModalOpenChildrenProps {
    idClient: number;
    idProject: number;
    idInputLog: number;
    idTerritory: number;
    idFuel: number;
    idModel?: number;
    onSuccess?: (action?: any, targetProjectId?: number) => void;
}

export default DuplicateModel;
