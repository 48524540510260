///
/// Model (Project Input) actions
/// resourceName: projectInputsResourceName, projectInputsInactiveResourceName
///
import { batch } from "react-redux";

import { clearInputNames } from "./projectInputNamesActions";
import { clearModelInputsProgress } from "store/resources/actions/modelInputs/modelInputsProgressActions";

import { projectInputsResourceName, projectInputsInactiveResourceName } from "store/configureResources";
import { MODEL_ACK_CHANGE } from "store/actionTypes";
import { broadcastSendEvent } from "store/brokerHub/actions";

import { useResource } from "store/resources/actions/useResource";
import { getResource, waitReadResource } from "store/resources/actions/getResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { copyModelLogNote } from "store/resources/actions/scenarioLog/modelLogNotesActions";

import {
    UseProjectInputsParams,
    ClearProjectInputsParams,
    WaitReadProjectInputsParams,
    DeleteProjectInputParams,
    UpdateProjectInputParams,
    AckChangeModelParams,
    GetProjectInputsParams,
} from "./types";

export const modelActions = {
    DELETED: "MODEL_DELETED",
    WASTED: "MODEL_WASTED",
    RESTORED: "MODEL_RESTORED",
};

export const useProjectInputs = ({ idProject, transform }: UseProjectInputsParams) =>
    useResource({
        resourceName: projectInputsResourceName,
        key: idProject ? `${projectInputsResourceName}-${idProject}` : undefined,
        query: { idProject },
        transform,
    });

export const getProjectInputs = ({ idProject, onComplete }: GetProjectInputsParams) =>
    getResource({
        resourceName: projectInputsResourceName,
        key: `${projectInputsResourceName}-${idProject}`,
        query: { idProject },
        onComplete,
    });

export const clearProjectInputs =
    ({ idProject }: ClearProjectInputsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: projectInputsResourceName,
                key: `${projectInputsResourceName}-${idProject}`,
                broadcast: true,
            })
        );

export const waitReadProjectInputs =
    ({ idProject, action }: WaitReadProjectInputsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            waitReadResource({
                resourceName: projectInputsResourceName,
                key: `${projectInputsResourceName}-${idProject}`,
                actionName: action,
            })
        );

export const deleteProjectInput =
    ({ idProject, idInputLog, action, onSuccess }: DeleteProjectInputParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(waitReadProjectInputs({ idProject, action }));

        dispatch(
            deleteResource({
                resourceName: projectInputsResourceName,
                path: { idInputLog: idInputLog },
                body: {
                    idProject: idProject,
                },
                onSuccess: () => {
                    batch(() => {
                        dispatch(clearProjectInputs({ idProject }));
                        dispatch(clearInputNames({ idProject }));
                        dispatch(clearProjectLogItems({ idProject }));
                        dispatch(clearModelInputsProgress({ idProject }));
                        dispatch(
                            broadcastSendEvent(modelActions.DELETED, {
                                idProject,
                                idInputLog,
                                lastModified: Date.now(),
                            })
                        );
                    });

                    onSuccess?.();
                },
            })
        );
    };

export const deleteAllInactiveProjectInputs =
    ({ idProject, action }: WaitReadProjectInputsParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(waitReadProjectInputs({ idProject, action }));

        dispatch(
            deleteResource({
                resourceName: projectInputsInactiveResourceName,
                query: { idProject: idProject },
                onSuccess: () => {
                    batch(() => {
                        dispatch(clearProjectInputs({ idProject }));
                        dispatch(
                            broadcastSendEvent(modelActions.DELETED, {
                                idProject,
                                lastModified: Date.now(),
                            })
                        );
                    });
                },
            })
        );
    };

// TODO: Optimistic update
export const updateProjectInput =
    ({
        idProject,
        idInputLog,
        idTerritory,
        idFuel,
        idAnalyst,
        idReviewer,
        datasetName,
        datasetDescription,
        modelState,
        required,
        active,
        action,
        userNote,
        isPublicNote,
        onSuccess,
        onComplete,
    }: UpdateProjectInputParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        dispatch(waitReadProjectInputs({ idProject, action }));

        dispatch(
            updateResource({
                resourceName: projectInputsResourceName,
                path: { idInputLog },
                body: {
                    idProject,
                    idTerritory,
                    idFuel,
                    idAnalyst,
                    idReviewer,
                    name: datasetName,
                    description: datasetDescription,
                    modelState,
                    required,
                    active,
                    lastUpdatedBy: getState().vdsmUser?.userNumber,
                },
                onSuccess: () => {
                    batch(() => {
                        dispatch(clearProjectInputs({ idProject }));
                        dispatch(clearInputNames({ idProject }));
                        dispatch(clearModelInputsProgress({ idProject, idInputLog }));
                        dispatch(clearModelInputsProgress({ idProject }));
                        dispatch(clearProjectLogItems({ idProject }));
                        dispatch(clearModelLogItems({ idProject, idInputLog }));

                        if (active !== undefined) {
                            dispatch(
                                broadcastSendEvent(active ? modelActions.RESTORED : modelActions.WASTED, {
                                    idProject,
                                    idInputLog,
                                    lastModified: Date.now(),
                                })
                            );
                        }

                        userNote && dispatch(copyModelLogNote({ idProject, idInputLog, userNote, isPublic: isPublicNote }));

                        onSuccess?.();
                    });
                },
                onError: () => {
                    dispatch(clearProjectInputs({ idProject }));
                },
                onComplete,
            })
        );
    };

export const ackChangeModel =
    ({ idProject, idInputLog }: AckChangeModelParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: MODEL_ACK_CHANGE,
            data: {
                idProject,
                idInputLog,
            },
        });
    };
