import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { store } from "store/configureStore";

import App from "./App";

import ErrorBoundary from "components/ui/ErrorBoundary";

// import registerServiceWorker from './registerServiceWorker';

import { isInIframe, disableBrowserNavigation } from "utils/dom";
import { registerLodashMixins } from "utils/lodash";

import "./style.scss";

disableBrowserNavigation();

registerLodashMixins();

localStorage.setItem("ids_theme_name", "aeg");

// Do not load app in iframe.
if (!isInIframe()) {
    const domNode = document.getElementById("root");
    const root = createRoot(domNode);
    const wrappedApp = (
        <Provider store={store}>
            {/* @ts-ignore */}
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </Provider>
    );
    root.render(wrappedApp);

    // registerServiceWorker();
}
