import cn from "classnames";

import { CardProps } from "components/ui/Cards/Card/types";

import "./style.scss";

export const Card = (props: CardProps) => {
    const classNames = cn("card", props.cardClassName, {
        selected: props.selected,
        disabled: props.disabled || props.status === "error",
        "read-only": props.readOnly,
        "card-in-progress": props.status === "RUNNING",
        "card-error": props.status === "DATA_MISSING" || props.status === "ERROR",
        "card-success": props.status === "CALCULATED",
        "card-add-new": props.addCard,
        "card-with-input": props.cardWithInput,
        "card-round-borders": props.roundBorders,
    });

    return (
        <div className={classNames} onClick={props.onClick}>
            {props.status && <div className="card__vertical-line" />}
            <div className={props.contentClassName}>{props.children}</div>
        </div>
    );
};
