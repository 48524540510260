import { forwardRef, useRef } from "react";
import cn from "classnames";
import { useAccordion } from "@react-aria/accordion";
import { useTreeState } from "@react-stately/tree";
import { filterDOMProps } from "@react-aria/utils";

import { AccordionItem } from "./AccordionItem";

import { AccordionRootProps } from "./types";

import "./style.scss";

const AccordionRoot = <T extends object>(props: AccordionRootProps<T>) => {
    const state = useTreeState<T>(props);
    const ref = useRef<HTMLDivElement | null>(null);
    const { accordionProps } = useAccordion({ ...props }, state, ref);

    return (
        <div {...filterDOMProps(props)} {...accordionProps} ref={ref} className={cn("accordion-root", props.className)}>
            {[...state.collection].map((item) => (
                <AccordionItem<T> key={item.key} item={item} state={state} />
            ))}
        </div>
    );
};

export const Accordion = forwardRef(AccordionRoot) as <T>(props: AccordionRootProps<T>) => ReturnType<typeof AccordionRoot>;

export { Item } from "@react-stately/collections";
