import { useEffect, useCallback, useMemo, useState } from "react";

import FileList from "./FileList";
import FolderChain from "./FolderChain";

import { FileNavigatorProps } from "./types";

const FileNavigator = ({ rootFolderId, fileMap, onDownload, onDelete }: FileNavigatorProps) => {
    const [currentFolderId, setCurrentFolderId] = useState("");

    useEffect(() => {
        // If root folder ID has changed that means
        // that a new file was uploaded and all
        // IDs have changed
        setCurrentFolderId(rootFolderId);
    }, [rootFolderId]);

    const files = useMemo(() => {
        const currentFolder = fileMap[currentFolderId];

        const files = !currentFolder?.childrenIds
            ? []
            : currentFolder.childrenIds.map((cId: any) => {
                  const file = fileMap[cId];

                  return file ? file : null;
              });

        return files;
    }, [fileMap, currentFolderId]);

    const folderChain = useMemo(() => {
        const folderChain = [];
        const currentFolder = fileMap[currentFolderId];

        if (currentFolder) {
            folderChain.push(currentFolder);

            let parentId = currentFolder?.parentId;

            while (parentId) {
                const parentFile = fileMap[parentId];

                if (parentFile) {
                    folderChain.unshift(parentFile);

                    parentId = parentFile.parentId;
                } else {
                    parentId = null;
                }
            }
        }

        return folderChain;
    }, [fileMap, currentFolderId]);

    const onStepBackClick = useCallback(() => {
        const fileBeforeLast = folderChain[folderChain.length - 2];

        setCurrentFolderId(fileBeforeLast.id);
    }, [folderChain]);

    const onDirectoryClick = useCallback((file) => {
        setCurrentFolderId(file.id);
    }, []);

    return (
        <div className="file-navigator">
            <FolderChain folderChain={folderChain} onStepBackClick={onStepBackClick} onDirectoryClick={onDirectoryClick} />
            <FileList
                files={files}
                folderChain={folderChain}
                onDirectoryClick={onDirectoryClick}
                onDownload={onDownload}
                onDelete={onDelete}
            />
        </div>
    );
};

export default FileNavigator;
