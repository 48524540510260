import { connect } from "react-redux";
import cn from "classnames";
import ReactModal from "react-modal";

import { modalClose } from "store/modal/actions";

import Button from "components/ui/Button";
import IconWrap from "components/ui/Icons";

import "../style.scss";

const ConfirmModal = ({
    title,
    modalIcon,
    bodyOpenClassName,
    overlayClassName,
    className,
    content,
    dispatch,
    footerContentCenter = false,
    okButtonText = "Yes",
    okButtonIcon,
    cancelButtonText = "No",
    cancelButtonIcon,
    onConfirm = () => {},
    onClose = () => {},
}) => (
    <ReactModal
        isOpen
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => dispatch(modalClose())}
        bodyOpenClassName={cn("", bodyOpenClassName)}
        overlayClassName={cn("", overlayClassName)}
        className={`modal confirm-modal ${className}`}
    >
        <div className="modal-content">
            {title && (
                <div className={cn("modal-header")}>
                    {modalIcon && <IconWrap icon={modalIcon} />}
                    {title}
                    <IconWrap
                        icon="clear-close"
                        title="Close"
                        onClick={() => {
                            dispatch(modalClose());
                            onClose();
                        }}
                    />
                </div>
            )}
            <div className="modal-body">
                <div className="flex-column">{content}</div>
            </div>
            <div className={"modal-footer" + (footerContentCenter ? " center" : "")}>
                <Button
                    icon={okButtonIcon}
                    primary
                    className="modal-close"
                    onClick={() => {
                        dispatch(modalClose());
                        onConfirm();
                    }}
                >
                    {okButtonText}
                </Button>
                <Button
                    icon={cancelButtonIcon}
                    className="modal-close"
                    onClick={() => {
                        dispatch(modalClose());
                        onClose();
                    }}
                >
                    {cancelButtonText}
                </Button>
            </div>
        </div>
    </ReactModal>
);
export default connect()(ConfirmModal);
