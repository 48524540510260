import { isString } from "lodash";
import { useCallback, memo, useRef, useEffect, useState } from "react";
import cn from "classnames";
import ReactDatePicker from "react-datepicker";

import Label from "components/ui/Label";

import { useFocusClasses } from "utils/useFocusClasses";
import { compareDateWithoutTime, DATEPICKER_DATE_FORMAT, jsonDateToDate } from "utils/date";

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const DatePicker = memo((props) => {
    const {
        className,
        id,
        name,
        placeholder,
        value,
        onChange,
        onKeyDown,
        disabled,
        readOnly,
        ghost,
        minDate,
        maxDate,
        autocomplete = false,
        showTime,
        error,
        title,
        popperContainer,
        popperProps,
        popperClassName,
    } = props;

    const datePickerRef = useRef();

    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({ disabled });

    const onFocus = useCallback(
        (event) => {
            onFocusClassesFocus(event);
            props.onFocus && props.onFocus(event);
        },
        [onFocusClassesFocus, props]
    );

    const onBlur = useCallback(
        (event) => {
            onFocusClassesBlur(event);
            props.onBlur && props.onBlur(event);
        },
        [onFocusClassesBlur, props]
    );

    const onPickerKeyDown = useCallback(
        (event) => {
            // Close datepicker on Tab and move to next field
            if (event.key === "Tab") {
                datePickerRef.current.setOpen(false);
            }
            onKeyDown && onKeyDown(event, datePickerRef.current.props.value);
        },
        [onKeyDown]
    );

    const onPickerChange = useCallback(
        (date) => {
            onChange && onChange({ value: date });
        },
        [onChange]
    );

    const dateFormat = showTime ? DATEPICKER_DATE_FORMAT + " h:mm aa" : DATEPICKER_DATE_FORMAT;

    // Set selected value only if value is date.
    // If value is a string it will be shown as is in input field.
    const selectedDate = isString(value) ? undefined : value;

    const parsedMinDate = minDate ? jsonDateToDate(minDate) : undefined;
    const parsedMaxDate = maxDate ? jsonDateToDate(maxDate) : undefined;
    const todayDate = new Date();
    const isTodayEarlierThanMinDate = compareDateWithoutTime(todayDate, parsedMinDate) === -1;
    const isTodayLaterThanMaxDate = compareDateWithoutTime(todayDate, parsedMaxDate) === 1;
    const todayButtonEnabled = !(isTodayEarlierThanMinDate || isTodayLaterThanMaxDate);
    const [openDatePicker, setOpenDatePicker] = useState(true);

    useEffect(() => {
        setOpenDatePicker(true);
    }, [openDatePicker]);

    return (
        <>
            {openDatePicker && (
                <>
                    {props.label && <Label>{props.label}</Label>}
                    <ReactDatePicker
                        ref={datePickerRef}
                        id={id}
                        name={name}
                        className={cn("datepicker", className, {
                            error: error,
                            "read-only": readOnly,
                            disabled: disabled,
                            "datepicker-ghost": ghost,
                            "input-table-filter": props.inputTableFilter,
                        })}
                        wrapperClassName={cn({ "datepicker-wrapper-ghost": ghost })}
                        autoComplete={autocomplete ? "on" : "off"}
                        todayButton={todayButtonEnabled ? "Today" : undefined}
                        dateFormat={dateFormat}
                        timeInputLabel="Time:"
                        showTimeSelect={showTime}
                        timeIntervals={1}
                        selected={selectedDate}
                        value={value}
                        onChange={onPickerChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onPickerKeyDown}
                        disabled={disabled}
                        readOnly={readOnly}
                        title={title}
                        minDate={parsedMinDate}
                        maxDate={parsedMaxDate}
                        placeholderText={placeholder}
                        popperContainer={popperContainer}
                        popperClassName={popperClassName}
                        popperProps={popperProps}
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: {
                                behavior: ["bottom"], // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false, // turn off since needs preventOverflow to be enabled
                            },
                        }}
                    />
                </>
            )}
        </>
    );
});

export default DatePicker;
