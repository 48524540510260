import {
    API_DELETE_AUTHORIZED,
    API_CRUD_DELETE_REQUEST,
    API_CRUD_DELETE_SUCCESS,
    API_CRUD_DELETE_ERROR,
    API_CRUD_DELETE_ITEM,
} from "store/actionTypes";
import { getResourceUrl } from "store/utils";

import { optimisticUpdateItem, OptimisticUpdateItemParams } from "./updateResource";

import { IndexSignature } from "types/types";

/**
 * Removes resource from database.
 */
export const deleteResource =
    ({
        resourceName,
        resourceId,
        key,
        path = {},
        query = {},
        body,
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        optimisticUpdate,
        onSuccess,
        onError,
        onComplete,
        updateFromResponse = false,
    }: DeleteResourceParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: API_DELETE_AUTHORIZED,
            url: getResourceUrl(resourceName, path) + (resourceId ? "/" + resourceId : ""),
            query: {
                // Remove query params with empty value
                ...Object.keys(query)
                    // @ts-ignore
                    .filter((key) => query[key] !== null && query[key] !== undefined)
                    // @ts-ignore
                    .reduce((obj, key) => (obj = { ...obj, [key]: query[key] }), {}),
            },
            body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
            actionTypes: {
                pending: API_CRUD_DELETE_REQUEST,
                response: API_CRUD_DELETE_SUCCESS,
                error: API_CRUD_DELETE_ERROR,
            },
            passThroughData: {
                resourceName,
                resourceId,
                path,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onSuccess,
                onError,
                onComplete,
                updateFromResponse,
            },
        });

        if (optimisticUpdate) {
            dispatch(
                optimisticUpdateItem({
                    value: (optimisticUpdate as OptimisticUpdateItemParams).value,
                    resourceName,
                    resourceId,
                    key,
                })
            );
        }
    };

/**
 * Removes item from resource.
 */
export const deleteItem =
    ({ resourceName, key }: DeleteItemParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: API_CRUD_DELETE_ITEM,
            key,
            passThroughData: {
                resourceName,
            },
        });
    };

interface DeleteResourceParams {
    /**
     * Name of the resource property.
     */
    key?: string;

    /**
     * Name of the resource.
     */
    resourceName: string;

    /**
     * ID of resource.
     */
    resourceId?: string;

    path?: IndexSignature<any>;

    query?: object;

    body?: object;

    /**
     * Update item in Redux store.
     */
    optimisticUpdate?: OptimisticUpdateItemParams | boolean;

    updateFromResponse?: boolean;

    /**
     * Display notification on success.
     */
    showSuccessNotification?: boolean;

    /**
     * Display notification on error.
     */
    showErrorNotification?: boolean;

    successMessage?: string;

    errorMessage?: string;

    /**
     * Function to run on success.
     */
    onSuccess?: (action: any) => void;

    /**
     * Function to run on error.
     */
    onError?: () => void;

    /**
     * Function to run on completion.
     */
    onComplete?: () => void;
}

interface DeleteItemParams {
    /**
     * Name of the resource property.
     */
    key: string | RegExp;

    /**
     * Name of the resource.
     */
    resourceName: string;
}
