import { SELECT_MODULE } from "store/actionTypes";

import { ModuleState, ModuleAction } from "./types";

const initialState: ModuleState = {
    selection: {},
};

export const reducer = (state = initialState, action: ModuleAction) => {
    switch (action.type) {
        case SELECT_MODULE:
            {
                const { viewIndex, idModel, moduleId } = action.payload;

                const selectedModules = state.selection[idModel] || [];

                selectedModules[viewIndex] = { moduleId };

                state = {
                    ...state,
                    selection: {
                        ...state.selection,
                        [idModel]: selectedModules,
                    },
                };
            }

            break;

        default:
            break;
    }

    return state;
};
