import { memo, useCallback, useState } from "react";

import ProjectVariables from "./ProjectVariables";

import Button from "components/ui/ButtonNew";
import DashboardPanel from "components/ui/Dashboard";

import { projectReadOnlyEnabled } from "pages/utils";

import { PROJECT_VARIABLES } from "utils/constants";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

const ProjectVariablesPanel = memo(({ project }: ProjectDashboardPanelProps) => {
    const [isEditing, setIsEditing] = useState(false);

    const onChangeClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const onCancelClick = useCallback(() => {
        setIsEditing(false);
    }, []);

    const headerAction =
        !isEditing && !projectReadOnlyEnabled(project.projectState) && hasAnyOfPermissions([USER_ACTIONS.PROJECT_VARIABLES_EDIT]) ? (
            <Button variant="tertiary" padding="md" iconLeft="ui-pencil__edit__create_b_s" iconSize="sm" onClick={onChangeClick}>
                Change Project Variables
            </Button>
        ) : undefined;

    return (
        <DashboardPanel className="project-variables-panel" title="Variables" headerAction={headerAction} pageId={PROJECT_VARIABLES}>
            <ProjectVariables project={project} editing={isEditing} onEditCancel={onCancelClick} />
        </DashboardPanel>
    );
});

export default ProjectVariablesPanel;
