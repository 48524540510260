import { isNil } from "lodash";

import { scenarioLogResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { getResource } from "store/resources/actions/getResource";
import { clearResource } from "store/resources/actions/clearResource";

import {
    ProjectLogItemsParams,
    GetProjectLogItemsParams,
    ModelLogItemsParams,
    StandaloneReportLogItemsParams,
    GetScenarioLogKeyParams,
} from "./types";

/**
 * Helper function to get Scenario Log key.
 *
 *  - Scenario Log key for Project Log - 'scenario-log-{idProject}';
 *  - Scenario Log key for Legacy Model Log - 'scenario-log-{idProject}-{idInputLog}';
 *  - Scenario Log key for Component Model Log - 'scenario-log-${idProject}-${idModel}';
 *  - Scenario Log key for Standalone Report Log - 'scenario-log-{idProject}-{idReport}'.
 */
export const getScenarioLogKey = ({ idProject, idInputLog, idModel, idReport, withoutResourceName }: GetScenarioLogKeyParams) => {
    let scenarioLogKey = `${idProject}`;

    if (!withoutResourceName) {
        scenarioLogKey = `${scenarioLogResourceName}-${idProject}`;
    }

    if (!isNil(idModel)) {
        scenarioLogKey += `-component-model-${idModel}`;
    } else if (!isNil(idInputLog)) {
        scenarioLogKey += `-legacy-model-${idInputLog}`;
    } else if (!isNil(idReport)) {
        scenarioLogKey += `-standalone-report-${idReport}`;
    }

    return scenarioLogKey;
};

export const useProjectLogItems = ({ idProject }: ProjectLogItemsParams) =>
    useResource({
        resourceName: scenarioLogResourceName,
        key: getScenarioLogKey({ idProject }),
        query: {
            idProject,
        },
        transform: (data) => data?.model,
    });

export const getProjectLogItems =
    ({ idProject, onSuccess }: GetProjectLogItemsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            getResource({
                resourceName: scenarioLogResourceName,
                key: getScenarioLogKey({ idProject }),
                query: {
                    idProject,
                },
                onSuccess,
            })
        );

export const useModelLogItems = ({ idProject, idInputLog, idModel }: ModelLogItemsParams) =>
    useResource({
        resourceName: scenarioLogResourceName,
        key: getScenarioLogKey({ idProject, idInputLog, idModel }),
        query: {
            idProject,
            idInputLog,
            idModel,
        },
        transform: (data) => data?.model,
    });

export const useStandaloneReportLogItems = ({ idProject, idReport }: StandaloneReportLogItemsParams) =>
    useResource({
        resourceName: scenarioLogResourceName,
        key: getScenarioLogKey({ idProject, idReport }),
        query: {
            idProject,
            idReport,
        },
        transform: (data) => data?.model,
    });

export const clearProjectLogItems =
    ({ idProject }: ProjectLogItemsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: scenarioLogResourceName,
                key: getScenarioLogKey({ idProject }),
                broadcast: true,
            })
        );

export const clearModelLogItems =
    ({ idProject, idInputLog, idModel }: ModelLogItemsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: scenarioLogResourceName,
                key: getScenarioLogKey({ idProject, idInputLog, idModel }),
                broadcast: true,
            })
        );

export const clearStandaloneReportLogItems =
    ({ idProject, idReport }: StandaloneReportLogItemsParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: scenarioLogResourceName,
                key: getScenarioLogKey({ idProject, idReport }),
                broadcast: true,
            })
        );

export const clearAllLogItems =
    ({ idProject }: ProjectLogItemsParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: scenarioLogResourceName,
                key: new RegExp(`^${scenarioLogResourceName}-${idProject}(-[0-9]+){0,1}$`),
                broadcast: true,
            })
        );
    };
