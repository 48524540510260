import { moduleInputsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

import { CommonModuleParams, ModuleInput } from "./types";

export const useModuleInputs = ({ moduleId }: CommonModuleParams) =>
    useResource({
        resourceName: moduleInputsResourceName,
        key: `${moduleInputsResourceName}-${moduleId}`,
        path: {
            moduleId,
        },
    }) as [ModuleInput[], boolean | undefined, any];

export const clearModuleInputs =
    ({ moduleId }: CommonModuleParams) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: moduleInputsResourceName,
                key: `${moduleInputsResourceName}-${moduleId}`,
                broadcast: true,
            })
        );

export const clearAllModuleInputs = () => (dispatch) =>
    dispatch(
        clearResource({
            resourceName: moduleInputsResourceName,
            key: new RegExp(`^${moduleInputsResourceName}(-[0-9]+){0,1}$`),
            broadcast: true,
        })
    );
