import { isEmpty, isNil } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { selectProjection } from "store/projections/actions";

import { updateProjectInput, deleteProjectInput } from "store/resources/actions/projectInput/projectInputActions";
import { updateComponentModel, deleteComponentModel } from "store/resources/actions/componentModel/componentModelActions";
import { updateStandaloneReport, deleteStandaloneReport } from "store/resources/actions/standaloneReport/standaloneReportActions";

import CustomList from "components/ui/List/CustomList";
import IconLoading from "components/ui/Icons/IconLoading";
import LabelWithIcon from "components/ui/LabelWithIcon";
import NothingFoundBlock from "components/ui/NothingFoundBlock";
import UserName from "components/ui/UserName";

import { openModalDialogRestoreProjection, openModalDialogDeletePermanentlyProjection } from "layouts/Modal/utils";

import { projections } from "utils/constants";
import { USER_ACTIONS } from "utils/user/defines";
import { hasAnyOfPermissions } from "utils/user";
import { sortObjectsByDate } from "utils/date";
import { toLocaleDateTime } from "utils/dateTime";

import { TrashBinLogProps } from "pages/ManageProject/ProjectionsContent/TrashBinDashboard/TrashBinLogPanel/types";
import { StandaloneReport } from "store/resources/actions/standaloneReport/types";

import "./style.scss";

const headers = {
    projectionName: "model/report",
    modified: "modified",
    modifiedBy: "by",
    actions: "",
};

export const TrashBinLog = memo(({ idProject, viewIndex, modelsInTrash, standaloneReportsInTrash }: TrashBinLogProps) => {
    const dispatch = useDispatch();

    const projectionsInTrash = useMemo(() => {
        let projectionsInTrash: any[] = [];

        const itemsInTrash = (modelsInTrash || []).concat(standaloneReportsInTrash || []);

        projectionsInTrash = sortObjectsByDate(itemsInTrash, "lastUpdateDate") || [];

        return projectionsInTrash;
    }, [modelsInTrash, standaloneReportsInTrash]);

    const onDeleteSuccess = useCallback(() => {
        dispatch(selectProjection({ viewIndex, idProjectionView: projections.PROJECT_DASHBOARD, idProject }));
    }, [idProject, viewIndex, dispatch]);

    const onMoveModel = useCallback(
        (idInputLog) => {
            dispatch(
                updateProjectInput({
                    idProject,
                    idInputLog,
                    active: true,
                    action: "Restoring Model",
                })
            );
        },
        [idProject, dispatch]
    );

    const onDeleteModel = useCallback(
        (idInputLog) => {
            dispatch(
                deleteProjectInput({
                    idProject,
                    idInputLog,
                    action: "Deleting Model",
                    onSuccess: onDeleteSuccess,
                })
            );
        },
        [idProject, onDeleteSuccess, dispatch]
    );

    const onMoveComponentModel = useCallback(
        (idModel) => {
            dispatch(
                updateComponentModel({
                    idProject,
                    idModel,
                    active: true,
                    action: "Restoring Model",
                })
            );
        },
        [idProject, dispatch]
    );

    const onDeleteComponentModel = useCallback(
        (idModel) => {
            dispatch(
                deleteComponentModel({
                    idProject,
                    idModel,
                    onSuccess: onDeleteSuccess,
                })
            );
        },
        [idProject, onDeleteSuccess, dispatch]
    );

    const onMoveStandaloneReport = useCallback(
        (idReport) => {
            let formData = new FormData();

            formData.append("idProject", idProject.toString());
            formData.append("idReport", idReport.toString());
            formData.append("active", "true");

            dispatch(
                updateStandaloneReport({
                    idProject,
                    idReport,
                    formData,
                    action: "Restoring Standalone Report",
                })
            );
        },
        [idProject, dispatch]
    );

    const onDeleteStandaloneReport = useCallback(
        (idReport) => {
            const selectedReport = standaloneReportsInTrash.find(
                (standaloneReport: StandaloneReport) => standaloneReport.idReport === idReport
            );

            if (selectedReport) {
                dispatch(
                    deleteStandaloneReport({
                        idProject,
                        idReport,
                        reportType: selectedReport.reportType,
                        filename: selectedReport.filename,
                        action: "Deleting Standalone Report",
                        onSuccess: onDeleteSuccess,
                    })
                );
            }
        },
        [idProject, standaloneReportsInTrash, onDeleteSuccess, dispatch]
    );

    const onRestoreClick = useCallback(
        (idProjection, projectionName, projectionType) => {
            switch (projectionType) {
                case "MODEL":
                    dispatch(
                        openModalDialogRestoreProjection({
                            title: "Restore Model",
                            text: (
                                <>
                                    <div>
                                        <strong>{projectionName}</strong> will be restored to the Project.
                                    </div>
                                    <div>You will be able to import data and run calculations.</div>
                                </>
                            ),
                            onRestore: () => onMoveModel(idProjection),
                        })
                    );

                    break;
                case "COMPONENT MODEL":
                    dispatch(
                        openModalDialogRestoreProjection({
                            title: "Restore Model",
                            text: (
                                <>
                                    <div>
                                        <strong>{projectionName}</strong> will be restored to the Project.
                                    </div>
                                    <div>You will be able to import data and run calculations.</div>
                                </>
                            ),
                            onRestore: () => onMoveComponentModel(idProjection),
                        })
                    );

                    break;
                case "STANDALONE REPORT":
                    dispatch(
                        openModalDialogRestoreProjection({
                            title: "Restore Standalone Report",
                            text: (
                                <div>
                                    <strong>{projectionName}</strong> will be restored to the Project.
                                </div>
                            ),
                            onRestore: () => onMoveStandaloneReport(idProjection),
                        })
                    );

                    break;
                default:
            }
        },
        [onMoveModel, onMoveComponentModel, onMoveStandaloneReport, dispatch]
    );

    const onDeleteClick = useCallback(
        (idProjection, projectionName, projectionType) => {
            switch (projectionType) {
                case "MODEL":
                    dispatch(
                        openModalDialogDeletePermanentlyProjection({
                            title: "Delete Model Permanently",
                            projectionName,
                            onDelete: () => onDeleteModel(idProjection),
                        })
                    );

                    break;
                case "COMPONENT MODEL":
                    dispatch(
                        openModalDialogDeletePermanentlyProjection({
                            title: "Delete Model Permanently",
                            projectionName,
                            onDelete: () => onDeleteComponentModel(idProjection),
                        })
                    );

                    break;
                case "STANDALONE REPORT":
                    dispatch(
                        openModalDialogDeletePermanentlyProjection({
                            title: "Delete Standalone Report Permanently",
                            projectionName,
                            onDelete: () => onDeleteStandaloneReport(idProjection),
                        })
                    );

                    break;
                default:
            }
        },
        [onDeleteModel, onDeleteComponentModel, onDeleteStandaloneReport, dispatch]
    );

    const renderItem = (item: any, index: number) => {
        const idInputLog = item.idInputLog;
        const idModel = item.idModel;
        const idReport = item.idReport;

        let idProjection: number | undefined;
        let projectionType: string;

        if (!isNil(idInputLog)) {
            idProjection = idInputLog;
            projectionType = "MODEL";
        } else if (!isNil(idModel)) {
            idProjection = idModel;
            projectionType = "COMPONENT MODEL";
        } else if (!isNil(idReport)) {
            idProjection = idReport;
            projectionType = "STANDALONE REPORT";
        }

        return (
            <div key={`timestamp-${idInputLog ? `model-${idInputLog}` : `custom-report-${idReport}`}-${index}`} className="list-item-row">
                <div className="item-value column-model-name">{item.name}</div>
                <div className="item-value column-modified">{toLocaleDateTime(`${item.lastUpdateDate}Z`) || ""}</div>
                <div className="item-value column-modified-by">
                    {/* @ts-ignore - remove when UserName is refactored to TypeScript */}
                    <UserName userNumber={item.lastUpdatedBy} />
                </div>
                {((projectionType.includes("MODEL") && hasAnyOfPermissions([USER_ACTIONS.MODEL_DELETE])) ||
                    (projectionType === "STANDALONE REPORT" && hasAnyOfPermissions([USER_ACTIONS.SA_REPORT_DELETE]))) && (
                    <div className="item-value column-actions">
                        <div className="flex-row align-center">
                            <LabelWithIcon
                                className="margin-left"
                                icon="arrows-reply_b_a"
                                iconSize="xs"
                                onClick={() => onRestoreClick(idProjection, item.name, projectionType)}
                            >
                                Restore
                            </LabelWithIcon>
                            <LabelWithIcon
                                className="margin-left"
                                icon="ui-trash__garbage__delete__remove__bin_b_s"
                                iconSize="xs"
                                onClick={() => onDeleteClick(idProjection, item.name, projectionType)}
                            >
                                Delete
                            </LabelWithIcon>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            {modelsInTrash === undefined || standaloneReportsInTrash === undefined ? (
                <IconLoading />
            ) : (
                <div className="flex-column fill-height trash-bin-log">
                    {isEmpty(projectionsInTrash) ? (
                        <div className="flex-column fill-height">
                            <NothingFoundBlock icon="ui-trash__garbage__delete__remove__bin_b_s" title="Trash bin is currently empty" />
                        </div>
                    ) : (
                        <CustomList
                            // @ts-ignore - remove when CustomList is refactored to TypeScript
                            headers={headers}
                            items={projectionsInTrash}
                            renderItem={renderItem}
                            lastColumnToRight
                        />
                    )}
                </div>
            )}
        </>
    );
});

export default TrashBinLog;
