import cn from "classnames";
import { get, isEmpty, isNil, kebabCase } from "lodash";
import { memo, useCallback, useEffect, useMemo } from "react";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    // RUN_MODULE_START,
    // PAUSE_MODULE_START,
    RUN_MODULE_V2_START,
    PAUSE_MODULE_V2_START,
    SET_PENDING_MODULE_V2_ACTIVE,
} from "store/actionTypes";

import { setupRunModuleEventSource } from "store/runModuleV2/actions";

import { updateModule } from "store/resources/actions/calculationWorkflow/modulesActions";
import { useAllModuleRuns } from "store/resources/actions/calculationWorkflow/moduleRunsActions";
import { useLastModuleRun } from "store/resources/actions/calculationWorkflow/moduleRunsActions";

import Button from "components/ui/ButtonNew";
import TagWithDropdown from "components/ui/Dropdown/TagWithDropdown";
import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import { Card } from "components/ui/Cards";
import Label from "components/ui/Label";
import Link from "components/ui/Link";
import { StaticProgressBar } from "components/ui/ProgressBar";
import StepBox from "components/ui/StepBox";
import Tag from "components/ui/Tag";

import { openModalDialogClientReviewModule, openModalDialogSetInClientReviewError } from "layouts/Modal/CalculationWorkflowModal/utils";

import { actionAllowed } from "pages/utils";

import { useHaveRights } from "utils/useHaveRights";
import { useUserRights } from "utils/useUserRights";
import { allComponentModelStates, allModuleStates, moduleTypeLabels, moduleRunState, moduleStates } from "utils/constants";
import { d } from "utils/date";
import { toLocaleDateTime } from "utils/dateTime";
import { hasAnyOfPermissions, hasInternalUserRole } from "utils/user";
import { USER_ACTIONS, USER_ROLES } from "utils/user/defines";
import { openWindowSingleModelReport } from "utils/window";

import { RunV2Module } from "store/runModuleV2/types";
import { RunState, ModuleState } from "store/resources/actions/calculationWorkflow/types";
import { ModuleCardProps } from "./types";

import "./style.scss";

const ModuleCard = memo(
    ({
        dataTestId,
        idClient,
        idProject,
        idAnalyst,
        idReviewer,
        idModel,
        idInputLog,
        moduleId,
        moduleLevel,
        moduleOrder,
        moduleState,
        runState,
        studyCase,
        type,
        modelName,
        modelState,
        modelRequired,
        selected,
        runPending,
        setRunPending,
        onClick,
    }: ModuleCardProps) => {
        const dispatch = useDispatch();

        // @ts-ignore
        const userName = useSelector((state) => state.user.userName);

        const runModule: RunV2Module = useSelector((state) => get(state, `runModuleV2.${idModel}-${moduleId}`));

        // Resources

        const [allModuleRuns, isLoadingAllModuleRuns] = useAllModuleRuns({ moduleId: typeof moduleId === "number" ? moduleId : undefined });
        const [lastModuleRun, isLoadingLastModuleRun] = useLastModuleRun({ moduleId: typeof moduleId === "number" ? moduleId : undefined });

        const cardClassNames = cn("module-card", {
            "module-card-secondary": moduleLevel === 1,
            "module-card-tertiary": moduleLevel === 2,
        });

        const latestRun = allModuleRuns && allModuleRuns[0];

        let tagIcon = "";

        if (runState === "DATA_MISSING" || (runState === "ERROR" && runModule?.status !== "IN_PROGRESS")) {
            tagIcon = "text-alert__error_b_f";
        } else if (runState === "RECALC_PENDING" && runModule?.status !== "IN_PROGRESS") {
            tagIcon = "text-warning__report_problem_b_f";
        }

        const userRights = useUserRights();

        const haveAnalystRights = useHaveRights({ entityId: idAnalyst, userName });
        const haveReviewerRights = useHaveRights({ entityId: idReviewer, userName });

        const modelReadOnlyEnabled = modelState === allComponentModelStates.FINAL.toUpperCase() && Boolean(modelRequired) === true;

        // Effects

        useEffect(() => {
            // Set Event Source if latest run status is in progress
            // and runModule state is undefined or waiting (in pending Modules case).
            //
            // NOTE: This use case is only for running pending Modules,
            // users that open Calculation Workflow page when run is already in progress
            // or when browser has been refreshed
            if (!isNil(latestRun) && !isLoadingLastModuleRun) {
                if (latestRun.status === "IN_PROGRESS" && (runModule === undefined || runModule?.waiting === true)) {
                    if (runModule?.waiting === true) {
                        dispatch({
                            type: SET_PENDING_MODULE_V2_ACTIVE,
                            payload: {
                                idModel,
                                moduleId,
                            },
                        });
                    }

                    dispatch(
                        setupRunModuleEventSource({
                            idProject,
                            idModel,
                            moduleId,
                            runId: latestRun.modelRunId,
                            status: latestRun.status,
                            // Do not need to clear Last Module Run resource
                            // when pending Modules are running
                            reconnect: !runPending,
                        })
                    );
                }
            }
        }, [idProject, idModel, moduleId, runPending, runModule, latestRun, isLoadingLastModuleRun, dispatch]);

        // Memos

        const moduleName = useMemo(() => `${moduleTypeLabels[type]} | ${studyCase}`, [type, studyCase]);

        const moduleStateItems = useMemo(() => {
            let moduleStateItems: any[] = [];

            if (haveAnalystRights && moduleStates[USER_ROLES.ANALYST].hasOwnProperty(moduleState.toUpperCase())) {
                moduleStateItems = moduleStates[USER_ROLES.ANALYST][moduleState.toUpperCase()].map((status) => ({
                    label: status,
                    value: status.toUpperCase(),
                }));
            }
            if (haveReviewerRights && moduleStates[USER_ROLES.MANAGER].hasOwnProperty(moduleState.toUpperCase())) {
                moduleStateItems = moduleStates[USER_ROLES.MANAGER][moduleState.toUpperCase()].map((status) => ({
                    label: status,
                    value: status.toUpperCase(),
                }));
            }

            return moduleStateItems;
        }, [moduleState, haveAnalystRights, haveReviewerRights]);

        const runProgress = useMemo(() => {
            let runProgress = 0;

            if (latestRun?.status === "IN_PROGRESS" && !isEmpty(lastModuleRun) && !isLoadingLastModuleRun) {
                const totalSteps = lastModuleRun.length;
                let lastCompletedIndex = -1;

                // If runModule.completedSteps is undefined,
                // track progress from module-last-run resource
                //
                // NOTE: This use case is only for users that open Calculation Workflow page
                // when run is already in progress or when browser has been refreshed
                if (runModule?.completedSteps === undefined) {
                    lastCompletedIndex = lastModuleRun.findLastIndex((moduleRun) => moduleRun.status === "DONE");
                }
                // If runModule.completedSteps is defined,
                // track progress from runModule state
                else {
                    const lastCompletedStep = runModule.completedSteps[runModule.completedSteps.length - 1];
                    lastCompletedIndex = lastModuleRun.findLastIndex((moduleRun) => moduleRun.step === lastCompletedStep);
                }

                if (lastCompletedIndex !== -1) {
                    runProgress = Math.floor(((lastCompletedIndex + 1) / totalSteps) * 100);
                }
            }

            return runProgress;
        }, [runModule, lastModuleRun, isLoadingLastModuleRun, latestRun]);

        const changeModuleStatus = useCallback(
            (moduleStatus: ModuleState) => {
                dispatch(
                    updateModule({
                        idProject,
                        idModel,
                        idInputLog,
                        moduleId,
                        moduleState: moduleStatus,
                    })
                );
            },
            [idProject, idModel, idInputLog, moduleId, dispatch]
        );

        const onResultClick = useCallback(
            (resultsId, finished) => {
                dispatch(
                    openWindowSingleModelReport({
                        idClient,
                        idProject,
                        idInputLog,
                        idModel,
                        filterEntityId: resultsId,
                        title: modelName,
                        subTitle: `${studyCase.toUpperCase()} - ${toLocaleDateTime(`${finished}Z`)}`,
                        showApprovement:
                            moduleState === allModuleStates.IN_CLIENT_REVIEW.toUpperCase() &&
                            hasAnyOfPermissions([USER_ACTIONS.MODULES_APPROVE]),
                    })
                );
            },
            [idClient, idProject, idInputLog, idModel, studyCase, modelName, moduleState, dispatch]
        );

        const onModuleStatusChange = useCallback(
            (moduleName, moduleStatus) => {
                if (moduleStatus === allModuleStates.IN_CLIENT_REVIEW.toUpperCase()) {
                    // If Model is set to In Progress,
                    // Modal window with a message pops up
                    // that user has to confirm
                    if (modelState === allComponentModelStates.IN_PROGRESS.toUpperCase()) {
                        dispatch(
                            openModalDialogClientReviewModule({
                                moduleName,
                                onChange: () => changeModuleStatus(moduleStatus),
                            })
                        );
                    }
                    // If Model is not set to In Progress,
                    // Modal window with an error pops up
                    else {
                        dispatch(
                            openModalDialogSetInClientReviewError({
                                modelName,
                                moduleName,
                            })
                        );
                    }
                } else {
                    changeModuleStatus(moduleStatus);
                }
            },
            [modelName, modelState, changeModuleStatus, dispatch]
        );

        const renderButton = useCallback(
            (currentState: RunState, currentStatus?: string) => {
                const onRunClick = (event: React.MouseEvent<HTMLIdsButtonElement, MouseEvent>) => {
                    event.stopPropagation();

                    // dispatch({
                    //     type: RUN_MODULE_START,
                    //     payload: {
                    //         idProject,
                    //         idModel,
                    //         moduleId,
                    //     },
                    // });

                    setRunPending(false);

                    dispatch({
                        type: RUN_MODULE_V2_START,
                        payload: {
                            idProject,
                            idModel,
                            moduleId,
                        },
                    });
                };

                const onPauseClick = (event: React.MouseEvent<HTMLIdsButtonElement, MouseEvent>, resultsId: number) => {
                    event.stopPropagation();

                    // dispatch({
                    //     type: PAUSE_MODULE_START,
                    //     payload: {
                    //         idProject,
                    //         idModel,
                    //         moduleId,
                    //         resultsId,
                    //         status: PAUSE_MODULE_START,
                    //     },
                    // });

                    dispatch({
                        type: PAUSE_MODULE_V2_START,
                        payload: {
                            idProject,
                            idModel,
                            moduleId,
                            resultsId,
                        },
                    });
                };

                const renderRunButton = (title: string) => (
                    <Button
                        dataTestId="run-module"
                        variant="tertiary"
                        padding="md"
                        iconLeft="multimedia-play__start_b_s"
                        iconSize="sm"
                        isDisabled={currentState === "DATA_MISSING"}
                        onClick={onRunClick}
                    >
                        <>{title}</>
                    </Button>
                );

                const renderPauseButton = (title: string) => (
                    <Button
                        dataTestId="pause-module"
                        variant="tertiary"
                        padding="md"
                        iconLeft="multimedia-pause_b_a"
                        iconSize="sm"
                        isDisabled={
                            currentStatus === undefined ||
                            ["PENDING", "RECALC_PENDING", "ERROR"].includes(currentState) ||
                            [
                                // "RUN_MODULE_START",
                                RUN_MODULE_V2_START,
                                // "PAUSE_MODULE_START",
                                PAUSE_MODULE_V2_START,
                                // "PAUSE_MODULE_STARTED",
                            ].includes(String(currentStatus))
                        }
                        onClick={isNil(latestRun) ? undefined : (event) => onPauseClick(event, latestRun.resultsId)}
                    >
                        <>{title}</>
                    </Button>
                );

                /* eslint-disable no-fallthrough */
                switch (String(currentStatus)) {
                    // only running module can be stopped:
                    case "RUN_MODULE_STARTED":
                    case "RUN_MODULE_V2_STARTED":
                    case "IN_PROGRESS":
                    case "RUNNING":
                    // pause button is disabled below:
                    case "RUN_MODULE_START":
                    case "RUN_MODULE_V2_START":
                    case "PAUSE_MODULE_START":
                    case "PAUSE_MODULE_V2_START":
                    case "PAUSE_MODULE_STARTED":
                        return renderPauseButton("Pause");
                    case "PAUSED":
                        return renderRunButton("Re-calculate");
                    default:
                        switch (currentState) {
                            case "RUNNING":
                                return renderPauseButton("Pause");
                            case "PENDING":
                            case "CALCULATED":
                                return renderRunButton("Calculate");
                            case "ERROR":
                            case "RECALC_PENDING":
                                return renderRunButton("Re-calculate");
                            default:
                                return null;
                        }
                }
            },
            [idProject, idModel, moduleId, latestRun, setRunPending, dispatch]
        );

        return (
            <Card
                cardClassName={cardClassNames}
                contentClassName="flex-row align-center"
                selected={selected}
                status={runState}
                roundBorders
                onClick={onClick}
            >
                <div className="module-card__drag-and-drop-icon-container">
                    <Icon icon="ui-menu__hamburger_b_a" clickable />
                </div>
                {isLoadingAllModuleRuns ? (
                    <IconLoading />
                ) : (
                    <div data-testid={dataTestId} className="flex-row flex-one-in-row align-center">
                        <div className="flex-column">
                            <div className="flex-row align-center">
                                <StepBox number={moduleOrder} />
                                <span className="module-card__module-name" title={`ID: ${moduleId}`}>
                                    {moduleName}
                                </span>
                                <span>
                                    <Tag
                                        dataTestId="calculation-status"
                                        className="module-card__calculation-status"
                                        variant={
                                            runState === "ERROR"
                                                ? moduleRunState[runState]?.variant
                                                : moduleRunState[runModule?.status]?.variant || moduleRunState[runState]?.variant
                                        }
                                        size="sm"
                                        iconLeft={tagIcon}
                                        iconSize="sm"
                                    >
                                        <>
                                            {runState === "ERROR"
                                                ? moduleRunState[runState]?.label
                                                : moduleRunState[runModule?.status]?.label || moduleRunState[runState]?.label}
                                        </>
                                    </Tag>
                                </span>
                            </div>
                            {runState === "RUNNING" && (
                                <div className="flex-row align-center module-card__bottom-container">
                                    <span className="module-card__progress-bar">
                                        <StaticProgressBar percentage={runProgress} />
                                    </span>
                                    <span className="module-card__progress-label">{`${runProgress}%`}</span>
                                </div>
                            )}
                            {runState !== "RUNNING" && latestRun?.status === "DONE" && runModule?.status !== "IN_PROGRESS" && (
                                <div className="module-card__bottom-container">
                                    <span>
                                        <Link
                                            className="module-card__link"
                                            onClick={() => onResultClick(latestRun.resultsId, latestRun.finished)}
                                        >
                                            Last calculation result
                                        </Link>
                                    </span>
                                    <span>:</span>
                                    <span className="module-card__date">{d.format(new Date(latestRun.finished + "Z"))}</span>
                                </div>
                            )}
                        </div>
                        <div className="flex-row align-center flex-one-in-row module-card__right-container">
                            {(modelRequired || hasInternalUserRole()) && (
                                <div className="flex-row align-center justify-end module-card__module-state-container">
                                    <Label>Status</Label>
                                    {!isEmpty(moduleStateItems) && !modelReadOnlyEnabled && (haveAnalystRights || haveReviewerRights) ? (
                                        <>
                                            <TagWithDropdown
                                                tagClassName={kebabCase(moduleState)}
                                                value={moduleState}
                                                items={moduleStateItems}
                                                onChange={(value) => onModuleStatusChange(moduleName, value)}
                                            />
                                        </>
                                    ) : (
                                        <div className="aeg-tag-wrapper">
                                            <Tag className={kebabCase(moduleState)} size="sm">
                                                <>{moduleState.toLowerCase()}</>
                                            </Tag>
                                        </div>
                                    )}
                                </div>
                            )}
                            {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                                <div className="module-card__calculate-button-container">{renderButton(runState, runModule?.status)}</div>
                            )}
                        </div>
                    </div>
                )}
            </Card>
        );
    }
);

export default ModuleCard;
