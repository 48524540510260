import { NAME_KEY, EXPIRES_IN_KEY, VDSM_USER_STORAGE_KEY, USER_ROLES, USER_RIGHTS, USER_STORAGE_KEY } from "./defines";

import { UserParams, UserSettings } from "./types";
import { User } from "store/user/types";
import { VdsmUser } from "store/vdsmUser/types";

export const isAuthenticated = (user: VdsmUser) => {
    return Boolean(user && user.status === "active" && !user.qrCode);
};

export const isStatusExpired = (user: VdsmUser) => {
    return Boolean(user && user.status === "expired");
};

export const isQrCode = (user: VdsmUser) => {
    return Boolean(user && user.status === "active" && user.qrCode);
};

export const getUserNameFromToken = (accessToken: string) => {
    const jwtData = accessToken.split(".")[1];
    const decodedData = window.atob(jwtData);
    const userData = JSON.parse(decodedData);

    return userData[NAME_KEY] || "";
};

export const tokenExpired = (user: VdsmUser) => {
    const currentTime = Date.now();

    if (isQrCode(user)) {
        return user.tokenExpires < currentTime;
    }

    return isAuthenticated(user) ? user.tokenExpires < currentTime : true;
};

export const createUser = (params: UserParams) => {
    const { firstName, lastName, userName, userStatus, rights, accessToken, refreshToken, qrCode, settings } = params;

    const name = !userName && accessToken ? getUserNameFromToken(accessToken) : userName;

    if (userStatus !== "active") {
        return {
            name,
            status: userStatus,
            accessToken,
            qrCode,
            settings,
        };
    }

    const jwtData = accessToken.split(".")[1];
    const decodedData = window.atob(jwtData);
    const userData = JSON.parse(decodedData);

    return {
        firstName,
        lastName,
        name,
        userNumber: userData.sub,
        status: userStatus,
        isActive: userStatus === "active",
        rights,
        accessToken,
        refreshToken,
        tokenExpires: Date.now() + userData[EXPIRES_IN_KEY] * 60000,
        tokenExpiresIn: userData[EXPIRES_IN_KEY],
        qrCode,
        settings,
    };
};

export const saveVdsmUser = (user: VdsmUser) => {
    sessionStorage.setItem(VDSM_USER_STORAGE_KEY, JSON.stringify(user));
};

export const getVdsmUser = () => {
    const user = sessionStorage.getItem(VDSM_USER_STORAGE_KEY);
    let parsedUser = null;

    if (user !== null) {
        parsedUser = JSON.parse(user);
    }

    return parsedUser;
};

export const deleteVdsmUser = () => {
    sessionStorage.removeItem(VDSM_USER_STORAGE_KEY);
};

export const refreshUser = (params: UserParams) => {
    const user = createUser(params);

    saveVdsmUser(user);

    return user;
};

export const saveUser = (user: User) => {
    sessionStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
};

export const getUser = () => {
    const user = sessionStorage.getItem(USER_STORAGE_KEY);
    let parsedUser = null;

    if (user !== null) {
        parsedUser = JSON.parse(user);
    }

    return parsedUser;
};

export const setUserSettings = (settings: UserSettings) => {
    const user = getVdsmUser();

    if (user && settings) {
        saveVdsmUser({
            ...user,
            settings: {
                ...settings,
            },
        });
    }
};

export const getUserInitials = (firstName?: string, lastName?: string) => {
    let initials = "U U";
    let name = `${firstName} ${lastName}`;

    if (firstName) {
        if (lastName) {
            initials = `${firstName[0]} ${lastName[0]}`;
        } else {
            initials = firstName.trim();

            const index = initials.indexOf(" ");

            if (index) {
                initials = `${firstName[0]} ${firstName[index + 1]}`;
            } else {
                initials = `${firstName[0]} ${firstName[1]}`;
            }
        }
    } else {
        if (lastName) {
            initials = lastName.trim();

            const index = initials.indexOf(" ");

            if (index) {
                initials = `${lastName[0]} ${lastName[index + 1]}`;
            } else {
                initials = `${lastName[0]} ${lastName[1]}`;
            }
        }
    }

    return { initials, name };
};

export const hasInternalUserRole = (vdsmUser?: VdsmUser) => {
    const user = vdsmUser || getVdsmUser();

    return (
        user.rights?.includes(USER_ROLES.COMPONENT_OWNER) ||
        user.rights?.includes(USER_ROLES.ANALYST) ||
        user.rights?.includes(USER_ROLES.MANAGER)
    );
};

export const hasClientUserRole = (vdsmUser?: VdsmUser) => {
    const user = vdsmUser || getVdsmUser();

    return (
        user.rights?.includes(USER_ROLES.BASIC_CLIENT) ||
        user.rights?.includes(USER_ROLES.BASIC_PRIMARY_CONTACT) ||
        user.rights?.includes(USER_ROLES.ADVANCED_CLIENT) ||
        user.rights?.includes(USER_ROLES.CLIENT_ADMIN)
    );
};

export const hasStakeholderUserRole = (vdsmUser?: VdsmUser) => {
    const user = vdsmUser || getVdsmUser();

    return user.rights?.includes(USER_ROLES.STAKEHOLDER);
};

export const hasExternalUserRole = (vdsmUser?: VdsmUser) => {
    const user = vdsmUser || undefined;

    return hasClientUserRole(user) || hasStakeholderUserRole(user);
};

const hasPermission = (user: VdsmUser, permission: string) => {
    return isAuthenticated(user) && user.rights && USER_RIGHTS[user.rights[0]]?.includes(permission);
};

export const hasAnyOfPermissions = (permissions: string[]) => {
    const user = getVdsmUser();

    return permissions.some((permission) => hasPermission(user, permission));
};
