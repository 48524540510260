import { memo, useCallback } from "react";

import ModelLog from "./ModelLog";

import Button from "components/ui/ButtonNew";
import DashboardPanel from "components/ui/Dashboard";

import ModelLogNoteSidebar from "layouts/Sidebar/ModelLogNoteSidebar";

import { useSidePanelHandlers } from "utils/useSidePanelHandlers";
import { MODEL_LOG } from "utils/constants";

import { ModelLogProps } from "./types";

const ModelLogPanel = memo(({ idClient, idProject, idModel, idInputLog }: ModelLogProps) => {
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ key: "outer-right" });

    const onAddNoteClick = useCallback(() => {
        handleOpenSidePanel(
            <ModelLogNoteSidebar
                idProject={idProject}
                idModel={idModel}
                idInputLog={idInputLog as number}
                idStudyLog={0}
                noteType="user-note"
                onClose={handleCloseSidePanel}
            />
        );
    }, [idProject, idModel, idInputLog, handleOpenSidePanel, handleCloseSidePanel]);

    const headerAction = (
        <Button variant="tertiary" padding="md" iconLeft="ui-plus_in_circle__add__create_b_s" iconSize="sm" onClick={onAddNoteClick}>
            Add note
        </Button>
    );

    return (
        <DashboardPanel
            dataTestId="model-log-panel"
            className="model-log-panel"
            title="Model Log"
            headerAction={headerAction}
            pageId={MODEL_LOG}
        >
            <ModelLog dataTestId="model-log" idClient={idClient} idProject={idProject} idModel={idModel} />
        </DashboardPanel>
    );
});

export default ModelLogPanel;
