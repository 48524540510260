///
/// User authentication actions
/// resourceName: usersAuthenticationCodeResourceName
///
import { usersAuthenticationCodeResourceName } from "store/configureVDSMResources";

import { createResource } from "store/resources/actions/createResource";

import { CreateUserAuthenticationCodeParams } from "./types";

export const createUserAuthenticationCode =
    ({ authCode, onError, onSuccess }: CreateUserAuthenticationCodeParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            createResource({
                resourceName: usersAuthenticationCodeResourceName,
                key: usersAuthenticationCodeResourceName,
                body: {
                    authCode,
                },
                showErrorNotification: false,
                onError,
                onSuccess,
            })
        );
