import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "components/ui/ButtonNew";
import InfoPanel from "components/ui/InfoPanel";
import LabelWithIcon from "components/ui/LabelWithIcon";

import { openModalDialogImportData } from "layouts/Modal/ModelDashboardModal/utils";

import HowToImport from "pages/ManageProject/ProjectionsContent/common/HowToImport";

import { ImportDataPanelProps } from "./types";

const ImportDataPanel = memo(
    ({
        viewIndex,
        idClient,
        idProject,
        idModel,
        idInputLog,
        idTemplate,
        idTerritory,
        idFuel,
        modelName,
        calculationPreconditionsErrors,
        disabledImport,
    }: ImportDataPanelProps) => {
        const dispatch = useDispatch();

        const [showHowTo, setShowHowTo] = useState(false);

        const onShowHideHowTo = useCallback(() => {
            setShowHowTo((prevShowHowTo) => !prevShowHowTo);
        }, []);

        const onImportDataClick = useCallback(() => {
            dispatch(
                openModalDialogImportData({
                    viewIndex,
                    idClient,
                    idProject,
                    idModel,
                    idInputLog,
                    idTemplate,
                    idTerritory,
                    idFuel,
                    modelName,
                })
            );
        }, [viewIndex, idClient, idProject, idModel, idInputLog, idTemplate, idTerritory, idFuel, modelName, dispatch]);

        return (
            <div className="import-data-panel">
                {showHowTo && (
                    <InfoPanel onClose={onShowHideHowTo}>
                        <HowToImport title="How to prepare file for download" />
                    </InfoPanel>
                )}
                <div className="flex-row align-center">
                    <div style={{ width: "200px" }}>
                        <Button
                            variant={calculationPreconditionsErrors ? "primary" : "secondary"}
                            iconLeft="files-move_to_inbox_b_s"
                            iconSize="sm"
                            padding="lg"
                            fullWidth
                            isDisabled={disabledImport}
                            onClick={onImportDataClick}
                        >
                            Import Data
                        </Button>
                    </div>
                    {!showHowTo && (
                        <div className="margin-left">
                            <LabelWithIcon icon="text-info_italic__more__details__information_about_b_s" onClick={onShowHideHowTo}>
                                How to prepare a file for download
                            </LabelWithIcon>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default ImportDataPanel;
