///
/// Client actions
/// resourceName: clientsResourceName
///
import { get } from "lodash";
import { v1 as uuidv1 } from "uuid";

import { clientsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";

import { clearAllTerritoryList, clearClientTerritories } from "store/resources/actions/territory/territoryActions";
import { clearClientFuels } from "store/resources/actions/fuel/fuelActions";
import { clearProjectList } from "store/resources/actions/project/projectActions";

import { sortArray } from "utils/array";

export const getClientsList = ({ getState }) =>
    get(getState(), `resources.${clientsResourceName}.itemsById[${clientsResourceName}].data.model`);

const getResourceParams = ({
    id,
    clientName,
    clientDescription,
    territories,
    fuels,
    contactName,
    contactEmail,
    contactPhone,
    aegContactName,
    aegContactEmail,
    updateItems,
    onComplete,
    dispatch,
}) => ({
    resourceName: clientsResourceName,
    key: `${clientsResourceName}`,
    body: {
        id,
        clientName,
        clientDescription,
        territories,
        fuels,
        contactName,
        contactEmail,
        contactPhone,
        aegContactName,
        aegContactEmail,
    },
    updateFromResponse: false,
    showSuccessNotification: false,
    onComplete: () => {
        onComplete && onComplete();
        dispatch(clearClientList());
        dispatch(clearAllTerritoryList());
        dispatch(clearClientTerritories({ idClient: id }));
        dispatch(clearClientFuels({ idClient: id }));
        dispatch(clearProjectList({ idClient: id }));
    },
    optimisticUpdate: updateItems?.length
        ? {
              value: {
                  model: updateItems,
              },
          }
        : undefined,
});

export const useClientList = () =>
    useResource({
        resourceName: clientsResourceName,
        key: clientsResourceName,
        transform: (data) => {
            // Sort clients alphabetically by their names
            return sortArray(data?.model || [], "clientName");
        },
    });

export const clearClientList = () => (dispatch) =>
    dispatch(
        clearResource({
            resourceName: clientsResourceName,
            key: `${clientsResourceName}`,
            broadcast: true,
        })
    );

export const createClient =
    ({ clientName, clientDescription, territories, fuels, onComplete }) =>
    (dispatch, getState) => {
        const updateItems = [
            {
                id: uuidv1(),
                clientName,
                clientDescription,
                territories,
                fuels,
            },
            ...getClientsList({ getState }),
        ];

        dispatch(
            createResource(
                getResourceParams({
                    clientName,
                    clientDescription,
                    territories,
                    fuels,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const updateClient =
    ({
        id,
        clientName,
        clientDescription,
        territories,
        fuels,
        contactName,
        contactEmail,
        contactPhone,
        aegContactName,
        aegContactEmail,
        onComplete,
    }) =>
    (dispatch, getState) => {
        const updateItems = getClientsList({ getState }).map((u) =>
            u.id === id
                ? {
                      ...u,
                      clientName: clientName || null,
                      clientDescription: clientDescription || null,
                      territories,
                      fuels,
                      contactName: contactName || null,
                      contactEmail: contactEmail || null,
                      contactPhone: contactPhone || null,
                      aegContactName: aegContactName || null,
                      aegContactEmail: aegContactEmail || null,
                  }
                : u
        );

        dispatch(
            updateResource(
                getResourceParams({
                    id,
                    clientName,
                    clientDescription,
                    territories,
                    fuels,
                    contactName,
                    contactEmail,
                    contactPhone,
                    aegContactName,
                    aegContactEmail,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };
