import { useCallback, useState } from "react";
import cn from "classnames";

import { useHelpTip } from "store/resources/actions/helpTips/helpTipsActions";

import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import LazyLoader from "components/ui/LazyLoader";

import HelpTipsSidebar from "layouts/Sidebar/HelpTipsSidebar";

import { useSidePanelHandlers } from "utils/useSidePanelHandlers";
import { hasInternalUserRole } from "utils/user";

import { DashboardPanelProps } from "./types";

import "./style.scss";

const DashboardPanel = (props: DashboardPanelProps) => {
    const { dataTestId, className, title, headerAction, pageId, lazyLoad = true, noHeader, noPadding, children } = props;

    const [isLoading, setIsLoading] = useState(lazyLoad);

    const [helpTip, isLoadingHelpTip] = useHelpTip({ pageId: hasInternalUserRole() && !isLoading ? pageId : undefined });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ key: "outer-right", size: "medium" });

    const helpTipIconStatus = helpTip?.name || helpTip?.content ? "f" : "s";

    const onHelpTipClick = useCallback(
        (id: number) => {
            handleOpenSidePanel(<HelpTipsSidebar pageId={id} onClose={handleCloseSidePanel} />);
        },
        [handleOpenSidePanel, handleCloseSidePanel]
    );

    const onPanelOnScreen = useCallback(() => {
        setIsLoading(false);
    }, []);

    const renderPanelContent = () => {
        return (
            <>
                {!noHeader && (
                    <div className="panel-header-container">
                        <div className="flex-row align-center justify-space-between fill-height panel-header">
                            <div className="flex-row align-center panel-title-container">
                                <span className="panel-title">{title}</span>
                                {pageId && !isLoadingHelpTip && hasInternalUserRole() && (
                                    <Icon icon={`text-help__question__faq_b_${helpTipIconStatus}`} onClick={() => onHelpTipClick(pageId)} />
                                )}
                            </div>
                            {headerAction && <div className="panel-header-action-container">{headerAction}</div>}
                        </div>
                    </div>
                )}
                <div data-testid={dataTestId} className="panel-body flex-column flex-one-in-column">
                    {isLoading ? <IconLoading /> : children}
                </div>
            </>
        );
    };

    if (lazyLoad) {
        return (
            <LazyLoader
                className={cn("flex-column fill-height dashboard-panel", className, { "no-padding": noPadding })}
                onVisible={onPanelOnScreen}
            >
                {renderPanelContent()}
            </LazyLoader>
        );
    }

    return (
        <div data-testid={dataTestId} className={cn("flex-column fill-height dashboard-panel", className, { "no-padding": noPadding })}>
            {renderPanelContent()}
        </div>
    );
};

export default DashboardPanel;
