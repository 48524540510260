import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { openModalDialogCreateClient } from "layouts/Modal/HomeDashboardModal/utils";

import ClientList from "./ClientList";

import Button from "components/ui/ButtonNew";
import DashboardPanel from "components/ui/Dashboard";

import { hasExternalUserRole, hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";
import { CLIENT_LIST } from "utils/constants";

import { ClientListProps } from "./types";

const ClientListPanel = memo(({ idClient, clients, loading }: ClientListProps) => {
    const dispatch = useDispatch();

    const title = hasExternalUserRole() ? "Utilities" : "Clients";

    const onNewClientClick = useCallback(() => {
        dispatch(openModalDialogCreateClient());
    }, [dispatch]);

    const headerAction = hasAnyOfPermissions([USER_ACTIONS.CLIENT_ADD]) ? (
        <Button variant="tertiary" iconLeft="ui-plus_in_circle__add__create_b_s" iconSize="sm" padding="md" onClick={onNewClientClick}>
            New Client
        </Button>
    ) : undefined;

    return (
        <DashboardPanel
            className="client-list-panel"
            title={title}
            headerAction={headerAction}
            pageId={CLIENT_LIST}
            lazyLoad={false}
            noPadding
        >
            <ClientList idClient={idClient} clients={clients} loading={loading} />
        </DashboardPanel>
    );
});

export default ClientListPanel;
