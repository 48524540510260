import { get } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPowerBIDatasetRefreshStatus } from "store/powerBI/refreshStatus/actions";

import { workspaceId } from "utils/constants";

import { UsePowerBIReportsRefreshStatusParams } from "./types";
import { RefreshStatus } from "store/powerBI/types";

export const usePowerBIReportsRefreshStatus = ({ datasetId }: UsePowerBIReportsRefreshStatusParams) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (datasetId) {
            dispatch(getPowerBIDatasetRefreshStatus({ datasetId, metadata: "auto" }));
        }
    }, [datasetId, dispatch]);

    const refreshStatus: RefreshStatus = useSelector((state) => get(state, `powerBiRefreshStatus[${workspaceId}-${datasetId}]`, {}));

    const isRefreshInProgress = refreshStatus?.status === "Unknown";

    return {
        isRefreshInProgress,
    };
};
