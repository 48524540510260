import { memo } from "react";

import DashboardHeader from "pages/ProjectStakeholderDashboard/DashboardHeader";

import FileNavigatorPanel from "pages/common/FileNavigatorPanel";

import { ProjectStakeholderDashboardProps } from "pages/ProjectStakeholderDashboard/types";

import "./style.scss";

/**
 * Project File navigator view.
 */
const ProjectStakeholderFiles = memo(
    ({ project, clientName, displayFiles, onDisplayFiles, onReturn }: ProjectStakeholderDashboardProps) => {
        return (
            <div className="project-stakeholder-files">
                <div className="project-stakeholder-files__header-container">
                    <div>
                        <DashboardHeader
                            project={project}
                            clientName={clientName}
                            displayFiles={displayFiles}
                            onDisplayFiles={onDisplayFiles}
                            onReturn={onReturn}
                        />
                    </div>
                </div>
                <div className="project-stakeholder-files__body-container">
                    <div>
                        <FileNavigatorPanel project={project} clientName={clientName} />
                    </div>
                </div>
            </div>
        );
    }
);

export default ProjectStakeholderFiles;
