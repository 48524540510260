import { batch } from "react-redux";

import { useProcessStatus, ProcessStatusProps, UseProcessParams } from "./useProcessStatus";

import { MODEL_DATA_IMPORT_COMPLETE } from "store/actionTypes";
import { modelDataImportStatusResourceName } from "store/configureResources";

import { clearModelLogItems, clearProjectLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { clearProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { clearInputNames } from "store/resources/actions/projectInput/projectInputNamesActions";
import { clearMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";
import { clearInputValidations } from "store/resources/actions/projectInput/projectInputValidationsActions";
import { clearModelStudyCase } from "store/resources/actions/studyCase/studyCaseModelActions";
import { clearCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";
import { clearVariableList } from "store/resources/actions/variables/variablesActions";
import { clearLabelVariableList } from "store/resources/actions/variables/labelVariablesActions";
import { clearAvoidedCostsTemplate } from "store/resources/actions/avoidedCosts/avoidedCostsTemplateActions";
import { clearAvoidedCostsSetup } from "store/resources/actions/modelInputs/avoidedCostsSetupActions";
import { clearModelInputsProgress } from "store/resources/actions/modelInputs/modelInputsProgressActions";

export const modelDataImportStatusProps: ProcessStatusProps = {
    resourceName: modelDataImportStatusResourceName,
    idSelector: ({ idInputLog }) => idInputLog,
    process: "data-import",
    timeoutHandlers: null,
    onProjectStatusChange:
        (idProject) =>
        // @ts-ignore
        (dispatch) => {
            batch(() => {
                dispatch(clearProjectLogItems({ idProject }));
                dispatch(clearProjectInputs({ idProject }));
                dispatch(clearInputNames({ idProject }));
            });
        },
    onItemStatusChange:
        ({ idProject, idInputLog }) =>
        // @ts-ignore
        (dispatch) => {
            batch(() => {
                dispatch(clearModelLogItems({ idProject, idInputLog }));
                dispatch(clearMeasureInputs({ idInputLog }));
            });
        },
    onItemFinishBefore:
        ({ idProject, idInputLog }) =>
        // @ts-ignore
        (dispatch) => {
            batch(() => {
                dispatch(clearInputValidations({ idInputLog }));
                dispatch(clearModelStudyCase({ idProject, idInputLog }));
                dispatch(clearCalculationPreconditions({ idProject }));
                dispatch(clearVariableList({ idProject }));
                dispatch(clearLabelVariableList({ idProject }));
                dispatch(clearVariableList({ idProject, idInputLog }));
                dispatch(clearAvoidedCostsTemplate({ idInputLog }));
                dispatch(clearAvoidedCostsSetup({ idInputLog }));
                dispatch(clearModelInputsProgress({ idProject }));
            });
        },
    onItemFinishAfter:
        (status) =>
        // @ts-ignore
        (dispatch) => {
            dispatch({
                type: MODEL_DATA_IMPORT_COMPLETE,
                status,
            });
        },
};

export const useModelDataImportStatus = ({ idProject, idInputLog }: UseProcessParams) =>
    useProcessStatus({ statusProps: modelDataImportStatusProps, idProject, idInputLog });
