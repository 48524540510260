///
/// Active Views let's the user know if other users
/// also have this view opened on their end.
///
/// Model Active Views actions
/// resourceName: activeViewsModelResourceName
///
import { chain } from "lodash";
import { batch, useSelector } from "react-redux";

import { activeViewsModelResourceName } from "store/configureResources";
import { SET_PROJECTIONS_VIEW_ID } from "store/actionTypes";

import { useResource } from "store/resources/actions/useResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

export const useActiveViewsModelList = ({ idProject, idInputLog }: ActiveViewsModelParams) => {
    // @ts-ignore
    const currentUser = useSelector((state) => state.vdsmUser);

    return useResource({
        resourceName: activeViewsModelResourceName,
        key: `${activeViewsModelResourceName}-${idProject}-${idInputLog}`,
        path: { idInputLog },
        transform: (data) =>
            chain(data?.model)
                .map((m) => m.userNumber)
                .uniq()
                .without(currentUser?.userNumber)
                .value(),
    });
};

export const clearActiveViewsModel =
    ({ idProject, idInputLog }: ActiveViewsModelParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: activeViewsModelResourceName,
                key: `${activeViewsModelResourceName}-${idProject}-${idInputLog}`,
                broadcast: true,
            })
        );

export const clearActiveViewsModelAll =
    () =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: activeViewsModelResourceName,
                key: new RegExp(`^${activeViewsModelResourceName}.$`),
            })
        );

export const updateActiveViewsModel =
    ({ viewIndex, idProject, idInputLog, previousIdInputLog, previousViewId }: UpdateActiveViewsModelParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            updateResource({
                resourceName: activeViewsModelResourceName,
                path: { idInputLog },
                body: {
                    previousViewId,
                },
                onSuccess: (response) => {
                    batch(() => {
                        dispatch({
                            type: SET_PROJECTIONS_VIEW_ID,
                            selection: {
                                viewIndex,
                                idProject,
                                viewId: response.data,
                            },
                        });

                        previousIdInputLog && dispatch(clearActiveViewsModel({ idProject, idInputLog: previousIdInputLog }));
                        dispatch(clearActiveViewsModel({ idProject, idInputLog }));
                    });
                },
            })
        );

export const deleteActiveViewsModel =
    ({ idProject, idInputLog, previousViewId }: DeleteActiveViewsModelParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            deleteResource({
                resourceName: activeViewsModelResourceName,
                path: { idInputLog },
                body: {
                    previousViewId,
                },
                onSuccess: () => {
                    dispatch(clearActiveViewsModel({ idProject, idInputLog }));
                },
            })
        );

interface ActiveViewsModelParams {
    /**
     * ID of Project.
     */
    idProject: number;

    /**
     * ID of Model.
     */
    idInputLog: number;
}

interface UpdateActiveViewsModelParams extends ActiveViewsModelParams {
    /**
     * View index:
     *  - 0: main view (or left view)
     *  - 1: right view
     */
    viewIndex: number;

    /**
     * ID of previous Model.
     */
    previousIdInputLog: number;

    /**
     * ID of previous View.
     */
    previousViewId: any;
}

interface DeleteActiveViewsModelParams extends ActiveViewsModelParams {
    /**
     * ID of previous View.
     */
    previousViewId: any;
}
