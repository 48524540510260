import { memo } from "react";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import Input from "components/ui/Input";
import StatusMsg from "components/ui/StatusMsg";

import { getVariableValue } from "layouts/common/Variables/utils";

import { VariablesTransformed } from "store/resources/actions/variables/types";
import { CommonVariablesProps } from "layouts/common/Variables";

const ProjectVariables = memo(
    ({
        values,
        projectVariables,
        variablesSavedOnProject,
        editing,
        showSave,
        warningMessage,
        infoMessage,
        onChange,
        onSave,
        onCancel,
    }: ProjectVariablesProps) => {
        return (
            <>
                {editing && warningMessage && (
                    <StatusMsg className="px-2 py-1 variables__warning" icon="info-empty" message={warningMessage} />
                )}
                {!variablesSavedOnProject && infoMessage && (
                    <StatusMsg className="px-2 py-1 variables__info" icon="info-empty" message={infoMessage} />
                )}
                <div className="flex-column">
                    {projectVariables.map((item, i) => (
                        <div key={`project-variables-${i}`} className="py-2">
                            {item[0].groupName && <div className="variables__group-name">{item[0].groupName}</div>}
                            <div className="flex-row align-end flex-wrap gap-2">
                                {item.map((it, j) => (
                                    <div key={`project-variable-${it.name}-${i}-${j}`} className="variable-field">
                                        {it.isText ? (
                                            <Input
                                                label={it.uiLabel}
                                                ghost={!editing}
                                                percent={!editing && it.inputType === "%"}
                                                value={getVariableValue(values, it)}
                                                onChange={(event) => onChange(event.target.value, it.name)}
                                            />
                                        ) : (
                                            <Dropdown
                                                label={it.uiLabel}
                                                ghost={!editing}
                                                value={getVariableValue(values, it)}
                                                items={it.values}
                                                multiple={it.inputType === "LIST"}
                                                singleLine={it.inputType === "LIST"}
                                                onChange={(value) => onChange(value, it.name)}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                {editing && (
                    <IdsButtonGroup customClasses="variables__actions" position="right" spaceBetween="lg">
                        <Button
                            variant="primary"
                            padding="lg"
                            isDisabled={!showSave}
                            onClick={() => {
                                onSave();
                            }}
                        >
                            Apply Changes
                        </Button>
                        <Button variant="secondary" padding="lg" onClick={onCancel}>
                            Cancel
                        </Button>
                    </IdsButtonGroup>
                )}
            </>
        );
    }
);

interface ProjectVariablesProps extends CommonVariablesProps {
    /**
     * An array of Project Variables.
     */
    projectVariables: VariablesTransformed[][];

    /**
     * Variables saved on Project-level.
     */
    variablesSavedOnProject: boolean;

    /**
     * Display "Save" button.
     */
    showSave: boolean;

    onChange: (value: string | number | readonly string[] | undefined, name: string) => void;

    onCancel: () => void;
}

export default ProjectVariables;
