import { memo, useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { RESET_PASSWORD_ERROR, RESET_PASSWORD_ERROR_CLEAR } from "store/actionTypes";
import { resetPassword as onResetPassword, resetLogin as onResetLogin } from "store/login/actions";

import { useTwoFactor } from "store/resources/actions/user/userTwoFactorActions";

import FormWrap from "pages/common/FormWrap";

import { IdsText } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import Captcha from "components/ui/Captcha";
import FieldGroup from "components/ui/FieldGroup";

import { openWindowLogin } from "utils/window";

import "./style.scss";

const ResetPassword = memo(() => {
    const dispatch = useDispatch();

    // @ts-ignore
    const resetPassword = useSelector((state) => state.resetPassword);

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [recaptcha, setRecaptcha] = useState<string | null>(null);

    const captchaRef = useRef<ReCAPTCHA>(null);

    const { userNumber, temporaryPassword } = getUserInfoFromUrl();

    const [twoFactorInfo, isLoading] = useTwoFactor({ userNumber });

    const canSubmit = newPassword.trim().length > 0 && confirmPassword.trim().length > 0 && recaptcha !== null;

    const isValid = useCallback(() => {
        const isValid = canSubmit && newPassword.trim() === confirmPassword.trim();

        if (!isValid) {
            dispatch({
                type: RESET_PASSWORD_ERROR,
                message: "Passwords do not match",
            });

            return false;
        }

        if (twoFactorInfo?.twoFactorRequired && twoFactorInfo?.qrCodeExists && (authCode || "").trim().length === 0) {
            dispatch({
                type: RESET_PASSWORD_ERROR,
                message: "Authentication code is required",
            });

            return false;
        }

        return isValid;
    }, [canSubmit, newPassword, confirmPassword, twoFactorInfo, authCode, dispatch]);

    const onChange = useCallback(
        (event, handler) => {
            dispatch({
                type: RESET_PASSWORD_ERROR_CLEAR,
            });

            handler(event.target.value);
        },
        [dispatch]
    );

    const onSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (isValid()) {
                if (userNumber && temporaryPassword) {
                    dispatch(
                        onResetPassword({
                            userNumber,
                            temporaryPassword,
                            newPassword,
                            authCode,
                            recaptcha,
                        })
                    );

                    captchaRef.current && captchaRef.current.reset();
                } else {
                    dispatch(openWindowLogin());
                }
            }
        },
        [isValid, userNumber, temporaryPassword, dispatch, newPassword, authCode, recaptcha]
    );

    const onRedirect = useCallback(() => {
        window.location.href = "/";

        dispatch(onResetLogin());
    }, [dispatch]);

    return (
        <FormWrap>
            <form className="flex-column reset-password-form" onSubmit={onSubmit}>
                {resetPassword.isLoading || isLoading ? (
                    <div className="flex-column flex-one align-center justify-center">
                        <IconLoading />
                    </div>
                ) : (
                    <>
                        {resetPassword.message && (
                            <div className="reset-password-form__error-message">
                                <ErrorMsg largeMsg message={resetPassword.message} />
                            </div>
                        )}
                        <div className="text-center reset-password-form__header">Reset password for your account</div>
                        <FieldGroup className="reset-password-form__inputs">
                            <Input
                                label="New password"
                                type="password"
                                placeholder="Enter your new password"
                                required
                                value={newPassword}
                                onChange={(event) => onChange(event, setNewPassword)}
                            />
                            <Input
                                label="Confirm password"
                                type="password"
                                placeholder="Confirm your new password"
                                required
                                value={confirmPassword}
                                onChange={(event) => onChange(event, setConfirmPassword)}
                            />
                            {twoFactorInfo?.twoFactorRequired && twoFactorInfo?.qrCodeExists && (
                                <Input
                                    required
                                    label="Authentication Code"
                                    name="authCode"
                                    type="password"
                                    placeholder="Enter authentication code"
                                    onChange={(event) => onChange(event, setAuthCode)}
                                />
                            )}
                            <div className="flex-column align-center">
                                <Captcha captchaRef={captchaRef} onChange={(token) => setRecaptcha(token)} />
                            </div>
                        </FieldGroup>
                    </>
                )}
                <div className="flex-row align-center justify-space-between">
                    <IdsText
                        customClasses="text-underline clickable"
                        weight="bold"
                        style={{ color: "var(--ids-semantic-ink-color-brand-a-accent)" }}
                        onClick={onRedirect}
                    >
                        Return to login page
                    </IdsText>
                    <div className="button-container">
                        <Button
                            ariaLabel="Reset password button"
                            variant="primary"
                            type="submit"
                            isDisabled={resetPassword.isLoading || !canSubmit}
                            isLoading={resetPassword.isLoading}
                            fullWidth
                            padding="lg"
                        >
                            <>{resetPassword.isLoading ? "Loading..." : "Save New Password"}</>
                        </Button>
                    </div>
                </div>
            </form>
        </FormWrap>
    );
});

const getUserInfoFromUrl = () => {
    const pathParts = window.location.pathname.split("/");
    let userNumber = null;
    let temporaryPassword = null;

    if (pathParts.length > 3) {
        userNumber = pathParts[2];
        temporaryPassword = pathParts[3];
    }

    return { userNumber, temporaryPassword };
};

export default ResetPassword;
