import { memo } from "react";

import InfoPanel from "components/ui/InfoPanel";

import { WelcomeBoardProps } from "./types";

import "./style.scss";

const WelcomeBoard = memo(({ name, message }: WelcomeBoardProps) => {
    return (
        <InfoPanel className="flex-row align-center welcome-board">
            <div>
                <span className="welcome-board__welcome-message">{`Welcome, ${name}!`}</span>
                <span className="welcome-board__helper">{message}</span>
            </div>
            <div className="welcome-board__vision-logo vision-logo-bottom" />
            <div className="welcome-board__vision-logo vision-logo-top" />
        </InfoPanel>
    );
});

export default WelcomeBoard;
