import { map, groupBy, maxBy, chain } from "lodash";
import { useMemo } from "react";

import { useProjectLogItems, useModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import { logActionKeys } from "utils/scenarioLog";

export const parseCalculationResult = (r) => {
    if (!r?.parameters) {
        return r;
    }

    const parameters = JSON.parse(r.parameters);
    const isPotential = parameters?.CASE_STUDIES?.some((p) => p?.NAME?.includes("POTENTIAL"));

    return { ...r, parameters, isPotential };
};

/**
 * useCalculationResult hook for Project Log.
 * @param {idProject} idProject
 * @returns {Array}
 *
 */
export const useCalculationResultProjectLog = ({ idProject }) => {
    const [projectLogItems, isLoading] = useProjectLogItems({ idProject });

    const projectLogItem = useMemo(() => {
        if (!projectLogItems) {
            return;
        }

        const res = projectLogItems.filter((item) => item.actionName === logActionKeys.RUN_CALCULATION && !isNaN(parseInt(item.results)));
        const latestResults = map(groupBy(res, "idInputLog"), (g) => maxBy(g, "results"));

        return chain(latestResults).map(parseCalculationResult).keyBy("idInputLog").value();
    }, [projectLogItems]);

    return [projectLogItem, isLoading];
};

/**
 * useCalculationResult hook for Model Log.
 * @param {idProject} idProject
 * @param {idInputLog} idInputLog
 * @returns {Array}
 *
 */
export const useCalculationResultModelLog = ({ idProject, idInputLog, idModel }) => {
    const [modelLogItems, isLoading] = useModelLogItems({ idProject, idInputLog, idModel });

    const modelLogItem = useMemo(() => {
        if (!modelLogItems) {
            return;
        }

        const res = modelLogItems.filter((item) => item.actionName === logActionKeys.RUN_CALCULATION && !isNaN(parseInt(item.results)));

        return parseCalculationResult(res?.[0]);
    }, [modelLogItems]);

    return [modelLogItem, isLoading];
};
