import _ from "lodash";
import cn from "classnames";
import { Fragment, useState, memo, useMemo, useRef, useEffect, useReducer, useCallback } from "react";

import VerticalFade from "./VerticalFade";

import IconWrap from "components/ui/Icons";
import Input from "components/ui/Input";
import Pagination from "components/ui/Pagination";

import { useDeepEffect } from "utils/useDeepEffect";
import { usePagination } from "utils/usePagination";
import { compareDateWithoutTime } from "utils/date";
import { invokeIfFunction, isNullOrWhitespace } from "utils/string";

import "./style.scss";

/**
 * TODO: Refactor this component to TypeScript
 *
 * Custom list with sorting functionality
 * @param {bool} sortable Default: false
 * @param {object} headers If object key value set as string text is rendered. To disable sorting for column set value as object and pass header title with key 'label' and set key 'sortable' to false. Example const headers = { model:{ label: 'Model', sortable : false}} .
 * @param {object|Array} columns Keyed collection of properties. Can be used instead of headers:
 * {
 *   key|Array element: {
 *     key: key in items row
 *     header: "header label",
 *     renderHeader: function({ key, headerValue }) renders header[key] cell,
 *     filter: "default filter value",
 *     renderFilter: function({ key, filterValue: filters[key], updateFilter: updateFilter(key), updateFilterValue: updateFilterValue(key), filteredList }) renders filter[key] cell,
 *     applyFilter: function({ key, item, itemValue, filterValue }) applies filter[key] to item[key],
 *     noFilterBorder: bool,
 *     renderItem: function({ key, item, itemValue }) renders item[key] cell,
 *     sortable: bool,
 *     freezedHeaders: list of headers that will appear on left side similar to Freezed in Excel.
 *     className {string|function}: "className" | className({ key, type: "header"|"filter"|"item", value })
 *   }
 * }
 */
const CustomList = memo(
    ({
        id,
        dataTestId,
        headers = {},
        items = [],

        renderHeaders,
        renderItem,
        renderSearch,
        renderFooter,

        component,
        searchComponent,
        componentProps,

        searchPlaceholder,
        searchFilters,
        filterByHeaders,

        sortable,
        limit,

        className,
        headerClassName,
        bodyClassName,

        lastColumnToRight,
        paginationAlignToRight,

        defaultPage,
        onBeforeSelectPage,
        pageDisplayCount,
        withLabels,
        scrollBody,
        withFade,
        skipBottomFade,
        skipTopFade,

        renderBeforeBody,
        beforeBodyClassName,

        columns,
        freezedHeaders,
        inputRef,

        noPadding,
    }) => {
        const columnsObj = useMemo(
            () => (_.isEmpty(columns) ? columns : _.isArray(columns) ? _.keyBy(columns, "key") : columns),
            [columns]
        );

        const cols = useMemo(() => (_.isEmpty(columns) ? columns : _(columns)), [columns]);

        const withFreeze = freezedHeaders?.length > 0;

        const defaultFilters = useMemo(() => {
            let defaultFilters = searchFilters ?? {};

            if (filterByHeaders) {
                defaultFilters = {};
                Object.keys(headers).forEach((key) => {
                    defaultFilters[key] = "";
                });
            }

            if (cols) {
                cols.omitBy((f) => _.isNil(f.renderFilter ?? f.filter ?? f.applyFilter)).forEach((f, key) => {
                    defaultFilters[f.key ?? key] = f.filter ?? "";
                });
            }

            return defaultFilters;
        }, [cols, filterByHeaders, headers, searchFilters]);

        const _headers = useMemo(() => {
            const hMap = new Map();
            if (!_.isEmpty(headers)) {
                _.each(headers, (h, key) => {
                    hMap.set(h.key ?? key, _.isObject(h) ? h : { label: h, sortable: true });
                });
            } else if (cols) {
                cols.omitBy((h) => _.isNil(h.header) && _.isNil(h.key)).each((h, key) => {
                    hMap.set(h.key ?? key, { label: h.header ?? h.key ?? key, sortable: h.sortable ?? true });
                });
            }
            return hMap;
        }, [cols, headers]);

        const [filters, dispatchFilters] = useReducer((state, action) => {
            switch (action.type) {
                case "key":
                    return {
                        ...state,
                        [action.key]: action.value,
                    };
                case "all":
                    return _.mapValues(action.value, (value, key) => (state?.hasOwnProperty(key) ? state[key] : value));
                default:
                    throw new Error();
            }
        }, defaultFilters);

        const [sortedBy, setSortedBy] = useState({ column: "", order: "asc" });

        useDeepEffect(() => {
            dispatchFilters({ type: "all", value: defaultFilters });
        }, [defaultFilters]);

        const hasSortParams = sortable && sortedBy && sortedBy.column && sortedBy.column.length > 0 ? true : false;
        const sortBy = hasSortParams ? sortedBy.column : null;
        const sortAsc = hasSortParams ? sortedBy.order === "asc" : null;

        const filteredList = useMemo(() => {
            let list = _(items);
            if (items && filters) {
                list = list.filter((item) => {
                    return Object.entries(filters).every(([key, filterValue]) => {
                        let itemValue = item[key] ?? "";
                        if (columnsObj?.[key]?.applyFilter) {
                            return columnsObj[key].applyFilter({ key, item, itemValue, filterValue });
                        } else {
                            // If filterValue is Date
                            if (Object.prototype.toString.call(filterValue) === "[object Date]") {
                                return (
                                    isNullOrWhitespace(filterValue) ||
                                    (!isNullOrWhitespace(itemValue) && compareDateWithoutTime(new Date(itemValue), filterValue) === 0)
                                );
                            }
                            // If filterValue is an array
                            else if (Array.isArray(filterValue)) {
                                return !filterValue?.length || filterValue.includes(itemValue);
                            } else {
                                itemValue = itemValue.toString().toLowerCase();
                                const fValue = filterValue.toLowerCase();

                                return itemValue.includes(fValue);
                            }
                        }
                    });
                });
            }

            if (sortedBy) {
                list = list.orderBy([sortedBy.column], [sortedBy.order]);
            }
            if (sortedBy.order === null) {
                list = _(items);
            }

            return list.value();
        }, [columnsObj, filters, items, sortedBy]);

        const updateFilter = (key) => (event) => {
            if (!_.isEqual(filters?.[key], event.target.value))
                dispatchFilters({
                    type: "key",
                    key,
                    value: event.target.value,
                });
        };

        const updateFilterValue = (key) => (value) => {
            if (!_.isEqual(filters?.[key], value))
                dispatchFilters({
                    type: "key",
                    key,
                    value,
                });
        };

        const onSort = (key) => (event) => {
            if (sortable && _headers.get(key).sortable) {
                const sortData = {
                    column: hasSortParams && sortedBy.order === "desc" ? null : key,
                    order: sortBy === key ? (sortAsc ? "desc" : "asc") : "asc",
                };
                setSortedBy(sortData);
            }
        };

        const checkHeaderSortable = (key) => {
            return _headers.get(key).sortable;
        };

        const checkHeaderText = (key) => _headers.get(key).label.length > 0;

        const renderHeadersRow = (headers) => {
            if (renderHeaders) {
                return renderHeaders(headers);
            }

            return Array.from(headers.keys()).map((key) => {
                const h = headers.get(key);
                const cKey = _.kebabCase(key);
                return columnsObj?.[key]?.hidden ? undefined : (
                    <div
                        key={`header-${cKey}`}
                        id={`header-${cKey}`}
                        className={cn(
                            `flex-row fill-width column-header column-${cKey}`,
                            invokeIfFunction(columnsObj?.[key]?.className, { key, type: "header", value: h.label }),
                            {
                                sortable: sortable && checkHeaderSortable(key),
                            }
                        )}
                    >
                        <div id={`column-content-${cKey}`} className="column-content" onClick={onSort(key)}>
                            {checkHeaderText(key) && (
                                <div id={`column-name-${cKey}`} className="header">
                                    {columnsObj?.[key]?.renderHeader
                                        ? columnsObj[key].renderHeader({ key, headerValue: h.label })
                                        : h.label}
                                </div>
                            )}

                            {sortable && checkHeaderSortable(key) && sortedBy && sortedBy.column !== key && (
                                <IconWrap
                                    data-testid={`${cKey}-header-sort`}
                                    icon="swap-vertical"
                                    title="Sort ASC or DESC"
                                    onClick={() => onSort(key)}
                                />
                            )}
                            {sortable && checkHeaderSortable(key) && sortedBy.column === key && (
                                <div
                                    data-testid={`${cKey}-header-sort`}
                                    className={"sorted" + (sortAsc ? " asc" : " desc")}
                                    title={"Sort " + (sortAsc ? "Descending" : "Ascending")}
                                >
                                    <IconWrap icon="swap-vertical" />
                                    <div className={"column-sorting-hint" + (sortAsc ? " asc" : " desc")}>{sortAsc ? "asc" : "dsc"}</div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            });
        };

        const rowOnHoverColorSync = useCallback(
            (e) => {
                if (withFreeze) {
                    const currentId = e.currentTarget.id;
                    const rows = document.getElementsByClassName(e.currentTarget.className);

                    // Clean up manual style
                    Array.from(rows).forEach((element) => {
                        if (element.id === currentId) {
                            element.style.backgroundColor = "";
                        }
                    });

                    const backgroundColor = window.getComputedStyle(e.currentTarget, null).getPropertyValue("background-color");

                    Array.from(rows).forEach((element) => {
                        if (
                            element.id !== currentId &&
                            (_.isEmpty(element.style.backgroundColor) || element.style.backgroundColor !== backgroundColor)
                        ) {
                            element.style.backgroundColor = backgroundColor;
                        }
                    });
                }
            },
            [withFreeze]
        );

        const renderItemsRows = (column, items, headers) => {
            if (renderItem) {
                return items.map((item, i) => {
                    return <Fragment key={i}>{renderItem(item, i)}</Fragment>;
                });
            }
            if (component) {
                const Component = component;

                return items.map((item, i) => {
                    const props = {
                        ...item,
                        ...componentProps,
                    };

                    return (
                        <Fragment key={i}>
                            <Component {...props} />
                        </Fragment>
                    );
                });
            }

            return items.map((item, i) => {
                return (
                    <div
                        id={"id-cust-row" + column + "-" + i}
                        key={"item-row-" + column + "-" + i}
                        className={cn("list-item-row", "row-" + i, { "list-item-row-selected": item?.selected })}
                        style={item?.selected ? { backgroundColor: "" } : {}}
                        onMouseEnter={rowOnHoverColorSync}
                        onMouseLeave={rowOnHoverColorSync}
                    >
                        {Array.from(headers.keys()).map((key) => {
                            const cKey = _.kebabCase(key);
                            return columnsObj?.[key]?.hidden ? undefined : (
                                <div
                                    key={`value-${i}-${cKey}`}
                                    className={cn(
                                        `item-value column-${cKey}`,
                                        invokeIfFunction(columnsObj?.[key]?.className, { key, type: "item", value: item?.[key] })
                                    )}
                                >
                                    {columnsObj?.[key]?.renderItem
                                        ? columnsObj[key].renderItem({ key, item, itemValue: item?.[key] })
                                        : item?.[key]}
                                </div>
                            );
                        })}
                    </div>
                );
            });
        };

        const renderSearchRow = (_headers) => {
            if (renderSearch) {
                return renderSearch(filters, updateFilter, updateFilterValue);
            } else if (searchComponent) {
                const SearchComponent = searchComponent;

                return <SearchComponent filters={filters} updateFilter={updateFilter} updateFilterValue={updateFilterValue} />;
            }

            const filtersKeys = Object.keys(filters);

            if (filtersKeys.length === 1) {
                return filtersKeys.map((key) => {
                    const cKey = _.kebabCase(key);

                    return (
                        <Input
                            key={`filter-${cKey}`}
                            className={cn(
                                `search-${cKey}`,
                                invokeIfFunction(columnsObj?.[key]?.className, { key, type: "filter", value: filters[key] })
                            )}
                            placeholder={searchPlaceholder}
                            value={filters[key]}
                            icon="search"
                            onChange={updateFilter(key)}
                        />
                    );
                });
            }

            const classNames = _.mapValues(Object.fromEntries(_headers), (_h, key) => ({
                filter: filters.hasOwnProperty(key),
                noborder: columnsObj?.[key]?.noFilterBorder,
            }));

            const headerKeys = Array.from(_headers.keys());

            headerKeys.forEach((key, index) => {
                if (classNames[key].filter) {
                    if (index === 0 || !classNames[headerKeys[index - 1]].filter || classNames[headerKeys[index - 1]].noborder) {
                        classNames[key].left = true;
                    }
                    if (
                        index + 1 === headerKeys.length ||
                        !classNames[headerKeys[index + 1]].filter ||
                        classNames[headerKeys[index + 1]].noborder
                    ) {
                        classNames[key].right = true;
                    }
                }
            });

            return (
                <div className="list-search-headers-row">
                    {Array.from(_headers.keys()).map((key) => {
                        const cKey = _.kebabCase(key);
                        const className = columnsObj?.[key]?.className;

                        return columnsObj?.[key]?.hidden ? undefined : (
                            <div
                                key={`filter-${cKey}`}
                                className={cn(
                                    `column-filter column-${cKey}`,
                                    classNames[key],
                                    invokeIfFunction(className, { key, type: "filter", value: filters[key] })
                                )}
                            >
                                {filters.hasOwnProperty(key) &&
                                    (columnsObj?.[key]?.renderFilter ? (
                                        columnsObj[key].renderFilter({
                                            key,
                                            filterValue: filters[key],
                                            updateFilter: updateFilter(key),
                                            updateFilterValue: updateFilterValue(key),
                                            filteredList,
                                        })
                                    ) : (
                                        <Input
                                            key={`filter-${cKey}`}
                                            inputTableFilter
                                            placeholder={columnsObj?.[key]?.searchPlaceholder}
                                            value={filters[key]}
                                            onChange={updateFilter(key)}
                                        />
                                    ))}
                            </div>
                        );
                    })}
                </div>
            );
        };

        //If freezed columns provided, than split into 2 maps one with list of freezed, another scrollable headers
        const splitedHeaders = withFreeze
            ? [
                  {
                      headers: new Map([..._headers].filter(([k, v]) => freezedHeaders.includes(k))),
                  },
                  {
                      headers: new Map([..._headers].filter(([k, v]) => !freezedHeaders.includes(k))),
                  },
              ]
            : [
                  {
                      headers: _headers,
                  },
              ];

        const [pagedItems, { selectedPage, pageCount, onSelectPage }] = usePagination(filteredList, {
            id,
            limit,
            defaultPage,
            onBeforeSelectPage,
        });

        const defaultPageRef = useRef(defaultPage);

        useEffect(() => {
            if (defaultPageRef.current !== defaultPage) {
                defaultPageRef.current = defaultPage;
                onSelectPage(defaultPage);
            }
        }, [defaultPage, onSelectPage]);

        const pagedItemLength = pagedItems?.length ?? 0;
        const itemsTotal = filteredList?.length ?? 0;
        const itemsFrom = pageCount === selectedPage ? filteredList.length - pagedItemLength : selectedPage * limit + 1;
        const itemsTill = itemsFrom + pagedItemLength - 1;

        const ListPagination = (props) => (
            <Pagination
                pageCount={pageCount}
                selectedPage={selectedPage}
                onSelectPage={onSelectPage}
                pageDisplayCount={pageDisplayCount}
                withLabels={withLabels}
                {...props}
            />
        );

        return (
            <>
                <div className={cn("custom-list flex-row", className, { "scroll-body": scrollBody, "no-padding": noPadding })}>
                    {splitedHeaders.map((entry, i) => {
                        return (
                            <div
                                key={`cst-split-${i}`}
                                id={`cst-split-${i}`}
                                className={cn("custom-list flex-column flex-one", {
                                    "last-col-to-right": lastColumnToRight,
                                    "no-padding": noPadding,
                                    "wrap-freeze-items": freezedHeaders && i === 0,
                                    "wrap-scroll-items": freezedHeaders && i > 0,
                                })}
                                ref={inputRef}
                            >
                                {!_.isEmpty(entry.headers) && (
                                    <div
                                        className={cn("list-header-row flex-row align-center justify-space-between", headerClassName, {
                                            "no-padding": noPadding,
                                        })}
                                    >
                                        {renderHeadersRow(entry.headers)}
                                    </div>
                                )}
                                {(!_.isEmpty(filters) || renderSearch || searchComponent) && (
                                    <div
                                        className={cn("list-filter flex-row", {
                                            "no-padding": noPadding,
                                        })}
                                    >
                                        {renderSearchRow(entry.headers)}
                                    </div>
                                )}
                                {renderBeforeBody && (
                                    <div className={cn("flex-row", beforeBodyClassName, { "no-padding": noPadding })}>
                                        {renderBeforeBody()}
                                    </div>
                                )}

                                <VerticalFade
                                    dataTestId={dataTestId}
                                    innerClassName={cn("flex-column list-body", bodyClassName, {
                                        "scroll-body": scrollBody && !freezedHeaders,
                                        "no-padding": noPadding,
                                    })}
                                    withFade={withFade}
                                    skipTopFade={skipTopFade}
                                    skipBottomFade={skipBottomFade}
                                    filters={filters}
                                >
                                    {limit
                                        ? renderItemsRows(i, (items = pagedItems), entry.headers)
                                        : renderItemsRows(i, (items = filteredList), (headers = _headers))}
                                </VerticalFade>
                            </div>
                        );
                    })}
                </div>
                {limit && (
                    <div className={cn("flex-row custom-list", className, { "pagination-align-right": paginationAlignToRight })}>
                        {renderFooter ? (
                            renderFooter(ListPagination, itemsFrom, itemsTill, itemsTotal, limit, onSelectPage)
                        ) : (
                            <ListPagination />
                        )}
                    </div>
                )}
            </>
        );
    }
);

export default CustomList;
