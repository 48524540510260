import { memo } from "react";

import "./style.scss";

const IconPreparing = memo(() => {
    return (
        <div className="icon-preparing">
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
            <div className="item" />
        </div>
    );
});

export default IconPreparing;
