import { isEmpty, kebabCase } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateProject } from "store/resources/actions/project/projectActions";
import { updateBookmarkForProject } from "store/resources/actions/bookmarks/bookmarkedProjectsActions";
import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";

import Button from "components/ui/ButtonNew";
import TagWithDropdown from "components/ui/Dropdown/TagWithDropdown";
import Icon from "components/ui/IconNew";
import Label from "components/ui/Label";
import StatusMsg from "components/ui/StatusMsg";
import Separator from "components/ui/Separator";
import InfoPanel from "components/ui/InfoPanel";
import Tag from "components/ui/Tag";

import {
    openModalDialogEditProject,
    openModalDialogSetFinalReportRequiredError,
    openModalDialogSetFinalReportModelFinalStatusError,
    openModalDialogSetFinalReportWarning,
    openModalDialogRollbackFinalReportWarning,
} from "layouts/Modal/ProjectDashboardModal/utils";

import { projectReadOnlyEnabled } from "pages/utils";

import { useHaveRights } from "utils/useHaveRights";
import { allProjectStates, allModelStates, allComponentModelStates, projectStates } from "utils/constants";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS, USER_ROLES } from "utils/user/defines";
import { openWindowProjectSources } from "utils/window";

import { ProjectInput } from "store/resources/actions/projectInput/types";
import { ProjectDashboardViewsProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

import "./style.scss";

const DashboardHeader = memo(({ project, clientName, displayFiles, onDisplayFiles, onReturn }: ProjectDashboardViewsProps) => {
    const {
        idClient,
        idProject,
        idManager,
        projectName,
        projectDescription,
        projectState,
        bookmarked,
        managerFullName,
        members,
        finished,
    } = project;

    // @ts-ignore
    const user = useSelector((state) => state.vdsmUser);

    const dispatch = useDispatch();

    const [allModels, isLoadingAllModels] = useProjectInputs({ idProject });
    const [allComponentModels, isLoadingAllComponentModels] = useComponentModels({ idProject });

    const haveManagerRights = useHaveRights({ entityId: idManager, userName: user.name });

    const projectStateItems = useMemo(() => {
        return projectStates[USER_ROLES.MANAGER][projectState.toUpperCase()].map((status) => ({
            label: status,
            value: status.toUpperCase(),
        }));
    }, [projectState]);

    const modelsCount = useMemo(() => {
        let count: number | undefined;

        if (!isEmpty(allModels) && !isLoadingAllModels) {
            count = allModels.filter((model: ProjectInput) => model.active === true).length;
        }

        return count;
    }, [allModels, isLoadingAllModels]);

    const requiredModels = useMemo(() => {
        let requiredLegacyModels = [];
        let requiredComponentModels: any[] = [];

        if (!isEmpty(allModels) && !isLoadingAllModels) {
            requiredLegacyModels = allModels.filter((model: ProjectInput) => model.required === true);
        }

        if (!isEmpty(allComponentModels) && !isLoadingAllComponentModels) {
            requiredComponentModels = allComponentModels.filter((componentModel) => componentModel.required === true);
        }

        return [...requiredLegacyModels, ...requiredComponentModels];
    }, [allModels, isLoadingAllModels, allComponentModels, isLoadingAllComponentModels]);

    const onEditClick = useCallback(() => {
        dispatch(
            openModalDialogEditProject({
                idClient,
                idProject,
                projectName,
                projectDescription,
                projectManager: idManager,
                projectTeamMembers: members,
            })
        );
    }, [idClient, idProject, idManager, projectName, projectDescription, members, dispatch]);

    const onUpdateBookmarkClick = useCallback(
        (bookmarked) => {
            dispatch(
                updateBookmarkForProject({
                    idClient,
                    idProject,
                    userNumber: user.userNumber,
                    bookmarked,
                    clientName,
                })
            );
        },
        [clientName, idClient, idProject, user.userNumber, dispatch]
    );

    const changeProjectStatus = useCallback(
        (projectStatus) => {
            dispatch(
                updateProject({
                    idClient,
                    idProject,
                    projectState: projectStatus,
                })
            );
        },
        [idClient, idProject, dispatch]
    );

    const onProjectStatusChange = useCallback(
        (projectStatus) => {
            // If Manager wants to set Project status
            // to Final Report
            if (projectStatus === allProjectStates.FINAL_REPORT.toUpperCase()) {
                // If Project does not have any Model set as required,
                // Modal window with an error pops up
                if (isEmpty(requiredModels)) {
                    dispatch(
                        openModalDialogSetFinalReportRequiredError({
                            projectName,
                        })
                    );
                }
                // If required Models for Project are not set to Approved (Legacy Models)
                // or Final (Component Models), Modal window with an error pops up
                else if (
                    !requiredModels.every(
                        (model: any) =>
                            model.modelState === allModelStates.CLIENT_APPROVED.toUpperCase() ||
                            model.modelState === allComponentModelStates.FINAL.toUpperCase()
                    )
                ) {
                    dispatch(
                        openModalDialogSetFinalReportModelFinalStatusError({
                            projectName,
                        })
                    );
                }
                // If all required Models for Project are set to Final,
                // Modal window with a warning pops up
                else {
                    dispatch(
                        openModalDialogSetFinalReportWarning({
                            projectName,
                            onChange: () => changeProjectStatus(projectStatus),
                        })
                    );
                }
            }
            // If Manager wants to rollback from Final Report status,
            // Modal window with a warning pops up
            else if (
                projectStatus === allProjectStates.IN_PROGRESS.toUpperCase() &&
                projectState === allProjectStates.FINAL_REPORT.toUpperCase()
            ) {
                dispatch(
                    openModalDialogRollbackFinalReportWarning({
                        projectName,
                        onChange: () => changeProjectStatus(projectStatus),
                    })
                );
            }
            // If Manager wants to set Project status to In Progress
            // or Proposed, no Modal windows pop up
            else {
                changeProjectStatus(projectStatus);
            }
        },
        [projectName, projectState, requiredModels, changeProjectStatus, dispatch]
    );

    const onProjectSourcesClick = () => {
        dispatch(
            openWindowProjectSources({
                idClient,
                idProject,
                clientName,
                projectName,
            })
        );
    };

    return (
        <div className="flex-column project-dashboard-header">
            <div className="project-dashboard-header__upper-container">
                <div className="flex-row align-center project-dashboard-header__top-container">
                    <div className="flex-column align-center justify-center project-dashboard-header__project-icon-wrapper">
                        <Icon className="project-dashboard-header__project-icon" icon="files-assignment_b_s" size="md" />
                    </div>
                    <div className="margin-left-small project-dashboard-header__project-name">
                        <div className="project-dashboard-header__project">Project</div>
                        <div className="flex-row align-center">
                            <div data-testid="header-project-name" className="project-dashboard-header__title">
                                {projectName}
                            </div>
                            {!projectReadOnlyEnabled(project.projectState) && hasAnyOfPermissions([USER_ACTIONS.PROJECT_EDIT]) && (
                                <div className="margin-left-small">
                                    <Button
                                        dataTestId="edit-project"
                                        variant="tertiary"
                                        icon="ui-pencil__edit__create_b_s"
                                        iconSize="sm"
                                        title="Edit project information"
                                        padding="sm"
                                        onClick={onEditClick}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="project-dashboard-header__middle-container">
                    <span>{projectDescription}</span>
                </div>
                <div className="flex-row align-center project-dashboard-header__bottom-container">
                    <div className="flex-column flex-one project-dashboard-header__project-details-container">
                        <div className="flex-row project-dashboard-header__project-details">
                            <div>
                                <span className="project-dashboard-header__label">ID: </span>
                                <span data-testid="id-project">{idProject}</span>
                            </div>
                            <div className="margin-left">
                                <span className="project-dashboard-header__label">Client: </span>
                                <span>{clientName}</span>
                            </div>
                        </div>
                        <div className="flex-row">
                            <div>
                                <span className="project-dashboard-header__label">Manager responsible: </span>
                                <span>{managerFullName || "None"}</span>
                            </div>
                            <div className="margin-left">
                                <span className="project-dashboard-header__label">Project team members: </span>
                                <span>{members && !isEmpty(members) ? members.map((member) => member.fullName).join(", ") : "None"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="project-dashboard-header__project-actions-container">
                        <div className="flex-row project-dashboard-header__bookmark-status-container">
                            <div>
                                <Button
                                    variant="tertiary"
                                    icon={bookmarked ? "files-bookmark_a_f" : "files-bookmark_b_s"}
                                    iconSize="sm"
                                    title={bookmarked ? "Remove project from bookmarks" : "Add project to bookmarks"}
                                    padding="sm"
                                    onClick={() => onUpdateBookmarkClick(bookmarked)}
                                />
                            </div>
                            <div className="flex-row align-center margin-left-small">
                                <Label>Status</Label>
                                {haveManagerRights ? (
                                    <TagWithDropdown
                                        tagClassName={kebabCase(projectState)}
                                        value={projectState.toUpperCase()}
                                        items={projectStateItems}
                                        onChange={(value) => onProjectStatusChange(value)}
                                    />
                                ) : (
                                    <div className="aeg-tag-wrapper">
                                        <Tag className={kebabCase(projectState)} size="sm">
                                            <>{projectState.toLowerCase()}</>
                                        </Tag>
                                    </div>
                                )}
                                {finished && projectState.toUpperCase() === allProjectStates.FINAL_REPORT.toUpperCase() && (
                                    <span className="project-dashboard-header__finalized-date">{`(Finalized on ${new Date(
                                        finished
                                    ).toLocaleDateString()})`}</span>
                                )}
                            </div>
                        </div>
                        <div className="margin-left-small">
                            <Button
                                variant="tertiary"
                                padding="sm"
                                iconLeft="files-personal_assignment_b_s"
                                iconSize="sm"
                                onClick={onProjectSourcesClick}
                            >
                                Project Sources
                            </Button>
                        </div>
                        <div className="margin-left-small">
                            {displayFiles ? (
                                <Button variant="tertiary" padding="sm" onClick={onReturn}>
                                    Return to Project Dashboard
                                </Button>
                            ) : (
                                <Button variant="tertiary" padding="sm" iconLeft="files-folder_b_s" iconSize="sm" onClick={onDisplayFiles}>
                                    Project Files
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {modelsCount === 0 && (
                <div className="project-dashboard-header__lower-container">
                    <Separator line />
                    <div>
                        <InfoPanel className="project-dashboard-header__no-models-message">
                            <StatusMsg
                                icon="lightbulb-empty"
                                iconWithLabelMedium
                                message="Start creating your first Model by adding territory and fuel information to this Project."
                            />
                        </InfoPanel>
                    </div>
                </div>
            )}
        </div>
    );
});

export default DashboardHeader;
