import { memo } from "react";

const Typography = memo(() => {
    return (
        <div>
            <div className="flexbox-row">
                <div className="flexbox-col">
                    <div className="ui-library-sub-title">Headers</div>
                    <div className="row">
                        <h1>H1 Title</h1>
                    </div>
                    <div className="row">
                        <h2>H2 Subtitle</h2>
                    </div>
                    <div className="row">
                        <h3>H3 Content Title</h3>
                    </div>
                    <div className="row">
                        <div className="content-header">Content Header</div>
                    </div>
                </div>
                <div className="flexbox-col">
                    <div className="ui-library-sub-title">Content</div>
                    <div className="row">
                        <div className="content-header">Content Header</div>
                        <p>
                            Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to
                            corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the
                            holistic world view of disruptive innovation via workplace diversity and empowerment.
                        </p>
                    </div>
                </div>
                <div className="flexbox-col"></div>
                <div className="flexbox-col"></div>
            </div>
        </div>
    );
});

export default Typography;
