import { memo, useCallback } from "react";
import { batch, useSelector, useDispatch } from "react-redux";

import { login as onLogin, vdsmLogin as onVdsmLogin } from "store/login/actions";

import LoginForm from "pages/common/LoginForm";

import { clientRoute } from "utils/constants";
import { isInIframe } from "utils/dom";
import { isAuthenticated, isStatusExpired, isQrCode } from "utils/user";

import { LoginProps } from "./types";

const Login = memo(({ onOpen }: LoginProps) => {
    const dispatch = useDispatch();

    // @ts-ignore
    const user = useSelector((state) => state.vdsmUser);

    // @ts-ignore
    const login = useSelector((state) => state.login);

    const pathParts = window.location.pathname.split("/")[1];
    const reportId = window.location.pathname.split("/")[2];

    // Redirect top window to login page
    if (window.top && !isAuthenticated(user) && isInIframe()) {
        window.top.location.replace(window.location.href);
    }

    // Open Reset Password view
    if (pathParts === clientRoute.resetPassword) {
        onOpen({
            name: "Reset Password",
            component: "ResetPassword",
            showHeader: false,
            showTabs: false,
        });
    }
    // Open SAML
    else if (pathParts === clientRoute.saml) {
        onOpen({
            name: "Saml",
            component: "SamlView",
            showHeader: false,
            showTabs: false,
        });
    }
    // Open public Power BI report
    else if (pathParts === clientRoute.reports) {
        onOpen({
            name: "Public Power BI",
            component: "PublicPowerBI",
            showHeader: false,
            showTabs: false,
            props: {
                reportId,
                filterEntityId: 0,
            },
        });
    }
    // Open Authenticator set up view
    else if (isQrCode(user)) {
        onOpen({
            name: "QR Code",
            component: "QrCode",
            showHeader: false,
            showTabs: false,
        });
    }
    // Open Change Password view
    else if (isStatusExpired(user)) {
        onOpen({
            name: "Change Password",
            component: "ChangePassword",
            showHeader: false,
            showTabs: false,
        });
    }

    const handleSubmit = useCallback(
        (values) => {
            batch(() => {
                dispatch(onLogin(values));
                dispatch(onVdsmLogin(values));
            });
        },
        [dispatch]
    );

    const handleForgotPassword = useCallback(() => {
        onOpen({
            name: "Forgot Password",
            component: "ForgotPassword",
            showHeader: false,
            showTabs: false,
        });
    }, [onOpen]);

    return (
        <LoginForm
            isLoading={login.isLoading}
            errorMessage={login.errorMessage}
            onSubmit={handleSubmit}
            onForgotPassword={handleForgotPassword}
        />
    );
});

export default Login;
