import { v1 as uuidv1 } from "uuid";
import { some, pickBy, isEmpty, isNil } from "lodash";
import { memo, useCallback, useState } from "react";
import { batch, useDispatch } from "react-redux";

import { modelDataImportV3ResourceName, projectInputsResourceName } from "store/configureResources";

import { getResourceState } from "store/utils";

import { selectProjection } from "store/projections/actions";

import { optimisticUpdateItem } from "store/resources/actions/updateResource";

import { uploadDataset } from "store/processes/actions/dataImport/modelDataImportActions";
import { getProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { getProjectFile } from "store/resources/actions/projectFiles/projectFilesActions";

import { FORM_VALIDATION_KEYS } from "layouts/common/ImportData";
import ImportDataForm from "layouts/common/ImportData/ImportDataForm";

import { projections, allModelStates } from "utils/constants";
import { isNullOrWhitespace } from "utils/string";

import { ProjectInput } from "store/resources/actions/projectInput/types";
import { ImportDataCommonProps } from "layouts/common/ImportData/types";

const Model = memo(
    ({
        viewIndex,

        idProject,
        idManager,
        idInputLog,

        managerFullName,
        members,

        modelName,
        modelTemplate,
        modelRequired,
        modelDescription,
        modelAnalyst,
        modelReviewer,
        modelTerritory,
        modelFuel,
        files,
        uploadedFile,
        selectedSheets,
        uploadedProjectFiles,
        projectTerritories,
        projectFuels,

        importMode,

        formValidationWarnings,
        modelNameExists,

        displayInModal,

        setFiles,
        setSelectedSheets,

        onChange,
        onCancel,
    }: ImportDataCommonProps) => {
        const dispatch = useDispatch();

        const initialFormValidationErrors = {
            [FORM_VALIDATION_KEYS.NO_NAME]: false,
            [FORM_VALIDATION_KEYS.NAME_EXISTS]: false,
            [FORM_VALIDATION_KEYS.NO_FILE]: false,
            [FORM_VALIDATION_KEYS.NO_TERRITORY]: false,
            [FORM_VALIDATION_KEYS.NO_FUEL]: false,
            [FORM_VALIDATION_KEYS.NO_SELECTED_SHEETS]: false,
        };

        const [formValidationErrors, setFormValidationErrors] = useState(initialFormValidationErrors);

        // Helper functions

        /**
         * Once data import starts, optimistically update
         * "New Model" to represent newly created Legacy Model
         * and navigate to that Model.
         */
        const onUploadStart = useCallback(
            (id: string, fileName: string, idInputLog?: number) => (dispatch, getState) => {
                const state = getState();

                const allModels = getResourceState<ProjectInput>(state, projectInputsResourceName, {
                    idProject,
                });

                const _idInputLog = idInputLog ? idInputLog : uuidv1();

                let updateItems = [];

                if (idInputLog) {
                    updateItems = allModels.map((model) =>
                        model.idInputLog === idInputLog
                            ? {
                                  ...model,
                                  uploadProps: {
                                      uploadId: id,
                                      fileName,
                                      isUploading: true,
                                  },
                              }
                            : {
                                  ...model,
                              }
                    );
                } else {
                    updateItems = allModels.map((model) =>
                        model.idInputLog.toString().includes("new-model")
                            ? {
                                  ...model,
                                  idInputLog: _idInputLog,
                                  idTerritory: modelTerritory,
                                  idFuel: modelFuel,
                                  idAnalyst: modelAnalyst ?? undefined,
                                  idReviewer: modelReviewer ?? undefined,
                                  name: modelName,
                                  description: modelDescription,
                                  required: modelRequired,
                                  modelState: allModelStates.PLANNED.toUpperCase(),
                                  uploadProps: {
                                      uploadId: id,
                                      fileName,
                                      isUploading: true,
                                  },
                              }
                            : {
                                  ...model,
                              }
                    );
                }

                batch(() => {
                    dispatch(
                        optimisticUpdateItem({
                            resourceName: projectInputsResourceName,
                            key: `${projectInputsResourceName}-${idProject}`,
                            value: updateItems,
                        })
                    );

                    if (displayInModal) {
                        onCancel?.();
                    } else {
                        dispatch(
                            selectProjection({
                                viewIndex,
                                idProjectionView: projections.MODEL_DASHBOARD,
                                idProject,
                                idProjection: _idInputLog,
                                key: "idInputLog",
                            })
                        );
                    }
                });
            },
            [
                viewIndex,
                idProject,
                modelTerritory,
                modelFuel,
                modelAnalyst,
                modelReviewer,
                modelName,
                modelDescription,
                modelRequired,
                displayInModal,
                onCancel,
            ]
        );

        /**
         * Once Legacy Model has been successfully created,
         * select that Model.
         *
         * Once Legacy Model has been successfully created,
         * select that Model, only if user is on Model Dashboard
         * for newly created Model.
         *
         * There are multiple phases for Model creation:
         *
         *  1. When Model creation request has been made,
         *     while UI waits for response (upload dataset to DB),
         *     optimistic item is created with the name of the Model.
         *  2. When optimistic item is created, app navigates user
         *     to Model Dashboard for that item and displays
         *     upload progress.
         *  3. When response is received (upload is finished):
         *      a. If user is still on Model Dashboard for that item,
         *         app navigates user to Model Dashboard, but this
         *         time for the newly created, real Model.
         *      b. If user is not on Model Dashboard for that item anymore,
         *         app does not navigate user anywhere.
         */
        const onImportDataComplete = useCallback(
            (idInputLog: number, firstImport: boolean) => (dispatch, getState) => {
                const onGetProjectInputsComplete = () => {
                    const state = getState();

                    const allModels = getResourceState<ProjectInput>(state, projectInputsResourceName, {
                        idProject,
                    });

                    let updateItems = [];

                    if (firstImport) {
                        // Remove optimistically added Model
                        // because we now have real Model
                        updateItems = allModels.filter(
                            (model) => model.idInputLog.toString().includes("new-model") || typeof model.idInputLog === "number"
                        );
                    } else {
                        // On successful upload set "isUploading" to false
                        updateItems = allModels.map((model) =>
                            model.idInputLog === idInputLog
                                ? {
                                      ...model,
                                      uploadProps: {
                                          ...model.uploadProps,
                                          isUploading: false,
                                      },
                                  }
                                : {
                                      ...model,
                                  }
                        );
                    }

                    dispatch(
                        optimisticUpdateItem({
                            resourceName: projectInputsResourceName,
                            key: `${projectInputsResourceName}-${idProject}`,
                            value: updateItems,
                        })
                    );

                    // @ts-ignore
                    const selectedProjection = state.projections?.selection[idProject]?.[viewIndex];

                    if (
                        selectedProjection.idProjectionView === projections.MODEL_DASHBOARD &&
                        selectedProjection.idInputLog &&
                        typeof selectedProjection.idInputLog === "string"
                    ) {
                        dispatch(
                            selectProjection({
                                viewIndex,
                                idProjectionView: projections.MODEL_DASHBOARD,
                                idProject,
                                idProjection: idInputLog,
                                key: "idInputLog",
                            })
                        );
                    }
                };

                dispatch(
                    getProjectInputs({
                        idProject,
                        onComplete: onGetProjectInputsComplete,
                    })
                );
            },
            [viewIndex, idProject]
        );

        /**
         * Imports data from the selected input file
         * and if it's a new import, creates a new
         * Legacy Model.
         */
        const onImportData = useCallback(async () => {
            const newFormValidationErrors = {
                [FORM_VALIDATION_KEYS.NO_NAME]: isNullOrWhitespace(modelName || ""),
                [FORM_VALIDATION_KEYS.NAME_EXISTS]: !displayInModal && modelNameExists,
                [FORM_VALIDATION_KEYS.NO_FILE]: isEmpty(files) && uploadedFile === "",
                [FORM_VALIDATION_KEYS.NO_TERRITORY]: modelTerritory === null,
                [FORM_VALIDATION_KEYS.NO_FUEL]: modelFuel === null,
                [FORM_VALIDATION_KEYS.NO_SELECTED_SHEETS]: !some(selectedSheets),
            };

            setFormValidationErrors(newFormValidationErrors);

            if (some(newFormValidationErrors)) {
                return;
            }

            let file: File | undefined;

            // With import mode we take user provided input file
            // With reference mode we take input file from Data Lake
            if (importMode === "import" && files !== undefined) {
                file = files[0];
            } else if (importMode === "reference" && uploadedFile !== "") {
                const fullPath = uploadedProjectFiles.find((file: any) => file.name === uploadedFile)?.path;

                if (fullPath) {
                    const filePath = fullPath.replace(`${idProject}/`, "");

                    const response = await getProjectFile({ idProject, filePath });

                    file = new File([response.blob], response.fileName, { type: response.blob.type });
                }
            }

            // Input file should always be provided with territory and fuel
            if (modelName && !isNil(modelTerritory) && !isNil(modelFuel) && file !== undefined) {
                const firstImport = isNil(idInputLog);

                const uploadId = uuidv1();

                const sheets = Object.keys(pickBy(selectedSheets || {}));

                dispatch(
                    uploadDataset({
                        resourceName: modelDataImportV3ResourceName,
                        id: uploadId,
                        idProject,
                        idInputLog,
                        idTerritory: modelTerritory,
                        idFuel: modelFuel,
                        idAnalyst: modelAnalyst ?? undefined,
                        idReviewer: modelReviewer ?? undefined,
                        name: modelName,
                        required: modelRequired,
                        description: modelDescription,
                        file,
                        selectedSheets: sheets,
                        onStart: () => {
                            dispatch(onUploadStart(uploadId, file?.name, idInputLog));
                        },
                        onComplete: (idInputLog) => {
                            dispatch(onImportDataComplete(idInputLog, firstImport));
                        },
                    })
                );
            }
        }, [
            idProject,
            idInputLog,
            modelName,
            modelRequired,
            modelDescription,
            modelAnalyst,
            modelReviewer,
            modelTerritory,
            modelFuel,
            files,
            uploadedFile,
            selectedSheets,
            uploadedProjectFiles,
            importMode,
            modelNameExists,
            displayInModal,
            onUploadStart,
            onImportDataComplete,
            dispatch,
        ]);

        return (
            <ImportDataForm
                idProject={idProject}
                idManager={idManager}
                idInputLog={idInputLog}
                managerFullName={managerFullName}
                members={members}
                modelName={modelName}
                modelRequired={modelRequired}
                modelTemplate={modelTemplate}
                modelDescription={modelDescription}
                modelAnalyst={modelAnalyst}
                modelReviewer={modelReviewer}
                modelTerritory={modelTerritory}
                modelFuel={modelFuel}
                files={files}
                uploadedFile={uploadedFile}
                selectedSheets={selectedSheets}
                uploadedProjectFiles={uploadedProjectFiles}
                projectTerritories={projectTerritories}
                projectFuels={projectFuels}
                importMode={importMode}
                formValidationErrors={formValidationErrors}
                formValidationWarnings={formValidationWarnings}
                displayInModal={displayInModal}
                setFiles={setFiles}
                setSelectedSheets={setSelectedSheets}
                onChange={onChange}
                onImport={onImportData}
                onCancel={onCancel}
            />
        );
    }
);

export default Model;
