import { DEFAULT_LOCALE } from "utils/date";

export const toLocaleDateTime = (unixDateTime: string) => {
    const t = new Date(unixDateTime);
    const c = new Date(unixDateTime);

    // Show date and time if the date is not today
    // Show only time if the date is today
    const formatted =
        (new Date().setHours(0, 0, 0, 0) === c.setHours(0, 0, 0, 0) ? "" : t.toLocaleDateString(DEFAULT_LOCALE) + " ") +
        t.toLocaleTimeString(DEFAULT_LOCALE);

    return formatted;
};

/**
 * Formats milliseconds to time string 00:00:00.
 */
export const toTimerString = (ms: number) => {
    const seconds = Math.floor(ms / 1000);

    const secs = seconds % 60;
    const mins = Math.floor(seconds / 60) % 60;
    const hrs = Math.floor(seconds / 3600);

    return `${("0" + hrs).slice(-2)}:${("0" + mins).slice(-2)}:${("0" + secs).slice(-2)}`;
};
