import { isEmpty } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useVariableList } from "store/resources/actions/variables/variablesActions";
import { useModuleVariables, updateModuleVariables } from "store/resources/actions/calculationWorkflow/moduleVariablesActions";
import { useHelpTip } from "store/resources/actions/helpTips/helpTipsActions";

import Markup from "components/ui/CKEditor/Markup";

import Button from "components/ui/ButtonNew";
import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import Tooltip from "components/ui/Tooltip";

import Variables from "layouts/common/Variables";

import { studyCaseVariables } from "layouts/Sidebar/CalculationWorkflowSidebar/utils";

import { actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { useVariablesTransform } from "utils/useVariablesTransform";
import { MODEL_VARIABLES } from "utils/constants";
import { USER_ACTIONS } from "utils/user/defines";

import { VariablesConfigurationProps } from "./types";
import { IndexSignature, UpdateVariableAction } from "types/types";
import { Variables as VariablesInterface } from "store/resources/actions/variables/types";
import { ModuleVariable, ModuleVariableTransformed } from "store/resources/actions/calculationWorkflow/types";

import "./style.scss";

const useVariablesFilter = (data: ModuleVariable[]) => data?.filter((item) => !studyCaseVariables.includes(item.name));

const useTransform = (data: ModuleVariable[]) =>
    useVariablesTransform<ModuleVariable, ModuleVariableTransformed>(useVariablesFilter(data), 2);

const VariablesConfiguration = memo(({ idProject, idModel, idInputLog, moduleId, modelRequired }: VariablesConfigurationProps) => {
    const dispatch = useDispatch();

    const [projectVariables] = useVariableList({ idProject, transform: (data) => data?.model });
    const [moduleVariables] = useModuleVariables({ moduleId, transform: useTransform });

    const [helpTip, isLoadingHelpTip] = useHelpTip({ pageId: MODEL_VARIABLES });

    const userRights = useUserRights();

    const [variableValues, setVariableValues] = useState<IndexSignature<string>>({});
    const [isEditing, setIsEditing] = useState(false);

    const variablesSavedOnProject = useMemo(() => {
        let variablesSavedOnProject;

        if (projectVariables !== undefined) {
            variablesSavedOnProject = projectVariables.some((pv: VariablesInterface) => pv.lastUpdatedBy !== null);
        }

        return variablesSavedOnProject;
    }, [projectVariables]);

    const differsFromProject = useMemo(() => {
        let differsFromProject = false;
        let updatedValueCheck = false;

        if (projectVariables !== undefined && variablesSavedOnProject) {
            const savedValueCheck = moduleVariables?.some((row: ModuleVariableTransformed[]) =>
                row.some(
                    (mv: ModuleVariableTransformed) =>
                        mv.value !== projectVariables.find((pv: VariablesInterface) => pv.name === mv.name)?.value
                )
            );

            if (!isEmpty(Object.keys(variableValues))) {
                for (const key in variableValues) {
                    if (
                        variableValues[key] !== "" &&
                        projectVariables.some((pv: VariablesInterface) => pv.name === key && pv.value !== variableValues[key])
                    ) {
                        updatedValueCheck = true;

                        break;
                    }
                }
            }

            differsFromProject = savedValueCheck || updatedValueCheck;
        }

        return differsFromProject;
    }, [projectVariables, moduleVariables, variableValues, variablesSavedOnProject]);

    const warningMessage = differsFromProject ? "Values of some variables differ from the Project settings" : "";
    const infoMessage =
        !differsFromProject && !variablesSavedOnProject ? "You use local settings until they are saved on the Project level" : "";

    const fieldDiffersFromProject = useCallback(
        (fieldName: string) => {
            if (projectVariables === undefined || isEmpty(projectVariables) || variablesSavedOnProject === undefined) {
                return false;
            }

            const projectVariableValue = projectVariables.find((pv: VariablesInterface) => pv.name === fieldName)?.value;

            if (projectVariableValue !== undefined) {
                if (
                    variableValues.hasOwnProperty(fieldName) &&
                    variableValues[fieldName] !== projectVariableValue &&
                    variableValues[fieldName] !== ""
                ) {
                    return true;
                }

                return moduleVariables?.some((row: ModuleVariableTransformed[]) =>
                    row.some((mv: ModuleVariableTransformed) => mv.name === fieldName && mv.value !== projectVariableValue)
                );
            }

            return false;
        },
        [projectVariables, moduleVariables, variableValues, variablesSavedOnProject]
    );

    const onUpdateVariablesClick = useCallback(
        (action: UpdateVariableAction) => {
            dispatch(
                updateModuleVariables({
                    idProject,
                    idModel,
                    idInputLog,
                    moduleId,
                    values: variableValues,
                    action,
                    onSuccess: () => {
                        setVariableValues({});
                    },
                })
            );
        },
        [idProject, idModel, idInputLog, moduleId, variableValues, dispatch]
    );

    const onEditClick = useCallback(() => {
        setVariableValues({});
        setIsEditing((prevIsEditing) => !prevIsEditing);
    }, []);

    const onEditCancel = useCallback(() => {
        setIsEditing(false);
    }, []);

    return (
        <div className="variables-configuration">
            <div className="flex-row align-center justify-space-between variables-configuration__header-container">
                <div className="flex-row align-center">
                    <span className="variables-configuration__title">Variables</span>
                    {helpTip !== undefined && !isLoadingHelpTip && (
                        <Tooltip
                            containerClassName="variables-configuration__help-tip"
                            placement="left"
                            customIcon={<Icon icon="text-info_italic__more__details__information_about_b_f" clickable />}
                        >
                            <div className="variables-configuration__tooltip-content-wrapper">
                                <Markup content={helpTip.content} />
                            </div>
                        </Tooltip>
                    )}
                </div>
                {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                    <>
                        {moduleVariables !== undefined && (
                            <Button
                                variant="tertiary"
                                iconLeft={!isEditing ? "ui-pencil__edit__create_b_s" : undefined}
                                padding="md"
                                onClick={onEditClick}
                            >
                                Edit
                            </Button>
                        )}
                    </>
                )}
            </div>
            <div>
                {moduleVariables === undefined ? (
                    <IconLoading />
                ) : (
                    <Variables
                        variablesLevel="module"
                        moduleVariables={moduleVariables}
                        values={variableValues}
                        differsFromProject={differsFromProject}
                        editing={isEditing}
                        warningMessage={warningMessage}
                        infoMessage={infoMessage}
                        modelRequired={modelRequired}
                        setValues={setVariableValues}
                        fieldDiffersFromProject={fieldDiffersFromProject}
                        onSave={onUpdateVariablesClick}
                        onEditCancel={onEditCancel}
                    />
                )}
            </div>
        </div>
    );
});

export default VariablesConfiguration;
