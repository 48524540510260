import { memo, useCallback, useState } from "react";

import FieldGroup from "components/ui/FieldGroup";
import Input from "components/ui/Input";
import NumericInput from "components/ui/Input/NumericInput";
import TextEdit from "components/ui/Input/TextEdit";
import UploadFile from "components/ui/Input/UploadFile";

const Inputs = memo(() => {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("pass1");
    const [value3, setValue3] = useState("");
    const [value4, setValue4] = useState("");
    const [value5, setValue5] = useState("");
    const [value6, setValue6] = useState("");
    const [value7, setValue7] = useState("");
    const [value8, setValue8] = useState("");
    const [value9, setValue9] = useState("");
    const [value10, setValue10] = useState("Input with buttons");
    const [value11, setValue11] = useState("0");
    const [value12, setValue12] = useState("0");
    const [value13, setValue13] = useState("0");
    const [value14, setValue14] = useState("0");
    const [value15, setValue15] = useState("0");
    const [value16, setValue16] = useState([]);
    const [value17, setValue17] = useState([]);
    const [value18, setValue18] = useState([]);

    const value13Min = "0";
    const value13Max = "5";
    const value13Error = parseInt(value13) > parseInt(value13Max) || parseInt(value13) < parseInt(value13Min);

    const onChange = useCallback((event, handler) => {
        handler(event.target.value);
    }, []);

    const onDropFile = useCallback((event, handler) => {
        handler(event);
    }, []);

    const onRemoveFile = useCallback((files, handler) => {
        handler(files);
    }, []);

    return (
        <div className="flex-row">
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Standard</div>
                <FieldGroup>
                    <Input
                        label="Input"
                        placeholder="Text"
                        value={value1}
                        onChange={(event, handler = setValue1) => onChange(event, handler)}
                    />
                    <Input
                        label="Password"
                        type="password"
                        value={value2}
                        onChange={(event, handler = setValue2) => onChange(event, handler)}
                    />
                    <Input
                        label="Input"
                        required
                        placeholder="Required"
                        value={value3}
                        onChange={(event, handler = setValue3) => onChange(event, handler)}
                    />
                    <Input
                        label="Input"
                        placeholder="Icon"
                        value={value4}
                        icon="search"
                        onChange={(event, handler = setValue4) => onChange(event, handler)}
                    />
                    <Input
                        label="Input"
                        focus
                        placeholder="Focused"
                        value={value5}
                        onChange={(event, handler = setValue5) => onChange(event, handler)}
                    />
                    <Input label="Input" disabled defaultValue="Disabled" />
                    <Input label="Input" readOnly defaultValue="Read-only" />
                    <Input label="Input" ghost defaultValue="Ghost" />
                    <Input label="Input" ghost percent defaultValue="0.0691" />
                    <Input
                        label="Input"
                        placeholder="With max length 6"
                        maxLength={6}
                        value={value6}
                        onChange={(event, handler = setValue6) => onChange(event, handler)}
                    />
                    <Input
                        label="Input"
                        placeholder="Read below"
                        value={value7}
                        msgText="Password must include Capital, Lowercase and Numbers and be at least 8 characters in length"
                        onChange={(event, handler = setValue7) => onChange(event, handler)}
                    />
                    <Input
                        label="Input"
                        warning
                        placeholder="Warning"
                        value={value8}
                        onChange={(event, handler = setValue8) => onChange(event, handler)}
                    />
                    <Input
                        label="Input"
                        error
                        placeholder="Text"
                        value={value9}
                        msgText="Please enter a valid e-mail address"
                        onChange={(event, handler = setValue9) => onChange(event, handler)}
                    />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Input with buttons</div>
                <FieldGroup>
                    <TextEdit value={value10} onApply={(value) => setValue10(value)} />
                    <TextEdit disabled value="Disabled input with buttons" />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Numeric input</div>
                <FieldGroup>
                    <NumericInput
                        label="Numeric Input"
                        defaultValue={value11}
                        onChange={(event, handler = setValue11) => onChange(event, handler)}
                    />
                    <NumericInput
                        label="Focused Numeric Input"
                        focus
                        defaultValue={value12}
                        onChange={(event, handler = setValue12) => onChange(event, handler)}
                    />
                    <NumericInput label="Disabled Numeric Input" disabled defaultValue="0" />
                    <NumericInput label="Read-only Numeric Input" readOnly defaultValue="0" />
                    <NumericInput label="Ghost Numeric Input" ghost defaultValue="0" />
                    <NumericInput
                        label="Warning Numeric Input"
                        warning
                        defaultValue={value13}
                        onChange={(event, handler = setValue13) => onChange(event, handler)}
                    />
                    <NumericInput
                        label="Error Numeric Input"
                        error
                        defaultValue={value14}
                        onChange={(event, handler = setValue14) => onChange(event, handler)}
                    />
                    <NumericInput
                        label="Numeric Input with Min/Max & Error"
                        error={value13Error}
                        min={value13Min}
                        max={value13Max}
                        autoDisableArrows
                        defaultValue={value15}
                        onChange={(event, handler = setValue15) => onChange(event, handler)}
                    />
                </FieldGroup>
            </div>
            <div className="flex-column ui-library__element-group__column">
                <div className="ui-library__element-group__sub-title">Upload file</div>
                <FieldGroup>
                    <UploadFile
                        label="Upload single file"
                        files={value16}
                        onDrop={(event) => onDropFile(event, setValue16)}
                        onRemove={(files) => onRemoveFile(files, setValue16)}
                    />
                    <UploadFile
                        label="Upload specific file type"
                        accept={[".csv", ".xls", ".xlsb", ".xlsm", ".xlsx"]}
                        files={value17}
                        onDrop={(event) => onDropFile(event, setValue17)}
                        onRemove={(files) => onRemoveFile(files, setValue17)}
                    />
                    <UploadFile
                        label="Upload multiple files"
                        files={value18}
                        multiple
                        onDrop={(event) => onDropFile(event, setValue18)}
                        onRemove={(files) => onRemoveFile(files, setValue18)}
                    />
                    <UploadFile label="Upload file (disabled)" files={[]} disabled onRemove={(files) => {}} />
                    <UploadFile label="Upload file (error)" files={[]} error onRemove={(files) => {}} />
                </FieldGroup>
            </div>
        </div>
    );
});

export default Inputs;
