///
/// Returns number of completed/total inputs per project or model
/// Model (Project Input) Progress actions
/// resourceName: modelInputsProgressResourceName
///
import { modelInputsProgressResourceName } from "store/configureResources";
import { getKey } from "store/utils";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

import {
    ModelInputsProgress,
    ModelInputsProgressResponseModel,
    ModelInputsProgressParams,
    ClearAllModelInputsProgressesParams,
} from "./types";

/**
 * Retrieves Model Inputs progress.
 *
 * If only idProject is provided, it retrieves
 * Model Inputs progress for all required Models
 * for that specific Project.
 *
 * If idInputLog is also provided, it retrieves
 * Model Inputs progress for that specific Model.
 *
 * @param idProject
 * @param idInputLog
 * @returns Model Inputs progress
 */
export const useModelInputsProgress = ({ idProject, idInputLog }: ModelInputsProgressParams) => {
    // TODO: Add generic type
    return useResource({
        resourceName: modelInputsProgressResourceName,
        key: `${modelInputsProgressResourceName}-${idProject}${getKey(idInputLog)}`,
        path: {
            idProject,
        },
        query: {
            idInputLog,
        },
        transform: (data: ModelInputsProgressResponseModel) => data?.model[0] || {},
    }) as [ModelInputsProgress, boolean | undefined, any];
};

export const clearModelInputsProgress =
    ({ idProject, idInputLog }: ModelInputsProgressParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: modelInputsProgressResourceName,
                key: `${modelInputsProgressResourceName}-${idProject}${getKey(idInputLog)}`,
                broadcast: true,
            })
        );
    };

export const clearAllModelInputsProgresses =
    ({ idProject }: ClearAllModelInputsProgressesParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: modelInputsProgressResourceName,
                key: new RegExp(`^${modelInputsProgressResourceName}-${idProject}(-[0-9]+){0,1}$`),
                broadcast: true,
            })
        );
    };
