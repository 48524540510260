// @deprecated

import * as React from "react";
import ClassNames from "classnames";

import "./style.scss";

const IconWithLabel = (props: IconWithLabelProps) => {
    const isClickable = (props.clickable || Boolean(props.onClick)) && !props.disabled;

    const classNames = ClassNames("icon-with-label with-icon", props.icon, props.className, {
        disabled: props.disabled,
        "icon-with-label-small": props.iconWithLabelSmall,
        "icon-with-label-medium": props.iconWithLabelMedium,
        "icon-after-label": props.iconRight,
        clickable: isClickable,
        inverse: props.inverse,
    });

    return (
        <div data-testid={props.dataTestId} className={classNames} onClick={props.onClick}>
            {props.children}
        </div>
    );
};

interface IconWithLabelProps {
    /**
     * Playwright attribute.
     */
    dataTestId?: string;

    className?: string;

    disabled?: boolean;

    /**
     * Display small size icon and label.
     */
    iconWithLabelSmall?: boolean;

    /**
     * Display medium size icon and label.
     */
    iconWithLabelMedium?: boolean;

    /**
     * Display icon after label.
     */
    iconRight?: boolean;

    /**
     * Display icon and label as clickable.
     */
    clickable?: boolean;

    /**
     * Display colors inversely.
     */
    inverse?: boolean;

    /**
     * Name of the icon.
     */
    icon: string;

    children: React.ReactNode;

    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default IconWithLabel;
