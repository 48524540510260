import cn from "classnames";

import { IdsButton } from "@emergn-infinity/ids-react";

import { ButtonProps } from "./types";

import "./style.scss";

const Button = (props: ButtonProps) => {
    const classNames = cn(
        "aeg-button",
        props.className
        // dev mode:
        /* {
        "icon-only": !props.children,
        "icon-btn-sm": props.iconSize === "sm",
        "icon-btn-md": props.iconSize === "md",
        "icon-btn-lg": props.iconSize === "lg",
    }*/
    );

    return (
        <IdsButton
            className={classNames}
            variant={props.variant}
            color={props.color || "brand"} // @todo: remove the default value as soon as IDS bug fixed
            type={props.type}
            padding={props.padding}
            fullWidth={props.fullWidth}
            icon={props.icon}
            iconLeft={props.iconLeft}
            iconRight={props.iconRight}
            iconSize={props.iconSize}
            ariaLabel={props.ariaLabel}
            isDisabled={props.isDisabled}
            isLoading={props.isLoading}
            onClick={props.onClick}
            rest={{
                "data-testid": props.dataTestId,
                title: props.title || "",
            }}
        >
            {props.children}
        </IdsButton>
    );
};

export default Button;
