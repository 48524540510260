///
/// List of model names per project, no security filtering
/// Model (Project Input) Names actions
/// resourceName: projectInputsNamesResourceName
///
import { projectInputsNamesResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

export const useInputNames = ({ idProject }) => {
    return useResource({
        resourceName: projectInputsNamesResourceName,
        key: `${projectInputsNamesResourceName}-${idProject}`,
        path: { idProject: idProject ? idProject : undefined },
        transform: (data) => data?.model,
    });
};

export const clearInputNames =
    ({ idProject }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: projectInputsNamesResourceName,
                key: `${projectInputsNamesResourceName}-${idProject}`,
                broadcast: true,
            })
        );
