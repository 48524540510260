import {
    USER_PROFILE_READ_REQUEST,
    USER_PROFILE_READ_SUCCESS,
    USER_PROFILE_READ_ERROR,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_PROFILE_UPDATE_ERROR,
    API_VDSM_GET_AUTHORIZED,
    API_PUT_AUTHORIZED,
} from "store/actionTypes";

export const API_BASE_URL = process.env.REACT_APP_VDSM_USERS_API_BASE_URL;
const ENDPOINT_BASE_URL = `${API_BASE_URL}profile`;

export const readProfile = () => async (dispatch, getState) => {
    if (getState().userProfile.isLoading) {
        return;
    }

    dispatch({
        type: API_VDSM_GET_AUTHORIZED,
        url: `${ENDPOINT_BASE_URL}`,
        actionTypes: {
            pending: USER_PROFILE_READ_REQUEST,
            response: USER_PROFILE_READ_SUCCESS,
            error: USER_PROFILE_READ_ERROR,
        },
    });
};

export const updateProfile =
    ({ data }) =>
    async (dispatch, getState) => {
        if (getState().userProfile.isLoading) {
            return;
        }

        dispatch({
            type: API_PUT_AUTHORIZED,
            url: `${ENDPOINT_BASE_URL}`,
            body: JSON.stringify(data),
            actionTypes: {
                pending: USER_PROFILE_UPDATE_REQUEST,
                response: USER_PROFILE_UPDATE_SUCCESS,
                error: USER_PROFILE_UPDATE_ERROR,
            },
            passThroughData: { data, showSuccessNotification: true },
        });
    };
