import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { createModule } from "store/resources/actions/calculationWorkflow/modulesActions";
import { useModuleStudyCases } from "store/resources/actions/calculationWorkflow/moduleStudyCasesActions";

import Button from "components/ui/ButtonNew";
import IconLoading from "components/ui/Icons/IconLoading";

import ModuleStudyCases from "layouts/common/ModuleStudyCases";

import { moduleTypes } from "utils/constants";

import { BaselineModuleProps } from "./types";

import "./style.scss";

const BaselineModule = memo(({ idProject, idModel, idInputLog }: BaselineModuleProps) => {
    const dispatch = useDispatch();

    const [moduleStudyCases, isLoadingModuleStudyCases] = useModuleStudyCases({ type: moduleTypes.BASELINE });

    const [selectedCase, setSelectedCase] = useState(0);

    useEffect(() => {
        if (selectedCase === 0 && !isEmpty(moduleStudyCases) && !isLoadingModuleStudyCases) {
            setSelectedCase(moduleStudyCases[0].studyCaseId);
        }
    }, [moduleStudyCases, isLoadingModuleStudyCases, selectedCase]);

    const onCaseChange = useCallback((event) => {
        setSelectedCase(parseInt(event.target.value));
    }, []);

    const onAddBaselineModuleClick = useCallback(() => {
        dispatch(
            createModule({
                idProject,
                idModel,
                idInputLog,
                studyCaseId: selectedCase,
                uiOrder: 1,
                type: moduleTypes.BASELINE,
            })
        );
    }, [idProject, idModel, idInputLog, selectedCase, dispatch]);

    return (
        <div className="baseline-module">
            <div className="baseline-module__title">Baseline Module</div>
            <div>
                {isLoadingModuleStudyCases ? (
                    <IconLoading />
                ) : (
                    <ModuleStudyCases
                        selectedCase={selectedCase}
                        studyCases={moduleStudyCases}
                        type={moduleTypes.BASELINE}
                        onCaseChange={onCaseChange}
                    />
                )}
            </div>
            <div className="flex-column align-center baseline-module__actions-container">
                <Button
                    variant="primary"
                    padding="lg"
                    iconLeft="ui-plus_in_circle__add__create_b_s"
                    iconSize="sm"
                    onClick={onAddBaselineModuleClick}
                >
                    Add New Baseline Module
                </Button>
            </div>
        </div>
    );
});

export default BaselineModule;
