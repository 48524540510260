///
/// Power BI actions
/// resourceName: powerBIResourceName, refreshPowerBIResourceName
///
import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

import { GetPowerBIReportParams, UsePowerBIReportParams } from "./types";
import { powerBIResourceName } from "store/configureResources";

const getPowerBIReportKey = (reportId: string, filterEntityId: number, idInputLog?: number) => {
    return idInputLog ? `${reportId}-${idInputLog}-${filterEntityId}` : `${reportId}-${filterEntityId}`;
};

const getPowerBIReport = ({
    resourceName,
    reportId,
    filterEntityId,
    datasetId,
    isPaginated,
    idInputLog,
    reportType,
}: GetPowerBIReportParams) => ({
    resourceName,
    key: getPowerBIReportKey(reportId, filterEntityId, idInputLog),
    path: {
        reportID: reportId,
    },
    query: {
        filterEntityId,
        datasetID: datasetId,
        isPaginated,
        reportType,
    },
});

export const usePowerBIReport = ({
    resourceName,
    reportId,
    filterEntityId,
    datasetId,
    isPaginated,
    idInputLog,
    reportType,
    forced = true,
}: UsePowerBIReportParams) => {
    return useResource({
        ...getPowerBIReport({ resourceName, reportId, filterEntityId, datasetId, isPaginated, idInputLog, reportType }),
        forced,
    });
};

export const clearPowerBIReport =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: powerBIResourceName,
                key: new RegExp(".*"),
                broadcast: true,
            })
        );
    };
