import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import { moduleAllRunsResourceName, moduleLastRunResourceName, moduleRunParamsResourceName } from "store/configureResources";

import { clearResource } from "store/resources/actions/clearResource";
import { useResource } from "store/resources/actions/useResource";
import { getResourcePromise } from "store/resources/actions/getResource";
import { updateResource, optimisticUpdateItem } from "store/resources/actions/updateResource";

import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import { getResourceState } from "store/utils";

import { CommonModuleParams, ModuleRun, LastModuleRun, ModuleRunsParams, UpdateRunParams } from "./types";

export const clearAllModuleRuns =
    ({ moduleId }: CommonModuleParams) =>
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: moduleAllRunsResourceName,
                key: `${moduleAllRunsResourceName}-${moduleId}`,
                broadcast: true,
            })
        );
    };

export const clearLastModuleRun =
    ({ moduleId }: CommonModuleParams) =>
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: moduleLastRunResourceName,
                key: `${moduleLastRunResourceName}-${moduleId}`,
                broadcast: true,
            })
        );
    };

export const useAllModuleRuns = ({ moduleId }: ModuleRunsParams) =>
    useResource({
        resourceName: moduleAllRunsResourceName,
        key: moduleId ? `${moduleAllRunsResourceName}-${moduleId}` : undefined,
        path: {
            moduleId,
        },
    }) as [ModuleRun[] | undefined, boolean | undefined, any];

export const getAllModuleRuns = ({ moduleId }: ModuleRunsParams) =>
    getResourcePromise({
        resourceName: moduleAllRunsResourceName,
        key: moduleId ? `${moduleAllRunsResourceName}-${moduleId}` : undefined,
        path: {
            moduleId,
        },
    }) as Promise<ModuleRun[]>;

export const useLastModuleRun = ({ moduleId }: ModuleRunsParams) =>
    useResource({
        resourceName: moduleLastRunResourceName,
        key: moduleId ? `${moduleLastRunResourceName}-${moduleId}` : undefined,
        path: {
            moduleId,
        },
    }) as [LastModuleRun[], boolean | undefined, any];

export const updateRun =
    ({ idProject, idModel, moduleId, resultsId, storageStatus, scenarioName, onSuccess }: UpdateRunParams) =>
    (dispatch, getState) => {
        const state = getState();

        const allModuleRuns = getResourceState<ModuleRun>(state, moduleAllRunsResourceName, { moduleId });

        const updateItems = allModuleRuns.map((run) =>
            run.resultsId === resultsId
                ? {
                      ...run,
                      storageStatus: storageStatus ?? run.storageStatus,
                      scenarioName: scenarioName ?? run.scenarioName,
                  }
                : run
        );

        if (!isEmpty(updateItems)) {
            dispatch(
                optimisticUpdateItem({
                    resourceName: moduleAllRunsResourceName,
                    key: `${moduleAllRunsResourceName}-${moduleId}`,
                    value: updateItems,
                })
            );
        }

        dispatch(
            updateResource({
                resourceName: moduleRunParamsResourceName,
                key: `${moduleRunParamsResourceName}-${resultsId}`,
                path: {
                    resultsId,
                },
                query: {
                    storageStatus,
                    scenarioName,
                },
                onSuccess: (action) => {
                    dispatch(clearAllModuleRuns({ moduleId }));
                    dispatch(clearProjectLogItems({ idProject }));
                    dispatch(clearModelLogItems({ idProject, idModel }));

                    if (!isEmpty(scenarioName)) {
                        toast.success("Scenario name for result successfully added");
                    }

                    onSuccess?.(action);
                },
            })
        );
    };
