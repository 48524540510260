import { VDSM_USER_SAVE, USER_PROFILE_UPDATE_SUCCESS } from "store/actionTypes";

import { getVdsmUser } from "utils/user";

export function userProfileMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        next(action);

        switch (action.type) {
            case USER_PROFILE_UPDATE_SUCCESS:
                onProfileUpdateSuccess(action);
                break;

            default:
                break;
        }
    };

    async function onProfileUpdateSuccess(action) {
        dispatch({
            type: VDSM_USER_SAVE,
            vdsmUser: getVdsmUser(),
        });
    }
}
