import { camelCase, chain, every, forEach, isEmpty, isUndefined, mapValues, omit, pick, startCase, some } from "lodash";

import { isNullOrWhitespace } from "utils/string";

const rowsWithoutSector = "Rows without a sector";
const columnSectorIsMissing = "Column sector is missing";

const assignableSectors = [rowsWithoutSector, columnSectorIsMissing];

const commonColumns = ["ID_INPUT_LOG", "SECTOR", "PEAK_SEASON", "LINE_LOSS"];
export const serviceColumns = ["IS_DUPLICATE", "idRow", "selected"];
export const freezedColumns = ["selected", "filterError", "END USE", "SEGMENT", "TECHNOLOGY", "VINTAGE", "FUEL"];

export const filterErrorKey = "filterError";

export const errorFiltersNames = {
    dupRow: "dupRow",
    badFormat: "badFormat",
};

const errorFiltersInit = {
    [errorFiltersNames.dupRow]: {
        title: "Duplicated row",
        apply: (item) => item.IS_DUPLICATE === "1",
    },
    [errorFiltersNames.badFormat]: {
        title: "Incorrect format",
        apply: (item) => some(item, (v, k) => !serviceColumns.includes(k) && !isNaN(Number(k)) && isNaN(v)),
    },
};

export const isAssignableSectors = (sector) => assignableSectors.includes(sector);

export const transformAvoidedCostTemplate = (data: any, years?: any) => {
    return chain(data)
        .groupBy((value) =>
            isUndefined(value.SECTOR) ? columnSectorIsMissing : isNullOrWhitespace(value.SECTOR) ? rowsWithoutSector : value.SECTOR
        )
        .mapValues((group) => ({
            ...pick(group[0], commonColumns),
            items: mapValues(group, (value, idRow) => ({ ...years, ...omit(value, commonColumns), idRow, selected: false })),
        }))
        .value();
};

export const getTabs = (avoidedCostsTemplateItemsGroupedBySector) =>
    isEmpty(avoidedCostsTemplateItemsGroupedBySector)
        ? undefined
        : Object.keys(avoidedCostsTemplateItemsGroupedBySector).map((key) => ({
              id: key,
              title: isAssignableSectors(key) ? key : startCase(camelCase(key)),
              error: isAssignableSectors(key),
          }));

export const initErrorFilters = () => mapValues(errorFiltersInit, (v) => ({ ...v, items: new Set() }));

export const nullifyErrorFilter = (errorFilters, errorFilter) => {
    if (every(errorFilters.current, (e) => e.items.size === 0)) {
        errorFilter.current = undefined;
    }
};

export const deleteErrorFilters = (errorFilters, item) => {
    forEach(errorFiltersNames, (n) => {
        errorFilters.current[n].items.delete(item.idRow);
    });
};

export const applyErrorFilters = (errorFilters, item) => {
    forEach(errorFiltersNames, (n) => {
        if (errorFilters.current[n].apply(item)) {
            errorFilters.current[n].items.add(item.idRow);
        }
    });
};
