import { useState, useEffect, useRef, useCallback } from "react";

import { UsePaginationOptionsParams } from "./types";

export const usePagination = (listItems: any[] = [], { id, limit, defaultPage = 0, onBeforeSelectPage }: UsePaginationOptionsParams) => {
    const [selectedPage, setSelectedPage] = useState(defaultPage);

    const refId = useRef(id);

    const pageCount = Math.ceil(listItems.length / limit);

    const sliceItemsByPage = (page: number) => {
        return listItems.slice(page * limit, page * limit + limit);
    };

    const onSelectPage = useCallback(
        (page: number) => {
            onBeforeSelectPage && onBeforeSelectPage(page);
            if (page >= 0 && page < pageCount) {
                setSelectedPage(page);
            }
        },
        [pageCount, onBeforeSelectPage]
    );

    useEffect(() => {
        if (listItems && pageCount) {
            if (refId.current !== id) {
                onSelectPage(defaultPage);
            }
            if (refId.current === id && pageCount <= selectedPage) {
                onSelectPage(pageCount - 1);
            }
            if (refId.current !== id) {
                refId.current = id;
            }
        }
    }, [listItems, id, pageCount, selectedPage, defaultPage, onSelectPage]);

    return [sliceItemsByPage(selectedPage), { selectedPage, pageCount, onSelectPage }];
};
