import {
    SYSTEM_STATUS_REQUEST,
    SYSTEM_STATUS_RECEIVE,
    SYSTEM_STATUS_ERROR,
    HEARTBEAT_STOP,
    APP_VERSION_CHANGED,
    SET_SERVER_TIMEZONE_OFFSET,
} from "store/actionTypes";

import { getInitialServerTimezoneOffset } from "utils/date";

const initialState = {
    systemStatus: false,
    systemStatusDateRestored: null,
    fileSystemStatus: false,
    fileSystemStatusDateRestored: null,
    heartbeatInterval: +process.env.REACT_APP_HEARTBEAT_INTERVAL,
    checked: false,
    pending: false,
    isLatestVersion: true,
    serverTimezoneOffset: null,
};

export const reducer = (state, action) => {
    state = state ?? {
        ...initialState,
        serverTimezoneOffset: getInitialServerTimezoneOffset(),
    };

    switch (action.type) {
        case SYSTEM_STATUS_REQUEST:
            state = {
                ...state,
                pending: true,
            };
            break;
        case SYSTEM_STATUS_RECEIVE:
            state = {
                ...state,
                ...action.data,
                checked: true,
                pending: false,
            };
            break;
        case SYSTEM_STATUS_ERROR:
            state = {
                ...initialState,
                checked: true,
            };
            break;
        case HEARTBEAT_STOP:
            state = {
                ...state,
                pending: false,
            };
            break;
        case APP_VERSION_CHANGED:
            state = {
                ...state,
                isLatestVersion: false,
            };
            break;
        case SET_SERVER_TIMEZONE_OFFSET:
            state = {
                ...state,
                serverTimezoneOffset: action.serverTimezoneOffset,
            };
            break;
        default:
            break;
    }

    return state;
};
