import { memo, useCallback, useState } from "react";

import FieldGroup from "components/ui/FieldGroup";
import TextArea from "components/ui/TextArea";

const TextFields = memo(() => {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const [value4, setValue4] = useState("");
    const [value5, setValue5] = useState("");

    const onChange = useCallback((event, handler) => {
        handler(event.target.value);
    }, []);

    return (
        <div className="flex-column">
            <div className="ui-library__element-group__sub-title">Standard</div>
            <FieldGroup className="flex-wrap ui-library__element-group__textarea" direction="row">
                <TextArea
                    label="Textarea"
                    placeholder="Text"
                    value={value1}
                    onChange={(event, handler = setValue1) => onChange(event, handler)}
                />
                <TextArea
                    label="Textarea"
                    required
                    placeholder="Required"
                    value={value2}
                    onChange={(event, handler = setValue2) => onChange(event, handler)}
                />
                <TextArea
                    label="Textarea"
                    focus
                    placeholder="Focused"
                    value={value3}
                    onChange={(event, handler = setValue3) => onChange(event, handler)}
                />
                <TextArea label="Textarea" disabled value="Disabled" />
                <TextArea label="Textarea" readOnly value="Read-only" />
                <TextArea
                    label="Textarea"
                    placeholder="Read below"
                    value={value4}
                    msgText="Describe what this Model will calculate"
                    onChange={(event, handler = setValue4) => onChange(event, handler)}
                />
                <TextArea
                    label="Textarea"
                    error
                    placeholder="Text"
                    value={value5}
                    msgText="Please enter a valid description"
                    onChange={(event, handler = setValue5) => onChange(event, handler)}
                />
            </FieldGroup>
        </div>
    );
});

export default TextFields;
