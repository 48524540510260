import { memo } from "react";

import "./style.scss";

const IconLoading = memo(({ label }: IconLoadingProps) => {
    return (
        <div className="flex-row align-center icon-loading">
            <div className="icon-loading__items-container">
                <div className="icon-loading__item" />
                <div className="icon-loading__item" />
                <div className="icon-loading__item" />
            </div>
            {label && <span className="icon-loading__label">{label}</span>}
        </div>
    );
});

interface IconLoadingProps {
    label?: string;
}

export default IconLoading;
