import { actionStatus } from "utils/constants";

export const logActionKeys = {
    DATA_IMPORT: "DATA_IMPORT",
    RUN_CALCULATION: "RUN_CALCULATION",
    MODEL_DUPLICATE: "MODEL_DUPLICATE",
    RUN_VALIDATION: "RUN_VALIDATION",
    CUSTOM_REPORT_UPLOAD: "CUSTOM_REPORT_UPLOAD",
    CUSTOM_REPORT_UPDATE: "CUSTOM_REPORT_UPDATE",
    PROJECT_STUDY_CASE_UPDATE: "PROJECT_STUDY_CASE_UPDATE",
    PROJECT_VARIABLE_UPDATE: "PROJECT_VARIABLE_UPDATE",
    PROJECT_LABELS_UPDATE: "PROJECT_LABELS_UPDATE",
    MODEL_CREATE: "MODEL_CREATE",
    MODEL_STATUS_PLANNED: "STATUS_UPDATE_PLANNED",
    MODEL_STATUS_PROPOSED: "STATUS_UPDATE_PROPOSED",
    MODEL_STATUS_IN_PROGRESS: "STATUS_UPDATE_IN_PROGRESS",
    MODEL_STATUS_IN_MANAGER_REVIEW: "STATUS_UPDATE_IN_MANAGER_REVIEW",
    MODEL_STATUS_IN_CLIENT_REVIEW: "STATUS_UPDATE_IN_CLIENT_REVIEW",
    MODEL_STATUS_CLIENT_UNAPPROVED: "STATUS_UPDATE_CLIENT_UNAPPROVED",
    MODEL_STATUS_CLIENT_APPROVED: "STATUS_UPDATE_APPROVED",
    MODEL_STATUS_FINAL: "STATUS_UPDATE_FINAL",
    MODEL_STUDY_CASE_UPDATE: "MODEL_STUDY_CASE_UPDATE",
    MODEL_VARIABLE_UPDATE: "MODEL_VARIABLE_UPDATE",
    AVOIDED_COST_UPDATE: "AVOIDED_COST_UPDATE",
    USER_NOTE: "USER_NOTE",
    MARKET_PROFILE_IMPORT: "MARKET_PROFILE_IMPORT",
};

export const logAction = {
    [logActionKeys.DATA_IMPORT]: {
        default: "Imported",
        [actionStatus.STARTED]: "Importing",
        [actionStatus.IN_PROGRESS]: "Importing",
        [actionStatus.DONE]: "Imported",
        [actionStatus.ERROR]: "Import failed",
        [actionStatus.FAILED]: "Import failed",
        [actionStatus.TERMINATED]: "Import terminated",
    },
    [logActionKeys.RUN_CALCULATION]: {
        default: "Calculated",
        [actionStatus.STARTED]: "Calculating",
        [actionStatus.IN_PROGRESS]: "Calculating",
        [actionStatus.DONE]: "Calculated",
        [actionStatus.ERROR]: "Calculation failed",
        [actionStatus.FAILED]: "Calculation failed",
        [actionStatus.TERMINATED]: "Calculation terminated",
    },
    [logActionKeys.MODEL_DUPLICATE]: {
        [actionStatus.ERROR]: "Duplicate failed",
        [actionStatus.DONE]: "Duplicated",
        default: "Duplicated",
    },
    // TODO: Verify if this is still used
    // [logActionKeys.RUN_VALIDATION]: {
    //     default: "Validated",
    // },
    [logActionKeys.CUSTOM_REPORT_UPLOAD]: {
        default: "Uploaded",
    },
    [logActionKeys.CUSTOM_REPORT_UPDATE]: {
        default: "Updated",
    },
    [logActionKeys.PROJECT_STUDY_CASE_UPDATE]: {
        [actionStatus.ERROR]: "Project Study Case update failed",
        [actionStatus.DONE]: "Project Study Case updated",
        default: "Project Study Case updated",
    },
    [logActionKeys.PROJECT_VARIABLE_UPDATE]: {
        [actionStatus.ERROR]: "Project Variables update failed",
        [actionStatus.DONE]: "Project Variables updated",
        default: "Project Variables updated",
    },
    [logActionKeys.PROJECT_LABELS_UPDATE]: {
        [actionStatus.ERROR]: "Project Labels update failed",
        [actionStatus.DONE]: "Project Labels updated",
        default: "Project Labels updated",
    },
    [logActionKeys.MODEL_CREATE]: {
        default: "Model created",
    },
    [logActionKeys.MODEL_STATUS_PLANNED]: {
        default: "Set Model to Planned",
    },
    [logActionKeys.MODEL_STATUS_PROPOSED]: {
        default: "Set Model to Proposed",
    },
    [logActionKeys.MODEL_STATUS_IN_PROGRESS]: {
        default: "Set Model to In Progress",
    },
    [logActionKeys.MODEL_STATUS_IN_MANAGER_REVIEW]: {
        default: "Set Model to In Manager Review",
    },
    [logActionKeys.MODEL_STATUS_IN_CLIENT_REVIEW]: {
        default: "Set Model to In Client Review",
    },
    [logActionKeys.MODEL_STATUS_CLIENT_UNAPPROVED]: {
        default: "Set Model to Unapproved",
    },
    [logActionKeys.MODEL_STATUS_CLIENT_APPROVED]: {
        default: "Set Model to Approved",
    },
    [logActionKeys.MODEL_STATUS_FINAL]: {
        default: "Set Model to Final",
    },
    [logActionKeys.MODEL_STUDY_CASE_UPDATE]: {
        [actionStatus.ERROR]: "Model Study Case update failed",
        [actionStatus.DONE]: "Model Study Case updated",
        default: "Model Study Case updated",
    },
    [logActionKeys.MODEL_VARIABLE_UPDATE]: {
        [actionStatus.ERROR]: "Model Variables update failed",
        [actionStatus.DONE]: "Model Variables updated",
        default: "Model Variables updated",
    },
    [logActionKeys.AVOIDED_COST_UPDATE]: {
        [actionStatus.ERROR]: "Avoided Costs update failed",
        [actionStatus.DONE]: "Avoided Costs updated",
        default: "Avoided Costs updated",
    },
    [logActionKeys.USER_NOTE]: {
        default: "User note added",
    },
    // TODO: Not implemented in DB
    // [logActionKeys.MARKET_PROFILE_IMPORT]: {
    //     default: "Market Profile data imported",
    // },
};
