///
/// User authentication actions
/// resourceName: usersQuickResponseCodeResourceName
///
import { usersQuickResponseCodeResourceName } from "store/configureVDSMResources";

import { deleteResource } from "store/resources/actions/deleteResource";

import { DeleteQrCodeParams } from "./types";

export const deleteQrCode =
    ({ userNumber, onComplete }: DeleteQrCodeParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            deleteResource({
                resourceName: usersQuickResponseCodeResourceName,
                path: {
                    userNumber,
                },
                showErrorNotification: false,
                onComplete,
            })
        );
