import { isEmpty } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { projectDataImportResourceName } from "store/configureResources";

import {
    getMarketProfileData,
    updateMarketProfileData,
    deleteMarketProfileData,
} from "store/resources/actions/marketProfile/marketProfileActions";
import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";

import { useProjectDataImportStatus } from "store/processes/useProjectDataImportStatus";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import IconWrap from "components/ui/Icons";
import IconLoading from "components/ui/Icons/IconLoading";
import CustomList from "components/ui/List/CustomList";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import UserName from "components/ui/UserName";

import PowerBI from "pages/PowerBI";

import { openModalDialogCustomActions, openModalDialogUploadProgress } from "layouts/Modal/utils";
import { openModalDialogUploadMarketProfileData } from "layouts/Modal/ProjectDashboardModal/utils";

import { projectReadOnlyEnabled } from "pages/utils";

import { USER_ACTIONS } from "utils/user/defines";
import { toLocaleDateTime } from "utils/dateTime";
import { hasAnyOfPermissions } from "utils/user";

import { MarketProfileSnapshotProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/MarketProfileSnapshotPanel/types";
import { MarketProfileData } from "store/resources/actions/marketProfile/types";
import { ProjectInput } from "store/resources/actions/projectInput/types";

import "./style.scss";

type AllSectors = {
    id: number;
    label: string;
    value: string;
}[];

const HEADERS = {
    file: "FILE",
    sector: "SECTOR",
    lastUpdate: "LAST UPDATE",
    uploadedBy: "UPLOADED BY",
    result: {
        label: "",
        sortable: false,
    },
};

const MarketProfileSnapshot = memo(({ project, data, isLoading }: MarketProfileSnapshotProps) => {
    const reportId = process.env.REACT_APP_POWERBI_VLM_MARKET_PROFILE_SNAPSHOT_REPORT_ID;

    const { idClient, idProject, projectState } = project;

    const dispatch = useDispatch();

    // Resources

    const [projectInputs, isLoadingProjectInputs] = useProjectInputs({
        idProject,
        transform: (data) => data?.filter((pi) => pi?.active === true),
    });

    const importStatus = useProjectDataImportStatus({ idProject });

    // useMemo

    const allSectors = useMemo(() => {
        const allSectors: AllSectors = [];

        if (!isEmpty(projectInputs) && !isLoadingProjectInputs) {
            projectInputs.forEach((pi: ProjectInput) => {
                if (pi.sectors) {
                    pi.sectors.forEach((sector) => {
                        if (!allSectors.find((s) => s.id === sector.idSector)) {
                            allSectors.push({
                                id: sector.idSector,
                                label: sector.name,
                                value: sector.name?.toLowerCase(),
                            });
                        }
                    });
                }
            });
        }

        return allSectors;
    }, [projectInputs, isLoadingProjectInputs]);

    // Event handlers

    /**
     * Change event handler for changing sector
     * for Market Profile data.
     */
    const onChange = useCallback(
        (value, idProjectUpload) => {
            const sector = allSectors.find((s) => s.value === value);

            if (sector) {
                dispatch(
                    updateMarketProfileData({
                        idProject,
                        idProjectUpload,
                        idSector: sector.id,
                        sector: sector.value,
                    })
                );
            }
        },
        [idProject, allSectors, dispatch]
    );

    /**
     * Market Profile data upload starts.
     */
    const onUploadStart = useCallback(
        (id: string, numberOfFiles: number) => {
            dispatch(
                openModalDialogUploadProgress({
                    resourceId: id,
                    resourceName: projectDataImportResourceName,
                    message: `Uploading ${numberOfFiles === 1 ? "file" : "files"}.`,
                    title: "Market Profile Upload",
                })
            );
        },
        [dispatch]
    );

    /**
     * Function to run when either Market Profile
     * data is uploaded or data is updated.
     */
    const onUploadComplete = useCallback(() => {
        dispatch(
            getMarketProfileData({
                idProject,
            })
        );
    }, [idProject, dispatch]);

    /**
     * Opens Modal window where Market Profile
     * data can be inserted.
     */
    const onUploadClick = useCallback(() => {
        dispatch(
            openModalDialogUploadMarketProfileData({
                idProject,
                onUploadStart,
                onUploadComplete,
            })
        );
    }, [idProject, onUploadStart, onUploadComplete, dispatch]);

    /**
     * Opens Modal window where Market Profile
     * data can be updated.
     */
    const onUpdateClick = useCallback(
        (idProjectUpload) => {
            dispatch(
                openModalDialogUploadMarketProfileData({
                    idProject,
                    idProjectUpload,
                    onUploadStart,
                    onUploadComplete,
                })
            );
        },
        [idProject, onUploadStart, onUploadComplete, dispatch]
    );

    /**
     * Deletes Market Profile data.
     */
    const onDeleteConfirm = useCallback(
        (idProjectUpload, fileName) => {
            dispatch(
                deleteMarketProfileData({
                    idProject,
                    idProjectUpload,
                    fileName,
                })
            );
        },
        [idProject, dispatch]
    );

    /**
     * Opens Modal confirmation window to verify
     * if Market Profile data should be deleted.
     */
    const onDeleteClick = useCallback(
        (idProjectUpload, fileName) => {
            dispatch(
                openModalDialogCustomActions({
                    title: `Delete ${fileName}`,
                    text: <div>Are you sure you want to delete this file?</div>,
                    actions: [
                        {
                            icon: "ui-delete_forever_b_s",
                            primary: true,
                            danger: true,
                            children: "Delete permanently",
                            onClick: () => {
                                onDeleteConfirm(idProjectUpload, fileName);
                            },
                        },
                        {
                            children: "Cancel",
                        },
                    ],
                })
            );
        },
        [onDeleteConfirm, dispatch]
    );

    // Render function

    const renderItem = (item: MarketProfileData, index: number) => {
        const { idProjectUpload, fileName } = item;

        let importInProgress = false;

        if (idProjectUpload !== null && importStatus) {
            if (
                importStatus[idProjectUpload]?.status === "IN PROGRESS" ||
                importStatus[idProjectUpload]?.finished === null ||
                item.status === "IN PROGRESS"
            ) {
                importInProgress = true;
            }
        }

        return (
            <div
                key={`${item.fileName}-${index}`}
                className={cn("list-item-row market-profile-data-list-row", {
                    "row-with-error": item.status === "ERROR" && item.statusMessage,
                })}
            >
                <div className="flex-column flex-one">
                    <div className="flex-row align-center">
                        <div className="item-value column-file">{item.fileName}</div>
                        {importInProgress && (
                            <div className="item-value justify-start">
                                <IconLoading />
                            </div>
                        )}
                        <div className="item-value column-sector">
                            <Dropdown
                                value={item.sector || undefined}
                                items={allSectors}
                                onChange={(value) => onChange(value, idProjectUpload)}
                            />
                        </div>
                        <div className="item-value column-last-update">{toLocaleDateTime(`${item.lastUpdateDate}Z`)}</div>
                        <div className="item-value column-uploaded-by">
                            {/* @ts-ignore */}
                            <UserName userNumber={item.uploadUser} />
                        </div>
                        <div className="item-value column-result">
                            <div className="flex-row align-center">
                                <div>
                                    <IconWrap icon="upload" title="Update file" onClick={() => onUpdateClick(idProjectUpload)} />
                                </div>
                                <div className="margin-left-small">
                                    <IconWrap
                                        icon="delete-trash-empty"
                                        title="Delete file"
                                        onClick={() => onDeleteClick(idProjectUpload, fileName)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {item.status === "ERROR" && item.statusMessage && (
                        <div className="row-error-message">
                            <ErrorMsg icon="octagon-exclamation-empty" message={item.statusMessage} />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="market-profile-snapshot">
            {/* Upload action only available for internal users */}
            {!projectReadOnlyEnabled(projectState) && hasAnyOfPermissions([USER_ACTIONS.MARKET_PROFILE_EDIT]) && (
                <div className="flex-row align-center justify-space-between">
                    <span className="market-profile-snapshot__message">
                        Upload data to see Market Profile Snapshot report and Baseline Utility Forecast Comparison report.
                    </span>
                    <Button variant="primary" iconLeft="arrows-align_top_b_a" iconSize="sm" padding="lg" onClick={onUploadClick}>
                        Import Data
                    </Button>
                </div>
            )}

            <div className="market-profile-snapshot__data-container">
                {/* Loading Market Profile data */}
                {isLoading || isLoadingProjectInputs ? (
                    <IconLoading />
                ) : (
                    // Market Profile data list only available for internal users
                    !isEmpty(data) &&
                    !projectReadOnlyEnabled(projectState) &&
                    hasAnyOfPermissions([USER_ACTIONS.MARKET_PROFILE_EDIT]) && (
                        <CustomList
                            // @ts-ignore
                            headers={HEADERS}
                            items={data}
                            limit={4}
                            sortable
                            paginationAlignToRight
                            renderItem={renderItem}
                        />
                    )
                )}
            </div>

            {!isLoading && !isLoadingProjectInputs && (
                <div className="market-profile-snapshot__report-container">
                    {/* No Market Profile data uploaded */}
                    {isEmpty(data) ? (
                        <ErrorMsg icon="alert-error-empty" message="No Market Profile data found to display the report." />
                    ) : (
                        // Market Profile Snapshot report
                        reportId && (
                            <div className="market-profile-snapshot__report-wrapper">
                                <PowerBI
                                    idClient={idClient}
                                    idProject={idProject}
                                    reportId={reportId}
                                    hideActions
                                    filterEntityId={idProject} // filter by Project ID
                                />
                            </div>
                        )
                    )}
                </div>
            )}
        </div>
    );
});

export default MarketProfileSnapshot;
