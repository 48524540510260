import { isEmpty, isNil, kebabCase } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { componentModelDataImportV3ResourceName } from "store/configureResources";

import { updateComponentModel, deleteComponentModel } from "store/resources/actions/componentModel/componentModelActions";
import { useProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useProjectFuels } from "store/resources/actions/fuel/fuelActions";

import { selectProjection } from "store/projections/actions";

import Button from "components/ui/ButtonNew";
import Label from "components/ui/Label";
import LabelWithIcon from "components/ui/LabelWithIcon";
import { DeterminateProgressBar } from "components/ui/ProgressBar";
import Separator from "components/ui/Separator";
import Switcher from "components/ui/Switcher";
import Tag from "components/ui/Tag";

import ImportDataPanel from "pages/ManageProject/ProjectionsContent/common/ImportDataPanel";

import {
    openModalDialogDeleteProjection,
    openModalDialogRestoreProjection,
    openModalDialogDeletePermanentlyProjection,
} from "layouts/Modal/utils";
import { openModalDialogEditModel } from "layouts/Modal/ModelDashboardModal/utils";

import { actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { projections } from "utils/constants";
import { hasAnyOfPermissions, hasInternalUserRole } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { Territory, Fuel } from "types/types";
import { DashboardHeaderProps } from "./types";

import "./style.scss";

const DashboardHeader = memo(({ idClient, viewIndex, model }: DashboardHeaderProps) => {
    const {
        idProject,
        idModel,
        idTemplate,
        idTerritory,
        idFuel,
        idAnalyst,
        idReviewer,
        analyst,
        reviewer,
        name,
        description,
        required,
        active,
        modelState,
    } = model;

    const dispatch = useDispatch();

    // Resources

    const [projectTerritories, isLoadingProjectTerritories] = useProjectTerritories({ idProject });
    const [projectFuels, isLoadingProjectFuels] = useProjectFuels({ idProject });

    const userRights = useUserRights();

    const isUploading = Boolean(model.uploadProps?.isUploading);

    // Memos

    const territory = useMemo(() => {
        let territory;

        if (!isEmpty(projectTerritories) && !isLoadingProjectTerritories) {
            territory = projectTerritories.find((territory: Territory) => territory.idTerritory === idTerritory);
        }

        return territory;
    }, [idTerritory, projectTerritories, isLoadingProjectTerritories]);

    const fuel = useMemo(() => {
        let fuel;

        if (!isEmpty(projectFuels) && !isLoadingProjectFuels) {
            fuel = projectFuels.find((fuel: Fuel) => fuel.idFuel === idFuel);
        }

        return fuel;
    }, [idFuel, projectFuels, isLoadingProjectFuels]);

    // Helper functions

    const onEditModel = useCallback(
        ({ modelName, modelDescription, modelAnalyst, modelReviewer, modelTerritory, modelFuel, onSuccess }) => {
            dispatch(
                updateComponentModel({
                    idProject,
                    idModel,
                    idTerritory: modelTerritory,
                    idFuel: modelFuel,
                    idAnalyst: modelAnalyst,
                    idReviewer: modelReviewer,
                    name: modelName,
                    description: modelDescription,
                    onSuccess,
                })
            );
        },
        [idProject, idModel, dispatch]
    );

    const onMoveModel = useCallback(
        (active) => {
            dispatch(
                updateComponentModel({
                    idProject,
                    idModel,
                    active,
                    action: active ? "Restoring Model" : "Moving Model to Trash Bin",
                })
            );
        },
        [idProject, idModel, dispatch]
    );

    const onDeleteSuccess = useCallback(() => {
        dispatch(
            selectProjection({
                viewIndex,
                idProjectionView: projections.PROJECT_DASHBOARD,
                idProject,
            })
        );
    }, [idProject, viewIndex, dispatch]);

    const onDeleteModel = useCallback(() => {
        dispatch(
            deleteComponentModel({
                idProject,
                idModel,
                onSuccess: onDeleteSuccess,
            })
        );
    }, [idProject, idModel, onDeleteSuccess, dispatch]);

    // Event handlers

    const onRequiredChange = useCallback(() => {
        dispatch(
            updateComponentModel({
                idProject,
                idModel,
                required: !required,
                action: "Updating Model",
            })
        );
    }, [idProject, idModel, required, dispatch]);

    const onEditClick = useCallback(() => {
        dispatch(
            openModalDialogEditModel({
                idClient,
                idProject,
                modelName: name,
                modelDescription: description ? description : undefined,
                modelAnalyst: idAnalyst,
                modelReviewer: idReviewer,
                modelTerritory: idTerritory,
                modelFuel: idFuel,
                // @ts-ignore
                onEdit: onEditModel,
            })
        );
    }, [idClient, idProject, name, description, idAnalyst, idReviewer, idTerritory, idFuel, onEditModel, dispatch]);

    const onDeleteClick = useCallback(() => {
        dispatch(
            openModalDialogDeleteProjection({
                title: "Delete Model",
                projectionName: name,
                onMove: () => onMoveModel(false),
                onDelete: onDeleteModel,
            })
        );
    }, [name, onMoveModel, onDeleteModel, dispatch]);

    const onRestoreClick = useCallback(() => {
        dispatch(
            openModalDialogRestoreProjection({
                title: "Restore Model",
                text: (
                    <>
                        <div>
                            <strong>{name}</strong> will be restored to the Project.
                        </div>
                        <div>You will be able to import data and run calculations.</div>
                    </>
                ),
                onRestore: () => onMoveModel(true),
            })
        );
    }, [name, onMoveModel, dispatch]);

    const onDeletePermanentlyClick = useCallback(() => {
        dispatch(
            openModalDialogDeletePermanentlyProjection({
                title: "Delete Model Permanently",
                projectionName: name,
                onDelete: onDeleteModel,
            })
        );
    }, [name, onDeleteModel, dispatch]);

    return (
        <div className="flex-column component-model-dashboard-header">
            <div className="component-model-dashboard-header__first-level-container">
                <div className="flex-row align-center component-model-dashboard-header__top-container">
                    <div>
                        <div className="component-model-dashboard-header__model">Model</div>
                        <div className="flex-row align-center">
                            <div className="component-model-dashboard-header__title">{name}</div>
                            {!isUploading && !isNil(idModel) && active && actionAllowed(userRights, USER_ACTIONS.MODEL_EDIT, required) && (
                                <div className="margin-left-small">
                                    <Button
                                        variant="tertiary"
                                        icon="ui-pencil__edit__create_b_s"
                                        iconSize="sm"
                                        title="Edit model information"
                                        padding="sm"
                                        onClick={onEditClick}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="component-model-dashboard-header__middle-container">
                    <span>{description}</span>
                </div>
                <div className="flex-row align-center justify-space-between component-model-dashboard-header__bottom-container">
                    <div className="flex-column flex-one component-model-dashboard-header__model-details-container">
                        <div className="flex-row component-model-dashboard-header__model-details">
                            <div>
                                <span className="component-model-dashboard-header__label">ID: </span>
                                <span>{typeof idModel === "string" ? "0" : idModel}</span>
                            </div>
                            <div className="margin-left">
                                <span className="component-model-dashboard-header__label">Territory: </span>
                                <span>{territory?.alias || territory?.name || "None"}</span>
                            </div>
                            <div className="margin-left">
                                <span className="component-model-dashboard-header__label">Fuel: </span>
                                <span>{fuel?.name || "None"}</span>
                            </div>
                        </div>
                        <div className="flex-row">
                            <div>
                                <span className="component-model-dashboard-header__label">Analyst responsible: </span>
                                <span>{analyst || "None"}</span>
                            </div>
                            <div className="margin-left">
                                <span className="component-model-dashboard-header__label">Reviewer responsible: </span>
                                <span>{reviewer || "None"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="component-model-dashboard-header__model-actions-container">
                        {(required || hasInternalUserRole()) && (
                            <div className="component-model-dashboard-header__status-container">
                                <div className="flex-row align-center">
                                    <Label>Status</Label>
                                    <div className="aeg-tag-wrapper">
                                        <Tag className={kebabCase(modelState)} size="sm">
                                            <>{modelState.toLowerCase()}</>
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isUploading && !isNil(idModel) && (
                            <div className="flex-row">
                                {active && actionAllowed(userRights, USER_ACTIONS.MODEL_DELETE, required) && (
                                    <div className="margin-left-small">
                                        <Button
                                            variant="tertiary"
                                            padding="sm"
                                            iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                                            iconSize="sm"
                                            onClick={onDeleteClick}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                )}
                                {!active && actionAllowed(userRights, USER_ACTIONS.MODEL_DELETE, required) && (
                                    <>
                                        <div className="margin-left-small">
                                            <Button
                                                variant="tertiary"
                                                padding="sm"
                                                iconLeft="arrows-reply_b_a"
                                                iconSize="sm"
                                                onClick={onRestoreClick}
                                            >
                                                Restore
                                            </Button>
                                        </div>
                                        <div className="margin-left-small">
                                            <Button
                                                variant="tertiary"
                                                padding="sm"
                                                iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                                                iconSize="sm"
                                                onClick={onDeletePermanentlyClick}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="component-model-dashboard-header__third-level-container">
                <Separator line />
                <div className="flex-row align-center">
                    {!isUploading && !isNil(idModel) && active && hasAnyOfPermissions([USER_ACTIONS.MODEL_ADD]) && (
                        <ImportDataPanel
                            viewIndex={viewIndex}
                            idClient={idClient}
                            idProject={idProject}
                            idModel={idModel}
                            idTemplate={idTemplate}
                            idTerritory={idTerritory}
                            idFuel={idFuel}
                            modelName={name}
                        />
                    )}
                    <div className="flex-row flex-one justify-end">
                        <Switcher
                            active={required === null ? false : required}
                            label="Required for project"
                            disabled={isUploading && isNil(idModel) ? true : !hasAnyOfPermissions([USER_ACTIONS.MODEL_EDIT_REQUIRED])}
                            onClick={onRequiredChange}
                        />
                    </div>
                </div>
            </div>
            <div className="component-model-dashboard-header__fourth-level-container">
                {/* Uploading dataset progress bar */}
                {isUploading && (
                    <div className="component-model-dashboard-header__progress-bar">
                        <Separator />
                        <DeterminateProgressBar
                            resourceId={model.uploadProps.uploadId}
                            resourceName={componentModelDataImportV3ResourceName}
                            topElement={
                                <LabelWithIcon
                                    className="component-model-dashboard-header__progress-bar-top-element"
                                    icon="arrows-sync__autorenew_b_a"
                                >{`Uploading dataset | ${model.uploadProps.fileName}`}</LabelWithIcon>
                            }
                            showPercentage
                        />
                    </div>
                )}
            </div>
        </div>
    );
});

export default DashboardHeader;
