import { memo } from "react";

import TrashBinLog from "./TrashBinLog";

import DashboardPanel from "components/ui/Dashboard";

import { TrashBinLogProps } from "./types";

const TrashBinLogPanel = memo(({ idProject, viewIndex, modelsInTrash, standaloneReportsInTrash }: TrashBinLogProps) => {
    return (
        <DashboardPanel className="trash-bin-log-panel" title="Removed">
            <TrashBinLog
                idProject={idProject}
                viewIndex={viewIndex}
                modelsInTrash={modelsInTrash}
                standaloneReportsInTrash={standaloneReportsInTrash}
            />
        </DashboardPanel>
    );
});

export default TrashBinLogPanel;
