import ClassNames from "classnames";

import { IconTriangleProps } from "./types";

import "./style.scss";

const IconTriangle = ({ className, direction = "downwards" }: IconTriangleProps) => {
    const classNames = ClassNames("icon-triangle", className, direction);

    return <div className={classNames} />;
};

export default IconTriangle;
