import StatusMsg, { StatusMsgInterface } from "components/ui/StatusMsg";

const SuccessMsg = (props: StatusMsgInterface) => {
    const icon = props.icon ? props.icon : "check-circle-filled";

    return (
        <StatusMsg
            success
            largeMsg={props.largeMsg}
            iconWithLabelMedium={props.iconWithLabelMedium}
            icon={!props.largeMsg || !props.withoutIcon ? icon : undefined}
            message={props.message}
        />
    );
};

export default SuccessMsg;
