import { memo } from "react";

import { useModelLogNotes } from "store/resources/actions/scenarioLog/modelLogNotesActions";

import ModelNotes from "layouts/Sidebar/common/ModelNotes";

import { ModelNoteInterface } from "layouts/Sidebar/common/ModelNotes/types";
import { SideNavHeaderProps } from "components/ui/SideNav/SideNavHeader";

const ModelLogNoteSidebar = memo((props: ModelNoteInterface & SideNavHeaderProps) => {
    const { idProject, idInputLog, idModel, idStudyLog, noteType, onClose } = props;

    const [modelLogNotes = [], isLoading] = useModelLogNotes({ idStudyLog: idStudyLog === 0 ? undefined : idStudyLog });

    return (
        <ModelNotes
            idProject={idProject}
            idInputLog={idInputLog}
            idModel={idModel}
            idStudyLog={idStudyLog}
            notes={modelLogNotes}
            noteType={noteType}
            variant="model-log"
            isLoadingNotes={isLoading}
            onClose={onClose}
        />
    );
});

export default ModelLogNoteSidebar;
