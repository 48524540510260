import { memo, useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { forgotPassword, resetLogin } from "store/login/actions";

import FormWrap from "pages/common/FormWrap";

import Button from "components/ui/Button";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import Captcha from "components/ui/Captcha";
import FieldGroup from "components/ui/FieldGroup";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";

import { openWindowLogin } from "utils/window";

import "./style.scss";

const ForgotPassword = memo(() => {
    const dispatch = useDispatch();

    // @ts-ignore
    const login = useSelector((state) => state.login);

    const [username, setUsername] = useState("");
    const [recaptcha, setRecaptcha] = useState<string | null>(null);

    const captchaRef = useRef<ReCAPTCHA>(null);

    const isValid = useCallback(() => {
        return username.trim().length > 0 && recaptcha !== null;
    }, [username, recaptcha]);

    const onChange = useCallback((event, handler) => {
        handler(event.target.value);
    }, []);

    const onSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (isValid()) {
                dispatch(forgotPassword({ userName: username, recaptcha }));
            }
        },
        [username, recaptcha, isValid, dispatch]
    );

    const onRedirect = useCallback(() => {
        // If e-mail with password has been sent
        if (login.infoMessage) {
            dispatch(resetLogin());
        } else {
            dispatch(openWindowLogin());
        }
    }, [login.infoMessage, dispatch]);

    return (
        <FormWrap>
            <form className="flex-column forgot-password-form" onSubmit={onSubmit}>
                {login.infoMessage ? (
                    <>
                        <h3 className="text-center">Check in your e-mail!</h3>
                        <p className="text-center forgot-password-form__info-message">
                            We just e-mailed you with the instructions to reset your password.
                        </p>
                        <div className="text-center forgot-password-form__redirect" onClick={onRedirect}>
                            Return to login page
                        </div>
                    </>
                ) : (
                    <>
                        {login.isLoading ? (
                            <div className="flex-column flex-one align-center justify-center">
                                <IconLoading />
                            </div>
                        ) : (
                            <>
                                {login.errorMessage && (
                                    <div className="forgot-password-form__error-message">
                                        <ErrorMsg largeMsg message={login.errorMessage} />
                                    </div>
                                )}
                                <div className="text-center forgot-password-form__header">Forgot your password?</div>
                                <FieldGroup className="forgot-password-form__inputs">
                                    <div>
                                        <p>No problem. Just enter your username below and we'll send you an e-mail with instructions.</p>
                                        <Input
                                            className="forgot-password-form__username-input"
                                            label="Username"
                                            name="userName"
                                            placeholder="Enter your username"
                                            value={username}
                                            autoFocus
                                            onChange={(event) => onChange(event, setUsername)}
                                        />
                                    </div>
                                    <div className="flex-column align-center">
                                        <Captcha captchaRef={captchaRef} onChange={(token) => setRecaptcha(token)} />
                                    </div>
                                </FieldGroup>
                            </>
                        )}
                        <div className="flex-row align-center justify-space-between">
                            <span className="forgot-password-form__redirect" onClick={onRedirect}>
                                Return to login page
                            </span>
                            <Button primary type="submit" disabled={login.isLoading || !isValid()}>
                                {login.isLoading ? "Loading..." : "Request password"}
                            </Button>
                        </div>
                    </>
                )}
            </form>
        </FormWrap>
    );
});

export default ForgotPassword;
