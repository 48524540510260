import { isEmpty } from "lodash";
import { memo } from "react";

import RadioCard from "components/ui/Input/RadioCard";
import FieldGroup from "components/ui/FieldGroup";

import { ModuleStudyCasesProps } from "./types";

const ModuleStudyCases = memo(({ selectedCase, studyCases, type, readOnly, onCaseChange }: ModuleStudyCasesProps) => {
    return (
        <>
            {!isEmpty(studyCases) && (
                <FieldGroup>
                    {studyCases.map((studyCase) => (
                        <RadioCard
                            key={`module-study-case-${studyCase.studyCaseId}`}
                            id={`${studyCase.studyCaseId}`}
                            name={type}
                            label={studyCase.uiLabel}
                            value={studyCase.studyCaseId}
                            checked={selectedCase === studyCase.studyCaseId}
                            readOnly={readOnly}
                            onChange={onCaseChange}
                        />
                    ))}
                </FieldGroup>
            )}
        </>
    );
});

export default ModuleStudyCases;
