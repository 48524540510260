import { isEmpty } from "lodash";
import { memo } from "react";

import { useProcessesInProgress } from "store/processes/useProcessStatus";

import { useModules } from "store/resources/actions/calculationWorkflow/modulesActions";

import IconLoading from "components/ui/Icons/IconLoading";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import BaselineModule from "./BaselineModule";
import ModulesContainer from "./ModulesContainer";

import { actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { USER_ACTIONS } from "utils/user/defines";

import { ManageCalculationWorkflowProps } from "./types";

import "./style.scss";

const ManageCalculationWorkflow = memo(
    ({
        viewIndex,
        idClient,
        idProject,
        idAnalyst,
        idReviewer,
        idModel,
        idInputLog,
        modelName,
        modelState,
        modelRequired,
    }: ManageCalculationWorkflowProps) => {
        useProcessesInProgress({ idProject }); // start checking calculation statuses (if not started already)

        const [modules, isLoadingModules] = useModules({ idModel });

        const userRights = useUserRights();

        return (
            <div className="flex-column fill-height with-scroll manage-calculation-workflow">
                {isLoadingModules ? (
                    <div className="flex-column flex-one-in-column align-center justify-center">
                        <IconLoading />
                    </div>
                ) : isEmpty(modules) ? (
                    <div className="flex-column align-center">
                        {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) ? (
                            <BaselineModule idProject={idProject} idModel={idModel} idInputLog={idInputLog} />
                        ) : (
                            <NothingFoundBlock
                                className="no-modules"
                                icon="files-chart__assessment_b_s"
                                title="There are no modules for the selected model"
                            />
                        )}
                    </div>
                ) : (
                    <ModulesContainer
                        viewIndex={viewIndex}
                        idClient={idClient}
                        idProject={idProject}
                        idAnalyst={idAnalyst}
                        idReviewer={idReviewer}
                        idModel={idModel}
                        idInputLog={idInputLog}
                        modules={modules}
                        modelName={modelName}
                        modelState={modelState}
                        modelRequired={modelRequired}
                    />
                )}
            </div>
        );
    }
);

export default ManageCalculationWorkflow;
