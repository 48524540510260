import { memo } from "react";

import StatusMsg from "components/ui/StatusMsg";
import Separator from "components/ui/Separator";
import { DeterminateProgressBar } from "components/ui/ProgressBar";

import { UploadProgressProps } from "./types";

const UploadProgress = memo(({ resourceId, resourceName, message }: UploadProgressProps) => {
    return (
        <>
            <div className="modal-padding">
                <StatusMsg icon="repeat-empty" message={message} />
                <Separator />
                <DeterminateProgressBar resourceId={resourceId} resourceName={resourceName} />
            </div>
        </>
    );
});

export default UploadProgress;
