import { isEqual } from "lodash";
import { useEffect, useRef, useMemo } from "react";

export function useDeepEffect(effectFunc, deps) {
    const isFirst = useRef(true);
    const prevDeps = useRef(deps);

    useEffect(() => {
        const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

        if (isFirst.current || !isSame) {
            effectFunc();
        }

        isFirst.current = false;
        prevDeps.current = deps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

const compareInputs = (inputKeys, oldInputs, newInputs) => {
    inputKeys.forEach((key) => {
        const oldInput = oldInputs[key];
        const newInput = newInputs[key];
        if (!isEqual(oldInput, newInput)) {
            console.log("change detected", key, "old:", JSON.stringify(oldInput), "new:", JSON.stringify(newInput));
        }
    });
};
export const useDependenciesDebugger = (inputs) => {
    const oldInputsRef = useRef(inputs);
    const inputValuesArray = Object.values(inputs);
    const inputKeysArray = Object.keys(inputs);
    useMemo(() => {
        const oldInputs = oldInputsRef.current;

        compareInputs(inputKeysArray, oldInputs, inputs);

        oldInputsRef.current = inputs;
    }, inputValuesArray); // eslint-disable-line react-hooks/exhaustive-deps
};
