import { isEmpty, isNil, kebabCase } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { useIsInProgress } from "store/processes/useProcessStatus";
import { selectProjection } from "store/projections/actions";

import { useModules } from "store/resources/actions/calculationWorkflow/modulesActions";

import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import { IconSingleModelReport } from "components/ui/Icons/IconReport";
import Checkbox from "components/ui/Input/Checkbox";
import { IndeterminateProgressBar } from "components/ui/ProgressBar";
import Tag from "components/ui/Tag";
import Tooltip from "components/ui/Tooltip";

import { actionAllowed, modelReadOnlyEnabled } from "pages/utils";

import { useModelCalculationPreconditions } from "utils/useCalculationPreconditions";
import { useModuleList, useRunList } from "utils/useModuleList";
import { useUserRights } from "utils/useUserRights";
import { projections } from "utils/constants";
import { toLocaleDateTime } from "utils/dateTime";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { ModelProps } from "./types";

const Model = memo((props: ModelProps) => {
    const {
        viewIndex,
        idInputLog,
        idModel,
        idTerritory,
        idFuel,
        name,
        inputState,
        sectors,
        required,
        modelState,
        lastUpdateDate,
        lastCalculatedOn,

        territories,
        fuels,
        calculationResults,
        processesInProgress,

        project,
        selectedModels,
        selectToCombine,
        selectToRun,
        selectToCompare,
        combining,
        loading,

        setSelectedModels,
    } = props;

    const { idClient, idProject } = project;

    const dispatch = useDispatch();

    // Resources

    const isInProgress = useIsInProgress({ idInputLog });

    const [modules, isLoadingModules] = useModules({ idModel });

    const moduleList = useModuleList({ modules });
    const runList = useRunList({ moduleList });

    const { modelCalculationPreconditionsErrors } = useModelCalculationPreconditions({ idProject, idInputLog });

    const userRights = useUserRights();

    // Variables and useMemos

    const isLoading = loading?.includes(`${idInputLog}`);

    let compareCheckboxLabel = "";

    if (selectToCompare) {
        if (selectedModels[idInputLog]?.primary === true) {
            compareCheckboxLabel = "Model 1";
        } else if (selectedModels[idInputLog]?.primary === false) {
            compareCheckboxLabel = "Model 2";
        }
    }

    const territory = territories?.find((territory) => territory.idTerritory === idTerritory);
    const fuel = fuels?.find((fuel) => fuel.idFuel === idFuel);

    const combineDisabled =
        combining ||
        isInProgress ||
        (selectToRun && !isNil(idModel) && isEmpty(runList)) ||
        (selectToRun && !isNil(idModel) && isLoadingModules) ||
        calculationResults?.[idInputLog] === undefined ||
        modelReadOnlyEnabled(modelState, required) ||
        !actionAllowed(userRights, USER_ACTIONS.MODELS_COMBINE, required);

    const runDisabled =
        isInProgress ||
        (selectToRun && !isNil(idModel) && isEmpty(runList)) ||
        (selectToRun && !isNil(idModel) && isLoadingModules) ||
        modelCalculationPreconditionsErrors ||
        modelReadOnlyEnabled(modelState, required);

    const compareDisabled =
        isInProgress ||
        modelCalculationPreconditionsErrors ||
        calculationResults?.[idInputLog] === undefined ||
        modelReadOnlyEnabled(modelState, required);

    const lastCalculatedOnDateTime = useMemo(() => {
        let lastCalculatedOnDateTime = "";

        if (lastCalculatedOn !== null) {
            lastCalculatedOnDateTime = toLocaleDateTime(lastCalculatedOn);
        } else if (lastUpdateDate !== null) {
            lastCalculatedOnDateTime = toLocaleDateTime(lastUpdateDate);
        }

        return lastCalculatedOnDateTime;
    }, [lastCalculatedOn, lastUpdateDate]);

    const onModelClick = useCallback(() => {
        if (!isNil(idModel)) {
            dispatch(
                selectProjection({
                    viewIndex,
                    idProjectionView: projections.COMPONENT_MODEL_DASHBOARD,
                    idProject,
                    idProjection: idModel,
                    key: "idModel",
                })
            );
        } else {
            dispatch(
                selectProjection({
                    viewIndex,
                    idProjectionView: projections.MODEL_DASHBOARD,
                    idProject,
                    idProjection: idInputLog,
                    key: "idInputLog",
                })
            );
        }
    }, [viewIndex, idProject, idModel, idInputLog, dispatch]);

    const onSelectModeModelClick = useCallback(() => {
        let newSelectedModels = { ...selectedModels };

        // Select mode for combination or running
        if (selectToCombine || selectToRun) {
            // Unselect Model
            if (idInputLog in newSelectedModels) {
                delete newSelectedModels[idInputLog];
            }
            // Select Model
            else {
                // Select Legacy Model
                if (isNil(idModel)) {
                    newSelectedModels = {
                        ...newSelectedModels,
                        [idInputLog]: {
                            resultIsPotential: calculationResults?.[idInputLog].isPotential,
                            isComponentModel: false,
                        },
                    };
                }
                // Select Component Model
                else {
                    newSelectedModels = {
                        ...newSelectedModels,
                        [idInputLog]: {
                            isComponentModel: true,
                            componentModelParams: {
                                idModel,
                                runList,
                            },
                        },
                    };
                }
            }
        }
        // Select mode for comparing
        // Note: Only two Models at a time can be selected
        else if (selectToCompare) {
            // Unselect Model
            if (idInputLog in newSelectedModels) {
                const keys = Object.keys(newSelectedModels);

                // If two Models are selected and clicks on "Model 1",
                // which is the primary Model, that means that this
                // Model will be unselected. Set "Model 2" as a primary
                // Model (Model 1)
                if (keys.length === 2 && newSelectedModels[idInputLog].primary === true) {
                    for (const key of keys) {
                        if (newSelectedModels[key].primary === false) {
                            newSelectedModels[key].primary = true;

                            break;
                        }
                    }
                }

                delete newSelectedModels[idInputLog];
            }
            // Select Model
            else {
                const keys = Object.keys(newSelectedModels);

                switch (keys.length) {
                    // If one Model is selected, the next selected Model
                    // will not be a primary Model (Model 1), it will be
                    // "Model 2"
                    case 1:
                        newSelectedModels = {
                            ...newSelectedModels,
                            [idInputLog]: {
                                primary: false,
                            },
                        };

                        break;

                    // If two Models are selected, the next selected Model
                    // will replace "Model 2"
                    case 2:
                        for (const key of keys) {
                            if (newSelectedModels[key].primary === false) {
                                delete newSelectedModels[key];

                                newSelectedModels = {
                                    ...newSelectedModels,
                                    [idInputLog]: {
                                        primary: false,
                                    },
                                };

                                break;
                            }
                        }

                        break;

                    // If no Models are selected, the next selected Model
                    // will be a primary Model (Model 1)
                    default:
                        newSelectedModels = {
                            [idInputLog]: {
                                primary: true,
                            },
                        };

                        break;
                }
            }
        }

        setSelectedModels(newSelectedModels);
    }, [
        idModel,
        idInputLog,
        selectedModels,
        selectToCombine,
        selectToRun,
        selectToCompare,
        calculationResults,
        runList,
        setSelectedModels,
    ]);

    // Main render

    return (
        <div
            className={cn("list-item-row", { clickable: !selectToCombine && !selectToRun && !selectToCompare })}
            onClick={
                // If no action selected, clicking on the Model
                // will navigate user to Model Dashboard
                !selectToCombine && !selectToRun && !selectToCompare
                    ? onModelClick
                    : // If combination is not in progress and
                    // calculations aren't running for this Model, and
                    // this Model can be selected for any action,
                    // then mark this Model as selected
                    !combining && !isInProgress && !combineDisabled && !runDisabled && !compareDisabled
                    ? onSelectModeModelClick
                    : undefined
            }
        >
            {selectToCombine && (
                <div className="item-value column-all">
                    <Checkbox id={`${idInputLog}`} disabled={combineDisabled} checked={idInputLog in selectedModels} />
                </div>
            )}
            {selectToRun && (
                <div className="item-value column-all">
                    <Checkbox id={`${idInputLog}`} disabled={runDisabled} checked={idInputLog in selectedModels} />
                </div>
            )}
            {selectToCompare && (
                <div className="item-value column-checkbox">
                    <Checkbox
                        id={`${idInputLog}`}
                        label={compareCheckboxLabel}
                        disabled={compareDisabled}
                        checked={idInputLog in selectedModels}
                    />
                </div>
            )}
            <div className="item-value column-name">{name}</div>
            <div className="item-value column-model-status">
                <Tag className={kebabCase(modelState)} size="sm">
                    <>{modelState.toLowerCase()}</>
                </Tag>
            </div>
            <div className="item-value column-territory">{territory?.alias}</div>
            <div className="item-value column-fuel">{fuel?.name}</div>
            <div className="item-value column-sector">{sectors?.[0].name}</div>
            <div className="item-value column-last-calculated-on">{lastCalculatedOnDateTime}</div>
            <div className="item-value column-required">{required && <Icon disabled icon="ui-checked__check_circle_b_f" />}</div>
            <div className="item-value column-calculation-status">
                {/* Status - waiting for calculation to start */}
                {!isInProgress && isLoading && (
                    <div className="flex-row">
                        <IconLoading />
                    </div>
                )}

                {!isInProgress && calculationResults?.[idInputLog] && !isLoading && (
                    <>
                        {/* Status - calculation successfully completed */}
                        {inputState === "DONE" && <Icon variant="success" icon="ui-checked__check_circle_b_f" />}
                        {/* Status - calculation ended with an error */}
                        {inputState === "ERROR" && (
                            <Tooltip customIcon={<Icon variant="error" icon="text-report_b_s" />} iconError placement="bottom-end">
                                Missing obligatory table or contains import errors.
                            </Tooltip>
                        )}
                    </>
                )}

                {/* Status - data import */}
                {processesInProgress?.modelDataImport?.[idInputLog] && (
                    <Icon className="rotate" icon="arrows-sync__autorenew_b_a" iconTitle="Model import is running" size="sm" />
                )}

                {/* Status - calculation status */}
                {processesInProgress?.runCalculations?.[idInputLog] && (
                    <IndeterminateProgressBar
                        startTime={
                            processesInProgress.runCalculations[idInputLog].started === undefined
                                ? 0
                                : Date.parse(processesInProgress.runCalculations[idInputLog].started + "Z")
                        }
                    />
                )}
            </div>
            <div className="item-value column-results">
                {!isInProgress && calculationResults?.[idInputLog] && (
                    <>
                        {isNil(idModel) ? (
                            // Legacy Model
                            <IconSingleModelReport
                                idClient={idClient}
                                idProject={idProject}
                                idInputLog={idInputLog}
                                title={name}
                                subTitle={`${calculationResults?.[idInputLog].isPotential ? "Potential" : "Baseline"} result`}
                                calculationResult={calculationResults[idInputLog]}
                            />
                        ) : (
                            // Component Model
                            <IconSingleModelReport
                                idClient={idClient}
                                idProject={idProject}
                                idModel={idModel}
                                idInputLog={idInputLog}
                                title={name}
                                subTitle={calculationResults?.[idInputLog].parameters?.CASE_STUDIES?.[0].NAME}
                                calculationResult={calculationResults?.[idInputLog]}
                                withApprovement={
                                    calculationResults?.[idInputLog].isInClientReview && hasAnyOfPermissions([USER_ACTIONS.MODULES_APPROVE])
                                }
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
});

export default Model;
