import { CKEditor as Editor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// @ts-ignore
import Event from "@ckeditor/ckeditor5-utils/src/eventinfo";

import { ckEditorConfig } from "utils/constants";

import "./CKEditor.scss";

/**
 * Wrapper component for CKEditor 5 which includes
 * ClassicEditor, custom configuration and custom styling.
 *
 * More information: https://ckeditor.com/ckeditor-5/
 */
const CKEditor = (props: CKEditorProps) => {
    return <Editor editor={ClassicEditor} data={props.data} config={ckEditorConfig} onChange={props.onChange} />;
};

interface CKEditorProps {
    data?: string;

    onChange?: (event: Event, editor: ClassicEditor) => void;
}

export default CKEditor;
