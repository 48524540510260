import { kebabCase } from "lodash";
import { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "components/ui/ButtonNew";
import ActionLabel from "components/ui/Label/ActionLabel";
import Tag from "components/ui/Tag";

import { openModalDialogDeleteProject } from "layouts/Modal/HomeDashboardModal/utils";

import { projectReadOnlyEnabled } from "pages/utils";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";
import { openWindowProject } from "utils/window";

import { ProjectItemProps } from "pages/HomeDashboard/HomeDashboardDefault/common/ProjectList/types";
import { updateBookmarkForProject } from "store/resources/actions/bookmarks/bookmarkedProjectsActions";

const ProjectDefault = memo(
    ({
        idClient,
        idProject,
        idManager,

        managerFullName,
        members,
        projectType,
        projectName,
        projectDescription,
        projectState,
        territories,
        fuels,
        targetDate,
        territoryStructureName,
        uploadFolder,
        uploadUrl,
        started,
        finished,
        bookmarked,

        clientName,
    }: ProjectItemProps) => {
        const dispatch = useDispatch();

        // @ts-ignore - remove when state typed
        const userNumber = useSelector((state) => state.vdsmUser.userNumber);

        const onBookmarkClick = () => {
            dispatch(
                updateBookmarkForProject({
                    idClient,
                    idProject,
                    userNumber,
                    bookmarked,
                    clientName,
                })
            );
        };

        const onProjectNameClick = () => {
            const project = {
                idProject,
                idClient,
                idManager,
                managerFullName,
                members,
                projectType,
                projectName,
                projectDescription,
                projectState,
                territories,
                fuels,
                targetDate,
                territoryStructureName,
                uploadFolder,
                uploadUrl,
                started,
                finished,
                bookmarked,
            };

            dispatch(openWindowProject({ project, clientName }));
        };

        const onProjectDeleteClick = () => {
            dispatch(
                openModalDialogDeleteProject({
                    idClient,
                    idProject,
                    projectName,
                    userNumber,
                })
            );
        };

        return (
            <div key={`project-${idClient}-${idProject}`} className="list-item-row">
                <div className="item-value column-bookmarked">
                    <Button
                        variant="tertiary"
                        title={bookmarked ? "Remove from bookmarks" : "Add to bookmarks"}
                        padding="xs"
                        icon={bookmarked ? "files-bookmark_a_f" : "files-bookmark_b_s"}
                        iconSize="sm"
                        onClick={onBookmarkClick}
                    />
                </div>
                <div data-testid="project-name" className="item-value column-project-name">
                    <ActionLabel onClick={onProjectNameClick}>{projectName}</ActionLabel>
                </div>
                <div className="item-value column-project-description">{projectDescription}</div>
                <div className="item-value column-started">{new Date(started).toLocaleDateString()}</div>
                <div className="item-value column-target-date">{targetDate ? new Date(targetDate).toLocaleDateString() : null}</div>
                <div className="item-value column-project-state">
                    <Tag className={kebabCase(projectState)} size="sm">
                        <>{projectState.toLowerCase()}</>
                    </Tag>
                </div>
                <div className="item-value column-actions">
                    {!projectReadOnlyEnabled(projectState) && hasAnyOfPermissions([USER_ACTIONS.PROJECT_DELETE]) && (
                        <Button
                            dataTestId={`delete-project-${idProject}`}
                            variant="tertiary"
                            title="Delete project"
                            padding="xs"
                            icon="ui-trash__garbage__delete__remove__bin_b_s"
                            iconSize="sm"
                            onClick={onProjectDeleteClick}
                        />
                    )}
                </div>
            </div>
        );
    }
);

export default ProjectDefault;
