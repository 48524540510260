import { get } from "lodash";
import { memo } from "react";
import { useSelector } from "react-redux";

import { moduleVariablesResourceName } from "store/configureResources";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import ActionLabel from "components/ui/Label/ActionLabel";
import StatusMsg from "components/ui/StatusMsg";

import { getVariableValue } from "layouts/common/Variables/utils";

import { actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { USER_ACTIONS } from "utils/user/defines";

import { ModuleVariableTransformed } from "store/resources/actions/calculationWorkflow/types";
import { CommonVariablesProps } from "layouts/common/Variables";

const ModuleVariables = memo(
    ({
        values,
        moduleVariables,
        differsFromProject,
        editing,
        showSave,
        warningMessage,
        infoMessage,
        modelRequired,
        fieldDiffersFromProject,
        onChange,
        onSave,
    }: ModuleVariablesProps) => {
        const resource: any = useSelector((state) => get(state, `resources.${moduleVariablesResourceName}`));

        const userRights = useUserRights();

        return (
            <>
                {!editing && actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && differsFromProject && (
                    <div className="px-2 py-1">
                        {resource.isReading || resource.isUpdating ? (
                            <IconLoading />
                        ) : (
                            <ActionLabel onClick={() => onSave("Revert")}>Use global project settings</ActionLabel>
                        )}
                    </div>
                )}
                {warningMessage && <StatusMsg className="px-2 py-1 variables__warning" icon="info-empty" message={warningMessage} />}
                {infoMessage && <StatusMsg className="px-2 py-1 variables__info" icon="info-empty" message={infoMessage} />}
                <div className="flex-column">
                    {moduleVariables?.map((item, i) => (
                        <div key={`module-variables-${i}`} className="flex-row align-end flex-wrap gap-2 py-2">
                            {item.map((it, j) => (
                                <div key={`module-variable-${it.name}-${i}-${j}`} className="variable-field">
                                    {it.isText ? (
                                        <Input
                                            label={it.uiLabel}
                                            ghost={!editing}
                                            percent={!editing && it.inputType === "%"}
                                            value={getVariableValue(values, it)}
                                            warning={fieldDiffersFromProject(it.name)}
                                            onChange={(event) => onChange(event.target.value, it.name)}
                                        />
                                    ) : (
                                        <Dropdown
                                            label={it.uiLabel}
                                            ghost={!editing}
                                            value={getVariableValue(values, it)}
                                            items={it.values}
                                            multiple={it.inputType === "LIST"}
                                            singleLine={it.inputType === "LIST"}
                                            warning={fieldDiffersFromProject(it.name)}
                                            onChange={(value) => onChange(value, it.name)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                {editing && (
                    <IdsButtonGroup customClasses="variables__actions">
                        {showSave && (
                            <Button variant="secondary" padding="md" onClick={() => onSave("Save")}>
                                Save changes
                            </Button>
                        )}
                    </IdsButtonGroup>
                )}
            </>
        );
    }
);

export interface ModuleVariablesProps extends CommonVariablesProps {
    /**
     * An array of Model Variables.
     */
    moduleVariables: ModuleVariableTransformed[][];

    /**
     * Module Variables differ from Project Variables.
     */
    differsFromProject?: boolean;

    /**
     * Display "Save" button.
     */
    showSave: boolean;

    /**
     * Model required for Project.
     */
    modelRequired: boolean | null;

    onChange: (value: string | number | readonly string[] | undefined, name: string) => void;
}

export default ModuleVariables;
