import { connect } from "react-redux";

import Modal from "./Modal";
import SimpleDialog from "./SimpleDialog";
import ConfirmModal from "./ConfirmModal";
import { WaitingModal } from "./WaitingModal";

export const MODAL_COMPONENTS = {
    SIMPLE_DIALOG: SimpleDialog,
    MODAL: Modal,
    CONFIRM: ConfirmModal,
    WAITING_MODAL: WaitingModal,
    /* other modals */
};

export const ModalRoot = ({ modalType, modalProps }) => {
    if (!modalType) {
        return null;
    }

    const SpecificModal = MODAL_COMPONENTS[modalType];

    return <SpecificModal {...modalProps} />;
};

export default connect((state) => state.modal)(ModalRoot);
