import { memo } from "react";

import ModelSetup from "./ModelSetup";
import ComponentModelSetup from "./ModelSetupNew";
import ProjectDashboard from "./ProjectDashboard";
import ModelDashboard from "./ModelDashboard";
import ComponentModelDashboard from "./ModelDashboardNew";
import StandaloneReportDashboard from "./ReportDashboard";
import TrashBinDashboard from "./TrashBinDashboard";

import { projections } from "utils/constants";

import { ProjectionsContentProps } from "./types";

const ProjectionsContent = memo(({ viewIndex, project, selectedProjection, clientName }: ProjectionsContentProps) => {
    const { idClient, idProject, projectName } = project;
    const { idInputLog, idModel, idReport } = selectedProjection;

    switch (selectedProjection.idProjectionView) {
        case projections.MODEL_SETUP:
            return <ModelSetup viewIndex={viewIndex} idClient={idClient} idProject={idProject} />;

        case projections.COMPONENT_MODEL_SETUP:
            return <ComponentModelSetup viewIndex={viewIndex} idClient={idClient} idProject={idProject} />;

        case projections.PROJECT_DASHBOARD:
            return <ProjectDashboard viewIndex={viewIndex} project={project} clientName={clientName} />;

        case projections.MODEL_DASHBOARD:
            return (
                <ModelDashboard
                    viewIndex={viewIndex}
                    idClient={idClient}
                    idProject={idProject}
                    idInputLog={idInputLog}
                    projectName={projectName}
                />
            );

        case projections.COMPONENT_MODEL_DASHBOARD:
            return (
                <ComponentModelDashboard
                    viewIndex={viewIndex}
                    idClient={idClient}
                    idProject={idProject}
                    idModel={idModel}
                    clientName={clientName}
                    projectName={projectName}
                />
            );

        case projections.STANDALONE_REPORT_DASHBOARD:
            return (
                <StandaloneReportDashboard
                    viewIndex={viewIndex}
                    idClient={idClient}
                    idProject={idProject}
                    idReport={idReport}
                    projectName={projectName}
                />
            );

        case projections.TRASH_BIN_DASHBOARD:
            return <TrashBinDashboard viewIndex={viewIndex} project={project} />;

        default:
            return null;
    }
});

export default ProjectionsContent;
