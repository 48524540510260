import Separator from "components/ui/Separator";

import { openModalDialog, openModalDialogCustomActions } from "layouts/Modal/utils";

import ImportModelData from "./ImportModelData";
import DeleteCombination from "./DeleteCombination";
import EditModel from "./EditModel";
import ClientReview from "./ClientReview";
import ClientApprovement from "./ClientApprovement";
import DuplicateModel, { DuplicateModelProps } from "./DuplicateModel";
import ModelInputsValidationErrors from "./ModelInputsValidationErrors";
import DownloadData from "./DownloadData";

import { actionStatus } from "utils/constants";

import {
    ImportDataModalProps,
    DeleteCombinationModalProps,
    EditModelModalProps,
    ClientApprovementModalProps,
    ModelModalProps,
    SetFinalWarningParams,
    DeleteNoteParams,
    DeleteNoteCommentParams,
    DownloadDataProps,
} from "./types";
import { ValidationErrorProps } from "pages/ManageProject/ProjectionsContent/common/ImportErrorPanel/ValidationError/types";

export const openModalDialogImportData =
    ({ viewIndex, idClient, idProject, idModel, idInputLog, idTemplate, idTerritory, idFuel, modelName }: ImportDataModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Import Data",
                size: "medium",
                component: (
                    <ImportModelData
                        viewIndex={viewIndex}
                        idClient={idClient}
                        idProject={idProject}
                        idModel={idModel}
                        idInputLog={idInputLog}
                        idTemplate={idTemplate}
                        idTerritory={idTerritory}
                        idFuel={idFuel}
                        modelName={modelName}
                    />
                ),
            })
        );
    };

export const openModalDialogDeleteCombination =
    ({ idProject, idCombination, combinationName }: DeleteCombinationModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Delete Combination",
                component: <DeleteCombination idProject={idProject} idCombination={idCombination} combinationName={combinationName} />,
            })
        );
    };

export const openModalDialogEditModel =
    ({
        idClient,
        idProject,
        modelName,
        modelDescription,
        modelAnalyst,
        modelReviewer,
        modelTerritory,
        modelFuel,
        onEdit,
    }: EditModelModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Edit Model Information",
                component: (
                    <EditModel
                        idClient={idClient}
                        idProject={idProject}
                        modelName={modelName}
                        modelDescription={modelDescription}
                        modelAnalyst={modelAnalyst}
                        modelReviewer={modelReviewer}
                        modelTerritory={modelTerritory}
                        modelFuel={modelFuel}
                        onEdit={onEdit}
                    />
                ),
            })
        );
    };

export const openModalDialogClientReview =
    ({ modelName, onChange }: ClientApprovementModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Publish Model for Client Review",
                component: <ClientReview modelName={modelName} onChange={onChange} />,
            })
        );
    };

export const openModalDialogClientApprovement =
    ({ modelName, modelStatus, onChange }: ClientApprovementModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: `Set Model to ${modelStatus}`,
                component: <ClientApprovement modelName={modelName} modelStatus={modelStatus} onChange={onChange} />,
            })
        );
    };

export const openModalDialogSetFinalSignedOffError =
    ({ modelName }: ModelModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Model Inputs needs approval",
                modalIcon: "circle-exclamation-filled",
                error: true,
                text: (
                    <div>
                        To be able to set the model <strong>'{modelName}'</strong> to 'Final', please ensure all required model inputs are
                        marked with the 'Manager Signed Off' status.
                    </div>
                ),
                actions: [
                    {
                        children: "Close",
                    },
                ],
            })
        );
    };

export const openModalDialogSetFinalNoModulesError =
    ({ modelName }: ModelModalProps) =>
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "No Modules found",
                modalIcon: "circle-exclamation-filled",
                error: true,
                text: (
                    <div>
                        To be able to set the model <strong>'{modelName}'</strong> to 'Final', please add modules and ensure all modules are
                        marked with the 'Approved' status.
                    </div>
                ),
                actions: [
                    {
                        children: "Close",
                    },
                ],
            })
        );
    };

export const openModalDialogSetFinalApprovedError =
    ({ modelName }: ModelModalProps) =>
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Modules needs approval",
                modalIcon: "circle-exclamation-filled",
                error: true,
                text: (
                    <div>
                        To be able to set the model <strong>'{modelName}'</strong> to 'Final', please ensure all modules are marked with the
                        'Approved' status.
                    </div>
                ),
                actions: [
                    {
                        children: "Close",
                    },
                ],
            })
        );
    };

export const openModalDialogSetFinalWarning =
    ({ modelName, onChange }: SetFinalWarningParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Set model to Final",
                text: (
                    <>
                        <div>
                            By setting the model <strong>'{modelName}'</strong> to 'Final', you will lock the model to be read only. Please
                            ensure all the deliverables are aligned before you proceed.
                        </div>
                        <Separator />
                        <div>Are you sure you want to set the model to 'Final'?</div>
                    </>
                ),
                actions: [
                    {
                        primary: true,
                        children: "Yes, Change Status",
                        onClick: onChange,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };

export const openModalDialogRollbackFinalWarning =
    ({ modelName, onChange }: SetFinalWarningParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Revert model to In Progress",
                text: (
                    <>
                        <div>
                            The model <strong>'{modelName}'</strong> is labeled as 'Final' now. Changing it to 'In Progress' will allow the
                            model to be edited, but you must ensure all deliverables are aligned if the results change in any way.
                        </div>
                        <Separator />
                        <div>Proceed with reverting the project to 'In Progress'?</div>
                    </>
                ),
                actions: [
                    {
                        primary: true,
                        children: "Yes, Change Status",
                        onClick: onChange,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };

export const openModalDialogDeleteNote =
    ({ onDelete }: DeleteNoteParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Delete note",
                text: "Are you sure you want to delete this note? This action cannot be undone.",
                actions: [
                    {
                        primary: true,
                        danger: true,
                        icon: "ui-delete_forever_b_s",
                        children: "Delete",
                        onClick: onDelete,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };

export const openModalDialogDeleteNoteComment =
    ({ noteTitle, onDelete }: DeleteNoteCommentParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: `Delete "${noteTitle}" comment`,
                text: "Are you sure you want to delete this comment?",
                actions: [
                    {
                        primary: true,
                        danger: true,
                        icon: "ui-delete_forever_b_s",
                        children: "Delete",
                        onClick: onDelete,
                    },
                    {
                        children: "Cancel",
                    },
                ],
            })
        );
    };

export const openModalDialogDuplicateModel =
    ({ idClient, idProject, idInputLog, idTerritory, idFuel, idModel, onSuccess }: DuplicateModelProps) =>
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Duplicate model",
                size: "large",
                component: (
                    <DuplicateModel
                        idClient={idClient}
                        idProject={idProject}
                        idInputLog={idInputLog}
                        idTerritory={idTerritory}
                        idFuel={idFuel}
                        idModel={idModel}
                        onSuccess={onSuccess}
                    />
                ),
            })
        );
    };

export const openModalDialogModelInputsValidationErrorsPopup =
    ({ idInputLog, idInputVersion, validations }: ValidationErrorProps) =>
    // @ts-ignore
    (dispatch) => {
        const msgError = validations.msgClassification === actionStatus.ERROR;
        const msgWarning = validations.msgClassification === actionStatus.WARNING;

        dispatch(
            openModalDialog({
                title: `${msgError ? "Error" : "Warning"} Details`,
                size: "fill",
                highlightCustomList: true,
                component: (
                    <ModelInputsValidationErrors
                        idInputLog={idInputLog}
                        idInputVersion={idInputVersion}
                        validations={validations}
                        msgError={msgError}
                        msgWarning={msgWarning}
                    />
                ),
            })
        );
    };

export const openModalDialogDownloadData =
    ({ idModel }: DownloadDataProps) =>
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Download Data",
                size: "medium",
                component: <DownloadData idModel={idModel} />,
            })
        );
    };
