import { isNil, isEmpty } from "lodash";
import { useMemo } from "react";

import { useTeamMembers } from "store/resources/actions/user/userActions";

import { UseHaveRightsParams } from "./types";

/**
 * Hook that checks if user have manager, analyst or
 * reviewer rights for the projection (Project,
 * Model, Module).
 */
export const useHaveRights = ({ entityId, userName }: UseHaveRightsParams) => {
    const debugStatuses = sessionStorage.getItem("debug-statuses-enabled") === "true";

    const [internalUsers, isLoadingInternalUsers] = useTeamMembers();

    const haveRights = useMemo(() => {
        let haveRights = false;

        if (!isNil(entityId) && userName !== undefined && !isEmpty(internalUsers) && !isLoadingInternalUsers) {
            const user = internalUsers.find((member) => member.userName === userName);

            haveRights = user?.idUser === entityId;
        }

        return haveRights;
    }, [entityId, userName, internalUsers, isLoadingInternalUsers]);

    return debugStatuses ? true : haveRights;
};
