import {
    RUN_CALCULATION_START,
    RUN_CALCULATION_STARTED,
    RUN_CALCULATION_TERMINATE,
    RUN_CALCULATION_FAILED,
    RUN_CALCULATION_COMPLETE,
    RUN_CALCULATION_ACK,
} from "store/actionTypes";

const initialState = {};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RUN_CALCULATION_START:
            {
                const { idProject, idInputLog } = action;

                state = {
                    ...state,
                    [`${idProject}-${idInputLog}`]: RUN_CALCULATION_START,
                };
            }

            break;

        case RUN_CALCULATION_STARTED:
            {
                const { idProject, idInputLog, id } = action;

                state = {
                    ...state,
                    [`${idProject}-${idInputLog}`]: id,
                };
            }

            break;

        case RUN_CALCULATION_TERMINATE:
            {
                const { idProject, idInputLog } = action;

                state = {
                    ...state,
                    [`${idProject}-${idInputLog}`]: RUN_CALCULATION_TERMINATE,
                };
            }

            break;

        case RUN_CALCULATION_FAILED:
            {
                const { idProject, idInputLog } = action;

                state = {
                    ...state,
                    [`${idProject}-${idInputLog}`]: RUN_CALCULATION_FAILED,
                };
            }

            break;

        case RUN_CALCULATION_COMPLETE:
            {
                const { idProject, idInputLog } = action.status;

                state = {
                    ...state,
                    [`${idProject}-${idInputLog}`]: action.status,
                };
            }

            break;

        case RUN_CALCULATION_ACK:
            {
                const { idProject, idInputLog } = action;
                const key = `${idProject}-${idInputLog}`;

                state = {
                    ...state,
                    [key]: {
                        ...state?.[key],
                        hide: true,
                    },
                };
            }

            break;

        default:
            break;
    }

    return state;
};
