import { memo, useCallback, useState } from "react";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import Separator from "components/ui/Separator";
import TextArea from "components/ui/TextArea";

import { ClientReviewModalProps } from "layouts/Modal/ModelDashboardModal/types";

const ClientReview = memo(({ modelName, onChange, onCancel }: ClientReviewModalProps) => {
    const [note, setNote] = useState("");

    const onNoteChange = useCallback((event) => {
        setNote(event.target.value);
    }, []);

    const onSetClick = useCallback(() => {
        onChange(note);

        onCancel?.();
    }, [note, onChange, onCancel]);

    return (
        <>
            <div className="flex-column modal-padding">
                <div>
                    Setting the status of <strong>{modelName}</strong> to 'Client Review' will publish it and make it available for client
                    in <strong>VisionLoadMap</strong>.
                </div>
                <Separator />
                <div>Are you sure you want to set the model to Client Review?</div>
                <Separator line />
                <div>
                    <TextArea label="Note" placeholder="Write a note" value={note} optional onChange={onNoteChange} />
                </div>
            </div>
            <ButtonGroup className="justify-end modal-actions">
                <Button primary onClick={onSetClick}>
                    Yes, Publish Model
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </ButtonGroup>
        </>
    );
});

export default ClientReview;
