import { memo } from "react";

import Inputs from "./Inputs";
import TextAreas from "./TextAreas";
import Controls from "./Controls";
import Buttons from "./Buttons";
import Dropdowns from "./Dropdowns";
import Typography from "./Typography";
import Icons from "./Icons";
import StatusesAndMarks from "./StatusesAndMarks";
import Additional from "./Additional";

import "./style.scss";

const UILibrary = memo(() => {
    return (
        <div className="flex-column with-scroll ui-library">
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Inputs</div>
                <Inputs />
            </div>
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Textareas</div>
                <TextAreas />
            </div>
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Controls</div>
                <Controls />
            </div>
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Buttons</div>
                <Buttons />
            </div>
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Dropdowns</div>
                <Dropdowns />
            </div>
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Typography</div>
                <Typography />
            </div>
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Icons</div>
                <Icons />
            </div>
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Statuses and Marks</div>
                <StatusesAndMarks />
            </div>
            <div className="ui-library__element-group">
                <div className="ui-library__element-group__title">Additional</div>
                <Additional />
            </div>
        </div>
    );
});

export default UILibrary;
