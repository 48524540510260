import { memo, useCallback } from "react";

import { modalOpen, modalClose } from "store/modal/actions";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";

export const openModalDialogAvoidedCostsDeleteRows =
    ({ onDelete, onCancel }) =>
    (dispatch) => {
        const title = "Delete Rows";

        dispatch(
            modalOpen({
                modalType: "WAITING_MODAL",
                modalProps: {
                    title,
                },
            })
        );

        const renderModal = (data) => {
            const ModalContent = memo((data) => {
                const onClickDelete = useCallback(() => {
                    dispatch(modalClose());
                    onDelete && onDelete();
                }, []);

                const onClickCancel = useCallback(() => {
                    dispatch(modalClose());
                    onCancel && onCancel();
                }, []);

                return (
                    <>
                        <div className="flex-column modal-padding">
                            Selected rows will be deleted from the Avoided Costs table when you press "Save" button.
                        </div>
                        <ButtonGroup className="justify-end modal-actions">
                            <Button danger icon="delete-trash-empty" onClick={onClickDelete}>
                                Delete Permanently
                            </Button>
                            <Button onClick={onClickCancel}>Cancel</Button>
                        </ButtonGroup>
                    </>
                );
            });

            dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title,
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        children: <ModalContent data={data} />,
                        noFooter: true,
                    },
                })
            );
        };

        renderModal();
    };
