import { isEmpty } from "lodash";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import Button from "components/ui/ButtonNew";
import Icon from "components/ui/IconNew";

import { openWindowProjectSources, closeWindowProjectStakeholder } from "utils/window";

import { ProjectStakeholderDashboardProps } from "pages/ProjectStakeholderDashboard/types";

import "./style.scss";

const DashboardHeader = memo(({ project, clientName, displayFiles, onDisplayFiles, onReturn }: ProjectStakeholderDashboardProps) => {
    const { idClient, idProject, projectName, projectDescription, managerFullName, members } = project;

    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(closeWindowProjectStakeholder({ idClient, idProject }));
    }, [idClient, idProject, dispatch]);

    const onProjectSourcesClick = useCallback(() => {
        dispatch(
            openWindowProjectSources({
                idClient,
                idProject,
                clientName,
                projectName,
            })
        );
    }, [idClient, idProject, clientName, projectName, dispatch]);

    return (
        <div className="flex-column project-stakeholder-dashboard-header">
            <div className="project-stakeholder-dashboard-header__upper-container">
                <div className="flex-row justify-space-between project-stakeholder-dashboard-header__top-container">
                    <div className="flex-row align-center">
                        <div className="flex-column align-center justify-center project-stakeholder-dashboard-header__project-icon-wrapper">
                            <Icon className="project-stakeholder-dashboard-header__project-icon" icon="files-assignment_b_s" size="md" />
                        </div>
                        <div className="margin-left-small project-stakeholder-dashboard-header__project-name">
                            <div className="project-stakeholder-dashboard-header__project">Project</div>
                            <div className="flex-row align-center">
                                <div data-testid="header-project-name" className="project-stakeholder-dashboard-header__title">
                                    {projectName}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Icon icon="ui-close-delete-cross-clear-b-a" onClick={onClose} />
                    </div>
                </div>
                <div className="project-stakeholder-dashboard-header__middle-container">
                    <span>{projectDescription}</span>
                </div>
                <div className="flex-row align-center project-stakeholder-dashboard-header__bottom-container">
                    <div className="flex-column flex-one project-stakeholder-dashboard-header__project-details-container">
                        <div className="flex-row project-stakeholder-dashboard-header__project-details">
                            <div>
                                <span className="project-stakeholder-dashboard-header__label">ID: </span>
                                <span data-testid="id-project">{idProject}</span>
                            </div>
                            <div className="margin-left">
                                <span className="project-stakeholder-dashboard-header__label">Client: </span>
                                <span>{clientName}</span>
                            </div>
                        </div>
                        <div className="flex-row">
                            <div>
                                <span className="project-stakeholder-dashboard-header__label">Manager responsible: </span>
                                <span>{managerFullName || "None"}</span>
                            </div>
                            <div className="margin-left">
                                <span className="project-stakeholder-dashboard-header__label">Project team members: </span>
                                <span>{members && !isEmpty(members) ? members.map((member) => member.fullName).join(", ") : "None"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="project-stakeholder-dashboard-header__project-actions-container">
                        <div className="flex-row">
                            <div>
                                <Button
                                    variant="tertiary"
                                    padding="sm"
                                    iconLeft="files-personal_assignment_b_s"
                                    iconSize="sm"
                                    onClick={onProjectSourcesClick}
                                >
                                    Project Sources
                                </Button>
                            </div>
                            <div className="margin-left-small">
                                {displayFiles ? (
                                    <Button variant="tertiary" padding="sm" onClick={onReturn}>
                                        Return to Project Dashboard
                                    </Button>
                                ) : (
                                    <Button
                                        variant="tertiary"
                                        padding="sm"
                                        iconLeft="files-folder_b_s"
                                        iconSize="sm"
                                        onClick={onDisplayFiles}
                                    >
                                        Project Files
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DashboardHeader;
