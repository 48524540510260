import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";

import { refresh } from "store/login/actions";
import { VDSM_USER_SAVE } from "store/actionTypes";

import FormWrap from "pages/common/FormWrap";

import ViewPlaceholder from "components/ui/ViewPlaceholder";
import IconLoading from "components/ui/Icons/IconLoading";

import { openWindowLogin } from "utils/window";

const SamlView = memo(() => {
    const dispatch = useDispatch();

    useEffect(() => {
        const params = queryString.parse(window.location.search);

        let accessToken = null;
        let refreshToken = null;

        if (params) {
            Object.keys(params).forEach((key) => {
                const formattedKey = key.toLowerCase();

                switch (formattedKey) {
                    case "accesstoken":
                        accessToken = params[key];
                        break;
                    case "refreshtoken":
                        refreshToken = params[key];
                        break;
                    default:
                        break;
                }
            });
        }

        if (accessToken && refreshToken) {
            dispatch({
                type: VDSM_USER_SAVE,
                vdsmUser: {
                    accessToken,
                    refreshToken,
                },
            });

            dispatch(refresh());
        } else {
            dispatch(openWindowLogin());
        }

        // Clear url
        window.history.pushState("", "", "/");
    }, [dispatch]);

    return (
        <FormWrap>
            <ViewPlaceholder>
                <IconLoading />
            </ViewPlaceholder>
        </FormWrap>
    );
});

export default SamlView;
