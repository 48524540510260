///
/// Project Final Report Exists actions
/// resourceName: projectFinalReportExistsActions
///
import { projectFinalReportExistsResourceName } from "store/configureResources";

import { updateResource } from "store/resources/actions/updateResource";

import { UpdateHasProjectFinalReportParams } from "./types";

export const updateHasProjectFinalReport =
    ({ idProject }: UpdateHasProjectFinalReportParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            updateResource({
                resourceName: projectFinalReportExistsResourceName,
                key: `${projectFinalReportExistsResourceName}-${idProject}`,
                path: { idProject },
            })
        );
    };
