import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { updateProjectStudyCase, useProjectStudyCase } from "store/resources/actions/studyCase/studyCaseProjectActions";

import IconLoading from "components/ui/Icons/IconLoading";

import StudyCase from "pages/ManageProject/ProjectionsContent/common/StudyCase";

import { IndexSignature } from "types/types";
import { ProjectDashboardPanelProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/types";

const ProjectStudyCase = memo(({ project, editing, onEditCancel }: ProjectDashboardPanelProps) => {
    const { idProject } = project;

    const dispatch = useDispatch();

    const [studyCasesRes = [], isLoading] = useProjectStudyCase({ idProject });

    const onSaveClick = useCallback(
        (values: IndexSignature<string>) => {
            dispatch(
                updateProjectStudyCase({
                    idProject,
                    values,
                })
            );
        },
        [idProject, dispatch]
    );

    return (
        <>
            {isLoading ? (
                <IconLoading />
            ) : (
                <StudyCase
                    studyCasesRaw={studyCasesRes}
                    editing={editing}
                    saveBtnText="Update Models"
                    onSave={onSaveClick}
                    onEditCancel={onEditCancel}
                />
            )}
        </>
    );
});

export default ProjectStudyCase;
