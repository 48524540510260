import { useEffect, useCallback, useRef } from "react";

import { LazyLoaderProps } from "./types";

/**
 * This component lets the UI know when it is on screen
 * and with onVisible function can do something when
 * component appears on screen.
 */
const LazyLoader = ({ className, children, onVisible }: LazyLoaderProps) => {
    const observerRef = useRef<IntersectionObserver>();
    const elementRef = useRef<HTMLDivElement>(null);

    const attachObserver = useCallback(() => {
        observerRef.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    onVisible?.();

                    if (observerRef.current && elementRef.current) {
                        observerRef.current.unobserve(elementRef.current);
                    }
                }
            },
            {
                threshold: 0,
            }
        );

        if (observerRef.current && elementRef.current) {
            observerRef.current.observe(elementRef.current);
        }
    }, [onVisible]);

    useEffect(() => {
        const element = elementRef.current;

        if ("IntersectionObserver" in window) {
            attachObserver();
        } else {
            onVisible?.();
        }

        return () => {
            if (observerRef.current && element !== null) {
                observerRef.current.unobserve(element);
            }
        };
    }, [onVisible, attachObserver]);

    return (
        <div ref={elementRef} className={className}>
            {children}
        </div>
    );
};

export default LazyLoader;
