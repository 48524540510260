import { get, isEmpty, isNil, countBy, transform } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MODEL_DATA_IMPORT_ACK } from "store/actionTypes";

import { projectInputsDuplicateResourceName } from "store/configureResources";

import { useMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";

import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import SuccessMsg from "components/ui/StatusMsg/SuccessMsg";
import ImportErrorMsg from "components/ui/StatusMsg/ImportErrorMsg";
import DuplicateErrorMsg from "components/ui/StatusMsg/DuplicateErrorMsg";
import InfoPanel from "components/ui/InfoPanel";

import ValidationError from "./ValidationError";

import { useValidationErrors } from "utils/useValidationErrors";
import { actionStatus } from "utils/constants";

import { ImportErrorPanelProps } from "./types";

import "./style.scss";

const ImportErrorPanel = memo(
    ({ idProject, idInputLog, idInputVersion = 0, modelName, calculationPreconditions }: ImportErrorPanelProps) => {
        const dispatch = useDispatch();

        const dataImportFromStore: any = useSelector((state) => get(state, `modelDataImport.${idProject}-${idInputLog}`));

        const inputsDuplicate: any = useSelector((state) => get(state, `resources.${projectInputsDuplicateResourceName}.itemsById`));

        const { inputCriticalValidations } = useValidationErrors(idInputLog, true);

        const [modelInputsRes, isLoadingModelInputsRes] = useMeasureInputs({ idInputLog });

        const inputDuplicated = useMemo(() => {
            let duplicated = false;

            // If "duplicate" resource is not empty,
            // there has been a duplication done
            if (!isEmpty(inputsDuplicate)) {
                for (const key of Object.keys(inputsDuplicate)) {
                    // Check if the selected Model has been duplicated
                    if (key.includes(`${idProject}-${modelName}`)) {
                        duplicated = inputsDuplicate[key]?.data?.idInputLog === idInputLog;

                        break;
                    }
                }
            }

            return duplicated;
        }, [idProject, idInputLog, modelName, inputsDuplicate]);

        const modelInputs = useMemo(() => {
            let modelInputs = [];

            if (!isEmpty(modelInputsRes) && !isLoadingModelInputsRes) {
                modelInputs = (modelInputsRes.equipmentinputstables || []).concat(modelInputsRes.nonequipmentinputstables || []);
            }

            return modelInputs;
        }, [modelInputsRes, isLoadingModelInputsRes]);

        const dataImport = useMemo(() => {
            if (dataImportFromStore !== undefined) {
                return dataImportFromStore;
            } else if (inputDuplicated) {
                const sheetStatuses: any = {};

                // Go through every required input in calculation preconditions
                // and map their statuses
                Object.keys(calculationPreconditions).forEach((key) => {
                    const cp = calculationPreconditions[key][0];

                    if (cp.required === 1) {
                        const { msgClassification, ...rest } = cp;

                        const input = {
                            ...rest,
                            status: msgClassification,
                        };

                        if (input.sheetName) {
                            sheetStatuses[input.sheetName] = input;
                        }
                    }
                });

                return {
                    finished: true,
                    fileName: "Model duplication",
                    sheetStatuses,
                };
            } else {
                return undefined;
            }
        }, [calculationPreconditions, dataImportFromStore, inputDuplicated]);

        const missingData = useMemo(() => {
            let missingData = false;

            if (!isEmpty(modelInputs) && !isEmpty(calculationPreconditions)) {
                // If some input row count is 0 and in calculation preconditions
                // input is invalid, that means that data is missing
                if (
                    modelInputs.some(
                        (input: any) =>
                            input.rowCount === 0 && calculationPreconditions?.[input.tableName]?.some((cp: any) => cp.tableIsValid === 0)
                    )
                ) {
                    missingData = true;
                }
                // If some calculation preconditions input is invalid, is required
                // for Model and data cannot be found, that means that data is missing
                else if (
                    Object.keys(calculationPreconditions).some((key) =>
                        calculationPreconditions[key].some(
                            (cp: any) =>
                                cp.tableIsValid === 0 && cp.required && !modelInputs.find((input: any) => input.tableName === cp.tableName)
                        )
                    )
                ) {
                    missingData = true;
                }
            }

            return missingData;
        }, [calculationPreconditions, modelInputs]);

        const dataImportMessage = useMemo(() => {
            let dataImportMessage = "";

            if (dataImport !== undefined && !isNil(dataImport.finished)) {
                const statusesCount = countBy(dataImport.sheetStatuses, "status");
                const successCount = statusesCount[actionStatus.DONE];
                const noSuccessCount = successCount === undefined;
                const oneSuccessCount = successCount === 1;

                dataImportMessage = `${noSuccessCount ? "No" : `${successCount}`} table${oneSuccessCount ? " was" : "s were"} ${
                    noSuccessCount ? "" : "successfully"
                } imported from ${dataImport.fileName}.${missingData ? " Import missing data." : ""}`;
            }

            return dataImportMessage;
        }, [dataImport, missingData]);

        const { errorList, validationList } = useMemo(() => {
            let initial: {
                errorList: any[];
                validationList: any[];
            } = { errorList: [], validationList: [] };

            if (dataImport === undefined || isEmpty(modelInputs)) {
                return initial;
            }

            const result = transform(
                dataImport.sheetStatuses,
                (result, value, key) => {
                    const tableName = modelInputs.find((input: any) => input.sheetName === key)?.tableName;

                    // If input status is error, push input to error list
                    if (value.status?.toLowerCase() === actionStatus.ERROR.toLowerCase()) {
                        result.errorList.push({ sheetName: key, ...value });
                    }

                    const inputCriticalValidation = inputCriticalValidations.find((validation: any) =>
                        validation.tables.includes(tableName)
                    );

                    // If input doesn't pass validation, push input to validation list
                    if (inputCriticalValidation) {
                        result.validationList.push(inputCriticalValidation);
                    }
                },
                initial
            );

            return result;
        }, [dataImport, modelInputs, inputCriticalValidations]);

        const onHideDataImportClick = useCallback(() => {
            dispatch({
                type: MODEL_DATA_IMPORT_ACK,
                idProject,
                idInputLog,
            });
        }, [idProject, idInputLog, dispatch]);

        return (
            <>
                {dataImport !== undefined && !isNil(dataImport.finished) && !dataImport.hide && (
                    <div className="import-error-panel">
                        {(errorList && !isEmpty(errorList)) || (validationList && !isEmpty(validationList)) ? (
                            <InfoPanel error onClose={onHideDataImportClick}>
                                <ErrorMsg
                                    icon="octagon-exclamation-empty"
                                    iconWithLabelMedium
                                    message={!inputDuplicated ? <ImportErrorMsg fileName={dataImport?.fileName} /> : <DuplicateErrorMsg />}
                                />

                                {errorList &&
                                    !isEmpty(errorList) &&
                                    errorList.map((input, index) => (
                                        <div key={`import-error-${index}`} className="import-error-panel__error-message">
                                            <ErrorMsg withoutIcon message={`Excel sheet “${input.sheetName}”: ${input.message}`} />
                                        </div>
                                    ))}

                                {validationList &&
                                    !isEmpty(validationList) &&
                                    validationList.map((validation, index) => (
                                        <div key={`validation-error-${index}`} className="import-error-panel__error-message">
                                            <ValidationError
                                                idInputLog={idInputLog}
                                                idInputVersion={idInputVersion}
                                                validations={validation}
                                            />
                                        </div>
                                    ))}
                            </InfoPanel>
                        ) : (
                            !inputDuplicated &&
                            dataImportMessage && (
                                <InfoPanel success onClose={onHideDataImportClick}>
                                    <SuccessMsg icon="check-circle-empty" iconWithLabelMedium message={dataImportMessage} />
                                </InfoPanel>
                            )
                        )}
                    </div>
                )}
            </>
        );
    }
);

export default ImportErrorPanel;
