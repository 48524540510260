import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { useUserProfile } from "store/resources/actions/user/userActions";

import Dropdown from "components/ui/Dropdown";
import Input from "components/ui/Input";
import ActionLabel from "components/ui/Label/ActionLabel";

import { toLocaleDateTime } from "utils/dateTime";
import { openWindowStandaloneReport } from "utils/window";

import { StandaloneReportProps } from "./types";

export const renderFilters = (filters: any, updateFilter: any, updateFilterValue: any) => {
    return (
        <div className="list-search-headers-row">
            <div className="column-filter column-name filter left">
                <Input
                    placeholder="Search"
                    icon="search"
                    inputTableFilter
                    value={filters.name}
                    onChange={(event) => updateFilter("name")(event)}
                />
            </div>
            <div className="column-filter column-report-type filter right">
                <Dropdown
                    placeholder="Select"
                    value={filters.reportType || ""}
                    items={[
                        {
                            label: "All",
                            value: "",
                        },
                        {
                            label: "HTML",
                            value: "html",
                        },
                        {
                            label: "PBI",
                            value: "pbi",
                        },
                    ]}
                    inputTableFilter
                    onChange={(value) => updateFilterValue("reportType")(value)}
                />
            </div>
            <div className="column-filter column-last-update filter hidden" />
        </div>
    );
};

const StandaloneReport = memo(
    ({ idProject, idReport, idPbi, projectName, filename, lastUpdateDate, lastUpdatedBy, name, reportType }: StandaloneReportProps) => {
        const dispatch = useDispatch();

        const [user] = useUserProfile({ userNumber: lastUpdatedBy });

        const onReportNameClick = useCallback(() => {
            dispatch(
                openWindowStandaloneReport({
                    idProject,
                    idReport,
                    idPbi,
                    reportName: name,
                    reportType,
                    fileName: filename,
                    projectName,
                })
            );
        }, [idProject, idReport, idPbi, name, reportType, filename, projectName, dispatch]);

        return (
            <div key={`standalone-report-${idReport}`} className="list-item-row">
                <div className="item-value column-name">
                    <ActionLabel title={`ID: ${idReport} | Open Standalone Report`} onClick={onReportNameClick}>
                        {name}
                    </ActionLabel>
                </div>
                <div className="item-value column-report-type">{reportType}</div>
                <div className="item-value column-last-update">
                    <div>{`${user?.firstName} ${user?.lastName}`}</div>
                    <div>{toLocaleDateTime(`${lastUpdateDate}Z`) || ""}</div>
                </div>
            </div>
        );
    }
);

export default StandaloneReport;
