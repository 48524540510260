import { get, isEmpty, kebabCase } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RUN_CALCULATION_TERMINATE } from "store/actionTypes";

import { modelDataImportV3ResourceName } from "store/configureResources";

// import { useModelInputsProgress } from "store/resources/actions/modelInputs/modelInputsProgressActions";
import { useActiveViewsModelList } from "store/resources/actions/activeViews/activeViewModelActions";
import { updateProjectInput, deleteProjectInput } from "store/resources/actions/projectInput/projectInputActions";
import { useProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useProjectFuels } from "store/resources/actions/fuel/fuelActions";

import { useProcessesInProgress, useIsInProgress } from "store/processes/useProcessStatus";

import { selectProjection } from "store/projections/actions";

import Button from "components/ui/ButtonNew";
import TagWithDropdown from "components/ui/Dropdown/TagWithDropdown";
import Label from "components/ui/Label";
import LabelWithIcon from "components/ui/LabelWithIcon";
import Avatar from "components/ui/Avatar";
import { DeterminateProgressBar, IndeterminateProgressBar } from "components/ui/ProgressBar";
import Separator from "components/ui/Separator";
import Switcher from "components/ui/Switcher";
import Tag from "components/ui/Tag";

import {
    openModalDialogDeleteProjection,
    openModalDialogRestoreProjection,
    openModalDialogDeletePermanentlyProjection,
} from "layouts/Modal/utils";
import {
    openModalDialogEditModel,
    openModalDialogClientReview,
    openModalDialogClientApprovement,
    // openModalDialogSetFinalSignedOffError,
    openModalDialogDuplicateModel,
} from "layouts/Modal/ModelDashboardModal/utils";

import ImportErrorPanel from "pages/ManageProject/ProjectionsContent/common/ImportErrorPanel";
import CalculationErrorPanel from "pages/ManageProject/ProjectionsContent/common/CalculationErrorPanel";
import ImportDataPanel from "pages/ManageProject/ProjectionsContent/common/ImportDataPanel";

import { projectReadOnlyEnabled, modelReadOnlyEnabled, actionAllowed } from "pages/utils";

import { useModelCalculationPreconditions } from "utils/useCalculationPreconditions";
import { useProjectFromStore } from "utils/useProjectFromStore";
import { useHaveRights } from "utils/useHaveRights";
import { useUserRights } from "utils/useUserRights";
import { useViews } from "utils/useViews";
import { projections, allModelStates, modelStates, actionStatus } from "utils/constants";
import { hasAnyOfPermissions, hasInternalUserRole } from "utils/user";
import { USER_ACTIONS, USER_ROLES } from "utils/user/defines";
import { windowContainerTypes } from "utils/window";

import { Territory, Fuel } from "types/types";
import { DashboardHeaderProps } from "./types";

import "./style.scss";

const DashboardHeader = memo(({ idClient, viewIndex, model }: DashboardHeaderProps) => {
    const {
        idProject,
        idInputLog,
        idTerritory,
        idFuel,
        name,
        description,
        idAnalyst,
        idReviewer,
        analyst,
        reviewer,
        required,
        active,
        modelState,
    } = model;

    const dispatch = useDispatch();

    // @ts-ignore
    const userName = useSelector((state) => state.user.userName);

    const views = useViews({ containerName: windowContainerTypes.Root });

    const runCalculationStatus = useSelector((state) => get(state, `runCalculation.${idProject}-${idInputLog}`));

    // Resources

    const processesInProgress = useProcessesInProgress({ idInputLog });

    const isInProgress = useIsInProgress({ idInputLog });

    const [activeViews, isLoadingActiveViews] = useActiveViewsModelList({ idProject, idInputLog });

    const [projectTerritories, isLoadingProjectTerritories] = useProjectTerritories({ idProject });
    const [projectFuels, isLoadingProjectFuels] = useProjectFuels({ idProject });

    const { modelCalculationPreconditions, modelCalculationPreconditionsErrors } = useModelCalculationPreconditions({
        idProject,
        idInputLog,
    });

    // const [inputsProgress] = useModelInputsProgress({ idProject, idInputLog });

    const isUploading = Boolean(model.uploadProps?.isUploading);

    const haveAnalystRights = useHaveRights({ entityId: idAnalyst, userName });
    const haveReviewerRights = useHaveRights({ entityId: idReviewer, userName });

    const userRights = useUserRights();

    // Selectors

    const project = useProjectFromStore({ idClient, idProject });

    const haveBasicClientRights = userRights === USER_ROLES.BASIC_PRIMARY_CONTACT;
    const haveAdvancedClientRights = userRights === USER_ROLES.ADVANCED_CLIENT;

    const importStartTime =
        processesInProgress?.modelDataImport?.started === undefined ? 0 : Date.parse(processesInProgress.modelDataImport.started);

    const calculationStartTime =
        processesInProgress?.runCalculations?.started === undefined ? 0 : Date.parse(`${processesInProgress.runCalculations.started}Z`);

    const haveCalculationErrors = processesInProgress?.runCalculations?.status?.toLowerCase() === actionStatus.ERROR.toLowerCase();

    // Memos

    const modelStateItems = useMemo(() => {
        let modelStateItems: any[] = [];

        if (haveAnalystRights && modelStates[USER_ROLES.ANALYST].hasOwnProperty(modelState.toUpperCase())) {
            modelStateItems = modelStates[USER_ROLES.ANALYST][modelState.toUpperCase()].map((status) => ({
                label: status,
                value: status.toUpperCase(),
            }));
        }
        if (haveReviewerRights && modelStates[USER_ROLES.MANAGER].hasOwnProperty(modelState.toUpperCase())) {
            modelStateItems = modelStates[USER_ROLES.MANAGER][modelState.toUpperCase()].map((status) => ({
                label: status,
                value: status.toUpperCase(),
            }));
        }
        if (haveBasicClientRights && modelStates[USER_ROLES.BASIC_PRIMARY_CONTACT].hasOwnProperty(modelState.toUpperCase())) {
            modelStateItems = modelStates[USER_ROLES.BASIC_PRIMARY_CONTACT][modelState.toUpperCase()].map((status) => ({
                label: status,
                value: status.toUpperCase(),
            }));
        }
        if (haveAdvancedClientRights && modelStates[USER_ROLES.ADVANCED_CLIENT].hasOwnProperty(modelState.toUpperCase())) {
            modelStateItems = modelStates[USER_ROLES.ADVANCED_CLIENT][modelState.toUpperCase()].map((status) => ({
                label: status,
                value: status.toUpperCase(),
            }));
        }

        return modelStateItems;
    }, [modelState, haveAnalystRights, haveReviewerRights, haveBasicClientRights, haveAdvancedClientRights]);

    const territory = useMemo(() => {
        let territory;

        if (!isEmpty(projectTerritories) && !isLoadingProjectTerritories) {
            territory = projectTerritories.find((territory: Territory) => territory.idTerritory === idTerritory);
        }

        return territory;
    }, [idTerritory, projectTerritories, isLoadingProjectTerritories]);

    const fuel = useMemo(() => {
        let fuel;

        if (!isEmpty(projectFuels) && !isLoadingProjectFuels) {
            fuel = projectFuels.find((fuel: Fuel) => fuel.idFuel === idFuel);
        }

        return fuel;
    }, [idFuel, projectFuels, isLoadingProjectFuels]);

    // Helper functions

    const changeModelStatus = useCallback(
        (modelStatus: string, userNote?: string, isPublicNote: boolean = false) => {
            dispatch(
                updateProjectInput({
                    idProject,
                    idInputLog,
                    modelState: modelStatus,
                    action: "Updating Model",
                    userNote,
                    isPublicNote,
                })
            );
        },
        [idProject, idInputLog, dispatch]
    );

    const onEditModel = useCallback(
        ({ modelName, modelDescription, modelAnalyst, modelReviewer, modelTerritory, modelFuel, onSuccess }) => {
            dispatch(
                updateProjectInput({
                    idProject,
                    idInputLog,
                    idTerritory: modelTerritory,
                    idFuel: modelFuel,
                    idAnalyst: modelAnalyst,
                    idReviewer: modelReviewer,
                    datasetName: modelName,
                    datasetDescription: modelDescription,
                    action: "Updating Model",
                    onSuccess,
                })
            );
        },
        [idProject, idInputLog, dispatch]
    );

    const onMoveDataset = useCallback(
        (active) => {
            dispatch(
                updateProjectInput({
                    idProject,
                    idInputLog,
                    active,
                    action: active ? "Restoring Model" : "Moving Model to Trash Bin",
                })
            );
        },
        [idProject, idInputLog, dispatch]
    );

    const onDeleteSuccess = useCallback(() => {
        dispatch(
            selectProjection({
                viewIndex,
                idProjectionView: projections.PROJECT_DASHBOARD,
                idProject,
            })
        );
    }, [idProject, viewIndex, dispatch]);

    const onDeleteDataset = useCallback(() => {
        dispatch(
            deleteProjectInput({
                idProject,
                idInputLog,
                action: "Deleting Model",
                onSuccess: onDeleteSuccess,
            })
        );
    }, [idProject, idInputLog, onDeleteSuccess, dispatch]);

    // Event handlers

    const onModelStatusChange = useCallback(
        (modelStatus) => {
            // If Manager wants to set Model status
            // to In Client Review
            if (modelStatus === allModelStates.IN_CLIENT_REVIEW.toUpperCase()) {
                // // If all required Model Inputs for Model
                // // are not set as Manager Signed Off,
                // // Modal window with an error pops up
                // if (inputsProgress?.percentage !== 100) {
                //     dispatch(
                //         openModalDialogSetClientReviewSignedOffError({
                //             modelName: name,
                //         })
                //     );
                // }
                // // If all required Model Inputs are set
                // // as Manager Signed Off, Modal window
                // // with an input field pops up
                // else {
                //     dispatch(
                //         openModalDialogClientReview({
                //             modelName: name,
                //             onChange: (userNote: string) => changeModelStatus(modelStatus, userNote),
                //         })
                //     );
                // }

                dispatch(
                    openModalDialogClientReview({
                        modelName: name,
                        onChange: (userNote: string) => changeModelStatus(modelStatus, userNote),
                    })
                );
            }
            // If Model status is about to be set to
            // Client Unapproved or Approved,
            // Modal window with an input field
            // pops up
            else if (
                modelStatus === allModelStates.CLIENT_UNAPPROVED.toUpperCase() ||
                modelStatus === allModelStates.CLIENT_APPROVED.toUpperCase()
            ) {
                const mStatus = modelStatus === allModelStates.CLIENT_UNAPPROVED.toUpperCase() ? "Unapproved" : "Approved";

                dispatch(
                    openModalDialogClientApprovement({
                        modelName: name,
                        modelStatus: mStatus,
                        onChange: (userNote: string) => changeModelStatus(modelStatus, userNote, true),
                    })
                );
            } else {
                changeModelStatus(modelStatus);
            }
        },
        [
            name,
            // inputsProgress,
            changeModelStatus,
            dispatch,
        ]
    );

    const onRequiredChange = useCallback(() => {
        dispatch(
            updateProjectInput({
                idProject,
                idInputLog,
                required: !required,
                action: "Updating Model",
            })
        );
    }, [idProject, idInputLog, required, dispatch]);

    const onEditClick = useCallback(() => {
        dispatch(
            openModalDialogEditModel({
                idClient,
                idProject,
                modelName: name,
                modelDescription: description ? description : undefined,
                modelAnalyst: idAnalyst,
                modelReviewer: idReviewer,
                modelTerritory: idTerritory,
                modelFuel: idFuel,
                // @ts-ignore
                onEdit: onEditModel,
            })
        );
    }, [idClient, idProject, name, description, idAnalyst, idReviewer, idTerritory, idFuel, onEditModel, dispatch]);

    const onDuplicateClick = useCallback(() => {
        const onDuplicateSuccess = (action?: any, targetProjectId?: number) => {
            const idInputLog = action?.data?.idInputLog;

            // Only select newly created model if project view where
            // the model is located at is opened
            const isViewOpen = views.find((view) => view.name.includes(`project-${targetProjectId}`)) !== undefined;

            if (isViewOpen) {
                dispatch(
                    selectProjection({
                        viewIndex,
                        idProjectionView: projections.MODEL_DASHBOARD,
                        idProject,
                        idProjection: idInputLog,
                        key: "idInputLog",
                    })
                );
            }
        };

        dispatch(
            openModalDialogDuplicateModel({
                idClient,
                idProject,
                idInputLog,
                idTerritory,
                idFuel,
                onSuccess: onDuplicateSuccess,
            })
        );
    }, [viewIndex, idClient, idProject, idInputLog, idTerritory, idFuel, views, dispatch]);

    const onDeleteClick = useCallback(() => {
        dispatch(
            openModalDialogDeleteProjection({
                title: "Delete Model",
                projectionName: name,
                onMove: () => onMoveDataset(false),
                onDelete: onDeleteDataset,
            })
        );
    }, [name, onMoveDataset, onDeleteDataset, dispatch]);

    const onRestoreClick = useCallback(() => {
        dispatch(
            openModalDialogRestoreProjection({
                title: "Restore Model",
                text: (
                    <>
                        <div>
                            <strong>{name}</strong> will be restored to the Project.
                        </div>
                        <div>You will be able to import data and run calculations.</div>
                    </>
                ),
                onRestore: () => onMoveDataset(true),
            })
        );
    }, [name, onMoveDataset, dispatch]);

    const onDeletePermanentlyClick = useCallback(() => {
        dispatch(
            openModalDialogDeletePermanentlyProjection({
                title: "Delete Model Permanently",
                projectionName: name,
                onDelete: onDeleteDataset,
            })
        );
    }, [name, onDeleteDataset, dispatch]);

    return (
        <div className="flex-column model-dashboard-header">
            <div className="model-dashboard-header__upper-container">
                <div className="flex-row align-center model-dashboard-header__top-container">
                    <div>
                        <div className="model-dashboard-header__model">Model</div>
                        <div className="flex-row align-center">
                            <div className="model-dashboard-header__title">{name}</div>
                            {active &&
                                !modelReadOnlyEnabled(modelState, required) &&
                                actionAllowed(userRights, USER_ACTIONS.MODEL_EDIT, required) && (
                                    <div className="margin-left-small">
                                        <Button
                                            variant="tertiary"
                                            icon="ui-pencil__edit__create_b_s"
                                            iconSize="sm"
                                            title="Edit model information"
                                            padding="sm"
                                            onClick={onEditClick}
                                        />
                                    </div>
                                )}
                            {!isEmpty(activeViews) &&
                                !isLoadingActiveViews &&
                                activeViews.map((userNumber: string, index: number) => (
                                    <div key={`avatar-${index}`} className="margin-left-small">
                                        <Avatar userNumber={userNumber} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="model-dashboard-header__middle-container">
                    <span>{description}</span>
                </div>
                <div className="flex-row align-center justify-space-between model-dashboard-header__bottom-container">
                    <div className="flex-column flex-one model-dashboard-header__model-details-container">
                        <div className="flex-row model-dashboard-header__model-details">
                            <div>
                                <span className="model-dashboard-header__label">ID: </span>
                                <span>{idInputLog}</span>
                            </div>
                            <div className="margin-left">
                                <span className="model-dashboard-header__label">Territory: </span>
                                <span>{territory?.alias || territory?.name || "None"}</span>
                            </div>
                            <div className="margin-left">
                                <span className="model-dashboard-header__label">Fuel: </span>
                                <span>{fuel?.name || "None"}</span>
                            </div>
                        </div>
                        {hasInternalUserRole() && (
                            <div className="flex-row">
                                <div>
                                    <span className="model-dashboard-header__label">Analyst responsible: </span>
                                    <span>{analyst || "None"}</span>
                                </div>
                                <div className="margin-left">
                                    <span className="model-dashboard-header__label">Reviewer responsible: </span>
                                    <span>{reviewer || "None"}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="model-dashboard-header__model-actions-container">
                        {(required || hasInternalUserRole()) && (
                            <div className="model-dashboard-header__status-container">
                                <div className="flex-row align-center">
                                    <Label>Status</Label>
                                    {/* If Project status is set to Final Report, statuses cannot be changed */}
                                    {!isEmpty(modelStateItems) &&
                                    !projectReadOnlyEnabled(project?.projectState || "") &&
                                    (haveAnalystRights || haveReviewerRights || haveBasicClientRights || haveAdvancedClientRights) ? (
                                        <TagWithDropdown
                                            tagClassName={kebabCase(modelState)}
                                            value={modelState.toUpperCase()}
                                            items={modelStateItems}
                                            onChange={(value) => onModelStatusChange(value)}
                                        />
                                    ) : (
                                        <div className="aeg-tag-wrapper">
                                            <Tag className={kebabCase(modelState)} size="sm">
                                                <>{modelState.toLowerCase()}</>
                                            </Tag>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="flex-row">
                            {active && hasAnyOfPermissions([USER_ACTIONS.MODEL_DUPLICATE]) && (
                                <div className="model-dashboard-header__duplicate-container">
                                    <Button
                                        variant="tertiary"
                                        padding="sm"
                                        iconLeft="ui-control_point_duplicate_b_s"
                                        iconSize="sm"
                                        onClick={onDuplicateClick}
                                    >
                                        Duplicate
                                    </Button>
                                </div>
                            )}
                            {active &&
                                // Required finalized Models cannot be deleted
                                !modelReadOnlyEnabled(modelState, required) &&
                                actionAllowed(userRights, USER_ACTIONS.MODEL_DELETE, required) && (
                                    <div className="margin-left-small">
                                        <Button
                                            variant="tertiary"
                                            padding="sm"
                                            iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                                            iconSize="sm"
                                            onClick={onDeleteClick}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                )}
                            {!active &&
                                // Required finalized Models cannot be deleted
                                !modelReadOnlyEnabled(modelState, required) &&
                                actionAllowed(userRights, USER_ACTIONS.MODEL_DELETE, required) && (
                                    <>
                                        <div className="margin-left-small">
                                            <Button
                                                variant="tertiary"
                                                padding="sm"
                                                iconLeft="arrows-reply_b_a"
                                                iconSize="sm"
                                                onClick={onRestoreClick}
                                            >
                                                Restore
                                            </Button>
                                        </div>
                                        <div className="margin-left-small">
                                            <Button
                                                variant="tertiary"
                                                padding="sm"
                                                iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                                                iconSize="sm"
                                                onClick={onDeletePermanentlyClick}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-dashboard-header__lower-container">
                <Separator line />
                <div className="flex-row align-center">
                    <div className="flex-column flex-one">
                        <ImportErrorPanel
                            idProject={idProject}
                            idInputLog={idInputLog}
                            modelName={name}
                            calculationPreconditions={modelCalculationPreconditions}
                        />

                        {haveCalculationErrors && (
                            <CalculationErrorPanel statusMessage={processesInProgress?.runCalculations?.statusMessage} />
                        )}

                        {!modelReadOnlyEnabled(modelState, required) && hasAnyOfPermissions([USER_ACTIONS.MODEL_ADD]) && active && (
                            <ImportDataPanel
                                viewIndex={viewIndex}
                                idClient={idClient}
                                idProject={idProject}
                                idInputLog={idInputLog}
                                idTerritory={idTerritory}
                                idFuel={idFuel}
                                modelName={name}
                                calculationPreconditionsErrors={modelCalculationPreconditionsErrors}
                                disabledImport={isInProgress}
                            />
                        )}
                    </div>
                    <div className="flex-row">
                        <Switcher
                            active={required === null ? false : required}
                            label="Required for project"
                            disabled={
                                projectReadOnlyEnabled(project?.projectState || "") ||
                                !hasAnyOfPermissions([USER_ACTIONS.MODEL_EDIT_REQUIRED])
                            }
                            onClick={onRequiredChange}
                        />
                    </div>
                </div>

                {/* Uploading dataset progress bar */}
                {isUploading && (
                    <div className="model-dashboard-header__progress-bar">
                        <Separator />
                        <DeterminateProgressBar
                            resourceId={model.uploadProps.uploadId}
                            resourceName={modelDataImportV3ResourceName}
                            topElement={
                                <LabelWithIcon
                                    className="model-dashboard-header__progress-bar-top-element"
                                    icon="arrows-sync__autorenew_b_a"
                                >{`Uploading dataset | ${model.uploadProps.fileName}`}</LabelWithIcon>
                            }
                            showPercentage
                        />
                    </div>
                )}

                {/* Transforming data progress bar */}
                {!isUploading && processesInProgress.modelDataImport !== undefined && processesInProgress.modelDataImport !== false && (
                    <div className="model-dashboard-header__progress-bar">
                        <Separator />
                        <IndeterminateProgressBar
                            topElement={
                                <LabelWithIcon
                                    className="model-dashboard-header__progress-bar-top-element"
                                    icon="arrows-sync__autorenew_b_a"
                                >{`Transforming dataset | ${processesInProgress.modelDataImport.fileName}`}</LabelWithIcon>
                            }
                            startTime={importStartTime}
                            steps={Object.keys(processesInProgress.modelDataImport.steps).map((stepKey) => ({
                                name: stepKey,
                                status: processesInProgress.modelDataImport.steps[stepKey],
                            }))}
                        />
                    </div>
                )}

                {/* Calculations progress bar */}
                {processesInProgress.runCalculations !== undefined && processesInProgress.runCalculations !== false && (
                    <div className="model-dashboard-header__progress-bar">
                        <Separator />
                        <IndeterminateProgressBar
                            topElement={
                                <LabelWithIcon
                                    className="model-dashboard-header__progress-bar-top-element"
                                    icon="arrows-sync__autorenew_b_a"
                                >
                                    {runCalculationStatus === RUN_CALCULATION_TERMINATE ? "Stopping..." : "Calculating"}
                                </LabelWithIcon>
                            }
                            startTime={runCalculationStatus === RUN_CALCULATION_TERMINATE ? undefined : calculationStartTime}
                            steps={Object.keys(processesInProgress.runCalculations.steps).map((stepKey) => ({
                                name: stepKey,
                                status: processesInProgress.runCalculations.steps[stepKey],
                            }))}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});

export default DashboardHeader;
