import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { deleteCombination } from "store/resources/actions/projectInput/projectInputCombinationActions";

import Button from "components/ui/ButtonNew";

import { DeleteCombinationModalProps } from "layouts/Modal/ModelDashboardModal/types";

const DeleteCombination = memo(({ idProject, idCombination, combinationName, onCancel }: DeleteCombinationModalProps) => {
    const dispatch = useDispatch();

    const onDeleteClick = useCallback(() => {
        dispatch(
            deleteCombination({
                idProject,
                idCombination,
                action: "Deleting combination",
                onComplete: onCancel,
            })
        );
    }, [idProject, idCombination, onCancel, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding">
                <div>
                    <strong>{combinationName}</strong> combination will be deleted permanently.
                </div>
                <div>Are you sure you want to delete?</div>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button
                    variant="primary"
                    color="system-critical"
                    iconLeft="ui-delete_forever_b_s"
                    iconSize="sm"
                    padding="lg"
                    onClick={onDeleteClick}
                >
                    Delete Permanently
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default DeleteCombination;
