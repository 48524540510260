import { memo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import Icon from "components/ui/IconNew";
import LabelWithIcon from "components/ui/LabelWithIcon";

import { toLocaleDateTime } from "utils/dateTime";
import { openWindowSingleModelReport, openWindowCombinedModelReport } from "utils/window";

import "./style.scss";

export const IconSingleModelReport: React.NamedExoticComponent<{
    idClient: number;
    idProject: number;
    idInputLog: number;
    title: string;
    subTitle: string;
    calculationResult: any;
    className?: string;
    disabled?: boolean;
    idModel?: number;
    scenarioName?: string;
    withApprovement?: boolean;
    withDate?: boolean;
    withLabel?: boolean;
}> = memo(
    ({
        idClient,
        idProject,
        idInputLog,
        title,
        subTitle,
        calculationResult,
        className,
        disabled,
        idModel,
        scenarioName,
        withApprovement,
        withDate,
        withLabel,
    }) => {
        const { results, isPotential, finished } = calculationResult;

        const dispatch = useDispatch();

        const titleWithDate = `${subTitle} - ${toLocaleDateTime(finished + "Z")}`;

        let fullTitle = titleWithDate;

        if (scenarioName) {
            fullTitle += `\nScenario Name: ${scenarioName}`;
        }
        if (results) {
            fullTitle += `\nResults Log ID: ${results}`;
        }

        const onViewPowerBiClick = (event: any) => {
            event.stopPropagation();

            dispatch(
                openWindowSingleModelReport({
                    idClient,
                    idProject,
                    idModel,
                    idInputLog,
                    filterEntityId: results,
                    title,
                    subTitle: titleWithDate,
                    showApprovement: withApprovement,
                })
            );
        };

        return withLabel ? (
            <LabelWithIcon
                className={className}
                icon="ui-chart_multiple_b_s"
                iconSize="sm"
                iconClassName={cn("powerbi-icon", {
                    inverse: isPotential,
                })}
                disabled={disabled}
                onClick={(event) => onViewPowerBiClick(event)}
            >
                {withDate ? titleWithDate : subTitle}
            </LabelWithIcon>
        ) : (
            <Icon
                className={cn("powerbi-icon", {
                    inverse: isPotential,
                })}
                icon={"ui-chart_multiple_b_s"}
                iconTitle={fullTitle}
                size="sm"
                disabled={disabled}
                onClick={(event) => onViewPowerBiClick(event)}
            />
        );
    }
);

export const IconCombinedModelReport: React.NamedExoticComponent<{
    idClient: number;
    idProject: number;
    idCombination: number;
    projectName: string;
    combinationName: string;
    disabled?: boolean;
    withLabel?: boolean;
}> = memo(({ idClient, idProject, idCombination, projectName, combinationName, disabled, withLabel }) => {
    const dispatch = useDispatch();

    const onViewPowerBiClick = (event: any) => {
        event.stopPropagation();

        dispatch(
            openWindowCombinedModelReport({
                idClient,
                idProject,
                filterEntityId: idCombination,
                projectName,
                combinationName,
            })
        );
    };

    return withLabel ? (
        <LabelWithIcon
            icon="ui-chart_multiple_b_s"
            iconSize="sm"
            iconClassName="powerbi-icon"
            disabled={disabled}
            onClick={(event) => onViewPowerBiClick(event)}
        >
            {combinationName}
        </LabelWithIcon>
    ) : (
        <Icon
            className="powerbi-icon"
            icon="ui-chart_multiple_b_s"
            // Combined models do not have a date
            iconTitle={`Combination ID: ${idCombination}`}
            size="sm"
            disabled={disabled}
            onClick={(event) => onViewPowerBiClick(event)}
        />
    );
});
