import { memo } from "react";

import { powerBIPublicResourceName } from "store/configureResources";
import { usePowerBIReport } from "store/resources/actions/powerBI/powerBIActions";

import ErrorBoundary from "components/ui/ErrorBoundary";

import IconLoading from "components/ui/Icons/IconLoading";
import PowerBIReport from "components/ui/PowerBIReport";

import { useGoogleAnalyticsInPage } from "utils/useGoogleAnalytics";

import { PublicPowerBIProps } from "pages/PowerBI/types";

const PublicPowerBI = memo((props: PublicPowerBIProps) => {
    const { reportId, filterEntityId } = props;

    const googleAnalyticsTrackingId = "";

    useGoogleAnalyticsInPage({ isAnonymousPage: true, trackingId: googleAnalyticsTrackingId });

    const [report] = usePowerBIReport({ resourceName: powerBIPublicResourceName, reportId, filterEntityId });

    return (
        <>
            {!(report && report.url && report.token) ? (
                <IconLoading />
            ) : (
                <ErrorBoundary>
                    <PowerBIReport
                        embedId={reportId}
                        embedUrl={report.url}
                        accessToken={report.token}
                        filterEntityId={filterEntityId}
                        hideActions={true}
                        showNavContentPane={false}
                    />
                </ErrorBoundary>
            )}
        </>
    );
});

export default PublicPowerBI;
