export const isResponseJson = (response) => {
    const header = response.headers.get("content-type");

    return Boolean(header && (header.includes("application/json") || header.includes("application/problem+json")));
};

export const isResponseText = (response) => {
    const header = response.headers.get("content-type");

    return Boolean(header && header.includes("text/"));
};

export const isResponseFileDownload = (response) => {
    const header = response.headers.get("content-disposition");

    return Boolean(header && header.includes("attachment;"));
};

export const isResponseNoContent = (response) => {
    return response.status === 204;
};
