import * as React from "react";
import ClassNames from "classnames";

import "./style.scss";

const SideNavContent = (props: SideNavContentProps) => {
    const { className, rowLayout, children } = props;

    const classNames = ClassNames("sidenav-content", className, {
        "flex-row": rowLayout,
    });

    return <div className={classNames}>{children}</div>;
};

interface SideNavContentProps {
    className?: string;
    rowLayout?: boolean;
    children?: React.ReactNode;
}

export default SideNavContent;
