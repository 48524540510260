// CUSTOM ICONS

export const icons = [
    {
        // Brightness icon
        name: "brightness-filled",
        viewBox: "0 0 14 14",
        path: "M3.94337 2.82333L2.89337 1.77916L2.07087 2.60166L3.11504 3.64583L3.94337 2.82333ZM2.33337 6.125H0.583374V7.29166H2.33337V6.125ZM7.58337 0.320831H6.41671V2.04166H7.58337V0.320831ZM11.9292 2.60166L11.1067 1.77916L10.0625 2.82333L10.885 3.64583L11.9292 2.60166ZM10.0567 10.5933L11.1009 11.6433L11.9234 10.8208L10.8734 9.77666L10.0567 10.5933ZM11.6667 6.125V7.29166H13.4167V6.125H11.6667ZM7.00004 3.20833C5.06921 3.20833 3.50004 4.7775 3.50004 6.70833C3.50004 8.63916 5.06921 10.2083 7.00004 10.2083C8.93087 10.2083 10.5 8.63916 10.5 6.70833C10.5 4.7775 8.93087 3.20833 7.00004 3.20833ZM6.41671 13.0958H7.58337V11.375H6.41671V13.0958ZM2.07087 10.815L2.89337 11.6375L3.93754 10.5875L3.11504 9.765L2.07087 10.815Z",
    },
    {
        // Moon icon
        name: "moon-filled",
        viewBox: "0 0 10 11",
        path: "M3.99284 0.103548C4.16944 0.0669302 4.34603 0.0408907 4.52019 0.0238022C4.69922 0.00671379 4.87663 -0.00142356 5.0516 0.000203907C5.15007 0.00101764 5.22901 0.0815775 5.22819 0.18004C5.22738 0.255717 5.17937 0.320002 5.11263 0.345228C4.368 0.638173 3.7723 1.18419 3.4069 1.8604C3.0472 2.52523 2.91049 3.31536 3.07406 4.11282L3.07487 4.11852C3.26123 5.01851 3.79346 5.7582 4.50554 6.22691C5.2168 6.69562 6.10629 6.89255 7.00635 6.70864L7.01205 6.70783C7.65983 6.57356 8.2238 6.26109 8.66407 5.83062C9.11979 5.38551 9.44206 4.81508 9.5861 4.19013C9.60808 4.09411 9.70411 4.03471 9.79932 4.05668C9.87175 4.07377 9.92383 4.13235 9.93522 4.20152C10.1501 5.51977 9.82536 6.81117 9.11654 7.83973C8.41097 8.86341 7.32373 9.62751 6.00944 9.89767C4.6569 10.176 3.31983 9.87977 2.25131 9.1767C1.18197 8.47119 0.382002 7.36044 0.103681 6.00802C-0.168128 4.68732 0.10775 3.38128 0.775882 2.32586C1.44727 1.26719 2.51335 0.459151 3.81787 0.142608C3.8431 0.136098 3.8724 0.129588 3.90414 0.122264H3.90495L3.99284 0.103548Z",
    },
    {
        // Document table icon
        name: "document-table-empty",
        viewBox: "0 0 24 24",
        path: "M15.4141 2H4V22H20V6.5859L15.4141 2ZM18 20H6V4H14.5856L18 7.4144V20ZM8 16H16V8H8V16ZM12.65 9.35H14.65V11.35H12.65V9.35ZM12.65 12.65H14.65V14.65H12.65V12.65ZM9.35 9.35H11.35V11.35H9.35V9.35ZM9.35 12.65H11.35V14.65H9.35V12.65Z",
    },
];
