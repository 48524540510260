import { isEmpty } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";

import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";
import { useUploadedProjectFiles } from "store/resources/actions/projectFiles/uploadedProjectFilesActions";
import { useProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useProjectFuels } from "store/resources/actions/fuel/fuelActions";

import ComponentModel from "./ComponentModel";
import Model from "./Model";

import { useProjectFromStore } from "utils/useProjectFromStore";

import { ImportMode, ImportDataProps } from "./types";
import { ProjectInput } from "store/resources/actions/projectInput/types";

// Form validation

export const FORM_VALIDATION_KEYS = {
    NO_NAME: "NO NAME",
    NAME_EXISTS: "NAME EXISTS",
    NO_TEMPLATE: "NO TEMPLATE",
    NO_TERRITORY: "NO TERRITORY",
    NO_FUEL: "NO FUEL",
    NO_FILE: "NO FILE",
    NO_SELECTED_SHEETS: "NO SELECTED SHEETS",

    NO_UPLOADED_FILES: "NO UPLOADED FILES",
    NO_PROJECT_TERRITORIES: "NO PROJECT TERRITORIES",
    NO_PROJECT_FUELS: "NO PROJECT FUELS",
};

export const FORM_VALIDATION_ERROR_MESSAGES = {
    // Error messages

    [FORM_VALIDATION_KEYS.NO_NAME]: "A name is required to create the model",
    [FORM_VALIDATION_KEYS.NAME_EXISTS]: "This name is already in use",
    [FORM_VALIDATION_KEYS.NO_TEMPLATE]: "A template is required to create the model",
    [FORM_VALIDATION_KEYS.NO_FILE]: "Please select a file to import",
    [FORM_VALIDATION_KEYS.NO_TERRITORY]: "A territory is required to create the model",
    [FORM_VALIDATION_KEYS.NO_FUEL]: "A fuel is required to create the model",
    [FORM_VALIDATION_KEYS.NO_SELECTED_SHEETS]: "You should select at least one table to import",

    // Warning messages

    [FORM_VALIDATION_KEYS.NO_UPLOADED_FILES]: "No uploaded files found! Please first upload them under 'Project Files'",
    [FORM_VALIDATION_KEYS.NO_PROJECT_TERRITORIES]: "Please add a territory for this project",
    [FORM_VALIDATION_KEYS.NO_PROJECT_FUELS]: "Please add a fuel for this project",
};

const ImportData = memo((props: ImportDataProps) => {
    const { idClient, idProject, idInputLog, idModel, isComponentModel } = props;

    // States

    const [modelName, setModelName] = useState(props.modelName || "");
    const [modelTemplate, setModelTemplate] = useState<number | null>(props.idTemplate || null);
    const [modelRequired, setModelRequired] = useState(true);
    const [modelDescription, setModelDescription] = useState(props.modelDescription || "");
    const [modelAnalyst, setModelAnalyst] = useState<number | null>(null);
    const [modelReviewer, setModelReviewer] = useState<number | null>(null);
    const [modelTerritory, setModelTerritory] = useState<number | null>(props.idTerritory || null);
    const [modelFuel, setModelFuel] = useState<number | null>(props.idFuel || null);
    const [files, setFiles] = useState<File[]>([]);
    const [uploadedFile, setUploadedFile] = useState("");
    const [selectedSheets, setSelectedSheets] = useState({});

    const [importMode, setImportMode] = useState<ImportMode>("import");

    // Resources

    const [allModels = []] = useProjectInputs({ idProject });
    const [allComponentModels = []] = useComponentModels({ idProject });

    const [uploadedProjectFiles, isLoadingUploadedProjectFiles] = useUploadedProjectFiles({ idProject });

    const [projectTerritories, isLoadingProjectTerritories] = useProjectTerritories({ idProject });
    const [projectFuels, isLoadingProjectFuels] = useProjectFuels({ idProject });

    const project = useProjectFromStore({ idClient, idProject });

    const formValidationWarnings = useMemo(() => {
        return {
            [FORM_VALIDATION_KEYS.NO_PROJECT_TERRITORIES]: isEmpty(projectTerritories) && !isLoadingProjectTerritories,
            [FORM_VALIDATION_KEYS.NO_PROJECT_FUELS]: isEmpty(projectFuels) && !isLoadingProjectFuels,
            [FORM_VALIDATION_KEYS.NO_UPLOADED_FILES]: isEmpty(uploadedProjectFiles) && !isLoadingUploadedProjectFiles,
        };
    }, [
        uploadedProjectFiles,
        projectTerritories,
        projectFuels,
        isLoadingUploadedProjectFiles,
        isLoadingProjectTerritories,
        isLoadingProjectFuels,
    ]);

    const modelNameExists = useMemo(() => {
        return (
            allModels?.some(
                (model: ProjectInput) => model.name.toLowerCase() === modelName.toLowerCase().trim() && modelName !== "New Model"
            ) ||
            allComponentModels?.some((model) => model.name.toLowerCase() === modelName.toLowerCase().trim() && modelName !== "New Model")
        );
    }, [allModels, allComponentModels, modelName]);

    const onChange = useCallback((value, name, helper) => {
        switch (name) {
            case "modelName":
                setModelName(value);

                break;

            case "modelTemplate":
                setModelTemplate(value);

                helper?.(value);

                break;

            case "modelRequired":
                setModelRequired(value);

                break;

            case "modelDescription":
                setModelDescription(value);

                break;

            case "modelAnalyst":
                setModelAnalyst(value);

                break;

            case "modelReviewer":
                setModelReviewer(value);

                break;

            case "modelTerritory":
                setModelTerritory(value);

                break;

            case "importMode":
                setImportMode(value);

                break;
            case "uploadedFile":
                setUploadedFile(value);

                break;

            case "modelFuel":
                setModelFuel(parseInt(value));

                break;
        }
    }, []);

    if (isComponentModel) {
        return (
            <ComponentModel
                viewIndex={props.viewIndex}
                idProject={idProject}
                idManager={project?.idManager}
                idInputLog={idInputLog}
                idModel={idModel}
                managerFullName={project?.managerFullName}
                members={project?.members}
                modelName={modelName}
                modelTemplate={modelTemplate}
                modelRequired={modelRequired}
                modelDescription={modelDescription}
                modelAnalyst={modelAnalyst}
                modelReviewer={modelReviewer}
                modelTerritory={modelTerritory}
                modelFuel={modelFuel}
                files={files}
                uploadedFile={uploadedFile}
                selectedSheets={selectedSheets}
                uploadedProjectFiles={uploadedProjectFiles}
                projectTerritories={projectTerritories}
                projectFuels={projectFuels}
                importMode={importMode}
                formValidationWarnings={formValidationWarnings}
                modelNameExists={modelNameExists}
                displayInModal={props.displayInModal}
                isComponentModel={isComponentModel}
                setFiles={setFiles}
                setSelectedSheets={setSelectedSheets}
                onChange={onChange}
                onCancel={props.onCancel}
            />
        );
    } else {
        return (
            <Model
                viewIndex={props.viewIndex}
                idProject={idProject}
                idManager={project?.idManager}
                idInputLog={idInputLog}
                managerFullName={project?.managerFullName}
                members={project?.members}
                modelName={modelName}
                modelRequired={modelRequired}
                modelDescription={modelDescription}
                modelAnalyst={modelAnalyst}
                modelReviewer={modelReviewer}
                modelTerritory={modelTerritory}
                modelFuel={modelFuel}
                files={files}
                uploadedFile={uploadedFile}
                selectedSheets={selectedSheets}
                uploadedProjectFiles={uploadedProjectFiles}
                projectTerritories={projectTerritories}
                projectFuels={projectFuels}
                importMode={importMode}
                formValidationWarnings={formValidationWarnings}
                modelNameExists={modelNameExists}
                displayInModal={props.displayInModal}
                setFiles={setFiles}
                setSelectedSheets={setSelectedSheets}
                onChange={onChange}
                onCancel={props.onCancel}
            />
        );
    }
});

export default ImportData;
