import { get } from "lodash";
import { batch } from "react-redux";
import { toast } from "react-toastify";

import {
    POWERBI_DATASET_REFRESH_RECEIVED,
    POWERBI_DATASET_REFRESH_STATUS_REQUESTED,
    POWERBI_DATASET_REFRESH_STATUS_RECEIVED,
    HUB_DATASET_REFRESH_STATUS,
} from "store/actionTypes";
import { clearPowerBIReport } from "store/resources/actions/powerBI/powerBIActions";

import { workspaceId } from "utils/constants";

import { RefreshPowerBIDatasetParams, SetPowerBIDatasetRefreshStatusParams } from "store/powerBI/types";

export const getPowerBIDatasetRefreshStatus =
    ({ datasetId, metadata }: RefreshPowerBIDatasetParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        // Check if the request is already in progress
        const refreshStatus = get(getState(), `powerBiRefreshStatus[${workspaceId}-${datasetId}]`);

        if (refreshStatus?.isReading) {
            return;
        }

        batch(() => {
            dispatch({
                type: POWERBI_DATASET_REFRESH_STATUS_REQUESTED,
                id: `${workspaceId}-${datasetId}`,
                payload: {
                    metadata,
                },
            });

            dispatch({
                type: HUB_DATASET_REFRESH_STATUS,
                datasetId,
                workspaceId,
            });
        });
    };

export const setPowerBIDatasetRefreshStatus =
    ({ workspaceId, datasetId, status }: SetPowerBIDatasetRefreshStatusParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        dispatch({
            type: POWERBI_DATASET_REFRESH_STATUS_RECEIVED,
            id: `${workspaceId}-${datasetId}`,
            payload: {
                status,
            },
        });

        if (status === "Completed") {
            // Check if there is a refresh request in progress
            const refreshState = get(getState(), `powerBiRefreshStatus[${workspaceId}-${datasetId}]`);

            if (refreshState.metadata === "user") {
                batch(() => {
                    dispatch({
                        type: POWERBI_DATASET_REFRESH_RECEIVED,
                        id: `${workspaceId}-${datasetId}`,
                        payload: {
                            status,
                        },
                    });

                    // Reload report after refresh completion
                    dispatch(clearPowerBIReport());
                });

                toast.success("Report has been refreshed successfully");
            }
        }
    };
