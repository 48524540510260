import { useCallback } from "react";

import { UseEnterKeyParams } from "./types";

export const useEnterKey = ({ disabled, onClick }: UseEnterKeyParams) => {
    const onEnter = useCallback(
        (event: any) => {
            if (event.key === "Enter" && !disabled) {
                event.preventDefault();

                onClick?.(event);
            }
        },
        [disabled, onClick]
    );

    return [onEnter];
};
