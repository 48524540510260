import { isNil } from "lodash";
import { memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { useAllModuleRuns, updateRun } from "store/resources/actions/calculationWorkflow/moduleRunsActions";

import { IdsTabPanel } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import IconLoading from "components/ui/Icons/IconLoading";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";

import { openModalDialogNameResult } from "layouts/Modal/CalculationWorkflowModal/utils";
import { storageStatuses } from "layouts/Sidebar/CalculationWorkflowSidebar/utils";

import { actionAllowed } from "pages/utils";

import { useUserRights } from "utils/useUserRights";
import { d, getTimeDifferenceToString } from "utils/date";
import { toLocaleDateTime } from "utils/dateTime";
import { USER_ACTIONS } from "utils/user/defines";
import { openWindowSingleModelReport } from "utils/window";

import "./style.scss";

const Runs: React.NamedExoticComponent<{
    idClient: number;
    idProject: number;
    idModel: number;
    idInputLog: number;
    moduleId: number;
    studyCase: string;
    modelName: string;
    modelRequired: boolean | null;
    id: string;
    ariaLabelledBy: string;
    isActive?: boolean;
}> = memo(({ idClient, idProject, idModel, idInputLog, moduleId, studyCase, modelName, modelRequired, id, ariaLabelledBy, isActive }) => {
    const dispatch = useDispatch();

    const [allModuleRuns, isLoadingAllModuleRuns] = useAllModuleRuns({ moduleId });

    const userRights = useUserRights();

    const classNames = cn("flex-column fill-height with-scroll remove-background runs-tab", { "is-hidden": !isActive });

    const storageStatusItems = useMemo(() => storageStatuses.map((status, index) => ({ label: status, value: index + 1 })), []);

    const onStorageStatusChange = (value: number, resultsId: number) => {
        if (value === 1) {
            dispatch(
                openModalDialogNameResult({
                    onSave: (scenarioName: string) => {
                        dispatch(
                            updateRun({
                                idProject,
                                idModel,
                                moduleId,
                                resultsId,
                                storageStatus: value,
                                scenarioName,
                            })
                        );
                    },
                })
            );
        } else {
            dispatch(
                updateRun({
                    idProject,
                    idModel,
                    moduleId,
                    resultsId,
                    storageStatus: value,
                })
            );
        }
    };

    const onResultsClick = (resultsId: number, finished: string) => {
        dispatch(
            openWindowSingleModelReport({
                idClient,
                idProject,
                idInputLog,
                filterEntityId: resultsId,
                title: modelName,
                subTitle: `${studyCase.toUpperCase()} - ${toLocaleDateTime(finished + "Z")}`,
            })
        );
    };

    return (
        <IdsTabPanel customClasses={classNames} idValue={id} slot="panel" ariaLabelledBy={ariaLabelledBy}>
            {/* Note: allModuleRuns changes to null if no data present */}
            {allModuleRuns === undefined && isLoadingAllModuleRuns ? (
                <IconLoading />
            ) : (
                <div>
                    {allModuleRuns?.map(
                        (run, index) =>
                            run.status !== "IN_PROGRESS" && (
                                <div key={run.modelRunId} className="flex-row">
                                    <div className="flex-column align-center">
                                        <div className={cn("runs-tab__vertical-line", { "hide-line": index === 0 })} />
                                        <div className="runs-tab__circle" />
                                        <div
                                            className={cn("runs-tab__vertical-line", {
                                                "hide-line": index === allModuleRuns.length - 1,
                                            })}
                                        />
                                    </div>
                                    <div className="flex-row flex-one align-center justify-space-between runs-tab__run-details-container">
                                        <div className="flex-column">
                                            {run.scenarioName && <div className="runs-tab__scenario-name">{run.scenarioName}</div>}
                                            {!isNil(run.finished) && (
                                                <div className="runs-tab__scenario-name" title={`Run ID: ${run.modelRunId}`}>
                                                    {d.format(new Date(run.finished + "Z"))}
                                                </div>
                                            )}
                                            <div className="runs-tab__results-log">{`Results Log ID: ${run.resultsId}`}</div>
                                            {!isNil(run.finished) && (
                                                <div className="runs-tab__run-time">{`Run time: ${getTimeDifferenceToString(
                                                    run.started,
                                                    run.finished
                                                )}`}</div>
                                            )}
                                        </div>
                                        <div className="flex-row align-center runs-tab__run-actions-container">
                                            {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                                                <>
                                                    {run.status === "DONE" && (
                                                        <div className="runs-tab__storage-status-container">
                                                            <Dropdown
                                                                placeholder="No action selected"
                                                                value={run.storageStatus ?? undefined}
                                                                items={storageStatusItems}
                                                                onChange={(value) => onStorageStatusChange(value as number, run.resultsId)}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            <div>
                                                {run.status === "ERROR" && <ErrorMsg message="Run ended with an error" />}
                                                {run.status === "TERMINATED" && <ErrorMsg message="Run was terminated by user" />}
                                                {run.status === "DONE" && (
                                                    <Button
                                                        variant="secondary"
                                                        iconLeft="ui-chart_multiple_b_s"
                                                        iconSize="sm"
                                                        padding="md"
                                                        onClick={() => onResultsClick(run.resultsId, run.finished)}
                                                    >
                                                        Results
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
                </div>
            )}
        </IdsTabPanel>
    );
});

export default Runs;
