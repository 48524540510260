import { useMemo } from "react";
import { useDropzone as useReactDropzone } from "react-dropzone";

import { UseDropzoneParams } from "./types";
import { IndexSignature } from "types/types";

const ALL_ACCEPTED_FILE_TYPES: IndexSignature<string[]> = {
    "text/csv": [".csv"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "text/html": [".htm", ".html"],
    "application/pdf": [".pdf"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
    "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12": [".pptm"],
};

export const useDropzone = ({ options, accept }: UseDropzoneParams) => {
    const acceptedFileTypes = useMemo(() => {
        let acceptedFileTypes = {};

        if (accept) {
            for (const key in ALL_ACCEPTED_FILE_TYPES) {
                for (let index = 0; index < accept.length; index++) {
                    const extension = accept[index];

                    if (ALL_ACCEPTED_FILE_TYPES[key].includes(extension)) {
                        acceptedFileTypes = {
                            ...acceptedFileTypes,
                            [key]: ALL_ACCEPTED_FILE_TYPES[key],
                        };
                    }
                }
            }
        }

        return acceptedFileTypes;
    }, [accept]);

    return useReactDropzone({
        ...options,
        accept: accept ? acceptedFileTypes : options?.accept,
    });
};
