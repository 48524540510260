import "eventsource/example/eventsource-polyfill";
import { v1 as uuidv1 } from "uuid";
import { isEmpty, isNil } from "lodash";
import { batch } from "react-redux";

import {
    RUN_MODULE_V2_STARTED,
    RUN_MODULE_V2_RETRY,
    RUN_MODULE_V2_FAILED,
    RUN_MODULE_V2_COMPLETE,
    PAUSE_MODULE_V2_FAILED,
    PAUSE_MODULE_V2_COMPLETE,
} from "store/actionTypes";
import { modulesResourceName, moduleAllRunsResourceName, moduleLastRunResourceName } from "store/configureResources";

import { getResourcePromise } from "store/resources/actions/getResource";

import { createRunModule, createRunPendingModules, updatePauseModule } from "store/resources/actions/calculationWorkflow/runModuleActions";
import { clearModules } from "store/resources/actions/calculationWorkflow/modulesActions";
import { clearAllModuleRuns, clearLastModuleRun } from "store/resources/actions/calculationWorkflow/moduleRunsActions";
import { clearModuleInputs } from "store/resources/actions/calculationWorkflow/moduleInputsActions";
import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import { isDevEnvironment } from "utils/constants";

import { GetModuleRunStatusParams, SetupRunModuleEventSourceParams, RunModuleParams, PauseModuleParams } from "./types";
import { Module, ModuleRun, LastModuleRun, CreateRunPendingModulesResponse } from "store/resources/actions/calculationWorkflow/types";

let eventSource: EventSource = null;

const getModuleRunStatus = async ({ moduleId, runId }: GetModuleRunStatusParams) => {
    const allModuleRuns = (await getResourcePromise({
        resourceName: moduleAllRunsResourceName,
        key: `${moduleAllRunsResourceName}-${moduleId}`,
        path: {
            moduleId,
        },
    })) as ModuleRun[];

    const currentRun = allModuleRuns.find((moduleRun) => moduleRun.modelRunId === runId);

    return currentRun?.status;
};

export const setupRunModuleEventSource =
    ({ idProject, idModel, moduleId, runId, status, reconnect }: SetupRunModuleEventSourceParams) =>
    async (dispatch, getState) => {
        let clearLastRunResource = Boolean(reconnect);

        if (reconnect) {
            dispatch({
                type: RUN_MODULE_V2_RETRY,
                payload: {
                    idModel,
                    moduleId,
                },
            });
        }

        const user = getState().vdsmUser;

        const lastModuleRun = (await getResourcePromise({
            resourceName: moduleLastRunResourceName,
            key: `${moduleLastRunResourceName}-${moduleId}`,
            path: {
                moduleId,
            },
        })) as LastModuleRun[];

        const lastStep = lastModuleRun?.[lastModuleRun?.length - 1]?.step || "";

        batch(() => {
            dispatch(clearModules({ idModel }));
            dispatch(clearAllModuleRuns({ moduleId }));

            if (user && lastStep !== "") {
                const url = `${process.env.REACT_APP_MODULES_API_BASE_URL}events/${runId}/stepCompletions`;

                const eventSourceInitDict = {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`,
                        "Last-Event-ID": uuidv1(),
                    },
                };

                // @ts-ignore
                eventSource = new window.EventSourcePolyfill(url, eventSourceInitDict);

                if (!isNil(eventSource)) {
                    eventSource.onopen = () => {
                        if (isDevEnvironment) {
                            console.log("Connection opened at:", url);
                        }
                    };

                    eventSource.onerror = async (event) => {
                        if (isDevEnvironment) {
                            console.log("Connection:", url, "Error:", event);
                        }

                        const runStatus = await getModuleRunStatus({ moduleId, runId });

                        if (["ERROR", "TERMINATED", "DONE"].includes(runStatus)) {
                            if (isDevEnvironment) {
                                console.log("Connection closed:", url);
                            }

                            eventSource.close();

                            switch (runStatus) {
                                case "ERROR":
                                    dispatch({
                                        type: RUN_MODULE_V2_FAILED,
                                        payload: {
                                            idModel,
                                            moduleId,
                                            status: runStatus,
                                        },
                                    });

                                    break;

                                case "TERMINATED":
                                case "DONE":
                                    dispatch({
                                        type: RUN_MODULE_V2_COMPLETE,
                                        payload: {
                                            idModel,
                                            moduleId,
                                            status: runStatus,
                                        },
                                    });

                                    break;

                                default:
                            }

                            dispatch(clearProjectLogItems({ idProject }));
                            dispatch(clearModelLogItems({ idProject, idModel }));
                            dispatch(clearModules({ idModel }));
                            dispatch(clearModuleInputs({ moduleId }));
                            dispatch(clearLastModuleRun({ moduleId }));
                        }
                    };

                    eventSource.onmessage = async (event) => {
                        const parsedData = JSON.parse(event.data);

                        if (clearLastRunResource) {
                            // Clear resource only on first message received.
                            // This is needed otherwise there can be issues with
                            // displaying correct statuses on Calculations tab
                            // when Module Run is in progress already.
                            //
                            // NOTE: Only needed when user opens up Calculation
                            // Workflow when run is already in progress or
                            // on browser refresh
                            clearLastRunResource = false;

                            dispatch(clearLastModuleRun({ moduleId }));
                        }

                        if (parsedData.StepName === lastStep) {
                            if (isDevEnvironment) {
                                console.log("Connection closed:", url);
                            }

                            eventSource.close();

                            const runStatus = await getModuleRunStatus({ moduleId, runId });

                            dispatch({
                                type: RUN_MODULE_V2_COMPLETE,
                                payload: {
                                    idModel,
                                    moduleId,
                                    status: runStatus,
                                    completedStep: parsedData.StepName,
                                },
                            });

                            dispatch(clearProjectLogItems({ idProject }));
                            dispatch(clearModelLogItems({ idProject, idModel }));
                            dispatch(clearModuleInputs({ moduleId }));
                            dispatch(clearLastModuleRun({ moduleId }));

                            const modules = (await getResourcePromise({
                                resourceName: modulesResourceName,
                                key: `${modulesResourceName}-${idModel}`,
                                path: {
                                    entityId: idModel,
                                },
                            })) as Module[];

                            const nextRunningModule = modules?.find((m) => m.moduleId !== moduleId && m.runState === "RUNNING");

                            // When run for the current Module is complete,
                            // need to clear runs for the next Module,
                            // otherwise progress bar will not update.
                            //
                            // NOTE: Only needed when running all of the
                            // pending Modules
                            if (nextRunningModule !== undefined) {
                                dispatch(clearAllModuleRuns({ moduleId: nextRunningModule.moduleId }));
                            }
                        } else {
                            dispatch({
                                type: RUN_MODULE_V2_STARTED,
                                payload: {
                                    idModel,
                                    moduleId,
                                    status,
                                    completedStep: parsedData.StepName,
                                },
                            });
                        }
                    };
                }
            }
        });
    };

export const runModule =
    ({ idProject, idModel, moduleId, onStart, onError }: RunModuleParams) =>
    (dispatch) => {
        const onRunModuleSuccess = (action: any) => {
            const { id, status } = action?.data;

            onStart?.();

            dispatch(setupRunModuleEventSource({ idProject, idModel, moduleId, runId: Number(id), status }));
        };

        dispatch(
            createRunModule({
                moduleId,
                onSuccess: onRunModuleSuccess,
                onError,
            })
        );
    };

export const runPendingModules =
    ({ idProject, idModel, runList, onStart, onError }) =>
    (dispatch) => {
        const onRunPendingModulesSuccess = (action: any) => {
            const response: CreateRunPendingModulesResponse = action?.data?.[0] || {};

            onStart?.();

            if (!isEmpty(response)) {
                // Set one Event Source at a time
                // because Modules in workflow are
                // running asynchronous
                dispatch(
                    setupRunModuleEventSource({
                        idProject,
                        idModel,
                        moduleId: Number(response.modelId),
                        runId: Number(response.id),
                        status: response.status,
                    })
                );
            }
        };

        dispatch(
            createRunPendingModules({
                idModel,
                runList,
                onSuccess: onRunPendingModulesSuccess,
                onError,
            })
        );
    };

export const pauseModule =
    ({ idProject, idModel, moduleId, resultsId }: PauseModuleParams) =>
    (dispatch) => {
        const onPauseModuleError = () => {
            if (!isNil(eventSource)) {
                if (isDevEnvironment) {
                    console.log("Connection closed:", eventSource.url);
                }

                eventSource.close();
            }

            dispatch({
                type: PAUSE_MODULE_V2_FAILED,
                payload: {
                    idModel,
                    moduleId,
                },
            });
        };

        const onPauseModuleSuccess = () => {
            if (!isNil(eventSource)) {
                if (isDevEnvironment) {
                    console.log("Connection closed:", eventSource.url);
                }

                eventSource.close();
            }

            dispatch({
                type: PAUSE_MODULE_V2_COMPLETE,
                payload: {
                    idProject,
                    idModel,
                    moduleId,
                },
            });
        };

        dispatch(
            updatePauseModule({
                moduleId,
                resultsId,
                onError: onPauseModuleError,
                onSuccess: onPauseModuleSuccess,
            })
        );
    };
