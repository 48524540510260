import { memo } from "react";
import cn from "classnames";

import { IdsTabPanel } from "@emergn-infinity/ids-react";

import Separator from "components/ui/Separator";

import StudyCase from "./StudyCase";
import VariablesConfiguration from "./VariablesConfiguration";

import { ConfigurationsProps } from "./types";

const Configurations = memo(
    ({ idProject, idModel, idInputLog, moduleId, studyCaseId, type, modelRequired, id, ariaLabelledBy, isActive }: ConfigurationsProps) => {
        const classNames = cn("flex-column fill-height with-scroll remove-background", { "is-hidden": !isActive });

        return (
            <IdsTabPanel customClasses={classNames} idValue={id} slot="panel" ariaLabelledBy={ariaLabelledBy}>
                <StudyCase
                    idProject={idProject}
                    idModel={idModel}
                    idInputLog={idInputLog}
                    moduleId={moduleId}
                    studyCaseId={studyCaseId}
                    type={type}
                    modelRequired={modelRequired}
                />
                <Separator line />
                <VariablesConfiguration
                    idProject={idProject}
                    idModel={idModel}
                    idInputLog={idInputLog}
                    moduleId={moduleId}
                    modelRequired={modelRequired}
                />
            </IdsTabPanel>
        );
    }
);

export default Configurations;
