///
/// Help Tips actions
/// resourceName: helpTipsResourceName
///
import { isNil } from "lodash";

import { helpTipsResourceName } from "store/configureResources";

import { clearResource } from "store/resources/actions/clearResource";
import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource } from "store/resources/actions/updateResource";

import { getResourceState } from "store/utils";

import {
    HelpTip,
    HelpTipsResponseModel,
    ClearHelpTipParams,
    UseHelpTipParams,
    CreateHelpTipParams,
    UpdateHelpTipParams,
    GetResourceParams,
} from "./types";

const clearHelpTip =
    ({ pageId }: ClearHelpTipParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: helpTipsResourceName,
                key: `${helpTipsResourceName}-${pageId}`,
                broadcast: true,
            })
        );

const getResourceParams = ({ id, pageId, title, content, updateItems, onComplete, dispatch }: GetResourceParams) => ({
    resourceName: helpTipsResourceName,
    key: `${helpTipsResourceName}-${pageId}`,
    body: {
        id,
        pageId,
        name: title,
        content,
    },
    updateFromResponse: false,
    showSuccessNotification: false,
    onComplete: () => {
        dispatch(clearHelpTip({ pageId }));

        onComplete && onComplete();
    },
    optimisticUpdate: updateItems?.length
        ? {
              value: {
                  model: updateItems,
              },
          }
        : undefined,
});

export const useHelpTip = ({ pageId }: UseHelpTipParams) =>
    useResource({
        resourceName: helpTipsResourceName,
        key: isNil(pageId) ? undefined : `${helpTipsResourceName}-${pageId}`, // Do not make the request if pageId not provided
        query: {
            pageId,
        },
        transform: (data: HelpTipsResponseModel) => data?.model[0], // array will always consist of one item
    }) as [HelpTip | undefined, boolean | undefined, any];

export const createHelpTip =
    ({ pageId, title, content, onComplete }: CreateHelpTipParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            createResource(
                getResourceParams({
                    pageId,
                    title,
                    content,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const updateHelpTip =
    ({ id, pageId, title, content, onComplete }: UpdateHelpTipParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const helpTips = getResourceState<HelpTip>(state, helpTipsResourceName, { pageId });

        const updateItems = helpTips.map((tip) =>
            tip.id === id
                ? {
                      ...tip,
                      name: title,
                      content,
                  }
                : tip
        );

        dispatch(
            updateResource(
                getResourceParams({
                    id,
                    pageId,
                    title,
                    content,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };
