import { memo } from "react";

import UtilityForecast from "./UtilityForecast";

import DashboardPanel from "components/ui/Dashboard";

import { UTILITY_FORECAST } from "utils/constants";

import { UtilityForecastProps } from "./types";

const UtilityForecastPanel = memo(({ idClient, idProject, idModel, idInputLog }: UtilityForecastProps) => {
    return (
        <DashboardPanel className="utility-forecast-panel" title="Baseline Utility Forecast Comparison" pageId={UTILITY_FORECAST}>
            <UtilityForecast idClient={idClient} idProject={idProject} idModel={idModel} idInputLog={idInputLog} />
        </DashboardPanel>
    );
});
export default UtilityForecastPanel;
