import { useMemo } from "react";
import { useSelector } from "react-redux";

import { UseViewsParams } from "./types";

export const useViews = ({ containerName }: UseViewsParams) => {
    // @ts-ignore
    const viewsContainer = useSelector((state) => state.window[containerName]);

    return useMemo(() => viewsContainer?.views || [], [viewsContainer]);
};
