import { uniqBy } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import ProgressBar from "react-customizable-progressbar";

import Dropdown from "components/ui/Dropdown";
import IconWrap from "components/ui/Icons";
import IconPreparing from "components/ui/Icons/IconPreparing";
import Input from "components/ui/Input";
import DatePicker from "components/ui/Input/DatePicker";
import DatePickerContainer from "components/ui/Input/DatePicker/DatePickerContainer";
import CustomList from "components/ui/List/CustomList";

import { DATEPICKER_DATE_FORMAT } from "utils/date";
import { toLocaleDateTime } from "utils/dateTime";
import { formatBytes } from "utils/string";

import { FileType } from "components/ui/FileNavigator/types";
import { FileListProps } from "./types";

import "./style.scss";

const DIRECTORY_HEADERS = {
    folderName: "Folder name",
    lastModified: "Last modified",
};

const FILE_HEADERS = {
    name: "File name",
    type: "Type",
    creationDate: "Date",
    extension: "Extension",
    size: "Size",
    actions: {
        label: "",
        sortable: false,
    },
};

const FileList = ({ files, folderChain, onDirectoryClick, onDownload, onDelete }: FileListProps) => {
    // @ts-ignore
    const downloadResults = useSelector((state) => state.downloadFile);

    const directoriesOnly = files.every((file: any) => file.isDir);

    const fileTypeItems = useMemo(() => {
        let fileTypeItems: any[] = [];

        if (!directoriesOnly) {
            const fileTypes = files.map((file) => ({ label: file?.type, value: file?.type }));

            fileTypeItems = [
                {
                    value: "",
                    label: "All",
                },
                ...uniqBy(fileTypes, "value"),
            ];
        }

        return fileTypeItems;
    }, [files, directoriesOnly]);

    const fileExtensionItems = useMemo(() => {
        let fileExtensionItems: any[] = [];

        if (!directoriesOnly) {
            const fileExtensions = files.map((file) => ({ label: file?.extension?.substring(1).toUpperCase(), value: file?.extension }));

            fileExtensionItems = [
                {
                    value: "",
                    label: "All",
                },
                ...uniqBy(fileExtensions, "value"),
            ];
        }

        return fileExtensionItems;
    }, [files, directoriesOnly]);

    const renderSearch = (filters: any, updateFilter: any, updateFilterValue: any) => {
        return (
            <div className="list-search-headers-row">
                <div className="column-filter column-name filter left">
                    <Input
                        placeholder="Search"
                        icon="search"
                        inputTableFilter
                        value={filters.name}
                        onChange={(event) => updateFilter("name")(event)}
                    />
                </div>
                <div className="column-filter column-type filter">
                    <Dropdown
                        placeholder="Filter"
                        value={filters.type || ""}
                        items={fileTypeItems}
                        inputTableFilter
                        onChange={(value: any) => updateFilterValue("type")(value)}
                    />
                </div>
                <div className="column-filter column-creation-date filter">
                    <DatePicker
                        // @ts-ignore
                        popperContainer={DatePickerContainer}
                        placeholder={DATEPICKER_DATE_FORMAT.toUpperCase()}
                        value={filters.creationDate}
                        inputTableFilter
                        onChange={(data: any) => updateFilterValue("creationDate")(data?.value || "")}
                    />
                </div>
                <div className="column-filter column-extension filter right">
                    <Dropdown
                        placeholder="Filter"
                        value={filters.extension || ""}
                        items={fileExtensionItems}
                        inputTableFilter
                        onChange={(value) => updateFilterValue("extension")(value)}
                    />
                </div>
                <div className="column-filter column-size"></div>
                <div className="column-filter column-actions"></div>
            </div>
        );
    };

    const renderItem = (item: FileType) => {
        const downloadStatus = downloadResults?.[item.id];
        const completed = downloadStatus ? downloadStatus.completed : true;

        return (
            <div className="list-item-row clickable">
                {directoriesOnly ? (
                    <>
                        <div className="flex-row align-center item-value column-folder-name" onClick={() => onDirectoryClick(item)}>
                            <IconWrap icon="folder-open-empty" clickable />
                            <span className="file-list__folder-name">{item.name}</span>
                        </div>
                        <div className="item-value column-last-modified">{toLocaleDateTime(`${item.lastModified}Z`)}</div>
                    </>
                ) : (
                    <>
                        <div className="item-value column-name">{item.name}</div>
                        <div className="item-value column-type">{item.type}</div>
                        <div className="item-value column-creation-date">{toLocaleDateTime(`${item.creationDate}Z`)}</div>
                        <div className="item-value column-extension">{item.extension?.substring(1).toUpperCase()}</div>
                        <div className="item-value column-size">{formatBytes(item.size || 0)}</div>
                        {/* TODO: Add this specialized icon to UI folder */}
                        <div className="item-value column-actions">
                            <div className="flex-row align-center">
                                {onDownload && (
                                    <>
                                        {downloadStatus?.error && (
                                            <IconWrap icon="warning-report-problem-empty" title="Download failed, please try again" error />
                                        )}
                                        {completed && !downloadStatus?.error ? (
                                            <IconWrap
                                                icon="download"
                                                onClick={() =>
                                                    onDownload({
                                                        folderChain,
                                                        id: item.id,
                                                        name: item.name,
                                                        extension: item.extension,
                                                        type: item.type,
                                                    })
                                                }
                                            />
                                        ) : downloadStatus?.receivedLength ? (
                                            <ProgressBar
                                                className="indicator"
                                                radius={8}
                                                progress={((downloadStatus.receivedLength || 0) * 100) / downloadStatus.contentLength}
                                                fillColor="transparent"
                                                strokeWidth={2}
                                                strokeColor="#5d9cec"
                                                strokeLinecap="square"
                                                trackStrokeWidth={2}
                                                pointerStrokeWidth={0}
                                            />
                                        ) : (
                                            <IconPreparing />
                                        )}
                                    </>
                                )}
                                {onDelete && (
                                    <>
                                        <IconWrap
                                            icon="delete-trash-empty"
                                            onClick={() =>
                                                onDelete({
                                                    folderChain,
                                                    id: item.id,
                                                    name: item.name,
                                                    extension: item.extension,
                                                    type: item.type,
                                                })
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };

    return (
        <div className="file-list">
            <CustomList
                // @ts-ignore
                headers={directoriesOnly ? DIRECTORY_HEADERS : FILE_HEADERS}
                items={files}
                filterByHeaders={!directoriesOnly}
                renderSearch={!directoriesOnly ? renderSearch : undefined}
                renderItem={renderItem}
            />
        </div>
    );
};

export default FileList;
