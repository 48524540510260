import { isEmpty } from "lodash";
import { memo, useCallback, useState } from "react";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import Separator from "components/ui/Separator";
import TextArea from "components/ui/TextArea";

import { ClientApprovementModalProps } from "layouts/Modal/ModelDashboardModal/types";

const ClientApprovement = memo(({ modelName, modelStatus, onChange, onCancel }: ClientApprovementModalProps) => {
    const [note, setNote] = useState("");

    const onNoteChange = useCallback((event) => {
        setNote(event.target.value);
    }, []);

    const onSetClick = useCallback(() => {
        onChange(note);

        onCancel?.();
    }, [note, onChange, onCancel]);

    return (
        <>
            <div className="flex-column modal-padding">
                <div>
                    Updating the status of <strong>{modelName}</strong> to '{modelStatus}' will trigger a notification to the manager.
                </div>
                {modelStatus === "Unapproved" && (
                    <>
                        <Separator />
                        <div>Please, include a note detailing any questions or adjustments needed.</div>
                    </>
                )}
                <Separator line />
                <div>
                    <TextArea
                        label="Note"
                        placeholder="Write a note"
                        value={note}
                        required={modelStatus === "Unapproved"}
                        optional={modelStatus === "Approved"}
                        onChange={onNoteChange}
                    />
                </div>
            </div>
            <ButtonGroup className="justify-end modal-actions">
                {modelStatus === "Unapproved" ? (
                    !isEmpty(note) && (
                        <Button primary onClick={onSetClick}>
                            Set to {modelStatus}
                        </Button>
                    )
                ) : (
                    <Button primary onClick={onSetClick}>
                        Set to {modelStatus}
                    </Button>
                )}
                <Button onClick={onCancel}>Cancel</Button>
            </ButtonGroup>
        </>
    );
});

export default ClientApprovement;
