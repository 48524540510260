import {
    RUN_ALL_MODULES_START,
    RUN_ALL_MODULES_STARTED,
    RUN_MODULE_START,
    RUN_MODULE_STARTED,
    RUN_MODULE_FAILED,
    RUN_MODULE_COMPLETE,
    PAUSE_MODULE_START,
    PAUSE_MODULE_STARTED,
    PAUSE_MODULE_FAILED,
    PAUSE_MODULE_COMPLETE,
} from "store/actionTypes";

import { RunModuleAction } from "./types";

const initialState = {};

export const reducer = (state = initialState, action: RunModuleAction) => {
    switch (action.type) {
        case RUN_ALL_MODULES_START:
            {
                const { idModel, runList } = action.payload;

                const started = runList.map(({ moduleId }) => ({
                    [`${idModel}-${moduleId}`]: RUN_MODULE_START,
                }));

                state = {
                    ...state,
                    ...started,
                };
            }

            break;

        case RUN_ALL_MODULES_STARTED:
            {
                const { idModel, runList } = action.payload;

                const started = runList.map(({ moduleId }) => ({
                    [`${idModel}-${moduleId}`]: "IN_PROGRESS",
                }));

                state = {
                    ...state,
                    ...started,
                };
            }

            break;

        case RUN_MODULE_START:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: RUN_MODULE_START,
                };
            }

            break;

        case RUN_MODULE_STARTED:
        case RUN_MODULE_FAILED:
        case RUN_MODULE_COMPLETE:
            {
                const { idModel, moduleId, status } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: status.toUpperCase(),
                };
            }

            break;

        case PAUSE_MODULE_START:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: PAUSE_MODULE_START,
                };
            }

            break;

        case PAUSE_MODULE_STARTED:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: PAUSE_MODULE_STARTED,
                };
            }

            break;

        case PAUSE_MODULE_FAILED:
        case PAUSE_MODULE_COMPLETE:
            {
                const { idModel, moduleId } = action.payload;

                state = {
                    ...state,
                    [`${idModel}-${moduleId}`]: "PAUSED",
                };
            }

            break;

        default:
    }

    return state;
};
