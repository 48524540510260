import { memo } from "react";

import "./style.scss";

/**
 * Home Dashboard view for users that don't have
 * any roles assigned to them.
 */
const HomeDashboardNoRole = memo(() => {
    return <div className="home-dashboard-no-role">This user doesn't have any user role set. Please contact support.</div>;
});

export default HomeDashboardNoRole;
