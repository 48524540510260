import {
    WINDOW_CONTAINER_ADD,
    WINDOW_CONTAINER_REMOVE,
    WINDOW_ADD,
    WINDOW_UPDATE,
    WINDOW_REMOVE,
    WINDOW_ACTIVATE,
} from "store/actionTypes";

import { getWindowState } from "utils/window";

const initialWindow = {
    views: [],
    previousActive: null,
};

export const reducer = (state, action) => {
    state = state || getWindowState();

    switch (action.type) {
        case WINDOW_CONTAINER_ADD:
            state = {
                ...state,
                [action.window]: initialWindow,
            };

            break;

        case WINDOW_CONTAINER_REMOVE:
            const { [action.window]: _remove, ...updatedState } = state;

            state = {
                ...updatedState,
            };

            break;

        case WINDOW_ADD:
        case WINDOW_UPDATE:
        case WINDOW_REMOVE:
            state = {
                ...state,
                [action.container]: {
                    ...state[action.container],
                    views: action.views,
                },
            };

            break;

        case WINDOW_ACTIVATE:
            state = {
                ...state,
                [action.container]: {
                    ...state[action.container],
                    views: action.views,
                    previousActive: action.previousActive,
                },
            };

            break;

        default:
            break;
    }

    return state;
};
