import { memo } from "react";

import DashboardPanel from "components/ui/Dashboard";

import CalculatedResults from "./CalculatedResults";

import { CALCULATED_RESULTS } from "utils/constants";

import { CalculatedResultsProps } from "./types";

const CalculatedResultsPanel = memo(({ idClient, idProject, idModel, idInputLog, componentModels }: CalculatedResultsProps) => {
    return (
        <DashboardPanel className="calculated-results-panel" title="Most Recent Calculated Results" pageId={CALCULATED_RESULTS}>
            <CalculatedResults
                idClient={idClient}
                idProject={idProject}
                idModel={idModel}
                idInputLog={idInputLog}
                componentModels={componentModels}
            />
        </DashboardPanel>
    );
});

export default CalculatedResultsPanel;
