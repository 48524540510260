import { memo } from "react";

import Button from "components/ui/ButtonNew";

import { StudyCasePanelHeaderProps } from "./types";

const StudyCasePanelHeader = memo((props: StudyCasePanelHeaderProps) => {
    const { editing, readOnly, onChangeClick } = props;

    return !editing && !readOnly ? (
        <Button variant="tertiary" padding="md" iconLeft="ui-pencil__edit__create_b_s" iconSize="sm" onClick={onChangeClick}>
            Change Study Case Goals
        </Button>
    ) : null;
});

export default StudyCasePanelHeader;
