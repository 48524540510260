import { unionWith, isEqual } from "lodash";

import * as actionTypes from "store/actionTypes";
import { getResourceKey } from "store/utils";

export const resourceReducer = (state, action) => {
    const resourceKey = getResourceKey(action.passThroughData);

    switch (action.type) {
        case actionTypes.API_CRUD_READ_LIST_REQUEST:
            state = {
                ...state,
                isReadingList: true,
                isError: false,
                message: null,
            };

            break;

        case actionTypes.API_CRUD_READ_LIST_SUCCESS:
            state = {
                ...state,
                isReadingList: false,
                items: unionWith(state.items, action.data, isEqual), // Combine unique items
            };

            break;

        case actionTypes.API_CRUD_READ_LIST_ERROR:
            state = {
                ...state,
                isReadingList: false,
                isError: true,
                message: action.message,
            };

            break;

        case actionTypes.API_CRUD_READ_REQUEST:
            if (state?.itemsById[resourceKey]?.isReading) {
                return state;
            }

            let newState = {
                ...state,
                isReading: true,
                isError: false,
                message: null,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        ...state?.itemsById[resourceKey],
                        isReading: true,
                    },
                },
            };

            newState?.itemsById[resourceKey]?.needUpdate && delete newState.itemsById[resourceKey].needUpdate;
            state = newState;

            break;

        case actionTypes.API_CRUD_READ_SUCCESS:
            let newData = action.data;

            // If resource has data property
            if (state?.itemsById?.[resourceKey]?.data) {
                // If data property is an array
                // and there are items in array
                // that have "persist" property,
                // do not overwrite those items
                //
                // Note: By default items will not
                // have "persist" property
                if (
                    Array.isArray(state.itemsById[resourceKey].data) &&
                    state.itemsById[resourceKey].data.some((item) => item.persist === true)
                ) {
                    const persistData = state.itemsById[resourceKey].data.filter((item) => item.persist === true);

                    newData = [...persistData, ...newData];
                }
            }

            state = {
                ...state,
                isReading: false,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        data: newData,
                    },
                },
            };

            break;

        case actionTypes.API_CRUD_READ_ERROR:
            const { [resourceKey]: _remove, ...itemsById } = state.itemsById;

            state = {
                ...state,
                isReading: false,
                itemsById,
                isError: true,
                message: action.message,
            };

            break;

        case actionTypes.API_CRUD_CREATE_REQUEST:
            state = {
                ...state,
                isCreating: true,
                isError: false,
                message: null,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        ...state?.itemsById[resourceKey],
                        isCreating: true,
                    },
                },
            };

            break;

        case actionTypes.API_CRUD_CREATE_SUCCESS:
            state = {
                ...state,
                isCreating: false,
                isError: false,
                message: action.data.responseMessage,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        ...state?.itemsById[resourceKey],
                        data: action.passThroughData.updateFromResponse ? action.data : state?.itemsById?.[resourceKey]?.data,
                        isCreating: false,
                    },
                },
            };

            break;

        case actionTypes.API_CRUD_CREATE_PROGRESS:
            state = {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [`${resourceKey}-progress`]: { percent: action.data.percent },
                },
            };

            break;

        case actionTypes.API_CRUD_CREATE_ERROR:
            if (resourceKey) {
                // Clear optimistic update values
                const { [resourceKey]: _remove, ...itemsById } = state.itemsById;

                state = {
                    ...state,
                    isCreating: false,
                    itemsById,
                    isError: true,
                    message: action.message,
                };
            } else {
                state = {
                    ...state,
                    isCreating: false,
                    isError: true,
                    message: action.message,
                };
            }

            break;

        case actionTypes.API_CRUD_UPDATE_REQUEST:
            state = {
                ...state,
                isUpdating: true,
                isError: false,
                message: null,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        ...state?.itemsById[resourceKey],
                        isUpdating: true,
                    },
                },
            };

            break;

        case actionTypes.API_CRUD_UPDATE_SUCCESS:
            state = {
                ...state,
                isUpdating: false,
                message: action.data.responseMessage,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        ...state?.itemsById[resourceKey],
                        data: action.passThroughData.updateFromResponse ? action.data : state?.itemsById?.[resourceKey]?.data,
                        isUpdating: false,
                    },
                },
            };

            break;

        case actionTypes.API_CRUD_UPDATE_ERROR:
            if (resourceKey) {
                // Clear optimistic update values
                const { [resourceKey]: _remove, ...itemsById } = state.itemsById;

                state = {
                    ...state,
                    isUpdating: false,
                    itemsById,
                    isError: true,
                    message: action.message,
                };
            } else {
                state = {
                    ...state,
                    isUpdating: false,
                    isError: true,
                    message: action.message,
                };
            }

            break;

        case actionTypes.API_CRUD_DELETE_REQUEST:
            state = {
                ...state,
                isDeleting: true,
                isError: false,
                message: null,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        ...state?.itemsById[resourceKey],
                        isDeleting: true,
                    },
                },
            };

            break;

        case actionTypes.API_CRUD_DELETE_SUCCESS:
            state = {
                ...state,
                isDeleting: false,
                isError: false,
                message: action.data.responseMessage,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        ...state?.itemsById[resourceKey],
                        data: action.passThroughData.updateFromResponse ? action.data : state?.itemsById?.[resourceKey]?.data,
                        isDeleting: true,
                    },
                },
            };

            break;

        case actionTypes.API_CRUD_DELETE_ERROR:
            state = {
                ...state,
                isDeleting: false,
                isError: true,
                message: action.message,
            };

            break;

        case actionTypes.API_CRUD_OPTIMISTIC_UPDATE_ITEM:
            state = {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: {
                        ...state.itemsById[resourceKey],
                        data: action.value,
                    },
                },
            };

            break;

        case actionTypes.API_CRUD_DELETE_ITEM:
            const newItemsById = { ...state.itemsById };

            delete newItemsById[action.key];

            state = {
                ...state,
                itemsById: newItemsById,
            };

            break;
        case actionTypes.API_CRUD_NEED_UPDATE:
            if (action.key instanceof RegExp) {
                state = Object.assign({}, state, {
                    itemsById: Object.keys(state.itemsById).reduce((result, key) => {
                        if (key.match(action.key)) {
                            result[key] = { ...state.itemsById[key], needUpdate: true };
                        } else {
                            result[key] = { ...state.itemsById[key] };
                        }
                        return result;
                    }, {}),
                });
            } else {
                state = {
                    ...state,
                    itemsById: {
                        ...state.itemsById,
                        [action.key]: {
                            ...state.itemsById[action.key],
                            needUpdate: true,
                            updatedBy: action.passThroughData?.userNumber,
                        },
                    },
                };
            }

            break;

        case actionTypes.API_CRUD_WAIT_READ:
            state = {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.key]: {
                        ...state.itemsById[action.key],
                        waitRead: {
                            isReading: true,
                            actionName: action.actionName,
                        },
                    },
                },
            };

            break;

        default:
            break;
    }

    return state;
};
