import { memo } from "react";

import ReportLog from "./ReportLog";

import DashboardPanel from "components/ui/Dashboard";

import { STANDALONE_REPORT_REPORT_LOG } from "utils/constants";

import { ReportLogProps } from "./types";

const ReportLogPanel = memo(({ idClient, idProject, idReport }: ReportLogProps) => {
    return (
        <DashboardPanel className="report-log-panel" title="Report Log" pageId={STANDALONE_REPORT_REPORT_LOG}>
            <ReportLog idClient={idClient} idProject={idProject} idReport={idReport} />
        </DashboardPanel>
    );
});

export default ReportLogPanel;
