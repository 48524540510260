import * as React from "react";
import ClassNames from "classnames";

import IconWrap from "components/ui/Icons";

import "./style.scss";

const InfoPanel = (props: InfoPanelProps) => {
    const classNames = ClassNames("flex-row align-start info-panel", props.className, {
        error: props.error,
        success: props.success,
        warning: props.warning,
    });

    return (
        <div className={classNames}>
            <div className="flex-one">{props.children}</div>
            {props.onClose && (
                <div>
                    <IconWrap icon="clear-close" title="Close" onClick={props.onClose} />
                </div>
            )}
        </div>
    );
};

interface InfoPanelProps {
    className?: string;

    /**
     * Error style for Info Panel.
     */
    error?: boolean;

    /**
     * Success style for Info Panel.
     */
    success?: boolean;

    /**
     * Warning style for Info Panel.
     */
    warning?: boolean;

    children: React.ReactNode;

    onClose?: React.MouseEventHandler<HTMLDivElement>;
}

export default InfoPanel;
