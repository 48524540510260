import { isEmpty } from "lodash";
import { memo, useMemo } from "react";

import { useModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import { IconSingleModelReport } from "components/ui/Icons/IconReport";
import IconLoading from "components/ui/Icons/IconLoading";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import { parseCalculationResult } from "utils/useCalculationResult";
import { logActionKeys } from "utils/scenarioLog";

import { CalculatedResultsProps } from "pages/ManageProject/ProjectionsContent/ModelDashboard/DashboardDefault/CalculatedResultsPanel/types";
import { ProjectInput } from "store/resources/actions/projectInput/types";

import "./style.scss";

const d = new Intl.DateTimeFormat("en-US", {
    dateStyle: "long",
    timeStyle: "short",
});

const CalculatedResults = memo(({ idClient, idProject, idInputLog, projectInputs }: CalculatedResultsProps) => {
    const [modelLogItems, isLoadingModelLogItems] = useModelLogItems({ idProject, idInputLog });

    const resultItems = useMemo(
        () =>
            modelLogItems
                ?.filter((item: any) => {
                    const results = parseInt(item.results);

                    return item.actionName === logActionKeys.RUN_CALCULATION && !isNaN(results) && results > 0;
                })
                .map(parseCalculationResult)
                .map((item: any) => ({
                    ...item,
                    date: d.format(new Date(item.finished + "Z")),
                })),
        [modelLogItems]
    );

    if (isLoadingModelLogItems) {
        return <IconLoading />;
    }

    return isEmpty(resultItems) ? (
        <div className="flex flex-center">
            <NothingFoundBlock icon="ui-chart_multiple_b_s" title="There are no calculated results" />
        </div>
    ) : (
        <div className="flex-row">
            {resultItems.slice(0, 3).map((item: any) => {
                const model = projectInputs.find((m: ProjectInput) => m.idInputLog === item.idInputLog);

                return (
                    <div key={`result-${item.results}`} className="flex-column result-container">
                        <div className="result-title">
                            <IconSingleModelReport
                                className="flex-row-reverse justify-space-between"
                                withLabel
                                idClient={idClient}
                                idProject={idProject}
                                idInputLog={idInputLog}
                                title={model?.name || ""}
                                subTitle={`${item.isPotential ? "Potential" : "Baseline"} result`}
                                calculationResult={{
                                    results: item.results,
                                    isPotential: true,
                                    started: item.started,
                                    finished: item.finished,
                                }}
                            />
                        </div>
                        <div className="result-date">{item.date}</div>
                    </div>
                );
            })}
        </div>
    );
});

export default CalculatedResults;
