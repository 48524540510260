import * as React from "react";
import ClassNames from "classnames";

import IconSvg from "./IconSvg";

import { icons } from "utils/icons";

import "./style.scss";

const IconWrap = (props: IconWrapProps) => {
    const isClickable = (props.clickable || Boolean(props.onClick)) && !props.disabled;

    const svgIcon = icons.find((icon) => icon.name === props.icon);

    const classNames = ClassNames("icon-wrap text-center with-icon", props.icon, props.className, {
        disabled: props.disabled,
        "icon-small": props.iconSmall,
        "icon-medium": props.iconMedium,
        "icon-big": props.iconBig,
        clickable: isClickable,
        active: props.active,
        error: props.error,
        success: props.success,
        warning: props.warning,
        "without-background": props.noBackground,
        inverse: props.inverse,
        secondary: props.secondary,
    });

    if (svgIcon) {
        return (
            <IconSvg
                viewBox={svgIcon.viewBox}
                path={svgIcon.path}
                color={props.color}
                title={props.title}
                disabled={props.disabled}
                iconSmall={props.iconSmall}
                iconMedium={props.iconMedium}
                iconBig={props.iconBig}
                clickable={isClickable}
                icon={svgIcon.name}
                onClick={props.onClick}
            />
        );
    }

    return (
        <div
            data-testid={props.dataTestId}
            className={classNames}
            title={props.title}
            onClick={props.disabled ? undefined : props.onClick}
        />
    );
};

interface IconWrapProps {
    dataTestId?: string;

    className?: string;

    title?: string;

    disabled?: boolean;

    /**
     * Display small size icon.
     */
    iconSmall?: boolean;

    /**
     * Display medium size icon.
     */
    iconMedium?: boolean;

    /**
     * Display large size icon.
     */
    iconBig?: boolean;

    /**
     * Display icon as clickable.
     */
    clickable?: boolean;

    /**
     * Display icon with hover effect always.
     */
    active?: boolean;

    /**
     * Display icon in error style.
     */
    error?: boolean;

    /**
     * Display icon in success style.
     */
    success?: boolean;

    /**
     * Display icon in warning style.
     */
    warning?: boolean;

    /**
     * Display icon without a background.
     */
    noBackground?: boolean;

    /**
     * Display icon colors inversely.
     */
    inverse?: boolean;

    /**
     * Display icon in secondary theme colors.
     */
    secondary?: boolean;

    /**
     * Color for SVG icon.
     */
    color?: string;

    /**
     * Name of the icon.
     */
    icon: string;

    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default IconWrap;
