///
/// Project Study Case actions
/// resourceName: studyCaseProjectResourceName
///
import { batch } from "react-redux";

import { studyCaseProjectResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";

import { clearModelStudyCase } from "./studyCaseModelActions";
import { clearCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";
import { clearProjectLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { clearModelInputsProgress, clearAllModelInputsProgresses } from "store/resources/actions/modelInputs/modelInputsProgressActions";

import { IndexSignature } from "types/types";
import { StudyCaseResponseModel } from "./types";

const getResourceParams = ({ idProject, updateItems, onComplete, dispatch }: GetResourceParams) => ({
    resourceName: studyCaseProjectResourceName,
    key: `${studyCaseProjectResourceName}-${idProject}`,
    path: idProject ? { idProject } : undefined,
    body: {
        parameters: updateItems,
    },
    showSuccessNotification: false,
    updateFromResponse: true,
    onComplete: () => {
        batch(() => {
            dispatch(clearProjectStudyCase({ idProject }));
            dispatch(clearProjectLogItems({ idProject }));
            dispatch(clearModelStudyCase({ idProject }));
            dispatch(clearCalculationPreconditions({ idProject }));
            dispatch(clearAllModelInputsProgresses({ idProject }));
            dispatch(clearModelInputsProgress({ idProject }));
        });

        onComplete?.();
    },
});

export const useProjectStudyCase = ({ idProject }: ProjectStudyCaseParams) =>
    useResource({
        resourceName: studyCaseProjectResourceName,
        key: `${studyCaseProjectResourceName}-${idProject}`,
        path: idProject ? { idProject } : { idProject: -1 },
        transform: (data: StudyCaseResponseModel) => data?.model || [],
    });

export const clearProjectStudyCase =
    ({ idProject }: ProjectStudyCaseParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: studyCaseProjectResourceName,
                key: `${studyCaseProjectResourceName}-${idProject}`,
                broadcast: true,
            })
        );

export const updateProjectStudyCase =
    ({ idProject, values, onComplete }: UpdateProjectStudyCaseParams) =>
    // @ts-ignore
    (dispatch) => {
        const parameters =
            values.ECONOMIC_TEST === undefined
                ? values
                : {
                      ...values,
                      ECONOMIC_TEST: JSON.stringify([values.ECONOMIC_TEST]),
                  };

        dispatch(
            updateResource(
                getResourceParams({
                    idProject,
                    updateItems: parameters,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

interface ProjectStudyCaseParams {
    /**
     * ID of Project.
     */
    idProject: number;
}

interface UpdateProjectStudyCaseParams extends ProjectStudyCaseParams {
    /**
     * Study case values.
     */
    values: IndexSignature<string>;

    /**
     * Function to run on completion.
     */
    onComplete?: () => void;
}

interface GetResourceParams {
    /**
     * ID of Project.
     */
    idProject: number;

    /**
     * Study case optimistic update object.
     */
    updateItems: IndexSignature<string>;

    /**
     * Function to run on completion.
     */
    onComplete?: () => void;

    dispatch: Function;
}
