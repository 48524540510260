import { Component } from "react";

import TabList from "components/ui/List/TabList";
import NothingFoundBlock from "components/ui/NothingFoundBlock";
import Tooltip from "components/ui/Tooltip";

class Additional extends Component {
    constructor(props) {
        super(props);

        this.tabs = [
            {
                id: "item",
                title: "Item",
            },
            {
                id: "name",
                title: "Name",
            },
        ];

        this.state = {
            activeTab: this.tabs[0],
            activeTab2: this.tabs[0],

            isActive: false,
            isActive2: true,
        };
    }

    render() {
        return (
            <div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Tabs</div>
                        <div className="row">
                            <TabList
                                items={this.tabs}
                                activeItem={this.state.activeTab}
                                onClick={(tab) => this.setState({ activeTab: tab })}
                            />
                        </div>
                        <div className="row">
                            <TabList
                                tabListGhost
                                items={this.tabs}
                                activeItem={this.state.activeTab2}
                                onClick={(tab) => this.setState({ activeTab2: tab })}
                            />
                        </div>
                    </div>
                </div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Nothing found block</div>
                        <NothingFoundBlock icon="assignment" title="There are no projects for the selected client" />
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Nothing found block with an explanation</div>
                        <NothingFoundBlock icon="assignment" title="There are no projects for the selected client">
                            Select different Client from the <strong>Clients Panel</strong>
                        </NothingFoundBlock>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Tooltips</div>
                        <div className="row">
                            <Tooltip icon="octagon-exclamation-empty">
                                Tooltip placed at the bottom and directed to the right side (default).
                            </Tooltip>
                            <Tooltip icon="octagon-exclamation-empty" placement="bottom">
                                Tooltip placed at the bottom and directed to the center.
                            </Tooltip>
                            <Tooltip icon="octagon-exclamation-empty" placement="bottom-end">
                                Tooltip placed at the bottom and directed to the left side.
                            </Tooltip>
                        </div>
                        <div className="row">
                            <Tooltip icon="octagon-exclamation-empty" placement="right-start">
                                Tooltip placed at the right and directed downwards.
                            </Tooltip>
                            <Tooltip icon="octagon-exclamation-empty" placement="right">
                                Tooltip placed at the center right.
                            </Tooltip>
                            <Tooltip icon="octagon-exclamation-empty" placement="right-end">
                                Tooltip placed at the right and directed upwards.
                            </Tooltip>
                        </div>
                        <div className="row">
                            <Tooltip icon="octagon-exclamation-empty" placement="top-start">
                                Tooltip placed at the top and directed to the right side.
                            </Tooltip>
                            <Tooltip icon="octagon-exclamation-empty" placement="top">
                                Tooltip placed at the top and directed to the center.
                            </Tooltip>
                            <Tooltip icon="octagon-exclamation-empty" placement="top-end">
                                Tooltip placed at the top and directed to the left side.
                            </Tooltip>
                        </div>
                        <div className="row">
                            <Tooltip icon="octagon-exclamation-empty" placement="left-start">
                                Tooltip placed at the left and directed downwards.
                            </Tooltip>
                            <Tooltip icon="octagon-exclamation-empty" placement="left">
                                Tooltip placed at the center left.
                            </Tooltip>
                            <Tooltip icon="octagon-exclamation-empty" placement="left-end">
                                Tooltip placed at the left and directed upwards.
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Additional;
