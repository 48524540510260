import { memo } from "react";

import Input from "components/ui/Input";
import DatePicker from "components/ui/Input/DatePicker";
import DatePickerContainer from "components/ui/Input/DatePicker/DatePickerContainer";

import { DATEPICKER_DATE_FORMAT } from "utils/date";

const ProjectStakeholderFilters = memo(({ filters, updateFilter, updateFilterValue }: any) => {
    return (
        <div className="list-search-headers-row">
            <div className="column-filter column-project-name project-stakeholder filter left">
                <Input
                    placeholder="Search"
                    icon="search"
                    inputTableFilter
                    value={filters.projectName}
                    onChange={(event) => updateFilter("projectName")(event)}
                />
            </div>
            <div className="column-filter column-project-description project-stakeholder filter">
                <Input
                    placeholder="Search"
                    icon="search"
                    inputTableFilter
                    value={filters.projectDescription}
                    onChange={(event) => updateFilter("projectDescription")(event)}
                />
            </div>
            <div className="column-filter column-finished project-stakeholder filter right">
                <DatePicker
                    // @ts-ignore
                    popperContainer={DatePickerContainer}
                    value={filters.finished}
                    placeholder={DATEPICKER_DATE_FORMAT.toUpperCase()}
                    inputTableFilter
                    onChange={(data: any) => updateFilterValue("finished")(data?.value || "")}
                />
            </div>
        </div>
    );
});

export default ProjectStakeholderFilters;
