import ClassNames from "classnames";

import "./style.scss";

const Separator = (props: SeparatorProps) => {
    const classNames = ClassNames("separator", props.className, {
        "separator--vertical": props.vertical,
        "separator--line": props.line,
    });

    return <div className={classNames}></div>;
};

interface SeparatorProps {
    className?: string;

    /**
     * Vertical separator.
     */
    vertical?: boolean;

    /**
     * Display line of separator.
     */
    line?: boolean;
}

export default Separator;
