import { connect } from "react-redux";
import cn from "classnames";
import ReactModal from "react-modal";

import { modalClose } from "store/modal/actions";

import Button from "components/ui/Button";
import IconWrap from "components/ui/Icons";

import "./style.scss";

// TODO: Rework this component
export const Modal = ({
    title,
    modalIcon,
    errorIcon,
    children,
    bodyOpenClassName,
    overlayClassName,
    portalClassName,
    className,
    dispatch,
    actions,
    onClose = () => {},
    noFooter = false,
    footerContentCenter = false,
    noCrossButton = false,
    noCloseButton = false,
    withScroll = false,
    parentSelector,
    highlightCustomList,
}) => (
    <ReactModal
        isOpen
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => dispatch(modalClose())}
        bodyOpenClassName={cn("", bodyOpenClassName)}
        overlayClassName={cn("", overlayClassName)}
        portalClassName={cn("", portalClassName)}
        parentSelector={parentSelector && (() => document.querySelector(parentSelector))}
        className={cn("modal simple-modal", className, { "with-scroll": withScroll })}
    >
        <div className="modal-content">
            {title && (
                <div className={cn("modal-header no-shrink")}>
                    {modalIcon && <IconWrap icon={modalIcon} error={errorIcon} />}
                    <div
                        className={cn("flex-row align-center justify-space-between fill-width", {
                            "margin-left-small": modalIcon,
                        })}
                    >
                        {title}
                        {!noCrossButton && (
                            <IconWrap
                                icon="clear-close"
                                title="Close"
                                onClick={() => {
                                    dispatch(modalClose());
                                    onClose();
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
            <div className={cn("modal-body fill-height", { "highlight-custom-list": highlightCustomList })}>
                <div className="flex-column fill-height">{children}</div>
            </div>
            {!noFooter && (
                <div className={"modal-footer no-shrink" + (footerContentCenter ? "center" : "")}>
                    {actions && actions}
                    {!noCloseButton && (
                        <Button
                            className="modal-close"
                            onClick={() => {
                                dispatch(modalClose());
                                onClose();
                            }}
                        >
                            Close
                        </Button>
                    )}
                </div>
            )}
        </div>
    </ReactModal>
);

export default connect()(Modal);
