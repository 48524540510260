import { moduleStudyCasesResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";

import { sortArray } from "utils/array";

import { ModuleStudyCase, UseModuleStudyCasesParams } from "./types";

export const useModuleStudyCases = ({ type }: UseModuleStudyCasesParams) =>
    useResource({
        resourceName: moduleStudyCasesResourceName,
        key: moduleStudyCasesResourceName,
        transform: (data: ModuleStudyCase[]) => {
            const filteredData = data?.filter((studyCase) => studyCase.moduleType === type) || [];

            return sortArray(filteredData, "uiOrder");
        },
    }) as [ModuleStudyCase[], boolean | undefined, any];
