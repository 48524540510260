import * as actionTypes from "store/actionTypes";

const initialState = {
    isLoading: false,
    errorMessage: null,
    infoMessage: null,
    logOutPending: false,
    repeatActions: [],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_RESET:
            state = initialState;
            break;

        case actionTypes.VDSM_LOGIN_REQUEST:
        case actionTypes.LOGIN_REFRESH_REQUEST:
        case actionTypes.UPDATE_PASSWORD_REQUEST:
        case actionTypes.FORGOT_PASSWORD_REQUEST:
            state = {
                ...state,
                isLoading: true,
                infoMessage: null,
                errorMessage: null,
            };

            break;

        case actionTypes.VDSM_LOGIN_RESPONSE:
        case actionTypes.LOGIN_REFRESH_RESPONSE:
        case actionTypes.UPDATE_PASSWORD_RESPONSE:
            state = {
                ...state,
                isLoading: false,
                infoMessage: null,
                errorMessage: null,
            };

            break;

        case actionTypes.FORGOT_PASSWORD_RESPONSE:
            state = {
                ...state,
                isLoading: false,
                infoMessage: action.message,
                errorMessage: null,
            };

            break;

        case actionTypes.VDSM_LOGIN_ERROR:
        case actionTypes.LOGIN_REFRESH_ERROR:
        case actionTypes.UPDATE_PASSWORD_ERROR:
        case actionTypes.FORGOT_PASSWORD_ERROR:
            state = {
                ...state,
                isLoading: false,
                infoMessage: null,
                errorMessage: action.message,
            };

            break;

        case actionTypes.UPDATE_PASSWORD_ERROR_CLEAR:
            state = {
                ...state,
                isLoading: false,
                infoMessage: null,
                errorMessage: null,
            };

            break;

        case actionTypes.LOGOUT_REQUEST:
            state = {
                ...state,
                logOutPending: true,
            };

            break;

        case actionTypes.LOGOUT_RESPONSE:
            state = {
                ...state,
                logOutPending: false,
            };

            break;

        case actionTypes.LOGOUT_ERROR:
            state = {
                ...state,
                logOutPending: false,
                infoMessage: null,
                errorMessage: action.message,
            };

            break;

        case actionTypes.LOGIN_ADD_REPEAT_ACTION:
            state = {
                ...state,
                repeatActions: state.repeatActions.concat([action.repeatAction]),
            };

            break;

        case actionTypes.LOGIN_CLEAR_REPEAT_ACTIONS:
            state = {
                ...state,
                repeatActions: [],
            };

            break;

        default:
            break;
    }

    return state;
};
