import { memo } from "react";

import HTMLReport from "./HTMLReport";
import PBIReport from "./PBIReport";

import { EmbeddedReportProps } from "./types";

const EmbeddedReport = memo(({ idProject, idReport, idPbi, reportType, fileName, showPresentationMode = true }: EmbeddedReportProps) => {
    return (
        <div className="flex-column fill-height">
            {reportType === "HTML" && (
                <HTMLReport idProject={idProject} idReport={idReport} fileName={fileName} showPresentationMode={showPresentationMode} />
            )}
            {reportType === "PBI" && <PBIReport id={idPbi} hideActions={!showPresentationMode} />}
        </div>
    );
});

export default EmbeddedReport;
