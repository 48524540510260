import cn from "classnames";

import { IdsIcon } from "@emergn-infinity/ids-react";

import { IconProps } from "./types";

import "./style.scss";

const Icon = (props: IconProps) => {
    const { icon, iconTitle, className, size = "sm", disabled, variant, clickable, onClick } = props;

    const isClickable = (clickable || Boolean(onClick)) && !props.disabled;

    const classNames = cn("vi-icon", className, {
        disabled: disabled,
        clickable: isClickable,
        error: variant === "error",
        success: variant === "success",
        warning: variant === "warning",
    });

    return <IdsIcon icon={icon} iconTitle={iconTitle} customClasses={classNames} size={size} onClick={!disabled ? onClick : undefined} />;
};

export default Icon;
