// NOTE: Themes currently have no use in application,
// but will be fully implemented in the future
import { get } from "lodash";
import cn from "classnames";

import { store } from "store/configureStore";
import { VDSM_USER_SAVE } from "store/actionTypes";
import { userThemesResourceName } from "store/configureVDSMResources";

import { setUserSettings, isAuthenticated } from ".";

import { ThemesProperties, UserThemes, UserSettings, GetUserThemeParams, SettingsParams } from "./types";

export const DEFAULT_THEME = "default";
export const DEFAULT_THEME_SIGHTLINE = "sightLine";

export const availableUserFontSizes: UserThemes = {
    Normal: { name: "Normal", className: "font-size-normal" },
    Large: { name: "Large", className: "font-size-large" },
};

export const DEFAULT_FONT_SIZE = availableUserFontSizes.Normal.name;

export const availableUserThemeModes: UserThemes = {
    Normal: { name: "Normal", className: "theme-mode-normal" },
    Light: { name: "Light", className: "theme-mode-light" },
    Dark: { name: "Dark", className: "theme-mode-dark" },
};

export const DEFAULT_THEME_MODE = availableUserThemeModes.Normal.name;

export const availableUserThemes = [
    { theme: "sightLine", name: "Sightline", className: "theme-sightline theme-mode-light", isActive: false },
    { theme: "default", name: "Default", className: "theme-default", isActive: false },
    { theme: "darkSky", name: "Dark Sky", className: "theme-darkSky", isActive: false },
    { theme: "lightSeaGreen", name: "Light Sea Green", className: "theme-lightSeaGreen", isActive: false },
    { theme: "valentine", name: "Valentine", className: "theme-valentine", isActive: false },
    { theme: "violets", name: "Violets", className: "theme-violets", isActive: false },
    { theme: "olive", name: "Olive", className: "theme-olive", isActive: false },
    { theme: "earth", name: "Earth", className: "theme-earth", isActive: false },
    { theme: "sunset", name: "Sunset", className: "theme-sunset", isActive: false },
    { theme: "nature", name: "Nature", className: "theme-nature", isActive: false },
    { theme: "dusk", name: "Dusk", className: "theme-dusk", isActive: false },
    { theme: "northemLights", name: "Northem Lights", className: "theme-northemLights", isActive: false },
    { theme: "eveningShades", name: "Evening Shades", className: "theme-eveningShades", isActive: false },
];

const getDefaultTheme = () => {
    let theme;

    const themes = getUserThemes();
    const stored = localStorage.getItem("theme");

    if (stored) {
        theme = themes.find((i) => i.theme === stored);
    }

    if (!theme) {
        theme = themes.find((i) => i.theme === DEFAULT_THEME);
    }

    if (!theme) {
        theme = themes.find((i) => i.theme === DEFAULT_THEME_SIGHTLINE);
    }

    return theme ? theme.theme : DEFAULT_THEME;
};

const getTheme = (themeName?: string) => {
    let theme = getDefaultTheme();
    let themes = getUserThemes();

    if (themeName) {
        const _theme = themes.find((i) => i.theme === themeName);

        if (_theme) {
            theme = _theme.theme;
        }
    } else {
        const state = store.getState();
        const settings = state?.vdsmUser?.settings;

        if (settings && settings.theme) {
            theme = getTheme(settings.theme);
        } else {
            const stored = localStorage.getItem("theme");

            if (stored) {
                theme = getTheme(stored);
            }
        }
    }

    return theme;
};

const getDefaultThemeMode = () => {
    let mode;

    const stored = localStorage.getItem("themeMode");

    if (stored) {
        mode = availableUserThemeModes[stored];
    }

    return mode ?? availableUserThemeModes[DEFAULT_THEME_MODE];
};

const getThemeMode = (themeMode?: string) => {
    let tm = getDefaultThemeMode();

    if (themeMode) {
        tm = availableUserThemeModes[themeMode];
    }

    return tm;
};

const getDefaultFontSize = () => {
    let size;

    const stored = localStorage.getItem("fontSize");

    if (stored) {
        size = availableUserFontSizes[stored];
    }

    return size ?? availableUserFontSizes[DEFAULT_FONT_SIZE];
};

const getFontSize = (fontSize?: string) => {
    let fs = getDefaultFontSize();

    if (fontSize) {
        fs = availableUserFontSizes[fontSize];
    }

    return fs;
};

const getThemeClassName = (themeName: string) => {
    const themes = getUserThemes();

    const theme = themes.find((i) => i.theme === themeName) || themes[0];

    return theme.className;
};

const setClient = ({ theme, themeMode, fontSize }: UserSettings) => {
    const state = store.getState();
    const user = state?.vdsmUser;

    if (
        isAuthenticated(user) &&
        (user.settings.theme !== theme || user.settings.themeMode !== themeMode.name || user.settings.fontSize !== fontSize.name)
    ) {
        setUserSettings({ theme, themeMode, fontSize });

        store.dispatch({
            type: VDSM_USER_SAVE,
            vdsmUser: {
                ...user,
                settings: {
                    theme,
                    themeMode: themeMode.name,
                    fontSize: fontSize.name,
                },
            },
        });
    }

    // store locally

    localStorage.setItem("theme", theme);
    localStorage.setItem("themeMode", themeMode.name);

    localStorage.setItem("fontSize", fontSize.name);

    // apply UI

    document.body.className = cn(getThemeClassName(theme), themeMode.className, fontSize.className);
};

export const getUserTheme = ({ isUserAuthenticated }: GetUserThemeParams) => {
    const theme = getTheme();

    if (isUserAuthenticated) {
        return theme;
    } else {
        return theme === DEFAULT_THEME_SIGHTLINE ? DEFAULT_THEME_SIGHTLINE : DEFAULT_THEME;
    }
};

export const getUserThemes = () => {
    const state = store.getState();
    const user = state?.vdsmUser;

    if (user && user.userNumber) {
        const themes = get(state, `resources.${userThemesResourceName}.itemsById[${user.userNumber}].data`);

        if (themes) {
            return availableUserThemes.filter((i) => themes.includes(i.theme));
        }
    }

    return availableUserThemes.filter((i) => i.theme === DEFAULT_THEME || i.theme === DEFAULT_THEME_SIGHTLINE);
};

export const setSettings = (settings: SettingsParams) => {
    setClient({
        theme: getTheme(settings?.theme),
        themeMode: getThemeMode(settings?.themeMode),
        fontSize: getFontSize(settings?.fontSize),
    });
};

export const setSettingsTheme = (themeName: string) => {
    setClient({ theme: getTheme(themeName), themeMode: getThemeMode(), fontSize: getFontSize() });
};

export const setSettingsThemeMode = (themeMode: ThemesProperties) => {
    setClient({ theme: getTheme(), themeMode: themeMode, fontSize: getFontSize() });
};

export const setSettingsFontSize = (fontSize: ThemesProperties) => {
    setClient({ theme: getTheme(), themeMode: getThemeMode(), fontSize: fontSize });
};
