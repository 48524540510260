import {
    API_PUT_AUTHORIZED,
    API_CRUD_UPDATE_REQUEST,
    API_CRUD_UPDATE_SUCCESS,
    API_CRUD_UPDATE_ERROR,
    API_CRUD_OPTIMISTIC_UPDATE_ITEM,
} from "store/actionTypes";
import { getResourceUrl } from "store/utils";

import { Nullable, CreateResourceParams } from "./createResource";

/**
 * Updates resource in database.
 */
export const updateResource =
    ({
        resourceName,
        resourceId,
        key,
        path = {},
        query = {},
        body,
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        optimisticUpdate,
        updateFromResponse = false,
        onSuccess,
        onError,
        onComplete,
    }: CreateResourceParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: API_PUT_AUTHORIZED,
            url: getResourceUrl(resourceName, path) + (resourceId ? "/" + resourceId : ""),
            query: {
                // Remove query params with empty value
                ...Object.keys(query)
                    // @ts-ignore
                    .filter((key) => query[key] !== null && query[key] !== undefined)
                    // @ts-ignore
                    .reduce((obj, key) => (obj = { ...obj, [key]: query[key] }), {}),
            },
            body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
            actionTypes: {
                pending: API_CRUD_UPDATE_REQUEST,
                response: API_CRUD_UPDATE_SUCCESS,
                error: API_CRUD_UPDATE_ERROR,
            },
            // These params show what resource to update, and on API error removes item with 'resourceId' from store.
            passThroughData: {
                resourceName,
                resourceId,
                key,
                path,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onSuccess,
                onError,
                onComplete,
                updateFromResponse,
            },
        });

        if (optimisticUpdate) {
            dispatch(
                optimisticUpdateItem({
                    value: (optimisticUpdate as OptimisticUpdateItemParams).value,
                    resourceName,
                    resourceId,
                    key,
                })
            );
        }
    };

/**
 * Updates resource in store.
 */
export const optimisticUpdateItem =
    ({ value, resourceName, resourceId, key }: OptimisticUpdateItemParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: API_CRUD_OPTIMISTIC_UPDATE_ITEM,
            value,
            passThroughData: {
                resourceId,
                key,
                resourceName,
            },
        });
    };

export interface OptimisticUpdateItemParams {
    /**
     * Name of the resource property.
     */
    key?: string;

    /**
     * Name of the resource.
     */
    resourceName?: string;

    /**
     * ID of resource.
     */
    resourceId?: Nullable<string>;

    value: object;
}
