import { memo } from "react";

import { useModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { useProjectInputs } from "store/resources/actions/projectInput/projectInputActions";

import IconLoading from "components/ui/Icons/IconLoading";

import ScenarioLog from "pages/ManageProject/ProjectionsContent/common/ScenarioLog";

import { ModelLogProps } from "pages/ManageProject/ProjectionsContent/ModelDashboard/DashboardDefault/ModelLogPanel/types";

const headers = {
    actionName: "action",
    started: "started",
    user: "user",
    executionTime: "execution time",
    result: {
        label: "result",
        sortable: false,
    },
    note: {
        label: "",
        sortable: false,
    },
};

const ModelLog = memo(({ idClient, idProject, idInputLog }: ModelLogProps) => {
    const [modelLogItems, isLoadingModelLogItems] = useModelLogItems({ idProject, idInputLog });

    const [projectInputs = [], isLoadingProjectInputs] = useProjectInputs({ idProject });

    return (
        <>
            {isLoadingModelLogItems || isLoadingProjectInputs ? (
                <IconLoading />
            ) : (
                <ScenarioLog
                    idClient={idClient}
                    idProject={idProject}
                    projections={projectInputs}
                    headers={headers}
                    logItems={modelLogItems}
                />
            )}
        </>
    );
});

export default ModelLog;
