import { isEmpty, isNil } from "lodash";
import { memo, useEffect, useMemo } from "react";

import { useComponentModels } from "store/resources/actions/componentModel/componentModelActions";

import ComponentModelDashboardEmpty from "./DashboardEmpty";
import ComponentModelDashboardDefault from "./DashboardDefault";

import hj from "utils/hotJar";

import { ComponentModelDashboardProps } from "./types";

const ComponentModelDashboard = memo(
    ({ idClient, idProject, idModel, viewIndex, clientName, projectName }: ComponentModelDashboardProps) => {
        const [componentModels, isLoadingComponentModels] = useComponentModels({ idProject });

        const currentModel = useMemo(() => {
            let currentModel;

            if (!isEmpty(componentModels) && !isLoadingComponentModels) {
                currentModel = componentModels.find((componentModel) => componentModel.idModel === idModel);
            }

            return currentModel;
        }, [idModel, componentModels, isLoadingComponentModels]);

        useEffect(() => {
            hj("stateChange", "ComponentModelDashboard");
        }, []);

        if (currentModel === undefined) {
            return null;
        } else if (isNil(currentModel.idInputLog)) {
            return (
                <ComponentModelDashboardEmpty
                    idClient={idClient}
                    viewIndex={viewIndex}
                    model={currentModel}
                    clientName={clientName}
                    projectName={projectName}
                    componentModels={[]}
                />
            );
        } else {
            return (
                <ComponentModelDashboardDefault
                    viewIndex={viewIndex}
                    idClient={idClient}
                    model={currentModel}
                    clientName={clientName}
                    projectName={projectName}
                    componentModels={componentModels}
                />
            );
        }
    }
);

export default ComponentModelDashboard;
