import { memo } from "react";

import "./style.scss";

const SideContainer = memo(() => {
    return (
        <div className="flex-column align-center justify-center form-wrap-side-container">
            <div className="form-wrap-side-container__vision-logo" />
            <div className="form-wrap-side-container__tagline">
                Data-centric modeling empowered by self-service platform to freely and securely browse, interact, and maintain all your data
            </div>
        </div>
    );
});

export default SideContainer;
