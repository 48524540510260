import { memo } from "react";
import cn from "classnames";

import Icon from "components/ui/IconNew";

import ClientListPanel from "pages/HomeDashboard/common/ClientListPanel";

import { ClientListContainerProps } from "./types";

import "./style.scss";

const ClientListContainer = memo(
    ({
        idClient,
        clients,
        isLoadingClients,
        isBookmarkView,
        isBookmarkViewEnabled,
        onBookmarkedProjectsClick,
    }: ClientListContainerProps) => {
        return (
            <div className="flex-column fill-height with-scroll client-list-container">
                {!isLoadingClients && (
                    <>
                        {isBookmarkViewEnabled && (
                            <div className="bookmarked-projects-item-container">
                                <div
                                    className={cn("flex-row align-center justify-space-between bookmarked-projects-item", {
                                        selected: isBookmarkView,
                                    })}
                                    onClick={onBookmarkedProjectsClick}
                                >
                                    <span>Bookmarked Projects</span>
                                    <Icon className="arrow-icon" icon="arrows-arrow_in_circle_right_b_s" size="sm" clickable />
                                </div>
                            </div>
                        )}
                        <ClientListPanel idClient={idClient} clients={clients} loading={isLoadingClients} />
                    </>
                )}
            </div>
        );
    }
);

export default ClientListContainer;
