import { maxBy } from "lodash";

import { calculationsLogResourceName } from "store/configureResources";
import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

import { LastCalculationResultParams } from "./types";

export const useLastCalculationResult = ({ idProject, idInputLog }: LastCalculationResultParams) =>
    useResource({
        resourceName: calculationsLogResourceName,
        key: `${calculationsLogResourceName}-${idProject}-${idInputLog}`,
        query: {
            idInputLog,
        },
        transform: (data) => maxBy(data?.model, "finished"),
    });

export const clearLastCalculationResult = ({ idProject, idInputLog }: LastCalculationResultParams) =>
    clearResource({
        resourceName: calculationsLogResourceName,
        key: `${calculationsLogResourceName}-${idProject}-${idInputLog}`,
        broadcast: true,
    });
