import { isEmpty } from "lodash";
import { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { readProfile } from "store/userProfile/actions";

import ChangeProfileDetailsForm from "./ChangeProfileDetailsForm";
import ChangePasswordForm from "./ChangePasswordForm";

import { IdsTabs, IdsTabItem, IdsTabPanel } from "@emergn-infinity/ids-react";

import IconLoading from "components/ui/Icons/IconLoading";

import "./style.scss";

const UserProfile = memo(() => {
    const dispatch = useDispatch();

    // @ts-ignore
    const userProfileState = useSelector((state) => state.userProfile);

    // @ts-ignore
    const isLoginLoading = useSelector((state) => state.login.isLoading);

    const { data = {}, isLoading } = userProfileState;

    if (isEmpty(data) && !isLoading) {
        dispatch(readProfile());
    }

    return (
        <div className="flex-column with-scroll user-profile">
            {isLoginLoading || isLoading ? (
                <div className="flex-column flex-one align-center justify-center">
                    <IconLoading />
                </div>
            ) : (
                <div className="flex-column px-4" style={{ minHeight: "100%" }}>
                    <div className="flex-row justify-center py-4 user-profile__header">
                        {`Hello, `} <span className="pl-1 text-bold">{`${data.firstName} ${data.lastName}`}</span>!
                    </div>
                    <div
                        className="flex-column align-center flex-grow"
                        style={{ backgroundColor: "var(--ids-semantic-background-color-neutral-accent-default-onlight)" }}
                    >
                        <div className="flex-column" style={{ width: "350px" }}>
                            <IdsTabs behavior="fill-container">
                                <IdsTabItem slot="header" idValue="profile-details" label="Change Profile Details" isActive={true} />
                                <IdsTabItem slot="header" idValue="password" label="Change Password" />

                                <IdsTabPanel customClasses="remove-background" slot="panel" idValue="profile-details">
                                    <ChangeProfileDetailsForm initialValues={data} />
                                </IdsTabPanel>
                                <IdsTabPanel customClasses="remove-background" slot="panel" idValue="password">
                                    <ChangePasswordForm />
                                </IdsTabPanel>
                            </IdsTabs>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default UserProfile;
