import { memo } from "react";

import DashboardHeader from "./DashboardHeader";

import { ModelDashboardEmptyProps } from "./types";

const ModelDashboardEmpty = memo(({ model }: ModelDashboardEmptyProps) => {
    return (
        <div className="model-dashboard-empty">
            <div className="model-dashboard-empty__header-container">
                <DashboardHeader model={model} />
            </div>
        </div>
    );
});

export default ModelDashboardEmpty;
