import { get } from "lodash";
import { useSelector } from "react-redux";

import { projectsResourceName } from "store/configureResources";

import { Project } from "store/resources/actions/project/types";

import { UseProjectFromStoreParams } from "./types";

export const useProjectFromStore = ({ idClient, idProject }: UseProjectFromStoreParams) => {
    return useSelector<any, Project[]>((state) =>
        get(state, `resources.${projectsResourceName}.itemsById[${projectsResourceName}-${idClient}].data.model`)
    )?.find((project) => project.idProject === idProject);
};
