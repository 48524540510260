import * as React from "react";

import Portal from "components/ui/Portal";

const DatePickerContainer = ({ children }: DatePickerContainerProps) => {
    return <Portal container={document.body}>{children}</Portal>;
};

interface DatePickerContainerProps {
    children: React.ReactNode;
}

export default DatePickerContainer;
