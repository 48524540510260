import { useCallback } from "react";
import ClassNames from "classnames";

import IconWrap from "components/ui/Icons";

import { TagButtonProps } from "./types";

import "./style.scss";

const TagButton = (props: TagButtonProps) => {
    const { onRemove } = props;

    const isClickable = !props.disabled && !props.readOnly && onRemove !== undefined;

    const classNames = ClassNames("button-tag", props.className, {
        disabled: props.disabled,
        "read-only": props.readOnly,
        unremovable: props.unremovable,
    });

    const onRemoveClick = useCallback(
        (event) => {
            event.stopPropagation();

            onRemove?.(event);
        },
        [onRemove]
    );

    return (
        <div className={classNames} onClick={isClickable ? props.onClick : undefined}>
            {props.children}
            {!props.unremovable && <IconWrap icon="clear-circle" title={props.title} onClick={isClickable ? onRemoveClick : undefined} />}
        </div>
    );
};

export default TagButton;
