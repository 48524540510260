import { isEmpty } from "lodash";
import { memo, useMemo } from "react";

import { useModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import { IconSingleModelReport } from "components/ui/Icons/IconReport";
import IconLoading from "components/ui/Icons/IconLoading";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import { parseCalculationResult } from "utils/useCalculationResult";
import { d } from "utils/date";
import { logActionKeys } from "utils/scenarioLog";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { CalculatedResultsProps } from "pages/ManageProject/ProjectionsContent/ModelDashboardNew/DashboardDefault/CalculatedResultsPanel/types";

import "./style.scss";

const CalculatedResults = memo(({ dataTestId, idClient, idProject, idModel, idInputLog, componentModels }: CalculatedResultsProps) => {
    const [modelLogItems, isLoadingModelLogItems] = useModelLogItems({ idProject, idModel });

    const resultItems = useMemo(
        () =>
            modelLogItems
                ?.filter((item: any) => {
                    const results = parseInt(item.results);

                    return item.actionName === logActionKeys.RUN_CALCULATION && !isNaN(results) && results > 0;
                })
                .map(parseCalculationResult)
                .map((item: any) => ({
                    ...item,
                    title: item.parameters?.CASE_STUDIES?.[0].NAME,
                    date: d.format(new Date(item.finished + "Z")),
                })),
        [modelLogItems]
    );

    if (isLoadingModelLogItems) {
        return <IconLoading />;
    }

    return isEmpty(resultItems) ? (
        <div data-testid={dataTestId} className="flex flex-center">
            <NothingFoundBlock icon="ui-chart_multiple_b_s" title="There are no calculated results" />
        </div>
    ) : (
        <div data-testid={dataTestId} className="flex-row">
            {resultItems.slice(0, 3).map((item: any) => {
                const model = componentModels.find((m) => m.idModel === item.idModel);

                return (
                    <div key={`result-${item.results}`} className="flex-column result-container">
                        <div className="result-title">
                            <IconSingleModelReport
                                className="flex-row-reverse justify-space-between"
                                withLabel
                                idClient={idClient}
                                idProject={idProject}
                                idModel={idModel}
                                idInputLog={idInputLog}
                                title={model?.name || ""}
                                subTitle={item.title}
                                withApprovement={item.isInClientReview && hasAnyOfPermissions([USER_ACTIONS.MODULES_APPROVE])}
                                calculationResult={{
                                    results: item.results,
                                    isPotential: true,
                                    started: item.started,
                                    finished: item.finished,
                                }}
                            />
                        </div>
                        <div data-testid="result-date" className="result-date">
                            {item.date}
                        </div>
                    </div>
                );
            })}
        </div>
    );
});

export default CalculatedResults;
