///
/// Component Model template actions
/// resourceName: componentModelTemplateListResourceName
///
import { componentModelTemplateListResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";

import { ComponentModelTemplate, ComponentModelTemplateResponseModel, UseComponentModelTemplatesParams } from "./types";

export const useComponentModelTemplates = ({ loadResource }: UseComponentModelTemplatesParams = { loadResource: true }) =>
    useResource({
        resourceName: componentModelTemplateListResourceName,
        key: loadResource ? componentModelTemplateListResourceName : undefined,
        transform: (data: ComponentModelTemplateResponseModel[]) => {
            const transformedData = data?.map((template) => {
                const { templateId, ...rest } = template;

                return {
                    ...rest,
                    idTemplate: templateId,
                };
            });

            return transformedData;
        },
    }) as [ComponentModelTemplate[], boolean | undefined, any | undefined];
