import { isEmpty } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { selectProjection } from "store/projections/actions";

import { uploadHTMLReport } from "store/processes/actions/dataImport/standaloneReportImportActions";
import {
    useStandaloneReports,
    getStandaloneReports,
    updateStandaloneReport,
} from "store/resources/actions/standaloneReport/standaloneReportActions";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import FieldGroup from "components/ui/FieldGroup";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import UploadFile from "components/ui/Input/UploadFile";

import { projections } from "utils/constants";
import { isNullOrWhitespace } from "utils/string";

import { StandaloneReport } from "store/resources/actions/standaloneReport/types";
import { ModalOpenChildrenProps } from "layouts/Modal/types";

const REPORT_TYPE = "html";

const EditHtmlReport: React.NamedExoticComponent<EditHtmlReportProps> = memo((props) => {
    const { viewIndex, idProject, idReport, editing, onCancel } = props;

    const dispatch = useDispatch();

    const [reportName, setReportName] = useState(props.reportName ?? "");

    const [file, setFile] = useState<File[]>([]);

    const [standaloneReports, isLoading] = useStandaloneReports({ idProject });

    const reportNameExists = useMemo(() => {
        let reportNameExists = false;

        if (!isLoading) {
            reportNameExists = standaloneReports?.some(
                (standaloneReport: StandaloneReport) =>
                    standaloneReport.idReport !== idReport && standaloneReport.name?.toLowerCase() === reportName.toLowerCase().trim()
            );
        }

        return reportNameExists;
    }, [idReport, reportName, standaloneReports, isLoading]);

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setReportName(event.target.value);
    }, []);

    const onDropFile = useCallback((event) => {
        setFile(event);
    }, []);

    const onRemoveFile = useCallback((files: File[]) => {
        setFile(files);
    }, []);

    const onUploadComplete = (idReport?: number) => {
        const onGotStandaloneReports = () => {
            dispatch(
                selectProjection({
                    viewIndex,
                    idProjectionView: projections.STANDALONE_REPORT_DASHBOARD,
                    idProject,
                    idProjection: idReport,
                    key: "idReport",
                })
            );
        };

        dispatch(getStandaloneReports({ idProject, onComplete: onGotStandaloneReports }));
    };

    const onImportReportClick = () => {
        // Update HTML Report name
        if (isEmpty(file)) {
            let formData = new FormData();

            formData.append("idProject", idProject.toString());
            formData.append("idReport", idReport ? idReport.toString() : "0");
            formData.append("name", reportName);
            formData.append("reportType", REPORT_TYPE);
            idReport === undefined && formData.append("active", "true");

            dispatch(
                updateStandaloneReport({
                    idProject,
                    idReport: idReport || 0,
                    formData,
                    action: idReport !== undefined ? "Updating HTML Report" : "Creating HTML Report",
                    onSuccess: onCancel,
                })
            );
        }
        // Create HTML Standalone Report
        // Update HTML Standalone Report file
        else {
            dispatch(
                uploadHTMLReport({
                    idProject,
                    idReport: idReport || 0,
                    file: file![0],
                    name: reportName,
                    reportType: REPORT_TYPE,
                    originalFilename: file![0].name,
                    action: idReport !== undefined ? "Updating HTML Report" : "Creating HTML Report",
                    onComplete: onUploadComplete,
                })
            );

            onCancel?.();
        }
    };

    return isLoading ? (
        <IconLoading />
    ) : (
        <>
            <div className="flex-column modal-padding">
                <FieldGroup>
                    <Input
                        label="Report name"
                        required
                        value={reportName}
                        error={reportNameExists}
                        msgText={reportNameExists ? "This name is already in use." : ""}
                        onChange={onChange}
                    />
                    <UploadFile
                        required
                        label="Report file"
                        accept={[".htm", ".html"]}
                        files={file}
                        onDrop={onDropFile}
                        onRemove={(files) => onRemoveFile(files)}
                    />
                </FieldGroup>
            </div>
            <ButtonGroup className="justify-end modal-actions">
                {editing ? (
                    <>
                        {/* In order to display "Update Report" button, either:
                                         - Report name must not be empty, report name must be unique and
                                           report name must differ from current report name
                                         OR
                                         - Report file must be provided */}
                        {((!isNullOrWhitespace(reportName) && !reportNameExists && reportName.trim() !== props.reportName) ||
                            !isEmpty(file)) && (
                            <Button primary onClick={onImportReportClick}>
                                Update Report
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        {/* In order to display "Import Report" button:
                                         - Report name must not be empty
                                         - Report name must be unique
                                         - Report file must be provided */}
                        {!isNullOrWhitespace(reportName) && !reportNameExists && !isEmpty(file) && (
                            <Button primary onClick={onImportReportClick}>
                                Import Report
                            </Button>
                        )}
                    </>
                )}
                <Button onClick={onCancel}>Cancel</Button>
            </ButtonGroup>
        </>
    );
});

export interface EditHtmlReportProps extends ModalOpenChildrenProps {
    viewIndex: number;
    idProject: number;
    idReport?: number;
    reportName?: string;
    editing?: boolean;
}

export default EditHtmlReport;
