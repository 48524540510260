import { CLIENT_SELECT } from "store/actionTypes";

const initialState = {
    selection: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_SELECT:
            state = {
                ...state,
                selection: {
                    idClient: action.selection.idClient,
                },
            };
            break;

        default:
            break;
    }
    return state;
};
