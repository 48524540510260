import * as React from "react";
import cn from "classnames";

import "./style.scss";

const Drawer = ({ className, children }: DrawerProps) => {
    return <div className={cn("fill-height drawer", className)}>{children}</div>;
};

interface DrawerProps {
    className?: string;
    children?: React.ReactNode;
}

export default Drawer;
