import { memo } from "react";

import DashboardPanel from "components/ui/Dashboard";

import ProjectLevelReports from "./ProjectLevelReports";

import { ProjectLevelReportsProps } from "./types";

const ProjectLevelReportsPanel = memo(({ idClient, idProject, projectName, reportName }: ProjectLevelReportsProps) => {
    const title = reportName === "project-level" ? "Project-level Reports" : "Measure Summary Reports";

    return (
        <DashboardPanel className="project-stakeholder-dashboard-panel" title={title}>
            <ProjectLevelReports idClient={idClient} idProject={idProject} projectName={projectName} reportName={reportName} />
        </DashboardPanel>
    );
});

export default ProjectLevelReportsPanel;
