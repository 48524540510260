///
/// List of project names per client, no security filtering
/// Project Names actions
/// resourceName: projectNamesResourceName,
///
import { projectNamesResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

export const useProjectNames = ({ idClient }) =>
    useResource({
        resourceName: projectNamesResourceName,
        key: `${projectNamesResourceName}-${idClient}`,
        path: idClient ? { idClient } : undefined,
        transform: (data) => data?.model,
    });

export const clearProjectNames =
    ({ idClient }) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: projectNamesResourceName,
                key: `${projectNamesResourceName}-${idClient}`,
                broadcast: true,
            })
        );
