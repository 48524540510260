import * as React from "react";
import cn from "classnames";

import Icon from "components/ui/IconNew";
import "./style.scss";

const NothingFoundBlock = (props: NothingFoundBlockProps) => {
    return (
        <div className={cn("flex-column align-center nothing-found-block", props.className)} data-testid={props.dataTestId}>
            {props.icon && <Icon className="not-found-icon" icon={props.icon} size="lg" />}
            <div className="nothing-found-block__title">{props.title}</div>
            {props.children && <div className="nothing-found-block__message">{props.children}</div>}
        </div>
    );
};

interface NothingFoundBlockProps {
    /**
     * Playwright attribute.
     */
    dataTestId?: string;

    className?: string;

    /**
     * Message to display inside the block.
     */
    title: string;

    /**
     * Icon to display inside the block.
     */
    icon?: string;

    /**
     * Additional message to display inside the block.
     */
    children?: React.ReactNode;
}

export default NothingFoundBlock;
