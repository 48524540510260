import { useCallback, useLayoutEffect, useState } from "react";

import Button from "components/ui/Button";

import { clientRoute } from "utils/constants";
import { COOKIE_COOKIES_PREFERENCES_SET, getCookie, setCookiesPolicyCookie, setCookiesPreferencesCookie } from "utils/cookies";
import { isInIframe } from "utils/dom";

import "./style.scss";

const CookiesBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useLayoutEffect(() => {
        const pathParts = window.location.pathname.split("/")[1];

        const cookie = getCookie(COOKIE_COOKIES_PREFERENCES_SET);

        if (isInIframe()) {
            setShowBanner(pathParts === clientRoute.reports);
        } else {
            setShowBanner(cookie !== "true" && pathParts === clientRoute.reports);
        }
    }, []);

    const onAcceptAll = useCallback(() => {
        const isUsageAllowed = true;

        setCookiesPreferencesCookie();
        setCookiesPolicyCookie(isUsageAllowed);

        setShowBanner(false);

        // Note: Page refresh is needed,
        // otherwise tracking gets enabled,
        // but starts to track only on the
        // second visit
        window.location.reload();
    }, []);

    return showBanner ? (
        <div className="flex-row align-center justify-space-between cookies-banner cookies-banner--bottom cookies-banner--overlay">
            <div className="flex-row align-center cookies-content">
                <div>This site uses cookies to learn about you and enhance your experience</div>
                <div className="cookies-content--link">Read More</div>
            </div>
            <Button primary onClick={onAcceptAll}>
                Accept Cookies
            </Button>
        </div>
    ) : null;
};

export default CookiesBanner;
