import { isEmpty } from "lodash";
import { memo, useCallback, useMemo } from "react";

import BookmarkedProjectStatusesPanel from "./BookmarkedProjectStatusesPanel";
import BookmarkedProjectsPanel from "./BookmarkedProjectsPanel";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import { sortArray } from "utils/array";

import { BookmarkedProjectsContainerProps } from "./types";
import { BookmarkedProject } from "store/resources/actions/bookmarks/types";
import { Project } from "store/resources/actions/project/types";
import { Client } from "types/api/ClientResponseModel";

import "./style.scss";

const BookmarkedProjectsContainer = memo(
    ({ clients, isLoadingClients, bookmarkedProjects, isLoadingBookmarkedProjects, user }: BookmarkedProjectsContainerProps) => {
        const bookmarkedProjectClients = useMemo(() => {
            const bookmarkedProjectClients: string[] = [];

            if (!isEmpty(bookmarkedProjects) && !isLoadingBookmarkedProjects) {
                bookmarkedProjects.forEach((bookmark) => {
                    const { clientName } = bookmark;

                    if (!bookmarkedProjectClients.includes(clientName)) {
                        bookmarkedProjectClients.push(clientName);
                    }
                });
            }

            return sortArray(bookmarkedProjectClients);
        }, [bookmarkedProjects, isLoadingBookmarkedProjects]);

        const getClient = useCallback(
            (clientName: string): any => {
                return clients.find((c: Client) => c.clientName === clientName) || {};
            },
            [clients]
        );

        const getClientBookmarkedProjects = useCallback(
            (clientName: string) => {
                const clientBookmarkedProjects = bookmarkedProjects.reduce((acc: Project[], cv: BookmarkedProject) => {
                    if (cv.clientName === clientName) {
                        acc.push(cv.project);
                    }

                    return acc;
                }, []);

                return clientBookmarkedProjects;
            },
            [bookmarkedProjects]
        );

        return (
            <div className="bookmarked-projects-container">
                {isEmpty(bookmarkedProjects) && !isLoadingBookmarkedProjects ? (
                    <div className="flex-column bookmarked-projects-container__no-bookmarks">
                        <NothingFoundBlock icon="files-assignment_b_s" title="There are no bookmarked projects" />
                    </div>
                ) : (
                    !isEmpty(clients) &&
                    !isLoadingClients && (
                        <>
                            <div className="bookmarked-projects-container__header">Bookmarked Projects</div>
                            <div>
                                <BookmarkedProjectStatusesPanel />
                            </div>
                            {bookmarkedProjectClients.map((clientName) => (
                                <div key={`${clientName}-bookmarked-projects`}>
                                    <BookmarkedProjectsPanel
                                        client={getClient(clientName)}
                                        bookmarkedProjects={getClientBookmarkedProjects(clientName)}
                                        isLoadingProjects={isLoadingBookmarkedProjects}
                                        user={user}
                                    />
                                </div>
                            ))}
                        </>
                    )
                )}
            </div>
        );
    }
);

export default BookmarkedProjectsContainer;
