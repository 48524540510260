import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";

import TagButton from "components/ui/Button/TagButton";
import IconWrap from "components/ui/Icons";

import { DropdownContainerProps } from "components/ui/Dropdown/types";

import "./style.scss";

const DropdownContainer = (props: DropdownContainerProps) => {
    const { onRemove } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [numberOfItemsVisible, setNumberOfItemsVisible] = useState(0);

    const valueContainerRef = useRef<HTMLDivElement>(null);

    const dropdownClassNames = cn("dropdown-field", props.dropdownClassName, {
        placeholder: !props.value || (Array.isArray(props.value) && isEmpty(props.value)),
        focus: isOpen,
        disabled: props.disabled,
        "read-only": props.readOnly,
        ghost: props.ghost,
        "input-table-filter": props.inputTableFilter,
        error: props.error,
        warning: props.warning,
    });

    const dropdownValueBoxContainerClassNames = cn("flex-row dropdown-container__wrapper__value-box-container", {
        "single-line": props.singleLine,
    });

    const fieldValue = useMemo(() => {
        let fieldValue: string | number | readonly string[] | undefined = props.value === undefined ? props.placeholder : props.value;

        if (props.multiple) {
            fieldValue = props.value === undefined ? [props.placeholder || ""] : props.value;
        }

        return fieldValue;
    }, [props.placeholder, props.multiple, props.value]);

    useEffect(() => {
        if (props.multiple && props.singleLine && Array.isArray(props.value) && !isEmpty(props.value) && valueContainerRef.current) {
            // Value container has a flex-wrap CSS property.
            // By checking each elements top offset, we get
            // to know when wrapping happens. When wrapping
            // has been applied, element top offset increases
            //
            // @ts-ignore
            const elementTopOffsets: number[] = Array.from(valueContainerRef.current.children).map((child) => child.offsetTop);

            // We can figure out how many elements there are
            // at the first row, by only filtering those
            // elements that have the same top offset as
            // the very first element
            const itemsVisible = elementTopOffsets.filter((offsetTop) => offsetTop === elementTopOffsets[0]).length;

            setNumberOfItemsVisible(itemsVisible);
        }
    }, [props.value, props.multiple, props.singleLine, valueContainerRef]);

    const onMouseDown = useCallback(
        (event) => {
            // Prevents from opening empty option box
            // which causes UI issues
            event.preventDefault();

            props.dropdownRef.current && props.dropdownRef.current.focus();
        },
        [props.dropdownRef]
    );

    const onBlur = useCallback(
        (event) => {
            // Do not close dropdown:
            //  - if input field is focused on
            //  - if "multiple" prop is provided and dropdown list is focused on
            if (
                !event.relatedTarget?.className.includes("input-field") &&
                !(props.multiple && event.relatedTarget?.className.includes("dropdown-list"))
            ) {
                setIsOpen(false);
            }
        },
        [props.multiple]
    );

    const onClick = useCallback(() => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    }, []);

    const onRemoveClick = useCallback(
        (value) => {
            if (props.value && Array.isArray(props.value)) {
                const newValue = props.value.filter((v) => v !== value) || [];

                onRemove?.(newValue);
            }
        },
        [props.value, onRemove]
    );

    const renderItems = () => {
        return (
            <>
                {!props.multiple && <option>{props.placeholder}</option>}
                {props.items.map((item, index) => (
                    <option key={`dropdown-item-${index}`} value={item.value} hidden>
                        {item.label}
                    </option>
                ))}
            </>
        );
    };

    const renderMultipleValues = () => {
        if (props.value && Array.isArray(props.value)) {
            const value = props.value;

            const selectedItems = props.items.filter((item) => value.includes(item.value));

            return (
                <>
                    <div ref={valueContainerRef} className="flex-row flex-wrap fill-height dropdown-container__wrapper__value-box">
                        {isEmpty(selectedItems) && (
                            <div className={cn("placeholder-value", { "placeholder-ghost-value": props.ghost })}>{props.placeholder}</div>
                        )}
                        {props.displayCount && !isEmpty(selectedItems) ? (
                            <>
                                <div className="counter-value">{`${selectedItems.length} selected`}</div>
                            </>
                        ) : (
                            <>
                                {selectedItems.map((item, index) => {
                                    const isHidden = !props.ghost && props.singleLine && index >= numberOfItemsVisible;

                                    return props.ghost ? (
                                        <div key={index} className="ghost-value">
                                            {`${item.label}${index < selectedItems.length - 1 ? "," : ""}`}
                                        </div>
                                    ) : (
                                        <div
                                            key={index}
                                            className={cn("button-tag-wrapper", {
                                                "hide-button-tag": isHidden,
                                            })}
                                        >
                                            <TagButton
                                                title={isHidden ? undefined : "Remove"}
                                                disabled={props.disabled}
                                                readOnly={props.readOnly}
                                                onClick={isHidden ? undefined : onClick}
                                                onRemove={isHidden ? undefined : () => onRemoveClick(item.value)}
                                            >
                                                {item.label}
                                            </TagButton>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                    {!props.ghost && props.singleLine && selectedItems.length > numberOfItemsVisible && (
                        <div>
                            <TagButton disabled={props.disabled} readOnly={props.readOnly} unremovable onClick={onClick}>{`+${
                                selectedItems.length - numberOfItemsVisible
                            }`}</TagButton>
                        </div>
                    )}
                </>
            );
        }
    };

    return (
        <div className="dropdown-container">
            <div ref={props.referenceElement} className="dropdown-container__wrapper">
                <select
                    data-testid={props.dataTestId}
                    ref={props.dropdownRef}
                    className={dropdownClassNames}
                    required={props.required}
                    disabled={props.disabled}
                    multiple={props.multiple}
                    value={fieldValue}
                    onMouseDown={onMouseDown}
                    onBlur={onBlur}
                    onClick={onClick}
                    // NOTE: To bypass "value" prop without "onChange" handler error.
                    //       There's probably a better approach
                    onChange={() => {}}
                >
                    {renderItems()}
                </select>
                {props.multiple && <div className={dropdownValueBoxContainerClassNames}>{renderMultipleValues()}</div>}
                {!props.ghost && <IconWrap icon={isOpen ? "shevron-in-circle-up-filled" : "shevron-small-down-expand-more"} />}
            </div>
            {isOpen && props.children}
        </div>
    );
};

export default DropdownContainer;
