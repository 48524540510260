import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { openModalDialogClearTrashBin } from "layouts/Modal/TrashBinDashboardModal/utils";

import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import Button from "components/ui/ButtonNew";

import { DashboardHeaderProps } from "./types";

import "./style.scss";

const DashboardHeader = memo(({ idProject, trashCount }: DashboardHeaderProps) => {
    const dispatch = useDispatch();

    const onDeleteClick = useCallback(() => {
        dispatch(
            openModalDialogClearTrashBin({
                idProject,
                trashCount,
            })
        );
    }, [idProject, trashCount, dispatch]);

    return (
        <div className="flex-column trash-bin-dashboard-header">
            <div className="flex-row align-center justify-space-between trash-bin-dashboard-header__container">
                <div className="trash-bin-dashboard-header__label">Trash bin</div>
                {hasAnyOfPermissions([USER_ACTIONS.PROJECTION_DELETE_ALL]) && (
                    <Button
                        variant="tertiary"
                        padding="md"
                        iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                        iconSize="sm"
                        isDisabled={trashCount === 0}
                        onClick={onDeleteClick}
                    >
                        Clear Trash Bin
                    </Button>
                )}
            </div>
        </div>
    );
});

export default DashboardHeader;
