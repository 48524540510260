import { v1 as uuidv1 } from "uuid";

import { projectInputCombinationsResourceName, projectInputCombinationsCheckResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";
import { getResource, waitReadResource } from "store/resources/actions/getResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource } from "store/resources/actions/updateResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { getResourceState } from "store/utils";

import {
    Combination,
    CombinationResponseModel,
    UseCombinationListParams,
    CreateCombinationParams,
    WaitReadCombinationParams,
    UpdateCombinationParams,
    DeleteCombinationParams,
    CheckModelInCombinationParams,
    GetCombinationResourceParams,
} from "./types";

const getResourceParams = ({
    idProject,
    idCombination,
    combinationName,
    bookmarked,
    modelInput,
    updateItems,
    onComplete,
    dispatch,
}: GetCombinationResourceParams) => ({
    resourceName: projectInputCombinationsResourceName,
    key: `${projectInputCombinationsResourceName}-${idProject}`,
    path: idCombination ? { idCombination } : undefined,
    body: {
        idCombination,
        idProject,
        combinationName,
        bookmarked,
        modelInput,
    },
    optimisticUpdate: updateItems?.length
        ? {
              value: {
                  model: updateItems,
              },
          }
        : undefined,
    updateFromResponse: false,
    showSuccessNotification: false,
    onComplete: () => {
        onComplete && onComplete();

        dispatch(clearCombinationList({ idProject }));
    },
});

export const useCombinationList = ({ idProject }: UseCombinationListParams) =>
    useResource({
        resourceName: projectInputCombinationsResourceName,
        key: `${projectInputCombinationsResourceName}-${idProject}`,
        query: {
            idProject,
        },
        transform: (data: CombinationResponseModel) => data?.model,
    });

export const clearCombinationList =
    ({ idProject }: UseCombinationListParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: projectInputCombinationsResourceName,
                key: `${projectInputCombinationsResourceName}-${idProject}`,
                broadcast: true,
            })
        );

const waitReadCombinations =
    ({ idProject, action }: WaitReadCombinationParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            waitReadResource({
                resourceName: projectInputCombinationsResourceName,
                key: `${projectInputCombinationsResourceName}-${idProject}`,
                actionName: action,
            })
        );

export const createCombination =
    ({ idProject, combinationName, modelInput, action, onComplete }: CreateCombinationParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        dispatch(waitReadCombinations({ idProject, action }));

        const state = getState();

        const combinations = getResourceState<Combination>(state, projectInputCombinationsResourceName, { idProject });

        const updateItems: Combination[] = [
            {
                // @ts-ignore - specific for optimistic update
                idCombination: uuidv1(),
                idProject,
                combinationName,
            },
            ...combinations,
        ];

        dispatch(
            createResource(
                getResourceParams({
                    idProject,
                    combinationName,
                    modelInput,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const updateCombination =
    ({ idCombination, idProject, combinationName, bookmarked, action, onComplete }: UpdateCombinationParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        dispatch(waitReadCombinations({ idProject, action }));

        const state = getState();

        const combinations = getResourceState<Combination>(state, projectInputCombinationsResourceName, { idProject });

        const updateItems = combinations.map((combo) =>
            combo.idCombination === idCombination
                ? {
                      ...combo,
                      combinationName: combinationName || combo.combinationName,
                      bookmarked: bookmarked || combo.bookmarked,
                  }
                : combo
        );

        dispatch(
            updateResource(
                getResourceParams({
                    idCombination,
                    idProject,
                    combinationName,
                    bookmarked,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const deleteCombination =
    ({ idProject, idCombination, action, onComplete }: DeleteCombinationParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        dispatch(waitReadCombinations({ idProject, action }));

        const state = getState();

        const combinations = getResourceState<Combination>(state, projectInputCombinationsResourceName, { idProject });

        const updateItems = combinations?.filter((combo) => combo.idCombination !== idCombination);

        dispatch(
            deleteResource(
                getResourceParams({
                    idCombination,
                    idProject,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const checkModelInCombination =
    ({ idProject, idInputLog }: CheckModelInCombinationParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            getResource({
                resourceName: projectInputCombinationsCheckResourceName,
                key: `${projectInputCombinationsCheckResourceName}-${idProject}-${idInputLog}`,
                path: {
                    idInputLog,
                },
            })
        );
    };
