import {
    DOWNLOAD_FILE_START,
    DOWNLOAD_FILE_STARTED,
    DOWNLOAD_FILE_PROGRESS,
    DOWNLOAD_FILE_COMPLETE,
    DOWNLOAD_FILE_ERROR,
} from "store/actionTypes";

import { DownloadFileState, DownloadFileAction } from "./types";

const initialState = {};

export const reducer = (state: DownloadFileState = initialState, action: DownloadFileAction) => {
    switch (action.type) {
        case DOWNLOAD_FILE_START:
            state = {
                ...state,
                [`${action.id}`]: {
                    completed: false,
                },
            };

            break;

        case DOWNLOAD_FILE_STARTED:
            state = {
                ...state,
                [`${action.id}`]: {
                    ...state[action.id],
                    contentLength: action.contentLength,
                },
            };

            break;

        case DOWNLOAD_FILE_PROGRESS:
            state = {
                ...state,
                [`${action.id}`]: {
                    ...state[action.id],
                    receivedLength: action.receivedLength,
                },
            };

            break;

        case DOWNLOAD_FILE_COMPLETE:
            state = {
                ...state,
                [`${action.id}`]: {
                    ...state[action.id],
                    completed: true,
                },
            };

            break;

        case DOWNLOAD_FILE_ERROR:
            state = {
                ...state,
                [`${action.id}`]: {
                    ...state[action.id],
                    error: action.error,
                    completed: true,
                },
            };

            break;

        default:
            break;
    }

    return state;
};
