import { SIDENAV_OPEN, SIDENAV_CLOSE } from "store/actionTypes";

export const sideNavOpen =
    ({ id, props }) =>
    (dispatch) => {
        const openAction = {
            type: SIDENAV_OPEN,
            id,
            props,
        };

        dispatch(openAction);
    };

export const sideNavClose =
    ({ id }) =>
    (dispatch, getState) => {
        const state = getState();

        const sideNav = state.sideNav;

        const closeAction = {
            type: SIDENAV_CLOSE,
            id,
        };

        if (sideNav[id]) {
            dispatch(closeAction);
        }
    };
