import { v1 as uuidv1 } from "uuid";
import { isEmpty, isNil } from "lodash";
import { batch } from "react-redux";

import { getScenarioLogKey, getProjectLogItems, clearProjectLogItems, clearModelLogItems } from "./scenarioLogActions";

import { modelLogNotesResourceName, scenarioLogResourceName } from "store/configureResources";
import { getResourceState } from "store/utils";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource, optimisticUpdateItem } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { logActionKeys } from "utils/scenarioLog";
import { getVdsmUser } from "utils/user";

import {
    ProjectLogItem,
    ModelLogNote,
    ModelLogNoteResponseModel,
    UseModelLogNoteParams,
    CreateModelLogNoteParams,
    UpdateModelLogNoteParams,
    DeleteModelLogNoteParams,
    ClearModelLogNoteParams,
    CopyModelLogNoteParams,
    GetModelLogNoteResourceParams,
} from "./types";

const getResourceParams = ({
    idProject,
    idInputLog,
    idStudyLog,
    idModel,
    idModelNote,
    description,
    noteType,
    isPublic,
    updateItems,
    updateFromResponse = false,
    onComplete,
    dispatch,
}: GetModelLogNoteResourceParams) => ({
    resourceName: modelLogNotesResourceName,
    key: `${modelLogNotesResourceName}-${idStudyLog}`,
    query: {
        idProject,
        idInputLog,
    },
    body: {
        idModelNote,
        idStudyLog,
        title: "",
        description,
        noteType,
        isPublic,
    },
    updateFromResponse,
    showSuccessNotification: false,
    onComplete: () => {
        batch(() => {
            onComplete?.();

            if (!updateFromResponse) {
                dispatch(clearModelLogNotesList({ idStudyLog }));
            }

            dispatch(clearProjectLogItems({ idProject }));

            if (idModel) {
                dispatch(clearModelLogItems({ idProject, idModel }));
            } else {
                dispatch(clearModelLogItems({ idProject, idInputLog }));
            }
        });
    },
    optimisticUpdate: updateItems?.length
        ? {
              value: {
                  model: updateItems,
              },
          }
        : undefined,
});

export const useModelLogNotes = ({ idStudyLog }: UseModelLogNoteParams) =>
    useResource({
        resourceName: modelLogNotesResourceName,
        key: idStudyLog ? `${modelLogNotesResourceName}-${idStudyLog}` : undefined,
        query: { idStudyLog },
        transform: (data: ModelLogNoteResponseModel) => (data?.model ? data?.model : data),
    });

export const clearModelLogNotesList =
    ({ idStudyLog }: ClearModelLogNoteParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: modelLogNotesResourceName,
                key: `${modelLogNotesResourceName}-${idStudyLog}`,
                broadcast: true,
            })
        );

export const createModelLogNote =
    ({ idProject, idInputLog, idStudyLog, idModel, description, noteType, isPublic = false, onComplete }: CreateModelLogNoteParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const modelLogNotes = getResourceState<ModelLogNote>(state, modelLogNotesResourceName, { idStudyLog });

        let modelLogItems;

        if (!isNil(idModel)) {
            modelLogItems = getResourceState<any>(state, scenarioLogResourceName, {
                key: getScenarioLogKey({ idProject, idModel, withoutResourceName: true }),
            });
        } else {
            modelLogItems = getResourceState<any>(state, scenarioLogResourceName, {
                key: getScenarioLogKey({ idProject, idInputLog, withoutResourceName: true }),
            });
        }

        const { firstName, lastName } = getVdsmUser();

        const lastUpdateDate = new Date().toISOString().substring(0, 23); // exclude UTC timezone

        let updateLogItems: any[] = [];

        // If note added to action log,
        // add loader to that log
        if (noteType === "system-entry") {
            updateLogItems = modelLogItems.map((item) =>
                item.idStudyLog === idStudyLog
                    ? {
                          ...item,
                          loadingNote: true,
                      }
                    : item
            );
        }
        // If new entry added to the log,
        // add loader to that new entry
        else if (noteType === "user-note") {
            updateLogItems = [...modelLogItems];

            updateLogItems.unshift({
                idStudyLog: uuidv1(),
                idInputLog,
                idModel: isNil(idModel) ? 0 : idModel,
                idReport: 0,
                actionName: "USER_NOTE",
                started: lastUpdateDate,
                executionTime: "00:00:00",
                creatorFirstName: firstName,
                creatorLastName: lastName,
                loadingNote: true,
            });
        }

        const updateNoteItems = [...modelLogNotes];

        updateNoteItems.unshift({
            // @ts-ignore - specific case only used for optimistic update
            idModelNote: uuidv1(),
            idStudyLog,
            description,
            noteType,
            isPublic,
            firstName,
            lastName,
            lastUpdateDate,
        });

        batch(() => {
            if (!isEmpty(updateLogItems)) {
                dispatch(
                    optimisticUpdateItem({
                        resourceName: scenarioLogResourceName,
                        key: getScenarioLogKey({ idProject, idInputLog, idModel }),
                        value: {
                            model: updateLogItems,
                        },
                    })
                );
            }

            dispatch(
                createResource(
                    getResourceParams({
                        idProject,
                        idInputLog,
                        idStudyLog,
                        idModel,
                        description,
                        noteType,
                        isPublic,
                        updateItems: updateNoteItems,
                        updateFromResponse: true,
                        onComplete,
                        dispatch,
                    })
                )
            );
        });
    };

export const updateModelLogNote =
    ({ idProject, idInputLog, idModel, idStudyLog, idModelNote, description, noteType, onComplete }: UpdateModelLogNoteParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const modelLogNotes = getResourceState<ModelLogNote>(state, modelLogNotesResourceName, { idStudyLog });

        const updateItems = modelLogNotes.map((u) =>
            u.idModelNote === idModelNote
                ? {
                      ...u,
                      description: description || null,
                      noteType: noteType || null,
                  }
                : u
        );

        dispatch(
            updateResource(
                getResourceParams({
                    idProject,
                    idInputLog,
                    idStudyLog,
                    idModel,
                    idModelNote,
                    description,
                    noteType,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const deleteModelLogNote =
    ({ idProject, idInputLog, idStudyLog, idModelNote, idModel, onComplete }: DeleteModelLogNoteParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const modelLogNotes = getResourceState<ModelLogNote>(state, modelLogNotesResourceName, { idStudyLog });

        let modelLogItems = getResourceState<any>(state, scenarioLogResourceName, {
            key: getScenarioLogKey({ idProject, idInputLog, withoutResourceName: true }),
        });

        if (!isNil(idModel)) {
            modelLogItems = getResourceState<any>(state, scenarioLogResourceName, {
                key: getScenarioLogKey({ idProject, idModel, withoutResourceName: true }),
            });
        }

        const updateLogItems = modelLogItems.map((item) =>
            item.idStudyLog === idStudyLog
                ? {
                      ...item,
                      loadingNote: true,
                  }
                : item
        );

        const updateNoteItems = modelLogNotes.filter((u) => u.idModelNote !== idModelNote);

        batch(() => {
            if (!isEmpty(updateLogItems)) {
                dispatch(
                    optimisticUpdateItem({
                        resourceName: scenarioLogResourceName,
                        key: getScenarioLogKey({ idProject, idInputLog, idModel }),
                        value: {
                            model: updateLogItems,
                        },
                    })
                );
            }

            dispatch(
                deleteResource({
                    resourceName: modelLogNotesResourceName,
                    key: `${modelLogNotesResourceName}-${idStudyLog}`,
                    path: { idModelNote },
                    onComplete: () => {
                        onComplete?.();

                        dispatch(clearModelLogNotesList({ idStudyLog }));

                        dispatch(clearProjectLogItems({ idProject }));

                        if (idModel) {
                            dispatch(clearModelLogItems({ idProject, idModel }));
                        } else {
                            dispatch(clearModelLogItems({ idProject, idInputLog }));
                        }
                    },
                    optimisticUpdate: {
                        value: {
                            model: updateNoteItems,
                        },
                    },
                })
            );
        });
    };

const copyModelLogNoteSync =
    ({ idProject, idInputLog, idModel, userNote, isPublic }: CopyModelLogNoteParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const projectLogItems = getResourceState<ProjectLogItem>(state, scenarioLogResourceName, { idProject });

        // Filter list by specific status changes
        const filteredProjectLogItems = projectLogItems.filter((item) =>
            [
                logActionKeys.MODEL_STATUS_IN_CLIENT_REVIEW,
                logActionKeys.MODEL_STATUS_CLIENT_APPROVED,
                logActionKeys.MODEL_STATUS_CLIENT_UNAPPROVED,
            ].includes(item.actionName)
        );

        if (!isEmpty(filteredProjectLogItems)) {
            const { idStudyLog } = filteredProjectLogItems[0]; // take the latest entry

            dispatch(
                createModelLogNote({
                    idProject,
                    idInputLog,
                    idStudyLog,
                    idModel,
                    description: userNote,
                    noteType: "system-entry",
                    isPublic,
                })
            );
        }
    };

export const copyModelLogNote =
    ({ idProject, idInputLog, idModel, userNote, isPublic }: CopyModelLogNoteParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            getProjectLogItems({
                idProject,
                onSuccess: () => {
                    dispatch(copyModelLogNoteSync({ idProject, idInputLog, idModel, userNote, isPublic }));
                },
            })
        );
    };
