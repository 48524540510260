import { openModalDialog } from "layouts/Modal/utils";

import EditHtmlReport, { EditHtmlReportProps } from "./EditHtmlReport";
import EditPbiReport, { EditPbiReportProps } from "./EditPbiReport";

export const openModalDialogEditHtmlReport = (props: Omit<EditHtmlReportProps, "onCancel">) => (dispatch) => {
    dispatch(
        openModalDialog({
            title: props.editing ? "Edit HTML Report" : "Add HTML Report",
            component: <EditHtmlReport {...props} />,
        })
    );
};

export const openModalDialogEditPbiReport = (props: Omit<EditPbiReportProps, "onCancel">) => (dispatch) => {
    dispatch(
        openModalDialog({
            title: props.editing ? "Edit PBI Report" : "Add PBI Report",
            component: <EditPbiReport {...props} />,
        })
    );
};
