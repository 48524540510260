import { USER_ROLES } from "utils/user/defines";

import { ModelInputConfig } from "./types";
import { IndexSignature } from "types/types";
import { ModuleType } from "store/resources/actions/calculationWorkflow/types";

// Compile time flags to identify environment we are in

export const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT";
export const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION";

export const VLM_URL = "visionloadmap.com";
export const isVLMLoginPage = window.location.hostname.includes(VLM_URL);

export const clientRoute = {
    resetPassword: "resetpassword",
    saml: "saml",
    reports: "reports",
};

export const ckEditorConfig = {
    toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "outdent", "indent", "|", "undo", "redo"],
};

// Model Input Types

export enum MODEL_INPUT {
    AVOIDED_COSTS,
    MARKET_SIZE,
    POTENTIAL_FACTORS,
    PROGRAM_COSTS,
    PURCHASE_SHARE,
    SATURATION,
    SPECIAL_MARKETS,
    TECH_DATA,
    UEC_AND_EUI,
    UTILIZATION_ELASTICITY,
    VINTAGE_DATA,
    ACHIEVABILITY,
    PURCHASE_OVERRIDE,
    RAMP_RATES,
    PRE_DEFINED,
    NEM_DATA,
}

// Power BI workspace ID (environment ID - dev deployed or prod deployed)

export const workspaceId = process.env.REACT_APP_POWERBI_WORKSPACE_ID;

// Power BI report IDs

export const singleModelReportId = process.env.REACT_APP_POWERBI_VLM_SINGLE_MODEL_REPORT_V2_ID;
export const combinedModelReportId = process.env.REACT_APP_POWERBI_VLM_COMBINED_MODEL_REPORT_V2_ID;
export const measureLevelReportId = process.env.REACT_APP_POWERBI_MEASURE_LEVEL_REPORT_ID;
export const projectComparisonReportId = process.env.REACT_APP_POWERBI_VLM_PROJECT_COMPARISON_REPORT_V2_ID;

export const modelInputLevelReportIds = Object.keys(MODEL_INPUT).reduce(
    (acc, key) => ({
        ...acc,
        [key]: process.env[`REACT_APP_POWERBI_${key}_REPORT_ID`],
    }),
    {} as unknown as ModelInputConfig
);

// Power BI dataset IDs

export const singleModelDatasetId = process.env.REACT_APP_POWERBI_VLM_SINGLE_MODEL_DATASET_ID;
export const combinedModelDatasetId = process.env.REACT_APP_POWERBI_VLM_COMBINED_MODEL_DATASET_ID;
export const measureLevelDatasetId = process.env.REACT_APP_POWERBI_MEASURE_LEVEL_DATASET_ID;
export const projectComparisonDatasetId = process.env.REACT_APP_POWERBI_VLM_PROJECT_COMPARISON_DATASET_ID;
export const measureSourcingDatasetId = process.env.REACT_APP_POWERBI_MEASURE_SOURCING_DATASET_ID;

export const modelInputLevelDatasetIds = Object.keys(MODEL_INPUT).reduce(
    (acc, key) => ({
        ...acc,
        [key]: process.env[`REACT_APP_POWERBI_${key}_DATASET_ID`],
    }),
    {} as unknown as ModelInputConfig
);

// Help Tip IDs

// Home Dashboard panels

// export const RECENT_VIEWED_PROJECT_LIST = 1; // unused
// export const BOOKMARKED_PROJECT_LIST = 2; // unused
// export const CLIENTS_WITH_OPEN_PROJECTS = 3; // unused
// export const PROJECT_STATUSES = 4; // unused
export const CLIENT_LIST = 5;
export const PROJECT_LIST = 6;
// add new panel ids here...

// Project Dashboard panels

export const PROJECT_DETAILS = 7;
export const PROJECT_STUDY_CASE = 8;
export const MODELS = 9;
export const MARKET_PROFILE_DATA = 10;
export const MARKET_PROFILE_SNAPSHOT = 11;
export const PROJECT_VARIABLES = 12;
export const PROJECT_LABELS = 13;
export const PROJECT_LOG = 14;
// add new panel ids here...

// Model Dashboard panels

export const MODEL_STUDY_CASE = 15;
export const MODEL_VARIABLES = 16;
export const MODEL_INPUTS = 17;
export const MODEL_LOG = 18;
export const UTILITY_FORECAST = 19;
export const CALCULATED_RESULTS = 22;
// add new panel ids here...

// Standalone Report Dashboard panels

export const STANDALONE_REPORT_REPORT_LOG = 20;
export const STANDALONE_REPORT_EMBEDDED_PANEL = 21;
// add new panel ids here...

// Client Management tabs

export const clientManagementTabs = [
    {
        id: 1,
        title: "Attribute",
    },
    {
        id: 2,
        title: "Project Results",
    },
    {
        id: 3,
        title: "Project Sources",
    },
];

// Types of Projects

export const projectTypeItems = [
    {
        dataTestId: "project-type-default",
        label: "Potential Study",
        value: "potentialStudy",
    },
    {
        label: "Other",
        value: "other",
    },
];

// Types of Project Files

export const fileShareTypes = ["OtherReport", "FinalReport"];
export const internalTypes = ["OtherReport", "DRProgramInputs", "DRForecast"];

// All types of Projections

export const projections = {
    // Types of creations
    MODEL_SETUP: "MODEL SETUP",
    COMPONENT_MODEL_SETUP: "COMPONENT MODEL SETUP",

    // Types of dashboards
    PROJECT_DASHBOARD: "PROJECT DASHBOARD",
    MODEL_DASHBOARD: "MODEL DASHBOARD",
    COMPONENT_MODEL_DASHBOARD: "COMPONENT MODEL DASHBOARD",
    STANDALONE_REPORT_DASHBOARD: "STANDALONE REPORT DASHBOARD",
    TRASH_BIN_DASHBOARD: "TRASH_BIN_DASHBORAD",
};

// Types of Modules

export const moduleTypes = {
    BASELINE: "BASELINE" as ModuleType,
    EE_POTENTIAL: "EE_POTENTIAL" as ModuleType,
    DR_POTENTIAL: "DR_POTENTIAL" as ModuleType,
};

export const moduleTypeLabels = {
    [moduleTypes.BASELINE]: "Baseline",
    [moduleTypes.EE_POTENTIAL]: "EE Potential",
    [moduleTypes.DR_POTENTIAL]: "DR Potential",
};

export const moduleRunState: IndexSignature<{ label: string; variant: "brand-d" | "success" | "critical" | "information" }> = {
    DATA_MISSING: {
        variant: "critical",
        label: "Required Data Missing",
    },
    PENDING: {
        variant: "information",
        label: "Pending",
    },
    RUN_MODULE_START: {
        variant: "brand-d",
        label: "Running Calculations",
    },
    RUN_MODULE_V2_START: {
        variant: "brand-d",
        label: "Running Calculations",
    },
    RUNNING: {
        variant: "brand-d",
        label: "Running Calculations",
    },
    IN_PROGRESS: {
        variant: "brand-d",
        label: "Running Calculations",
    },
    PAUSE_MODULE_START: {
        variant: "brand-d",
        label: "Cancelling Calculations",
    },
    PAUSE_MODULE_V2_START: {
        variant: "brand-d",
        label: "Cancelling Calculations",
    },
    PAUSE_MODULE_STARTED: {
        variant: "brand-d",
        label: "Cancelling Calculations",
    },
    PAUSED: {
        variant: "brand-d",
        label: "Paused",
    },
    DONE: {
        variant: "success",
        label: "Calculated",
    },
    CALCULATED: {
        variant: "success",
        label: "Calculated",
    },
    ERROR: {
        variant: "critical",
        label: "Error",
    },
    RECALC_PENDING: {
        variant: "information",
        label: "Pending",
    },
};

// Status Levels

// Project Status - Manage goals, timelines, and final deliverables

export const allProjectStates = {
    PROPOSED: "Proposed",
    IN_PROGRESS: "In Progress",
    FINAL_REPORT: "Final Report",
};

export const projectStates = {
    [USER_ROLES.MANAGER]: {
        [allProjectStates.PROPOSED.toUpperCase()]: [allProjectStates.PROPOSED, allProjectStates.IN_PROGRESS],
        [allProjectStates.IN_PROGRESS.toUpperCase()]: [
            allProjectStates.IN_PROGRESS,
            allProjectStates.PROPOSED,
            allProjectStates.FINAL_REPORT,
        ],
        [allProjectStates.FINAL_REPORT.toUpperCase()]: [allProjectStates.FINAL_REPORT, allProjectStates.IN_PROGRESS],
    },
};

// Legacy Model Status

export const allModelStates = {
    PLANNED: "Planned",
    IN_PROGRESS: "In Progress",
    IN_MANAGER_REVIEW: "In Manager Review",
    IN_CLIENT_REVIEW: "In Client Review",
    CLIENT_UNAPPROVED: "Client Unapproved",
    CLIENT_APPROVED: "Approved",
};

export const modelStates = {
    [USER_ROLES.MANAGER]: {
        [allModelStates.PLANNED.toUpperCase()]: [allModelStates.PLANNED, allModelStates.IN_PROGRESS, allModelStates.IN_MANAGER_REVIEW],
        [allModelStates.IN_PROGRESS.toUpperCase()]: [allModelStates.IN_PROGRESS, allModelStates.PLANNED, allModelStates.IN_MANAGER_REVIEW],
        [allModelStates.IN_MANAGER_REVIEW.toUpperCase()]: [
            allModelStates.IN_MANAGER_REVIEW,
            allModelStates.IN_PROGRESS,
            allModelStates.IN_CLIENT_REVIEW,
        ],
        [allModelStates.IN_CLIENT_REVIEW.toUpperCase()]: [
            allModelStates.IN_CLIENT_REVIEW,
            allModelStates.IN_PROGRESS,
            allModelStates.IN_MANAGER_REVIEW,
            allModelStates.CLIENT_UNAPPROVED,
            allModelStates.CLIENT_APPROVED,
        ],
        [allModelStates.CLIENT_UNAPPROVED.toUpperCase()]: [
            allModelStates.CLIENT_UNAPPROVED,
            allModelStates.IN_PROGRESS,
            allModelStates.IN_MANAGER_REVIEW,
        ],
        [allModelStates.CLIENT_APPROVED.toUpperCase()]: [
            allModelStates.CLIENT_APPROVED,
            allModelStates.IN_PROGRESS,
            allModelStates.IN_MANAGER_REVIEW,
        ],
    },
    [USER_ROLES.ANALYST]: {
        [allModelStates.PLANNED.toUpperCase()]: [allModelStates.PLANNED, allModelStates.IN_PROGRESS, allModelStates.IN_MANAGER_REVIEW],
        [allModelStates.IN_PROGRESS.toUpperCase()]: [allModelStates.IN_PROGRESS, allModelStates.PLANNED, allModelStates.IN_MANAGER_REVIEW],
    },
    [USER_ROLES.BASIC_PRIMARY_CONTACT]: {
        [allModelStates.IN_CLIENT_REVIEW.toUpperCase()]: [
            allModelStates.IN_CLIENT_REVIEW,
            allModelStates.CLIENT_UNAPPROVED,
            allModelStates.CLIENT_APPROVED,
        ],
    },
    [USER_ROLES.ADVANCED_CLIENT]: {
        [allModelStates.IN_CLIENT_REVIEW.toUpperCase()]: [
            allModelStates.IN_CLIENT_REVIEW,
            allModelStates.CLIENT_UNAPPROVED,
            allModelStates.CLIENT_APPROVED,
        ],
    },
};

// Component Model Status - Manage individual chunks of work

export const allComponentModelStates = {
    PROPOSED: "Proposed",
    IN_PROGRESS: "In Progress",
    FINAL: "Final",
};

export const componentModelStates = {
    [USER_ROLES.MANAGER]: {
        [allComponentModelStates.PROPOSED.toUpperCase()]: [allComponentModelStates.PROPOSED, allComponentModelStates.IN_PROGRESS],
        [allComponentModelStates.IN_PROGRESS.toUpperCase()]: [
            allComponentModelStates.IN_PROGRESS,
            allComponentModelStates.PROPOSED,
            allComponentModelStates.FINAL,
        ],
        [allComponentModelStates.FINAL.toUpperCase()]: [allComponentModelStates.FINAL, allComponentModelStates.IN_PROGRESS],
    },
    [USER_ROLES.ANALYST]: {
        [allComponentModelStates.PROPOSED.toUpperCase()]: [allComponentModelStates.PROPOSED, allComponentModelStates.IN_PROGRESS],
        [allComponentModelStates.IN_PROGRESS.toUpperCase()]: [allComponentModelStates.IN_PROGRESS, allComponentModelStates.PROPOSED],
    },
};

// Model Input Status - Manage inputs that make up the model

export const allModelInputStates = {
    WAITING_ON_DATA: "Waiting On Data",
    IN_PROGRESS: "In Progress",
    PENDING_REVIEW: "Pending Review",
    MANAGER_SIGNED_OFF: "Manager Signed Off",
};

export const modelInputStates = {
    [USER_ROLES.MANAGER]: {
        // TODO: Remove when Model Input checks are added back
        [allModelInputStates.WAITING_ON_DATA.toUpperCase()]: [
            allModelInputStates.WAITING_ON_DATA,
            allModelInputStates.IN_PROGRESS,
            allModelInputStates.PENDING_REVIEW,
        ],
        // TODO: Remove when Model Input checks are added back
        [allModelInputStates.IN_PROGRESS.toUpperCase()]: [
            allModelInputStates.IN_PROGRESS,
            allModelInputStates.WAITING_ON_DATA,
            allModelInputStates.PENDING_REVIEW,
        ],
        [allModelInputStates.PENDING_REVIEW.toUpperCase()]: [
            allModelInputStates.PENDING_REVIEW,
            allModelInputStates.IN_PROGRESS,
            allModelInputStates.MANAGER_SIGNED_OFF,
        ],
        [allModelInputStates.MANAGER_SIGNED_OFF.toUpperCase()]: [
            allModelInputStates.MANAGER_SIGNED_OFF,
            allModelInputStates.IN_PROGRESS,
            allModelInputStates.PENDING_REVIEW,
        ],
    },
    [USER_ROLES.ANALYST]: {
        [allModelInputStates.WAITING_ON_DATA.toUpperCase()]: [
            allModelInputStates.WAITING_ON_DATA,
            allModelInputStates.IN_PROGRESS,
            allModelInputStates.PENDING_REVIEW,
        ],
        [allModelInputStates.IN_PROGRESS.toUpperCase()]: [
            allModelInputStates.IN_PROGRESS,
            allModelInputStates.WAITING_ON_DATA,
            allModelInputStates.PENDING_REVIEW,
        ],
    },
};

// Module Status - Configure parameters against a group of components

export const allModuleStates = {
    PLANNED: "Planned",
    IN_PROGRESS: "In Progress",
    IN_MANAGER_REVIEW: "In Manager Review",
    IN_CLIENT_REVIEW: "In Client Review",
    CLIENT_UNAPPROVED: "Client Unapproved",
    CLIENT_APPROVED: "Approved",
};

export const moduleStates = {
    [USER_ROLES.MANAGER]: {
        [allModuleStates.PLANNED.toUpperCase()]: [allModuleStates.PLANNED, allModuleStates.IN_PROGRESS, allModuleStates.IN_MANAGER_REVIEW],
        [allModuleStates.IN_PROGRESS.toUpperCase()]: [
            allModuleStates.IN_PROGRESS,
            allModuleStates.PLANNED,
            allModuleStates.IN_MANAGER_REVIEW,
        ],
        [allModuleStates.IN_MANAGER_REVIEW.toUpperCase()]: [
            allModuleStates.IN_MANAGER_REVIEW,
            allModuleStates.IN_PROGRESS,
            allModuleStates.IN_CLIENT_REVIEW,
        ],
        [allModuleStates.IN_CLIENT_REVIEW.toUpperCase()]: [
            allModuleStates.IN_CLIENT_REVIEW,
            allModuleStates.IN_PROGRESS,
            allModuleStates.IN_MANAGER_REVIEW,
            allModuleStates.CLIENT_UNAPPROVED,
            allModuleStates.CLIENT_APPROVED,
        ],
        [allModuleStates.CLIENT_UNAPPROVED.toUpperCase()]: [
            allModuleStates.CLIENT_UNAPPROVED,
            allModuleStates.IN_PROGRESS,
            allModuleStates.IN_MANAGER_REVIEW,
        ],
        [allModuleStates.CLIENT_APPROVED.toUpperCase()]: [
            allModuleStates.CLIENT_APPROVED,
            allModuleStates.IN_PROGRESS,
            allModuleStates.IN_MANAGER_REVIEW,
        ],
    },
    [USER_ROLES.ANALYST]: {
        [allModuleStates.PLANNED.toUpperCase()]: [allModuleStates.PLANNED, allModuleStates.IN_PROGRESS, allModuleStates.IN_MANAGER_REVIEW],
        [allModuleStates.IN_PROGRESS.toUpperCase()]: [
            allModuleStates.IN_PROGRESS,
            allModuleStates.PLANNED,
            allModuleStates.IN_MANAGER_REVIEW,
        ],
    },
    [USER_ROLES.ADVANCED_CLIENT]: {
        [allModelStates.IN_CLIENT_REVIEW.toUpperCase()]: [
            allModelStates.IN_CLIENT_REVIEW,
            allModelStates.CLIENT_UNAPPROVED,
            allModelStates.CLIENT_APPROVED,
        ],
    },
};

// Status abbreviations

export const statusAbbreviations = {
    PLANNED: "PLND",
    PROPOSED: "PRPSD",
    "IN PROGRESS": "PRG",
    "IN MANAGER REVIEW": "MGR",
    "IN CLIENT REVIEW": "CLNT",
    "CLIENT UNAPPROVED": "UAPV",
    "CLIENT APPROVED": "APV",
    FINAL: "FNL",
};

// Generic statuses

export const actionStatus = {
    STARTED: "STARTED",
    IN_PROGRESS: "IN PROGRESS",
    DONE: "DONE",
    ERROR: "ERROR",
    WARNING: "WARNING",
    FAILED: "FAILED",
    TERMINATED: "TERMINATED",
    OUT_OF_DATE: "OUT OF DATE",
};
