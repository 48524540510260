import * as React from "react";
import cn from "classnames";

import Button from "components/ui/ButtonNew";

import "./style.scss";

const SideNavHeader = (props: SideNavHeaderProps) => {
    const sideNavHeaderClassName = cn("sidenav-header", props.className);

    return (
        <div className={sideNavHeaderClassName}>
            <div className="flex-row align-center justify-space-between">
                <div className={cn("sidenav-header__title", { "fill-width": !Boolean(props.onClose) })}>
                    {props.title || props.children}
                </div>
                {props.onClose && (
                    <Button title="Close" variant="tertiary" icon="ui-form-error" iconSize="sm" padding="sm" onClick={props.onClose} />
                )}
            </div>
        </div>
    );
};

export interface SideNavHeaderProps {
    className?: string;

    /**
     * Title of the side panel.
     */
    title?: string;

    children?: React.ReactNode;

    /**
     * Function to run on close action.
     */
    onClose?: React.MouseEventHandler<HTMLDivElement | HTMLIdsButtonElement>;
}

export default SideNavHeader;
