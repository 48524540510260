import { chain, find, forEach, has, isEmpty, isNil, map, mapValues, omit, set, startCase } from "lodash";
import { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";

import {
    getFromStoreAvoidedCostsList,
    updateAvoidedCostsTemplate,
    useAvoidedCostsTemplateSectors,
    useAvoidedCostsTemplateValidation,
} from "store/resources/actions/avoidedCosts/avoidedCostsTemplateActions";
import { useActiveViewsAvoidedCostsList } from "store/resources/actions/activeViews/activeViewAvoidedCostsActions";

import { openModalDialogConfirmBulkEdit } from "layouts/Modal/AvoidedCostsModal/utils";
import { openModalDialogAvoidedCostsBulkEdit } from "components/modals/AvoidedCostsModals/openModalDialogAvoidedCostsBulkEdit";
import { openModalDialogAvoidedCostsDeleteRows } from "components/modals/AvoidedCostsModals/openModalDialogAvoidedCostsDeleteRows";

import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import IconWrap from "components/ui/Icons";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import Input from "components/ui/Input";
import Checkbox from "components/ui/Input/Checkbox";
import CustomList from "components/ui/List/CustomList";

import {
    serviceColumns,
    freezedColumns,
    filterErrorKey,
    errorFiltersNames,
    isAssignableSectors,
    transformAvoidedCostTemplate,
    initErrorFilters,
    applyErrorFilters,
    nullifyErrorFilter,
    deleteErrorFilters,
} from "pages/ModifyAvoidedCosts/utils";

import { AvoidedCostsProps } from "pages/ModifyAvoidedCosts/types";

import "./style.scss";

// TODO: activeItem as ref
const AvoidedCosts = memo(
    ({
        idProject,
        idInputLog,
        activeTab,
        avoidedCostsTemplateItemsGroupedBySectorRef,
        onUpdate,
        onShowValidationPanel,
        onSaveAvoidedCosts,
    }: AvoidedCostsProps) => {
        const dispatch = useDispatch();

        // @ts-ignore
        const userNumber = useSelector((state) => state.vdsmUser.userNumber);

        // Resources

        const [sectorList, isLoadingSectorList] = useAvoidedCostsTemplateSectors({ idInputLog });

        const [activeViews] = useActiveViewsAvoidedCostsList({ idProject, idInputLog });

        // @ts-ignore
        const [avoidedCostsValidation] = useAvoidedCostsTemplateValidation({ idInputLog });

        // States

        const [listUpdate, forceListUpdate] = useReducer(
            (state, action) => {
                switch (action.type) {
                    case "update":
                        return { count: state.count + 1 };
                    case "reset":
                        return { count: 0 };
                    default:
                        throw new Error();
                }
            },
            { count: 0 }
        );

        const [, forceThisUpdate] = useReducer((x) => x + 1, 0);

        const [costType, setCostType] = useState("");

        const {
            PEAK_SEASON: peakSeason,
            LINE_LOSS: lineLoss,
            SECTOR: sector,
        } = avoidedCostsTemplateItemsGroupedBySectorRef?.current[activeTab] || {};

        // Refs

        const avoidedCostsTemplateItemsGroupedByCostTypeRef = useRef(avoidedCostsTemplateItemsGroupedBySectorRef.current[activeTab].items);

        const headersWithErrors = useRef({});

        const errorFilters = useRef(initErrorFilters());

        const errorFilter = useRef();

        const avoidedCostsListRef = useRef();

        const selections = useRef(new Set());

        const otherEditing = useRef(activeViews);

        // useEffects

        useEffect(() => {
            let newOtherEditing = false;

            if (activeViews?.length > 1) {
                const currentUserStarted = activeViews.find((a) => a.userNumber === userNumber).started;

                if (activeViews.some((a) => a.started < currentUserStarted)) {
                    newOtherEditing = true;
                }
            }
            if (newOtherEditing !== otherEditing.current) {
                otherEditing.current = newOtherEditing;
                forceThisUpdate();
            }
        }, [activeViews, userNumber]);

        // useMemos

        // Update refs when tab changed
        //
        // TODO: Check how it works with useEffect
        useMemo(() => {
            errorFilters.current = mapValues(initErrorFilters());

            errorFilter.current = undefined;

            avoidedCostsListRef.current = undefined;

            selections.current = new Set();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [activeTab, avoidedCostsTemplateItemsGroupedBySectorRef]);

        const costTypes = useMemo(() => {
            const costTypes = [];

            // Combines cost and UOM, and pushes it to an array
            forEach(avoidedCostsTemplateItemsGroupedBySectorRef.current[activeTab]?.items, (item) => {
                const { COST, UOM } = item;
                const value = `${COST}-${UOM}`;

                if (!costTypes.find((type) => type.value === value)) {
                    costTypes.push({
                        COST,
                        UOM,
                        value,
                        label: `${COST} (${UOM})`,
                    });
                }
            });

            return costTypes;
        }, [activeTab, avoidedCostsTemplateItemsGroupedBySectorRef]);

        // TODO: Try using ref
        const columns = useMemo(() => {
            const _headers = find(avoidedCostsTemplateItemsGroupedBySectorRef.current[activeTab]?.items);

            if (!_headers) return;

            const missingYears = avoidedCostsValidation?.missingYears || [];

            const headers = Object.keys(_headers);

            headers.sort((a, b) => {
                const aYear = !isNaN(Number(a));
                const bYear = !isNaN(Number(b));

                // When text column - do not sort
                if (!aYear && !bYear) return 0;

                // When both are numbers
                if (aYear && bYear) {
                    // When there are missing years - they should go first
                    if (!isEmpty(missingYears) && missingYears.includes(a) && !missingYears.includes(b)) return -1;

                    // When there are no missing years - sort by year
                    return a.localeCompare(b);
                }

                // When one of the item is a number - it should go last
                if (aYear) return 1;
                else return -1;
            });

            let omittedColumns = [...serviceColumns, "COST", "UOM"];

            const headersWithPossibleValues = chain(_headers)
                .omit(omittedColumns)
                .omitBy((c) => !isNaN(c))
                .mapValues(() => [])
                .value();

            const filterPossibleValuesKeys = Object.keys(headersWithPossibleValues);

            forEach(avoidedCostsTemplateItemsGroupedBySectorRef.current[activeTab]?.items, (item) => {
                forEach(filterPossibleValuesKeys, (h) => {
                    if (!headersWithPossibleValues[h].includes(item[h])) {
                        headersWithPossibleValues[h].push(item[h]);
                    }
                });
                applyErrorFilters(errorFilters, item);
            });
            const filterPossibleValues = mapValues(headersWithPossibleValues, (filter) => filter.map((f) => ({ label: f, value: f })));

            // If there are missing years
            // and headers with errors are present
            if (!isEmpty(missingYears) && !isEmpty(headersWithErrors?.current)) {
                missingYears.forEach((year) => {
                    // avoidedCostsTemplateItemsGroupedBySectorRef includes
                    // missing years, so in order to not display "duplicate"
                    // columns with empty fields, we need to remove them,
                    // if headers with errors has not been corrected.
                    //
                    // "Y2018" and "2018" are basically "duplcate"
                    // columns
                    if (!Object.keys(headersWithErrors.current).find((key) => headersWithErrors.current[key] === year)) {
                        omittedColumns.push(year);
                    }
                });
            }

            const onChangeSelected = (idRow, value) => {
                avoidedCostsTemplateItemsGroupedByCostTypeRef.current[costType][idRow].selected = value;

                if (value) {
                    selections.current.add(idRow);
                } else {
                    selections.current.delete(idRow);
                }

                forceThisUpdate();
            };

            const onChangeAvoidedCostsYear = (idRow, year, value) => {
                if (isNaN(value)) {
                    errorFilters.current[errorFiltersNames.badFormat].items.add(idRow);
                } else {
                    errorFilters.current[errorFiltersNames.badFormat].items.delete(idRow);
                }

                if (errorFilters.current[errorFiltersNames.badFormat].items.size === 0) {
                    errorFilter.current = undefined;
                }

                avoidedCostsTemplateItemsGroupedByCostTypeRef.current[costType][idRow][year] = value;

                forceListUpdate({ type: "update" });
            };

            const onBlurChangeCostValue = (idRow, year) => {
                avoidedCostsTemplateItemsGroupedByCostTypeRef.current[costType][idRow][year] = parseFloat(
                    avoidedCostsTemplateItemsGroupedByCostTypeRef.current[costType][idRow][year]
                ).toFixed(2);
                forceListUpdate({ type: "update" });
            };

            const onChangeHeaderWithError = (e, key) => {
                headersWithErrors.current[key] = e.target.value;

                forceListUpdate({ type: "update" });
            };

            const onBlurHeaderWithError = (oldKey, newKey) => {
                // Only add new key to avoidedCostsTemplateItemsGroupedByCostTypeRef,
                // if the new key is one of the missing years
                if (oldKey !== newKey && missingYears.includes(newKey)) {
                    costTypes.forEach((type) => {
                        const items = {};

                        forEach(avoidedCostsTemplateItemsGroupedByCostTypeRef.current[type.value], (item) => {
                            const _item = item;
                            const value = _item[oldKey];

                            _item[newKey] = value;

                            items[_item.idRow] = _item;
                        });

                        avoidedCostsTemplateItemsGroupedByCostTypeRef.current[type.value] = items;
                    });
                }
                // If the new key is not one of the missing years,
                // reset header with error to initial value
                else if (oldKey !== newKey && !missingYears.includes(newKey)) {
                    headersWithErrors.current[oldKey] = oldKey;

                    const listUpdateType = listUpdate.count === 0 ? "reset" : "update"; // update type enables "Revert Changes" button

                    forceListUpdate({ type: listUpdateType });
                }
            };

            const columns = chain(headers)
                .difference(omittedColumns)
                .map((key) => {
                    const isYear = !isNaN(Number(key));

                    let column: {
                        key: string;
                        header?: string;
                        className?: string;
                        sortable?: boolean;
                        hidden?: boolean;
                        noFilterBorder?: boolean;
                        applyFilter?: ({ itemValue, item, filterValue }) => boolean;
                        renderHeader?: () => JSX.Element;
                        renderItem?: ({ itemValue, item }) => JSX.Element;
                        renderFilter?: ({ filterValue, updateFilterValue, filteredList }) => JSX.Element;
                    } = {
                        key,
                        header: startCase(key),
                    };

                    // One of the years
                    if (isYear && !missingYears.includes(key)) {
                        column.className = "year";
                        column.sortable = false;

                        column.renderItem = ({ itemValue, item }) => (
                            <Input
                                disabled={otherEditing.current}
                                value={itemValue}
                                error={isNaN(itemValue)}
                                onBlur={() => onBlurChangeCostValue(item.idRow, key)}
                                onChange={(event) => onChangeAvoidedCostsYear(item.idRow, key, event.target.value)}
                            />
                        );

                        column.renderFilter = () =>
                            filterPossibleValuesKeys.length > 0 ? (
                                <Dropdown inputTableFilter items={[]} multiple displayCount />
                            ) : (
                                <Checkbox />
                            );
                    }
                    // One of the freezed columns
                    else if (freezedColumns.includes(key)) {
                        column.renderFilter = ({ filterValue, updateFilterValue }) => (
                            <Dropdown
                                inputTableFilter
                                value={filterValue || []}
                                items={filterPossibleValues[key] || []}
                                withFilter
                                multiple
                                displayCount
                                onChange={updateFilterValue}
                            />
                        );

                        column.applyFilter = ({ itemValue, filterValue }) => !filterValue?.length || filterValue.includes(itemValue);
                    }
                    // One of the missing years
                    else {
                        column.className = "missing year";
                        column.sortable = false;
                        column.header = column.header.replace(/\s/g, "");

                        if (!column.header.includes("Y")) {
                            column.header = "Y" + column.header;
                        }

                        headersWithErrors.current[column.header] = key;

                        column.renderHeader = () => (
                            <Input
                                disabled={otherEditing.current}
                                value={headersWithErrors.current[column.header]}
                                error={isNaN(headersWithErrors.current[column.header])}
                                onBlur={() => onBlurHeaderWithError(key, headersWithErrors.current[column.header])}
                                onChange={(event) => onChangeHeaderWithError(event, column.header)}
                            />
                        );

                        column.renderItem = ({ itemValue, item }) => (
                            <Input
                                disabled={otherEditing.current}
                                value={itemValue}
                                error={isNaN(itemValue)}
                                onChange={(event) => onChangeAvoidedCostsYear(item.idRow, key, event.target.value)}
                            />
                        );
                    }

                    return column;
                })
                .unshift({
                    key: "selected",
                    header: "ALL",
                    sortable: false,
                    renderFilter: ({ filterValue, updateFilterValue, filteredList }) => (
                        <Checkbox
                            checked={
                                !isNil(selections.current.size) && filteredList.length === selections.current.size ? filterValue : false
                            }
                            onChange={(event) => {
                                const value = event.target.checked;

                                filteredList.forEach((item) => {
                                    if (value) {
                                        selections.current.add(item.idRow);
                                    } else {
                                        selections.current.delete(item.idRow);
                                    }

                                    avoidedCostsTemplateItemsGroupedByCostTypeRef.current[costType][item.idRow].selected = value;
                                });

                                forceThisUpdate();
                                updateFilterValue(value);
                            }}
                        />
                    ),
                    renderItem: ({ itemValue, item }) => (
                        <>
                            <Checkbox checked={itemValue} onChange={(event) => onChangeSelected(item.idRow, event.target.checked)} />
                            {item.IS_DUPLICATE === "1" && (
                                <IconWrap icon="octagon-exclamation-empty" title="This is a duplicated row" error />
                            )}
                        </>
                    ),
                    applyFilter: () => true,
                    noFilterBorder: true,
                })
                .unshift({
                    key: filterErrorKey,
                    applyFilter: ({ item }) => (errorFilter.current ? errorFilters.current[errorFilter.current].apply(item) : true),
                    hidden: true,
                })
                .value();

            return columns;

            // Disabling ESLint for the next line because
            // there's a warning about missing
            // dependency - listUpdate.count.
            // By adding this dependency, columns get
            // rendered too often and break functionality
            //
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [activeTab, avoidedCostsTemplateItemsGroupedBySectorRef, avoidedCostsValidation, costType, costTypes]);

        const errFilters = useMemo(() => {
            return chain(errorFilters.current)
                .pickBy((err) => err.items.size)
                .map((p, k) => ({ label: p.title, value: k }))
                .value();
        }, []);

        const sectors = useMemo(() => {
            return sectorList?.map((sector) => ({ label: sector, value: sector })) || [];
        }, [sectorList]);

        useEffect(() => {
            if (!isEmpty(costTypes)) {
                setCostType(costTypes[0].value); // set the default value for cost type
            }
        }, [costTypes]);

        // Helper functions

        /**
         * Helper function for initializing avoidedCostsTemplateItemsGroupedByCostTypeRef
         * based on avoidedCostsTemplateItemsGroupedBySectorRef values.
         */
        const initializeAvoidedCostsTemplateItemsGroupedByCostType = useCallback(() => {
            let items = {};

            forEach(avoidedCostsTemplateItemsGroupedBySectorRef.current[activeTab]?.items, (item) => {
                const { COST, UOM, idRow } = item;
                const value = `${COST}-${UOM}`;

                if (!has(items, value)) {
                    items[value] = {}; // need to add key first
                }

                items[value][idRow] = item;
            });

            avoidedCostsTemplateItemsGroupedByCostTypeRef.current = items;
        }, [activeTab, avoidedCostsTemplateItemsGroupedBySectorRef]);

        /**
         * Helper function for updating avoidedCostsTemplateItemsGroupedBySectorRef by
         * removing column with header error.
         */
        const updateAvoidedCostsTemplateItemsGroupedByCostType = useCallback(
            (key) => {
                costTypes.forEach((type) => {
                    const items = {};

                    forEach(avoidedCostsTemplateItemsGroupedByCostTypeRef.current[type.value], (item) => {
                        const _item = omit(item, key);

                        // @ts-ignore
                        items[_item.idRow] = _item;
                    });

                    avoidedCostsTemplateItemsGroupedByCostTypeRef.current[type.value] = items;
                });
            },
            [costTypes]
        );

        /**
         * Helper function for updating avoidedCostsTemplateItemsGroupedBySectorRef
         * based on avoidedCostsTemplateItemsGroupedByCostTypeRef values.
         */
        const updateAvoidedCostsTemplateItemsGroupedBySector = useCallback(() => {
            const items = {};

            costTypes.map((type) =>
                forEach(avoidedCostsTemplateItemsGroupedByCostTypeRef.current[type.value], (item) => {
                    items[item.idRow] = item;
                })
            );

            avoidedCostsTemplateItemsGroupedBySectorRef.current[activeTab].items = items;
        }, [costTypes, activeTab, avoidedCostsTemplateItemsGroupedBySectorRef]);

        useEffect(() => {
            initializeAvoidedCostsTemplateItemsGroupedByCostType();
        }, [initializeAvoidedCostsTemplateItemsGroupedByCostType]);

        const { height, ref } = useResizeDetector();

        const pagingElem = document.querySelector(".paging-wrap");

        const pagingElemHeight = pagingElem?.clientHeight ? pagingElem?.clientHeight : 43;

        //TODO: Recalculate on resizing window
        const listLimit = useMemo(() => Math.floor(((height || 1000) - 43 - pagingElemHeight - 65) / 45), [height, pagingElemHeight]);

        // Event handlers

        /**
         * Change error to filter by.
         */
        const onChangeErrorFilter = useCallback((value) => {
            errorFilter.current = value;

            forceThisUpdate();
        }, []);

        /**
         * Change sector.
         */
        const onChangeSector = useCallback(
            (value) => {
                if (isNil(avoidedCostsTemplateItemsGroupedBySectorRef.current[value])) {
                    avoidedCostsTemplateItemsGroupedBySectorRef.current[value] = { SECTOR: value, items: {} };
                }

                const newItems = {};

                forEach(avoidedCostsTemplateItemsGroupedBySectorRef.current[activeTab]?.items, (item, key) => {
                    if (item.selected) {
                        avoidedCostsTemplateItemsGroupedBySectorRef.current[value].items[value] = item;
                        deleteErrorFilters(errorFilters, item);
                    } else {
                        newItems[key] = item;
                        applyErrorFilters(errorFilters, item);
                    }
                });

                nullifyErrorFilter(errorFilters, errorFilter);

                forceThisUpdate();
                onUpdate();
            },
            [activeTab, avoidedCostsTemplateItemsGroupedBySectorRef, onUpdate]
        );

        /**
         * Change cost type.
         */
        const onChangeCostType = useCallback(
            (value) => {
                forEach(avoidedCostsTemplateItemsGroupedByCostTypeRef.current[value], (item) => {
                    if (item.selected) {
                        avoidedCostsTemplateItemsGroupedByCostTypeRef.current[value][item.idRow].selected = false; // unselect any selected item
                    }
                });

                selections.current.clear();

                // If there are missing years
                // and headers with errors are present
                if (!isEmpty(avoidedCostsValidation?.missingYears) && !isEmpty(headersWithErrors)) {
                    // Loop through keys
                    Object.keys(headersWithErrors.current).forEach((key) => {
                        // If header with error has been changed,
                        // update avoidedCostsTemplateItemsGroupedByCostTypeRef
                        // and avoidedCostsTemplateItemsGroupedBySectorRef
                        if (headersWithErrors.current[key] !== key) {
                            updateAvoidedCostsTemplateItemsGroupedByCostType(key);
                            updateAvoidedCostsTemplateItemsGroupedBySector();
                        }
                    });
                }

                setCostType(value);
            },
            [avoidedCostsValidation, updateAvoidedCostsTemplateItemsGroupedByCostType, updateAvoidedCostsTemplateItemsGroupedBySector]
        );

        /**
         * Helper function for deleting rows.
         */
        const deleteRows = useCallback(() => {
            const items = {};

            forEach(avoidedCostsTemplateItemsGroupedByCostTypeRef.current[costType], (item, key) => {
                if (item.selected) {
                    deleteErrorFilters(errorFilters, item);
                } else {
                    items[key] = item;
                    applyErrorFilters(errorFilters, item);
                }
            });

            avoidedCostsTemplateItemsGroupedByCostTypeRef.current[costType] = items;

            nullifyErrorFilter(errorFilters, errorFilter);

            forceListUpdate({ type: "update" });
        }, [costType]);

        /**
         * Delete selected rows.
         */
        const onClickDeleteRows = useCallback(() => {
            dispatch(
                // @ts-ignore
                openModalDialogAvoidedCostsDeleteRows({
                    onDelete: deleteRows,
                })
            );
        }, [deleteRows, dispatch]);

        /**
         * Save changes.
         */
        const onClickSave = useCallback(() => {
            // If there are missing years
            // and headers with errors are present
            if (!isEmpty(avoidedCostsValidation?.missingYears) && !isEmpty(headersWithErrors.current)) {
                // Loop through keys
                Object.keys(headersWithErrors.current).forEach((key) => {
                    // If header with error has been changed,
                    // update avoidedCostsTemplateItemsGroupedByCostTypeRef
                    if (headersWithErrors.current[key] !== key) {
                        updateAvoidedCostsTemplateItemsGroupedByCostType(key);
                    }
                });
            }

            // Update avoidedCostsTemplateItemsGroupedBySectorRef
            updateAvoidedCostsTemplateItemsGroupedBySector();

            dispatch(
                // @ts-ignore
                updateAvoidedCostsTemplate({
                    idProject,
                    idInputLog,
                    body: chain(avoidedCostsTemplateItemsGroupedBySectorRef.current)
                        .mapValues(({ ...sector }) => ({
                            ...sector,
                            items: map(sector.items, (item) => chain(item).omit(serviceColumns).value()),
                        }))
                        .value(),
                })
            );

            onShowValidationPanel();
            onSaveAvoidedCosts();

            forEach(avoidedCostsTemplateItemsGroupedBySectorRef.current, function (activeItem) {
                forEach(activeItem.items, function (obj) {
                    set(obj, "selected", false);
                });
            });
        }, [
            dispatch,
            idProject,
            avoidedCostsValidation,
            avoidedCostsTemplateItemsGroupedBySectorRef,
            idInputLog,
            onShowValidationPanel,
            onSaveAvoidedCosts,
            updateAvoidedCostsTemplateItemsGroupedByCostType,
            updateAvoidedCostsTemplateItemsGroupedBySector,
        ]);

        /**
         * Reverts changes.
         */
        const onClickRevert = useCallback(() => {
            const oldItems = dispatch(getFromStoreAvoidedCostsList({ idInputLog }));

            avoidedCostsTemplateItemsGroupedBySectorRef.current = transformAvoidedCostTemplate(oldItems);

            selections.current.clear();

            initializeAvoidedCostsTemplateItemsGroupedByCostType();

            // If headers with errors are present
            if (!isEmpty(headersWithErrors.current)) {
                // Loop through keys
                Object.keys(headersWithErrors.current).forEach((key) => {
                    if (headersWithErrors.current[key] !== key) {
                        headersWithErrors.current[key] = key; // reset header with error to initial value
                    }
                });
            }

            forceListUpdate({ type: "reset" });
        }, [dispatch, idInputLog, avoidedCostsTemplateItemsGroupedBySectorRef, initializeAvoidedCostsTemplateItemsGroupedByCostType]);

        /**
         * Opens Bulk Edit modal window.
         */
        const onBulkEditClick = useCallback(() => {
            dispatch(
                // @ts-ignore
                openModalDialogAvoidedCostsBulkEdit({
                    yearStart: parseInt(Object.values(columns).find((e) => e.className === "year")?.key),
                    yearEnd: parseInt(
                        Object.values(columns)
                            .reverse()
                            .find((e) => e.className === "year")?.key
                    ),
                    avoidedCostSectorItems: avoidedCostsTemplateItemsGroupedBySectorRef,
                    activeItem: activeTab,
                    avoidedCostTypeItems: avoidedCostsTemplateItemsGroupedByCostTypeRef,
                    costType: costType,
                    onSave: () => forceListUpdate({ type: "update" }),
                })
            );
        }, [dispatch, columns, activeTab, avoidedCostsTemplateItemsGroupedBySectorRef, costType]);

        /**
         * Opens Bulk Edit confirmation modal window.
         */
        const onClickConfirmBulkEdit = useCallback(() => {
            dispatch(
                openModalDialogConfirmBulkEdit({
                    onConfirm: onBulkEditClick,
                })
            );
        }, [onBulkEditClick, dispatch]);

        return (
            <div className="flex-column flex-one-in-column no-scroll avoided-costs-tab-content">
                {/* Header */}
                <div className="flex-row align-center justify-space-between avoided-costs-subheader">
                    <div className="flex-column">
                        <div className="flex-row align-center avoided-costs-actions-top">
                            <div className="flex-row align-center cost-type">
                                <div className="actions-label actions-margin">Cost type</div>
                                <Dropdown
                                    className="filter-cost-type"
                                    value={costType}
                                    items={costTypes}
                                    onChange={(value) => onChangeCostType(value)}
                                />
                            </div>
                            <div className="line-loss">
                                <div className="actions-label">Line Loss %: {lineLoss}</div>
                            </div>
                            <div className="peak-season">
                                <div className="actions-label">Peak Season: {peakSeason}</div>
                            </div>
                        </div>
                        {/* Actions */}
                        <div className="flex-row align-center avoided-costs-actions-bottom">
                            <div className="revert-changes">
                                <IconWithLabel
                                    icon="reply"
                                    disabled={otherEditing.current || listUpdate.count === 0}
                                    onClick={onClickRevert}
                                >
                                    Revert changes
                                </IconWithLabel>
                            </div>
                            <div className="bulk-edit">
                                <IconWithLabel
                                    icon="edit-empty"
                                    disabled={otherEditing.current || selections.current.size === 0}
                                    onClick={listUpdate.count === 0 ? onBulkEditClick : onClickConfirmBulkEdit}
                                >
                                    Bulk Edit
                                </IconWithLabel>
                            </div>
                            <div className="delete-rows">
                                <IconWithLabel
                                    icon="delete-trash-empty"
                                    disabled={otherEditing.current || selections.current.size === 0}
                                    onClick={onClickDeleteRows}
                                >
                                    Delete
                                </IconWithLabel>
                            </div>
                            {Object.keys(errorFilters.current).some((key) => errorFilters.current[key]?.items?.size) && (
                                <div className="flex-row align-center errors">
                                    <Dropdown
                                        className="filter-errors"
                                        label="Filter rows by error"
                                        value={errorFilter.current}
                                        items={errFilters}
                                        onChange={(value) => onChangeErrorFilter(value)}
                                    />
                                </div>
                            )}
                            {isAssignableSectors(sector) && selections.current.size > 0 && !isLoadingSectorList && (
                                <div className="flex-row align-center assign-sector">
                                    <Dropdown
                                        className="sector"
                                        label="Assign sector"
                                        disabled={otherEditing.current}
                                        value={sector}
                                        items={sectors}
                                        onChange={(value) => onChangeSector(value)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Save button */}
                    <div className="flex-column flex-margin">
                        <Button primary onClick={onClickSave} disabled={otherEditing.current}>
                            Validate And Save
                        </Button>
                    </div>
                </div>
                {/* Body */}
                <div ref={ref} className="flex-column flex-one-in-column no-scroll avoided-costs-list-wrapper">
                    <CustomList
                        // @ts-ignore
                        className="avoided-costs-list"
                        items={avoidedCostsTemplateItemsGroupedByCostTypeRef.current[costType]}
                        columns={columns}
                        limit={listLimit}
                        pageDisplayCount={10}
                        inputRef={(ref) => {
                            if (!ref) return;
                            avoidedCostsListRef.current = ref;
                        }}
                        searchFilters={{
                            filterErrorKey: errorFilter.current || "",
                        }}
                        sortable
                        noPadding
                        update={listUpdate.count}
                        freezedHeaders={freezedColumns}
                    />
                </div>
            </div>
        );
    }
);

export default AvoidedCosts;
