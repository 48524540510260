export const isInIframe = () => {
    return Boolean(window.frameElement);
};

/**
 * Do nothing on browser back button click.
 */
export const disableBrowserNavigation = () => {
    window.history.pushState(null, "", window.location.href);

    window.onpopstate = function () {
        window.history.go(1);
    };
};

// Inspiration from: https://gomakethings.com/detecting-when-a-visitor-has-stopped-scrolling-with-vanilla-javascript/
export const scrollEnd = (element: any, callback: any) => {
    let isScrolling: any;

    const scrollHandler = (event: any) => {
        clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
            element.removeEventListener("scroll", scrollHandler, false);
            callback();
        }, 50);
    };

    element.addEventListener("scroll", scrollHandler, false);
};
