import { memo } from "react";
import * as React from "react";
import ClassNames from "classnames";

import "./style.scss";

export const ViewPlaceholder = memo((props: ViewPlaceholderProps) => {
    const className = ClassNames("view-placeholder flex-column flex-one align-center", props.className, {
        "text-center placeholder-split-screen": props.placeholderSplitView,
    });

    return (
        <div className={className}>
            {props.placeholderSplitView ? (
                <div className="split-view-inner-block">{props.children}</div>
            ) : (
                <div className="text-center">{props.children}</div>
            )}
        </div>
    );
});

interface ViewPlaceholderProps {
    className?: string;

    /**
     * Split view placeholder.
     */
    placeholderSplitView?: boolean;

    children: React.ReactNode;
}

export default ViewPlaceholder;
