import cn from "classnames";

import { StepBoxProps } from "./types";

import "./style.scss";

const StepBox = (props: StepBoxProps) => {
    const classNames = cn("flex-column align-center justify-center step-box", props.className);

    return <div className={classNames}>{props.number}</div>;
};

export default StepBox;
