import { memo } from "react";
import cn from "classnames";

import MainContainer from "./MainContainer";
import SideContainer from "./SideContainer";

import { isVLMLoginPage } from "utils/constants";

import { FormWrapProps } from "./types";

import "./style.scss";

const FormWrap = memo(({ children }: FormWrapProps) => {
    const classNames = cn("with-scroll form-wrap", {
        "form-wrap__vlm": isVLMLoginPage,
    });

    return (
        <div className={classNames}>
            <div className="flex-row form-wrap__container">
                {isVLMLoginPage && <SideContainer />}
                <MainContainer>{children}</MainContainer>
            </div>
        </div>
    );
});

export default FormWrap;
