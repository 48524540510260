import { memo } from "react";

import DashboardPanel from "components/ui/Dashboard";

import CalculatedResults from "pages/ManageProject/ProjectionsContent/ModelDashboard/DashboardDefault/CalculatedResultsPanel/CalculatedResults";

import { CALCULATED_RESULTS } from "utils/constants";

import { CalculatedResultsProps } from "./types";

const CalculatedResultsPanel = memo(({ idClient, idProject, idInputLog, projectInputs }: CalculatedResultsProps) => {
    return (
        <DashboardPanel className="calculated-results-panel" title="Most Recent Calculated Results" pageId={CALCULATED_RESULTS}>
            <CalculatedResults idClient={idClient} idProject={idProject} idInputLog={idInputLog} projectInputs={projectInputs} />
        </DashboardPanel>
    );
});

export default CalculatedResultsPanel;
