import { memo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateProfile } from "store/userProfile/actions";

import { useTwoFactor } from "store/resources/actions/user/userTwoFactorActions";

import { IdsText, IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import FieldGroup from "components/ui/FieldGroup";
import Input from "components/ui/Input";

import { openModalDialogResetQrCode } from "layouts/Modal/UserProfileModal/utils";

import { ERROR_EMAIL_INVALID, isEmailAddress } from "utils/string";

const ERROR_FIRST_NAME_EMPTY = "First name is a required property.";
const ERROR_LAST_NAME_EMPTY = "Last name is a required property.";
const ERROR_EMAIL_EMPTY = "E-mail is a required property.";

const ChangeProfileDetailsForm: React.NamedExoticComponent<{
    initialValues: {
        address1: string;
        address2: string;
        cell: string;
        city: string;
        company: string;
        email: string;
        firstName: string;
        lastName: string;
        pager: string;
        phone: string;
        settings: string;
        sso: boolean;
        state: string;
        userName: string;
        userNumber: string;
        zip: string;
    };
}> = memo(({ initialValues }) => {
    const dispatch = useDispatch();

    // @ts-ignore
    const user = useSelector((state) => state.vdsmUser);

    const [firstName, setFirstName] = useState(initialValues.firstName);
    const [lastName, setLastName] = useState(initialValues.lastName);
    const [email, setEmail] = useState(initialValues.email);

    const [changeDetected, setChangeDetected] = useState(false);

    const [twoFactorInfo] = useTwoFactor({ userName: initialValues.userName });

    const isFirstNameEmptyError = firstName?.length === 0;
    const isLastNameEmptyError = lastName?.length === 0;
    const isEmailEmptyError = email?.length === 0;
    const isEmailInvalidError = !isEmailAddress(email);

    const isSaveEnabled = changeDetected && !isFirstNameEmptyError && !isLastNameEmptyError && !isEmailInvalidError;
    const emailErrorText = isEmailEmptyError ? ERROR_EMAIL_EMPTY : ERROR_EMAIL_INVALID;

    if (firstName === initialValues.firstName && lastName === initialValues.lastName && email === initialValues.email && changeDetected) {
        setChangeDetected(false);
    }

    const onChange = useCallback(
        (event, name) => {
            switch (name) {
                case "firstName":
                    setFirstName(event.target.value);

                    if (event.target.value !== initialValues.firstName) {
                        setChangeDetected(true);
                    }

                    break;
                case "lastName":
                    setLastName(event.target.value);

                    if (event.target.value !== initialValues.lastName) {
                        setChangeDetected(true);
                    }

                    break;
                case "email":
                    setEmail(event.target.value);

                    if (event.target.value !== initialValues.email) {
                        setChangeDetected(true);
                    }

                    break;
                default:
            }
        },
        [initialValues.firstName, initialValues.lastName, initialValues.email]
    );

    const onResetQrCodeClick = useCallback(() => {
        dispatch(openModalDialogResetQrCode({ userNumber: user?.userNumber, username: user?.name }));
    }, [user?.userNumber, user?.name, dispatch]);

    const onSaveProfileChangesClick = useCallback(() => {
        dispatch(
            updateProfile({
                data: {
                    ...initialValues,
                    firstName,
                    lastName,
                    email,
                },
            })
        );

        setChangeDetected(false);
    }, [initialValues, firstName, lastName, email, dispatch]);

    const onRevertChangesClick = useCallback(() => {
        setFirstName(initialValues.firstName);
        setLastName(initialValues.lastName);
        setEmail(initialValues.email);

        setChangeDetected(false);
    }, [initialValues.firstName, initialValues.lastName, initialValues.email]);

    return (
        <div className="flex-column align-center gap-4">
            <div className="fill-width pt-4">
                {twoFactorInfo?.twoFactorRequired && twoFactorInfo?.qrCodeExists && (
                    <IdsText
                        customClasses="text-underline clickable"
                        weight="bold"
                        style={{ color: "var(--ids-semantic-ink-color-brand-a-accent)" }}
                        onClick={onResetQrCodeClick}
                    >
                        Reset QR code
                    </IdsText>
                )}
                <FieldGroup>
                    <Input label="Username" readOnly value={initialValues.userName} />
                    <Input
                        label="First name"
                        required
                        value={firstName}
                        error={isFirstNameEmptyError}
                        msgText={isFirstNameEmptyError ? ERROR_FIRST_NAME_EMPTY : ""}
                        onChange={(event) => onChange(event, "firstName")}
                    />
                    <Input
                        label="Last name"
                        required
                        value={lastName}
                        error={isLastNameEmptyError}
                        msgText={isLastNameEmptyError ? ERROR_LAST_NAME_EMPTY : ""}
                        onChange={(event) => onChange(event, "lastName")}
                    />
                    <Input
                        label="E-mail"
                        required
                        value={email}
                        error={isEmailEmptyError || isEmailInvalidError}
                        msgText={isEmailEmptyError || isEmailInvalidError ? emailErrorText : ""}
                        onChange={(event) => onChange(event, "email")}
                    />
                </FieldGroup>
            </div>
            <IdsButtonGroup spaceBetween="lg">
                <div style={{ width: "165px" }}>
                    <Button variant="primary" isDisabled={!isSaveEnabled} padding="lg" fullWidth onClick={onSaveProfileChangesClick}>
                        Save Profile Changes
                    </Button>
                </div>
                <div style={{ width: "165px" }}>
                    <Button variant="secondary" isDisabled={!changeDetected} padding="lg" fullWidth onClick={onRevertChangesClick}>
                        Revert Changes
                    </Button>
                </div>
            </IdsButtonGroup>
        </div>
    );
});

export default ChangeProfileDetailsForm;
