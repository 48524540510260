import { memo, useEffect, useMemo } from "react";
import { useSelector, useDispatch, batch } from "react-redux";

import { windowContainerReset, windowAdd } from "store/window/actions";

import Windows from "./Windows";

import { clientRoute, isVLMLoginPage } from "utils/constants";
import { DEFAULT_FONT_SIZE, DEFAULT_THEME, DEFAULT_THEME_MODE, setSettings } from "utils/user/userSettings";
import { isAuthenticated, isQrCode, isStatusExpired, hasInternalUserRole, hasExternalUserRole } from "utils/user";
import { windowContainerTypes, getLoginWindowParams } from "utils/window";

import { Window } from "store/window/types";

// TODO: Remove when themes are implemented into Vision Insight
const DEFAULT_SETTINGS = {
    theme: DEFAULT_THEME,
    themeMode: DEFAULT_THEME_MODE,
    fontSize: DEFAULT_FONT_SIZE,
};

// TODO: There's a bug when user logs out of the application,
// he gets automatically signed back in. Usually happens when
// user has been inactive for few minutes and then tries to
// log out
const WindowManager = memo(() => {
    const dispatch = useDispatch();

    // @ts-ignore
    const user = useSelector((state) => state.vdsmUser);

    // Variables

    const isUserAuthenticated = isAuthenticated(user);
    const isUserQrCode = isQrCode(user);
    const isUserExpired = isStatusExpired(user);

    const containerName = isUserAuthenticated ? windowContainerTypes.Root : windowContainerTypes.Login;

    const isResetPasswordRoute = window.location.pathname.split("/")[1] === clientRoute.resetPassword;
    const isSamlRoute = window.location.pathname.split("/")[1] === clientRoute.saml;
    const isReportsRoute = window.location.pathname.split("/")[1] === clientRoute.reports;

    const initialViews = useMemo(() => {
        let initialViews: Window[] = [getLoginWindowParams()];

        if (
            isUserAuthenticated &&
            (hasInternalUserRole(user) || (hasExternalUserRole(user) && isVLMLoginPage) || Boolean(process.env.REACT_APP_NO_REDIRECT))
        ) {
            initialViews = [
                {
                    containerName: windowContainerTypes.Root,
                    name: "Home",
                    component: "HomeDashboard",
                    tabSubTitle: "Home",
                },
            ];
        }

        return initialViews;
    }, [user, isUserAuthenticated]);

    useEffect(() => {
        setSettings(DEFAULT_SETTINGS);
    }, []);

    useEffect(() => {
        if (!isUserAuthenticated && !isUserQrCode && !isUserExpired && !isResetPasswordRoute && !isSamlRoute && !isReportsRoute) {
            batch(() => {
                dispatch(windowContainerReset({ containerName }));

                dispatch(windowAdd(initialViews[0]));
            });
        }
    }, [
        isUserAuthenticated,
        isUserQrCode,
        isUserExpired,
        containerName,
        isResetPasswordRoute,
        isSamlRoute,
        isReportsRoute,
        initialViews,
        dispatch,
    ]);

    return <Windows containerName={containerName} initialViews={initialViews} persist={isUserAuthenticated} />;
});

export default WindowManager;
