import { useCallback } from "react";
import * as React from "react";

import { UseFocusClassesParams } from "./types";

const isFocusedClass = "focused";
const isFocusedByKeyboardClass = "focused--keyboard";

export const useFocusClasses = ({ disabled }: UseFocusClassesParams) => {
    const onFocus = useCallback(
        (event: React.FocusEvent<HTMLElement>) => {
            if (!disabled) {
                //TODO: Determine if this is keyboard event
                const isKeyboardEvent = false;

                event.target.classList.add(isFocusedClass);

                if (isKeyboardEvent) {
                    event.target.classList.add(isFocusedByKeyboardClass);
                }
            }
        },
        [disabled]
    );

    const onBlur = useCallback(
        (event: React.FocusEvent<HTMLElement>) => {
            if (!disabled) {
                event.target.classList.remove(isFocusedClass);
                event.target.classList.remove(isFocusedByKeyboardClass);
            }
        },
        [disabled]
    );

    return [onFocus, onBlur];
};
