import { GetViewSideNavigationKeyParams } from "./types";

export const sideNavigationKey = {
    // TODO: Replace with inner
    outerLeft: "outer-left",
    outerRight: "outer-right",

    innerLeft: "inner-left",
    innerRight: "inner-right",
};

export const sideNavigationPosition = {
    left: "left",
    right: "right",
};

export const sideNavigationSize = {
    small: "small",
    medium: "medium",
    large: "large",
};

export const getViewSideNavigationKey = ({ name, position }: GetViewSideNavigationKeyParams) => {
    return `${name}-${position}`;
};
