///
/// Project Statuses actions
/// resourceName: bookmarkedProjectStatusesResourceName
///
import { bookmarkedProjectStatusesResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { clearResource } from "store/resources/actions/clearResource";

import { BookmarkedProjectsByStatuses, BookmarkedProjectsByStatusesResponseModel } from "./types";

export const useBookmarkedProjectStatuses = () =>
    useResource({
        resourceName: bookmarkedProjectStatusesResourceName,
        key: bookmarkedProjectStatusesResourceName,
        transform: (data: BookmarkedProjectsByStatusesResponseModel) => data?.model,
    }) as [BookmarkedProjectsByStatuses[], boolean | undefined, any | undefined];

export const clearBookmarkedProjectStatuses =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: bookmarkedProjectStatusesResourceName,
                key: bookmarkedProjectStatusesResourceName,
                broadcast: true,
            })
        );
    };
