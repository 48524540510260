import { memo } from "react";
import { useSelector } from "react-redux";

import ProjectionsContent from "./ProjectionsContent";

import ProjectionsSelectionSidebar from "layouts/Sidebar/ProjectionsSelectionSidebar";

import { projections } from "utils/constants";

import { ManageProjectProps } from "./types";

const defaultSelectedProjection = { idProjectionView: projections.PROJECT_DASHBOARD };

const ManageProject = memo((props: ManageProjectProps) => {
    const { viewIndex, project, clientName } = props;

    const { idProject } = project;

    // @ts-ignore - remove when state typed
    const selectedProjection = useSelector((state) => state.projections?.selection[idProject]?.[viewIndex]) || defaultSelectedProjection;

    return (
        <div className="flex-row flex-one-in-row fill-height no-scroll manage-project">
            <div className="manage-project__projections-sidebar-wrapper">
                <ProjectionsSelectionSidebar viewIndex={props.viewIndex} project={project} selectedProjection={selectedProjection} />
            </div>
            <div id="react-infinite-scroll" className="flex-column flex-one-in-row with-scroll manage-project__projections-content-wrapper">
                <ProjectionsContent
                    viewIndex={viewIndex}
                    project={project}
                    selectedProjection={selectedProjection}
                    clientName={clientName}
                />
            </div>
        </div>
    );
});

export default ManageProject;
