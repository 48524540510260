import { IndexSignature } from "types/types";
import { VariablesTransformed } from "store/resources/actions/variables/types";
import { ModuleVariableTransformed } from "store/resources/actions/calculationWorkflow/types";

export const getVariableValue = (currentValues: IndexSignature<string>, savedValue: VariablesTransformed | ModuleVariableTransformed) => {
    let value: string | string[] = "";
    const isList = Boolean(savedValue?.inputType === "LIST");

    // If variable value has changed, display that value
    if (currentValues?.[savedValue.name] !== undefined) {
        value = currentValues[savedValue.name];
    }
    // If variable value has not changed and
    // value is a list, display saved values
    else if (isList && typeof savedValue?.value !== "number") {
        if (savedValue.value) {
            value = JSON.parse(savedValue.value);
        } else {
            value = [];
        }
    }
    // If variable value has not changed, display saved value
    else {
        value = (savedValue.value as string) ?? "";
    }

    return value;
};
