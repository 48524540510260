import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { powerBIResourceName } from "store/configureResources";
import { usePowerBIReport } from "store/resources/actions/powerBI/powerBIActions";

import ErrorBoundary from "components/ui/ErrorBoundary";

import IconLoading from "components/ui/Icons/IconLoading";
import PowerBIReport from "components/ui/PowerBIReport";

import { openWindowProjectFinalReport } from "utils/window";

import { PowerBIProps } from "./types";

const PowerBI = memo((props: PowerBIProps) => {
    const { idProject, idInputLog, reportId, filterEntityId, reportType, datasetId, isPaginated, allowButtonEvents } = props;

    const dispatch = useDispatch();

    const [report] = usePowerBIReport({
        resourceName: powerBIResourceName,
        reportId,
        filterEntityId,
        datasetId: isPaginated ? datasetId : undefined,
        isPaginated,
        idInputLog,
        reportType,
        forced: false,
    });

    const onButtonClicked = useCallback(
        (event) => {
            switch (event.detail.title) {
                case "ProjectFinalReport":
                    idProject && dispatch(openWindowProjectFinalReport({ idProject }));

                    break;
                default:
            }
        },
        [idProject, dispatch]
    );

    return (
        <>
            {!(report && report.token && report.url) ? (
                <div className="flex-column align-center justify-center fill-height">
                    <IconLoading />
                </div>
            ) : (
                <ErrorBoundary>
                    <PowerBIReport
                        embedId={reportId}
                        embedUrl={report.url}
                        accessToken={report.token}
                        idInputLog={idInputLog}
                        isPaginated={isPaginated}
                        onButtonClicked={allowButtonEvents ? onButtonClicked : undefined}
                        {...props}
                    />
                </ErrorBoundary>
            )}
        </>
    );
});

export default PowerBI;
