import { get, isNil } from "lodash";
import { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getResource } from "./getResource";

import { store } from "store/configureStore";
import { getResourceKey } from "store/utils";

import { useDeepEffect } from "utils/useDeepEffect";
import { useUnmounted } from "utils/useUnmounted";

import { IndexSignature } from "types/types";

export const useResource = ({
    resourceName,
    showSuccessNotification,
    showErrorNotification,
    resourceId,
    key,
    path = {},
    query = {},
    transform,
    forced,
    takeFromStore,
    onError,
}: UseResourceParams) => {
    const resourceKey = getResourceKey({ resourceId, key });

    const resourceData = useSelector((state) => get(state, `resources.${resourceName}.itemsById[${resourceKey}].data`), shallowEqual);

    // @ts-ignore
    const { needUpdate, waitRead } = useSelector((state) => get(state, `resources.${resourceName}.itemsById[${resourceKey}]`)) || {};

    const isNilResource = isNil(resourceData);

    // Note: Remove Boolean constructor if this causes issues in app
    const [loading, setLoading] = useState(Boolean((isNilResource || forced) && !takeFromStore));

    // Flag to request resource only once
    // Set resource as requested if we take it only from store
    const isRequested = useRef(takeFromStore);

    const lastResourceValue = useRef(resourceData);
    const unmounted = useUnmounted();

    useEffect(() => {
        if (!takeFromStore) {
            isRequested.current = false;
        }
    }, [resourceKey, takeFromStore]);

    useEffect(() => {
        if (isNilResource && !isNil(lastResourceValue.current) && isRequested.current && !loading) {
            isRequested.current = false;
            setLoading(true);
        }

        if (loading && !isNilResource) {
            setLoading(false);
        }

        lastResourceValue.current = resourceData;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNilResource, loading]);

    useDeepEffect(
        /*Debugger*/ () => {
            if (needUpdate) {
                store.dispatch(
                    getResource({
                        resourceName,
                        resourceId,
                        key,
                        path,
                        query,
                        showSuccessNotification,
                        showErrorNotification,
                        onError: (action: Function) => {
                            !unmounted.current && onError && onError(action);
                        },
                    })
                );
            } else {
                const hasKey = resourceId || key;
                const canRequest = hasKey && (isNilResource || forced) && !isRequested.current;

                if (canRequest) {
                    isRequested.current = true;
                    setLoading(true);

                    store.dispatch(
                        getResource({
                            resourceName,
                            resourceId,
                            key,
                            path,
                            query,
                            showSuccessNotification,
                            showErrorNotification,
                            onError: (action: Function) => {
                                !unmounted.current && onError && onError(action);
                            },
                            onComplete: () => {
                                !unmounted.current && setLoading(false);
                            },
                        })
                    );
                } else {
                    if (!hasKey) {
                        setLoading(false);
                    }
                }
            }
        },
        [
            isNilResource,
            forced,
            resourceName,
            resourceId,
            key,
            path,
            query,
            onError,
            unmounted,
            showSuccessNotification,
            showErrorNotification,
            needUpdate,
            loading,
            resourceData,
        ]
    );

    // useDependenciesDebugger({ isNilResource, forced, resourceName, resourceId, key, path, query, onError, unmounted, showSuccessNotification, showErrorNotification, needUpdate, isReading });

    const transformedData = transform ? transform(resourceData) : resourceData;

    return [transformedData, loading, waitRead];
};

export const useResourceProgress = ({ resourceName, resourceId }: UseResourceProgressParams) => {
    // @ts-ignore
    return useSelector((state) => state.resources[resourceName].itemsById[`${resourceId}-progress`].percent) || 0;
};

interface UseResourceProgressParams {
    /**
     * Name of the resource.
     */
    resourceName?: string;

    /**
     * ID of resource.
     */
    resourceId?: string;
}

export interface UseResourceParams extends UseResourceProgressParams {
    /**
     * Name of the resource property.
     */
    key?: string;

    path?: IndexSignature<any>;

    query?: object;

    forced?: boolean;

    takeFromStore?: boolean;

    /**
     * Display notification on success.
     */
    showSuccessNotification?: boolean;

    /**
     * Display notification on error.
     */
    showErrorNotification?: boolean;

    /**
     * Function to transform received data as needed.
     */
    transform?: (data: any) => any;

    /**
     * Function to run on error.
     */
    onError?: (action: Function) => void;
}
