///
/// Action Statistics actions
/// resourceName: actionStatisticsResourceName
///
import { actionStatisticsResourceName } from "store/configureResources";
import { getKey } from "store/utils";

import { createResource } from "store/resources/actions/createResource";

const getResourceParams = ({
    idInputLog,
    idResultsLog,
    resourceId,
    actionType,
    started,
    finished,
    actionStatus,
    onComplete,
}: ActionStatisticsParams) => ({
    resourceName: actionStatisticsResourceName,
    key: `${actionStatisticsResourceName}${getKey(idInputLog)}${getKey(idResultsLog)}${getKey(resourceId)}`,
    body: {
        idInputLog,
        idResultsLog,
        resourceId,
        actionType,
        started,
        finished,
        actionStatus,
    },
    optimisticUpdate: false,
    updateFromResponse: false,
    showSuccessNotification: false,
    onComplete: () => {
        onComplete && onComplete();
    },
});

export const createActionStatistics =
    ({ idInputLog, idResultsLog, resourceId, actionType, started, finished, actionStatus, onComplete }: ActionStatisticsParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            createResource(
                getResourceParams({
                    idInputLog,
                    idResultsLog,
                    resourceId,
                    actionType,
                    started,
                    finished,
                    actionStatus,
                    onComplete,
                })
            )
        );
    };

interface ActionStatisticsParams {
    /**
     * ID of Model.
     */
    idInputLog: number;

    /**
     * ID of Results.
     */
    idResultsLog?: number;

    /**
     * ID of resource.
     */
    resourceId: string;

    /**
     * Type of action.
     */
    actionType: string;

    /**
     * When action was started.
     */
    started: string;

    /**
     * When action was finished.
     */
    finished: string;

    /**
     * Status of action.
     */
    actionStatus: string;

    /**
     * Function to run on completion.
     */
    onComplete?: () => void;
}
