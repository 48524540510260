import { isEmpty } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { uploadProjectFile } from "store/processes/actions/dataImport/projectFileImportActions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import Checkbox from "components/ui/Input/Checkbox";
import UploadFile from "components/ui/Input/UploadFile";

import { fileShareTypes, internalTypes } from "utils/constants";
import { hasInternalUserRole } from "utils/user";

import { UploadProjectFileModalProps } from "layouts/Modal/ProjectDashboardModal/types";
import { useProjectFileAccess, useProjectFileTypes } from "store/resources/actions/projectFiles/projectFilesActions";

const UploadProjectFile = memo(({ idProject, clientName, onLoading, onCancel }: UploadProjectFileModalProps) => {
    const dispatch = useDispatch();

    // @ts-ignore
    const rights = useSelector((state) => state.vdsmUser.rights[0]);

    const [projectFileAccess, isLoadingProjectFileAccess] = useProjectFileAccess();
    const [projectFileTypes, isLoadingProjectFileTypes] = useProjectFileTypes();

    const [fileAccess, setFileAccess] = useState("");
    const [share, setShare] = useState(false);
    const [fileType, setFileType] = useState("");
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState<File[]>([]);

    const showUpload = hasInternalUserRole() ? fileType !== "" && !isEmpty(file) : !isEmpty(file);

    if (isEmpty(fileAccess) && !isEmpty(projectFileAccess) && !isLoadingProjectFileAccess) {
        setFileAccess(projectFileAccess[0].value);
    }

    const fileAccessItems = useMemo(() => {
        let fileAccessItems: any = [];

        if (!isEmpty(projectFileAccess) && !isLoadingProjectFileAccess) {
            fileAccessItems = projectFileAccess.map((access) => ({
                label: access.uiLabel,
                value: access.value,
            }));
        }

        return fileAccessItems;
    }, [projectFileAccess, isLoadingProjectFileAccess]);

    const fileTypeItems = useMemo(() => {
        let fileTypeItems: any = [];

        if (!isEmpty(projectFileAccess) && !isEmpty(projectFileTypes) && !isLoadingProjectFileAccess && !isLoadingProjectFileTypes) {
            if (fileAccess === "FileShare") {
                fileTypeItems = projectFileTypes
                    .filter((f) => fileShareTypes.includes(f.value))
                    .map((f) => ({ label: f.uiLabel, value: f.value }));
            } else {
                fileTypeItems = projectFileTypes
                    .filter((f) => internalTypes.includes(f.value))
                    .map((f) => ({ label: f.uiLabel, value: f.value }));
            }
        }

        return fileTypeItems;
    }, [projectFileAccess, projectFileTypes, isLoadingProjectFileAccess, isLoadingProjectFileTypes, fileAccess]);

    const acceptedFileTypes = useMemo(() => {
        let acceptedFileTypes = [".xls", ".xlsb", ".xlsm", ".xlsx"];

        if (fileType === "OtherReport") {
            acceptedFileTypes = [...acceptedFileTypes, ".csv", ".doc", ".docx", ".ppt", ".pptx", ".pptm"];
        } else if (fileType === "FinalReport") {
            acceptedFileTypes = [".pdf"]; // Final Report should only be PDF file
        }

        acceptedFileTypes.sort((a, b) => a.localeCompare(b));

        return acceptedFileTypes;
    }, [fileType]);

    const onChange = useCallback((value: any, name: string) => {
        switch (name) {
            case "fileAccess":
                setShare(false);
                setFile([]);
                setFileType("");
                setFileAccess(value);

                break;

            case "share":
                setShare(value);

                break;

            case "fileType":
                setFile([]);
                setFileType(value);

                break;

            case "fileName":
                setFileName(value);

                break;

            default:
        }
    }, []);

    const onDropFile = useCallback((event: any) => {
        setFile(event);
    }, []);

    const onRemoveFile = useCallback((files: File[]) => {
        setFile(files);
    }, []);

    const onUploadClick = useCallback(() => {
        const type = hasInternalUserRole() ? fileType : "OtherReport"; // External Clients can only upload OtherReport file types

        dispatch(
            uploadProjectFile({
                idProject,
                fileAccess,
                shareWithStakeholder: type === "OtherReport" ? share : false,
                fileType: type,
                fileName,
                clientName: !hasInternalUserRole() ? clientName : undefined,
                file: file[0],
                rights,
                onStart: onLoading,
                onComplete: onCancel,
            })
        );
    }, [idProject, fileAccess, share, fileType, fileName, clientName, file, rights, onLoading, onCancel, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding">
                {isLoadingProjectFileTypes ? (
                    <IconLoading />
                ) : (
                    <FieldGroup>
                        {/* Only internal user can choose file access level */}
                        {hasInternalUserRole() && (
                            <>
                                <Dropdown
                                    label="File access"
                                    required
                                    value={fileAccess}
                                    items={fileAccessItems}
                                    onChange={(value) => onChange(value, "fileAccess")}
                                />
                                <Dropdown
                                    label="File type"
                                    placeholder="Choose the file type"
                                    required
                                    value={fileType}
                                    items={fileTypeItems}
                                    onChange={(value) => onChange(value, "fileType")}
                                />
                                {fileAccess === "FileShare" && fileType === "OtherReport" && (
                                    <Checkbox
                                        id="share"
                                        name="share"
                                        label="Share with stakeholders"
                                        checked={share}
                                        onChange={(event) => setShare(event.target.checked)}
                                    />
                                )}
                            </>
                        )}
                        <Input
                            label="File name"
                            placeholder="Name"
                            value={fileName}
                            onChange={(event) => onChange(event.target.value, "fileName")}
                        />
                        <UploadFile
                            required
                            label="Data file"
                            accept={acceptedFileTypes}
                            files={file}
                            onDrop={onDropFile}
                            onRemove={(files) => onRemoveFile(files)}
                        />
                    </FieldGroup>
                )}
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                {showUpload && (
                    <Button variant="primary" onClick={onUploadClick} padding="lg">
                        Upload
                    </Button>
                )}
                <Button variant="secondary" onClick={onCancel} padding="lg">
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default UploadProjectFile;
