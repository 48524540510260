import { isEmpty, isNil } from "lodash";
import { useMemo } from "react";

import { Module } from "store/resources/actions/calculationWorkflow/types";

/**
 * Add sub modules to parent module
 *
 * @param modules - all modules
 * @param results - initial array with parent module
 * @returns flattened array with parent module and its sub modules
 */

const addSubModules = (modules: Module[], results: Module[]) => {
    const parent = results[0];
    parent.priority = 0;

    // 2nd level
    for (const sub of modules) {
        if (!isNil(sub.parentId)) {
            if (sub.parentId === parent.moduleId) {
                parent.hasChildren = true;
                sub.priority = 1;

                results.push(sub);
            }
            // 3rd level
            else {
                const subParent = results.find((m) => m.moduleId === sub.parentId);

                if (subParent) {
                    subParent.hasChildren = true;
                    sub.priority = 2;

                    results.push(sub);
                }
            }
        }
    }

    return results;
};

export const useModuleList = ({ modules }: { modules: Module[] }) =>
    useMemo(() => {
        let moduleList: Module[][] = [];
        let groupIndex = -1;

        if (!isEmpty(modules)) {
            for (const module of modules) {
                if (isNil(module.parentId)) {
                    // Create a new group for every root module
                    moduleList[++groupIndex] = [module];

                    // Add all sub modules to every group
                    addSubModules(modules, moduleList[groupIndex]);
                }
            }
        }

        return moduleList;
    }, [modules]);

export const useRunList = ({ moduleList }: { moduleList: Module[][] }) =>
    useMemo(() => {
        let runList: {
            moduleId: number;
            priority?: number;
        }[] = [];

        if (!isEmpty(moduleList)) {
            runList = moduleList
                .flat()
                .filter((item) => ["PENDING", "RECALC_PENDING"].includes(item.runState))
                .map((item) => ({
                    moduleId: item.moduleId,
                    priority: item.priority,
                }));
        }

        return runList;
    }, [moduleList]);
