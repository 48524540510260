import { upperFirst } from "lodash";
import { memo, useState, useEffect, useCallback } from "react";

import { modalOpen, modalClose } from "store/modal/actions";

import { useAvoidedCostsSetup } from "store/resources/actions/modelInputs/avoidedCostsSetupActions";
import { createAvoidedCostsTemplate } from "store/resources/actions/avoidedCosts/avoidedCostsTemplateActions";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Input/Checkbox";
import IconLoading from "components/ui/Icons/IconLoading";

import { openWindowModifyAvoidedCosts } from "utils/window";

import "./style.scss";

export const openModalDialogAvoidedCostsSetup =
    ({ idProject, projectName, model, onCancel }) =>
    (dispatch) => {
        const { idInputLog } = model;
        const title = "Create inputs from Tech Data";

        dispatch(
            modalOpen({
                modalType: "WAITING_MODAL",
                modalProps: {
                    title,
                },
            })
        );

        const renderModal = (data) => {
            const ModalContent = memo((data) => {
                const [acSetup, isLoading] = useAvoidedCostsSetup({ idInputLog });
                const [columnsChecked, setColumnsChecked] = useState({});

                useEffect(() => {
                    if (!isLoading) {
                        if (acSetup?.columns.length > 0) {
                            const initColumnState =
                                Object.assign({}, ...acSetup?.columns?.map((item) => ({ [upperFirst(item.toLowerCase())]: true }))) || {};
                            setColumnsChecked({
                                ...initColumnState,
                            });
                        }
                    }
                }, [isLoading, acSetup]);

                const onChangeColumnCheck = useCallback(
                    (e) => {
                        setColumnsChecked({ ...columnsChecked, [e.target.name]: e.target.checked });
                    },
                    [columnsChecked, setColumnsChecked]
                );

                const onClickCancel = useCallback(() => {
                    dispatch(modalClose());
                    onCancel && onCancel();
                }, []);

                const onClickGenerate = useCallback(() => {
                    const columnList = Object.keys(columnsChecked).filter((key) => columnsChecked[key]);
                    dispatch(
                        createAvoidedCostsTemplate({
                            idInputLog,
                            columnList,
                            onComplete: () => {
                                dispatch(modalClose());
                                dispatch(openWindowModifyAvoidedCosts({ idProject, projectName, model }));
                            },
                        })
                    );
                }, [columnsChecked]);

                const renderCheckboxes = (item, index, onChangeHandler, stateHolder) => {
                    const columnName = upperFirst(item.toLowerCase());

                    return (
                        <div key={`${item}-${index}`} className="ac-column-checkbox">
                            <Checkbox
                                id={`ac-col-${columnName}`}
                                name={columnName}
                                label={columnName}
                                checked={stateHolder[columnName] || false}
                                onChange={onChangeHandler}
                            />
                        </div>
                    );
                };

                return (
                    <>
                        <div className="flex-column modal-padding">
                            {isLoading ? (
                                <IconLoading />
                            ) : (
                                <div className="flex-column">
                                    {acSetup?.isTechDataLoaded === false && (
                                        <div className="status-msg error-msg circle-exclamation-filled with-icon msg-visible msg-field-status ac-margin-bottom">
                                            Please upload "Tech Data" before creating Inputs for Avoided Costs
                                        </div>
                                    )}
                                    <div className="flex-column">
                                        <div>Columns to import</div>
                                        <div className="flex-row flex-wrap ac-margin-title">
                                            {acSetup?.columns?.map((columnName, index) =>
                                                renderCheckboxes(columnName, index, onChangeColumnCheck, columnsChecked)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <ButtonGroup className="justify-end modal-actions">
                            {acSetup?.isTechDataLoaded && (
                                <Button primary onClick={onClickGenerate}>
                                    Generate
                                </Button>
                            )}
                            <Button onClick={onClickCancel}>Cancel</Button>
                        </ButtonGroup>
                    </>
                );
            });

            dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title,
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        children: <ModalContent data={data} />,
                        withScroll: false,
                        noFooter: true,
                    },
                })
            );
        };

        renderModal();
    };
