import { isEmpty } from "lodash";
import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { resetLogin as onResetLogin } from "store/login/actions";

import { useTwoFactor } from "store/resources/actions/user/userTwoFactorActions";

import FormWrap from "pages/common/FormWrap";

import { IdsText } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import FieldGroup from "components/ui/FieldGroup";

import { useAutoFillDetect } from "utils/useAutoFillDetect";

import "./style.scss";

const inputIds = ["username", "password"];

const LoginForm: React.NamedExoticComponent<{
    isLoading: boolean;
    errorMessage: string | null;
    onSubmit: (values: any) => void;
    onForgotPassword: (username: string) => void;
}> = memo(({ isLoading, errorMessage, onSubmit, onForgotPassword }) => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [authCode, setAuthCode] = useState("");

    const [twoFactorCheck, setTwoFactorCheck] = useState(false);
    const [twoFactorError, setTwoFactorError] = useState("");

    const [twoFactorInfo, isLoadingTwoFactorInfo] = useTwoFactor({
        userName: username,
        skip: !twoFactorCheck,
        onError: setTwoFactorError,
    });

    const [isAutoFilled] = useAutoFillDetect(inputIds);

    const isUsernameValid = username.trim().length > 0;
    const isPasswordValid = password.trim().length > 0;
    const isAuthCodeValid = twoFactorInfo?.twoFactorRequired && twoFactorInfo?.qrCodeExists ? authCode.trim().length > 0 : true;

    let signInDisabled = true;

    // Input fields on WebKit browsers are empty on autofill
    if (isAutoFilled && isEmpty(username) && isEmpty(password)) {
        signInDisabled = false;
    } else if (!isLoading && !isLoadingTwoFactorInfo && isUsernameValid && isPasswordValid && isAuthCodeValid) {
        signInDisabled = false;
    }

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        switch (name) {
            case "username":
                setUsername(event.target.value);
                setTwoFactorCheck(false);

                break;

            case "password":
                setPassword(event.target.value);

                break;

            case "authCode":
                setAuthCode(event.target.value);

                break;

            default:
        }
    }, []);

    const performTwoFactorCheck = useCallback(() => {
        setTwoFactorCheck(true);
    }, []);

    const handleForgotPassword = useCallback(() => {
        dispatch(onResetLogin());

        onForgotPassword(username);
    }, [username, onForgotPassword, dispatch]);

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();

            onSubmit({ userName: username, password, authCode });
        },
        [username, password, authCode, onSubmit]
    );

    return (
        <FormWrap>
            <form className="flex-column login-form" onSubmit={handleSubmit}>
                {isLoading ? (
                    <div className="flex-column flex-one align-center justify-center">
                        <IconLoading />
                    </div>
                ) : (
                    <>
                        {(errorMessage || twoFactorError) && (
                            <div className="login-form__error-message">
                                <ErrorMsg largeMsg message={errorMessage || twoFactorError} />
                            </div>
                        )}
                        <div className="text-center login-form__header">Sign in to your account</div>
                        <FieldGroup className="login-form__inputs">
                            <Input
                                label="Username"
                                id={inputIds[0]}
                                name="userName"
                                placeholder="Enter your username"
                                value={username}
                                autoFocus={!twoFactorCheck}
                                onChange={(event) => onChange(event, "username")}
                                onBlur={performTwoFactorCheck}
                            />
                            <Input
                                label="Password"
                                id={inputIds[1]}
                                type="password"
                                placeholder="Enter your password"
                                autoFocus={twoFactorCheck}
                                value={password}
                                onChange={(event) => onChange(event, "password")}
                            />
                            {twoFactorInfo?.twoFactorRequired && twoFactorInfo?.qrCodeExists && (
                                <Input
                                    label="Authentication Code"
                                    placeholder="Enter authentication code"
                                    value={authCode}
                                    onChange={(event) => onChange(event, "authCode")}
                                />
                            )}
                        </FieldGroup>
                    </>
                )}
                <div className="flex-row align-center justify-space-between">
                    <IdsText
                        customClasses="text-underline clickable"
                        weight="bold"
                        style={{ color: "var(--ids-semantic-ink-color-brand-a-accent)" }}
                        onClick={handleForgotPassword}
                    >
                        Forgot password?
                    </IdsText>
                    <div className="button-container">
                        <Button
                            ariaLabel="Sign in button"
                            type="submit"
                            variant="primary"
                            isDisabled={signInDisabled}
                            isLoading={isLoading || isLoadingTwoFactorInfo}
                            fullWidth
                            padding="lg"
                        >
                            <>{isLoading || isLoadingTwoFactorInfo ? "Loading..." : "Sign In"}</>
                        </Button>
                    </div>
                </div>
            </form>
        </FormWrap>
    );
});

export default LoginForm;
