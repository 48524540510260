import { batch } from "react-redux";
import { toast } from "react-toastify";

import { MODEL_DATA_IMPORT_STARTED } from "store/actionTypes";
import { modelDataImportStatusResourceName } from "store/configureResources";

import { checkProcessStatus } from "store/processes/useProcessStatus";
import { modelDataImportStatusProps } from "store/processes/useModelDataImportStatus";

import { createResource } from "store/resources/actions/createResource";
import { clearResource } from "store/resources/actions/clearResource";

import { createActionStatistics } from "store/resources/actions/actionStatistics/actionStatisticsActions";
import { clearProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { clearComponentModelList } from "store/resources/actions/componentModel/componentModelActions";

import { isDevEnvironment } from "utils/constants";

import { UploadDatasetParams } from "./types";

const startModelDataImportStatusCheck =
    () =>
    // @ts-ignore
    (dispatch) => {
        // If status check is already scheduled - do nothing
        if (modelDataImportStatusProps.timeoutHandlers) {
            return;
        }

        dispatch(checkProcessStatus(modelDataImportStatusProps));
    };

export const uploadDataset =
    ({
        resourceName,
        id,
        idProject,
        idInputLog,
        idTerritory,
        idFuel,
        idAnalyst,
        idReviewer,
        file,
        name,
        required,
        description,
        selectedSheets,
        onStart,
        onError,
        onComplete,
    }: UploadDatasetParams) =>
    // @ts-ignore
    (dispatch) => {
        const startTime = new Date().toISOString().slice(0, -1);
        let formData = new FormData();

        formData.append("id", id);
        formData.append("file", file);
        formData.append("name", name);
        formData.append("idTerritory", idTerritory.toString());
        formData.append("idFuel", idFuel.toString());
        required && formData.append("required", required.toString());
        description && formData.append("description", description);
        idAnalyst && formData.append("idAnalyst", idAnalyst.toString());
        idReviewer && formData.append("idReviewer", idReviewer.toString());
        selectedSheets && selectedSheets.forEach((sh) => formData.append("sheets", sh));

        const onUploadSuccess = (action: any, startTime: string) => {
            const { responseStatus, responseMessage, newIdInputLog } = action?.data;

            if (isDevEnvironment && responseStatus === "success\nsuccess") {
                console.log(action);
            }

            if (responseStatus === "failure\nfailure") {
                toast.error(responseMessage.split("\n", 2)[0]);

                dispatch(clearProjectInputs({ idProject }));
                dispatch(clearComponentModelList({ idProject }));

                return;
            }

            batch(() => {
                dispatch(
                    createActionStatistics({
                        idInputLog: newIdInputLog,
                        resourceId: id,
                        actionType: "DATA UPLOAD",
                        started: startTime,
                        finished: new Date().toISOString().slice(0, -1),
                        actionStatus: "success",
                    })
                );

                dispatch({
                    type: MODEL_DATA_IMPORT_STARTED,
                    idProject,
                    idInputLog: newIdInputLog,
                    id,
                });

                dispatch(startModelDataImportStatusCheck());

                dispatch(
                    clearResource({
                        resourceName: modelDataImportStatusResourceName,
                        key: modelDataImportStatusResourceName,
                        broadcast: true,
                    })
                );
            });

            onComplete?.(newIdInputLog);
        };

        dispatch(
            createResource({
                resourceName,
                resourceId: id,
                query: {
                    idProject,
                    idInputLog,
                },
                body: formData,
                onStart,
                onSuccess: (action) => {
                    onUploadSuccess(action, startTime);
                },
                onError,
            })
        );
    };
