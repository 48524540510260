import IconWrap from "components/ui/Icons";

import { FolderChainProps } from "./types";

import "./style.scss";

const FolderChain = ({ folderChain, onStepBackClick, onDirectoryClick }: FolderChainProps) => {
    return (
        <div className="flex-row align-center folder-chain">
            <div>
                <IconWrap icon="step-back" onClick={folderChain.length > 1 ? onStepBackClick : undefined} />
            </div>
            <div className="flex-row folder-chain__items">
                {folderChain.map((file, index) => {
                    const isLastItem = index === folderChain.length - 1;

                    return (
                        <div key={`folder-chain-item-${index}`} className="flex-row align-center">
                            <div className="clickable folder-chain__item-name" onClick={() => onDirectoryClick(file)}>
                                {file.name}
                            </div>
                            {!isLastItem && <div className="folder-chain__item-separator" />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FolderChain;
