import { isNil } from "lodash";
import { memo } from "react";

import Button from "components/ui/ButtonNew";
import LabelWithIcon from "components/ui/LabelWithIcon";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";

import Markup from "components/ui/CKEditor/Markup";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import { HelpTipsViewProps } from "./types";

import "./style.scss";

const HelpTipsView = memo((props: HelpTipsViewProps) => {
    return (
        <SideNavContent className="help-tips-view">
            <SideNavHeader onClose={props.onClose}>
                <LabelWithIcon className="faq-icon" icon="text-help__question__faq_b_s" iconSize="md">
                    Help Tip
                </LabelWithIcon>
            </SideNavHeader>
            <SideNavBody className="help-tips-view__body">
                {!isNil(props.title) || !isNil(props.content) ? (
                    <>
                        <div className="help-tips-view__title">{props.title}</div>
                        <div className="with-scroll">
                            <div className="help-tips-view__content-container">
                                <Markup content={props.content} />
                            </div>
                        </div>
                    </>
                ) : (
                    <NothingFoundBlock icon="text-help__question__faq_b_s" title="There are no tips added for the selected panel" />
                )}
            </SideNavBody>
            {props.onEdit && (
                <SideNavFooter className="flex-row justify-end help-tips-view__footer">
                    <Button variant="tertiary" padding="md" iconLeft="ui-pencil__edit__create_b_s" iconSize="sm" onClick={props.onEdit}>
                        Edit Content
                    </Button>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
});

export default HelpTipsView;
