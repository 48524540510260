import { CLOSE_AVOIDED_COSTS, OPEN_AVOIDED_COSTS } from "store/actionTypes";
import { getKey } from "store/utils";
import { modalOpen, modalClose } from "store/modal/actions";
import { windowAdd, windowAddSplit, windowRemove } from "store/window/actions";

import { getProjectFile } from "store/resources/actions/projectFiles/projectFilesActions";

import {
    isVLMLoginPage,
    singleModelReportId,
    combinedModelReportId,
    measureLevelReportId,
    modelInputLevelReportIds,
    projectComparisonReportId,
    singleModelDatasetId,
    combinedModelDatasetId,
    measureLevelDatasetId,
    modelInputLevelDatasetIds,
    projectComparisonDatasetId,
} from "utils/constants";

import {
    OpenWindowParams,
    OpenSplitWindowParams,
    CloseWindowParams,
    OpenPowerBIReportParams,
    OpenProjectSourcesParams,
    OpenProjectParams,
    OpenStandaloneReportParams,
    OpenCalculationWorkflowParams,
    OpenModifyAvoidedCostsParams,
    OpenProjectFinalReportParams,
    OpenProjectComparisonReportParams,
    OpenSingleModelReportParams,
    OpenCombinedModelReportParams,
    OpenSingleModelReportComparisonParams,
    CloseCalculationWorkflowParams,
    ModelInputReportParams,
} from "./types";

const storage = sessionStorage;
const WINDOW_STATE_KEY = "window-state";

export const windowContainerTypes = {
    Root: "root-window",
    Login: "login-window",
    Home: "home-window",
};

export const EMPTY_SPLIT_VIEW_NAME = "SplitView";
export const EMPTY_SPLIT_VIEW_TITLE = " ";

export const getWindowState = () => {
    const item = storage.getItem(WINDOW_STATE_KEY);

    if (item !== null) {
        return JSON.parse(item);
    } else {
        return {};
    }
};

export const setWindowState = (state: any) => {
    if (state !== null) {
        storage.setItem(WINDOW_STATE_KEY, JSON.stringify(state));
    }
};

export const deleteWindowState = () => {
    storage.removeItem(WINDOW_STATE_KEY);
};

export const getLoginWindowParams = () => {
    return {
        containerName: windowContainerTypes.Login,
        name: isVLMLoginPage ? "VisionLoadMAP Login" : "Login",
        component: isVLMLoginPage ? "VLMLogin" : "Login",
        showHeader: false,
        showTabs: false,
        activate: true,
    };
};

const openWindow =
    (params: OpenWindowParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            windowAdd({
                name: params.name,
                containerName: windowContainerTypes.Root,
                component: params.component,
                showHeader: params.showHeader !== undefined ? params.showHeader : true,
                showTabs: params.showTabs !== undefined ? params.showTabs : true,
                headerTitle: params.headerTitle,
                headerSubTitle: params.headerSubTitle,
                tabTitle: params.tabTitle,
                tabSubTitle: params.tabSubTitle,
                tabIcon: params.tabIcon,
                activate: true,
                close: params.close !== undefined ? params.close : true,
                persist: params.persist,
                isSplitViewEnabled: params.isSplitViewEnabled !== undefined ? params.isSplitViewEnabled : false,
                splitView: params.splitView !== undefined ? params.splitView : false,
                props: params.props,
                onClose: params.onClose !== undefined ? params.onClose : undefined,
            })
        );
    };

const openSplitWindow =
    (params: OpenSplitWindowParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            windowAddSplit({
                name: params.name,
                containerName: windowContainerTypes.Root,
                showHeader: params.showHeader,
                showTabs: params.showTabs,
                activate: params.activate,
                persist: params.persist,
                isSplitViewEnabled: params.isSplitViewEnabled,
                leftView: params.leftView,
                rightView: params.rightView,
            })
        );
    };

const openWindowPowerBIReport =
    ({ name, component, headerTitle, headerSubTitle, tabTitle, tabSubTitle, tabIcon, props }: OpenPowerBIReportParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openWindow({
                name,
                component,
                headerTitle: headerTitle || "PowerBI View",
                headerSubTitle,
                tabTitle,
                tabSubTitle: tabSubTitle || "PowerBI View",
                tabIcon,
                isSplitViewEnabled: true,
                props,
            })
        );
    };

export const closeWindow =
    ({ name }: CloseWindowParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            windowRemove({
                name,
                containerName: windowContainerTypes.Root,
            })
        );
    };

// OPEN NEW WINDOWS

/**
 * Opens Login page.
 */
export const openWindowLogin =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(windowAdd(getLoginWindowParams()));
    };

/**
 * Opens User Profile page.
 */
export const openWindowUserProfile =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openWindow({
                name: "UserProfile",
                component: "UserProfile",
                headerTitle: "User Profile",
                tabSubTitle: "User Profile",
            })
        );
    };

/**
 * Opens Developer page.
 */
export const openWindowDeveloperPage =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openWindow({
                name: "DevPage",
                component: "DeveloperPage",
                headerTitle: "Developer Page",
                tabSubTitle: "Developer Page",
            })
        );
    };

/**
 * Opens Project Sources page.
 */
export const openWindowProjectSources =
    ({ idClient, idProject, clientName, projectName }: OpenProjectSourcesParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openWindow({
                name: `project-sources-${clientName}-${projectName}`,
                component: "ProjectSources",
                headerTitle: clientName,
                headerSubTitle: projectName,
                tabTitle: clientName,
                tabSubTitle: projectName,
                tabIcon: "files-personal_assignment_b_s",
                isSplitViewEnabled: true,
                props: {
                    idClient,
                    idProject,
                },
            })
        );
    };

/**
 * Opens Project Dashboard page.
 */
export const openWindowProject =
    ({ project, clientName }: OpenProjectParams) =>
    // @ts-ignore
    (dispatch) => {
        const { idClient, idProject, projectName } = project;

        dispatch(
            openWindow({
                name: `client-${idClient}-project-${idProject}`,
                component: "ManageProject",
                headerTitle: clientName,
                headerSubTitle: projectName,
                tabTitle: clientName,
                tabSubTitle: projectName,
                tabIcon: "files-assignment_b_s",
                isSplitViewEnabled: true,
                props: {
                    project,
                    clientName,
                },
            })
        );
    };

/**
 * Opens Project Dashboard for Stakeholders.
 */
export const openWindowProjectStakeholder =
    ({ project, clientName }: OpenProjectParams) =>
    (dispatch) => {
        const { idClient, idProject, projectName } = project;

        dispatch(
            openWindow({
                name: `client-${idClient}-project-stakeholder-${idProject}`,
                component: "ProjectStakeholderDashboard",
                showHeader: false,
                tabTitle: clientName,
                tabSubTitle: projectName,
                tabIcon: "files-assignment_b_s",
                props: {
                    project,
                    clientName,
                },
            })
        );
    };

/**
 * Opens Standalone Report for Stakeholders.
 */
export const openWindowStandaloneReport =
    ({ idProject, idReport, idPbi, reportName, fileName, reportType, projectName }: OpenStandaloneReportParams) =>
    (dispatch) => {
        dispatch(
            openWindow({
                name: `standalone-report-${idReport}`,
                component: "StandaloneReport",
                headerTitle: projectName,
                headerSubTitle: reportName,
                tabTitle: projectName,
                tabSubTitle: reportName,
                tabIcon: "files-document_diagram__poll_b_s",
                props: {
                    idProject,
                    idReport,
                    idPbi,
                    reportType,
                    fileName,
                },
            })
        );
    };

/**
 * Opens Calculation Workflow page.
 */
export const openWindowCalculationWorkflow =
    ({
        idClient,
        idProject,
        idAnalyst,
        idReviewer,
        idModel,
        idInputLog,
        clientName,
        projectName,
        modelName,
        modelState,
        required,
    }: OpenCalculationWorkflowParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openWindow({
                name: `calculation-workflow-${idProject}-${idModel}`,
                component: "ManageCalculationWorkflow",
                headerTitle: clientName,
                headerSubTitle: projectName,
                tabTitle: projectName,
                tabSubTitle: modelName,
                tabIcon: "files-chart__assessment_b_s",
                props: {
                    idClient,
                    idProject,
                    idAnalyst,
                    idReviewer,
                    idModel,
                    idInputLog,
                    modelName,
                    modelState,
                    modelRequired: required,
                },
            })
        );
    };

/**
 * Opens Avoided Costs page.
 */
export const openWindowModifyAvoidedCosts =
    ({ idProject, projectName, model }: OpenModifyAvoidedCostsParams) =>
    // @ts-ignore
    (dispatch) => {
        const { idInputLog, name } = model;

        dispatch({
            type: OPEN_AVOIDED_COSTS,
            selection: {
                idProject,
                idInputLog,
            },
        });

        const onClose = () => {
            dispatch({
                type: CLOSE_AVOIDED_COSTS,
                selection: {
                    idProject,
                    idInputLog,
                },
            });
        };

        dispatch(
            openWindow({
                name: `modifyAvoidedCosts-${idInputLog}`,
                component: "ModifyAvoidedCosts",
                headerTitle: `Avoided Costs ${name}`,
                tabTitle: projectName,
                tabSubTitle: `${name}`,
                tabIcon: "places-scales__judge__justice__courthouse_b_s",
                isSplitViewEnabled: true,
                props: {
                    idProject,
                    idInputLog,
                },
                onClose,
            })
        );
    };

/**
 * Opens Project Final Report in a new browser's tab.
 */
export const openWindowProjectFinalReport =
    ({ idProject }: OpenProjectFinalReportParams) =>
    // @ts-ignore
    async (dispatch) => {
        try {
            dispatch(
                modalOpen({
                    modalType: "WAITING_MODAL",
                    modalProps: {
                        title: "Project Final Report",
                    },
                })
            );

            const finalReportFile = await getProjectFile({ idProject });

            const fileURL = URL.createObjectURL(finalReportFile.blob);

            window.open(fileURL, "_blank");
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(modalClose());
        }
    };

export const openWindowProjectComparisonReport =
    ({ client }: OpenProjectComparisonReportParams) =>
    // @ts-ignore
    (dispatch) => {
        const powerBIProps = {
            reportId: projectComparisonReportId,
            datasetId: projectComparisonDatasetId,
            idClient: client.id,
            filterEntityId: client.id,
        };

        dispatch(
            openWindowPowerBIReport({
                name: `ProjectComparisonReport${getKey(projectComparisonReportId)}${getKey(client.id)}`,
                component: "PowerBI",
                headerTitle: client.clientName,
                tabSubTitle: client.clientName,
                tabIcon: "ui-chart_pie__data_usage__circular_diagram_b_s",
                props: powerBIProps,
            })
        );
    };

/**
 * Opens page with VLM Single Model Report inside.
 */
export const openWindowSingleModelReport =
    ({ idClient, idProject, idModel, idInputLog, filterEntityId, title, subTitle, showApprovement }: OpenSingleModelReportParams) =>
    // @ts-ignore
    (dispatch) => {
        const powerBIProps = {
            reportId: singleModelReportId,
            datasetId: singleModelDatasetId,
            idClient,
            idProject,
            idModel,
            idInputLog,
            filterEntityId,
            showApprovement,
        };

        dispatch(
            openWindowPowerBIReport({
                name: `SingleModelReport-${idInputLog}-${filterEntityId}`,
                component: "FloatModalPowerBI",
                headerTitle: title,
                headerSubTitle: subTitle,
                tabTitle: title,
                tabSubTitle: subTitle,
                tabIcon: "ui-chart_multiple_b_s",
                props: powerBIProps,
            })
        );
    };

/**
 * Opens page with VLM Combined Model Report inside.
 */
export const openWindowCombinedModelReport =
    ({ idClient, idProject, filterEntityId, projectName, combinationName }: OpenCombinedModelReportParams) =>
    // @ts-ignore
    (dispatch) => {
        const powerBIProps = {
            reportId: combinedModelReportId,
            datasetId: combinedModelDatasetId,
            idClient,
            idProject,
            filterEntityId,
            allowButtonEvents: true,
        };

        dispatch(
            openWindowPowerBIReport({
                name: `CombinedModelReport-${combinedModelReportId}-${filterEntityId}`,
                component: "PowerBI",
                headerTitle: projectName,
                headerSubTitle: combinationName,
                tabTitle: projectName,
                tabSubTitle: combinationName,
                tabIcon: "ui-chart_multiple_b_s",
                props: powerBIProps,
            })
        );
    };

/**
 * Opens page with Measure-level Report inside.
 */
export const openWindowMeasureLevelReport =
    ({ idClient, idProject, filterEntityId, projectName, combinationName }: OpenCombinedModelReportParams) =>
    // @ts-ignore
    (dispatch) => {
        const powerBIProps = {
            reportId: measureLevelReportId,
            datasetId: measureLevelDatasetId,
            idClient,
            idProject,
            filterEntityId,
            isPaginated: true,
        };

        dispatch(
            openWindowPowerBIReport({
                name: `MeasureLevelReport-${measureLevelReportId}-${filterEntityId}`,
                component: "PowerBI",
                headerTitle: projectName,
                headerSubTitle: combinationName,
                tabTitle: projectName,
                tabSubTitle: combinationName,
                tabIcon: "files-document_table_b_s",
                props: powerBIProps,
            })
        );
    };

/**
 * Opens page with Model Input Paginated Report inside.
 */
export const openWindowModelInputPaginatedReport =
    ({ idClient, idProject, filterEntityId, projectName, configName, title }: ModelInputReportParams) =>
    // @ts-ignore
    (dispatch) => {
        const powerBIProps = {
            reportId: modelInputLevelReportIds[configName],
            datasetId: modelInputLevelDatasetIds[configName],
            idClient,
            idProject,
            filterEntityId,
            isPaginated: true,
            showRefresh: false,
        };

        dispatch(
            openWindowPowerBIReport({
                name: `ModelInputPaginatedReport-${modelInputLevelReportIds[configName]}-${filterEntityId}`,
                component: "PowerBI",
                headerTitle: title,
                tabTitle: projectName,
                tabSubTitle: title,
                tabIcon: "files-document_table_b_s",
                props: powerBIProps,
            })
        );
    };

/**
 * Opens page with Single Model Report side by side
 * to compare between two different Models.
 */
export const openWindowSingleModelReportComparison =
    ({ project, leftViewProps, rightViewProps }: OpenSingleModelReportComparisonParams) =>
    // @ts-ignore
    (dispatch) => {
        const { idClient, idProject, projectName } = project;

        const component = "FloatModalPowerBI";

        const idLeftView = `${leftViewProps.idInputLog}-${leftViewProps.filterEntityId}`;
        const idRightView = `${rightViewProps.idInputLog}-${rightViewProps.filterEntityId}`;

        const leftViewTitle = `${leftViewProps.modelName} - ${leftViewProps.finished}`;
        const rightViewTitle = `${rightViewProps.modelName} - ${rightViewProps.finished}`;

        const leftViewPowerBIProps = {
            reportId: singleModelReportId,
            datasetId: singleModelDatasetId,
            idClient,
            idProject,
            idInputLog: leftViewProps.idInputLog,
            filterEntityId: leftViewProps.filterEntityId,
        };

        const rightViewPowerBIProps = {
            reportId: singleModelReportId,
            datasetId: singleModelDatasetId,
            idClient,
            idProject,
            idInputLog: rightViewProps.idInputLog,
            filterEntityId: rightViewProps.filterEntityId,
        };

        const leftViewDropdownItems = leftViewProps.items.map((item) => ({
            ...item,
            itemProps: {
                ...item.itemProps,
                name: `SingleModelReport-${item.itemProps.idInputLog}-${item.itemProps.filterEntityId}`,
                tabSubTitle: item.label,
            },
        }));

        const rightViewDropdownItems = rightViewProps.items.map((item) => ({
            ...item,
            itemProps: {
                ...item.itemProps,
                name: `SingleModelReport-${item.itemProps.idInputLog}-${item.itemProps.filterEntityId}`,
                tabSubTitle: item.label,
            },
        }));

        const leftView = {
            name: `SingleModelReport-${idLeftView}`,
            component,
            tabTitle: projectName,
            tabSubTitle: leftViewTitle,
            tabIcon: "ui-chart_multiple_b_s",
            props: {
                ...leftViewPowerBIProps,
                renderDropdownHeader: true,
                dropdownItems: leftViewDropdownItems,
                idSelector: "idInputLog",
                side: "left",
            },
        };

        const rightView = {
            name: `SingleModelReport-${idRightView}`,
            component,
            tabTitle: projectName,
            tabSubTitle: rightViewTitle,
            tabIcon: "ui-chart_multiple_b_s",
            props: {
                ...rightViewPowerBIProps,
                renderDropdownHeader: true,
                renderPresentationMode: true,
                dropdownItems: rightViewDropdownItems,
                idSelector: "idInputLog",
                side: "right",
            },
        };

        dispatch(
            openSplitWindow({
                name: `SingleModelReportComparison-LEFT-${idLeftView}-RIGHT-${idRightView}`,
                leftView,
                rightView,
            })
        );
    };

// CLOSE WINDOWS

export const closeWindowProjectStakeholder =
    ({ idClient, idProject }) =>
    (dispatch) => {
        dispatch(
            closeWindow({
                name: `client-${idClient}-project-stakeholder-${idProject}`,
            })
        );
    };

export const closeWindowCalculationWorkflow =
    ({ idProject, idModel }: CloseCalculationWorkflowParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            closeWindow({
                name: `calculation-workflow-${idProject}-${idModel}`,
            })
        );
    };
