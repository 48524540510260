import { runModuleResourceName, runPendingModulesResourceName, pauseModuleResourceName } from "store/configureResources";

import { createResource } from "store/resources/actions/createResource";
import { updateResource } from "store/resources/actions/updateResource";

import { CreateRunModuleParams, CreateRunPendingModulesParams, UpdatePauseModuleParams } from "./types";

export const createRunModule =
    ({ moduleId, onSuccess, onError }: CreateRunModuleParams) =>
    (dispatch) => {
        dispatch(
            createResource({
                resourceName: runModuleResourceName,
                resourceId: runModuleResourceName,
                path: {
                    moduleId,
                },
                onSuccess,
                onError,
            })
        );
    };

export const createRunPendingModules =
    ({ idModel, runList, onSuccess, onError }: CreateRunPendingModulesParams) =>
    (dispatch) => {
        dispatch(
            createResource({
                resourceName: runPendingModulesResourceName,
                resourceId: runPendingModulesResourceName,
                path: {
                    idModel,
                },
                body: {
                    runList,
                },
                onSuccess,
                onError,
            })
        );
    };

export const updatePauseModule =
    ({ moduleId, resultsId, onSuccess, onError }: UpdatePauseModuleParams) =>
    (dispatch) => {
        dispatch(
            updateResource({
                resourceName: pauseModuleResourceName,
                path: {
                    moduleId,
                    resultsId,
                },
                onSuccess,
                onError,
            })
        );
    };
