import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const Portal = ({ children, container }) => {
    const portalElementRef = useRef(document.createElement("div"));

    const containerElement = container ?? document.body;

    useEffect(() => {
        const element = portalElementRef.current;

        element && containerElement.appendChild(element);

        return () => {
            element && containerElement.removeChild(element);
        };
    }, [containerElement]);

    return ReactDOM.createPortal(children, portalElementRef.current);
};

export default Portal;
