import { UserRole } from "./types";

// Storage keys

export const NAME_KEY = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
export const EXPIRES_IN_KEY = "ExpiresIn";

export const USER_STORAGE_KEY = "vinsight.user";
export const VDSM_USER_STORAGE_KEY = "vdsm.user";

// Actions, roles and rights

export const USER_ACTIONS = {
    // Client-level

    // Create new Client
    // Edit Client Attributes
    CLIENT_ADD: "CLIENT_ADD",
    CLIENT_EDIT: "CLIENT_EDIT",

    // Create new Project
    // Delete Project
    PROJECT_ADD: "PROJECT_ADD",
    PROJECT_DELETE: "PROJECT_DELETE",

    // Project-level

    // Edit Project information
    PROJECT_EDIT: "PROJECT_EDIT",

    // Create new Model
    MODEL_ADD: "MODEL_ADD",

    // Create Standalone Report
    SA_REPORT_ADD: "SA_REPORT_ADD",

    // Clear Trash Bin
    PROJECTION_DELETE_ALL: "PROJECTION_DELETE_ALL",

    // Upload Project Files
    // Delete Project Files
    PROJECT_FILES_ADD: "PROJECT_FILES_ADD",
    PROJECT_FILES_DELETE: "PROJECT_FILES_DELETE",

    // Change Project Study Case Goals
    // Change Project Variables
    // Change Model Variables
    PROJECT_STUDY_CASE_GOALS_EDIT: "PROJECT_STUDY_CASE_GOALS_EDIT",
    PROJECT_VARIABLES_EDIT: "PROJECT_VARIABLES_EDIT",
    PROJECT_LABELS_EDIT: "PROJECT_LABELS_EDIT",

    // Combine Models
    // Compare Models
    MODELS_COMBINE: "MODELS_COMBINE",
    MODELS_COMPARE: "MODELS_COMPARE",

    // Add or Update Market Profile data
    MARKET_PROFILE_EDIT: "MARKET_PROFILE_EDIT",

    // Model-level

    // Edit Model information
    // Set/unset Model as required for Project
    // Duplicate Model
    // Delete Model
    MODEL_EDIT: "MODEL_EDIT",
    MODEL_EDIT_REQUIRED: "MODEL_EDIT_REQUIRED",
    MODEL_DUPLICATE: "MODEL_DUPLICATE",
    MODEL_DELETE: "MODEL_DELETE",

    // Change Model Study Case Goals (Legacy Models only)
    // Change Model Variables
    MODEL_STUDY_CASE_GOALS_EDIT: "MODEL_STUDY_CASE_GOALS_EDIT",
    MODEL_VARIABLES_EDIT: "MODEL_VARIABLES_EDIT",

    // Generate Avoided Costs table
    // Edit Avoided Costs table
    AVOIDED_COSTS_ADD: "AVOIDED_COSTS_ADD",
    AVOIDED_COSTS_EDIT: "AVOIDED_COSTS_EDIT",

    // Add Model Log or Model Inputs note
    NOTE_ADD: "NOTE_ADD",

    // Run Calculations
    CALCULATIONS_RUN: "CALCULATIONS_RUN",

    // Module-level

    // Access Calculation Workflow page
    // Run Calculation Workflow
    CALCULATION_WORKFLOW_ACCESS: "CALCULATION_WORKFLOW_ACCESS",
    CALCULATION_WORKFLOW_RUN: "CALCULATION_WORKFLOW_RUN",

    // Approve/disapprove Modules
    MODULES_APPROVE: "MODULES_APPROVE",

    // Report-level

    // Edit Standalone Report information
    // Delete Standalone Report
    SA_REPORT_EDIT: "SA_REPORT_EDIT",
    SA_REPORT_DELETE: "SA_REPORT_DELETE",

    // Global

    // Add or Update Help Tips
    HELP_TIPS_EDIT: "HELP_TIPS_EDIT",
};

export const USER_ROLES: {
    [key: string]: UserRole;
} = {
    // Vision Insight (Internal) Users

    COMPONENT_OWNER: "COMPONENT_OWNER",
    ANALYST: "ANALYST",
    MANAGER: "MANAGER",

    // VisionLoadMAP (External) Users

    BASIC_CLIENT: "BASIC_CLIENT", // Read-only type of user
    BASIC_PRIMARY_CONTACT: "BASIC_PRIMARY_CONTACT", // Read-only type of user with ability to approve Modules
    ADVANCED_CLIENT: "ADVANCED_CLIENT", // Read-only type of user with ability to approve Modules and run "what if" scenarios
    CLIENT_ADMIN: "CLIENT_ADMIN",
    STAKEHOLDER: "STAKEHOLDER",
    // PROGRAM_STAKEHOLDER: "PROGRAM_STAKEHOLDER",
    // REGULATOR_STAKEHOLDER: "REGULATOR_STAKEHOLDER",
    // AUDITOR_STAKEHOLDER: "AUDITOR_STAKEHOLDER",
};

export const USER_RIGHTS = {
    [USER_ROLES.MANAGER]: [
        USER_ACTIONS.CLIENT_ADD,
        USER_ACTIONS.CLIENT_EDIT,
        USER_ACTIONS.PROJECT_ADD,
        USER_ACTIONS.PROJECT_DELETE,
        USER_ACTIONS.PROJECT_EDIT,
        USER_ACTIONS.MODEL_ADD,
        USER_ACTIONS.SA_REPORT_ADD,
        USER_ACTIONS.PROJECTION_DELETE_ALL,
        USER_ACTIONS.PROJECT_FILES_ADD,
        USER_ACTIONS.PROJECT_FILES_DELETE,
        USER_ACTIONS.PROJECT_STUDY_CASE_GOALS_EDIT,
        USER_ACTIONS.PROJECT_VARIABLES_EDIT,
        USER_ACTIONS.PROJECT_LABELS_EDIT,
        USER_ACTIONS.MODELS_COMBINE,
        USER_ACTIONS.MODELS_COMPARE,
        USER_ACTIONS.MARKET_PROFILE_EDIT,
        USER_ACTIONS.MODEL_EDIT,
        USER_ACTIONS.MODEL_EDIT_REQUIRED,
        USER_ACTIONS.MODEL_DUPLICATE,
        USER_ACTIONS.MODEL_DELETE,
        USER_ACTIONS.MODEL_STUDY_CASE_GOALS_EDIT,
        USER_ACTIONS.MODEL_VARIABLES_EDIT,
        USER_ACTIONS.AVOIDED_COSTS_ADD,
        USER_ACTIONS.AVOIDED_COSTS_EDIT,
        USER_ACTIONS.NOTE_ADD,
        USER_ACTIONS.CALCULATIONS_RUN,
        USER_ACTIONS.CALCULATION_WORKFLOW_ACCESS,
        USER_ACTIONS.CALCULATION_WORKFLOW_RUN,
        USER_ACTIONS.MODULES_APPROVE,
        USER_ACTIONS.SA_REPORT_EDIT,
        USER_ACTIONS.SA_REPORT_DELETE,
        USER_ACTIONS.HELP_TIPS_EDIT,
    ],
    [USER_ROLES.ANALYST]: [
        USER_ACTIONS.CLIENT_ADD,
        USER_ACTIONS.CLIENT_EDIT,
        USER_ACTIONS.PROJECT_ADD,
        USER_ACTIONS.PROJECT_DELETE,
        USER_ACTIONS.PROJECT_EDIT,
        USER_ACTIONS.MODEL_ADD,
        USER_ACTIONS.SA_REPORT_ADD,
        USER_ACTIONS.PROJECTION_DELETE_ALL,
        USER_ACTIONS.PROJECT_FILES_ADD,
        USER_ACTIONS.PROJECT_FILES_DELETE,
        USER_ACTIONS.PROJECT_STUDY_CASE_GOALS_EDIT,
        USER_ACTIONS.PROJECT_VARIABLES_EDIT,
        USER_ACTIONS.PROJECT_LABELS_EDIT,
        USER_ACTIONS.MODELS_COMBINE,
        USER_ACTIONS.MODELS_COMPARE,
        USER_ACTIONS.MARKET_PROFILE_EDIT,
        USER_ACTIONS.MODEL_EDIT,
        USER_ACTIONS.MODEL_EDIT_REQUIRED,
        USER_ACTIONS.MODEL_DUPLICATE,
        USER_ACTIONS.MODEL_DELETE,
        USER_ACTIONS.MODEL_STUDY_CASE_GOALS_EDIT,
        USER_ACTIONS.MODEL_VARIABLES_EDIT,
        USER_ACTIONS.AVOIDED_COSTS_ADD,
        USER_ACTIONS.AVOIDED_COSTS_EDIT,
        USER_ACTIONS.NOTE_ADD,
        USER_ACTIONS.CALCULATIONS_RUN,
        USER_ACTIONS.CALCULATION_WORKFLOW_ACCESS,
        USER_ACTIONS.CALCULATION_WORKFLOW_RUN,
        USER_ACTIONS.SA_REPORT_EDIT,
        USER_ACTIONS.SA_REPORT_DELETE,
        USER_ACTIONS.HELP_TIPS_EDIT,
    ],
    [USER_ROLES.BASIC_CLIENT]: [USER_ACTIONS.PROJECT_FILES_ADD, USER_ACTIONS.MODELS_COMPARE, USER_ACTIONS.NOTE_ADD],
    [USER_ROLES.BASIC_PRIMARY_CONTACT]: [
        USER_ACTIONS.PROJECT_FILES_ADD,
        USER_ACTIONS.MODELS_COMPARE,
        USER_ACTIONS.NOTE_ADD,
        USER_ACTIONS.MODULES_APPROVE,
    ],
    [USER_ROLES.ADVANCED_CLIENT]: [
        USER_ACTIONS.PROJECT_FILES_ADD,
        USER_ACTIONS.MODELS_COMBINE,
        USER_ACTIONS.MODELS_COMPARE,
        USER_ACTIONS.MODEL_EDIT,
        USER_ACTIONS.MODEL_DUPLICATE,
        USER_ACTIONS.MODEL_DELETE,
        USER_ACTIONS.MODEL_STUDY_CASE_GOALS_EDIT,
        USER_ACTIONS.MODEL_VARIABLES_EDIT,
        USER_ACTIONS.AVOIDED_COSTS_ADD,
        USER_ACTIONS.AVOIDED_COSTS_EDIT,
        USER_ACTIONS.NOTE_ADD,
        USER_ACTIONS.CALCULATION_WORKFLOW_ACCESS,
        USER_ACTIONS.CALCULATION_WORKFLOW_RUN,
        USER_ACTIONS.MODULES_APPROVE,
    ],
    [USER_ROLES.STAKEHOLDER]: [],
};
