import { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ReactModal from "react-modal";

import { heartbeat } from "store/system/actions";

import TelemetryProvider from "./AI/telemetry-provider";

import IconLoading from "components/ui/Icons/IconLoading";
import ModalRoot from "components/ui/Modal/ModalRoot";
import CookiesBanner from "components/ui/CookiesBanner";

import Maintanance from "pages/Maintanance";
import WindowManager from "pages/WindowManager";

import { useGoogleAnalytics } from "utils/useGoogleAnalytics";

import "react-toastify/dist/ReactToastify.css";
import "./style.scss";

if (process.env.NODE_ENV !== "test") {
    ReactModal.setAppElement("#root");
}

// Create global app settings object if not present.
// Can change settings from console.
if (!window.__vdsm) {
    window.__vdsm = {
        log: false,
    };
}

const App = memo(() => {
    const dispatch = useDispatch();

    const checked = useSelector((state) => state.system?.checked);
    const systemStatus = useSelector((state) => state.system?.systemStatus);
    const isLatestVersion = useSelector((state) => state.system.isLatestVersion);

    const isOnline = systemStatus === "active";
    const googleAnalyticsTrackingId = "";

    useEffect(() => {
        if (!isLatestVersion) {
            toast.warn("New version of Vision Insight is available! Refresh browser tab to get latest version.", {
                className: "new-app-version-info-toast",
                autoClose: false,
                delay: 60000, // delay notification for a minute to make sure deployment is fully completed when showing it.
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }, [isLatestVersion]);

    useEffect(() => {
        dispatch(heartbeat());
    }, [dispatch]);

    useGoogleAnalytics(googleAnalyticsTrackingId);

    return (
        <TelemetryProvider>
            <div className="app no-scroll layout-full-screen">
                {isOnline && checked && (
                    <>
                        <WindowManager />
                        <CookiesBanner />
                    </>
                )}
                {!isOnline && checked && <Maintanance />}
                {!checked && (
                    <div className="flex-column align-center justify-center fill-width">
                        <IconLoading />
                    </div>
                )}
                <ModalRoot />
                <ToastContainer theme="colored" autoClose={5000} position="bottom-left" />
            </div>
        </TelemetryProvider>
    );
});

export default App;
