import { MODEL_DATA_IMPORT_STARTED, MODEL_DATA_IMPORT_COMPLETE, MODEL_DATA_IMPORT_ACK } from "store/actionTypes";

const initialState = {};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_DATA_IMPORT_STARTED:
            {
                const { id, idProject, idInputLog } = action;

                state = {
                    ...state,
                    [`${idProject}-${idInputLog}`]: id,
                };
            }

            break;

        case MODEL_DATA_IMPORT_COMPLETE:
            {
                const { idProject, idInputLog } = action.status;

                state = {
                    ...state,
                    [`${idProject}-${idInputLog}`]: action.status,
                };
            }

            break;

        case MODEL_DATA_IMPORT_ACK:
            {
                const { idProject, idInputLog } = action;
                const key = `${idProject}-${idInputLog}`;

                state = {
                    ...state,
                    [key]: {
                        ...state?.[key],
                        hide: true,
                    },
                };
            }

            break;

        default:
            break;
    }

    return state;
};
