import { useState, useCallback } from "react";
import cn from "classnames";

import IconWrap from "components/ui/Icons";

import { usePopper } from "utils/usePopper";

import { TooltipProps } from "./types";

import "./style.scss";

const Tooltip = (props: TooltipProps) => {
    const [referenceElement, setReferenceElement] = useState<Element | null | undefined>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null | undefined>(null);
    const [arrowElement, setArrowElement] = useState<HTMLElement | null | undefined>(null);

    const placement = props.placement || "bottom-start";

    const { styles, attributes, update } = usePopper({
        referenceElement,
        popperElement,
        arrowElement,
        popperType: "tooltip",
        tooltipPlacement: placement,
    });

    const onMouseEnter = useCallback(() => {
        update?.(); // update tooltip position on hover
    }, [update]);

    return (
        <div className="tooltip">
            {(props.icon || props.customIcon) && (
                <div
                    ref={setReferenceElement}
                    className={cn("tooltip__icon", props.className)}
                    aria-describedby="tooltip"
                    onMouseEnter={onMouseEnter}
                >
                    {props.icon && (
                        <IconWrap icon={props.icon} clickable={props.iconClickable} error={props.iconError} onClick={props.onIconClick} />
                    )}
                    {props.customIcon && <>{props.customIcon}</>}
                </div>
            )}
            <div
                ref={setPopperElement}
                className={cn("tooltip__container", props.containerClassName)}
                style={styles.popper}
                role="tooltip"
                {...attributes.popper}
            >
                {props.children}
                <div ref={setArrowElement} className="tooltip-arrow" style={styles.arrow} />
            </div>
        </div>
    );
};

export default Tooltip;
