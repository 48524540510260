import { isEmpty } from "lodash";
import { memo, useState } from "react";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Input from "components/ui/Input";

const NameResult: React.FC<{
    onSave: (scenarioName: string) => void;
    onCancel?: () => void;
}> = memo(({ onSave, onCancel }) => {
    const [scenarioName, setScenarioName] = useState("");

    const onSaveClick = () => {
        onSave(scenarioName);

        onCancel?.();
    };

    return (
        <>
            <div className="flex-column modal-padding">
                <Input
                    label="Scenario name"
                    placeholder="Type scenario name"
                    required
                    value={scenarioName}
                    onChange={(event) => setScenarioName(event.target.value)}
                />
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button variant="primary" isDisabled={isEmpty(scenarioName)} padding="md" onClick={onSaveClick}>
                    Save
                </Button>
                <Button variant="secondary" padding="md" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default NameResult;
