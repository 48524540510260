import ClassNames from "classnames";

import Input from "components/ui/Input";
import Label from "components/ui/Label";

import { CheckboxProps } from "./types";

import "./style.scss";

const Checkbox = (props: CheckboxProps) => {
    const classNames = ClassNames("flex-row align-center checkbox-input-container", props.className, {
        disabled: props.disabled,
        error: props.error,
    });

    return (
        <div className={classNames}>
            <Input
                inputRef={props.inputRef}
                data-testid={props.dataTestId}
                type="checkbox"
                id={props.id}
                name={props.name}
                disabled={props.disabled}
                readOnly={props.readOnly}
                error={props.error}
                defaultChecked={props.defaultChecked}
                checked={props.checked}
                onChange={props.onChange}
            />
            {props.label && (
                <Label htmlFor={props.id} disabled={props.disabled} readOnly={props.readOnly} error={props.error}>
                    {props.label}
                </Label>
            )}
        </div>
    );
};

export default Checkbox;
