import { memo } from "react";

import Button from "components/ui/ButtonNew";

import { Card } from "components/ui/Cards";

import { AddModuleCardProps } from "./types";

const AddModuleCard = memo(({ children, disabled, onClick }: AddModuleCardProps) => {
    return (
        <Card contentClassName="flex-column align-center justify-center" addCard onClick={onClick}>
            <Button variant="tertiary" padding="md" isDisabled={disabled} iconLeft="ui-plus_in_circle__add__create_b_s" iconSize="sm">
                {children}
            </Button>
        </Card>
    );
});

export default AddModuleCard;
