///
/// Active Views let's the user know if other users
/// also have this view opened on their end.
///
/// Custom Report Active Views actions
/// resourceName: activeViewsCustomReportResourceName
///
import { chain } from "lodash";
import { batch, useSelector } from "react-redux";

import { SET_PROJECTIONS_VIEW_ID } from "store/actionTypes";
import { activeViewsCustomReportResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

export const useActiveViewsCustomReportList = ({ idProject, idReport }: ActiveViewsCustomReportParams) => {
    // @ts-ignore
    const currentUser = useSelector((state) => state.vdsmUser);

    return useResource({
        resourceName: activeViewsCustomReportResourceName,
        key: `${activeViewsCustomReportResourceName}-${idProject}-${idReport}`,
        path: { idReport },
        transform: (data) =>
            chain(data?.model)
                .map((m) => m.userNumber)
                .uniq()
                .without(currentUser?.userNumber)
                .value(),
    });
};

export const createActiveViewsCustomReport =
    ({ viewIndex, idProject, idReport }: CreateActiveViewsCustomReportParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            createResource({
                resourceName: activeViewsCustomReportResourceName,
                path: { idReport },
                onSuccess: (response) => {
                    batch(() => {
                        dispatch({
                            type: SET_PROJECTIONS_VIEW_ID,
                            selection: {
                                viewIndex,
                                idProject,
                                viewId: response.data,
                            },
                        });

                        dispatch(clearActiveViewsCustomReport({ idProject, idReport }));
                    });
                },
            })
        );

export const clearActiveViewsCustomReport =
    ({ idProject, idReport }: ActiveViewsCustomReportParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: activeViewsCustomReportResourceName,
                key: `${activeViewsCustomReportResourceName}-${idProject}-${idReport}`,
                broadcast: true,
            })
        );

export const clearActiveViewsCustomReportAll =
    () =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: activeViewsCustomReportResourceName,
                key: new RegExp(`^${activeViewsCustomReportResourceName}.$`),
            })
        );

export const deleteActiveViewsCustomReport =
    ({ idProject, idReport, previousViewId }: DeleteActiveViewsCustomReportParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            deleteResource({
                resourceName: activeViewsCustomReportResourceName,
                path: { idReport },
                body: { previousViewId },
                onSuccess: () => {
                    dispatch(clearActiveViewsCustomReport({ idProject, idReport }));
                },
            })
        );

interface ActiveViewsCustomReportParams {
    /**
     * ID of Project.
     */
    idProject: number;

    /**
     * ID of Custom Report.
     */
    idReport: number;
}

interface CreateActiveViewsCustomReportParams extends ActiveViewsCustomReportParams {
    /**
     * View index:
     *  - 0: main view (or left view)
     *  - 1: right view
     */
    viewIndex: number;
}

interface DeleteActiveViewsCustomReportParams extends ActiveViewsCustomReportParams {
    /**
     * ID of previous View.
     *
     * TODO: Add type here
     */
    previousViewId: any;
}
