import cn from "classnames";

import Icon from "components/ui/IconNew";
import Radio from "components/ui/Input/Radio";
import Label from "components/ui/Label";
import { Card } from "components/ui/Cards";

import { RadioProps } from "components/ui/Input/Radio/types";

import "./style.scss";

const RadioCard = (props: RadioProps) => {
    return (
        <Label className={cn("radio-card", { "radio-card__checked": props.checked })} htmlFor={props.id}>
            <Card
                contentClassName="flex-row align-center justify-space-between"
                selected={props.checked}
                readOnly={props.readOnly}
                cardWithInput
            >
                {props.label && <div>{props.label}</div>}
                {!props.readOnly ? (
                    <Radio id={props.id} name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} />
                ) : (
                    <div className="flex-row align-center">
                        <span>{props.checked ? "On" : "Off"}</span>
                        <Icon
                            className={cn("radio-card__read-only-icon", { checked: props.checked })}
                            icon={props.checked ? "ui-checked__check_circle_b_f" : "ui-block__not_interested__prohibited_b_s"}
                            size="md"
                        />
                    </div>
                )}
            </Card>
        </Label>
    );
};

export default RadioCard;
