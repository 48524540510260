import { v1 as uuidv1 } from "uuid";
import { isEmpty } from "lodash";
import { batch } from "react-redux";

import { modelInputNotesResourceName, modelInputsV2ResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource, optimisticUpdateItem } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { clearMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";

import { getResourceState } from "store/utils";

import { getVdsmUser } from "utils/user";

import {
    ModelInputNote,
    ModelInputNoteResponseModel,
    ModelInputNoteProps,
    CreateModelInputNoteProps,
    UpdateModelInputNoteProps,
    DeleteModelInputNoteProps,
    GetModelInputNoteResourceParams,
} from "./types";

const getResourceParams = ({
    idInputLog,
    idDataImportLog,
    idInputNote,
    description,
    isPublic,
    updateItems,
    onComplete,
    dispatch,
}: GetModelInputNoteResourceParams) => ({
    resourceName: modelInputNotesResourceName,
    key: `${modelInputNotesResourceName}-${idDataImportLog}`,
    query: {
        idDataImportLog,
    },
    body: {
        idInputNote,
        title: "",
        description,
        isPublic,
    },
    updateFromResponse: false,
    showSuccessNotification: false,
    onComplete: () => {
        onComplete && onComplete();
        dispatch(clearModelInputNotesList({ idDataImportLog }));
        dispatch(clearMeasureInputs({ idInputLog }));
    },
    optimisticUpdate: updateItems?.length
        ? {
              value: {
                  model: updateItems,
              },
          }
        : undefined,
});

export const useModelInputNotes = ({ idDataImportLog }: ModelInputNoteProps) =>
    useResource({
        resourceName: modelInputNotesResourceName,
        key: `${modelInputNotesResourceName}-${idDataImportLog}`,
        query: { dataImportLogId: idDataImportLog },
        transform: (data: ModelInputNoteResponseModel) => data?.model,
    });

export const clearModelInputNotesList =
    ({ idDataImportLog }: ModelInputNoteProps) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: modelInputNotesResourceName,
                key: `${modelInputNotesResourceName}-${idDataImportLog}`,
                broadcast: true,
            })
        );

export const createModelInputNote =
    ({ idInputLog, idDataImportLog, description, isPublic = false, onComplete }: CreateModelInputNoteProps) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const modelInputNotes = getResourceState<ModelInputNote>(state, modelInputNotesResourceName, { idDataImportLog });
        const modelInputLogItems: any = getResourceState<any>(state, modelInputsV2ResourceName, { idInputLog });

        const { firstName, lastName } = getVdsmUser();

        const lastUpdateDate = new Date().toISOString().substring(0, 23); // exclude UTC timezone

        const updateLogItems: any = {};

        Object.keys(modelInputLogItems).forEach((table) => {
            updateLogItems[table] = [];

            modelInputLogItems[table].forEach((item: any) => {
                let newItem = item;

                if (item.idDataImportLog === idDataImportLog) {
                    newItem = { ...item, loadingNote: true };
                }

                updateLogItems[table].push(newItem);
            });
        });

        const updateNoteItems = [...modelInputNotes];

        updateNoteItems.unshift({
            // @ts-ignore - specific case only used for optimistic update
            idInputNote: uuidv1(),
            idDataImportLog,
            description,
            isPublic,
            firstName,
            lastName,
            lastUpdateDate,
        });

        batch(() => {
            if (!isEmpty(updateLogItems)) {
                dispatch(
                    optimisticUpdateItem({
                        resourceName: modelInputsV2ResourceName,
                        key: `${modelInputsV2ResourceName}-${idInputLog}`,
                        value: updateLogItems,
                    })
                );
            }

            dispatch(
                createResource(
                    getResourceParams({
                        idInputLog,
                        idDataImportLog,
                        description,
                        isPublic,
                        updateItems: updateNoteItems,
                        onComplete,
                        dispatch,
                    })
                )
            );
        });
    };

export const updateModelInputNote =
    ({ idInputLog, idDataImportLog, idInputNote, description, onComplete }: UpdateModelInputNoteProps) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const modelInputNotes = getResourceState<ModelInputNote>(state, modelInputNotesResourceName, { idDataImportLog });

        const updateItems = modelInputNotes.map((u) =>
            u.idInputNote === idInputNote
                ? {
                      ...u,
                      description: description || null,
                  }
                : u
        );

        dispatch(
            updateResource(
                getResourceParams({
                    idInputLog,
                    idDataImportLog,
                    idInputNote,
                    description,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const deleteModelInputNote =
    ({ idInputLog, idDataImportLog, idInputNote, onComplete }: DeleteModelInputNoteProps) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const modelInputNotes = getResourceState<ModelInputNote>(state, modelInputNotesResourceName, { idDataImportLog });
        const modelInputLogItems: any = getResourceState<any>(state, modelInputsV2ResourceName, { idInputLog });

        const updateLogItems: any = {};

        Object.keys(modelInputLogItems).forEach((table) => {
            updateLogItems[table] = [];

            modelInputLogItems[table].forEach((item: any) => {
                let newItem = item;

                if (item.idDataImportLog === idDataImportLog) {
                    newItem = { ...item, loadingNote: true };
                }

                updateLogItems[table].push(newItem);
            });
        });

        const updateNoteItems = modelInputNotes.filter((u) => u.idInputNote !== idInputNote);

        batch(() => {
            if (!isEmpty(updateLogItems)) {
                dispatch(
                    optimisticUpdateItem({
                        resourceName: modelInputsV2ResourceName,
                        key: `${modelInputsV2ResourceName}-${idInputLog}`,
                        value: updateLogItems,
                    })
                );
            }

            dispatch(
                deleteResource({
                    resourceName: modelInputNotesResourceName,
                    key: `${modelInputNotesResourceName}-${idDataImportLog}`,
                    path: { idInputNote },
                    onComplete: () => {
                        onComplete && onComplete();
                        dispatch(clearModelInputNotesList({ idDataImportLog }));
                        dispatch(clearMeasureInputs({ idInputLog }));
                    },
                    optimisticUpdate: {
                        value: {
                            model: updateNoteItems,
                        },
                    },
                })
            );
        });
    };
