import { toast } from "react-toastify";

import { updateStandaloneReport } from "store/resources/actions/standaloneReport/standaloneReportActions";

import { isDevEnvironment } from "utils/constants";

export const uploadHTMLReport =
    ({ idProject, idReport, file, name, reportType, originalFilename, action, onStart, onComplete }: UploadHTMLReportParams) =>
    // @ts-ignore
    (dispatch) => {
        let formData = new FormData();

        formData.append("idProject", idProject.toString());
        formData.append("name", name);
        formData.append("reportType", reportType);
        formData.append("active", "true");
        formData.append("file", file);
        formData.append("originalFilename", originalFilename);
        idReport === 0 && formData.append("idReport", idReport.toString());

        const onUploadSuccess = (action: any) => {
            const { outputFileName, responseStatus, responseMessage } = action?.data;

            if (isDevEnvironment && responseStatus === "success\nsuccess") {
                console.log(action);
            }

            if (responseStatus === "failure\nfailure") {
                toast.error(responseMessage.split("\n", 2)[0]);

                return;
            }

            const newIdReport = parseInt(outputFileName.substring(0, outputFileName.indexOf("_"))); // output file structure: "id_filename.extension"

            onComplete?.(newIdReport);
        };

        const onUploadError = () => {
            onComplete?.();
        };

        dispatch(
            updateStandaloneReport({ idProject, idReport, formData, action, onStart, onSuccess: onUploadSuccess, onError: onUploadError })
        );
    };

interface UploadHTMLReportParams {
    /**
     * ID of Project.
     */
    idProject: number;

    /**
     * ID of Standalone Report.
     *
     * 0 - creating a new Standalone Report;
     *
     * number - updating an already existing Standalone Report.
     */
    idReport: number;

    /**
     * HTML report.
     */
    file: File;

    /**
     * Name of the Standalone Report.
     */
    name: string;

    /**
     * Type of the Standalone Report.
     *
     *  - HTML Report
     *  - PBI Report
     */
    reportType: string;

    /**
     * HTML report original file name.
     */
    originalFilename: string;

    /**
     * Name of the action.
     */
    action?: string;

    /**
     * Function to run when upload has started.
     */
    onStart?: () => void;

    /**
     * Function to run when upload is complete.
     */
    onComplete?: (idReport?: number) => void;
}
