import { differenceWith, isEqual, isNil } from "lodash";
import { useDispatch } from "react-redux";

import { projectInputCombinationsCheckResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { getResourcePromise } from "store/resources/actions/getResource";
import { refreshPowerBIDataset } from "store/powerBI/refreshAction/actions";

import { checkModelInCombination } from "store/resources/actions/projectInput/projectInputCombinationActions";

import { getResourceState } from "store/utils";

import { useModelDataImportStatus } from "./useModelDataImportStatus";
import { useRunCalculationStatus } from "./useRunCalculationStatus";

import { singleModelDatasetId, combinedModelDatasetId, measureLevelDatasetId } from "utils/constants";

import { IndexSignature } from "types/types";

const STATUS_CHECK_TIMEOUT = 5000;

export const checkProcessStatus =
    (statusProps: ProcessStatusProps) =>
    // @ts-ignore
    async (dispatch, getState) => {
        statusProps.checking = true;

        const state = getState();
        const currentStatuses = state.resources[statusProps.resourceName]?.itemsById[statusProps.resourceName]?.data?.model || {};

        try {
            const result: any = await getResourcePromise({
                resourceName: statusProps.resourceName,
                key: statusProps.key || statusProps.resourceName,
            });

            const statuses = result?.model;

            // Get list of changed statuses
            const changedStatuses = differenceWith(
                statuses,
                currentStatuses,
                (st: any, currSt: any) => st.id === currSt.id && st.status === currSt.status && isEqual(st.steps, currSt.steps)
            );

            if (changedStatuses && changedStatuses.length > 0) {
                // Stores a list of changed Projects
                let changedProjects: number[] = [];

                // Stores a list of changed items
                //
                // Item can be imported data, calculation, etc.
                let changedItems: number[] = [];

                // For each changed item get its ID and
                // Project's ID, and clear corresponding
                // resources if they are not cleared already
                changedStatuses.forEach((status) => {
                    const { id, idProject } = status;

                    if (!changedProjects.includes(idProject)) {
                        changedProjects.push(idProject);

                        statusProps.onProjectStatusChange && dispatch(statusProps.onProjectStatusChange(idProject));
                    }

                    const idSelector = statusProps.idSelector(status);

                    if (statusProps.process === "run-calculation") {
                        // Check if calculating Model is part of a combination
                        dispatch(checkModelInCombination({ idProject, idInputLog: idSelector }));
                    }

                    if (!changedItems.includes(idSelector)) {
                        changedItems.push(idSelector);

                        statusProps.onItemStatusChange && dispatch(statusProps.onItemStatusChange(status));

                        // Process has been marked as finished
                        // (that includes with an error or process has been manually terminated)
                        if (status.finished) {
                            const isModuleRun = id.includes("00000000-0000-0000-0000-"); // Run Module calculation ID consists of these zeros at the start

                            statusProps.onItemFinishBefore && dispatch(statusProps.onItemFinishBefore(status));

                            if (statusProps.process === "run-calculation") {
                                // Process finished with an error
                                if (status.statusMessage) {
                                    statusProps.onItemFailed && dispatch(statusProps.onItemFailed(status));
                                }
                                // Process has been terminated (legacy calculations)
                                else if (!isModuleRun && Object.values(status.steps).some((st) => st !== "DONE")) {
                                    statusProps.onItemTerminated && dispatch(statusProps.onItemTerminated(status));
                                }
                                // Process has been terminated (Run Module)
                                else if (isModuleRun && status.status !== "IN PROGRESS" && status.status !== "DONE") {
                                    statusProps.onItemTerminated && dispatch(statusProps.onItemTerminated(status));
                                }
                                // Process finished successfully
                                else {
                                    statusProps.doReportRefresh = true;

                                    statusProps.onItemFinishAfter && dispatch(statusProps.onItemFinishAfter(status));
                                }
                            } else {
                                statusProps.onItemFinishAfter && dispatch(statusProps.onItemFinishAfter(status));
                            }
                        }
                    }
                });
            }

            const process = statuses && statuses.length > 0 && statuses.some((st: any) => !st.finished);

            if (process) {
                if (statusProps.timeoutHandlers) {
                    clearTimeout(statusProps.timeoutHandlers);
                }

                statusProps.timeoutHandlers = window.setTimeout(() => {
                    dispatch(checkProcessStatus(statusProps));
                }, STATUS_CHECK_TIMEOUT);
            } else {
                statusProps.checking = false;
                statusProps.timeoutHandlers = null;

                if (statusProps.doReportRefresh) {
                    statusProps.doReportRefresh = false;
                    let additionalReportRefresh = false;

                    for (const status of statuses) {
                        // If some of the calculating Models were part of a combination,
                        // do additional report refresh as well
                        if (
                            getResourceState(state, projectInputCombinationsCheckResourceName, {
                                idProject: status.idProject,
                                idInputLog: status.idInputLog,
                            }).some((isPartOfCombination) => isPartOfCombination === true)
                        ) {
                            additionalReportRefresh = true;

                            break;
                        }
                    }

                    // Default report refresh - Single Model Report
                    if (singleModelDatasetId) {
                        dispatch(refreshPowerBIDataset({ datasetId: singleModelDatasetId }));
                    }

                    // Additional report refresh - Combined Model Report & Measure-level Report
                    if (additionalReportRefresh) {
                        if (combinedModelDatasetId) {
                            dispatch(refreshPowerBIDataset({ datasetId: combinedModelDatasetId }));
                        }

                        if (measureLevelDatasetId) {
                            dispatch(refreshPowerBIDataset({ datasetId: measureLevelDatasetId }));
                        }
                    }
                }

                statusProps.onProcessEnd && dispatch(statusProps.onProcessEnd(statuses));
            }
        } catch (error) {
            delete statusProps.checking;

            statusProps.timeoutHandlers = null;
        }
    };

/**
 * Returns:
 *  - undefined     - data not loaded
 *  - false         - no running processes at this moment
 *  - Object        - when Model ID is provided, returns the most recent status
 *  - Collection    - when Project ID is provided, returns collection of the most recent statuses per Model ID
 *
 * TODO: Improve this hook so that idProjectUpload can also be used
 *
 * VI-1544
 */
export const useProcessStatus = ({ statusProps, idProject, idInputLog }: UseProcessStatusParams) => {
    const dispatch = useDispatch();

    const [statuses] = useResource({
        resourceName: statusProps.resourceName,
        key: statusProps.resourceName,
        transform: (data: any) => data?.model,
    });

    if (!statuses) {
        return;
    }
    if (!statuses.length) {
        return false;
    }
    if (statuses.some((st: any) => !st.finished)) {
        if (!statusProps.timeoutHandlers && !statusProps.checking) {
            dispatch(checkProcessStatus(statusProps));
        }
    }

    const unfinishedStatuses = statuses.filter((st: any) => (st.idProject === idProject || st.idInputLog === idInputLog) && !st.finished);

    if (!unfinishedStatuses.length) {
        return false;
    }

    if (isNil(idInputLog)) {
        const result = unfinishedStatuses.reduce((st: any, currSt: any) => {
            if (!st[currSt.idInputLog] || currSt.started > st[currSt.idInputLog]?.started) {
                st[currSt.idInputLog] = currSt;
            }

            return st;
        }, {});

        return result;
    } else {
        const result = unfinishedStatuses.reduce((st: any, currSt: any) => {
            return st.started > currSt.started ? st : currSt;
        });

        return result;
    }
};

export const useProcessesInProgress = ({ idProject, idInputLog }: UseProcessParams): IndexSignature<any> => {
    const modelDataImportInProgress = useModelDataImportStatus({ idProject, idInputLog });
    const runCalculationsInProgress = useRunCalculationStatus({ idProject, idInputLog });

    return {
        modelDataImport: modelDataImportInProgress,
        runCalculations: runCalculationsInProgress,
    };
};

export const useIsInProgress = ({ idProject, idInputLog }: UseProcessParams) => {
    const processesInProgress = useProcessesInProgress({ idProject, idInputLog });

    return Object.keys(processesInProgress).some((p) => processesInProgress[p] || isNil(processesInProgress[p]));
};

export interface ProcessStatusProps {
    /**
     * Name of the resource.
     */
    resourceName: string;

    /**
     * Name of the resource property.
     */
    key?: string;

    /**
     * ID of Model, Market Profile, etc.
     */
    idSelector: (arg: any) => number;

    /**
     * Process type.
     */
    process: "data-import" | "run-calculation";

    timeoutHandlers: number | null;

    /**
     * Check status.
     */
    checking?: boolean;

    /**
     * Need of report refresh.
     *
     * Only used for calculations.
     */
    doReportRefresh?: boolean;

    /**
     * Function to run when Project status has changed.
     */
    onProjectStatusChange?: (idProject: number) => void;

    /**
     * Function to run when item status has changed.
     */
    onItemStatusChange?: (status: any) => void;

    /**
     * Function to run when item action has finished successfully.
     *
     * Runs before other onItemFinish function.
     */
    onItemFinishBefore?: (status: any) => void;

    /**
     * Function to run when item action has failed.
     */
    onItemFailed?: (status: any) => void;

    /**
     * Function to run when item action has been terminated.
     */
    onItemTerminated?: (status: any) => void;

    /**
     * Function to run when item action has finished successfully.
     *
     * Runs after other onItemFinish function.
     */
    onItemFinishAfter?: (status: any) => void;

    /**
     * Function to run when all processes have ended.
     */
    onProcessEnd?: (statuses?: any) => void;
}

export interface UseProcessParams {
    /**
     * ID of Project.
     */
    idProject?: number;

    /**
     * ID of Model.
     */
    idInputLog?: number;

    /**
     * ID of Component Model.
     */
    idModel?: number;

    /**
     * ID of Market Profile data.
     */
    idProjectUpload?: number;
}

interface UseProcessStatusParams extends UseProcessParams {
    /**
     * Necessary status properties to track process.
     */
    statusProps: ProcessStatusProps;
}
