import {
    POWERBI_DATASET_REFRESH_STATUS_ERROR,
    POWERBI_DATASET_REFRESH_STATUS_RECEIVED,
    POWERBI_DATASET_REFRESH_STATUS_REQUESTED,
} from "store/actionTypes";

import { Action, State } from "store/powerBI/types";

const initialState: State = {};

export const reducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case POWERBI_DATASET_REFRESH_STATUS_REQUESTED:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    error: undefined,
                    isReading: true,
                    metadata: action.payload?.metadata,
                },
            };

            break;

        case POWERBI_DATASET_REFRESH_STATUS_RECEIVED:
            state = {
                ...state,
                [action.id]: {
                    ...action.payload,
                    isReading: false,
                    metadata: state[action.id].metadata,
                },
            };

            break;

        case POWERBI_DATASET_REFRESH_STATUS_ERROR:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    ...action.payload,
                    isReading: false,
                },
            };

            break;

        default:
            break;
    }

    return state;
};
