import ClassNames from "classnames";

import Label from "components/ui/Label";
import StatusMsg from "components/ui/StatusMsg";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";

import { TextAreaProps } from "./types";

import "./style.scss";

const TextArea = (props: TextAreaProps) => {
    const classNames = ClassNames("fill-width textarea-container", props.className, {
        "flex-column fill-height": props.fillHeight,
    });

    const textAreaClassNames = ClassNames("textarea-field", {
        focus: props.focus,
        "read-only": props.readOnly,
        error: props.error,
        "fill-height": props.fillHeight,
    });

    return (
        <div className={classNames}>
            {props.label && (
                <Label required={props.required} optional={props.optional}>
                    {props.label}
                </Label>
            )}
            <textarea
                data-testid={props.dataTestId}
                className={textAreaClassNames}
                required={props.required}
                disabled={props.disabled}
                readOnly={props.readOnly}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
            />
            {props.msgText && (props.error ? <ErrorMsg message={props.msgText} /> : <StatusMsg message={props.msgText} />)}
        </div>
    );
};

export default TextArea;
