import { useInputValidations } from "store/resources/actions/projectInput/projectInputValidationsActions";

import { actionStatus } from "utils/constants";

const groupValidations = (inputValidations) =>
    Object.values(
        inputValidations.reduce((acc, iv, index) => {
            if (!acc[iv.idValidation]) {
                acc = {
                    ...acc,
                    [iv.idValidation]: {
                        ...iv,
                        tables: [iv.tableName],
                    },
                };
            } else {
                acc = {
                    ...acc,
                    [iv.idValidation]: {
                        ...iv,
                        tables: [...acc[iv.idValidation].tables, iv.tableName],
                    },
                };
            }
            return acc;
        }, {})
    );

export const useValidationErrors = (idInputLog, group = false) => {
    let [inputValidations] = useInputValidations({ idInputLog });

    const hasValidationsErrors = inputValidations && inputValidations.length > 0;
    let inputCriticalValidations = inputValidations?.filter((v) => v.msgClassification === actionStatus.ERROR);
    const hasCriticalValidations = inputCriticalValidations && inputCriticalValidations.length > 0;

    if (group) {
        inputValidations = groupValidations(inputValidations);
        inputCriticalValidations = groupValidations(inputCriticalValidations);
    }
    return { inputValidations, hasValidationsErrors, inputCriticalValidations, hasCriticalValidations };
};
