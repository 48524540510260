import { isFunction } from "lodash";
import { v1 as uuidv1 } from "uuid";

export const ERROR_EMAIL_INVALID = "E-mail address must be a valid e-mail address";

export const copyToClipboard = (text: string, callback?: () => void) => {
    var textField = document.createElement("textarea");

    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    callback && callback();
};

export const decodeHtml = (html: string) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};

export const createId = () => {
    return uuidv1().toUpperCase().split("-").join("");
};

/**
 * Removes whitespace from string using RegExr
 *
 * @param value - string containing whitespaces
 * @returns string without whitespaces
 */
export const removeWhitespace = (value: string) => {
    return value.replace(/\s+/g, "");
};

/**
 * Check if string is null or contains only whitespaces.
 */
export const isNullOrWhitespace = (value: string) => {
    return String(value ?? "").trim().length === 0;
};

/**
 * Checks whether a string matches an email pattern.
 */
export const isEmailAddress = (value: string) => {
    const emailEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const emailRegEx = new RegExp(emailEx);

    return emailRegEx.test(value);
};

export const formatFullName = (firstName: string, lastName: string, companyName = "") => {
    if (!firstName && !lastName) {
        return companyName;
    }

    if (!firstName || !lastName) {
        return `${firstName || ""}${lastName || ""}`.trim();
    }

    return `${firstName} ${lastName}`.trim();
};

// Taken from: https://stackoverflow.com/a/18650828
export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) {
        return "0 Bytes";
    }

    const k = 1024;
    const d = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

    const e = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, e)).toFixed(d))} ${sizes[e]}`;
};

// TODO: Check
export const invokeIfFunction = (value: any, ...args: any[]) => (isFunction(value) ? value(...args) : value);
