import { memo, useCallback } from "react";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";

import { CustomActionsProps } from "./types";

const CustomActions = memo(({ text, actions, onCancel }: CustomActionsProps) => {
    const onButtonClick = useCallback(
        (event, onClick) => {
            onCancel?.();
            onClick?.(event);
        },
        [onCancel]
    );

    return (
        <>
            <div className="flex-column modal-padding">
                <span>{text}</span>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                {actions.map((action, index) => {
                    const { children, disabled, icon, loading, primary, danger, onClick, ...rest } = action;

                    return (
                        <Button
                            {...rest}
                            key={`button-${index}`}
                            variant={primary ? "primary" : "secondary"}
                            color={danger ? "system-critical" : "brand"}
                            iconLeft={icon}
                            iconSize="sm"
                            padding="lg"
                            isDisabled={disabled}
                            isLoading={loading}
                            onClick={(event) => onButtonClick(event, onClick)}
                        >
                            {children}
                        </Button>
                    );
                })}
            </IdsButtonGroup>
        </>
    );
});

export default CustomActions;
