import { openModalDialog, openModalDialogCustomActions } from "layouts/Modal/utils";

import AddModule from "./AddModule";
import AddPotentialModule from "./AddPotentialModule";
import ClientReview from "./ClientReview";
import ClientApprovement from "./ClientApprovement";
import NameResult from "./NameResult";

import {
    AddPotentialModuleModalProps,
    AddModuleModalParams,
    ClientReviewModalProps,
    ClientApprovementModalProps,
    SetInClientReviewErrorParams,
} from "./types";

export const openModalDialogAddModule =
    ({ idProject, idModel, idInputLog, title, type, modules, goBack }: AddModuleModalParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title,
                component: (
                    <AddModule
                        idProject={idProject}
                        idModel={idModel}
                        idInputLog={idInputLog}
                        type={type}
                        modules={modules}
                        goBack={goBack}
                    />
                ),
            })
        );
    };

export const openModalDialogAddPotentialModule =
    ({ idProject, idModel, idInputLog, modules }: AddPotentialModuleModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Add Potential Module",
                component: <AddPotentialModule idProject={idProject} idModel={idModel} idInputLog={idInputLog} modules={modules} />,
            })
        );
    };

export const openModalDialogClientReviewModule =
    ({ moduleName, onChange }: ClientReviewModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Publish Module for Client Review",
                component: <ClientReview moduleName={moduleName} onChange={onChange} />,
            })
        );
    };

export const openModalDialogClientApprovementModule =
    ({ idProject, idInputLog, idModel }: ClientApprovementModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Approve Modules",
                component: <ClientApprovement idProject={idProject} idInputLog={idInputLog} idModel={idModel} />,
            })
        );
    };

export const openModalDialogSetInClientReviewError =
    ({ modelName, moduleName }: SetInClientReviewErrorParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialogCustomActions({
                title: "Model not set to In Progress",
                modalIcon: "circle-exclamation-filled",
                error: true,
                text: (
                    <div>
                        To be able to set the module <strong>'{moduleName}'</strong> to 'In Client Review', please ensure that the model{" "}
                        <strong>'{modelName}'</strong> is set to 'In Progress'.
                    </div>
                ),
                actions: [
                    {
                        children: "Close",
                    },
                ],
            })
        );
    };

export const openModalDialogNameResult =
    ({ onSave }) =>
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Name Module Result",
                component: <NameResult onSave={onSave} />,
            })
        );
    };
