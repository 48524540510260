import * as React from "react";
import cn from "classnames";

import "./style.scss";

const SideNavBody = (props: SideNavBodyProps) => {
    const classNames = cn("flex-column flex-one-in-column sidenav-body", props.className, {
        "no-padding": props.noPadding,
    });

    return <div className={classNames}>{props.children}</div>;
};

interface SideNavBodyProps {
    className?: string;
    noPadding?: boolean;
    children?: React.ReactNode;
}

export default SideNavBody;
