import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";

import { sideNavOpen, sideNavClose } from "store/sideNav/actions";

import { WindowContext } from "pages/WindowManager/Windows/WindowsContainer/Window";

import { sideNavigationPosition, sideNavigationSize, getViewSideNavigationKey } from "utils/sideNav";

import { UseSidePanelHandlersParams } from "./types";

/**
 * Hook that returns handlers to use for SidePanel.
 */
export const useSidePanelHandlers = ({ className, key, size, disableClose }: UseSidePanelHandlersParams) => {
    const dispatch = useDispatch();

    // Get SidePanel key
    // @ts-ignore
    const { viewName } = useContext(WindowContext);
    const sideNavKey = key || getViewSideNavigationKey({ name: viewName, position: sideNavigationPosition.right });

    // Handler for opening SidePanel
    const handleOpenSidePanel = useCallback(
        (component: any, params: any = {}) => {
            if (component) {
                dispatch(
                    sideNavOpen({
                        id: sideNavKey,
                        props: {
                            className: params.className || className,
                            backdrop: true,
                            size: params.size || size || sideNavigationSize.small,
                            disableClose: params.disableClose || disableClose || false,
                            children: component,
                        },
                    })
                );
            }
        },
        [className, size, disableClose, sideNavKey, dispatch]
    );

    // Handler for closing SidePanel
    const handleCloseSidePanel = useCallback(() => {
        dispatch(sideNavClose({ id: sideNavKey }));
    }, [sideNavKey, dispatch]);

    return {
        handleOpenSidePanel,
        handleCloseSidePanel,
    };
};
