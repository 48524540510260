import { memo } from "react";

import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import InfoPanel from "components/ui/InfoPanel";

import { CalculationErrorPanelProps } from "./types";

const CalculationErrorPanel = memo(({ statusMessage }: CalculationErrorPanelProps) => {
    return (
        <div className="calculation-error-panel">
            <InfoPanel error>
                <div className="flex-column">
                    <ErrorMsg
                        icon="alert-error-empty"
                        iconWithLabelMedium
                        message="Calculations done with errors. Check the original file for errors or format mismatches and re-import."
                    />
                    {statusMessage && <ErrorMsg withoutIcon message={statusMessage} />}
                </div>
            </InfoPanel>
        </div>
    );
});

export default CalculationErrorPanel;
