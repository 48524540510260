import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { useVariableList, updateVariables } from "store/resources/actions/variables/variablesActions";

import Variables from "layouts/common/Variables";

import IconLoading from "components/ui/Icons/IconLoading";

import { useVariablesTransform } from "utils/useVariablesTransform";

import { IndexSignature } from "types/types";
import { ModelVariablesProps } from "pages/ManageProject/ProjectionsContent/ModelDashboard/DashboardDefault/ModelVariablesPanel/types";
import {
    Variables as VariablesInterface,
    VariablesTransformed as VariablesTransformedInterface,
    VariablesResponseModel as VariablesResponseModelInterface,
} from "store/resources/actions/variables/types";

const useTransform = (data: VariablesResponseModelInterface) => useVariablesTransform(data?.model, 5);

const ModelVariables = memo(({ idProject, idInputLog, editing, modelState, required, onEditCancel }: ModelVariablesProps) => {
    const dispatch = useDispatch();

    // Resources

    const [projectVariables] = useVariableList({ idProject, transform: (data) => data?.model });
    const [modelVariables] = useVariableList({ idProject, idInputLog, transform: useTransform });

    // State

    const [variableValues, setVariableValues] = useState<IndexSignature<string>>({});

    // Variable and useMemos

    const variablesSavedOnProject = useMemo(() => {
        if (projectVariables === undefined) {
            return undefined;
        }

        return projectVariables?.find((projectVariable: VariablesInterface) => projectVariable.lastUpdatedBy !== null);
    }, [projectVariables]);

    const differsFromProject = useMemo(() => {
        // If there are no Project Variables,
        // return false
        if (projectVariables === undefined) {
            return false;
        }

        // If there are Project Variables,
        // but not saved yet, return false
        if (variablesSavedOnProject === undefined) {
            return false;
        }

        const initialValueCheck = modelVariables?.some((v1: VariablesTransformedInterface[]) =>
            v1.some(
                (v2: VariablesTransformedInterface) =>
                    v2.value !== projectVariables.find((projectVariable: VariablesInterface) => projectVariable.name === v2.name)?.value
            )
        );

        let updatedValueCheck = false;

        if (Object.keys(variableValues).length !== 0) {
            Object.keys(variableValues).forEach((key) => {
                if (variableValues[key] !== "") {
                    updatedValueCheck = projectVariables.some(
                        (projectVariable: VariablesInterface) =>
                            projectVariable.name === key && projectVariable.value !== variableValues[key]
                    );

                    return updatedValueCheck;
                }
            });
        }

        return initialValueCheck || updatedValueCheck;
    }, [projectVariables, modelVariables, variableValues, variablesSavedOnProject]);

    let infoMessage = "";

    if (differsFromProject) {
        infoMessage = "Values of some variables differ from the Project settings";
    } else if (!variablesSavedOnProject) {
        infoMessage = "You use local settings until they are saved on the Project level";
    }

    const fieldDiffersFromProject = useCallback(
        (fieldName: string) => {
            if (projectVariables === undefined || projectVariables?.length === 0 || variablesSavedOnProject === undefined) {
                return false;
            }

            const projectVariableValue = projectVariables.find(
                (projectVariable: VariablesInterface) => projectVariable.name === fieldName
            )?.value;

            if (projectVariableValue !== undefined) {
                if (
                    variableValues.hasOwnProperty(fieldName) &&
                    variableValues[fieldName] !== projectVariableValue &&
                    variableValues[fieldName] !== ""
                ) {
                    return true;
                }

                return modelVariables?.some((v1: VariablesTransformedInterface[]) =>
                    v1.some((v2: VariablesTransformedInterface) => v2.name === fieldName && v2.value !== projectVariableValue)
                );
            }

            return false;
        },
        [projectVariables, modelVariables, variableValues, variablesSavedOnProject]
    );

    // Event handlers

    const onUpdateVariableClick = useCallback(
        (action?: string) => {
            dispatch(
                updateVariables({
                    idProject,
                    idInputLog,
                    values: variableValues,
                    action,
                    onSuccess: () => {
                        dispatch(clearModelLogItems({ idProject, idInputLog }));

                        setVariableValues({});
                    },
                })
            );
        },
        [idProject, idInputLog, variableValues, dispatch]
    );

    return (
        <>
            {/* Using data instead of loading states
                because they are not functioning as
                intended in this scenario. */}
            {!projectVariables || !modelVariables ? (
                <IconLoading />
            ) : (
                <Variables
                    variablesLevel="model"
                    values={variableValues}
                    projectVariables={projectVariables}
                    modelVariables={modelVariables}
                    variablesSavedOnProject={variablesSavedOnProject}
                    differsFromProject={differsFromProject}
                    editing={editing}
                    infoMessage={infoMessage}
                    modelState={modelState}
                    modelRequired={required}
                    setValues={setVariableValues}
                    fieldDiffersFromProject={fieldDiffersFromProject}
                    onSave={onUpdateVariableClick}
                    onEditCancel={onEditCancel}
                />
            )}
        </>
    );
});

export default ModelVariables;
