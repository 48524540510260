import { useMemo } from "react";

import { useProjectStudyCase } from "store/resources/actions/studyCase/studyCaseProjectActions";
import { useModelStudyCase } from "store/resources/actions/studyCase/studyCaseModelActions";

import { UseProjectPotentialFlagParams, UseModelPotentialFlagParams } from "./types";

const useProjectPotentialFlag = ({ idProject }: UseProjectPotentialFlagParams) => {
    const [studyCases = []] = useProjectStudyCase({ idProject });

    const projectPotentialFlag = useMemo(() => {
        return studyCases.some((studyCase: any) => studyCase.value === "1" && studyCase.displayName.includes("Potential"));
    }, [studyCases]);

    return projectPotentialFlag;
};

const useModelPotentialFlag = ({ idProject, idInputLog }: UseModelPotentialFlagParams) => {
    const [studyCases = []] = useModelStudyCase({ idProject, idInputLog });

    const modelPotentialFlag = useMemo(() => {
        return studyCases.some((studyCase: any) => studyCase.value === "1" && studyCase.displayName.includes("Potential"));
    }, [studyCases]);

    return modelPotentialFlag;
};

export const usePotentialFlag = ({ idProject, idInputLog }: UseModelPotentialFlagParams) => {
    const projectPotentialFlag = useProjectPotentialFlag({ idProject });
    const modelPotentialFlag = useModelPotentialFlag({ idProject, idInputLog });

    return {
        projectPotentialFlag,
        modelPotentialFlag: modelPotentialFlag === undefined ? projectPotentialFlag : modelPotentialFlag,
    };
};
