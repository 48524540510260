import ClassNames from "classnames";

import { useFocusClasses } from "utils/useFocusClasses";
import { useEnterKey } from "utils/useEnterKey";

const TabListItem = ({ item, active, onClick, equalItemsWidth, classNames }) => {
    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({ disabled: !isTabable });
    const [onEnter] = useEnterKey({ disabled: !isTabable, onClick: () => onClick(item) });

    const className = ClassNames("tab text-center", classNames, {
        "equal-width": equalItemsWidth,
        active: active,
        error: item.error,
    });

    return (
        <div
            className={className}
            tabIndex="0"
            onClick={() => onClick(item)}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
        >
            {item.title}
        </div>
    );
};

export default TabListItem;
