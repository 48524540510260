import { HUB_EVENT_RECEIVE, HUB_EVENT_SEND } from "store/actionTypes";

import { clearResource } from "store/resources/actions/clearResource";

const clearResourceMethod = (resourceName, key, userNumber) => (dispatch) => {
    dispatch(
        clearResource({
            resourceName: resourceName,
            key: key,
            userNumber: userNumber,
        })
    );
};

const sendEventMethod = (key, userNumber, data) => (dispatch) => {
    dispatch({
        type: HUB_EVENT_RECEIVE,
        key,
        userNumber,
        data,
    });
};

export const brokerHubActionEventMap = {
    clearResource: clearResourceMethod,
    sendEvent: sendEventMethod,
};

export const broadcastSendEvent = (key, data) => (dispatch) => {
    dispatch({
        type: HUB_EVENT_SEND,
        key,
        data,
    });
};
