import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { API_VDSM_GET_AUTHORIZED, API_RIGHTS_RESPONSE, VDSM_USER_SAVE } from "store/actionTypes";

import { refresh } from "store/login/actions";

import { updateUserRole } from "store/resources/actions/user/userActions";

import Tile from "./Tile";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import Checkbox from "components/ui/Input/Checkbox";
import Separator from "components/ui/Separator";

import { tokenExpired } from "utils/user";
import { copyToClipboard } from "utils/string";
import { USER_ROLES } from "utils/user/defines";
import { openWindowUserProfile } from "utils/window";

import { UserRole } from "utils/user/types";

import "./style.scss";

const USER_RIGHTS = [
    {
        label: "Component Owner",
        value: USER_ROLES.COMPONENT_OWNER,
        hidden: true,
    },
    {
        label: "Analyst",
        value: USER_ROLES.ANALYST,
    },
    {
        label: "Manager",
        value: USER_ROLES.MANAGER,
    },
    {
        label: "Basic Client",
        value: USER_ROLES.BASIC_CLIENT,
    },
    {
        label: "Basic Primary Contact",
        value: USER_ROLES.BASIC_PRIMARY_CONTACT,
    },
    {
        label: "Advanced Client",
        value: USER_ROLES.ADVANCED_CLIENT,
    },
    {
        label: "Client Admin",
        value: USER_ROLES.CLIENT_ADMIN,
        hidden: true,
    },
    {
        label: "Stakeholder",
        value: USER_ROLES.STAKEHOLDER,
    },
    {
        label: "Program Stakeholder",
        value: USER_ROLES.PROGRAM_STAKEHOLDER,
        hidden: true,
    },
    {
        label: "Regulator Stakholder",
        value: USER_ROLES.REGULATOR_STAKEHOLDER,
        hidden: true,
    },
    {
        label: "Auditor Stakeholder",
        value: USER_ROLES.AUDITOR_STAKEHOLDER,
        hidden: true,
    },
];

const TILES = [
    {
        header: "UI Library",
        children: "List of UI elements that are being used in VisionInsight",
        component: "UILibrary",
    },
];

const DeveloperPage = memo(() => {
    const dispatch = useDispatch();

    // @ts-ignore
    const user = useSelector((state) => state.vdsmUser);

    // @ts-ignore
    const isLoading = useSelector((state) => state.login.isLoading);

    // @ts-ignore
    const isLatestVersion = useSelector((state) => state.system.isLatestVersion);

    const [simCalculation, setSimCalculation] = useState(false); // TODO: Create our own simulation
    const [loggerEnabled, setLoggerEnabled] = useState(localStorage.getItem("redux-logger-enabled") === "true" || false);
    const [debugStatuses, setDebugStatuses] = useState(sessionStorage.getItem("debug-statuses-enabled") === "true" || false);

    const onCopy = useCallback(() => {
        copyToClipboard(user.accessToken, () => {
            toast.success("Copied to clipboard");
        });
    }, [user.accessToken]);

    const onRefresh = useCallback(() => {
        dispatch(refresh());
    }, [dispatch]);

    const onOpenUserProfilePage = useCallback(() => {
        dispatch(openWindowUserProfile());
    }, [dispatch]);

    const onChange = useCallback((event, name) => {
        switch (name) {
            case "simCalculation":
                setSimCalculation(event.target.checked);

                break;

            // Enables/disables Redux Logger
            case "loggerEnabled":
                localStorage.setItem("redux-logger-enabled", `${event.target.checked}`);

                setLoggerEnabled(event.target.checked);

                // Page reload required to
                // enable/disable Redux Logger
                window.location.reload();

                break;

            case "debugStatuses":
                sessionStorage.setItem("debug-statuses-enabled", `${event.target.checked}`);

                setDebugStatuses(event.target.checked);

                break;

            default:
        }
    }, []);

    const onUserRightsChange = useCallback(
        (value: UserRole) => {
            dispatch(
                updateUserRole({
                    userName: user.name,
                    userRole: value,
                    onComplete: () => {
                        dispatch({
                            type: API_VDSM_GET_AUTHORIZED,
                            url: `${process.env.REACT_APP_API_BASE_URL}permissions`,
                            actionTypes: {
                                store: VDSM_USER_SAVE,
                            },
                            responseActionType: API_RIGHTS_RESPONSE,
                        });
                    },
                })
            );
        },
        [user, dispatch]
    );

    return (
        <div className="flex-column flex-one-in-column with-scroll developer-page">
            <div className="developer-page__container">
                {user && (
                    <div className="flex-row no-shrink flex-padding">
                        <div className="flex-column">
                            <div>
                                Username: <strong>{user.name}</strong>
                            </div>
                            <div>
                                Access Token: <strong>{tokenExpired(user) ? "invalid" : "valid"}</strong>
                            </div>
                            <div>
                                Expires: <strong>{new Date(user.tokenExpires).toLocaleString()}</strong>
                            </div>
                            <div>
                                VisionInsight latest version: <strong>{isLatestVersion ? "true" : "false"}</strong>
                            </div>
                            <Separator />
                            <ButtonGroup>
                                <Button onClick={onCopy}>Copy token</Button>
                                <Button disabled={isLoading} onClick={onRefresh}>
                                    Refresh token
                                </Button>
                                <Button onClick={onOpenUserProfilePage}>User profile</Button>
                            </ButtonGroup>
                            <Separator />
                            <FieldGroup>
                                <div>
                                    <Dropdown
                                        placeholder="Select from the list"
                                        value={user.rights[0]}
                                        items={USER_RIGHTS}
                                        onChange={(value) => onUserRightsChange(value as UserRole)}
                                    />
                                </div>
                                <div>
                                    <Checkbox
                                        label="Simulate model calculations in progress"
                                        disabled
                                        checked={simCalculation}
                                        onChange={(event) => onChange(event, "simCalculation")}
                                    />
                                </div>
                                <div>
                                    <Checkbox
                                        label="Use redux logger"
                                        checked={loggerEnabled}
                                        onChange={(event) => onChange(event, "loggerEnabled")}
                                    />
                                </div>
                                <div>
                                    {/* Debug statues for Projects, Models or Modules */}
                                    <Checkbox
                                        label="Debug statuses"
                                        checked={debugStatuses}
                                        onChange={(event) => onChange(event, "debugStatuses")}
                                    />
                                </div>
                            </FieldGroup>
                        </div>
                    </div>
                )}
                {TILES.map((tile) => (
                    <Tile {...tile} />
                ))}
            </div>
        </div>
    );
});

export default DeveloperPage;
