import { isEmpty } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { useMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";

import { openModalDialogModelInputsValidationErrorsPopup } from "layouts/Modal/ModelDashboardModal/utils";

import IconWithLabel from "components/ui/Icons/IconWithLabel";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import WarningMsg from "components/ui/StatusMsg/WarningMsg";

import { getArrayFieldsJoined } from "utils/array";
import { actionStatus } from "utils/constants";

import { ValidationErrorProps } from "./types";

const ValidationError = memo(({ idInputLog, idInputVersion, validations }: ValidationErrorProps) => {
    const dispatch = useDispatch();

    const [modelInputs, isLoadingModelInputs] = useMeasureInputs({ idInputLog });

    const validatedModelInputs = useMemo(() => {
        let validatedModelInputs;

        if (!isEmpty(modelInputs) && !isLoadingModelInputs) {
            validatedModelInputs = (modelInputs.equipmentinputstables || [])
                .concat(modelInputs.nonequipmentinputstables || [])
                .filter((input: any) => validations.tables.includes(input.tableName));
        }

        return validatedModelInputs;
    }, [validations.tables, modelInputs, isLoadingModelInputs]);

    const sheetName = getArrayFieldsJoined(validatedModelInputs, "sheetName");

    const onViewValidationError = useCallback(() => {
        dispatch(
            openModalDialogModelInputsValidationErrorsPopup({
                idInputLog,
                idInputVersion,
                validations,
            })
        );
    }, [idInputLog, idInputVersion, validations, dispatch]);

    return (
        <>
            <div className="flex-row align-end">
                {validations.msgClassification === actionStatus.ERROR && (
                    <ErrorMsg withoutIcon message={`‘${sheetName}’ validation: ${validations.message}`} />
                )}
                {validations.msgClassification === actionStatus.WARNING && (
                    <WarningMsg withoutIcon message={`‘${sheetName}’ validation: ${validations.message}`} />
                )}
                <IconWithLabel className="margin-left-small" icon="eye-visibility-empty" onClick={onViewValidationError}>
                    View
                </IconWithLabel>
            </div>
        </>
    );
});

export default ValidationError;
