import { memo } from "react";

import { useUserProfile } from "store/resources/actions/user/userActions";

import IconLoading from "components/ui/Icons/IconLoading";
import Tooltip from "components/ui/Tooltip";

import { getUserInitials } from "utils/user";

import { AvatarProps } from "./types";

import "./style.scss";

interface UserAvatarProps {
    className?: string;
    name: string;
    size: number;
}

const defaultColors = [
    "#2ecc71", // emerald
    "#3498db", // peter river
    "#8e44ad", // wisteria
    "#e67e22", // carrot
    "#e74c3c", // alizarin
    "#1abc9c", // turquoise
    "#2296e6", // blue
];

function sumChars(str: string) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
    }

    return sum;
}

export const UserAvatar = memo(({ className = "user-avatar", name, size }: UserAvatarProps) => {
    // pick a deterministic color from the list
    let i = sumChars(name) % defaultColors.length;
    const backgroundColor = defaultColors[i];

    const innerStyle = {
        heigth: `${size}px`,
        width: `${size}px`,
        lineHeight: `${size}px`,
        backgroundColor,
    };

    return (
        <div aria-label={name} className={className} data-testid={className} style={innerStyle}>
            {name.replace(/\s/g, "")}
        </div>
    );
});

const Avatar = memo(({ userNumber }: AvatarProps) => {
    const [user = {}, isLoading] = useUserProfile({ userNumber });

    const { initials, name } = getUserInitials(user.firstName, user.lastName);

    return (
        <>
            {isLoading ? (
                <IconLoading />
            ) : (
                <Tooltip
                    customIcon={<UserAvatar className="avatar" size={24} name={initials} />}
                >{`${name} also has this view opened. You can't make any changes while they are using it.`}</Tooltip>
            )}
        </>
    );
});

export default Avatar;
