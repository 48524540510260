import { memo } from "react";

import Window from "./Window";
import WindowTabs from "./WindowTabs";

import { useViews } from "utils/useViews";

import { View } from "store/window/types";

import "./style.scss";

const WindowsContainer = memo((props: WindowsContainerProps) => {
    const {
        containerName,

        onOpen,
        onClose,

        onSplit,
        onRemoveSplit,
        onClearSplit,

        onActivate,
        onSync,
    } = props;

    const views = useViews({ containerName });

    const splitView = views.filter((view: View) => view.active)[0] && views.filter((view: View) => view.active)[0].splitView;

    const renderWindows = () => {
        return views.map((view: View) => (
            <Window
                key={view.name}
                containerName={containerName}
                name={view.name}
                splitView={splitView}
                onOpen={onOpen}
                onClose={onClose}
                onSplit={onSplit}
                onRemoveSplit={onRemoveSplit}
                onClearSplit={onClearSplit}
            />
        ));
    };

    return (
        <div className="flex-column flex-one-in-row no-scroll windows-container">
            <div className="flex-row flex-one no-scroll windows-container__content">{renderWindows()}</div>
            <WindowTabs containerName={containerName} onClose={onClose} onActivate={onActivate} onSync={onSync} />
        </div>
    );
});

interface WindowsContainerProps {
    /**
     * Type of View's container.
     */
    containerName: string;

    /**
     * Function that opens a new View.
     */
    onOpen: (params: any) => void;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that splits the View.
     */
    onSplit: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit: (name: string) => void;

    /**
     * Function that clears the chosen side
     * of the Split View to blank View.
     */
    onClearSplit: (name: string, side: "left" | "right") => void;

    /**
     * Function that activates a View.
     */
    onActivate: (name: string) => void;

    /**
     * Function that enables/disables
     * sync scrolling for Split View.
     */
    onSync: (name: string) => void;
}

export default WindowsContainer;
