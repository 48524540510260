import StatusMsg, { StatusMsgInterface } from "components/ui/StatusMsg";

const ErrorMsg = (props: StatusMsgInterface) => {
    const icon = props.icon ? props.icon : "circle-exclamation-filled";

    return (
        <StatusMsg
            error
            largeMsg={props.largeMsg}
            iconWithLabelMedium={props.iconWithLabelMedium}
            icon={props.largeMsg || props.withoutIcon ? undefined : icon}
            message={props.message}
        />
    );
};

export default ErrorMsg;
