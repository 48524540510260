import { API_VDSM_POST_AUTHORIZED } from "store/actionTypes";

export const BASE_URL = process.env.REACT_APP_LOGGING_API_BASE_URL;

export const reportError = (error) => async (dispatch, getState) => {
    const { user } = getState();

    if (user && user.isActive) {
        dispatch({
            type: API_VDSM_POST_AUTHORIZED,
            url: `${BASE_URL}`,
            body: JSON.stringify(error),
            skipErrorReport: true,
        });
    }
};
