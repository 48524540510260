import { memo, useMemo } from "react";

import Dropdown from "components/ui/Dropdown";
import Input from "components/ui/Input";
import DatePicker from "components/ui/Input/DatePicker";
import DatePickerContainer from "components/ui/Input/DatePicker/DatePickerContainer";

import { allProjectStates } from "utils/constants";
import { DATEPICKER_DATE_FORMAT } from "utils/date";

const ProjectDefaultFilters = memo(({ filters, updateFilter, updateFilterValue }: any) => {
    const projectStateItems = useMemo(() => {
        return Object.values(allProjectStates).map((status) => ({
            label: status,
            value: status.toUpperCase(),
        }));
    }, []);

    return (
        <div className="list-search-headers-row">
            <div className="column-filter column-project-name project-default filter left">
                <Input
                    placeholder="Search"
                    icon="search"
                    inputTableFilter
                    value={filters.projectName}
                    onChange={(event) => updateFilter("projectName")(event)}
                />
            </div>
            <div className="column-filter column-project-description project-default filter">
                <Input
                    placeholder="Search"
                    icon="search"
                    inputTableFilter
                    value={filters.projectDescription}
                    onChange={(event) => updateFilter("projectDescription")(event)}
                />
            </div>
            <div className="column-filter column-started project-default filter">
                <DatePicker
                    // @ts-ignore
                    popperContainer={DatePickerContainer}
                    value={filters.started}
                    placeholder={DATEPICKER_DATE_FORMAT.toUpperCase()}
                    inputTableFilter
                    onChange={(data: any) => updateFilterValue("started")(data?.value || "")}
                />
            </div>
            <div className="column-filter column-target-date project-default filter">
                <DatePicker
                    // @ts-ignore
                    popperContainer={DatePickerContainer}
                    value={filters.targetDate}
                    placeholder={DATEPICKER_DATE_FORMAT.toUpperCase()}
                    inputTableFilter
                    onChange={(data: any) => updateFilterValue("targetDate")(data?.value || "")}
                />
            </div>
            <div className="column-filter column-project-state project-default filter right">
                <Dropdown
                    placeholder="Select"
                    value={filters.projectState || []}
                    items={projectStateItems}
                    inputTableFilter
                    withFilter
                    multiple
                    displayCount
                    onChange={(value) => updateFilterValue("projectState")(value)}
                />
            </div>
        </div>
    );
});

export default ProjectDefaultFilters;
