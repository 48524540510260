import { memo } from "react";

import LabelWithIcon from "components/ui/LabelWithIcon";

import "./style.scss";

const HowToImport = memo((props: HowToImportProps) => {
    return (
        <div className="how-to-import">
            {props.title && (
                <div className="flex-row justify-space-between how-to-import__title-block">
                    <div className="how-to-import__title-block--title">{props.title}</div>
                </div>
            )}
            <p>
                Data upload may take some time due to large file size or poor Internet connection. To speed up this process, we suggest you
                prepare the source file by following an instruction.
            </p>
            <ul>
                <li>To import data use files with extension .xlsx, .xlsm, .xls or .csv;</li>
                <li>Remove all extra sheets, such as Results, Engines, Notes, System Log, Summary. etc;</li>
                <li>
                    Check tabs you are going to import have correct names, column names and provide all listed columns. Any additional
                    (extra) columns will be ignored.
                </li>
            </ul>
            <a href={`${process.env.PUBLIC_URL}/InputData.xlsx`}>
                <LabelWithIcon icon="arrows-download__get_app_b_a" clickable>
                    Download list of sheets and columns used by model (xlsx, 14 kB)
                </LabelWithIcon>
            </a>
            <p>Note: All sheets can be in 1 File.</p>
        </div>
    );
});

interface HowToImportProps {
    /**
     * Title of the import block.
     */
    title?: string;
}

export default HowToImport;
