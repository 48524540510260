import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { useProjectList } from "store/resources/actions/project/projectActions";

import ProjectList from "pages/HomeDashboard/HomeDashboardDefault/common/ProjectList";

import { openModalDialogCreateProject } from "layouts/Modal/HomeDashboardModal/utils";

import Button from "components/ui/ButtonNew";
import DashboardPanel from "components/ui/Dashboard";

import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";
import { PROJECT_LIST } from "utils/constants";

import { ProjectListContainerProps } from "pages/HomeDashboard/HomeDashboardDefault/ProjectListContainer/types";

const ProjectListPanel = memo(({ selectedClient, user }: ProjectListContainerProps) => {
    const dispatch = useDispatch();

    const [projects, isLoadingProjects] = useProjectList({ idClient: selectedClient.id });

    const onNewProjectClick = useCallback(() => {
        dispatch(openModalDialogCreateProject({ idClient: selectedClient.id }));
    }, [selectedClient.id, dispatch]);

    const headerAction = hasAnyOfPermissions([USER_ACTIONS.PROJECT_ADD]) ? (
        <Button variant="tertiary" iconLeft="ui-plus_in_circle__add__create_b_s" iconSize="sm" padding="md" onClick={onNewProjectClick}>
            New Project
        </Button>
    ) : undefined;

    return (
        <DashboardPanel className="project-list-panel" title="Projects" headerAction={headerAction} pageId={PROJECT_LIST} lazyLoad={false}>
            <ProjectList client={selectedClient} projects={projects} isLoadingProjects={isLoadingProjects} user={user} withFilters />
        </DashboardPanel>
    );
});

export default ProjectListPanel;
