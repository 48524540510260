import { isNil } from "lodash";
import { memo } from "react";
import * as React from "react";
import ClassNames from "classnames";
import { useSelector } from "react-redux";

import "../style.scss";

const SideNavContainer = memo((props: SideNavContainerProps) => {
    const { className, sideNavIds, children } = props;

    const isActive = useSelector((state) => {
        // @ts-ignore - remove when state typed
        return (sideNavIds ?? []).some((id) => !isNil(state.sideNav[id]));
    });

    const classNames = ClassNames("sidenav-container flex-row fill-width", className, {
        "sidenav-container--active": isActive,
    });

    return <div className={classNames}>{children}</div>;
});

interface SideNavContainerProps {
    className?: string;
    sideNavIds?: string[];
    children?: React.ReactNode;
}

export default SideNavContainer;
