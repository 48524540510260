///
/// Market profile actions
/// resourceName: marketProfileResourceName, projectDataImportResourceName
///
import { get } from "lodash";

import { marketProfileResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { getResource } from "store/resources/actions/getResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { clearProjectLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import {
    MarketProfileData,
    MarketProfileDataResponseModel,
    MarketProfileDataParams,
    UpdateMarketProfileDataParams,
    DeleteMarketProfileDataParams,
    GetMarketProfileDataParams,
} from "./types";

const getMarketProfileDataList = ({ idProject, getState }: GetMarketProfileDataParams): MarketProfileData[] =>
    get(getState(), `resources.${marketProfileResourceName}.itemsById[${marketProfileResourceName}-${idProject}].data.model`) || [];

export const useMarketProfileData = ({ idProject }: MarketProfileDataParams) =>
    useResource({
        resourceName: marketProfileResourceName,
        key: `${marketProfileResourceName}-${idProject}`,
        query: { projectId: idProject },
        transform: (data: MarketProfileDataResponseModel) => data?.model,
    }) as [MarketProfileData[], boolean | undefined, any | undefined];

export const getMarketProfileData = ({ idProject }: MarketProfileDataParams) =>
    getResource({
        resourceName: marketProfileResourceName,
        key: `${marketProfileResourceName}-${idProject}`,
        query: { projectId: idProject },
    });

export const clearMarketProfileData =
    ({ idProject }: MarketProfileDataParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: marketProfileResourceName,
                key: `${marketProfileResourceName}-${idProject}`,
                broadcast: true,
            })
        );

export const updateMarketProfileData =
    ({ idProject, idProjectUpload, idSector, sector, onComplete }: UpdateMarketProfileDataParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const updateItems = getMarketProfileDataList({ idProject, getState }).map((u) =>
            u.idProjectUpload === idProjectUpload
                ? {
                      ...u,
                      idSector,
                      sector,
                  }
                : u
        );

        dispatch(
            updateResource({
                resourceName: marketProfileResourceName,
                key: `${marketProfileResourceName}-${idProject}`,
                body: {
                    idProject,
                    idProjectUpload,
                    idSector,
                    sector,
                },
                onComplete: () => {
                    onComplete && onComplete();
                    dispatch(clearMarketProfileData({ idProject }));
                    dispatch(clearProjectLogItems({ idProject }));
                },
                optimisticUpdate: {
                    value: {
                        model: updateItems,
                    },
                },
            })
        );
    };

export const deleteMarketProfileData =
    ({ idProject, idProjectUpload, fileName, onComplete }: DeleteMarketProfileDataParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const updateItems = getMarketProfileDataList({ idProject, getState }).filter((u) => u.idProjectUpload !== idProjectUpload);

        dispatch(
            deleteResource({
                resourceName: marketProfileResourceName,
                key: `${marketProfileResourceName}-${idProject}`,
                path: { projectUploadId: idProjectUpload },
                body: {
                    fileName,
                },
                onComplete: () => {
                    onComplete && onComplete();
                    dispatch(clearMarketProfileData({ idProject }));
                    dispatch(clearProjectLogItems({ idProject }));
                },
                optimisticUpdate: {
                    value: {
                        model: updateItems,
                    },
                },
            })
        );
    };
