import { useCallback, useState } from "react";
import { useFocusRing } from "@react-aria/focus";
import cn from "classnames";
import DropdownList from "components/ui/Dropdown/DropdownList";
import DropdownItem from "components/ui/Dropdown/DropdownItem";

import IconWrap from "components/ui/Icons";

import { usePopper } from "utils/usePopper";

import { ComponentWithDropdownProps } from "components/ui/Dropdown/types";

import "./style.scss";

const ComponentWithDropdown = (props: ComponentWithDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const [referenceElement, setReferenceElement] = useState<Element | null | undefined>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null | undefined>(null);

    // TODO: replace the current implementation with the usePopover from react-aria
    // https://react-spectrum.adobe.com/react-aria/usePopover.html
    const { styles, attributes } = usePopper({ referenceElement, popperElement, popperType: "dropdown" });
    const { focusProps } = useFocusRing({ within: true });

    const popperStyle = {
        ...styles.popper,
        width: referenceElement?.clientWidth,
    };

    const onBlur = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onClick = useCallback((event) => {
        event.stopPropagation();

        setIsOpen((prevIsOpen) => !prevIsOpen);
    }, []);

    return (
        <div className={cn("component-with-dropdown", props.className)}>
            <div className="component-with-dropdown__container">
                <div
                    data-testid={props.dataTestId}
                    ref={setReferenceElement}
                    className={cn("flex-row align-center justify-space-between component-with-dropdown__container__wrapper")}
                    onClick={onClick}
                    tabIndex={0}
                    // @todo: remove this workaround when react-aria implemented
                    onBlur={(e) => {
                        onBlur();
                        focusProps?.onBlur?.(e);
                    }}
                >
                    {props.component}
                    {!props.withoutShevron && (
                        <IconWrap icon={isOpen ? "shevron-small-up-expand-less" : "shevron-small-down-expand-more"} clickable />
                    )}
                </div>
                {isOpen && (
                    <DropdownList popperElement={setPopperElement} popperStyle={popperStyle} {...attributes.popper}>
                        <ul className="dropdown-component-items">
                            {props.items.map((item, index) => (
                                <DropdownItem
                                    key={`dropdown-item-${index}`}
                                    dataTestId={item.dataTestId}
                                    active={item.active}
                                    disabled={item.disabled}
                                    danger={item.danger}
                                    value={item.value}
                                    selectedValue={props.value}
                                    onMouseDown={
                                        item.disabled
                                            ? undefined
                                            : item.onSelect
                                            ? () => {
                                                  item.onSelect?.();
                                                  // @todo: remove this workaround when react-aria implemented
                                                  setIsOpen((prevIsOpen) => !prevIsOpen);
                                              }
                                            : props.onChange
                                    }
                                >
                                    <div className="flex-row align-center">
                                        {item.icon && (
                                            <div className="dropdown-component-items__item-icon">
                                                {typeof item.icon === "string" ? (
                                                    <IconWrap icon={item.icon} disabled={item.disabled} clickable />
                                                ) : (
                                                    item.icon
                                                )}
                                            </div>
                                        )}
                                        <div>{item.label}</div>
                                    </div>
                                </DropdownItem>
                            ))}
                        </ul>
                    </DropdownList>
                )}
            </div>
        </div>
    );
};

export default ComponentWithDropdown;
