import { memo, useCallback } from "react";
import { batch, useDispatch } from "react-redux";

import { deleteAllInactiveProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { deleteAllInactiveStandaloneReports } from "store/resources/actions/standaloneReport/standaloneReportActions";
import { deleteAllInactiveComponentModels } from "store/resources/actions/componentModel/componentModelActions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";

import { ClearTrashBinProps } from "layouts/Modal/TrashBinDashboardModal/types";

const ClearTrashBin = memo(({ idProject, trashCount, onCancel }: ClearTrashBinProps) => {
    const dispatch = useDispatch();

    const onClearClick = useCallback(() => {
        batch(() => {
            dispatch(deleteAllInactiveProjectInputs({ idProject, action: "Deleting Models" }));
            dispatch(deleteAllInactiveComponentModels({ idProject }));
            dispatch(deleteAllInactiveStandaloneReports({ idProject, action: "Deleting Standalone Reports" }));
        });

        onCancel?.();
    }, [idProject, onCancel, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding">
                <div>
                    <strong>{trashCount}</strong> Projection{trashCount > 1 && "s"} will be deleted permanently.
                </div>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button
                    variant="primary"
                    color="system-critical"
                    iconLeft="ui-delete_forever_b_s"
                    iconSize="sm"
                    padding="lg"
                    onClick={onClearClick}
                >
                    Clear Trash Bin
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default ClearTrashBin;
