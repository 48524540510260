import { memo } from "react";

import Copyright from "components/ui/Copyright";

import { MainContainerProps } from "./types";

import "./style.scss";

const MainContainer = memo(({ children }: MainContainerProps) => {
    return (
        <div className="flex-column fill-width form-wrap-main-container">
            <div className="flex-column justify-center form-wrap-main-container__wrapper-container">
                <div>
                    <div className="logo" />
                    <div>{children}</div>
                </div>
            </div>
            <div className="form-wrap-main-container__copyright-container">
                <Copyright />
            </div>
        </div>
    );
});

export default MainContainer;
