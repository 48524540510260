import { chain, isEmpty } from "lodash";

export const getArrayFieldsJoined = (array: any[], selector: string) => chain(array).uniqBy(selector).map(selector).join(", ").value();

/**
 * Sort array in ascending order.
 *
 * @param array - array that needs to be sorted
 * @param key - a key to sort by
 */
export const sortArray = (array: any[], key?: string) => {
    if (!isEmpty(array)) {
        if (key) {
            if (typeof array[0][key] === "string") {
                return array.sort((a, b) =>
                    a[key].toLowerCase() > b[key].toLowerCase() ? 1 : b[key].toLowerCase() > a[key].toLowerCase() ? -1 : 0
                );
            } else if (typeof array[0][key] === "number") {
                return array.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
            } else {
                return array;
            }
        }

        if (typeof array[0] === "string") {
            return array.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : b.toLowerCase() > a.toLowerCase() ? -1 : 0));
        } else if (typeof array[0] === "number") {
            return array.sort();
        } else {
            return array;
        }
    } else {
        return array;
    }
};
