import { memo, useEffect, useMemo } from "react";

import DashboardHeader from "./DashboardHeader";
import EmbeddedPanel from "./EmbeddedPanel";
import ReportLogPanel from "./ReportLogPanel";

import { useStandaloneReports } from "store/resources/actions/standaloneReport/standaloneReportActions";

import hj from "utils/hotJar";

import { ReportDashboardProps } from "./types";
import { StandaloneReport } from "store/resources/actions/standaloneReport/types";

import "./style.scss";

const ReportDashboard = memo(({ viewIndex, idClient, idProject, idReport }: ReportDashboardProps) => {
    const [standaloneReports, isLoading] = useStandaloneReports({ idProject });

    const currentReport = useMemo(() => {
        let currentReport;

        if (standaloneReports && !isLoading) {
            currentReport = standaloneReports.find((standaloneReport: StandaloneReport) => standaloneReport.idReport === idReport);
        }

        return currentReport;
    }, [idReport, standaloneReports, isLoading]);

    const currentReportType = currentReport?.reportType || "";
    const currentReportFileName = currentReport?.filename || undefined;
    const currentIdPbi = currentReport?.idPbi || "";

    useEffect(() => {
        hj("stateChange", "ReportDashboard");
    }, []);

    return (
        <div className="standalone-report-dashboard">
            <div className="standalone-report-dashboard__header-container">
                <DashboardHeader viewIndex={viewIndex} report={currentReport} />
            </div>
            <div className="standalone-report-dashboard__body-container">
                <div>
                    <EmbeddedPanel
                        idProject={idProject}
                        idReport={idReport}
                        idPbi={currentIdPbi}
                        reportType={currentReportType}
                        fileName={currentReportFileName}
                    />
                </div>
                <div>
                    <ReportLogPanel idClient={idClient} idProject={idProject} idReport={idReport} />
                </div>
            </div>
        </div>
    );
});

export default ReportDashboard;
