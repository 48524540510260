import { memo } from "react";

import PowerBI from "pages/PowerBI";

import { measureSourcingDatasetId } from "utils/constants";

import { ProjectSourcesProps } from "./types";

export const ProjectSources = memo(({ idClient, idProject }: ProjectSourcesProps) => {
    const reportId = process.env.REACT_APP_POWERBI_MEASURE_SOURCING_REPORT_ID;

    return (
        <div className="flex-column fill-height project-sources">
            {reportId && (
                <PowerBI
                    idClient={idClient}
                    idProject={idProject}
                    reportId={reportId}
                    datasetId={measureSourcingDatasetId}
                    filterEntityId={idProject} // filter by Project ID
                    isPaginated
                />
            )}
        </div>
    );
});
