import { isEmpty } from "lodash";
import { memo, useMemo } from "react";

import { useMeasureInputs } from "store/resources/actions/modelInputs/modelInputsActions";

import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";
import WarningMsg from "components/ui/StatusMsg/WarningMsg";
import ImportErrorMsg from "components/ui/StatusMsg/ImportErrorMsg";

import ValidationErrorsTable from "./ValidationErrorsTable";

import { useValidationErrors } from "utils/useValidationErrors";
import { getArrayFieldsJoined } from "utils/array";

import { ModelInputsValidationErrorsProps } from "layouts/Modal/ModelDashboardModal/types";

const ModelInputsValidationErrors = memo(
    ({ idInputLog, idInputVersion, validations, msgError, msgWarning }: ModelInputsValidationErrorsProps) => {
        const { inputValidations } = useValidationErrors(idInputLog, true);

        const [modelInputs, isLoadingModelInputs] = useMeasureInputs({ idInputLog });

        const groupedModelInputs = useMemo(() => {
            let groupedModelInputs;

            if (!isEmpty(inputValidations)) {
                const inputValidationsGrouped = inputValidations.find(
                    (validation: any) => validation.idValidation === validations.idValidation
                );

                if (!isEmpty(modelInputs) && !isLoadingModelInputs) {
                    groupedModelInputs = (modelInputs.equipmentinputstables || [])
                        .concat(modelInputs.nonequipmentinputstables || [])
                        .filter((input: any) => inputValidationsGrouped?.tables.includes(input.tableName));
                }
            }

            return groupedModelInputs;
        }, [validations.idValidation, inputValidations, modelInputs, isLoadingModelInputs]);

        const fileName = getArrayFieldsJoined(groupedModelInputs, "fileName");

        const sheetName = getArrayFieldsJoined(groupedModelInputs, "sheetName");

        return (
            <>
                <div className="flex-column fill-height modal-padding">
                    <div>
                        <ImportErrorMsg fileName={fileName} sheetName={sheetName} warning={msgWarning} />
                    </div>
                    <div style={{ marginTop: "8px" }}>
                        {msgError && <ErrorMsg message={validations.message} />}
                        {msgWarning && <WarningMsg message={validations.message} />}
                    </div>
                    <ValidationErrorsTable idInputLog={idInputLog} idInputVersion={idInputVersion} validations={validations} />
                </div>
            </>
        );
    }
);

export default ModelInputsValidationErrors;
