import { componentModelResourceName, componentModelListResourceName, componentModelCloneResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { waitReadResource } from "store/resources/actions/getResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";

import { clearProjectInputs } from "store/resources/actions/projectInput/projectInputActions";
import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { clearModelInputsProgress } from "store/resources/actions/modelInputs/modelInputsProgressActions";
import { clearCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";

import { getResourceState } from "store/utils";

import {
    ComponentModel,
    ComponentModelResponseModel,
    UseComponentModelsParams,
    ClearComponentModelParams,
    CreateComponentModelParams,
    WaitReadComponentModelsParams,
    UpdateComponentModelParams,
    DeleteComponentModelParams,
} from "./types";

export const clearComponentModelList =
    ({ idProject }: ClearComponentModelParams) =>
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: componentModelListResourceName,
                key: `${componentModelListResourceName}-${idProject}`,
                broadcast: true,
            })
        );

const waitReadComponentModels =
    ({ idProject, action }: WaitReadComponentModelsParams) =>
    (dispatch) =>
        dispatch(
            waitReadResource({
                resourceName: componentModelListResourceName,
                key: `${componentModelListResourceName}-${idProject}`,
                actionName: action,
            })
        );

export const useComponentModels = ({ idProject, onlyActives }: UseComponentModelsParams) =>
    useResource({
        resourceName: componentModelListResourceName,
        key: idProject ? `${componentModelListResourceName}-${idProject}` : undefined,
        path: {
            projectId: idProject,
        },
        transform: (data: ComponentModelResponseModel[]) => {
            let transformedFilteredData: ComponentModel[] = [];

            const transformedData = data?.map((model) => {
                const { projectId, inputLogId, modelId, templateId, territoryId, fuelId, analystId, reviewerId, ...rest } = model;

                return {
                    ...rest,
                    idProject: projectId,
                    idInputLog: inputLogId,
                    idModel: modelId,
                    idTemplate: templateId,
                    idTerritory: territoryId,
                    idFuel: fuelId,
                    idAnalyst: analystId,
                    idReviewer: reviewerId,
                };
            });

            if (onlyActives) {
                transformedFilteredData = transformedData?.filter((model) => model.active === true);
            }

            return onlyActives ? transformedFilteredData : transformedData;
        },
    }) as [ComponentModel[], boolean | undefined] | [ComponentModel[], boolean | undefined, any];

export const createComponentModel =
    ({
        idProject,
        idTemplate,
        idInputLog,
        idTerritory,
        idFuel,
        idAnalyst,
        idReviewer,
        name,
        required,
        description,
        modelState,
        onSuccess,
    }: CreateComponentModelParams) =>
    (dispatch) =>
        dispatch(
            createResource({
                resourceName: componentModelResourceName,
                key: `${componentModelResourceName}-${idProject}`,
                query: {
                    projectId: idProject,
                    name,
                    required,
                    description,
                    modelState,
                    templateId: idTemplate,
                    inputLogId: idInputLog,
                    territoryId: idTerritory,
                    fuelId: idFuel,
                    analystId: idAnalyst,
                    reviewerId: idReviewer,
                },
                onSuccess: (action) => {
                    const modelId = action?.data;

                    dispatch(clearProjectLogItems({ idProject }));
                    dispatch(clearComponentModelList({ idProject }));

                    modelId && onSuccess?.(Number(modelId));
                },
            })
        );

export const updateComponentModel =
    ({
        idProject,
        idModel,
        idInputLog,
        idTerritory,
        idFuel,
        idAnalyst,
        idReviewer,
        name,
        description,
        modelState,
        required,
        active,
        markedForDeletion,
        action,
        onSuccess,
        onComplete,
    }: UpdateComponentModelParams) =>
    (dispatch, getState) => {
        action && dispatch(waitReadComponentModels({ idProject, action }));

        dispatch(
            updateResource({
                resourceName: componentModelResourceName,
                query: {
                    modelId: idModel,
                    inputLogId: idInputLog,
                    territoryId: idTerritory,
                    fuelId: idFuel,
                    analystId: idAnalyst,
                    reviewerId: idReviewer,
                    name,
                    description,
                    modelState,
                    required,
                    active,
                    markedForDeletion,
                    lastUpdatedBy: getState().vdsmUser?.userNumber,
                },
                onSuccess: () => {
                    dispatch(clearProjectLogItems({ idProject }));
                    dispatch(clearModelLogItems({ idProject, idModel }));
                    dispatch(clearProjectInputs({ idProject }));
                    dispatch(clearComponentModelList({ idProject }));

                    onSuccess?.();
                },
                onError: () => {
                    dispatch(clearComponentModelList({ idProject }));
                },
                onComplete,
            })
        );
    };

export const cloneComponentModel =
    ({
        sourceProjectId,
        sourceModelId,
        targetProjectId,
        targetModelName,
        targetTerritoryId,
        targetFuelId,
        targetModelDescription,
        tableNames,
        onSuccess,
    }: CloneComponentModelParams) =>
    (dispatch) => {
        const isDifferentProject = sourceProjectId !== targetProjectId;

        dispatch(waitReadComponentModels({ idProject: targetProjectId, action: "Duplicating" }));

        // Display action text for the source project as well
        isDifferentProject && dispatch(waitReadComponentModels({ idProject: sourceProjectId, action: "Duplicating" }));

        dispatch(
            createResource({
                resourceName: componentModelCloneResourceName,
                query: {
                    sourceModelId,
                    targetProjectId,
                    targetModelName,
                    targetTerritoryId,
                    targetFuelId,
                    description: targetModelDescription,
                    tableNames,
                },
                onComplete: () => {
                    dispatch(clearComponentModelList({ idProject: targetProjectId }));
                    isDifferentProject && dispatch(clearComponentModelList({ idProject: sourceProjectId }));
                    dispatch(clearModelInputsProgress({ idProject: targetProjectId }));
                    dispatch(clearCalculationPreconditions({ idProject: targetProjectId }));
                },
                onSuccess,
            })
        );
    };

export const deleteComponentModel =
    ({ idProject, idModel, onSuccess }: DeleteComponentModelParams) =>
    (dispatch) => {
        dispatch(
            updateComponentModel({
                idProject,
                idModel,
                markedForDeletion: true,
                action: "Deleting Model",
                onSuccess,
            })
        );
    };

export const deleteAllInactiveComponentModels =
    ({ idProject }: ClearComponentModelParams) =>
    (dispatch, getState) => {
        const state = getState();

        const models = getResourceState<ComponentModelResponseModel>(state, componentModelListResourceName, { idProject });

        const inactiveModels = models.filter((model) => model.active === false);

        inactiveModels.forEach((model) => {
            dispatch(
                deleteComponentModel({
                    idProject,
                    idModel: model.modelId,
                })
            );
        });
    };

interface CloneComponentModelParams {
    sourceProjectId: number;
    sourceModelId: number;
    targetProjectId: number;
    targetModelName: string;
    targetTerritoryId: number | null;
    targetFuelId: number | null;
    targetModelDescription?: string;
    tableNames: string;
    onSuccess?: (action?: any) => void;
}
