import {
    API_VDSM_POST_AUTHORIZED,
    API_CRUD_CREATE_REQUEST,
    API_CRUD_CREATE_SUCCESS,
    API_CRUD_CREATE_ERROR,
    API_CRUD_CREATE_START,
    API_CRUD_CREATE_PROGRESS,
} from "store/actionTypes";
import { getResourceUrl } from "store/utils";

import { optimisticUpdateItem, OptimisticUpdateItemParams } from "./updateResource";

import { IndexSignature } from "types/types";

export type Nullable<T> = T | null;

/**
 * Creates a new resource in database.
 */
export const createResource =
    ({
        resourceName,
        key,
        resourceId = null,
        path = {},
        query,
        body,
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        optimisticUpdate,
        updateFromResponse = false,
        onStart,
        onProgress,
        onSuccess,
        onError,
        onComplete,
    }: CreateResourceParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch({
            type: API_VDSM_POST_AUTHORIZED,
            url: getResourceUrl(resourceName, path),
            query,
            body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
            actionTypes: {
                pending: API_CRUD_CREATE_REQUEST,
                response: API_CRUD_CREATE_SUCCESS,
                error: API_CRUD_CREATE_ERROR,
                start: API_CRUD_CREATE_START,
                progress: API_CRUD_CREATE_PROGRESS,
            },
            passThroughData: {
                resourceName,
                key,
                resourceId,
                path,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onStart,
                onSuccess,
                onError,
                onComplete,
                onProgress,
                updateFromResponse,
            },
        });

        if (optimisticUpdate) {
            dispatch(
                optimisticUpdateItem({
                    value: (optimisticUpdate as OptimisticUpdateItemParams).value,
                    resourceName,
                    resourceId,
                    key,
                })
            );
        }
    };

export interface CreateResourceParams {
    /**
     * Name of the resource.
     */
    resourceName?: string;

    /**
     * Name of the resource property.
     */
    key?: string;

    /**
     * ID of resource.
     */
    resourceId?: Nullable<string>;

    path?: IndexSignature<any>;

    query?: object;

    body?: object | string;

    /**
     * Display notification on success.
     */
    showSuccessNotification?: boolean;

    /**
     * Display notification on error.
     */
    showErrorNotification?: boolean;

    successMessage?: string;

    errorMessage?: string;

    /**
     * Update item in Redux store.
     */
    optimisticUpdate?: OptimisticUpdateItemParams | boolean;

    updateFromResponse?: boolean;

    /**
     * Function to run on start.
     */
    onStart?: (...args: any) => void;

    /**
     * Function to run on progress.
     */
    onProgress?: () => void;

    /**
     * Function to run on success.
     */
    onSuccess?: (action?: any) => void;

    /**
     * Function to run on error.
     */
    onError?: (action?: any) => void;

    /**
     * Function to run on completion.
     */
    onComplete?: () => void;
}
