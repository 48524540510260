import { standaloneReportsFilesResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";

import { StandaloneReportFileParams } from "./types";

export const useStandaloneReportFile = ({ idProject, idReport, fileName }: StandaloneReportFileParams) =>
    useResource({
        resourceName: standaloneReportsFilesResourceName,
        key: fileName ? `${standaloneReportsFilesResourceName}-${idProject}-${idReport}` : undefined,
        path: {
            fileName,
        },
    }) as [string, boolean | undefined, any | undefined];
