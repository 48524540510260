import { ElementType, RefObject, useRef } from "react";
import { useHover } from "@react-aria/interactions";
import { useFocusRing } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import { useLink } from "@react-aria/link";
import { FocusableElement } from "@react-types/shared";
import cn from "classnames";

import { LinkProps } from "./types";

import "./style.scss";

const Link = (props: LinkProps) => {
    const ElementType: ElementType = props.href ? "a" : "span";

    const ref = useRef<FocusableElement>(null);
    const { hoverProps, isHovered } = useHover(props);
    const { focusProps, isFocused, isFocusVisible } = useFocusRing({ within: true });

    const { linkProps, isPressed } = useLink(
        {
            ...props,
            elementType: ElementType,
            onPress: (e) => props.onClick?.(e),
        },
        ref
    );

    return (
        <ElementType
            ref={ref as RefObject<HTMLAnchorElement>}
            {...mergeProps(props, linkProps, hoverProps, focusProps)}
            className={cn("aria-link", props.className, {
                "is-disabled": props.isDisabled || false,
                "is-hovered": isHovered,
                "is-pressed": isPressed,
                "is-focused": isFocused,
                "is-focus-visible": isFocusVisible,
            })}
            data-disabled={props.isDisabled || undefined}
            data-hovered={isHovered || undefined}
            data-pressed={isPressed || undefined}
            data-focused={isFocused || undefined}
            data-focus-visible={isFocusVisible || undefined}
        >
            {props.children}
        </ElementType>
    );
};

export default Link;
