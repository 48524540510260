import { memo } from "react";

import ModelInputs from "./ModelInputs";

import DashboardPanel from "components/ui/Dashboard";

import { MODEL_INPUTS } from "utils/constants";

import { ModelInputsProps } from "./types";

const ModelInputsPanel = memo(({ idClient, idProject, idInputLog, idTemplate, model, projectName }: ModelInputsProps) => {
    return (
        <DashboardPanel className="model-inputs-panel" title="Model Inputs" pageId={MODEL_INPUTS}>
            <ModelInputs
                idClient={idClient}
                idProject={idProject}
                idInputLog={idInputLog}
                idTemplate={idTemplate}
                model={model}
                projectName={projectName}
            />
        </DashboardPanel>
    );
});

export default ModelInputsPanel;
