import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import { clearProjectList, createProject } from "store/resources/actions/project/projectActions";
import { useProjectNames } from "store/resources/actions/project/projectNamesActions";
import { useTeamMembers } from "store/resources/actions/user/userActions";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import FieldGroup from "components/ui/FieldGroup";
import Input from "components/ui/Input";

import { projectTypeItems } from "utils/constants";
import { isNullOrWhitespace } from "utils/string";

import { ProjectModalProps } from "layouts/Modal/HomeDashboardModal/types";
import { TeamMember } from "store/resources/actions/user/types";

const CreateProject = memo(({ idClient, onCancel }: ProjectModalProps) => {
    const dispatch = useDispatch();

    const [projectType, setProjectType] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [projectManager, setProjectManager] = useState<number | null>(null);
    const [teamMembers, setTeamMembers] = useState<string[]>([]);

    const [projectNames = []] = useProjectNames({ idClient });
    const [internalUsers = []] = useTeamMembers();

    const userItems = useMemo(
        () =>
            internalUsers.map((member: TeamMember) => ({
                label: member.fullName || "",
                value: member.idUser,
            })) || [],
        [internalUsers]
    );

    const nameExists = useMemo(
        () =>
            !isNullOrWhitespace(projectName) &&
            projectNames.some((name: string) => name.toLowerCase() === projectName.toLowerCase().trim()),
        [projectName, projectNames]
    );

    const onChange = useCallback((value, handler) => {
        handler(value);
    }, []);

    const onCreateComplete = useCallback(() => {
        dispatch(
            clearProjectList({
                idClient,
            })
        );

        onCancel?.();
    }, [idClient, onCancel, dispatch]);

    const onCreateClick = useCallback(() => {
        dispatch(
            createProject({
                idClient,
                idManager: projectManager ?? undefined,
                members: teamMembers.length > 0 ? teamMembers.map((id) => ({ idUser: Number(id) })) : undefined,
                projectType,
                projectName,
                projectDescription,
                onComplete: onCreateComplete,
            })
        );
    }, [idClient, projectType, projectName, projectDescription, projectManager, teamMembers, onCreateComplete, dispatch]);

    return (
        <>
            <div className="flex-column modal-padding">
                <FieldGroup>
                    <Dropdown
                        dataTestId="project-type-select"
                        label="Project type"
                        placeholder="Select project type"
                        required
                        value={projectType}
                        items={projectTypeItems}
                        onChange={(value) => onChange(value, setProjectType)}
                    />
                    <Input
                        dataTestId="project-name-input"
                        label="Project name"
                        required
                        placeholder="Study Year Project Type"
                        value={projectName}
                        error={nameExists}
                        msgText={nameExists && "This name is already in use."}
                        onChange={(event) => onChange(event.target.value, setProjectName)}
                    />
                    <Input
                        dataTestId="project-description-input"
                        placeholder="Type a project description"
                        label="Project description"
                        required
                        value={projectDescription}
                        onChange={(event) => onChange(event.target.value, setProjectDescription)}
                    />
                    <Dropdown
                        label="Manager responsible"
                        placeholder="Select from the list"
                        // @todo: dropdown support for null value
                        value={projectManager ?? undefined}
                        items={userItems}
                        withFilter
                        onChange={(value) => onChange(value, setProjectManager)}
                    />
                    <Dropdown
                        label="Project team members"
                        placeholder="Select from the list"
                        // @todo: dropdown support for number[] value
                        value={teamMembers.map((id) => id.toString())}
                        items={userItems.map((item) => ({ ...item, value: item.value.toString() }))}
                        withFilter
                        multiple
                        singleLine
                        onChange={(value) => onChange(value, setTeamMembers)}
                    />
                </FieldGroup>
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button
                    dataTestId="create-project"
                    className="create-project"
                    variant="primary"
                    padding="lg"
                    isDisabled={nameExists || !projectType || isNullOrWhitespace(projectName) || isNullOrWhitespace(projectDescription)}
                    onClick={onCreateClick}
                >
                    Create Project
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default CreateProject;
