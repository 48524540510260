///
/// Project actions
/// resourceName: projectsResourceName
///
import { get } from "lodash";
import { v1 as uuidv1 } from "uuid";
import { batch } from "react-redux";

import { clearProjectNames } from "./projectNamesActions";

import { projectsResourceName } from "store/configureResources";

import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource, optimisticUpdateItem } from "store/resources/actions/updateResource";
import { clearResource } from "store/resources/actions/clearResource";
import { deleteResource } from "store/resources/actions/deleteResource";

import { clearBookmarks, optimisticDeleteBookmark } from "store/resources/actions/bookmarks/bookmarkedProjectsActions";

import { allProjectStates } from "utils/constants";

import {
    ProjectState,
    Project,
    ProjectResponseModel,
    UseProjectListParams,
    ClearProjectListParams,
    CreateProjectParams,
    UpdateProjectParams,
    DeleteProjectParams,
    OptimisticUpdateProjectBookmarkParams,
    GetProjectListParams,
    GetResourceParams,
} from "./types";

export const getProjectList = ({ idClient, getState }: GetProjectListParams): Project[] =>
    get(getState(), `resources.${projectsResourceName}.itemsById[${projectsResourceName}-${idClient}].data.model`);

const getResourceParams = ({
    idClient,
    idProject,
    idProjectType,
    idManager,
    members,
    projectName,
    projectDescription,
    projectState,
    territories,
    fuels,
    targetDate,
    territoryStructureName,
    uploadFolder,
    uploadUrl,
    hasFinalReport,
    userNumber,
    updateItems,
    onComplete,
    dispatch,
}: GetResourceParams) => ({
    resourceName: projectsResourceName,
    key: `${projectsResourceName}-${idClient}`,
    path: idProject ? { idProject } : undefined,
    body: {
        idClient,
        idProject,
        idProjectType,
        idManager,
        members,
        projectName,
        projectDescription,
        projectState,
        territories,
        fuels,
        targetDate,
        territoryStructureName,
        uploadFolder,
        uploadUrl,
        hasFinalReport,
    },
    optimisticUpdate: updateItems?.length
        ? {
              value: {
                  model: updateItems,
              },
          }
        : undefined,
    updateFromResponse: false,
    showSuccessNotification: false,
    onComplete: () => {
        onComplete?.();

        batch(() => {
            dispatch(clearProjectList({ idClient }));
            dispatch(clearProjectNames({ idClient }));
            userNumber && dispatch(clearBookmarks({ userNumber }));
        });
    },
});

export const useProjectList = ({ idClient, transform }: UseProjectListParams) =>
    useResource({
        resourceName: projectsResourceName,
        key: idClient ? `${projectsResourceName}-${idClient}` : undefined,
        query: { idClient: idClient },
        transform: transform ? transform : (data: ProjectResponseModel) => data?.model,
    });

export const createProject =
    ({ idClient, idManager, members, projectType, projectName, projectDescription, onComplete }: CreateProjectParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const idProjectType = projectType === "potentialStudy" ? 1 : 2;

        const updateItems: Project[] = [
            {
                idClient,
                // @ts-ignore
                idProject: uuidv1(),
                idManager,
                members,
                projectName,
                projectDescription,
                projectState: allProjectStates.PROPOSED.toUpperCase() as ProjectState,
                started: new Date().toISOString().slice(0, -1),
            },
            ...getProjectList({ idClient, getState }),
        ];

        dispatch(
            createResource(
                getResourceParams({
                    idClient,
                    idManager,
                    members,
                    idProjectType,
                    projectName,
                    projectDescription,
                    projectState: allProjectStates.PROPOSED.toUpperCase() as ProjectState,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const updateProject =
    ({
        idClient,
        idProject,
        idManager,
        managerFullName,
        members,
        projectName,
        projectDescription,
        projectState,
        territories,
        fuels,
        targetDate,
        territoryStructureName,
        uploadFolder,
        uploadUrl,
        hasFinalReport,
        userNumber,
        onComplete,
    }: UpdateProjectParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const updateItems = getProjectList({ idClient, getState }).map((u) =>
            u.idProject === idProject
                ? {
                      ...u,
                      idManager: idManager ?? u.idManager,
                      managerFullName: managerFullName ?? u.managerFullName,
                      members: members !== undefined ? members : u.members,
                      projectName: projectName || u.projectName,
                      projectDescription: projectDescription || u.projectDescription,
                      projectState: projectState || u.projectState,
                      territories: territories || u.territories,
                      fuels: fuels || u.fuels,
                      targetDate: targetDate || u.targetDate,
                      territoryStructureName: territoryStructureName || u.territoryStructureName,
                      uploadFolder: uploadFolder || u.uploadFolder,
                      uploadUrl: uploadUrl || u.uploadUrl,
                  }
                : u
        );

        dispatch(
            updateResource(
                getResourceParams({
                    idClient,
                    idProject,
                    idManager,
                    members: updateItems.find((u) => u.idProject === idProject)?.members,
                    projectName,
                    projectDescription,
                    projectState,
                    territories: updateItems.find((u) => u.idProject === idProject)?.territories,
                    fuels: updateItems.find((u) => u.idProject === idProject)?.fuels,
                    targetDate,
                    territoryStructureName,
                    uploadFolder,
                    uploadUrl,
                    hasFinalReport,
                    userNumber,
                    updateItems,
                    onComplete,
                    dispatch,
                })
            )
        );
    };

export const deleteProject =
    ({ idClient, idProject, userNumber, onComplete }: DeleteProjectParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const updateItems = getProjectList({ idClient, getState })?.filter((p) => p.idProject !== idProject);

        batch(() => {
            dispatch(
                optimisticDeleteBookmark({
                    idProject,
                    userNumber,
                })
            );

            dispatch(
                deleteResource(
                    getResourceParams({
                        idClient,
                        idProject,
                        updateItems,
                        onComplete,
                        dispatch,
                    })
                )
            );
        });
    };

export const clearProjectList =
    ({ idClient }: ClearProjectListParams) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(
            clearResource({
                resourceName: projectsResourceName,
                key: `${projectsResourceName}-${idClient}`,
                broadcast: true,
            })
        );

export const optimisticUpdateProjectBookmark =
    ({ idClient, idProject, bookmarked }: OptimisticUpdateProjectBookmarkParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const projectList = getProjectList({ idClient, getState });

        if (projectList === undefined) {
            return;
        }

        const updatedProjectList = projectList.map((project) =>
            project.idProject === idProject
                ? {
                      ...project,
                      bookmarked,
                  }
                : project
        );

        dispatch(
            optimisticUpdateItem({
                resourceName: projectsResourceName,
                key: `${projectsResourceName}-${idClient}`,
                value: {
                    model: updatedProjectList,
                },
            })
        );
    };
