const ImportErrorMsg = ({ fileName, sheetName, warning }: ImportErrorMsgProps) => {
    return (
        <>
            <span>Import from</span>
            {sheetName && (
                <span>
                    {" "}
                    ‘<strong>{sheetName}</strong>’ sheet in
                </span>
            )}
            <span>
                {" "}
                ‘<strong>{fileName}</strong>’ file done with {warning ? "warnings" : "errors"} due to bad-formed input data. Check the
                original file for errors or format mismatches and re-import.
            </span>
        </>
    );
};

interface ImportErrorMsgProps {
    fileName: string;

    sheetName?: string;

    warning?: boolean;
}

export default ImportErrorMsg;
