import { clearProjectInputs, waitReadProjectInputs } from "./projectInputActions";
import { clearComponentModelList } from "store/resources/actions/componentModel/componentModelActions";
import { clearModelInputsProgress } from "store/resources/actions/modelInputs/modelInputsProgressActions";
import { clearCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";

import { createResource } from "store/resources/actions/createResource";
import { projectInputsDuplicateResourceName } from "store/configureResources";
import { getKey } from "store/utils";

export const createDuplicateInput =
    ({
        sourceIdProject,
        sourceIdInputLog,
        targetIdProject,
        targetModelName,
        targetModelDescription,
        targetIdTerritory,
        targetIdFuel,
        tableNames,
        onSuccess,
    }: DuplicateInputParams) =>
    (dispatch) => {
        const isDifferentProject = sourceIdProject !== targetIdProject;

        dispatch(waitReadProjectInputs({ idProject: targetIdProject, action: "Duplicating" }));

        // Display action text for the source project as well
        isDifferentProject && dispatch(waitReadProjectInputs({ idProject: sourceIdProject, action: "Duplicating" }));

        dispatch(
            createResource({
                resourceName: projectInputsDuplicateResourceName,
                key: `${projectInputsDuplicateResourceName}${getKey(sourceIdInputLog)}${getKey(targetIdProject)}${getKey(targetModelName)}`,
                body: {
                    sourceIdInputLog,
                    targetIdProject,
                    targetModelName,
                    description: targetModelDescription,
                    targetIdTerritory,
                    targetIdFuel,
                    tableNames,
                },
                optimisticUpdate: false,
                updateFromResponse: true,
                showSuccessNotification: false,
                onComplete: () => {
                    dispatch(clearProjectInputs({ idProject: targetIdProject }));
                    isDifferentProject && dispatch(clearProjectInputs({ idProject: sourceIdProject }));
                    dispatch(clearComponentModelList({ idProject: targetIdProject }));
                    dispatch(clearModelInputsProgress({ idProject: targetIdProject }));
                    dispatch(clearCalculationPreconditions({ idProject: targetIdProject }));
                },
                onSuccess,
            })
        );
    };

interface DuplicateInputParams {
    sourceIdProject: number;
    sourceIdInputLog: number;
    targetIdProject: number;
    targetModelName: string;
    targetModelDescription?: string;
    targetIdTerritory: number | null;
    targetIdFuel: number | null;
    tableNames: string;
    onSuccess?: (action?: any) => void;
}
