import { isEmpty } from "lodash";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { updateStandaloneReport, deleteStandaloneReport } from "store/resources/actions/standaloneReport/standaloneReportActions";
import { useActiveViewsCustomReportList } from "store/resources/actions/activeViews/activeViewCustomReportActions";

import { selectProjection } from "store/projections/actions";

import Button from "components/ui/ButtonNew";
import Avatar from "components/ui/Avatar";

import {
    openModalDialogDeleteProjection,
    openModalDialogRestoreProjection,
    openModalDialogDeletePermanentlyProjection,
} from "layouts/Modal/utils";
import { openModalDialogEditHtmlReport, openModalDialogEditPbiReport } from "layouts/Modal/StandaloneReportModal/utils";

import { projections } from "utils/constants";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";

import { DashboardHeaderProps } from "./types";

import "./style.scss";

const DashboardHeader = memo(({ viewIndex, report }: DashboardHeaderProps) => {
    const { idProject, idReport, idPbi, name, reportType, filename, active } = report;

    const dispatch = useDispatch();

    // Resources

    const [activeViews, isLoadingActiveViews] = useActiveViewsCustomReportList({ idProject, idReport });

    // Helper functions

    const onMoveStandaloneReport = useCallback(
        (active) => {
            let formData = new FormData();

            formData.append("idProject", idProject.toString());
            formData.append("idReport", idReport.toString());
            formData.append("reportType", reportType);
            formData.append("active", active.toString());

            dispatch(
                updateStandaloneReport({
                    idProject,
                    idReport,
                    formData,
                    action: active ? "Restoring Standalone Report" : "Moving Standalone Report to Trash Bin",
                })
            );
        },
        [idProject, idReport, reportType, dispatch]
    );

    const onDeleteSuccess = useCallback(() => {
        dispatch(
            selectProjection({
                viewIndex,
                idProjectionView: projections.PROJECT_DASHBOARD,
                idProject,
            })
        );
    }, [idProject, viewIndex, dispatch]);

    const onDeleteStandaloneReport = useCallback(() => {
        dispatch(
            deleteStandaloneReport({
                idProject,
                idReport,
                reportType,
                filename,
                action: "Deleting Standalone Report",
                onSuccess: onDeleteSuccess,
            })
        );
    }, [idProject, idReport, reportType, filename, onDeleteSuccess, dispatch]);

    // Event handlers

    const onEditClick = useCallback(() => {
        switch (reportType) {
            case "HTML":
                dispatch(
                    openModalDialogEditHtmlReport({
                        viewIndex,
                        idProject,
                        idReport,
                        reportName: name ?? undefined,
                        editing: true,
                    })
                );

                break;
            case "PBI":
                dispatch(
                    openModalDialogEditPbiReport({
                        idProject,
                        idReport,
                        reportName: name ?? undefined,
                        idPbi: idPbi ?? undefined,
                        editing: true,
                    })
                );

                break;
            default:
        }
    }, [idProject, idReport, idPbi, name, reportType, viewIndex, dispatch]);

    const onDeleteClick = useCallback(() => {
        dispatch(
            openModalDialogDeleteProjection({
                title: "Delete Standalone Report",
                projectionName: name as string,
                onMove: () => onMoveStandaloneReport(false),
                onDelete: onDeleteStandaloneReport,
            })
        );
    }, [name, onMoveStandaloneReport, onDeleteStandaloneReport, dispatch]);

    const onRestoreClick = useCallback(() => {
        dispatch(
            openModalDialogRestoreProjection({
                title: "Restore Standalone Report",
                text: (
                    <div>
                        <strong>{name}</strong> will be restored to the Project.
                    </div>
                ),
                onRestore: () => onMoveStandaloneReport(true),
            })
        );
    }, [name, onMoveStandaloneReport, dispatch]);

    const onDeletePermanentlyClick = useCallback(() => {
        dispatch(
            openModalDialogDeletePermanentlyProjection({
                title: "Delete Standalone Report Permanently",
                projectionName: name as string,
                onDelete: onDeleteStandaloneReport,
            })
        );
    }, [name, onDeleteStandaloneReport, dispatch]);

    return (
        <div className="flex-column standalone-report-dashboard-header">
            <div className="flex-row align-center standalone-report-dashboard-header__top-container">
                <div className="standalone-report-dashboard-header__model-name">
                    <div className="margin-bottom-small standalone-report-dashboard-header__label">Standalone Report</div>
                    <div className="flex-row align-center standalone-report-dashboard-header__title-container">
                        <div className="standalone-report-dashboard-header__title">{name}</div>
                        {hasAnyOfPermissions([USER_ACTIONS.SA_REPORT_EDIT]) && active && (
                            <div className="margin-left-small">
                                <Button
                                    variant="tertiary"
                                    icon="ui-pencil__edit__create_b_s"
                                    iconSize="sm"
                                    title="Edit report information"
                                    padding="sm"
                                    onClick={onEditClick}
                                />
                            </div>
                        )}
                        {!isEmpty(activeViews) &&
                            !isLoadingActiveViews &&
                            activeViews.map((userNumber: string, index: number) => (
                                <div key={`avatar-${index}`} className="margin-left-small">
                                    <Avatar userNumber={userNumber} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <div className="flex-row align-center justify-space-between standalone-report-dashboard-header__bottom-container">
                <div className="standalone-report-dashboard-header__model-details">
                    <span className="standalone-report-dashboard-header__label">ID: </span>
                    <span>{idReport}</span>
                </div>
                <div>
                    {hasAnyOfPermissions([USER_ACTIONS.SA_REPORT_DELETE]) && active && (
                        <div>
                            <Button
                                variant="tertiary"
                                padding="md"
                                iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                                iconSize="sm"
                                onClick={onDeleteClick}
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                    {hasAnyOfPermissions([USER_ACTIONS.SA_REPORT_DELETE]) && !active && (
                        <>
                            <div className="margin-left">
                                <Button variant="tertiary" padding="md" iconLeft="arrows-reply_b_a" iconSize="sm" onClick={onRestoreClick}>
                                    Restore
                                </Button>
                            </div>
                            <div className="margin-left-small">
                                <Button
                                    variant="tertiary"
                                    padding="md"
                                    iconLeft="ui-trash__garbage__delete__remove__bin_b_s"
                                    iconSize="sm"
                                    onClick={onDeletePermanentlyClick}
                                >
                                    Delete
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

export default DashboardHeader;
