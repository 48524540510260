import StatusMsg, { StatusMsgInterface } from "components/ui/StatusMsg";

const WarningMsg = (props: StatusMsgInterface) => {
    const icon = props.icon ? props.icon : "warning-report-problem-filled";

    return (
        <StatusMsg
            warning
            largeMsg={props.largeMsg}
            iconWithLabelMedium={props.iconWithLabelMedium}
            icon={!props.largeMsg || !props.withoutIcon ? icon : undefined}
            message={props.message}
        />
    );
};

export default WarningMsg;
