import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useStandaloneReports, updateStandaloneReport } from "store/resources/actions/standaloneReport/standaloneReportActions";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import FieldGroup from "components/ui/FieldGroup";
import IconLoading from "components/ui/Icons/IconLoading";
import Input from "components/ui/Input";

import { isNullOrWhitespace } from "utils/string";

import { StandaloneReport } from "store/resources/actions/standaloneReport/types";
import { ModalOpenChildrenProps } from "layouts/Modal/types";

const REPORT_TYPE = "pbi";

const EditPbiReport: React.NamedExoticComponent<EditPbiReportProps> = memo((props) => {
    const { idProject, idReport, editing, onCancel } = props;

    const dispatch = useDispatch();

    const [reportName, setReportName] = useState(props.reportName ?? "");
    const [idPbi, setIdPbi] = useState(props.idPbi ?? "");

    const [standaloneReports, isLoading] = useStandaloneReports({ idProject });

    const reportNameExists = useMemo(() => {
        let reportNameExists = false;

        if (!isLoading) {
            reportNameExists = standaloneReports?.some(
                (standaloneReport: StandaloneReport) =>
                    standaloneReport.idReport !== idReport && standaloneReport.name?.toLowerCase() === reportName.toLowerCase().trim()
            );
        }

        return reportNameExists;
    }, [idReport, reportName, standaloneReports, isLoading]);

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, handler: (value: string) => void) => {
        handler(event.target.value);
    }, []);

    const onImportReportClick = () => {
        let formData = new FormData();

        formData.append("idProject", idProject.toString());
        formData.append("idReport", idReport ? idReport.toString() : "0");
        formData.append("name", reportName);
        formData.append("reportType", REPORT_TYPE);
        formData.append("idPbi", idPbi);
        idReport === undefined && formData.append("active", "true");

        dispatch(
            updateStandaloneReport({
                idProject,
                idReport: idReport || 0,
                formData,
                action: idReport !== undefined ? "Updating PBI Report" : "Creating PBI Report",
                onSuccess: onCancel,
            })
        );
    };

    return isLoading ? (
        <IconLoading />
    ) : (
        <>
            <div className="flex-column modal-padding">
                <FieldGroup>
                    <Input
                        label="Report name"
                        required
                        value={reportName}
                        error={reportNameExists}
                        msgText={reportNameExists ? "This name is already in use." : ""}
                        onChange={(event) => onChange(event, setReportName)}
                    />
                    <Input
                        label="Report ID"
                        placeholder="Paste report ID here"
                        required
                        value={idPbi}
                        error={idPbi.length > 0 && idPbi.length !== 36}
                        msgText={idPbi.length > 0 && idPbi.length !== 36 ? "Report ID must be 36 characters long." : ""}
                        onChange={(event) => onChange(event, setIdPbi)}
                    />
                </FieldGroup>
            </div>
            <ButtonGroup className="justify-end modal-actions">
                {editing ? (
                    <>
                        {/* In order to display "Update Report" button, either:
                                         - Report name must not be empty, report name must be unique and
                                           report name must differ from current report name
                                         OR
                                         - Report ID must be 36 characters long and report ID must
                                           differ from current report ID */}
                        {((!isNullOrWhitespace(reportName) && !reportNameExists && reportName.trim() !== props.reportName) ||
                            (idPbi.length === 36 && idPbi.trim() !== props.idPbi)) && (
                            <Button primary onClick={onImportReportClick}>
                                Update Report
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        {/* In order to display "Import Report" button:
                                         - Report name must not be empty
                                         - Report name must be unique
                                         - Report ID must be 36 characters long */}
                        {!isNullOrWhitespace(reportName) && !reportNameExists && idPbi.length === 36 && (
                            <Button primary onClick={onImportReportClick}>
                                Import Report
                            </Button>
                        )}
                    </>
                )}
                <Button onClick={onCancel}>Cancel</Button>
            </ButtonGroup>
        </>
    );
});

export interface EditPbiReportProps extends ModalOpenChildrenProps {
    idProject: number;
    idReport?: number;
    idPbi?: string;
    reportName?: string;
    editing?: boolean;
}

export default EditPbiReport;
