import { openModalDialog } from "layouts/Modal/utils";

import ClearTrashBin from "./ClearTrashBin";

import { ClearTrashBinProps } from "./types";

export const openModalDialogClearTrashBin =
    ({ idProject, trashCount }: ClearTrashBinProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Clear Trash Bin",
                component: <ClearTrashBin idProject={idProject} trashCount={trashCount} />,
            })
        );
    };
