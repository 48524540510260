import { JsonHubProtocol, HubConnectionState, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import { brokerHubActionEventMap } from "./actions";

import {
    API_HUB_BROADCAST_CLEAR_RESOURCE,
    API_HUB_CONNECT,
    API_HUB_DISCONNECT,
    HUB_EVENT_RECEIVE,
    HUB_EVENT_SEND,
    HUB_DATASET_REFRESH,
    HUB_DATASET_REFRESH_STATUS,
    POWERBI_DATASET_REFRESH_STATUS_ERROR,
    POWERBI_DATASET_REFRESH_ERROR,
} from "store/actionTypes";
import { refresh } from "store/login/actions";
import { setPowerBIDatasetRefreshStatus } from "store/powerBI/refreshStatus/actions";

import { isDevEnvironment } from "utils/constants";
import { tokenExpired } from "utils/user";

const connectionHub = `${process.env.REACT_APP_API_HUB_URL}`;
let connection;

export const brokerMiddleware = ({ dispatch, getState }) => {
    function needToRefreshToken() {
        const user = getState().vdsmUser;

        if (!user) {
            return false;
        }

        return tokenExpired(user);
    }

    const accessTokenFactory = () => getState().vdsmUser?.accessToken;

    const canConnect = () => !connection || connection?.state === HubConnectionState.Disconnected;

    const startSignalRConnection = async (connection) => {
        if (!canConnect()) {
            return;
        }

        try {
            await connection.start();
            console.assert(connection.state === HubConnectionState.Connected);
            if (isDevEnvironment) {
                console.log("SignalR: connection established");
            }
        } catch (err) {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.error("SignalR: Connection Error: ", err);
            setTimeout(() => startSignalRConnection(connection), 5000);
        }
    };

    const canDisconnect = () => connection && connection.state && connection.state !== HubConnectionState.Disconnected;

    const isConnected = () => connection.state === HubConnectionState.Connected;

    const connectToHub = () => {
        const options = {
            logMessageContent: isDevEnvironment,
            logger: isDevEnvironment ? LogLevel.Warning : LogLevel.Error,
            accessTokenFactory: accessTokenFactory,
        };
        // create the connection instance
        // withAutomaticReconnect will automatically try to reconnect
        // and generate a new socket connection if needed
        connection = new HubConnectionBuilder()
            .withUrl(connectionHub, options)
            .withAutomaticReconnect()
            .withHubProtocol(new JsonHubProtocol())
            .configureLogging(isDevEnvironment ? LogLevel.Debug : LogLevel.Error)
            .build();

        // Note: to keep the connection open the serverTimeout should be
        // larger than the KeepAlive value that is set on the server
        // keepAliveIntervalInMilliseconds default is 15000 and we are using default
        // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
        connection.serverTimeoutInMilliseconds = 60000;

        // re-establish the connection if connection dropped
        connection.onclose((error) => {
            console.assert(connection.state === HubConnectionState.Disconnected);
            if (isDevEnvironment) {
                console.log("SignalR: Connection closed due to error. Try refreshing this page to restart the connection", error);
            }
        });

        connection.onreconnecting((error) => {
            console.assert(connection.state === HubConnectionState.Reconnecting);
            if (isDevEnvironment) {
                console.log("SignalR: Connection lost due to error. Reconnecting.", error);
            }
        });

        connection.onreconnected((connectionId) => {
            console.assert(connection.state === HubConnectionState.Connected);
            if (isDevEnvironment) {
                console.log("SignalR: Connection reestablished. Connected with connectionId", connectionId);
            }
        });

        Object.keys(brokerHubActionEventMap).forEach((actionEvent) => {
            connection.on(actionEvent, function eventHandler() {
                const res = arguments;
                const eventHandler = brokerHubActionEventMap[actionEvent];
                eventHandler && dispatch(eventHandler(...res));
            });
        });

        startSignalRConnection(connection);
    };

    const refreshDataset = async ({ workspaceId, datasetId }) => {
        try {
            if (!workspaceId || !datasetId) {
                throw new Error("workspaceId or datasetId is missing");
            }

            await waitForConnection(connection);

            if (isConnected()) {
                connection.invoke("RefreshDataset", getState().vdsmUser?.userNumber, workspaceId, datasetId);
            }
        } catch (err) {
            dispatch({
                type: POWERBI_DATASET_REFRESH_ERROR,
                id: `${workspaceId}-${datasetId}`,
                payload: {
                    error: err?.message ?? "Failed to refresh dataset",
                },
            });
        }
    };

    const getDatasetRefreshStatus = async ({ workspaceId, datasetId }) => {
        try {
            if (!workspaceId || !datasetId) {
                throw new Error("workspaceId or datasetId is missing");
            }

            await waitForConnection(connection);

            if (isConnected()) {
                connection.invoke("GetDatasetRefreshStatus", getState().vdsmUser?.userNumber, workspaceId, datasetId);
            }
        } catch (err) {
            dispatch({
                type: POWERBI_DATASET_REFRESH_STATUS_ERROR,
                id: `${workspaceId}-${datasetId}`,
                payload: {
                    error: err?.message ?? "Failed to get dataset refresh status",
                },
            });
        }
    };

    const disconnectFromHub = () => {
        connection.stop();
    };

    const sendClearResource = ({ resourceName, key }) => {
        //key may be of type RegExp, converting it to toString
        isConnected() && connection.invoke("ClearResource", resourceName, key.toString(), getState().vdsmUser?.userNumber);
    };

    const sendEvent = ({ key, data }) => {
        isConnected() && connection.invoke("SendEvent", key, getState().vdsmUser?.userNumber, data);
    };

    if (accessTokenFactory()) {
        if (canConnect()) {
            connectToHub();
        }
    }

    return (next) => (action) => {
        switch (action.type) {
            case API_HUB_CONNECT:
                if (needToRefreshToken()) {
                    dispatch(refresh({ action }));
                    return;
                }

                if (canConnect()) {
                    connectToHub();
                }
                break;

            case API_HUB_DISCONNECT:
                if (needToRefreshToken()) {
                    dispatch(refresh({ action }));
                    return;
                }

                if (canDisconnect()) {
                    disconnectFromHub();
                }
                break;

            case API_HUB_BROADCAST_CLEAR_RESOURCE:
                if (needToRefreshToken()) {
                    dispatch(refresh({ action }));
                    return;
                }

                if (canDisconnect()) {
                    sendClearResource(action);
                }
                break;

            case HUB_EVENT_SEND:
                if (needToRefreshToken()) {
                    dispatch(refresh({ action }));
                    return;
                }

                if (canDisconnect()) {
                    sendEvent(action);
                }
                break;

            case HUB_DATASET_REFRESH:
                if (needToRefreshToken()) {
                    dispatch(refresh({ action }));
                    return;
                }

                refreshDataset(action);
                break;

            case HUB_DATASET_REFRESH_STATUS:
                if (needToRefreshToken()) {
                    dispatch(refresh({ action }));
                    return;
                }

                getDatasetRefreshStatus(action);
                break;

            case HUB_EVENT_RECEIVE:
                if (action.key === "VI_POWERBI_DATASET_REFRESH_STATUS") {
                    dispatch(
                        setPowerBIDatasetRefreshStatus({
                            workspaceId: action.data.workspaceID,
                            datasetId: action.data.datasetID,
                            status: action.data.status,
                        })
                    );
                }

                break;

            default:
                break;
        }

        return next(action);
    };
};

const waitForConnection = async (connection, timeout = 5000) => {
    if (connection.state !== HubConnectionState.Connected) {
        let pollingCount = 0;
        const maxPollingCount = timeout / 1000;
        await new Promise((resolve, reject) => {
            const timeout = setInterval(() => {
                pollingCount++;

                if (connection.state === HubConnectionState.Connected) {
                    clearInterval(timeout);
                    resolve();
                } else {
                    if (pollingCount > maxPollingCount) {
                        clearInterval(timeout);
                        reject(new Error("connection timeout"));
                    }
                }
            }, 1000);
        });
    }

    return;
};
