import { isEmpty } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectClient } from "store/clients/actions";
import { useClientList } from "store/resources/actions/client/clientActions";

import IconLoading from "components/ui/Icons/IconLoading";

import HomeDashboardDefault from "./HomeDashboardDefault";
import HomeDashboardNoRole from "./HomeDashboardNoRole";

import { useClientId } from "utils/useClientId";
import { USER_ROLES } from "utils/user/defines";

import { Client } from "types/api/ClientResponseModel";

/**
 * HOME DASHBOARD VIEW.
 *
 * The first view user sees upon logging in.
 */
const HomeDashboard = memo(() => {
    const dispatch = useDispatch();

    // @ts-ignore
    const user = useSelector((state) => state.vdsmUser);

    const idClient = useClientId();

    const [clients, isLoadingClients] = useClientList();

    const [isLoadingUser, setIsLoadingUser] = useState(true);

    const selectedClient = useMemo(() => {
        let selectedClient;

        if (!isEmpty(clients) && !isLoadingClients) {
            // Automatically select a client if there's only one client available
            if (idClient === undefined && clients.length === 1) {
                dispatch(selectClient(clients[0].id));
            } else {
                selectedClient = clients.find((client: Client) => client.id === idClient);
            }
        }

        return selectedClient;
    }, [idClient, clients, isLoadingClients, dispatch]);

    useEffect(() => {
        // When user rights are gathered,
        // set loading to false
        if (user.rights !== undefined) {
            setIsLoadingUser(false);
        }
    }, [user.rights]);

    if (isLoadingUser) {
        return (
            <div className="flex-column flex-one align-center justify-center">
                <IconLoading />
            </div>
        );
    }

    switch (user.rights[0]) {
        case USER_ROLES.MANAGER:
        case USER_ROLES.ANALYST:
        case USER_ROLES.BASIC_CLIENT:
        case USER_ROLES.BASIC_PRIMARY_CONTACT:
        case USER_ROLES.ADVANCED_CLIENT:
        case USER_ROLES.STAKEHOLDER:
            return (
                <HomeDashboardDefault selectedClient={selectedClient} clients={clients} isLoadingClients={isLoadingClients} user={user} />
            );

        default:
            return <HomeDashboardNoRole />;
    }
});

export default HomeDashboard;
