import { createContext, memo } from "react";
// @ts-ignore
import { ScrollSync } from "react-scroll-sync";
import ClassNames from "classnames";

import ErrorBoundary from "components/ui/ErrorBoundary";
import SideNavContainer from "components/ui/SideNav/SideNavContainer";
import SideNavRoot from "components/ui/SideNav/SideNavRoot";

import SplitWindow from "./SplitWindow";
import WindowContent from "./WindowContent";
import WindowHeader from "./WindowHeader";

import { availableViews } from "pages/configureViews";

import { useViews } from "utils/useViews";
import { sideNavigationKey } from "utils/sideNav";

import { View } from "store/window/types";

import "./style.scss";

export const WindowContext = createContext({});

const Window = memo((props: WindowProps) => {
    const { containerName, name, splitView, onOpen, onClose, onSplit, onRemoveSplit, onClearSplit } = props;

    const views = useViews({ containerName });

    const view = views.find((view: View) => view.name === name) || {};

    const { className, component, active, showHeader, isSplitViewSync } = view;

    const ViewComponent = availableViews[component];

    const classNames = ClassNames("flex-column flex-one-in-row fill-height with-scroll no-shrink window", className, {
        "split-view": splitView,
        active: active,
    });

    if (splitView) {
        return (
            <ScrollSync enabled={isSplitViewSync}>
                <SplitWindow
                    className={classNames}
                    containerName={containerName}
                    name={name}
                    onOpen={onOpen}
                    onClose={onClose}
                    onRemoveSplit={onRemoveSplit}
                    onClearSplit={onClearSplit}
                />
            </ScrollSync>
        );
    }

    return (
        <WindowContext.Provider value={{ name, active, splitView }}>
            <ScrollSync enabled={isSplitViewSync}>
                <div className={classNames} hidden={!active}>
                    <WindowContent active={active}>
                        <>
                            {showHeader && (
                                <WindowHeader
                                    view={view}
                                    close={view.close}
                                    isSplitViewEnabled={view.isSplitViewEnabled}
                                    splitView={view.splitView}
                                    onClose={() => onClose(name)}
                                    onSplit={() => onSplit(name)}
                                    onRemoveSplit={() => onRemoveSplit(name)}
                                />
                            )}
                            <div className="flex-one-in-column with-scroll">
                                <div className="flex-column flex-one fill-height">
                                    {/* @ts-ignore - ErrorBoundary 'children' prop expects type 'never' which requires multiple children, but only a single child was provided. ts(2745) */}
                                    <ErrorBoundary>
                                        {ViewComponent && (
                                            // TODO: Overtime replace "global" SideNavContainer with this one
                                            <SideNavContainer
                                                className="fill-height"
                                                sideNavIds={[sideNavigationKey.innerLeft, sideNavigationKey.innerRight]}
                                            >
                                                <SideNavRoot id={sideNavigationKey.innerLeft} />
                                                <div className="flex-column flex-one">
                                                    <ViewComponent {...view.props} viewIndex={0} onOpen={onOpen} />
                                                </div>
                                                <SideNavRoot id={sideNavigationKey.innerRight} />
                                            </SideNavContainer>
                                        )}
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </>
                    </WindowContent>
                </div>
            </ScrollSync>
        </WindowContext.Provider>
    );
});

interface WindowProps {
    /**
     * Name of the View.
     */
    name: string;

    /**
     * Type of View's container.
     */
    containerName: string;

    /**
     * Split view active for the View.
     */
    splitView?: boolean;

    /**
     * Function that opens a new View.
     */
    onOpen: (params: any) => void;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that splits the View.
     */
    onSplit: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit: (name: string) => void;

    /**
     * Function that clears the chosen side
     * of the Split View to blank View.
     */
    onClearSplit: (name: string, side: "left" | "right") => void;
}

export default Window;
