import { memo, useCallback, useState } from "react";

import DashboardHeader from "./DashboardHeader";
import ProjectStakeholderFiles from "./ProjectStakeholderFiles";
import ProjectLevelReportsPanel from "./ProjectLevelReportsPanel";
import StandaloneReportsPanel from "./StandaloneReportsPanel";

import { ProjectStakeholderDashboardProps } from "./types";

import "./style.scss";

const ProjectStakeholderDashboard = memo(({ project, clientName }: ProjectStakeholderDashboardProps) => {
    const { idClient, idProject, projectName } = project;

    const [displayFiles, setDisplayFiles] = useState(false);

    const onDisplayFiles = useCallback(() => {
        setDisplayFiles(true);
    }, []);

    const onReturn = useCallback(() => {
        setDisplayFiles(false);
    }, []);

    if (displayFiles) {
        return (
            <ProjectStakeholderFiles
                project={project}
                clientName={clientName}
                displayFiles={displayFiles}
                onDisplayFiles={onDisplayFiles}
                onReturn={onReturn}
            />
        );
    } else {
        return (
            <div className="flex-column with-scroll project-stakeholder-dashboard">
                <div className="project-stakeholder-dashboard__header-container">
                    <DashboardHeader
                        project={project}
                        clientName={clientName}
                        displayFiles={displayFiles}
                        onDisplayFiles={onDisplayFiles}
                        onReturn={onReturn}
                    />
                </div>
                <div className="flex-row fill-height project-stakeholder-dashboard__body-container">
                    <div className="project-level-reports-panel-wrapper">
                        <ProjectLevelReportsPanel
                            idClient={idClient}
                            idProject={idProject}
                            projectName={projectName}
                            reportName="project-level"
                        />
                    </div>
                    <div className="project-level-reports-panel-wrapper">
                        <ProjectLevelReportsPanel
                            idClient={idClient}
                            idProject={idProject}
                            projectName={projectName}
                            reportName="measure-level"
                        />
                    </div>
                    <div className="standalone-reports-panel-wrapper">
                        <StandaloneReportsPanel idProject={idProject} projectName={projectName} />
                    </div>
                </div>
            </div>
        );
    }
});

export default ProjectStakeholderDashboard;
