import { useCallback, useRef, useState } from "react";
import cn from "classnames";

import DropdownContainer from "./DropdownContainer";
import DropdownList from "./DropdownList";
import DropdownItem from "./DropdownItem";

import Input from "components/ui/Input";
import Label from "components/ui/Label";
import StatusMsg from "components/ui/StatusMsg";
import ErrorMsg from "components/ui/StatusMsg/ErrorMsg";

import { usePopper } from "utils/usePopper";

import { DropdownProps } from "./types";

import "./style.scss";

/**
 * Filter threshold.
 */
const FILTER_LIMIT = 5;

const Dropdown = (props: DropdownProps) => {
    const [filterValue, setFilterValue] = useState("");

    const [referenceElement, setReferenceElement] = useState<Element | null | undefined>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null | undefined>(null);

    const dropdownRef = useRef<HTMLSelectElement>(null);

    const { styles, attributes } = usePopper({ referenceElement, popperElement, popperType: "dropdown" });

    const popperStyle = {
        ...styles.popper,
        width: referenceElement?.clientWidth,
    };

    const onFilterChange = useCallback((event) => {
        setFilterValue(event.target.value);
    }, []);

    const onBlur = useCallback(
        (event) => {
            // Workaround, when dropdown list loses focus,
            // dropdown list closes
            if (dropdownRef.current && !(props.multiple && event.relatedTarget?.className.includes("dropdown-list"))) {
                dropdownRef.current.focus();
                dropdownRef.current.blur();

                if (props.withFilter) {
                    setFilterValue("");
                }
            }
        },
        [props.multiple, props.withFilter]
    );

    return (
        <div className={cn("dropdown-component", props.className)}>
            {props.label && <Label required={props.required}>{props.label}</Label>}
            <DropdownContainer
                dataTestId={props.dataTestId}
                dropdownRef={dropdownRef}
                referenceElement={setReferenceElement}
                dropdownClassName={props.dropdownClassName}
                required={props.required}
                disabled={props.disabled}
                readOnly={props.readOnly}
                ghost={props.ghost}
                inputTableFilter={props.inputTableFilter}
                error={props.error}
                warning={props.warning}
                placeholder={props.placeholder}
                multiple={props.multiple}
                singleLine={props.singleLine}
                displayCount={props.displayCount}
                value={props.value}
                items={props.items}
                onRemove={props.onChange}
            >
                <DropdownList popperElement={setPopperElement} popperStyle={popperStyle} {...attributes.popper} onBlur={onBlur}>
                    {props.withFilter && props.items.length > FILTER_LIMIT && (
                        <div className="dropdown-component__filter">
                            <DropdownItem>
                                <Input key="dropdown-filter" autoFocus value={filterValue} icon="search" onChange={onFilterChange} />
                            </DropdownItem>
                        </div>
                    )}
                    <ul className="dropdown-component-items">
                        {props.items
                            .filter((item) => item.label.toLowerCase().trim().includes(filterValue.toLowerCase().trim()))
                            .map((item, index) => (
                                <DropdownItem
                                    key={`dropdown-item-${index}`}
                                    dataTestId={item.dataTestId}
                                    hidden={item.hidden}
                                    value={item.value}
                                    selectedValue={props.value}
                                    itemProps={item.itemProps}
                                    multiple={props.multiple}
                                    onMouseDown={props.onChange}
                                >
                                    {item.label}
                                </DropdownItem>
                            ))}
                    </ul>
                </DropdownList>
            </DropdownContainer>
            {props.msgText && (props.error ? <ErrorMsg message={props.msgText} /> : <StatusMsg message={props.msgText} />)}
        </div>
    );
};

export default Dropdown;
