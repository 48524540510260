import { memo } from "react";

import { useUserProfile } from "store/resources/actions/user/userActions";

import IconLoading from "components/ui/Icons/IconLoading";

import { formatFullName } from "utils/string";

const UserName = memo(({ userNumber, showWait = false }) => {
    const [user, isLoading] = useUserProfile({ userNumber });

    return (
        <>
            {user?.firstName && user?.lastName
                ? formatFullName(user.firstName, user.lastName)
                : user?.userName
                ? user.userName
                : showWait && isLoading && <IconLoading />}
        </>
    );
});

export default UserName;
