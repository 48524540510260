export const COOKIE_COOKIES_PREFERENCES_SET = "cookies_preferences_set";
export const COOKIE_OPT_IN = "opt_in";

export const getCookie = (name: string) => {
    const n = `${name}=`;
    const cookies = document.cookie.split(";");
    const cookiesCount = cookies.length;

    for (let index = 0; index < cookiesCount; index++) {
        let cookie = cookies[index];

        if (cookie.charAt(0) === " ") {
            cookie = cookie.substring(1, cookie.length);
        }

        if (cookie.indexOf(n) === 0) {
            return decodeURIComponent(cookie.substring(n.length));
        }
    }

    return null;
};

export const getCookiesPolicyCookie = () => {
    return getCookie(COOKIE_OPT_IN) === "true";
};

const setCookie = (name: string, value: string, expires: { days?: number }) => {
    void 0 === expires && (expires = {});

    let cookie = `${name}=${value};path=/`;

    if (expires.days) {
        let date = new Date();

        date.setTime(date.getTime() + 24 * expires.days * 60 * 60 * 1e3);

        cookie = `${cookie};expires=${date.toUTCString()}`;
    }

    if (document.location.protocol === "https:") {
        cookie = `${cookie};Secure`;
    }

    cookie = `${cookie};SameSite=Lax`;

    document.cookie = cookie;
};

const deleteCookie = (name: string, path?: string, domain?: string) => {
    if (getCookie(name)) {
        let cookie = `${name}=`;

        if (path) {
            cookie = `${cookie};path=${path}`;
        }

        if (domain) {
            cookie = `${cookie};domain=${domain}`;
        }

        cookie = `${cookie};expires=Thu, 01 Jan 1970 00:00:01 GMT`;

        document.cookie = cookie;
    }
};

export const setCookiesPolicyCookie = (isUsageAllowed: boolean) => {
    if (isUsageAllowed) {
        setCookie(COOKIE_OPT_IN, "true", { days: 365 });
    } else {
        deleteCookie(COOKIE_OPT_IN);
    }
};

export const setCookiesPreferencesCookie = () => {
    setCookie(COOKIE_COOKIES_PREFERENCES_SET, "true", { days: 365 });
};
