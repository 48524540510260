import { Fragment, memo } from "react";
import cn from "classnames";

import Icon from "components/ui/IconNew";
import LabelWithIcon from "components/ui/LabelWithIcon";

import { useFocusClasses } from "utils/useFocusClasses";

import "./style.scss";

const Pagination = memo(({ selectedPage, pageCount, onSelectPage, pageDisplayCount, withLabels }) => {
    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({ disabled: !isTabable });
    const onKeyDown = (page) => (event) => {
        if (event.key === "Enter") {
            onSelectPage(page);
        }
    };

    let displayCount = pageCount;
    let displayStartPage = 0;
    const lastPage = pageCount - 1;
    let displayEndPage = lastPage;

    if (pageDisplayCount) {
        const half = Math.floor(pageDisplayCount / 2);
        const half1 = Math.floor((pageDisplayCount - 1) / 2);

        if (pageDisplayCount + 1 === pageCount) displayCount = pageCount;
        else displayCount = Math.min(pageDisplayCount, pageCount);

        const e = selectedPage + half + 1;

        displayStartPage = Math.min(
            Math.max(selectedPage - half1 - (e + 1 === lastPage ? 1 : 0) + (e === lastPage ? 1 : 0), 0),
            Math.max(pageCount - pageDisplayCount, 0)
        );
        if (displayStartPage === 1) {
            displayStartPage = 0;
        }

        displayEndPage = Math.min(displayStartPage + displayCount, lastPage);
    }

    const arr = Array.from({ length: displayCount }, (v, k) => displayStartPage + k);

    const renderTab = (page, index) => (
        <div
            key={index}
            className={cn("selectable-page", { active: page === selectedPage })}
            tabIndex={isTabable ? "0" : "-1"}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onKeyDown(page)}
            onClick={() => onSelectPage(page)}
        >
            <span>{page + 1}</span>
        </div>
    );

    const renderSpace = (index) => (
        <div key={index} className="selectable-page spacer" tabIndex={isTabable ? "0" : "-1"}>
            <span>...</span>
        </div>
    );

    return (
        <>
            {pageCount > 1 && (
                <Fragment>
                    <div className="paging-wrap">
                        <div className="paging flex flex-center">
                            {withLabels ? (
                                <LabelWithIcon
                                    className="arrow-icon"
                                    icon="arrows-arrow_in_circle_left_b_s"
                                    disabled={selectedPage < 1}
                                    onClick={() => onSelectPage(selectedPage - 1)}
                                >
                                    prev
                                </LabelWithIcon>
                            ) : (
                                <Icon
                                    className="arrow-icon"
                                    icon="arrows-arrow_in_circle_left_b_s"
                                    title="Previous"
                                    disabled={selectedPage < 1}
                                    onClick={() => onSelectPage(selectedPage - 1)}
                                />
                            )}
                            {displayStartPage !== 0 && (
                                <>
                                    {renderTab(0, 0)}
                                    {renderSpace(1)}
                                </>
                            )}
                            {arr.map((page, index) => renderTab(page, index))}
                            {displayEndPage !== lastPage && (
                                <>
                                    {renderSpace(lastPage - 1)}
                                    {renderTab(lastPage, lastPage)}
                                </>
                            )}
                            {withLabels ? (
                                <LabelWithIcon
                                    className="arrow-icon"
                                    icon="arrows-arrow_in_circle_right_b_s"
                                    disabled={selectedPage === lastPage}
                                    onClick={() => onSelectPage(selectedPage + 1)}
                                >
                                    next
                                </LabelWithIcon>
                            ) : (
                                <Icon
                                    className="arrow-icon"
                                    icon="arrows-arrow_in_circle_right_b_s"
                                    title="Next"
                                    disabled={selectedPage === lastPage}
                                    onClick={() => onSelectPage(selectedPage + 1)}
                                />
                            )}
                        </div>
                    </div>
                </Fragment>
            )}
        </>
    );
});

export default Pagination;
