import { openModalDialog } from "layouts/Modal/utils";

import CreateClient from "./CreateClient";
import MarketData from "./MarketData";
import CreateProject from "./CreateProject";
import DeleteProject from "./DeleteProject";

import ClientAttributes from "layouts/common/ClientAttributes";

import { ClientModalProps, ProjectModalProps, DeleteProjectModalProps } from "./types";

export const openModalDialogCreateClient =
    () =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Create A New Client",
                component: <CreateClient />,
            })
        );
    };

export const openModalDialogClientAttributes =
    ({ client }: ClientModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Client Attributes",
                size: "large",
                component: <ClientAttributes client={client} displayInModal />,
            })
        );
    };

export const openModalDialogMarketData =
    ({ client }: ClientModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Market Data",
                component: <MarketData client={client} />,
            })
        );
    };

export const openModalDialogCreateProject =
    ({ idClient }: ProjectModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Create A New Project",
                component: <CreateProject idClient={idClient} />,
            })
        );
    };

export const openModalDialogDeleteProject =
    ({ idClient, idProject, projectName, userNumber }: DeleteProjectModalProps) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            openModalDialog({
                title: "Delete Project",
                component: <DeleteProject idClient={idClient} idProject={idProject} projectName={projectName} userNumber={userNumber} />,
            })
        );
    };
