import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { createHelpTip, updateHelpTip } from "store/resources/actions/helpTips/helpTipsActions";

import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import FieldGroup from "components/ui/FieldGroup";
import Input from "components/ui/Input";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import Label from "components/ui/Label";
import CKEditor from "components/ui/CKEditor/CKEditor";
import Markup from "components/ui/CKEditor/Markup";

import { HelpTipsFormProps } from "./types";

import "./style.scss";

const HelpTipsForm = memo((props: HelpTipsFormProps) => {
    const { id, pageId, onClose, onCancel } = props;

    const dispatch = useDispatch();

    // States

    const [title, setTitle] = useState(props.title || "");
    const [content, setContent] = useState(props.content || "");

    const [isPreviewMode, setIsPreviewMode] = useState(false);

    // Variable

    const headerAction = isPreviewMode ? "Preview" : "Edit";

    // Helper function

    const onSaveComplete = useCallback(
        (message: string) => {
            onCancel();

            toast.success(message);
        },
        [onCancel]
    );

    // Event handlers

    const onTitleChange = useCallback((event) => {
        setTitle(event.target.value);
    }, []);

    const onContentChange = useCallback((event, editor) => {
        const content = editor.getData();

        setContent(content);
    }, []);

    const onSaveClick = useCallback(() => {
        if (id === undefined) {
            dispatch(
                createHelpTip({
                    pageId,
                    title,
                    content,
                    onComplete: () => onSaveComplete("Help Tip was created successfully"),
                })
            );
        } else {
            dispatch(
                updateHelpTip({
                    id,
                    pageId,
                    title,
                    content,
                    onComplete: () => onSaveComplete("Help Tip was updated successfully"),
                })
            );
        }
    }, [id, pageId, title, content, onSaveComplete, dispatch]);

    const onPreviewClick = useCallback(() => {
        setIsPreviewMode(!isPreviewMode);
    }, [isPreviewMode]);

    // Render functions

    const renderEditorBody = () => {
        return (
            <>
                <FieldGroup className="fill-height">
                    <div className="help-tips-form__title-field">
                        <Input label="Title" placeholder="This is the title" value={title} onChange={onTitleChange} />
                    </div>
                    <div className="flex-column flex-one-in-column help-tips-form__content-field">
                        <Label className="help-tips-form__content-label">Content</Label>
                        <CKEditor data={content} onChange={(event, editor) => onContentChange(event, editor)} />
                    </div>
                </FieldGroup>
            </>
        );
    };

    const renderPreviewBody = () => {
        return (
            <>
                <div className="help-tips-form__title">{title}</div>
                <div className="with-scroll">
                    <div className="help-tips-form__content-container">
                        <Markup content={content} />
                    </div>
                </div>
            </>
        );
    };

    const renderEditorFooter = () => {
        return (
            <ButtonGroup className="justify-end fill-width">
                <Button primary onClick={onSaveClick}>
                    Save Changes
                </Button>
                <Button icon="eye-visibility-empty" onClick={onPreviewClick}>
                    Preview
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </ButtonGroup>
        );
    };

    const renderPreviewFooter = () => {
        return (
            <ButtonGroup className="align-center justify-space-between fill-width">
                <IconWithLabel icon="shevron-keyboard-arrow-left" onClick={onPreviewClick}>
                    Back To Edit Content
                </IconWithLabel>
                <Button primary onClick={onSaveClick}>
                    Save Changes
                </Button>
            </ButtonGroup>
        );
    };

    const renderBody = () => {
        if (isPreviewMode) {
            return renderPreviewBody();
        } else {
            return renderEditorBody();
        }
    };

    const renderFooter = () => {
        if (isPreviewMode) {
            return renderPreviewFooter();
        } else {
            return renderEditorFooter();
        }
    };

    // Main render

    return (
        <SideNavContent className="help-tips-form">
            <SideNavHeader className="help-tips-form__header" onClose={onClose}>
                <IconWithLabel className="help-tips-form__header-text" icon={isPreviewMode ? "eye-visibility-empty" : "edit-empty"}>
                    {`${headerAction} Content`}
                </IconWithLabel>
            </SideNavHeader>
            <SideNavBody className="help-tips-form__body">{renderBody()}</SideNavBody>
            <SideNavFooter className="help-tips-form__footer">{renderFooter()}</SideNavFooter>
        </SideNavContent>
    );
});

export default HelpTipsForm;
